<template>
  <div>
    <KValidationObserver
      ref="observer"
      v-slot="valdata"
      class="content-page"
    >
      <k-form
        @keydown.enter.prevent
        @submit.prevent="saveGroup"
        class="content-form"
      >
        <div class="nav-btn-group">
          <k-button-group>
            <slot
              name="formBar"
              v-bind="valdata"
            />
          </k-button-group>
          <KWikiHelp :props-wiki-help-code="wikiHelpCode" />
        </div>
        <div class="content-main checkedGroupDetail">
          <k-alert-msg ref="lblMsg" />
          <div class="content-navTab">
            <slot name="formBarNav" />
          </div>
          <div class="content-group">
            <k-form-group
              label-class="require-mark"
              label-suffix="groupName"
              label-align-md="right"
              label-cols-md="3"
              content-cols-lg="6"
              content-cols-md="7"
            >
              <KValidationProvider
                :rules="{ required: true, remote: isGroupNameUsed, max: 200 }"
                v-slot="{ errors }"
                slim
                name="groupName"
              >
                <k-alert
                  :show="errors.length > 0"
                  variant="warning"
                >
                  {{ errors[0] }}
                </k-alert>
                <k-form-input
                  v-model="form.groupName"
                  :plaintext="plaintext"
                />
              </KValidationProvider>
            </k-form-group>
            <k-form-group
              label-class="require-mark"
              label-suffix="type"
              label-align-md="right"
              label-cols-md="3"
              content-cols-lg="6"
              content-cols-md="7"
            >
              <KValidationProvider
                rules="required"
                v-slot="{ errors }"
                slim
                name="type"
              >
                <k-alert
                  :show="errors.length > 0"
                  variant="warning"
                >
                  {{ errors[0] }}
                </k-alert>
                <k-form-select
                  v-model="form.groupType"
                  :options="typeList"
                  :plaintext="plaintext"
                />
              </KValidationProvider>
            </k-form-group>

            <k-form-group
              v-if="isEvent"
              label-suffix="useFor"
              label-align-md="right"
              label-cols-md="3"
              content-cols-lg="6"
              content-cols-md="7"
            >
              <k-form-checkbox-group
                class="col-form-label d-block"
                v-model="form.dropDownKeyValue[enumGroupEditTypeValue.useFor]"
                :plaintext="plaintext"
                :options="useForList"
                @change="bindBookingTypes"
              />
            </k-form-group>

            <k-form-group
              v-if="isEvent"
              label-suffix="bookingStatus"
              label-align-md="right"
              label-cols-md="3"
              content-cols-lg="6"
              content-cols-md="7"
            >
              <k-form-checkbox-group
                class="col-form-label d-block"
                v-model="
                  form.dropDownKeyValue[enumGroupEditTypeValue.bookingStatus]
                "
                :plaintext="plaintext"
                :options="bookingStatusList"
              />
            </k-form-group>

            <k-form-group
              v-if="isEvent"
              label-suffix="bookingType"
              label-align-md="right"
              label-cols-md="3"
              content-cols-lg="6"
              content-cols-md="7"
            >
              <k-multiselect
                v-model="
                  form.dropDownKeyValue[enumGroupEditTypeValue.bookingType]
                "
                :options="bookingTypeList"
                :plaintext="plaintext"
              />
            </k-form-group>

            <k-form-group
              v-if="isEvent"
              label-suffix="votingStationSize"
              label-align-md="right"
              label-cols-md="3"
              content-cols-lg="6"
              content-cols-md="7"
            >
              <k-multiselect
                v-model="
                  form.dropDownKeyValue[
                    enumGroupEditTypeValue.votingStationSize
                  ]
                "
                :options="votingStationSizeList"
                :plaintext="plaintext"
              />
            </k-form-group>

            <k-form-group
              v-if="isEvent"
              label-suffix="requiredPayment"
              label-align-md="right"
              label-cols-md="3"
              content-cols-lg="6"
              content-cols-md="7"
            >
              <k-form-checkbox-group
                class="col-form-label d-block"
                v-model="
                  form.dropDownKeyValue[enumGroupEditTypeValue.requiredPayment]
                "
                :plaintext="plaintext"
                :options="requiredPaymentList"
              />
            </k-form-group>

            <k-form-group
              v-if="isEvent"
              label-suffix="bookingsPaid"
              label-align-md="right"
              label-cols-md="3"
              content-cols-lg="6"
              content-cols-md="7"
            >
              <k-form-checkbox-group
                class="col-form-label d-block"
                v-model="
                  form.dropDownKeyValue[enumGroupEditTypeValue.bookingsPaid]
                "
                :plaintext="plaintext"
                :options="requiredPaymentList"
              />
            </k-form-group>
            <k-form-group
              label-suffix="facilityStatus"
              label-align-md="right"
              label-cols-md="3"
              content-cols-lg="6"
              content-cols-md="7"
            >
              <k-multiselect
                v-model="
                  form.dropDownKeyValue[enumGroupEditTypeValue.facilityStatus]
                "
                :options="facilityStatusList"
                :plaintext="plaintext"
              />
            </k-form-group>
            <k-form-group
              label-suffix="facilityDesignation"
              label-align-md="right"
              label-cols-md="3"
              content-cols-lg="6"
              content-cols-md="7"
            >
              <k-multiselect
                v-model="
                  form.dropDownKeyValue[
                    enumGroupEditTypeValue.facilityDesignation
                  ]
                "
                :options="facilityDesignationList"
                :plaintext="plaintext"
                :on-item-remove="onFacilityDesignationItemRemove"
              />
            </k-form-group>

            <k-form-group
              label-suffix="vsoba"
              label-align-md="right"
              label-cols-md="3"
              content-cols-lg="6"
              content-cols-md="7"
            >
              <div
                v-if="!plaintext"
                class="input-search"
                v-b-modal.popStation
              >
                <k-form-tags disabled>
                  <k-form-tag
                    v-for="(station, stationIndex) in form.votingStationList"
                    :key="stationIndex"
                    @remove="onDelStation(station)"
                    @click.stop.native
                  >
                    {{ station.name }}
                  </k-form-tag>
                </k-form-tags>
                <b-icon icon="search" />
              </div>
              <k-list-group v-else>
                <b-list-group-item
                  v-for="(station, stationIndex) in form.votingStationList"
                  :key="stationIndex"
                >
                  {{ station.name }}
                </b-list-group-item>
              </k-list-group>
            </k-form-group>
          </div>
        </div>
      </k-form>
    </KValidationObserver>
    <k-popwindow
      id="popStation"
      @ok="onPopStationSelected"
      :before-close="onBeforeClose"
    >
      <voting-stations-window
        :is-event="isEvent"
        ref="selVotingStation"
        :location-type-id="
          form.dropDownKeyValue[enumGroupEditTypeValue.facilityDesignation]
        "
        :except-voting-station-i-d-list="
          form.dropDownKeyValue[enumGroupEditTypeValue.vOAssignment]
        "
      />
    </k-popwindow>
  </div>
</template>
<script>
import {
  saveGroup,
  getGroup,
  getFacilityStatus,
  getLocationTypes,
  getSizes,
  getBookingStatus,
  checkGroupNameUsed,
} from "../api/messageGroup";
import { repResult } from "../../../libs/global";
import { originDataChangeMixin } from "../../../utils/mixins/mixin";
import { enumGroupEditTypeValue } from "../enums/enumGroupEditTypeValue";
import votingStationsWindow from "../components/groupVotingStationsWindow";
import storeage from "../../../utils/storeage";
import { g } from "../../../locale/lang-val";
import { getCurPageConfigAndDefaultPageConfigbyRoutePath, getConfigVal } from "../../../utils/tools";

export default {
  mixins: [originDataChangeMixin],
  components: {
    votingStationsWindow,
  },
  props: {
    plaintext: {
      type: Boolean,
      default(){
        return false;
      },
    },
    id: {
      type: String,
      default(){
        return null;
      },
    },
    isEvent: {
      type: Boolean,
      default(){
        return false;
      },
    },
    propPageConfigSystem: {
      type: String,
      default(){
        return null;
      },
    },
  },
  data(){
    let curPageConfigAndDefaultPageConfig = this.propPageConfigSystem
        ? getCurPageConfigAndDefaultPageConfigbyRoutePath(
            this.propPageConfigSystem,
            this.$route.path,
        )
        : null;
    return {
      enumGroupEditTypeValue: enumGroupEditTypeValue,
      typeList: this.propPageConfigSystem
          ? getConfigVal(
              curPageConfigAndDefaultPageConfig,
              "typeOptionsSelect",
              "options",
          )
          : [
            { text: " ", value: null },
            { text: "Organization", value: "1" },
            { text: "Facility", value: "2" },
          ],
      facilityStatusList: [],
      useForList: [
        { text: g("votingStation"), value: "1" },
        { text: "Other Booking", value: "2" },
      ],
      bookingStatusList: [],
      bookingTypeList: [],
      votingStationSizeList: [],
      requiredPaymentList: [
        { text: "Yes", value: "2" },
        { text: "No", value: "1" },
      ],
      facilityDesignationList: [],
      organizationTypeList: [],
      boundaryTypeList: [],
      vsobaList: [],
      form: {
        id: this.id,
        electionId: this.isEvent ? storeage.getElectionId() : "",
        groupName: "",
        groupType: "",
        dropDownKeyValue: {
          [enumGroupEditTypeValue.useFor]: [],
          [enumGroupEditTypeValue.bookingStatus]: [],
          [enumGroupEditTypeValue.bookingType]: [],
          [enumGroupEditTypeValue.votingStationSize]: [],
          [enumGroupEditTypeValue.requiredPayment]: [],
          [enumGroupEditTypeValue.bookingsPaid]: [],
          [enumGroupEditTypeValue.facilityStatus]: [],
          [enumGroupEditTypeValue.facilityDesignation]: [],
          [enumGroupEditTypeValue.boundaryType]: [],
          [enumGroupEditTypeValue.vOAssignment]: [],
        },
        votingStationList: [],
      },
      wikiHelpCode: this.isEvent
          ? this.$wikiHelpLinkCodes?.Event.Communication.MessageGroup.BasicInfo
          : this.$wikiHelpLinkCodes?.BAU.Communication.MessageGroup.BasicInfo,
    };
  },
  methods: {
    async isGroupNameUsed(){
      let result = null;
      let para = {};
      para.name = this.form.groupName;
      para.id = this.form.id;
      para.electionId = this.form.electionId;
      await checkGroupNameUsed(para).then((rep) => {
        result = rep;
      });
      return result;
    },
    onBeforeClose(){
      return this.$bvModal
          .msgBoxConfirm(this.$g("const.confirm.save"), {
            title: this.$g("const.confirm.confirm"),
            cancelTitle: this.$g("const.confirm.saveYes"),
            okTitle: this.$g("const.confirm.no"),
            cancelVariant: "primary",
            okVariant: "secondary",
            noCloseOnBackdrop: true,
          })
          .then((value) => {
            return value != null && !value;
          });
    },
    onPopStationSelected(){
      let selectStations = this.$refs.selVotingStation.getSelected();
      selectStations.forEach((item) => {
        this.form.dropDownKeyValue[enumGroupEditTypeValue.vOAssignment].push(
            item.votingStationId,
        );
        this.form.votingStationList.push({
          id: item.votingStationId,
          name: `${item.locationTypeDescription} - ${item.name}`,
          locationTypeId: item.locationTypeId,
        });
      });
    },
    async saveGroup(){
      const isValid = await this.$refs.observer.validate();
      if(isValid){
        let pmOpt = this.$confirm({
          title: this.$g("const.confirm.confirm"),
          content: this.$g("const.confirm.save"),
          cancelType: "secondary",
          okText: this.$g("const.confirm.saveYes"),
          cancelText: this.$g("const.confirm.no"),
        });
        if(pmOpt == null){
          return;
        }
        pmOpt.then(() => {
          if(
              !this.form.dropDownKeyValue[
                  enumGroupEditTypeValue.facilityDesignation
                  ]
          ){
            this.form.dropDownKeyValue[
                enumGroupEditTypeValue.facilityDesignation
                ] = [];
          }
          saveGroup(this.form).then((m) => {
            if(m.data.status === repResult.success){
              this.$emit("save-successful", m);
            } else {
              this.$refs.lblMsg.message(m.data.status, m.message);
            }
          });
        });
      }
    },
    onFacilityDesignationItemRemove(removeItem){
      this.form.votingStationList = this.form.votingStationList.filter(
          (item) => item.locationTypeId !== removeItem.value,
      );
      this.form.dropDownKeyValue[enumGroupEditTypeValue.vOAssignment] = [];
      this.form.votingStationList.forEach((item) => {
        this.form.dropDownKeyValue[enumGroupEditTypeValue.vOAssignment].push(
            item.id,
        );
      });
    },
    onBoundaryTypeItemRemove(removeItem){
      if(removeItem.relateContent === 4){
        //remove achieved type
        this.boundaryTypeList = this.boundaryTypeList.filter((item) => {
          return item.value !== removeItem.value;
        });
      }
    },
    onDelStation: function (station){
      // this.$confirm({
      //   title: this.$g('const.confirm.confirm'),
      //   content: this.$g('const.confirm.delete'),
      //   cancelType: 'secondary',
      //   okText: this.$g('const.confirm.yes'),
      //   cancelText: this.$g('const.confirm.no'),
      // }).then(() => {
      this.form.votingStationList = this.form.votingStationList.filter(
          (item) => item.id !== station.id,
      );
      this.form.dropDownKeyValue[enumGroupEditTypeValue.vOAssignment] =
          this.form.dropDownKeyValue[enumGroupEditTypeValue.vOAssignment].filter(
              (id) => id !== station.id,
          );
      // })
    },
    bindFacilityStatus(){
      getFacilityStatus(this.isEvent).then((res) => {
        this.facilityStatusList = (res.data || []).filter((item) => item.value);
      });
    },
    bindDesignations(){
      getLocationTypes().then((res) => {
        this.facilityDesignationList = (res.data || []).filter(
            (item) => item.value,
        );
      });
    },
    bindBookingTypes(){
      if(this.form.dropDownKeyValue[enumGroupEditTypeValue.useFor]){
        let isOtherBookingUsed =
            this.form.dropDownKeyValue[enumGroupEditTypeValue.useFor].indexOf(
                "2",
            ) > - 1;
        let isVotingStationUsed =
            this.form.dropDownKeyValue[enumGroupEditTypeValue.useFor].indexOf(
                "1",
            ) > - 1;
        if(!isOtherBookingUsed && !isVotingStationUsed){
          this.bookingTypeList = [];
        } else if(isOtherBookingUsed && isVotingStationUsed){
          this.bookingTypeList = this.facilityDesignationList;
        } else if(isOtherBookingUsed && !isVotingStationUsed){
          this.bookingTypeList = this.facilityDesignationList.filter((item) => {
            return item.relateContent.isOtherBookingUsed;
          });
        } else if(!isOtherBookingUsed && isVotingStationUsed){
          this.bookingTypeList = this.facilityDesignationList.filter((item) => {
            return item.relateContent.isVotingStationUsed;
          });
        }
      }
      this.filterBookingTypes();
    },
    filterBookingTypes(){
      let bookingTypeValues =
          this.form.dropDownKeyValue[enumGroupEditTypeValue.bookingType];
      if(bookingTypeValues){
        this.form.dropDownKeyValue[enumGroupEditTypeValue.bookingType] =
            bookingTypeValues.filter(this.isBookingTypeExist);
      }
    },
    isBookingTypeExist(bookingType){
      for (let item of this.bookingTypeList) {
        if(bookingType === item.value){
          return true;
        }
      }
      return false;
    },
    bindBookingStatus(){
      getBookingStatus().then((data) => {
        this.bookingStatusList = (data.data || []).filter((item) => item.value);
      });
    },
    bindVotingStationSize(){
      getSizes().then((data) => {
        this.votingStationSizeList = (data.data || []).filter(
            (item) => item.value,
        );
      });
    },
    bindBoundaryType(){
      // getBoundaryType({ groupId: this.form.id, electionId: this.form.electionId }).then((data) => {
      //   this.boundaryTypeList = (data.data || []).filter((item) => item.value)

      //   this.getGroupInfo()
      // })
      this.getGroupInfo();
    },
    setDropDownKeyValue(data){
      let dropDownKeyValue = data.data.dropDownKeyValue;
      if(dropDownKeyValue){
        for (let key in dropDownKeyValue) {
          if(key !== enumGroupEditTypeValue.boundaryType){
            this.form.dropDownKeyValue[key] = dropDownKeyValue[key];
          }
        }
      }
    },
    getGroupInfo(){
      if(this.id){
        getGroup(this.id).then((data) => {
          this.form.groupName = data.data.groupName;
          this.form.groupType = data.data.groupType;
          if(data.data.votingStationList){
            this.form.votingStationList = data.data.votingStationList;
          }
          let dropDownKeyValue = data.data.dropDownKeyValue;
          if(dropDownKeyValue){
            for (let key in dropDownKeyValue) {
              this.setGetGroupInfoFuncDropDownKeyValue(key, dropDownKeyValue);
            }
          }
          this.setDropDownKeyValue(data);
          this.setOrignData(this.form);
          if(this.isEvent){
            this.bindBookingTypes();
          }
        });
      } else {
        this.setOrignData(this.form);
      }
    },
    setGetGroupInfoFuncDropDownKeyValue(key, dropDownKeyValue){
      if(key !== enumGroupEditTypeValue.boundaryType){
        this.form.dropDownKeyValue[key] = dropDownKeyValue[key];
      }
    },
  },
  mounted(){
    this.bindFacilityStatus();
    this.bindDesignations();
    this.bindBookingStatus();
    this.bindVotingStationSize();
    //getGroup and getBoundaryType sametime has 500error
    this.bindBoundaryType();
  },
};
</script>
<style>
.checkedGroupDetail .checkboxGroup .checkboxList:nth-last-child(n + 2)::after {
  content: ",";
  display: inline-block;
  margin-right: 3px;
}
</style>
