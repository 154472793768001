<template>
  <div class="content-page">
    <div class="content-form">
      <div class="nav-btn-group">
        <k-button-group>
          <k-button-back
            :back-route="backRoute()"
            :back-query="{ tabindex: $route.query.tabindex }"
          >
            buttons.back
          </k-button-back>
          <k-button
            @click="toggleView(false)"
            v-show="isPlainText && $route.query.showEdit == '1'"
          >
            buttons.edit
          </k-button>
          <k-button @click="toggleView(true)" v-show="!isPlainText">
            buttons.view
          </k-button>
          <k-pre-next v-show="false" :has-save-next="false" ref="pn" />
        </k-button-group>
        <div class="nav-search">
          <search-bar
            v-if="isPlainText"
            :data-source="searchBarDataSource"
            query-keyword="keywords"
            :search-bar-fomatter="searchBarFomatter"
            @option-selected="searchSelected"
            sort="subject"
            order="Asc"
            :other-conditions="{
              electionId: electionId,
              templateType: $route.query.templateType,
              isNotification: isNotification,
            }"
          />
        </div>
        <KWikiHelp :propsWikiHelpCode="wikiHelpCode" />
      </div>
      <div class="content-main">
        <k-alert-msg ref="lblMsg" />
        <div class="content-navTab">
          <template-nav
            ref="nav"
            :tab-index="navIndex"
            :is-plain-text="isPlainText"
            :is-event="isEvent"
            :isNotification="isNotification"
          />
        </div>
        <div class="content-party pt-3 tableLog">
          <common-log
            :fields-type="logFieldsType.activityLog"
            :log-type="logType"
            :id="id"
            :sys-id="sysId"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import templateNav from "../../components/template-nav.vue";
import { activityLogType } from "../../../../utils/enums/logType";
import { logFieldsType } from "../../../../business-components/log/const";
import { sysId } from "../../../../utils/enums/sysId";
import { getTemplateSearchBarList } from "../../api/messageTemplate";
import { enumTemplateType } from "../../enums/enumTemplateType";
import storeage from "../../../../utils/storeage";
import { permissionCode } from "../../mixins/permissionCode";

export default {
  data() {
    let isEvent = this.$route.meta.isEvent;
    return {
      isEvent: isEvent,
      id: this.$route.query.id,
      isPlainText: this.$route.query.isView === "1",
      logFieldsType: logFieldsType,
      logType: activityLogType.template,
      sysId: this.logSysId,
      navIndex: 1,
      enumTemplateType: enumTemplateType,
      electionId: isEvent ? storeage.getElectionId() : "",
      wikiHelpCode: null,
    };
  },
  props: {
    logSysId: {
      type: Number,
      default: sysId.plms,
    },
  },
  mixins: [permissionCode],
  components: { templateNav },
  computed: {
    searchBarDataSource: function () {
      return getTemplateSearchBarList;
    },
  },
  created() {
    this.setWikiHelpCode();
  },
  methods: {
    setWikiHelpCode() {
      let wikiHelpCodes = this.isEvent
        ? this.$wikiHelpLinkCodes?.Event.Communication?.Template
        : this.$wikiHelpLinkCodes?.BAU.Communication?.Template;
      if (this.isNotification) {
        wikiHelpCodes = this.isEvent
          ? this.$wikiHelpLinkCodes?.Event.Notification?.Template
          : this.$wikiHelpLinkCodes?.BAU.Notification?.Template;
      }
      switch (parseInt(this.$route.query.templateType ?? 0)) {
        case enumTemplateType.email:
          this.wikiHelpCode = wikiHelpCodes?.Email.Log;
          break;
        case enumTemplateType.phone:
          this.wikiHelpCode = wikiHelpCodes?.Phone.Log;
          break;
        case enumTemplateType.sms:
          this.wikiHelpCode = wikiHelpCodes?.SMS.Log;
          break;
        case enumTemplateType.letter:
          this.wikiHelpCode = wikiHelpCodes?.Letter.Log;
          break;
        case enumTemplateType.documentation:
          this.wikiHelpCode = this.isNotification
            ? wikiHelpCodes?.Documents?.Log
            : wikiHelpCodes?.Document?.Log;
          break;
        default:
          this.wikiHelpCode = null;
          break;
      }
    },
    backRoute() {
      if (this.isNotification) {
        if (this.$route.query.templateType == enumTemplateType.documentation)
          return this.backRouteIsEventFunc(
            "eleNotificationDocumentTemplateList",
            "notificationDocumentTemplateList"
          );
        return this.backRouteIsEventFunc("eleNotification", "notification");
      } else {
        if (this.$route.query.templateType == enumTemplateType.documentation)
          return this.backRouteIsEventFunc(
            "eleDocumentTemplateList",
            "documentTemplateList"
          );
        return this.backRouteIsEventFunc(
          "eleMessageTemplate",
          "messageTemplate"
        );
      }
    },
    backRouteIsEventFunc(isEventTrue, isEventFalse) {
      return this.isEvent ? isEventTrue : isEventFalse;
    },
    toggleView(view) {
      this.$router.push({
        name: this.$route.name,
        query: {
          id: this.id,
          isView: view ? "1" : "0",
          dbtn: this.$route.query.dbtn,
          templateType: this.$route.query.templateType,
          showEdit: this.$route.query.showEdit,
          tabindex: this.$route.query.tabindex,
        },
      });
    },
    searchBarFomatter(item) {
      return `${item.subject}`;
    },
    searchSelected(item) {
      let routeName = "";
      if (this.isNotification) {
        routeName = this.isEvent ? "eleNotificationView" : "notificationView";
      } else {
        routeName = this.isEvent
          ? "eleMessageTemplateView"
          : "messageTemplateView";
      }
      if (this.$route.query.templateType == enumTemplateType.documentation) {
        if (this.isNotification) {
          routeName = this.isEvent
            ? "eleNotificationDocumentTemplateView"
            : "notificationDocumentTemplateView";
        } else {
          routeName = this.isEvent
            ? "eleDocumentTemplateView"
            : "documentTemplateView";
        }
      }
      this.$router.push({
        name: routeName,
        query: {
          id: item.id,
          hidePreNext: "1",
          isView: "1",
          dbtn: this.$route.query.dbtn,
          templateType: this.$route.query.templateType,
          tabindex: this.$route.query.tabindex,
        },
      });
    },
  },
};
</script>
<style lang="scss">
.tableLog .table-responsive-sm table {
  table-layout: fixed;
  overflow-wrap: break-word;
}
</style>
