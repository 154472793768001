var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"backgroundGray"},[_c('KValidationObserver',{ref:"observer",staticClass:"content-page"},[_c('k-form-group',{attrs:{"label-class":"require-mark","label-suffix":"name","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('KValidationProvider',{attrs:{"rules":{
          required: true,
          passiveMax: { length: 200 },
          remote: _vm.isReportTemplateNameUsed,
        },"slim":"","name":"name"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-input',{attrs:{"id":"input-code","plaintext":false},model:{value:(_vm.form.tempName),callback:function ($$v) {_vm.$set(_vm.form, "tempName", $$v)},expression:"form.tempName"}})]}}])})],1),_c('k-form-group',{attrs:{"label-suffix":"description","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('KValidationProvider',{attrs:{"rules":{
          max: 2000,
        },"slim":"","name":"description"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-textarea',{attrs:{"plaintext":false},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }