const routes = [
  {
    name: "trainingHistory",
    path: "trainingHistory",
    meta: {
      title: "trainingHistory",
      pageTitle: "welcome",
    },
    route: true,
    component: () => import("../../views/workTrainingHistory/trainingHistory"),
  },
  {
    name: "workHistory",
    path: "workHistory",
    meta: {
      title: "workHistory",
      pageTitle: "welcome",
    },
    component: () => import("../../views/workTrainingHistory/workHistory"),
    route: true,
  },
];


export default [
  {
    name: "workTrainingHistory",
    path: "/workTrainingHistory",
    meta: {
      title: 'workTrainingHistory',
      requiresAuth: false,
    },
    redirect: { name: '' },
    component: () => import("@/layout/brief"),
    children: [...routes],
  },
];