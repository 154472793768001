<template>
  <div class="content">
    <div class="center-bar">
      <div class="nav-btn-group">
        <b-btn-group>
          <k-button-back
            :back-route="isEvent ? 'eleMessageGroup' : 'messageGroup'"
          >
            buttons.back
          </k-button-back>
        </b-btn-group>
        <KWikiHelp :props-wiki-help-code="wikiHelpCode" />
      </div>

      <div class="content-main">
        <div class="content-party">
          <konn-grid
            :list-id="lisId"
            :select-mode="selectMode"
            :has-check-box="hasCheckBox"
            :has-action="hasAction"
            ref="selectableTable"
            :data-source="dataSource"
            :get-condition="getCondition"
            grid-sort-by="code"
          >
            <template
              v-for="field in ['votingStationID', 'votingStationSize','votingStationStatus','votingStationType']"
              #[`cell(${field})`]="row"
            >
              <div
                :key="field"
                v-html="toBr(row.value)"
              />
            </template>
          </konn-grid>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getGroupMatchingList } from "../../api/messageGroup";
import storeage from "../../../../utils/storeage";

export default {
  data(){
    let isEvent = this.$route.meta.isEvent;

    let orgLisId = isEvent
        ? "elemsgroupcountdetailorglistnew"
        : "msgroupcountdetailorglistnew";

    let vsLisId = isEvent
        ? "elemsgroupcountdetailvslistnew"
        : "msgroupcountdetailvslistnew";
    return {
      isEvent: isEvent,
      keyword: "",
      modes: ["multi", "single", "range"],
      testUrl: "",
      selectMode: "multi",
      selected: [],
      hasCheckBox: false,
      hasAction: false,
      paras: {
        id: this.$route.query.id,
        electionId: isEvent ? storeage.getElectionId() : "",
        groupType: this.$route.query.type,
      },
      lisId: `${this.$route.query.type}` === "1" ? orgLisId : vsLisId,
      wikiHelpCode: isEvent
          ? this.$wikiHelpLinkCodes?.Event.Communication.MessageGroup
              .NumberOfMatchingLink
          : this.$wikiHelpLinkCodes?.BAU.Communication.MessageGroup
              .NumberOfMatchingLink,
    };
  },
  components: {},
  computed: {
    dataSource: function (){
      return getGroupMatchingList;
    },
    searchBarDataSource: function (){
      return null;
    },
  },
  methods: {
    toBr(html){
      // eslint-disable-next-line no-control-regex
      let reg = new RegExp("\r\n", "g");
      return html.replaceAll(reg, "<br/>");
    },
    onColumnChanged(){
      this.$refs.selectableTable.clearFields();
    },
    getCondition(){
      return {
        ...this.paras,
      };
    },
    search: function (){
      this.$refs.selectableTable.refresh();
    },
    selectAllRows: function (){
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected: function (){
      this.$refs.selectableTable.clearSelected();
    },
    searchBarFomatter(item){
      return `${item.boundaryCode} ${item.boundaryName} ${item.boundaryTypeCode}`;
    },
  },
};
</script>
