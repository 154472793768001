import { render, staticRenderFns } from "./report-widget-list.vue?vue&type=template&id=71d0ae30&scoped=true"
import script from "./report-widget-list.vue?vue&type=script&lang=js"
export * from "./report-widget-list.vue?vue&type=script&lang=js"
import style0 from "./report-widget-list.vue?vue&type=style&index=0&id=71d0ae30&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../fe-workerportal/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71d0ae30",
  null
  
)

export default component.exports