import Vue from 'vue'
import load from './load'
import { g } from "../../locale/lang-val";
const LoadingConstructor = Vue.extend(load);
const instance = new LoadingConstructor({
  el: document.createElement("div"),
})
const loading = {
  open(message) {
    instance.open++;
    instance.message = message ? message : g("load");
    instance.showLoad = true;
    document.body.append(instance.$el);
    setTimeout(() => {
      document
        .querySelector(".search-area-main input,.search-area-main select")
        ?.focus();
    }, 100);
  },
  close() {
    instance.close++;
    if (instance.open <= instance.close) {
      instance.open = 0;
      instance.close = 0;
      instance.showLoad = false;
    }
  }
}

export default loading