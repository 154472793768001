<template>
  <div class="content-page">
    <election-select v-if="isEvent" />
    <div class="content">
      <div
        class="right-bar"
        v-show="seen"
        v-if="!isLinkPreview"
      >
        <b-icon
          icon="x"
          @click="onToggleSearch"
        />
        <ReportViewTemplate
          ref="reportViewTemplate"
          type-name="ReportViewConfigurationTemplate"
          :report-id="reportId"
          :template-choose="onTemplateChoose"
          :template-delete="onTemplateDelete"
          :template-save="onTemplateSave"
          :is-event="isEvent"
        />
      </div>
      <div class="center-bar">
        <div
          class="nav-btn-group"
          v-if="!isLinkPreview"
        >
          <k-button-group>
            <k-button-back
              :back-route="isEvent ? 'eleReport' : 'report'"
              :back-query="{ tabindex: $route.query.tabindex }"
              v-if="hasBack"
            >
              buttons.back
            </k-button-back>
            <k-button
              v-show="!useConfiguration"
              @click="saveReportConfiguration()"
              v-permission="permissionCode.Report.SaveReportConfiguration"
            >
              buttons.saveReportConfiguration
            </k-button>
            <k-button
              v-show="useConfiguration"
              @click="updataReportConfiguration()"
              v-permission="permissionCode.Report.UpdateReportConfiguration"
            >
              buttons.updateReportConfiguration
            </k-button>
            <k-button
              v-show="useConfiguration"
              @click="saveReportConfiguration()"
              v-permission="permissionCode.Report.SaveNewReportConfiguration"
            >
              buttons.saveNewReportConfiguration
            </k-button>
          </k-button-group>
          <b-icon-search-area
            @click="onToggleSearch"
            :class="{ active: isActive }"
          />
        </div>
        <div class="content-main">
          <k-alert-msg ref="lblMsg" />
          <p v-show="totalCount == 0">
            {{ $g("reportNoRecords") }}
          </p>
          <h1
            class="text-center"
            v-show="totalCount != 0"
          >
            {{ title }}
          </h1>
          <div class="content-party">
            <Pivot
              ref="dataRocks"
              :beforetoolbarcreated="customizeToolbar"
              :toolbar="true"
            />
          </div>
          <div style="padding: 0.45rem">
            {{ $g("report-last-update-at") }} {{ reportTime }}
          </div>
        </div>
      </div>
    </div>
    <k-popwindow
      id="saveViewTemplate"
      @ok="selectReasonComplete"
      :before-close="onSelectReasonBeforeClose"
      :check-selected="false"
      :title="$g('popwindow.saveViewTemplate')"
    >
      <SaveViewTemplate
        ref="saveViewTemplate"
        type-name="ReportViewConfigurationTemplate"
        :report-id="reportId"
        :election-id="electionId"
      />
    </k-popwindow>
  </div>
</template>

<script>
import { Pivot } from "vue-webdatarocks";
import "webdatarocks/webdatarocks.css";
import ReportViewTemplate from "../../components/reportViewTemplate";
import SaveViewTemplate from "../../components/saveViewTemplate";
import { getReportDetail, getReportURL } from "../../api/report";
import { saveTemplate } from "../../../../api/searchTemplate";
import storeage from "../../../../utils/storeage";

export default {
  components: {
    Pivot,
    ReportViewTemplate,
    SaveViewTemplate,
  },
  props: {
    hasBack: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    propsElectionId: {
      type: String,
      default: () => {
        return null;
      },
    },
    isLinkPreview: {
      type: Boolean,
      default: false,
    },
    permissionCode: {
      type: Object,
      default(){
        return this.$route.meta.permissionCode;
      },
    },
  },
  data(){
    let isEvent = this.$route.meta.isEvent ?? false;
    return {
      reportId: this.$route.query.id,
      fileName: "",
      isEvent: isEvent,
      title: "",
      footString: null,
      reportTime: "",
      electionId: this.propsElectionId ?? storeage.getElectionId() ?? "",
      totalCount: 1,
      exportType: 1,
      seen: true,
      isActive: true,
      useConfiguration: false,
      templateId: "",
      form: {
        reportId: "",
        templateType: 0,
        templateName: "",
        description: "",
      },
      defultOptions: {
        grid: { type: "flat", showTotals: "off", showGrandTotals: "off" },
      },
    };
  },
  computed: {},
  methods: {
    onToggleSearch(){
      this.seen = !this.seen;
      this.isActive = !this.isActive;
    },
    customizeToolbar(toolbar){
      var that = this;
      var tabs = toolbar.getTabs();
      toolbar.getTabs = function (){
        var index = 0;
        delete tabs[index ++];
        delete tabs[index ++];
        delete tabs[index ++];
        if(!that.$has(that.permissionCode.Report.Export)){
          delete tabs[index ++];
        }
        return tabs;
      };
    },
    requestData(){
      return {
        reportId: this.reportId,
        electionId: this.electionId,
        exportType: this.exportType,
      };
    },
    onTemplateChoose(template){
      this.templateId = template.id;
      this.form.reportId = template.reportId;
      this.form.templateType = template.templateType;
      this.form.templateName = template.templateName;
      this.form.description = template.description;
      this.useConfiguration = !template.usedInWidget;
      var report = JSON.parse(template.content);
      report.dataSource.filename = this.fileName;
      this.$refs.dataRocks.webdatarocks.setReport(report);
    },
    onTemplateDelete(id){
      if(this.templateId === id){
        this.useConfiguration = false;
      }
    },
    onTemplateSave(id){
      this.templateId = id;
      this.useConfiguration = true;
    },
    async onSelectReasonBeforeClose(){
      let valid = await this.$refs.saveViewTemplate.checkValid();
      if(valid){
        return this.$bvModal
            .msgBoxConfirm(this.$g("const.confirm.save"), {
              title: this.$g("const.confirm.confirm"),
              cancelTitle: this.$g("const.confirm.saveYes"),
              okTitle: this.$g("const.confirm.no"),
              cancelVariant: "primary",
              okVariant: "secondary",
              noCloseOnBackdrop: true,
            })
            .then((value) => {
              return value != null && !value;
            });
      }
      return false;
    },
    selectReasonComplete(){
      let report = this.$refs.dataRocks.webdatarocks.getReport();
      let data = {
        ...this.$refs.saveViewTemplate.getFormData(),
        content: JSON.stringify(report),
        electionid: this.isEvent ? this.electionId : "",
      };
      saveTemplate(data).then((res) => {
        if(res.data){
          this.$refs.lblMsg.success(this.$g("search.savedsuccessfully"));
          this.saveDialogVisible = false;
        } else {
          this.$refs.lblMsg.error(this.$g("search.savedfailed"));
        }
        this.$refs.reportViewTemplate.getTemplateListByTemplateType(1);
      });
    },
    saveReportConfiguration(){
      this.$bvModal.show("saveViewTemplate");
    },
    updataReportConfiguration(){
      let report = this.$refs.dataRocks.webdatarocks.getReport();
      let data = {
        ...this.form,
        content: JSON.stringify(report),
        electionid: this.isEvent ? this.electionId : "",
      };
      saveTemplate(data, this.templateId).then((res) => {
        if(res.data){
          this.$refs.lblMsg.success(this.$g("search.savedsuccessfully"));
          this.saveDialogVisible = false;
        } else {
          this.$refs.lblMsg.error(this.$g("search.savedfailed"));
        }
        this.$refs.reportViewTemplate.getTemplateListByTemplateType();
      });
    },
  },
  created(){
    getReportDetail(this.reportId).then((resp) => {
      if(resp.data){
        this.title = resp.data.title;
        this.reportTime = resp.data.generateTime;
        // this.fileName =
        //   "https://pcfdfs.2dmeeting.cn/group1/M00/01/9E/wKgEcWLgXPuAIazkAAAGgFfQRkU612.csv";
        // this.$refs.dataRocks.webdatarocks.updateData({
        //   filename: this.fileName,
        // });
        // this.$refs.dataRocks.webdatarocks.updateData({
        //   filename:
        //     "https://cdn.webdatarocks.com/data/data.csv",
        // });
      }
    });
    getReportURL(this.requestData()).then((rep) => {
      this.fileName = rep.data;
      this.$refs.dataRocks.webdatarocks.setOptions(this.defultOptions);
      this.$refs.dataRocks.webdatarocks.refresh();
      this.$refs.dataRocks.webdatarocks.updateData({
        filename: this.fileName,
      });
    });
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .stickyTable .table.b-table {
    > thead > tr > th {
      min-width: 200px;
    }

    > tbody > tr > td {
      min-width: 200px;
    }
  }

  .footData {
    .table-responsive-sm {
      margin-bottom: 0;
    }

    .table.b-table {
      border-bottom: 0;

      > thead {
        display: none;
      }

      > tbody {
        background-color: inherit;

        > tr > td {
          border: 0;
          background-color: inherit;
        }
      }
    }
  }
}
</style>
