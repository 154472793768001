<template>
  <b-dropdown
    id="dropdown-form"
    right
    v-if="hasSelectLanguage"
    class="userSetting"
  >
    <template #button-content>
      <b-icon
        icon="person-fill"
        style="color: var(--topMenuFont); cursor: auto"
      />
      <span class="userName">{{ userName }}</span>
    </template>
    <SelectLanguage />
    <div style="display:list-item" @click="onResetPassword">
      <div
      class="languageTitle"
     
    >
       <div class="languageHeader">
        {{ $g("resetPassword") }}
      </div>
    </div>
    </div>
  </b-dropdown>
  <div v-else style="margin-right: 0.6rem">
    <b-icon
      icon="person-fill"
      style="color: var(--topMenuFont); cursor: auto"
    />
    {{ userName }}
  </div>
</template>

<script>
import { getResetPasswordView } from "../../api/app"
import storeage from "../../utils/storeage";
import SelectLanguage from "../../business-components/common-select-language/select-language";

export default {
  props: {
    hasSelectLanguage: {
      type: Boolean,
      default: true,
    },
    isOnlyUserName: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    SelectLanguage,
  },
  methods: {
    onLogout() {
      this.$store.dispatch("user/logout").then(() => {
        this.$router.push({ name: "login" });
      });
    },
    onResetPassword () {
      this.$confirm({
        title: this.$g("const.confirm.confirm"),
        content: this.$g("const.confirm.reset-password"),
        cancelType: "secondary",
        okText: this.$g("const.confirm.yes"),
        cancelText: this.$g("const.confirm.no"),
      }).then((m) => {
        getResetPasswordView().then(res=>{
          window.location.href=res.data.info.url
        })
      });
    },
  },
  computed: {
    userName() {
      if (!this.isOnlyUserName) {
        if (
          storeage.getUserInfo()?.lastName &&
          storeage.getUserInfo()?.firstName
        ) {
          return (
            storeage.getUserInfo()?.lastName +
            ", " +
            storeage.getUserInfo()?.firstName
          );
        } else if (storeage.getUserInfo()?.lastName) {
          return storeage.getUserInfo()?.lastName;
        } else if (storeage.getUserInfo()?.firstName) {
          return storeage.getUserInfo()?.firstName;
        } else {
          return "";
        }
      } else {
        return storeage.getUserInfo()?.username;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
[class^="el-icon-"] {
  font-size: 1.2rem;
}

.userText {
  font-size: 1rem;
}
.languageTitle {
  position: relative;

  &:hover {
    .languageGroup {
      display: block;
    }
  }

  .languageHeader {
    padding: 0.25rem 1.5rem;
    cursor: pointer;

    &:hover {
      background-color: #ddd;
    }
  }
}
</style>
