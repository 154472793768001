<template>
  <b-modal
    :title="title"
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    v-model="showModal"
    @hidden="sendResponse"
  >
    <p>{{ content }}</p>
    <div slot="modal-footer">
      <b-button
        class="float-right m-1"
        :variant="okType"
        data-action="auto-focus"
        @click="toggle()"
      >
        {{ okText }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>

import { BModal, BButton } from "bootstrap-vue";

export default {
  name: "MsgAlert",
  components: { BModal, BButton },
  props: {
    title: {
      type: String,
      default: "Title",
    },
    content: {
      type: String,
      default: "Empty",
    },
    okText: {
      type: String,
      default: "Ok",
    },
    okType: {
      type: String,
      default: "primary",
    },
    cb: {
      type: Function,
      required: true,
    },
  },
  data: function (){
    return {
      showModal: false,
    };
  },
  methods: {
    sendResponse: function (){
      this.cb();
    },
    toggle(){
      this.showModal = !this.showModal;
    },
  },
  mounted: function (){
    this.showModal = true;
  },
};
</script>

<style scoped>
</style>
