import http from "../utils/http";
import globalEnv from "../libs/globalEnv";

export const getTemplateList = (model) => {
  return http.post("api/search", { model }, { loading: true, baseURL: globalEnv.VUE_APP_STD_URL });
};

export const getTemplatePage = (model) => {
  return http.post("api/search/search-page", model, { useBody: true, loading: false, baseURL: globalEnv.VUE_APP_STD_URL });
};

export const saveTemplate = (template, iD) => {
  return http.put("api/search", { template, iD }, { loading: false, baseURL: globalEnv.VUE_APP_STD_URL });
};

export const deleteTemplate = (iD) => {
  return http.delete("api/search", { iD }, { loading: false, baseURL: globalEnv.VUE_APP_STD_URL });
};

export const deleteTemplateList = (iDs) => {
  return http.delete("api/search/delete-list", { iDs }, { loading: false, baseURL: globalEnv.VUE_APP_STD_URL });
};

export const getTemplateType = (data) => {
  return http.get("api/search/type", data, { loading: false, baseURL: globalEnv.VUE_APP_STD_URL });
};

export const checktemplatenameexits = (model) => {
  return http.post("/api/search/actions/check-templatename-used", { model }, {
    loading: false,
    baseURL: globalEnv.VUE_APP_STD_URL,
  });
};
