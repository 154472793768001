import config from "../../../package.json";

const state = {
  version: config.version,
  environment: process.env.NODE_ENV
};

const mutations = {};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,

  actions
};
