<template>
  <div class="detail">
    <div class="content">
      <div class="center-bar" v-show="showThisSearchArea">
        <div class="nav-btn-group">
          <k-button-group>
            <k-button-back :back-route="isEvent ? 'eleReport' : 'report'">
              buttons.back
            </k-button-back>
          </k-button-group>
          <KWikiHelp :props-wiki-help-code="permissionCode.Report.Category" />
        </div>
        <div class="content-main">
          <k-alert-msg ref="lblMsg" />
          <div class="content-navTab">
            <div class="nav-tools">
              <b-icon-add
                v-permission="permissionCode.Report.CategoryAdd"
                @click="onAdd"
              />
              <b-icon-delete
                v-permission="permissionCode.Report.CategoryDelete"
                @click="onDeleteClick"
              />
            </div>
            <div>
              <b-tabs>
                <b-tab>
                  <template #title>
                    {{ $g("categories") }}
                    <span class="text-red">({{ totalCount }})</span>
                  </template>
                </b-tab>
              </b-tabs>
            </div>
          </div>
          <div class="content-party">
            <konn-grid
              ref="selectableTable"
              :data-source="dataSource"
              :get-condition="getCondition"
              :class="{ 'table-show-search-area': showThisSearchArea }"
              list-id="Id"
              @total-changed="onTotalChanged"
              :grid-sort-desc="sortDesc"
              :grid-sort-by="sortField"
              @items-get="itemsGet"
              sort-icon-left
              :fields="fields"
              :has-action="
                $has(permissionCode.Report.CategoryEdit) ||
                $has(permissionCode.Report.CategoryAdd)
              "
            >
              <template #cell(title)="row">
                <template v-if="row.item.isSave == true">
                  <KValidationObserver :ref="`observername${row.item.id}`">
                    <KValidationProvider
                      :rules="{
                        required: true,
                        max: 200,
                        remote: isCheckname,
                      }"
                      v-slot="{ errors }"
                      slim
                      mode="lazy"
                      name="name"
                    >
                      <k-alert :show="errors.length > 0" variant="warning">
                        {{ errors[0] }}
                      </k-alert>
                      <k-form-input
                        id="txtname"
                        name="name"
                        v-model="row.item.title"
                        type="text"
                      />
                    </KValidationProvider>
                  </KValidationObserver>
                </template>
              </template>
              <template #cell(order)="row">
                <template v-if="row.item.isSave == true">
                  <KValidationObserver
                    :ref="`observerorderIndex${row.item.id}`"
                  >
                    <KValidationProvider
                      :rules="{
                        integer: true,
                        required: true,
                        max: 6,
                        numberBetween: { minValue: 1, maxValue: 999999 },
                        remote: isCheckorder,
                      }"
                      v-slot="{ errors }"
                      slim
                      name="orderIndex"
                    >
                      <k-alert :show="errors.length > 0" variant="warning">
                        {{ errors[0] }}
                      </k-alert>
                      <k-form-input
                        id="numberorderIndex"
                        name="orderIndex"
                        v-model="row.item.order"
                        type="text"
                      />
                    </KValidationProvider>
                  </KValidationObserver>
                </template>
              </template>
              <template #cell(actions)="row">
                <a
                  href="#"
                  @click="onEdit(row)"
                  v-if="
                    !onAddShow(row) && $has(permissionCode.Report.CategoryEdit)
                  "
                  >{{ $g("buttons.edit") }}</a
                >
                <a href="#" @click="onSaveClick(row)" v-if="onAddShow(row)">{{
                  $g("buttons.save")
                }}</a>
                <a
                  href="#"
                  @click="onCancelClick(row)"
                  v-if="onAddShow(row)"
                  style="margin-left: 50px"
                  >{{ $g("buttons.cancel") }}</a
                >
              </template>
            </konn-grid>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getCategoryList,
  checkCategoryNameUsed,
  checkCategoryOrderUsed,
  updateCategory,
  addCategory,
  delCategory,
} from "../../api/report";
import { repResult } from "../../../../libs/global";
import {
  originDataChangeMixinOnlyEdit,
  beforeRouteLeaveMixin,
} from "../../../../utils/mixins/mixin";

export default {
  mixins: [originDataChangeMixinOnlyEdit, beforeRouteLeaveMixin],
  data() {
    let isEvent = this.$route.meta.isEvent ?? false;
    return {
      permissionCode: this.$route.meta.permissionCode,
      isEvent: isEvent,
      AddIndex: [],
      isAdd: false,
      sortDesc: false,
      sortField: "order",
      showThisSearchArea: true,
      departMentIdOptions: [],
      departMentId: "",
      isActive: true,
      totalCount: 0,
      name: "",
      fields: [
        { key: "title", label: "title", sortable: true },
        { key: "order", label: "order", sortable: true },
      ],
      form: {
        id: "",
        title: "",
        order: 1,
        isBau: !isEvent,
      },
      tableItem: {
        id: "",
        title: "",
        order: "",
        isSave: true,
      },
      newEditData: {},
      lastEditData: {},
      operateEmnum: {
        Add: 1,
        Edit: 2,
        Delete: 3,
      },
      itemsBeforeEdit: [],
    };
  },
  computed: {
    dataSource: function () {
      return this.getPages;
    },
  },
  methods: {
    itemsGet(items) {
      this.setOrignData(items);
    },
    onAdd() {
      this.onAddClick();
    },
    onEdit(row) {
      let itemBeforeEdit = JSON.parse(JSON.stringify(row.item));
      let findIndex = this.itemsBeforeEdit.findIndex(
        (e) => e.id == itemBeforeEdit.id
      );
      if (findIndex > 0) {
        this.itemsBeforeEdit[findIndex] = itemBeforeEdit;
      } else {
        this.itemsBeforeEdit.push(itemBeforeEdit);
      }
      this.onEditClick(row);
    },
    isCheckEdit(operate, row) {
      let isCheck = true;
      let AllRows = this.$refs.selectableTable.getAllRows();
      var mapArr = AllRows.map((item) => item.isSave);
      console.log(mapArr);
      var setArr = new Set(mapArr);
      console.log(setArr);
      console.log(this.newEditData);
      console.log(this.lastEditData);
      if (
        setArr.size > 1 &&
        (this.newEditData.title != this.lastEditData.title ||
          this.newEditData.order != this.lastEditData.order)
      ) {
        let pmOpt = this.$confirm({
          title: this.$g("const.confirm.confirm"),
          content: this.$g("const.confirm.save"),
          cancelType: "secondary",
          okText: this.$g("const.confirm.saveYes"),
          cancelText: this.$g("const.confirm.no"),
        });
        if (pmOpt == null) {
          return true;
        }
        pmOpt
          .then((result) => {
            if (result) {
              console.log(99999);
              return this.operateTableRow(operate, row);
            } else {
              isCheck = false;
              return isCheck;
            }
          })
          .catch((err) => {
            isCheck = false;
            return isCheck;
          });
      } else {
        this.operateTableRow(operate, row);
      }
      return isCheck;
    },
    getPages(p) {
      if (!p) {
        p = {};
      }
      p.isbau = !this.isEvent;
      return getCategoryList(p).then((data) => {
        console.log(data);
        data.data.rows.forEach((c) => {
          c.isSave = false;
        });
        return data;
      });
    },
    onAddShow(row) {
      if (row.item.isSave) {
        return true;
      }
      return false;
    },
    operateTableRow(operate, row) {
      switch (operate) {
        case this.operateEmnum.Add:
          if (
            this.$refs.selectableTable.items.filter((c) => c.isSave).length > 0
          ) {
            break;
          }
          if (this.newEditData != {}) {
            this.newEditData.isSave = false;
            this.newEditData.title = this.lastEditData.title;
            this.newEditData.order = this.lastEditData.order;
            this.onAddClick();
          } else {
            this.onAddClick();
          }
          break;
        case this.operateEmnum.Edit:
          if (this.newEditData != {}) {
            this.newEditData.isSave = false;
            this.newEditData.title = this.lastEditData.title;
            this.newEditData.order = this.lastEditData.order;
          }
          this.onEditClick(row);
          return true;
        default:
          break;
      }
    },
    async onAddClick() {
      let isValidname = true;
      let isValidOrderIndex = true;
      let isHaveAdd = false;
      this.$refs.selectableTable.getAllRows().forEach((row) => {
        if (!row.id) {
          isHaveAdd = true;
        }
      });
      if (isHaveAdd) {
        isValidname = await this.$refs["observername"].validate();
        isValidOrderIndex = await this.$refs["observerorderIndex"].validate();
        return;
      }
      let tableItem = {
        id: "",
        title: "",
        order: "",
        isSave: true,
      };
      this.newEditData = {};
      this.lastEditData = {};
      this.$refs.selectableTable.addNewRow(tableItem, true);
    },
    onEditClick(row) {
      this.lastEditData = JSON.parse(JSON.stringify(row.item));
      this.newEditData = row.item;
      row.item.isSave = true;
    },
    async onSaveClick(row) {
      let isValidname = true;
      let isValidOrderIndex = true;
      isValidname = await this.$refs[`observername${row.item.id}`].validate();
      isValidOrderIndex = await this.$refs[
        `observerorderIndex${row.item.id}`
      ].validate();
      if (isValidname && isValidOrderIndex) {
        let pmOpt = this.$confirm({
          title: this.$g("const.confirm.confirm"),
          content: this.$g("const.confirm.save"),
          cancelType: "secondary",
          okText: this.$g("const.confirm.saveYes"),
          cancelText: this.$g("const.confirm.no"),
        });
        if (pmOpt == null) {
          return;
        }
        pmOpt.then(() => {
          this.saveFunc(row);
        });
      }
    },
    saveFunc(row) {
      let isAdd = row.item.id ? false : true;
      this.form.id = row.item.id;
      this.form.title = row.item.title;
      this.form.order = row.item.order;
      this.saveCategory(this.form, isAdd).then((m) => {
        if (m.data.status == repResult.success) {
          this.$refs.selectableTable.pageChange(1);
          row.item.isSave = false;
          this.lastEditData = this.newEditData;
          this.$refs.lblMsg.message(m.data.status, m.message);
        } else {
          this.$refs.lblMsg.message(m.data.status, m.message);
        }
      });
    },
    saveCategory(params, isAdd) {
      if (isAdd) {
        return addCategory(params);
      }
      return updateCategory(params);
    },
    onCancelClick(row) {
      this.newEditData = this.lastEditData;
      if (row.item.id) {
        let itemBeforeEdit = this.itemsBeforeEdit.find(
          (e) => e.id == row.item.id
        );
        row.item.title = itemBeforeEdit.title;
        row.item.order = itemBeforeEdit.order;
        row.item.isSave = false;
        return;
      }
      this.$refs.selectableTable.items.splice(row.index, 1);
    },
    onDeleteClick() {
      let pmOpt = this.$refs.selectableTable.confirmOpt(
        this.$g("const.confirm.delete")
      );
      if (pmOpt == null) {
        return;
      }
      pmOpt.then(() => {
        let sitems = this.$refs.selectableTable.getSelectedItems();
        let delIds = [];
        sitems.forEach(function (val) {
          delIds.push(val.id);
        });
        delCategory(delIds).then((m) => {
          this.$refs.selectableTable.refresh();
          this.$refs.lblMsg.message(m.data.status, m.message);
        });
      });
    },
    onTotalChanged(total) {
      this.totalCount = total;
    },
    // pageCondition() {
    //   return this.$refs.selectableTable.getSearchState();
    // },
    // getSearchState() {
    //   let condition = this.$refs.selectableTable.getCondition();
    //   return this.$refs.selectableTable.getSearchState(condition);
    // },
    getCondition() {
      return {};
    },
    search: function () {
      this.$refs.selectableTable.pageChange(1);
    },
    onColumnChanged() {
      this.$refs.selectableTable.clearFields();
      this.$refs.selectableTable.refresh();
    },
    async isCheckname(item) {
      if (this.isView) {
        return;
      }
      let para = {};
      para.name = item;
      para.isBau = !this.isEvent;
      if (this.newEditData != {}) {
        para.id = this.newEditData.id;
      }
      let result = null;
      await checkCategoryNameUsed(para).then(function (rep) {
        result = rep;
      });
      return result;
    },
    async isCheckorder(item) {
      if (this.isView) {
        return;
      }
      let para = {};
      para.order = item;
      para.isBau = !this.isEvent;
      if (this.newEditData != {}) {
        para.id = this.newEditData.id;
      }
      let result = null;
      await checkCategoryOrderUsed(para).then(function (rep) {
        result = rep;
      });
      return result;
    },
  },
};
</script>

<style>
.table-actions {
  margin-left: 10px;
}
</style>
