import { ErrorPage } from "fe-infrastractures";

const routes = [
  // {
  //   name: "redirect",
  //   path: "/redirect",
  //   meta: { title: "", requiresAuth: true },
  //   component: () => import("@/views/formdemo/redirect.vue")
  // },
  {
    name: "login",
    path: "/",
    meta: {
      title: "",
      pageTitle: "PollChief - Login",
      requiresAuth: true,
      anonymous: true
    },
    component: () => import("@/views/const/login.vue")
  },
  {
    name: "login1",
    path: "/login1",
    meta: {
      title: "",
      pageTitle: "PollChief - Login",
      requiresAuth: true,
      anonymous: true
    },
    component: () => import("@/views/const/login.vue")
  },

  {
    name: "send-code",
    path: "/send-code",
    meta: {
      title: "",
      pageTitle: "PollChief - Login",
      requiresAuth: true,
      anonymous: true
    },
    component: () => import("@/views/const/send-code.vue")
  },

  {
    name: "forget-username",
    path: "/forget-username",
    meta: {
      title: "",
      pageTitle: "PollChief - Login",
      requiresAuth: true,
      anonymous: true
    },
    component: () => import("@/views/const/forget-username.vue")
  },
  {
    name: "send-url-success",
    path: "/send-url-success",
    meta: { title: "", anonymous: true },
    component: () => import("@/views/const/send-url-success.vue")
  },

  {
    name: "input-code",
    path: "/input-code",
    meta: {
      title: "",
      pageTitle: "PollChief - Login",
      requiresAuth: true,
      anonymous: true
    },
    component: () => import("@/views/const/input-code.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: {
      title: "",
      pageTitle: "PollChief - Login",
      requiresAuth: true,
      anonymous: true
    },
    component: () => import("@/views/const/reset-password.vue")
  },

  {
    name: "submit-success",
    path: "/submit-success",
    meta: {
      title: "",
      pageTitle: "PollChief - Login",
      requiresAuth: true,
      anonymous: true
    },
    component: () => import("@/views/const/submit-success.vue")
  },
  {
    name: "validate-reset-password",
    path: "/validate-reset-password",
    meta: {
      title: "",
      pageTitle: "PollChief - Login",
      requiresAuth: true,
      anonymous: true
    },
    component: () => import("@/views/const/validate-reset-password.vue")
  },
  {
    name: "validate-reset-password-success",
    path: "/validate-reset-password-success",
    meta: {
      title: "",
      pageTitle: "PollChief - Login",
      requiresAuth: true,
      anonymous: true
    },
    component: () => import("@/views/const/validate-reset-password-success.vue")
  },

  {
    name: "aad-error",
    path: "/aad-error",
    meta: {
      title: "",
      pageTitle: "PollChief - Login",
      requiresAuth: true,
      anonymous: true
    },
    component: () => import("@/views/const/aad-error.vue")
  },

  {
    name: "empty",
    path: "/empty",
    meta: { title: "", anonymous: true },
    component: () => import("@/views/const/empty.vue")
  },
  {
    name: "urlAccessProhibit",
    path: "/url-access-prohibit",
    meta: {
      title: "urlAccessProhibit",
      pageTitle: "welcome",
      anonymous: true
    },
    route: true,
    component: () => import("@/views/const/urlAccessProhibit"),
  },
  {
    name: "unsubscribeEmail",
    path: "/unsubscribeEmail",
    meta: {
      title: "",
      pageTitle: "PollChief - Login",
      requiresAuth: false,
      anonymous: true
    },
    component: () => import("@/views/unsubscribeSetting/unsubscribeEmail.vue")
  },
  //   {
  //   name: "maindemo",
  //   path: "/finance/list",
  //   meta: { title: "", requiresAuth: true },
  //   component: () => import("@/views/campaign-finance/campaign-finance-list.vue")
  // },

  // {
  //   name: "maindemo",
  //   path: "/finance/add",
  //   meta: { title: "", requiresAuth: true },
  //   component: () => import("@/views/campaign-finance/report-add.vue")
  // },
  // {
  //   name: "maindemo",
  //   path: "/demo1",
  //   meta: { title: "", requiresAuth: true },
  //   component: () => import("@/views/demo/griddemoSlot.vue")
  // },
  // {
  //   name: "mapdemo",
  //   path: "/map",
  //   meta: { title: "", requiresAuth: true },
  //   component: () => import("@/views/election/home.vue")
  // },
  // {
  //     name: 'main',
  //     path: '/',
  //     meta: { title: '', requiresAuth: true },
  //     component: () => import('@/views/election')
  // },
  // {
  //     name: 'login',
  //     path: '/login',
  //     meta: { title: 'Login', requiresAuth: false },
  //     component: () => import('@/views/const/login')
  // },
  {
    name: "404",
    path: "*",
    meta: { title: "Error", requiresAuth: false },
    component: ErrorPage
  }
];
export default routes;
