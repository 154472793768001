import { render, staticRenderFns } from "./sendMessageDetail.vue?vue&type=template&id=f058586c&scoped=true"
import script from "./sendMessageDetail.vue?vue&type=script&lang=js"
export * from "./sendMessageDetail.vue?vue&type=script&lang=js"
import style0 from "./sendMessageDetail.vue?vue&type=style&index=0&id=f058586c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../fe-workerportal/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f058586c",
  null
  
)

export default component.exports