<template>
  <k-button
    v-bind="$props"
    v-on="listeners"
    @click="onClick"
    class="btn rounded-0"
  >
    <slot />
  </k-button>
</template>
<script>
import { BButton } from "bootstrap-vue";
import Button from "./button.vue";
import { pageNavUtil } from "../../utils/pageNavUtil";

const addedProps = {
  backRoute: {
    type: String,
    default() {
      return null;
    },
  },
  backQuery: {
    type: Object,
    default() {
      return null;
    },
  },
  backParams: {
    type: Object,
    default() {
      return null;
    },
  },
  useHisotryBack: {
    type: Boolean,
    default() {
      return false;
    },
  },
  expectListId: {
    type: String,
    default() {
      return null;
    },
  },
};
const btnProps = Object.assign({}, BButton.options.props, addedProps);
export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { BButton, Button },
  props: {
    ...btnProps,
  },
  computed: {
    listeners() {
      // eslint-disable-next-line no-unused-vars
      let { click, ...params } = this.$listeners;
      return params;
    },
  },
  methods: {
    onClick() {
      var backList = pageNavUtil.getRecentList(
        this.$store.getters.pageState,
        this.expectListId
      );
      if (
        this.useHisotryBack ||
        // eslint-disable-next-line no-prototype-builtins
        this.$route.query.hasOwnProperty("historyBack")
      ) {
        history.back();
        return;
      }
      if (this.backRoute) {
        let query = null;
        if (backList) {
          query = backList.pageAddtional.query;
        }
        if (!query) {
          query = {};
        }
        let params = this.backParams || {};
        Object.assign(query, this.backQuery, { restore: true });
        this.$router.push({
          name: this.backRoute,
          query: query,
          params: params,
        });
        return;
      }
      if (this.$route && this.$route.query.historyBack) {
        history.back();
        return;
      }
      if (backList) {
        let routeName = backList.pageAddtional.routeName;
        let query = backList.pageAddtional.query;
        if (!query) {
          query = {};
        }
        Object.assign(query, this.backQuery, { restore: true });
        this.$router.push({
          name: routeName,
          query: query,
        });
      }
    },
  },
};
</script>
