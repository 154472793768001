<template>
  <span
    v-b-tooltip.hover
    :title="$g('logout')"
  >
    <b-icon
      icon="power"
      @click="onLogout"
    />
  </span>
</template>
<script>
import {logout} from "fe-infrastractures";
export default {
  methods: {
    onLogout(){
      this.$confirm({
        title: this.$g("const.confirm.confirm"),
        content: this.$g("const.confirm.logOutComfirm"),
        cancelType: "secondary",
        okText: this.$g("const.confirm.yes"),
        cancelText: this.$g("const.confirm.no"),
      }).then((m) => {
        logout();
        this.$emit("onBeforeLogout", true);
        if (window.location.origin) {
          window.location.href = window.location.origin;
        } else {
          this.$router.push({ name: "login" });
        }
      });
    },
  },
};
</script>
