<template>
  <div class="backgroundGray">
    <KValidationObserver
      ref="observer"
      class="content-page"
    >
      <k-form-group
        label-class="require-mark"
        label-suffix="name"
        label-align-md="right"
        label-cols-md="3"
        content-cols-lg="6"
        content-cols-md="7"
      >
        <KValidationProvider
          :rules="{
            required: true,
            passiveMax: { length: 200 },
            remote: isReportTemplateNameUsed,
          }"
          v-slot="{ errors }"
          slim
          name="name"
        >
          <k-alert
            :show="errors.length > 0"
            variant="warning"
          >
            {{ errors[0] }}
          </k-alert>
          <k-form-input
            id="input-code"
            v-model="form.tempName"
            :plaintext="false"
          />
        </KValidationProvider>
      </k-form-group>
      <k-form-group
        label-suffix="description"
        label-align-md="right"
        label-cols-md="3"
        content-cols-lg="6"
        content-cols-md="7"
      >
        <KValidationProvider
          :rules="{
            max: 2000,
          }"
          v-slot="{ errors }"
          slim
          name="description"
        >
          <k-alert
            :show="errors.length > 0"
            variant="warning"
          >
            {{ errors[0] }}
          </k-alert>
          <k-form-textarea
            v-model="form.description"
            :plaintext="false"
          />
        </KValidationProvider>
      </k-form-group>
    </KValidationObserver>
  </div>
</template>

<script>
import { checkReportTemplateName } from "../api/report";

export default {
  props: {
    fromReportTemplateId: {
      type: String,
      default(){
        return "";
      },
    },
  },
  data(){
    return {
      form: {
        fromReportTemplateId: this.fromReportTemplateId,
        tempName: "",
        description: "",
      },
    };
  },
  methods: {
    async isReportTemplateNameUsed(){
      let result = null;
      let para = {};
      para.name = this.form.tempName;
      para.id = this.$route.query.id ? this.form.fromReportTemplateId : null;
      await checkReportTemplateName(para).then((rep) => {
        result = rep;
      });
      return result;
    },
    checkValid(){
      return this.$refs.observer.validate();
    },
    getFormData(){
      return this.form;
    },
  },
};
</script>

