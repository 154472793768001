import http from "../../node_modules/fe-infrastractures/src/utils/http";

const isUseEoLinker = false;

export const getPortalPageSetting = (data) => {
  return http.post("/anon/portal-page/setting", { pageName: data }, {
    loading: false,
    useBody: true
  });
};

export const getIsHideConactUs = () => {
  return http.get("/anon/portal-page/is-hide-conact-us");
};

export const getUpcommingElectionList = (data) => {
  return http.get("/api/home/upcomming-elections", data, { useEoLinker: isUseEoLinker });
};

export const getOutstandingTrainingList = (data) => {
  return http.get("/api/home/outstanding-training", data, { useEoLinker: isUseEoLinker });
};

export const getNewWorkAssignmentsList = (data) => {
  return http.get("/api/home/new-work-assignments", data, { useEoLinker: isUseEoLinker });
};

export const apply = (data) => {
  return http.post("/api/home/save-apply", data, { loading: false, useBody: true, useEoLinker: isUseEoLinker });
};

export const getWorkerTravelsList = (data) => {
  return http.get("/api/lists/worker-travels", data, { useEoLinker: isUseEoLinker });
};

export const getWorkerTravelsListV2 = (data) => {
  return http.get("anon/worker-travels", data, { useEoLinker: isUseEoLinker });
};

export const getPositionPreferenceList = (data) => {
  return http.get("/api/lists/positions/active", data, { useEoLinker: isUseEoLinker });
};

export const getPositionPreferenceListV2 = (data) => {
  return http.get("anon/positions/active", data, { useEoLinker: isUseEoLinker });
};

export const getElectionEarlyDayList = (data) => {
  return http.get("/anon/election/early-day?electionId=" + data);
};

export const getPersonLanguageList = (data) => {
  return http.get("/anon/language/personlist", data, {});
};

export const getApplyLanguages = (data) => {
  return http.get("/api/home/get-apply-Languages", data, {});
};

export const existsWorker = () => {
  return http.get('/api/home/existsWorker');
};

export const checkIsStudent = (data) => {
  return http.get("/api/home/check-is-student", data, { useEoLinker: isUseEoLinker });
};

export const getYearOfBirthOptions = (data) => {
  return http.get("/anon/home/yearofbirth", data, { loading: true });
};

export const getApplyworker = (data) => {
  return http.get("api/home/get-apply", data, { loading: true });
};

export const getWorkerTypes = (data) => {
  return http.get("api/lists/worker-types", data, { loading: true });
};

export const checkEmailAddressUsed = (data) => {
  return http.post(`api/home/check-email-used`, data, { useBody: true, loading: false });
};

export const getVolunteerWithWorkerType = (data) => {
  return http.get(`/api/home/getvolunteer`, data, {});
};
export const getRequiredDocumens = (data) => {
  return http.get(`api/required-documens-portal/home`, data, { loading: true });
};

export const getWorkerTypeUpload = () => {
  return http.get("/api/home/get-worker-type-upload", null, { loading: false });
};


export const uploadFile = (data) => {
  return http.post("api/docs", data, {
    loading: false,
    useBody: true,
  });
};

export const multiUploadFile = (data) => {
  return http.post("api/docs/multi", data, {
    loading: false,
    useBody: true,
  });
};
