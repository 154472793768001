<template>
  <div>
    <b-tabs v-model="tabindex">
      <b-tab
        v-for="(item, index) in items"
        :title="item.title"
        :key="index"
        @click="clickTab(item, index)"
      />
    </b-tabs>
  </div>
</template>
<script>
import { templateNavAllTabs } from "./templateTabs";
import { getTabIndexByRouteName, handleTabs } from "../../../utils/permission/permission";

export default {
  data(){
    return {
      tabindex: 0,
      items: [],
      permissionCode: this.$route.meta.permissionCode,
    };
  },
  created(){
    this.items = handleTabs(templateNavAllTabs(this.isEvent, this.isPlainText, this.isSystem, this.permissionCode), this);
    this.tabindex = getTabIndexByRouteName(this.items, this);
  },
  methods: {
    clickTab(item){
      this.$router.push({
        name: item.route,
        query: {
          id: this.$route.query.id,
          isView: this.isPlainText ? "1" : "0",
          tabindex: this.$route.query.tabindex,
          isSystem: this.isSystem ? "1" : "0",
        },
      });
    },
  },
  watch: {
    isPlainText(){
      this.items = handleTabs(templateNavAllTabs(this.isEvent, this.isPlainText, this.isSystem, this.permissionCode), this);
    },
  },
  props: {
    isEvent: {
      type: Boolean,
      default(){
        return false;
      },
    },
    isPlainText: {
      type: Boolean,
      default(){
        return false;
      },
    },
    isSystem: {
      type: Boolean,
      default(){
        return false;
      },
    },
  },
};
</script>

