<template>
  <div :id="id" style="width: 100%; height: 100%" />
</template>

<script>
import * as echarts from "echarts";
import { integer } from "vee-validate/dist/rules";

export default {
  props: {
    colorConfig: {
      type: Array,
      default() {
        return null;
      },
    },
    autoBind: {
      type: Boolean,
      default() {
        return true;
      },
    },
    itemsPie: {
      type: Array,
      default() {
        return [];
      },
    },
    chartData: {
      type: Function,
      default() {
        return null;
      },
    },
    getCondition: {
      type: Object,
      default() {
        return null;
      },
    },
    chartType: {
      type: String,
      default() {
        return "bar";
      },
    },
    title: {
      type: Object,
      default() {
        return {};
      },
    },
    textFontSize: {
      type: Number,
      default() {
        return 16;
      },
    },
    subTextFontSize: {
      type: Number,
      default() {
        return 16;
      },
    },
    pieLegendTop: {
      type: Number,
      default() {
        return 50;
      },
    },
    pieLegendLeft: {
      type: Number,
      default() {
        return 100;
      },
    },
    pieLegendIcon: {
      type: String,
      default() {
        return "rect";
      },
    },
    pieLegendItemHeight: {
      type: Number,
      default() {
        return 14;
      },
    },
    pieRadius: {
      type: String | Number,
      default() {
        return "50%";
      },
    },
    pieCenter: {
      type: Array,
      default() {
        return ["50%", "50%"];
      },
    },
    showPieLegend: {
      type: Boolean,
      default() {
        return true;
      },
    },
    dataSource: {
      type: Array,
      default() {
        return [];
      },
    },
    dimensions: {
      type: Array,
      default() {
        return [];
      },
    },
    modal: {
      type: Number,
      default() {
        return 1;
      },
    },
    pieLabelFormatter: {
      type: String,
      default() {
        return "{b}: {d}%";
      },
    },
  },
  data() {
    return {
      id: "chart" + this._uid,
      colorConfigDefault: [
        "#5470c6",
        "#91cc75",
        "#fac858",
        "#ee6666",
        "#73c0de",
        "#749f83",
        "#3ba272",
        "#fc8452",
        "#9a60b4",
        "#ea7ccc",
        "#c4ccd3",
      ],
      optionChart: null,
      res: null,
    };
  },
  methods: {
    async initChart() {
      if (this.modal == 2 && this.dataSource.length == 0) {
        return;
      }
      if (this.autoBind) {
        await this.chartData(this.getCondition).then((res) => {
          this.res = res.data;
        });
      } else if (this.itemsPie.length > 0) {
        this.res = this.itemsPie;
      }
      let $this = this;
      let color;
      if (this.colorConfig) {
        color = this.colorConfig;
      } else {
        color = this.colorConfigDefault;
      }
      let myChart = echarts.init(document.getElementById(this.id));
      myChart.clear();
      if (this.chartType == "pie") {
        this.optionPie(this.res, color);
      }
      if (this.chartType == "bar") {
        this.optionBar(this.res, color);
      }
      if (this.chartType == "datasetBar") {
        this.optionDatasetBar(this.res, color);
      }
      myChart.setOption(this.optionChart);
      window.addEventListener("resize", () => {
        myChart.resize();
      });
      myChart.on("click", function (params) {
        $this.$emit("echartClick", params);
      });
    },
    optionBar(res, colorConfig) {
      this.optionChart = {
        grid: {
          top: 50,
          left: 50,
          right: 50,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        xAxis: [{ ...res.xAxis }],
        yAxis: [{ ...res.yAxis }],
        series: [
          {
            ...res.series[0],
            itemStyle: {
              color: function (params) {
                let conut = params.dataIndex % colorConfig.length;
                return colorConfig[conut];
              },
            },
          },
        ],
      };
    },
    optionDatasetBar(res, colorConfig) {
      let series = colorConfig.map((col) => ({
        type: "bar",
        color: col,
      }));
      this.optionChart = {
        legend: {
          orient: "horizontal",
          selectedMode: false,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        dataset: {
          dimensions: this.dimensions,
          source: this.dataSource,
        },
        xAxis: { type: "category", triggerEvent: true, index: 1 },
        yAxis: {},
        series: series,
      };
    },
    optionPie(res, colorConfig) {
      let total = 0;
      res.forEach((e) => {
        total += e.value;
      });
      this.optionChart = {
        title: {
          text: this.title.text ? this.title.text : "",
          subtext: this.title.subtext ? this.title.subtext + ": " + total : "",
          left: "center",
          top: window.innerHeight < 768 ? 10 : 50,

          textStyle: {
            fontSize: this.textFontSize,
            color: "#333333",
          },
          subtextStyle: {
            fontSize: this.subTextFontSize,
            color: "#333333",
            fontWeight: "bold",
          },
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: "vertical",
          left: this.pieLegendLeft,
          top: this.pieLegendTop,
          selectedMode: false,
          icon: this.pieLegendIcon,
          itemHeight: this.pieLegendItemHeight,
          show: this.showPieLegend,
        },
        series: [
          {
            type: "pie",
            center: this.pieCenter,
            radius: this.pieRadius,
            data: res,
            label: {
              show: this.pieLabelFormatter == "" ? false : true,
              formatter: this.pieLabelFormatter,
            },
            itemStyle: {
              color: function (params) {
                let conut = params.dataIndex % colorConfig.length;
                return colorConfig[conut];
              },
            },
          },
        ],
      };
    },
    refresh() {
      this.initChart();
    },
  },
  mounted() {
    this.initChart();
  },
};
</script>