<template>
  <b-alert
    v-bind="$props"
    v-on="$listeners"
  >
    <slot />
  </b-alert>
</template>

<script>
import { BAlert } from "bootstrap-vue";
const objProps = Object.assign({}, BAlert.options.props);
export default {
  components: {BAlert},
  props: {
    ...objProps,
  },
};
</script>