<template>
  <group-detail
    :is-event="isEvent"
    :plaintext="false"
    :id="id"
    @save-successful="onSaveSuccessful"
    ref="detail"
    :propPageConfigSystem="propPageConfigSystem"
  >
    <template #formBar>
      <div>
        <k-button-back
          ref="backBtn"
          :back-route="backRoute"
          :back-params="backParams"
          :back-query="backQuery"
        >
          buttons.back
        </k-button-back>
        <k-button v-if="id" id="btnView" @click="onRedirectToView">
          buttons.view
        </k-button>
        <k-button
          type="submit"
          variant="primary"
          class="mr-1"
          ref="btnSave"
          @click="onSave"
        >
          buttons.save
        </k-button>
        <k-pre-next
          ref="pn"
          :has-save-next="true"
          :before-to-leave="preNextToLeave"
        />
      </div>
    </template>
    <template #formBarNav>
      <k-alert-msg ref="lblMsg" />
      <group-nav
        :is-event="isEvent"
        ref="nav"
        :tab-index="navIndex"
        :is-plain-text="isPlainText"
      />
    </template>
  </group-detail>
</template>
<script>
import GroupNav from "../../components/groupNav";
import GroupDetail from "../../components/groupDetail";
import { repResult } from "../../../../libs/global";
import { saveAlert } from "../../../../utils/saveAlert";
import { beforeRouteLeaveMixin } from "../../../../utils/mixins/mixin";

export default {
  mixins: [beforeRouteLeaveMixin],
  components: { GroupDetail, GroupNav },
  props: {
    propPageConfigSystem: {
      type: String,
      default() {
        return null;
      }
    }
  },
  data() {
    let isEvent = this.$route.meta.isEvent;
    let backRoute = isEvent ? "eleMessageGroup" : "messageGroup";
    let otherBackRoute = this.$route.query.otherBackRoute;
    if (otherBackRoute) {
      backRoute = otherBackRoute;
    }
    return {
      isEvent: isEvent,
      id: this.$route.query.id,
      isPlainText: false,
      navIndex: 0,
      backRoute: backRoute,
      otherBackRoute: otherBackRoute,
      backParams: {
        tabindex: this.$route.query.tabindex
      },
      backQuery: {
        msgType: this.$route.query.msgType
      }
    };
  },
  methods: {
    onRedirectToView() {
      this.$router.push({
        name: this.isEvent ? "eleMessageGroupView" : "messageGroupView",
        query: {
          id: this.$route.query.id,
          isView: "1",
          hidePreNext: this.$route.query.hidePreNext,
          dbtn: this.$route.query.dbtn
        }
      });
    },
    onSaveSuccessful(m) {
      this.isDoSave = true;
      if (this.$refs.pn.checkSaveNexted()) {
        this.$refs.pn.goToNext(m);
      } else {
        if (this.otherBackRoute) {
          this.$refs.backBtn.onClick();
        } else {
          this.$router.push({
            name: this.isEvent ? "eleMessageGroupView" : "messageGroupView",
            query: {
              id: m.data.info,
              isView: "1",
              hidePreNext: this.$route.query.hidePreNext,
              dbtn: this.$route.query.dbtn
            },
            params: {
              message: m.message,
              status: m.data.status
            }
          });
        }
      }
    },
    showAlert() {
      let status = saveAlert.savedStatus(this.$route);
      if (status === repResult.success) {
        this.$refs.lblMsg.message(status, saveAlert.savedMessage(this.$route));
      }
    }
  },
  mounted() {
    this.showAlert();
  }
};
</script>
