<template>
  <b-input-group>
    <b-form-input
      v-bind="props"
      :placeholder="gPlaceholder"
      v-on="$listeners"
      :aria-label="ariaLabel"
      ref="theinput"
      :autocomplete="autocompleteStr"
      :id="id"
      :tabindex="tabIndex"
      :type="isShowPassword ? 'text' : 'password'"
    />
    <b-input-group-append
      is-text
      v-if="hasPasswordChangeBtn"
      class="passwordIcon"
      style="user-select: none"
    >
      <b-icon
        :icon="isShowPassword ? 'eye-fill' : 'eye-slash-fill'"
        @click="ssnIconClickEvent"
      />
    </b-input-group-append>
  </b-input-group>
</template>
<script>
import { BFormInput } from "bootstrap-vue";
import { g } from "../../locale/lang-val";
import storeage from "../../utils/storeage";

const addedProps = {
  maxLength: {
    type: Number,
    default() {
      return null;
    },
  },
  ariaLabel: {
    type: String,
    default() {
      return "input";
    },
  },
  id: {
    type: String,
    default: "",
  },
  tabIndex: {
    type: Number,
    default() {
      return 0;
    },
  },
  hasPasswordChangeBtn: {
    type: Boolean,
    default: true,
  },
};

const objProps = Object.assign({}, BFormInput.options.props, addedProps);
export default {
  model: {
    props: "value",
    event: "update",
  },
  components: { BFormInput },
  props: {
    ...objProps,
  },
  data() {
    return {
      isShowPassword: false,
    };
  },
  computed: {
    props() {
      let { placeholder, ...params } = this.$props;
      return params;
    },
    gPlaceholder() {
      return g(this.placeholder);
    },
    autocompleteStr() {
      if (storeage.getSystemGeneralInfo()?.allowBrowserAutoFill == 2) {
        return "";
      } else {
        if (!this.id) {
          return "off";
        }
        return "close";
      }
      //new-password
    },
  },
  mounted() {
    if (this.id != null && this.id.indexOf("search") != -1) {
      document.querySelectorAll("input").forEach((item) => {
        item.maxLength = 200;
      });
    }
    if (this.maxLength > 0) {
      this.$refs.theinput.$el.maxLength = this.maxLength;
    }
  },
  methods: {
    ssnIconClickEvent() {
      this.isShowPassword = !this.isShowPassword;
    },
  },
};
</script>
<style lang="scss" scoped>
.passwordIcon .input-group-text {
  background-color: var(--passwordIconBackground, #e9ecef) !important;
  border: 1px solid var(--passwordIconBorder, #ced4da) !important;
  .b-icon.bi {
    color: var(--passwordIconColor, #495057) !important;
  }
  &:hover {
    background-color: var(--passwordIconBackgroundActive, #e9ecef) !important;
    border: 1px solid var(--passwordIconBorderActive, #ced4da) !important;
    .b-icon.bi {
      color: var(--passwordIconColorActive, #495057) !important;
    }
  }
}
</style>