import { asyncRoutes, constantRoutes } from "fe-infrastractures";

function hasPermission(permissionList, route) {
  if (route.meta && route.meta.permissionCode) {
    return permissionList.some(
      code => route.meta.permissionCode.indexOf(code) >= 0
    );
  } else {
    return true;
  }
}

function filterAsyncRouter(asyncRoutes1, permissionList) {
  return asyncRoutes1.filter(route => {
    if (hasPermission(permissionList, route)) {
      if (route.children && route.children.length) {
        route.children = filterAsyncRouter(route.children, permissionList);
      }
      return true;
    }
    return false;
  });
}

const permission = {
  namespaced: true,
  state: {
    routers: constantRoutes,
    addRouters: []
  },
  mutations: {
    SET_ROUTERS: (state, routers) => {
      state.addRouters = routers;
      state.routers = constantRoutes.concat(routers);
      console.log("state.routers", state.routers);
    }
  },
  actions: {
    GenerateRoutes({ commit }, data) {
      return new Promise(resolve => {
        const { permissionList } = data;
        let accessedRouters;
        accessedRouters = filterAsyncRouter(asyncRoutes, permissionList);
        console.log("accessedRouters", accessedRouters);
        commit("SET_ROUTERS", accessedRouters);
        resolve();
      });
    }
  }
};

export default permission;
