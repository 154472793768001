<template>
  <k-container class="bv-example-row">
    <k-row cols="2">
      <template v-for="(item, index) in labels">
        <b-col :key="index">
          <k-form-checkbox
            no-locale
            v-model="item.checked"
            :key="item.value"
          >
            {{ item.displayName }}
          </k-form-checkbox>
        </b-col>
      </template>
    </k-row>
  </k-container>
</template>
<script>
import storeage from "../../../../../../utils/storeage";
import { getDynamicLabelSetting } from "../api/notification";

export default {
  props: {
    dynamicInsertLabels: {
      type: Array,
    },
    systemType: {
      type: Number,
      default: () => {
        return 0;
      },
    },
    optionType: {
      type: String,
      default: () => {
        return "0";
      },
    },
    notificationType: {
      type: Number,
      default: 1,
    },
  },
  created(){
    getDynamicLabelSetting({
      systemType: this.systemType,
      notificationType: this.notificationType,
    })
        .then((result) => {
          this.labels = result.data;
          this.dynamicInsertLabels.forEach((c) => {
            this.labels.push({ displayName: c.display, ...c });
          });
        })
        .catch((err) => {
        });
  },
  computed: {
    selectedVal(){
      let val = "";
      this.labels.forEach((element) => {
        if(element.checked){
          val += element.value;
          element.checked = false;
        }
      });
      return val;
    },
  },
  data(){
    return {
      dateFormat: storeage.getDateFormat(),
      labels: [],
    };
  },
};
</script>
