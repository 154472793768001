import { getGoogleMapKey } from "../api/googleMap";
import * as VueGoogleMaps from 'vue2-google-maps'

export const googleMapKey = {
  async install(Vue) {
    let {data}=await getGoogleMapKey(process.env.VUE_APP_SYSTEM_ID)
      Vue.use(VueGoogleMaps, {
      load: {
        key:data,
        libraries: 'geometry,places',
        language: 'en',
      },
    })
  }
}