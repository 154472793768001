import http from '../utils/http'

export const getBoundaryValues = (data, system = "boundaries") => {
    return http.get(`/api/${system}/boundary-default-values`, data)
}

export const getEleBoundaryValues = (data, system = "election-boundaries") => {
    return http.get(`/api/${system}/eleboundary-default-values`, data)
}

export const getStreetIndexValues = (data, isElection = false, system = "boundaries") => {

    if (isElection) {
        return http.post(`/api/${system}/street-index/election/default-values`, data, { loading: false })
    }

    return http.post(`/api/${system}/street-index/default-values`, data, { loading: false })
}

export const getModelType = (system = "boundaries", isFacility = false) => {
    return http.get(`/api/${system}/boundary-default-type`, {
        isFacility: isFacility
    })
}
