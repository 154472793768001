var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-tabs',{staticClass:"search-area",model:{value:(_vm.tabsIndex),callback:function ($$v) {_vm.tabsIndex=$$v},expression:"tabsIndex"}},[_c('k-alert-msg',{ref:"lblMsg",staticClass:"mt-1 mb-0"}),_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"tab-lable"},[_vm._v(" "+_vm._s(_vm.$g("search.tabs.template"))+" ")])]},proxy:true}])},[_c('div',{staticClass:"search-container"},[_c('b-overlay',{attrs:{"show":_vm.loading.template,"rounded":"sm"}},[(_vm.templateList.length <= 0)?_c('div',{staticClass:"noDataImg",staticStyle:{"height":"160px"}},[_c('div',{staticClass:"noDataFont"},[_vm._v(" "+_vm._s(_vm.$g("search.noDataText"))+" ")])]):_vm._e(),_vm._l((_vm.templateList),function(template){return _c('b-list-group-item',{key:template.id,staticClass:"d-flex justify-content-between align-items-center"},[_c('b-link',{staticClass:"ellipsis",attrs:{"type":"primary"},on:{"click":function($event){return _vm.templateChoose(template)}}},[_vm._v(" "+_vm._s(template.templateName)+" ")]),(!template.usedInWidget)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
              placement: 'bottomleft',
              trigger: 'hover',
              title: _vm.$g('search.delete'),
              boundary: 'window',
              container: 'tipSearch_' + template.id,
            }),expression:"{\n              placement: 'bottomleft',\n              trigger: 'hover',\n              title: $g('search.delete'),\n              boundary: 'window',\n              container: 'tipSearch_' + template.id,\n            }"}],attrs:{"id":'tipSearch_' + template.id}},[_c('b-icon',{staticClass:"template-delete",attrs:{"icon":"trash"},on:{"click":function($event){return _vm.templateChooseDeleteHandler(template)}}})],1):_vm._e()],1)})],2),_c('b-modal',{attrs:{"hide-footer":"","dialog-class":"confirm-dialog","no-close-on-backdrop":true},model:{value:(_vm.deleteDialogVisible),callback:function ($$v) {_vm.deleteDialogVisible=$$v},expression:"deleteDialogVisible"}},[_c('b-overlay',{attrs:{"show":_vm.loading.deleting,"rounded":"sm"}},[_c('p',[_vm._v(_vm._s(_vm.$g("search.confirmDelete")))]),_c('div',{staticClass:"text-right search-dialog-footer"},[_c('k-button',{staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.deleteTemplateHandler()}}},[_vm._v(" search.yes ")]),_c('k-button',{on:{"click":function($event){_vm.deleteDialogVisible = false}}},[_vm._v(" search.no ")])],1)])],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }