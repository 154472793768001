<template>
  <div>
    <div class="col-form-label">
      <div class="d-flex justify-content-between mb-2">
        <div class="d-flex">
          <span class="iconSpan mr-2">
            <b-icon
              icon="arrow-up-circle-fill"
              @click="arrowItem(true)"
              ref="arrowItemUp"
            />
            <b-tooltip
              :target="() => $refs['arrowItemUp']"
              :title="$g('up')"
              boundary="window"
              :container="uid"
              placement="bottomleft"
              triggers="hover"
            />
            <div :id="uid" />
          </span>
          <span class="iconSpan">
            <b-icon
              icon="arrow-down-circle-fill"
              @click="arrowItem(false)"
              ref="arrowItemDown"
            />
            <b-tooltip
              :target="() => $refs['arrowItemDown']"
              :title="$g('down')"
              boundary="window"
              :container="uid_1"
              placement="bottomleft"
              triggers="hover"
            />
            <div :id="uid_1" />
          </span>
        </div>
        <div class="d-flex">
          <b-icon-add @click="addFieldHandle" class="mr-2" />
          <b-icon-delete @click="delFieldHandle" />
        </div>
      </div>
      <KonnFixedGrid
        ref="selectableTable"
        select-mode="multi"
        :has-check-box="true"
        :has-action="false"
        :sticky-header="true"
        class="stickyTable table-bordered border-0"
        :fields="fields"
        :original-items="value"
      >
        <template #cell(needSum)="row">
          <k-form-checkbox
            v-show="row.item.isNeedSum"
            v-model="row.item.needSum"
          />
        </template>
        <template #cell(listOrder)="row">
          <k-form-checkbox v-model="row.item.listOrder" />
        </template>
      </KonnFixedGrid>
    </div>
    <k-popwindow
      id="selectFieldsPopWindow"
      :title="$g('popwindow.field')"
      @ok="onPopSelected"
    >
      <SelectFields
        ref="selectFields"
        :report-template-id="reportTemplateId"
        :excepted-report-field-names="exceptedReportFieldNames"
      />
    </k-popwindow>
  </div>
</template>

<script>
import KonnFixedGrid from "../../konn-grid/konnFixedGrid";
import SelectFields from "./SelectFields";

export default {
  data() {
    return {
      exceptedReportFieldNames: [],
      fields: [
        {
          key: "displayName",
          label: "displayFields.fieldName",
          sortable: false,
          width: "30%",
        },
        {
          key: "description",
          label: "source",
          sortable: false,
          width: "30%",
        },
        {
          key: "needSum",
          label: "needSum",
          sortable: false,
          width: "20%",
        },
        {
          key: "listOrder",
          label: "listOrder",
          sortable: false,
          width: "20%",
        },
      ],
      uid: "up" + this._uid,
      uid_1: "down" + this._uid,
    };
  },
  props: {
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    reportTemplateId: {
      type: String,
      default() {
        return "";
      },
    },
  },
  components: {
    KonnFixedGrid,
    SelectFields,
  },
  methods: {
    arrowItem(isUp) {
      if (this.$refs.selectableTable.checkSingleSelected()) {
        if (isUp) {
          this.$refs.selectableTable.moveUpOneItem(
            this.$refs.selectableTable.getRowIndex(
              this.$refs.selectableTable.getSelectedItems()[0]
            )
          );
        } else {
          this.$refs.selectableTable.moveDownOneItem(
            this.$refs.selectableTable.getRowIndex(
              this.$refs.selectableTable.getSelectedItems()[0]
            )
          );
        }
      }
    },
    addFieldHandle() {
      let rows = this.$refs.selectableTable.getAllRows();
      this.exceptedReportFieldNames = [];
      rows.forEach((m) => {
        this.exceptedReportFieldNames.push(m.fieldName);
      });
      this.$bvModal.show("selectFieldsPopWindow");
    },
    delFieldHandle() {
      if (this.$refs.selectableTable.checkSelected()) {
        this.$refs.selectableTable.deleteSelected();
      }
    },
    async onPopSelected() {
      let selectedItems = this.$refs.selectFields.getSelectedItems();
      if (selectedItems.length) {
        selectedItems.forEach((item) => {
          item.needSum = item.isNeedSum;
          item.listOrder = false;
          this.$refs.selectableTable.addNewRow(item, true);
        });
      }
      this.$emit("fields-selected");
    },
  },
};
</script>
