<template>
  <div class="content-page">
    <election-select v-if="isEvent" />
    <div class="content">
      <div
        class="right-bar"
        v-show="seenSearch"
      >
        <b-icon
          icon="x"
          @click="onToggleSearch"
        />
        <search-area
          ref="searchArea"
          v-if="showThisSearchArea"
          type-name="CommunicationGroup"
          :search-click="search"
          :clear-click="clear"
          :get-paras="getCondition"
          :set-paras="setParas"
          :fields="fields"
        >
          <k-form-group label-suffix="search.groupName">
            <k-form-input v-model="queryParams.groupName" />
          </k-form-group>
        </search-area>
      </div>
      <div class="center-bar">
        <div class="nav-btn-group">
          <k-button-group />
          <b-icon-search-area
            @click="onToggleSearch"
            :class="{ active: isActive }"
          />
          <KWikiHelp :props-wiki-help-code="wikiHelpCode" />
        </div>

        <div class="content-main">
          <div class="content-navTab">
            <div class="nav-tools">
              <b-icon-add
                v-if="isAdd"
                @click="addGroup"
              />
              <b-icon-delete
                v-if="isDel"
                @click="deleteGroup"
              />
              <column-config-icon
                :list-id="isEvent ? 'elemsggrouplist' : 'msggrouplist'"
                @column-changed="onColumnChanged"
              />
            </div>
            <div>
              <b-tabs>
                <b-tab>
                  <template #title>
                    {{ $g("tabs.home.groups") }}
                    <span class="text-red">({{ totalCount }})</span>
                  </template>
                </b-tab>
              </b-tabs>
            </div>
          </div>
          <k-alert-msg ref="lblMsg" />
          <div class="content-party">
            <konn-grid
              ref="selectableTable"
              :data-source="dataSource"
              :get-condition="getCondition"
              :has-action="isEdit"
              @edit-clicked="onEditClicked"
              :class="{ 'table-show-search-area': showThisSearchArea }"
              @fields-get="onFieldsGet"
              :list-id="isEvent ? 'elemsggrouplist' : 'msggrouplist'"
              :set-paras="setParas"
              @total-changed="onTotalChanged"
              grid-sort-by="groupName"
              :sticky-header="true"
              class="stickyTable"
              :auto-bind="false"
            >
              <template #cell(groupName)="row">
                <k-link @click="goNext(row)">
                  {{ row.value }}
                </k-link>
              </template>
              <template #cell(numberOfMatching)="row">
                <k-link @click="goNextList(row)">
                  {{ row.value }}
                </k-link>
              </template>
            </konn-grid>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getGroupList, delGroup } from "../../api/messageGroup";
import { repResult } from "../../../../libs/global";
import { saveAlert } from "../../../../utils/saveAlert";
import { has } from "../../../../utils/permission/operatePermission";
import storeage from "../../../../utils/storeage";
import { permissionCode } from "../../mixins/permissionCode";

export default {
  mixins: [permissionCode],
  data() {
    let isEvent = this.$route.meta.isEvent;
    return {
      isEvent: isEvent,
      queryParams: {
        electionId: isEvent ? storeage.getElectionId() : "",
        groupName: "",
      },
      keyword: "",
      selected: [],
      showThisSearchArea: true,
      typeName: isEvent ? "EleGroup" : "Group",
      fields: [],
      seenSearch: true,
      isActive: true,
      totalCount: 0,
      has,
      isAdd: true,
      isEdit: true,
      isDel: true,
      wikiHelpCode: isEvent
        ? this.$wikiHelpLinkCodes?.Event.Communication.MessageGroup.List
        : this.$wikiHelpLinkCodes?.BAU.Communication.MessageGroup.List,
    };
  },
  components: {},
  computed: {
    dataSource: function () {
      return getGroupList;
    },
    exportHandler: function () {
      return null;
    },
  },
  methods: {
    setPermission() {
      if (!this.isPermissionCode) {
        this.isAdd = true;
        this.isEdit = true;
        this.isDel = true;
        return;
      }
      if (this.isEvent) {
        this.isAdd = has(this.permissionCodeEvent?.Communication.GroupsAdd);
        this.isEdit = has(this.permissionCodeEvent?.Communication.GroupsEdit);
        this.isDel = has(this.permissionCodeEvent?.Communication.GroupsDelete);
      } else {
        this.isAdd = has(this.permissionCode?.Communication.GroupsAdd);
        this.isEdit = has(this.permissionCode?.Communication.GroupsEdit);
        this.isDel = has(this.permissionCode?.Communication.GroupsDelete);
      }
    },
    goNext(row) {
      this.$router.push({
        name: this.isEvent ? "eleMessageGroupView" : "messageGroupView",
        query: {
          id: row.item.id,
          dbtn: "1",
        },
      });
    },
    goNextList(row) {
      this.$router.push({
        name: this.isEvent
          ? "eleMessageGroupMatchingList"
          : "messageGroupMatchingList",
        query: {
          id: row.item.id,
          type: row.item.groupType,
        },
      });
    },
    checkHasData() {
      if (!this.totalCount) {
        this.$refs.lblMsg.message(
          repResult.warning,
          this.$g("const.confirm.exportNoData")
        );
        return false;
      } else {
        return true;
      }
    },
    getSearchState() {
      let condition = this.$refs.selectableTable.getCondition();
      return this.$refs.selectableTable.getSearchState(condition);
    },
    getCondition() {
      this.queryParams.dynamicConditions =
        this.$refs.searchArea.getDynamicConditions();
      return this.queryParams;
    },
    onTotalChanged(total) {
      this.totalCount = total;
    },
    addGroup() {
      this.$router.push({
        name: this.isEvent ? "eleMessageGroupAdd" : "messageGroupAdd",
        query: {
          hidePreNext: "1",
          isView: "0",
          dbtn: "1",
        },
      });
    },
    deleteGroup() {
      let pmOpt = this.$refs.selectableTable.confirmOpt(
        this.$g("const.confirm.delete")
      );
      if (pmOpt == null) {
        return;
      }
      pmOpt.then(() => {
        let sitems = this.$refs.selectableTable.getSelectedItems();
        let delIds = [];
        sitems.forEach(function (val) {
          delIds.push(val.id);
        });
        delGroup(delIds).then((m) => {
          this.$refs.selectableTable.refresh();
          this.$refs.lblMsg.message(m.data.status, m.message);
        });
      });
    },
    onEditClicked: function (item) {
      this.$router.push({
        name: this.isEvent ? "eleMessageGroupEdit" : "messageGroupEdit",
        query: {
          id: item.id,
          isView: "0",
          dbtn: "1",
        },
      });
    },
    onFieldsGet(fields) {
      this.fields = fields;
    },
    search: function () {
      this.$refs.selectableTable.pageChange(1);
    },

    setParas(para) {
      if (!para) {
        para = { groupName: "" };
      }
      this.queryParams.groupName = para.groupName;
    },
    clear() {
      this.setParas(null);
    },
    onToggleSearch() {
      this.seenSearch = !this.seenSearch;
      this.isActive = !this.isActive;
    },
    onColumnChanged() {
      this.$refs.selectableTable.clearFields();
      this.$refs.searchArea.clearHandler();
    },
    showAlert() {
      let status = saveAlert.savedStatus(this.$route);
      if (status === repResult.success) {
        this.$refs.lblMsg.message(status, saveAlert.savedMessage(this.$route));
      }
    },
  },
  mounted() {
    this.showAlert();
    this.setPermission();
  },
};
</script>
