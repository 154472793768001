import {operatePermission} from 'fe-infrastractures'
export default {
  inserted(el, binding, vnode) {
    const {
      value
    } = binding
    let temps = operatePermission.getPermission()
    const codes =  temps&& temps.split(",")||[]
    if (value) {

      const permissionCodes = value instanceof Array && value.length > 0 ? value : [value]
      const hasPermission = codes.some(code => {
        return permissionCodes.includes(code)
      })

      if (!hasPermission) {
        el.parentNode && el.parentNode.removeChild(el)
      }
    } else {
      return true
    }
  }
}