import http from '../../../utils/http'

export const postCategoryPage = (data) => {
  return http.post(`/api/msgtemplate/category/page`, data)
}
export const addCategory = (data) => {
  return http.post(`/api/msgtemplate/category`, data, {
    headers: { 'Content-Type': 'application/json-patch+json' },
  })
}
export const deleteCategory = (data) => {
  return http.delete(`/api/msgtemplate/category`, data)
}
export const updateCategory = (data) => {
  return http.put(`/api/msgtemplate/category`, data, {
    headers: { 'Content-Type': 'application/json-patch+json' },
  })
}
export const getCategory = (id) => {
  return http.get(`/api/msgtemplate/category/${id}`)
}
export const getCheckCategoryUsed = (data) => {
  return http.post(`/api/msgtemplate/actions/check-category-used`, data, {
    loading: false,
    useBody: true,
  })
}
