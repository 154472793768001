<template>
  <div>
    <div
      v-if="isShow"
      class="mt-3 pl-0 file-group-width"
    >
      <b-list-group>
        <b-list-group-item
          class="d-flex justify-content-between align-items-center"
          v-for="(file, index) in files"
          :key="index"
        >
          <k-link
            v-if="file.documentId"
            @click="downloadPath(file.documentId)"
          >
            {{ file.name ? file.name : file.fileName }}
          </k-link>
          <div v-else>
            {{ file.name ? file.name : file.fileName }}
          </div>
          <span
            :id="'tipUpload_' + index"
            v-b-tooltip="{
              placement: 'bottomleft',
              trigger: 'hover',
              title: $g('icon.deleteToolTip'),
              boundary: 'window',
              container: 'tipUpload_' + index,
            }"
            v-if="!plaintext"
          >
            <b-icon
              icon="trash"
              @click="deleteFile(index)"
            />
          </span>
        </b-list-group-item>
      </b-list-group>
    </div>
    <div
      v-else
      class="mt-3"
    />
  </div>
</template>
<script>
import { repResult } from "../../../libs/global";
import {
  downloadDocumentUrl,
  isExit,
} from "../../documentation/api";

export default {
  data(){
    return {
      files: [], //files used in common upload
    };
  },

  props: {
    downloadParams: {
      type: Object,
      default: null,
    },
    fileList: {
      // inputfile from form-file
      type: Array,
      default(){
        return [];
      },
    },
    isShow: Boolean,
    plaintext: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    downloadPath(documentId){
      let model = {
        DocumentId: documentId,
        ...this.downloadParams,
      };
      isExit(model).then((m) => {
        if(m.data.status === repResult.success){
          downloadDocumentUrl(model);
        } else {
          this.$emit("show-message", m.data.status, m.message);
        }
      });
    },
    deleteFile(index){
      let file = this.files[index];
      this.files.splice(index, 1);
      this.$emit("onDelete", file);
    },
    addFile(file){
      this.files.push(file);
    },
    clear(){
      this.files.splice(0, this.files.length);
    },
    clearAddFile(file){
      this.clear();
      this.addFile(file);
    },
    getFiles(){
      return this.files;
    },
    getUploadFiles(){
      let uploadFile = [];
      this.files.forEach((file) => {
        if(file instanceof File){
          uploadFile.push(file);
        }
      });
      return uploadFile;
    },
    initFiles(files){
      this.files = files;
    },

  },
  created(){
    this.files = [...this.files, ...this.fileList];
  },
};
</script>
