const statusList = [
  {
    "status": "messageStatus.sent",
    "description": "messageStatus.sentDescription",
  },
  {
    "status": "messageStatus.remoteHangUp",
    "description": "messageStatus.remoteHangUpDescription",
  },
  {
    "status": "messageStatus.success",
    "description": "messageStatus.successDescription",
  },
  {
    "status": "messageStatus.voicemail",
    "description": "messageStatus.voicemailDescription",
  },
  {
    "status": "messageStatus.answerMachine",
    "description": "messageStatus.answerMachineDescription",
  },
  {
    "status": "messageStatus.fax",
    "description": "messageStatus.faxDescription",
  },
  {
    "status": "messageStatus.duplicate",
    "description": "messageStatus.duplicateDescription",
  },
  {
    "status": "messageStatus.invalidPhone",
    "description": "messageStatus.invalidPhoneDescription",
  },
  {
    "status": "messageStatus.invalidEmail",
    "description": "messageStatus.invalidEmailDescription",
  },
  {
    "status": "messageStatus.noContact",
    "description": "messageStatus.noContactDescription",
  },
  {
    "status": "messageStatus.busyTone",
    "description": "messageStatus.busyToneDescription",
  },
  {
    "status": "messageStatus.noAnswer",
    "description": "messageStatus.noAnswerDescription",
  },
  {
    "status": "messageStatus.failed1",
    "description": "messageStatus.failed1Description",
  },
  {
    "status": "messageStatus.noAnswerRetry1",
    "description": "messageStatus.noAnswerRetry1Description",
  },
  {
    "status": "messageStatus.noAnswerRetry2",
    "description": "messageStatus.noAnswerRetry2Description",
  },
  {
    "status": "messageStatus.notSent",
    "description": "messageStatus.notSentDescription",
  },
  {
    "status": "messageStatus.accepted",
    "description": "messageStatus.acceptedDescription",
  },
  {
    "status": "messageStatus.queued",
    "description": "messageStatus.queuedDescription",
  },
  {
    "status": "messageStatus.sending",
    "description": "messageStatus.sendingDescription",
  },
  {
    "status": "messageStatus.delivered",
    "description": "messageStatus.deliveredDescription",
  },
  {
    "status": "messageStatus.undelivered",
    "description": "messageStatus.undeliveredDescription",
  },
  {
    "status": "messageStatus.failed2",
    "description": "messageStatus.failed2Description",
  },
  {
    "status": "messageStatus.queueOverflow",
    "description": "messageStatus.queueOverflowDescription",
  },
  {
    "status": "messageStatus.accountSuspended",
    "description": "messageStatus.accountSuspendedDescription",
  },
  {
    "status": "messageStatus.unreachableDestinationHandset",
    "description": "messageStatus.unreachableDestinationHandsetDescription",
  },
  {
    "status": "messageStatus.messageBlocked",
    "description": "messageStatus.messageBlockedDescription",
  },
  {
    "status": "messageStatus.unknownDestinationHandset",
    "description": "messageStatus.unknownDestinationHandsetDescription",
  },
  {
    "status": "messageStatus.landlineOrUnreachableCarrier",
    "description": "messageStatus.landlineOrUnreachableCarrierDescription",
  },
  {
    "status": "messageStatus.carrierViolation",
    "description": "messageStatus.carrierViolationDescription",
  },
  {
    "status": "messageStatus.unknownError",
    "description": "messageStatus.unknownErrorDescription",
  },
  {
    "status": "messageStatus.missingSegment",
    "description": "messageStatus.missingSegmentDescription",
  },
  {
    "status": "messageStatus.messagePriceExceedsMaxPrice",
    "description": "messageStatus.messagePriceExceedsMaxPriceDescription",
  },
  {
    "status": "messageStatus.doNotCall",
    "description": "messageStatus.doNotCallDescription",
  },
];
export default statusList;
