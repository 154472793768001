import { refreshToken, getUser, getSystem, getMenuTree } from "../../api/user";
import {
  operatePermission,
  storeage,
  setToken,
  removeToken,
  getToken,
} from "fe-infrastractures";
import store from "@/store";

const mState = {
  token: "",
  tokenKey: "",
  username: "",
  nickname: "",
  systems: [],
  menus: [],
  tenant: null,
  communicationIds: [],
  tenantkey: "",
  roletimestamp: "",
  // test:allCode.split(","),
  permissionList: [],
  locationNav: {},
};

const mutations = {
  RESET_USER (nstate) {
    Object.assign(nstate, mState);
  },
  SET_TOKEN (state, tokenInfo) {
    state.token = tokenInfo.token;
    state.tokenKey = tokenInfo.tokenKey;
  },
  SET_LOCATION_NAV (state, navInfo) {
    state.locationNav = navInfo;
  },
  SET_USER (state, userInfo) {
    state.username = userInfo.username;
    state.nickname = userInfo.nickname;
    state.tenantkey = userInfo.tenantkey;
    state.roletimestamp = userInfo.roletimestamp;
    state.permissionList = userInfo.permissionlist
      ? userInfo.permissionlist.split(",")
      : [];
  },
  SET_SYSTEMS (state, systems) {
    state.systems = systems || [];
  },
  SET_MENUS (state, menus) {
    state.menus = menus;
  },
  SET_COMMUNICATION_IDS (state, communicationIds) {
    state.communicationIds = communicationIds;
  },
  SET_TENANT (state, tenant) {
    state.tenant = tenant;
  },
};

const actions = {
  resetUser ({ commit }) {
    commit("RESET_USER");
  },
  saveToken ({ commit }, tokenInfo) {
    commit("SET_TOKEN", tokenInfo);
    setToken(tokenInfo);
  },
  logout ({ commit }) {
    return new Promise(resolve => {
      commit("SET_TOKEN", {});
      commit("SET_USER", {});
      removeToken();
      resolve();
      storeage.removeLocationAllNavs();
    });
  },
  refreshToken ({ commit, state }) {
    return refreshToken(getToken()?.tokenKey).then(res => {
      const { data } = res;
      const tokenInfo = { token: data.token, tokenKey: data.refreshToken };

      store.dispatch("logger/sys", {
        content: tokenInfo,
        description: "refresh token successfully",
      });
      commit("SET_TOKEN", tokenInfo);
      setToken(tokenInfo);
      return res;
    });
  },
  getUser ({ commit }) {
    return getUser().then(res => {
      const { data } = res;
      const userInfo = {
        username: data.username,
        nickname: data.nickname,
        tenantkey: data.tenantkey,
        roletimestamp: data.roletimestamp,
        permissionlist: data.permissionlist,
      };
      operatePermission.setPermission(data.permissionlist);
      storeage.setUserInfo(data);
      commit("SET_USER", userInfo);
      return data;
    });
  },
  getSystems ({ commit }) {
    return getSystem()
      .then(res => {
        const { data } = res;
        const systems = (data || []).map(e => {
          return {
            id: e.ID,
            code: e.SystemCode,
            icon: e.SystemIcon,
            name: e.SystemName,
            type: e.SystemType,
            typeText: e.SystemTypeStr,
            base: e,
          };
        });
        commit("SET_SYSTEMS", systems);
        return res;
      });
  },
  getMenus ({ commit }, mData) {
    const { systemId } = mData;
    return getMenuTree(systemId).then(res => {
      const { data } = res;
      const format_menu = menus => {
        return (menus || []).map(e => {
          return {
            icon: e.MenuIcon,
            label: e.MenuName,
            index: e.RoutePath,
            children: format_menu(e.ChildRen),
            base: e,
          };
        });
      };
      commit("SET_MENUS", format_menu(data));

      return res;
    });
  },
  setTenant ({ commit }, tenant) {
    commit("SET_TENANT", tenant);
  },
  setCommunicationIds ({ commit }, communicationIds) {
    commit("SET_COMMUNICATION_IDS", communicationIds);
  },
};

export default {
  namespaced: true,
  mState,
  mutations,
  actions,
};
