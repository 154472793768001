import http from "../../../utils/http";

export const getPageFieldsConfig = (para) => {
  return http.get("/api/page-fields-config/" + para.listId);
};

export const savePageFieldsConfig = (data) => {
  return http.post("/api/page-fields-config", data, { useBody: true });
};

export const getCandidatePageFieldsConfig = (para) => {
  return http.get("/api/candidate-page-fields-config/" + para.listId);
};

export const saveCandidatePageFieldsConfig = (data) => {
  return http.post("/api/candidate-page-fields-config", data, { useBody: true });
};
