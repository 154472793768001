import http from "../../../utils/http";

const isUseEoLinker = false;

export const getReportNavs = (isBau, electionId) => {
  return http.get("/api/report-category-common/nav?isBau=" + isBau + "&electionId=" + electionId, null, { useEoLinker: isUseEoLinker });
};

export const getReportTime = (reportId) => {
  return http.get("/api/report-detail-common/report-time/" + reportId, null, { useEoLinker: isUseEoLinker });
};


export const getBauReportPage = (data) => {
  return http.post("/api/report-common/bau-page", data, { useBody: true, useEoLinker: isUseEoLinker });
};

export const getEleReportPage = (data) => {
  return http.post("/api/report-common/event-page", data, { useBody: true, useEoLinker: isUseEoLinker });
};

export const getReportTemplatePage = (data) => {
  return http.post("/api/report-template-common/page", data, { useBody: true, useEoLinker: isUseEoLinker });
};

export const getSelectReportTemplatePage = (data) => {
  return http.post("/api/report-template-common/select-template-page", data, { useBody: true, useEoLinker: isUseEoLinker });
};

export const getTemplateLevelOptions = (data) => {
  return http.post("/api/report-template-common/template-level-drop", data, { useBody: true, useEoLinker: isUseEoLinker });
};

export const delReports = (data) => {
  return http.delete("/api/report-common", data, { useBody: true, useEoLinker: isUseEoLinker });
};

export const delReportTemplates = (data) => {
  return http.delete("/api/report-template-common", data, { useBody: true, useEoLinker: isUseEoLinker });
};

export const delReportCategorys = (data) => {
  return http.delete("/api/report-category-pop-common/page", data, { useBody: true, useEoLinker: isUseEoLinker });
};

export const getReportCategorys = (data) => {
  return http.post("/api/report-category-pop-common/page", data, { useBody: true, useEoLinker: isUseEoLinker });
};

export const changeCategory = (data) => {
  return http.post("/api/report-common/actions/change-category", data, { useBody: true, useEoLinker: isUseEoLinker });
};

export const getCategoryList = (data) => {
  return http.post(`/api/report-category-common/page`, data, { useBody: true, useEoLinker: isUseEoLinker });
};


export const checkCategoryNameUsed = (data) => {
  return http.post(`/api/report-category-common/check-name`, data, { useBody: true, useEoLinker: isUseEoLinker, loading: false });
};

export const checkCategoryOrderUsed = (data) => {
  return http.post(`/api/report-category-common/check-order`, data, { useBody: true, useEoLinker: isUseEoLinker, loading: false });
};


export const updateCategory = (data) => {
  return http.put(`/api/report-category-common`, data, { useBody: true, useEoLinker: isUseEoLinker });
};

export const addCategory = (data) => {
  return http.post(`/api/report-category-common`, data, { useBody: true, useEoLinker: isUseEoLinker });
};

export const delCategory = (data) => {
  return http.delete(`/api/report-category-common`, data, { useBody: true, useEoLinker: isUseEoLinker });
};
export const getReportTemplateDetail = (id) => {
  return http.get(`/api/report-template-common/${id}`, {}, { useEoLinker: isUseEoLinker });
};

export const saveReportTemplate = (data) => {
  return http.put("/api/report-template-common", data, { useBody: true, useEoLinker: isUseEoLinker });
};

export const checkReportTemplateName = (data) => {
  return http.post("/api/report-template-common/check-name", data, {
    useBody: true,
    useEoLinker: isUseEoLinker,
    loading: false,
  });
};

export const getReportDetailPage = (data) => {
  return http.post("/api/report-detail-common/page", data, { useBody: true, useEoLinker: isUseEoLinker });
};

export const exportReport = (data) => {
  return http.post("/api/report-detail-common/actions/export-page", data, {
    useBody: true,
    useEoLinker: isUseEoLinker,
  });
};

export const getReportURL = (data) => {
  return http.post("/api/report-detail-common/export-page-url", data, {
    useBody: true,
    useEoLinker: isUseEoLinker,
    loading: true,
  });
};

export const checkReportNeedSum = (id) => {
  return http.get(`/api/report-detail-common/action/check-need-sum/${id}`, {}, { useEoLinker: isUseEoLinker });
};

export const getReportFoot = (para) => {
  return http.post(`/api/report-detail-common/report-foot`, para, { useBody: true, useEoLinker: isUseEoLinker });
};

export const getGroupsList = () => {
  return http.get("/api/report-lists-common/staff-groups", { useEoLinker: isUseEoLinker });
};

export const getUser = (data) => {
  return http.post("/api/report-lists-common/staff-members", data, { useBody: true, useEoLinker: isUseEoLinker });
};

export const addUserToTemplate = (data) => {
  return http.put("/api/report-template-permissions-common", data, { useBody: true });
};

export const addUser = (data) => {
  return http.put("/api/report-permissions-common", data, { useBody: true });
};

//! templatePremissions
export const getTemplatePermissionsList = (data) => {
  return http.post("/api/report-template-permissions-common/page", data, { useBody: true, useEoLinker: isUseEoLinker });
};

export const deleteTemplatepermissionss = (data) => {
  return http.delete("/api/report-template-permissions-common", data, { useBody: true, useEoLinker: isUseEoLinker });
};


export const changeTemolateEditPermission = (data) => {
  return http.put("/api/report-template-permissions-common/action/change-edit-permission", data, {
    useBody: true,
    useEoLinker: isUseEoLinker,
  });
};

export const changeTemolateDeletePermission = (data) => {
  return http.put("/api/report-template-permissions-common/action/change-delete-permission", data, {
    useBody: true,
    useEoLinker: isUseEoLinker,
  });
};

//! reportPremissions
export const getReportPermissionsList = (data) => {
  return http.post("/api/report-permissions-common/page", data, { useBody: true, useEoLinker: isUseEoLinker });
};

export const deleteReportPermissionss = (data) => {
  return http.delete("/api/report-permissions-common", data, { useBody: true, useEoLinker: isUseEoLinker });
};

export const changeReportEditPermission = (data) => {
  return http.put("/api/report-permissions-common/action/change-edit-permission", data, {
    useBody: true,
    useEoLinker: isUseEoLinker,
  });
};

export const changeReportDeletePermission = (data) => {
  return http.put("/api/report-permissions-common/action/change-delete-permission", data, {
    useBody: true,
    useEoLinker: isUseEoLinker,
  });
};

export const getReportDetail = (id) => {
  return http.get(`/api/report-common/${id}`, {}, { useEoLinker: isUseEoLinker });
};

export const getTemplateInfo = (id) => {
  return http.get(`/api/report-common/template/${id}`, {}, { useEoLinker: isUseEoLinker });
};

export const checkCanEdit = (id) => {
  return http.get(`/api/report-template-common/action/check-can-edit/${id}`, {}, { useEoLinker: isUseEoLinker });
};

export const getReportFieldPage = (data) => {
  return http.post("/api/report-common/report-field-page", data, { useBody: true, useEoLinker: isUseEoLinker });
};

export const getUpdateMethodList = (data) => {
  return http.get("/api/report-lists-common/update-method", data, { useEoLinker: isUseEoLinker });
};

export const getViewTypeList = (data) => {
  return http.get("/api/report-lists-common/view-types", data, { useEoLinker: isUseEoLinker });
};

export const getSizeTypeList = (data) => {
  return http.get("/api/report-lists-common/size-types", data, { useEoLinker: isUseEoLinker });
};

export const getWidgetTypeList = (data) => {
  return http.get("/api/report-lists-common/widget-types", data, { useEoLinker: isUseEoLinker });
};

export const getChartTypeList = (data) => {
  return http.get("/api/report-lists-common/chart-types", data, { useEoLinker: isUseEoLinker });
};

export const getWidgetStatusList = (data) => {
  return http.get("/api/report-lists-common/widget-status", data, { useEoLinker: isUseEoLinker });
};

export const getWidgetCategoryList = (isBau, electionId) => {
  return http.get("/api/report-lists-common/widget-category?isBau=" + isBau + "&electionId=" + electionId, { useEoLinker: isUseEoLinker });
};

export const getCategoriesList = (data) => {
  return http.get("/api/report-lists-common/categories", data, { useEoLinker: isUseEoLinker });
};

export const getCompareOperatorsList = (data) => {
  return http.get("/api/report-lists-common/compare-operators", data, { useEoLinker: isUseEoLinker });
};

export const getLogicalOperatorsList = (data) => {
  return http.get("/api/report-lists-common/logical-operators", data, { useEoLinker: isUseEoLinker });
};

export const getCommunicationIds = async (data, isRegular = true, tempname = "") => {
  let api = '/api/report-detail-common/get-communication-ids'
  if (!isRegular) {
    api = `api/reports/${tempname}/get-communication-ids`;
  }
  return http.post(api, data, { useBody: true, useEoLinker: isUseEoLinker });
};

export const getReportWidgetList = (data) => {
  return http.post(`/api/report-widget/list`, data, { useBody: true, useEoLinker: isUseEoLinker });
};

export const getReportWidgetPage = (data) => {
  return http.post(`/api/report-widget/page`, data, { useBody: true, useEoLinker: isUseEoLinker });
};

export const getReportWidgetDetail = (id) => {
  return http.get(`/api/report-widget/${id}`, {}, { useEoLinker: isUseEoLinker });
};

export const delReportWidgets = (ids) => {
  return http.delete("/api/report-widget", ids, { useBody: true, useEoLinker: isUseEoLinker });
};

export const saveReportWidget = (data) => {
  return http.put("/api/report-widget/save-widget", data, { useBody: true, useEoLinker: isUseEoLinker });
};

export const addReportWidget = (data) => {
  return http.post("/api/report-widget/save-widget", data, { useBody: true, useEoLinker: isUseEoLinker });
};

export const checkReportWidgetName = (data) => {
  return http.post("/api/report-widget/check-name", data, { useBody: true, useEoLinker: isUseEoLinker, loading: false });
};

export const checkReportWidgetOrder = (data) => {
  return http.post("/api/report-widget/check-order", data, { useBody: true, useEoLinker: isUseEoLinker, loading: false });
};

export const renewReportWidgetUrl = (data) => {
  return http.post("/api/report-widget/report-renew-url", data, { useBody: true, useEoLinker: isUseEoLinker, loading: false });
};

export const getBauReportDropList = (data) => {
  return http.get("/api/report-common/bau-drop", data, { useEoLinker: isUseEoLinker });
};

export const getEleReportDropList = (electionId) => {
  return http.get("/api/report-common/event-drop?&electionId=" + electionId, { useEoLinker: isUseEoLinker });
};
