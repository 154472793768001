<template>
  <k-form-group
    :label-suffix="addressLabel"
    label-align-md="right"
    label-cols-md="3"
    :content-cols-lg="lgNum"
    :content-cols-md="mdNum"
    label-for="noneBind"
  >
    <k-form-group
      label-for="noneBind"
      label-align-md="left"
      label-cols-md="3"
      v-show="!isPlainText"
    >
      <div class="space-between">
        <k-form-checkbox
          :id="getId('isSameAddress')"
          v-model="innerAddress.isSame"
          :plaintext="isPlainText"
          @input="onIsSameAddressChanged"
          :no-locale="true"
          class="inline"
        >
          Same as Residential Address
        </k-form-checkbox>
        <k-button
          v-if="!isPlainText && !innerAddress.isSame"
          @click="clearAddress"
          @keydown.enter="clearAddress"
          class="btn-level1"
        >
          address.clear
        </k-button>
      </div>
    </k-form-group>
    <overseas-address
      ref="overseas"
      :address="innerAddress"
      :is-plain-text="isPlainText"
      :identifier="identifier"
      :validate="overseasValidate"
      :non-editable-fields="nonEditableFields"
      :has-oversea-country="hasOverseaCountry"
    />
  </k-form-group>
</template>
<script>
import OverseasAddress from "./address-overseas.vue";

export default {
  components: { OverseasAddress },
  props: {
    lgNum: {
      type: Number,
      default() {
        return 6;
      }
    },
    mdNum: {
      type: Number,
      default() {
        return 7;
      }
    },
    identifier: {
      type: String,
      default() {
        return "";
      }
    },
    address: {
      type: Object,
      default() {
        return { isSame: false };
      }
    },
    addressToCopy: {
      type: Object,
      default() {
        return {};
      }
    },
    addressCopyLabel: {
      type: String,
      default() {
        return "";
      }
    },
    addressLabel: {
      type: String,
      default() {
        return "";
      }
    },
    loading: {
      type: Boolean,
      default() {
        return false;
      }
    },
    isPlainText: {
      type: Boolean,
      default() {
        return false;
      }
    },
    overseasValidate: {
      type: Boolean,
      default() {
        return false;
      }
    },
    mailingAddressFormat: {
      type: Array,
      default() {
        return [];
      }
    },
    nonEditableFields: {
      type: Object,
      default() {
        return {
          ResidentialStreetName: false,
          MailingAddressLine1: false
        };
      }
    },
    hasOverseaCountry: {
      type: Boolean,
      default:true
    }
  },
  watch: {
    address: function (newAddress) {
      this.innerAddress = newAddress;
    },
    addressToCopy: {
      handler: function (newAddressToCopy) {
        this.copyAddress(newAddressToCopy);
      },
      deep: true
    }
  },
  data() {
    let address = {
      country: "",
      addressLine1: "",
      addressLine2: "",
      addressLine3: ""
    };
    if (this.address) address = this.address;
    return {
      innerAddress: address
    };
  },
  methods: {
    clearAddress() {
      if (!this.loading) {
        this.innerAddress.addressLine1 = "";
        this.innerAddress.addressLine2 = "";
        this.innerAddress.addressLine3 = "";
        this.innerAddress.overseaCountry = "";
        if (this.$refs.overseas) this.$refs.overseas.clearAddress();
      }
    },
    onIsSameAddressChanged(value) {
      if (value) {
        this.copyToMailingAddress();
      }
    },
    getId(element) {
      return element + this.identifier;
    },
    copyToMailingAddress() {
      this.innerAddress.addressLine1 = this.copyAddress("AddressLine1");
      this.innerAddress.addressLine2 = this.copyAddress("AddressLine2");
      this.innerAddress.addressLine3 = this.copyAddress("AddressLine3");
      this.innerAddress.overseaCountry = this.copyAddress("AddressLine4");
    },
    copyAddress(key) {
      const format = this.mailingAddressFormat.find((it) => it.name === key);
      if (format) {
        const address = this.addressToCopy;
        const getValue = (part) => {
          let v = "";
          switch (part) {
            case "$Unit$":
              v = address.unit;
              break;
            case "$Street Number$":
              v = address.streetNumber;
              break;
            case "$Street Name$":
              v = address.streetName;
              break;
            case "$Street Type$":
              v = address.streetType;
              break;
            case "$City$":
              v = address.city;
              break;
            case "$State$":
              v = address.state;
              break;
            case "$Postal Code$":
              v = address.postcode;
              break;
            case "$Country$":
              v = address.country;
              break;
            case "$Street Fraction$":
              v = address.streetFraction;
              break;
            case "$Street Pre$":
              v = address.streetPre;
              break;
            case "$Street Post$":
              v = address.streetPost;
              break;
            case "$Building$":
              v = address.building;
              break;
            case "$Apartment$":
              v = address.apartment;
              break;
            case "$Unit$ / $Street Number$":
              v = [address.unit, address.streetNumber]
                .filter((it) => it != null && it.length > 0)
                .join(" / ");
          }
          return v;
        };

        return format.groups
          .map((parts) => {
            return parts
              .map((part) => getValue(part.part))
              .filter((it) => it != null && it.length > 0)
              .join(" ");
          })
          .filter((it) => it != null && it.length > 0)
          .join(", ");
      } else {
        return "";
      }
    }
  }
};
</script>
<style scoped>
.inline {
  display: inline-block;
}
</style>
