(function () {
  /**
   * 左补齐
   * @param {Number} len 补齐长度
   * @param {*} char 填充字符
   */
  String.prototype.padLeft = Number.prototype.padLeft = function (len, char) {
    return (Array(len).join(char) + this).slice(-len);
  };

  /**
   * 右补齐
   * @param {Number} len 补齐长度
   * @param {*} char 填充字符
   */
  String.prototype.padRight = Number.prototype.padRight = function (len, char) {
    return (this + Array(len).join(char)).slice(0, len);
  };
  if (typeof (String.prototype.realLenth) == 'undefined') {
    Object.defineProperty(String.prototype, 'realLenth', {
      get: function () {
        return this.replace(/\r/g, '').length;
      }
    })
  }

  String.prototype.trim = function () {
    return this.replace(/(^\s*)|(\s*$)/g, "");
  };

  String.prototype.left = function (n) {
    if (n >= this.length) {
      return this;
    } else {
      return this.substring(0, n);
    }
  };

  String.prototype.format = function (args) {
    let formatted = this;
    for (let i = 0; i < args.length; i++) {
      let regexp = new RegExp("\\{" + i + "\\}", "gi");
      formatted = formatted.replace(regexp, args[i]);
    }
    return formatted;
  };

  /**
   * 转换时区时间
   * @param {*} zone 时区
   */
  Date.prototype.toZone = function (zone) {
    const localOffset = !Number.isNaN(Number(zone)) ? (this.getTimezoneOffset() + Number(zone) * 60) : 0;
    return new Date(this.getTime() + localOffset * 60000);
  };

  const __define_week = [
    ["Mon", "Tue", "Wed", "Thur", "Fri", "Sat", "Sun"],
    ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
  ];

  const __define_month = [
    ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
  ];

  /**
   * 转换时间格式
   * @param {*} format 时间格式
   */
  Date.prototype.format = function (format) {
    var ye = this.getFullYear();
    var mo = this.getMonth();
    var da = this.getDate();
    var ho = this.getHours();
    var mu = this.getMinutes();
    var ss = this.getSeconds();
    var we = this.getDay();
    var ap = ho < 12 ? "AM" : "PM";

    format = format || "";
    format = format.replace("yyyy", ye);
    format = format.replace("MMMM", __define_month[1][mo]);
    format = format.replace("MMM", __define_month[0][mo]);
    format = format.replace("MM", (mo + 1).padLeft(2, "0"));
    format = format.replace("dd", da.padLeft(2, "0"));
    format = format.replace("HH", ho.padLeft(2, "0"));
    format = format.replace("hh", (ho > 12 ? ho - 12 : ho).padLeft(2, "0"));
    format = format.replace("ap", ap);
    format = format.replace("mm", mu.padLeft(2, "0"));
    format = format.replace("ss", ss.padLeft(2, "0"));
    format = format.replace("wwww", __define_week[1][we - 1]);
    format = format.replace("www", __define_week[0][we - 1]);
    format = format.replace("ww", we.padLeft(2, "0"));

    return format;
  };
})();

export const __define_Property_ = function (source, target, isCover) {
  Object.keys(target).forEach(key => {
    if (key in source) {
      if (isCover !== false) {
        Object.defineProperty(source, key, {
          value: target[key],
          enumerable: true,
          configurable: true,
          writable: true,
        });
      }
    } else {
      source[key] = target[key];
    }
  });
};
