<template>
  <div>
    {{ fullAddress }}
  </div>
</template>
<script>
export default {
  props: {
    address: {
      type: Object,
      default () {
        return {};
      },
    },
  },
  data () {
    return {
      fullAddress: "",
    };
  },
  watch: {
    address () {
      this.fullAddress = this.getFormatAddr();
    },
  },
  created: function () {
    this.fullAddress = this.getFormatAddr();
  },
  methods: {
    getAddrPart2: function (returnAddr) {
      if (this.address.locality && this.address.locality.length > 0) {
        returnAddr += this.address.locality + " ";
      }
      if (this.address.state && this.address.state.length > 0) {
        returnAddr += this.address.state + " ";
      }
      if (this.address.postCode && this.address.postCode.length > 0) {
        returnAddr += this.address.postCode + " ";
      }
      if (this.address.country && this.address.country.length > 0) {
        returnAddr += this.address.country + " ";
      }
      return returnAddr;
    },
    getFormatAddr: function () {
      let returnAddr = "";
      if (this.address.buildingName && this.address.buildingName.length > 0) {
        returnAddr += this.address.buildingName + " ";
      }
      if (this.address.unit && this.address.unit.length > 0) {
        returnAddr += this.address.unit + " ";
      }
      if (this.address.streetNum && this.address.streetNum.length > 0) {
        returnAddr += this.address.streetNum + " ";
      }
      if (this.address.streetName && this.address.streetName.length > 0) {
        returnAddr += this.address.streetName + " ";
      }
      if (this.address.streetType && this.address.streetType.length > 0) {
        returnAddr += this.address.streetType + " ";
      }
      returnAddr = this.getAddrPart2(returnAddr);
      returnAddr = returnAddr.trim();
      return returnAddr;
    },
  },
};
</script>
