const routes = [
  {
    name: "workAssignments",
    path: "workAssignments",
    meta: {
      title: "workAssignments",
      pageTitle: "welcome",
    },
    route: true,
    component: () => import("@/views/workAssignments/workAssignments"),
    children: [
      {
        name: "workAssignment-detail",
        path: "detail",
        meta: {
          title: "supervisor",
          pageTitle: "welcome",
        },
        route: true,
        component: () => import("@/views/workAssignments/workAssignments-detail"),
      },
      {
        name: "noWorkAssignments",
        path: "noWorkAssignments",
        meta: {
          title: "noWorkAssignments",
          pageTitle: "welcome",
        },
        route: true,
        component: () => import("@/views/workAssignments/noWorkAssignments"),
      },
    ],

  },
];


export default [
  {
    path: "/",
    meta: {
      title: "workAssignments",
      requiresAuth: false,
    },
    redirect: {
      name: "workAssignments",
    },
    component: () => import("@/layout/brief"),
    children: [...routes],
  },
];
