const saveAlert = {
  savedStatus(route) {
    let status = 0
    if (route) {
      if (route.query && route.query.status) {
        status = route.query.status
      } else if (route.params && route.params.status) {
        status = route.params.status
      }
    }
    return status
  },
  savedMessage(route) {
    let msg = null
    if (route) {
      if (route.query && route.query.message) {
        msg = route.query.message
      } else if (route.params && route.params.message) {
        msg = route.params.message
      }
    }
    return msg
  },
}

export { saveAlert }
