<template>
  <b-input-group>
    <k-form-input
      autocomplete="new-password"
      :type="isShowPassword ? 'text' : 'password'"
      v-model="ssn"
      @blur="ssnBlurEvent"
      :plaintext="plaintext"
    />
    <b-input-group-append is-text v-show="hasPasswordChangeBtn && !plaintext">
      <b-icon
        :icon="isShowPassword ? 'eye-fill' : 'eye-slash-fill'"
        @click="ssnIconClickEvent"
      />
    </b-input-group-append>
  </b-input-group>
</template>

<script>
export default {
  model: {
    prop: "value",
    event: "updateValue",
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    propsShowPassword: {
      type: Boolean,
      default: false,
    },
    plaintext: {
      type: Boolean,
      default: false,
    },
    hasPasswordChangeBtn: {
      type: Boolean,
      default: true,
    },
    ssnDigits: {
      type: Number,
      default: 9,
    },
  },
  computed: {},
  data() {
    return {
      isShowPassword: this.propsShowPassword,
      ssn: this.ssnFormat(this.value),
    };
  },
  methods: {
    ssnIconClickEvent() {
      this.isShowPassword = !this.isShowPassword;
      if (this.ssn.indexOf("-") && this.isShowPassword) {
        this.ssn = this.ssnFormat(this.ssn);
      } else {
        this.ssn = this.ssn.replaceAll("-", "");
      }
    },
    ssnBlurEvent() {
      if (!this.isShowPassword) {
        this.$emit("updateValue", this.ssn);
        return;
      }
      this.ssn = this.ssnFormat(this.ssn);
      this.$emit("updateValue", this.ssn.replaceAll("-", ""));
    },
    ssnFormat(v) {
      if (!v || v == "" || this.ssnDigits == 4 || v.length!=9) {
        return v;
      }
      v = v.trim();
      v = v.replaceAll(" ", "");
      v = v.replaceAll("-", "");
      return `${v.slice(0, 3)}-${v.slice(3, 5)}-${v.slice(5)}`;
    },
  },
  watch: {
    value(newData) {
      if (this.isShowPassword) {
        this.ssn = this.ssnFormat(newData);
      } else {
        this.ssn = newData;
      }
    },
  },
};
</script>

<style></style>
