var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"center-bar"},[_c('div',{staticClass:"nav-btn-group"},[_c('k-button-group',[_c('k-button',{on:{"click":_vm.onBackClick}},[_vm._v(" buttons.back ")]),(_vm.isExport && _vm.enumTemplateType.letter == this.type)?_c('k-export-button',{attrs:{"params-getter":_vm.getExportCSV,"export-handler":_vm.exportHandler,"check-has-data":_vm.checkHasData,"exported-callback":_vm.exportCallbacker}},[_vm._v(" buttons.exportToPDF ")]):_vm._e(),(
            _vm.hasGenerateMailingAddress &&
            _vm.$has(
              _vm.isEvent
                ? _vm.permissionCodeEvent.Communication.GenerateMailingLabels
                : _vm.permissionCode.Communication.GenerateMailingLabels
            )
          )?_c('k-button',{on:{"click":_vm.generateMailingAddressClick}},[_vm._v(" buttons.generateMailingAddress ")]):_vm._e()],1),_c('KWikiHelp',{attrs:{"props-wiki-help-code":_vm.propsWikiHelpCode}})],1),_c('div',{staticClass:"content-main"},[_c('k-alert-msg',{ref:"lblMsg"}),_c('div',{staticClass:"d-flex mt-3 offset-xl-3"},[_c('div',{staticClass:"text-right col-xl-9 p-0"},[_vm._v(" "+_vm._s(_vm.$g("totalCount"))+": "+_vm._s(_vm.totalCount)+" ")])]),_c('div',{staticClass:"content-party"},[_c('LanguageList',{staticClass:"previewLanguage",attrs:{"person-languages":_vm.personLanguages,"is-show-hr":false,"is-validation":true,"show-is-data-icon":false},on:{"onLanguageSwitch":_vm.onLanguageSwitch},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"ml-xl-3"},[_c('div',{staticClass:"d-xl-flex align-items-center mb-3 col-md-9 p-0"},[_c('div',{staticClass:"d-xl-flex align-items-center",staticStyle:{"flex":"1"}},[_c('div',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.$g("contactPerson"))+":")]),_c('k-form-select',{staticStyle:{"flex":"1"},attrs:{"options":_vm.contactPersons},on:{"change":_vm.contactPersonChange},model:{value:(_vm.contactPerson),callback:function ($$v) {_vm.contactPerson=$$v},expression:"contactPerson"}})],1),_c('div',{staticClass:"ml-1 text-xl-right",staticStyle:{"width":"300px"}},[_vm._v(" "+_vm._s(_vm.$g("totalCountLanguage"))+": "+_vm._s(_vm.languageTotalCount)+" ")])]),_c('div',{ref:"divContent",staticClass:"p-3 border col-md-9",style:(_vm.getStyle())})])]},proxy:true}])})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }