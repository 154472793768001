<template>
  <div>
    <k-alert-msg ref="lblMsg" />
    <div class="photo-group mb-3 pb-2">
      <div
        class="photo-group-item"
        v-for="(photo, index) in photoes"
        :key="index"
      >
        <img-photo :src="photo.base64String" />
        <span
          v-if="!isPlaintext"
          class="photo-trash"
          :id="'tipImage_' + index"
          v-b-tooltip="{
            placement: 'bottomleft',
            trigger: 'hover',
            title: $g('icon.deleteToolTip'),
            boundary: 'window',
            container: 'tipImage_' + index,
          }"
        >
          <b-icon
            icon="trash"
            @click="onDeletePhoto(photo)"
          />
        </span>
        <slot
          name="photoInfo"
          :itemprop="photo"
        />
      </div>
    </div>
    <KValidationObserver
      ref="observer"
      class="content-page"
      v-if="!isPlaintext"
    >
      <k-form
        ref="form"
        @submit.prevent="onSubmit"
        class="content-form"
      >
        <k-form-group
          :label-class="labelClass"
          label-suffix="document.file"
          label-align-md="right"
          content-cols-lg="6"
          content-cols-md="7"
        >
          <KValidationProvider
            :rules="{
              required: isRequired,
              acceptFileType: accept,
              maxFileSize: size * 1024,
            }"
            v-slot="{ errors }"
            slim
            :name="$g('document.file')"
          >
            <k-alert
              :show="errors.length > 0 && isShowErrors"
              variant="warning"
            >
              {{ errors[0] }}
            </k-alert>
            <common-upload
              ref="fileinput"
              v-model="form.file"
              :accept="accept"
              :size="size"
              :on-delete="onDelete"
              :is-multiple="isMultiple"
              :placeholder="placeholder"
              :show-message="false"
              :is-use-internal-validate="false"
            >
              <template #btnGroup>
                <k-button
                  type="submit"
                  class="mb-1"
                >
                  buttons.upload
                </k-button>
              </template>
            </common-upload>
          </KValidationProvider>
        </k-form-group>
      </k-form>
    </KValidationObserver>
  </div>
</template>
<script>
import { getDocs, deleteDocs, addDocument } from "./image";
import imgPhoto from "../../k-components/img/img.vue";
import { getUploadConfig } from "../../api/upload.js";
import globalEnv from "../../libs/globalEnv";

export default {
  components: { imgPhoto },
  props: {
    logType: {
      type: Number,
      default(){
        return 0;
      },
    },
    docRequestType: {
      type: Number,
      default(){
        return 0;
      },
    },
    refId: {
      type: String,
      default(){
        return "";
      },
    },
    refId2: {
      type: String,
      default(){
        return "";
      },
    },
    electionId: {
      type: String,
      default(){
        return "";
      },
    },
    isRequired: {
      type: Boolean,
      default(){
        return true;
      },
    },
    isPlaintext: {
      type: Boolean,
      default(){
        return false;
      },
    },
  },

  data(){
    return {
      isMultiple: true,
      photoes: [],
      accept: "",
      size: 0,
      placeholder: "No file chosen",
      form: {
        title: "",
        file: [],
      },
      status: 0,
      message: "",
      isShowErrors: true,
    };
  },
  computed: {
    labelClass(){
      if(this.isRequired){
        return "require-mark fileWidth";
      }
      return "fileWidth";
    },
  },

  methods: {
    async onSubmit(){
      this.isShowErrors = true;
      const isValid = await this.$refs.observer.validate();
      if(!isValid) return;
      this.form.file.forEach((item) => {
        var formData = new FormData();
        formData.append("RefId", this.refId);
        formData.append("RefId2", this.refId2);
        formData.append("DocRequestType", this.docRequestType);
        formData.append("LogRequestType", this.logType);
        formData.append("Title", item.name);
        formData.append("File", item);
        if(this.electionId){
          formData.append("electionId", this.electionId);
        }

        addDocument(formData).then((m) => {
          this.status = m.data.status;
          this.message = m.message;
          this.$refs.lblMsg.message(this.status, this.message);
          this.bindPhoto();
          this.$refs.fileinput.clear();
          this.isShowErrors = false;
        });
      });

      // });
    },

    onDeletePhoto(photo){
      let pmOpt = this.$bvModal
          .msgBoxConfirm(this.$g("const.confirm.delete"), {
            title: this.$g("const.confirm.confirm"),
            okTitle: this.$g("const.confirm.no"),
            cancelTitle: this.$g("const.confirm.yes"),
            cancelVariant: "primary",
            okVariant: "secondary",
            noCloseOnBackdrop: true,
          })
          .then((value) => {
            return value != null && !value;
          });
      if(pmOpt == null){
        return;
      }
      pmOpt.then(async (success) => {
        if(success){
          let data = {};
          let ids = [];

          ids.push(photo.id);

          data.DocRequestType = this.docRequestType;
          data.Ids = ids;
          data.RefId = this.refId;
          data.RefId2 = this.refId2;
          data.LogRequestType = this.logType;
          deleteDocs(data).then((res) => {
            this.status = res.data.status;
            this.message = res.message;
            this.$refs.lblMsg.message(this.status, this.message);
            this.bindPhoto();
          });
        }
      });
    },
    onShowPhoto(model){
      var baseUrl = globalEnv.VUE_APP_STD_URL;
      if(globalEnv.VUE_APP_SYSTEM_PATH){
        baseUrl = baseUrl + "/" + globalEnv.VUE_APP_SYSTEM_PATH;
      }
      return baseUrl + "/api/docs/actions/showPhoto?filePath=" + model.filePath;
    },
    onDelete(file){
      if(this.form.file.length > 0 && this.form.file[0].name == file.name)
        this.form.file = [];
    },

    async bindPhoto(){
      if(this.refId && this.refId.length > 0){
        await getDocs(this.refId, this.refId2, this.docRequestType).then((res) => {
          const items = res.data;

          this.photoes = items || [];
        });
      }
    },
    showMsg(status, message){
      this.$refs.lblMsg.message(status, message);
    },
  },

  mounted(){
    this.bindPhoto();

    getUploadConfig("imagepanal").then((data) => {
      this.accept = data.data.acceptTypes;
      this.size = data.data.maxSize / 1024;
    });
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .fileWidth {
    flex: 0;
  }

  .photo-group-item {
    display: inline-block;
    padding: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    width: 100px;
    height: 100px;
    vertical-align: top;
    position: relative;
    box-sizing: content-box;
    background: #eee;
    text-align: center;
  }

  .photo-group-item:hover .photo-trash {
    display: block;
  }

  .photo-group-item img {
    max-width: 100%;
    max-height: 100%;
  }

  .photo-group-item .photo-trash {
    position: absolute;
    top: 1px;
    right: 1px;
    display: none;
  }
}
</style>
