<template>
  <b-tabs v-model="tabsIndex" class="search-area">
    <k-alert-msg ref="lblMsg" class="mt-1 mb-0" />
    <b-tab>
      <template #title>
        <div class="tab-lable">
          {{ $g("search.tabs.search") }}
        </div>
      </template>
      <div class="search-container" @keyup.enter="searchHandler">
        <div class="search-area-main">
          <slot />
        </div>
        <div class="search-division" v-show="showDynamic">
          {{ $g("search.and") }}
        </div>
        <KValidationObserver
          ref="observer"
          class="search-dynamic"
          v-show="showDynamic"
        >
          <search-dynamic
            v-for="(dynamicCondition, i) in dynamicConditions"
            :key="i"
            :first="i == 0"
            :index="parseInt(i)"
            :info="dynamicCondition"
            @conditionChange="onConditionChange"
            @conditionDelete="onConditionDelete"
            :fields="fields"
            :show-delete="dynamicConditions.length > 1"
          />
        </KValidationObserver>
        <div class="mt-2">
          <k-button
            variant="primary"
            class="search-area-button mr-1 mb-1"
            @click="searchHandler"
          >
            search.search
          </k-button>
          <k-button class="search-area-button mr-1 mb-1" @click="clearHandler">
            search.clear
          </k-button>
          <k-button
            class="search-area-button mb-1"
            @click="saveDialogVisible = true"
            v-show="showTemplate"
          >
            search.saveAsTemplate
          </k-button>
        </div>
      </div>

      <b-modal
        :title="$g('search.savetemplate')"
        v-model="saveDialogVisible"
        @hide="resetSaveTemplateForm"
        hide-footer
        :no-close-on-backdrop="true"
      >
        <b-overlay :show="loading.save" rounded="sm">
          <KValidationObserver v-slot="{ handleSubmit, reset }">
            <b-form
              @reset="onReset"
              @reset.prevent="reset"
              @submit.prevent="handleSubmit(saveTemplateHandler)"
            >
              <k-form-group
                label-suffix="search.templateName"
                label-for="templateName"
                label-class="require-mark"
                class="save-template"
              >
                <KValidationProvider
                  :rules="{ required: true, remote: isNameExits, max: 50 }"
                  slim
                  name="template Name"
                  v-slot="{ errors }"
                >
                  <k-alert :show="errors.length > 0" variant="warning">
                    {{ errors[0] }}
                  </k-alert>
                  <k-form-input
                    id="templateName"
                    v-model="saveTemplate.templateName"
                    placeholder="search.enterTemplateName"
                  />
                </KValidationProvider>
              </k-form-group>
              <div class="text-right search-dialog-footer">
                <k-button type="submit" variant="primary" class="mr-1">
                  search.save
                </k-button>
                <k-button @click="closeSaveDialog"> search.cancel </k-button>
              </div>
            </b-form>
          </KValidationObserver>
        </b-overlay>
        <template #modal-footer />
      </b-modal>
    </b-tab>
    <b-tab v-if="showTemplate">
      <template #title>
        <div class="tab-lable">
          {{ $g("search.tabs.template") }}
        </div>
      </template>
      <div class="search-container">
        <b-overlay :show="loading.template" rounded="sm">
          <div
            v-if="templateList.length <= 0"
            class="noDataImg"
            style="height: 160px"
          >
            <div class="noDataFont">
              {{ $g("search.noDataText") }}
            </div>
          </div>
          <b-list-group-item
            v-for="template in templateList"
            :key="template.id"
            class="d-flex justify-content-between align-items-center"
          >
            <b-link
              class="ellipsis"
              type="primary"
              @click="templateChooseHandler(template)"
            >
              {{ template.templateName }}
            </b-link>
            <div class="searchIcon" :id="'tipSearch_' + template.id">
              <b-badge
                :variant="template.isDefault ? 'success' : 'secondary'"
                @click="setDefault(template)"
                v-b-tooltip="{
                  placement: 'bottomleft',
                  trigger: 'hover',
                  title: $g('search.default'),
                  boundary: 'window',
                  container: 'tipSearch_' + template.id,
                }"
              >
                D
              </b-badge>
              <b-icon
                icon="person"
                @click="shareToClickEvent(template)"
                v-b-tooltip="{
                  placement: 'bottomleft',
                  trigger: 'hover',
                  title: $g('search.share'),
                  boundary: 'window',
                  container: 'tipSearch_' + template.id,
                }"
              />
              <b-icon
                icon="trash"
                @click="templateChooseDeleteHandler(template)"
                class="template-delete mr-0"
                v-b-tooltip="{
                  placement: 'bottomleft',
                  trigger: 'hover',
                  title: $g('search.delete'),
                  boundary: 'window',
                  container: 'tipSearch_' + template.id,
                }"
              />
            </div>
          </b-list-group-item>
        </b-overlay>
        <b-modal
          v-model="deleteDialogVisible"
          @hide="resetSaveTemplateForm"
          hide-footer
          dialog-class="confirm-dialog"
          :no-close-on-backdrop="true"
        >
          <b-overlay :show="loading.deleting" rounded="sm">
            <p>{{ $g("search.confirmDelete") }}</p>
            <div class="text-right search-dialog-footer">
              <k-button
                variant="primary"
                @click="deleteTemplateHandler()"
                class="mr-1"
              >
                search.yes
              </k-button>
              <k-button @click="deleteDialogVisible = false">
                search.no
              </k-button>
            </div>
          </b-overlay>
        </b-modal>
        <k-popwindow id="modal-shareTo" :title="$g('shareTo')" :hide-ok="true">
          <ShareTo
            ref="shareTo"
            :search-id="shareToData.searchId"
            :shared-group-id="shareToData.sharedGroupId"
            :shared-user-id="shareToData.sharedUserId"
          />
        </k-popwindow>
        <k-popwindow
          id="modal-users"
          :title="$g('users')"
          @ok="onUsersOKAdd"
          :check-selected="true"
        >
          <ShareToAddUsers
            ref="shareToAddUsers"
            :search-id="shareToData.searchId"
            :shared-user-id="shareToData.sharedUserId"
          />
        </k-popwindow>
        <k-popwindow
          id="modal-groups"
          :title="$g('groups')"
          @ok="onGroupsOKAdd"
          :check-selected="true"
        >
          <ShareToAddGroups
            ref="shareToAddGroups"
            :search-id="shareToData.searchId"
            :shared-group-id="shareToData.sharedGroupId"
          />
        </k-popwindow>
      </div>
    </b-tab>
  </b-tabs>
</template>

<script>
import {
  getTemplateList,
  saveTemplate,
  deleteTemplate,
  getTemplateType,
  checktemplatenameexits,
} from "../../api/searchTemplate";
import {
  saveShareToUsers,
  saveShareToGroups,
  setDefault,
} from "./api/searchArea";
import eventBus from "../../utils/eventBus";
import searchDynamic from "./components/searchDynamic";
import storeage from "../../utils/storeage";
import { repResult } from "../../libs/global";
import ShareTo from "./components/shareTo.vue";
import ShareToAddGroups from "./components/shareToAddGroups.vue";
import ShareToAddUsers from "./components/shareToAddUsers.vue";

export default {
  components: { searchDynamic, ShareTo, ShareToAddGroups, ShareToAddUsers },
  props: {
    typeName: {
      type: String,
      default() {
        return "";
      },
    },
    setParas: {
      type: Function,
      default: null,
    },
    getParas: {
      type: Function,
      default: null,
    },
    searchClick: {
      type: Function,
      default: null,
    },
    clearClick: {
      type: Function,
      default: null,
    },
    isClearRefresh: {
      type: Boolean,
      default: true,
    },
    fields: {
      type: Array,
      default() {
        return [];
      },
    },
    showDynamic: {
      type: Boolean,
      default() {
        return true;
      },
    },
    showTemplate: {
      type: Boolean,
      default() {
        return true;
      },
    },
    notNeedTemplateApi: {
      type: Boolean,
      default() {
        return false;
      },
    },
    otherTypeNameCondition: {
      type: Object,
      default() {
        return {};
      },
    },
    pageListRef: {
      type: String,
      default: "selectableTable",
    },
  },
  data() {
    let isEvent = this.$route.meta.isEvent;
    return {
      isEvent: isEvent,
      templateType: 0,
      templateList: [],
      selectTemplateValue: "",
      saveTemplate: {
        templateName: "",
      },
      saveDialogVisible: false,
      deleteDialogVisible: false,
      tabsIndex: 0,
      loading: {
        save: false,
        template: true,
        deleting: false,
      },
      deleteTemplateQuery: {
        id: "",
        sharedUserId: "",
        sharedGroupId: "",
      },
      dynamicConditions: [{ field: null, sign: 1, condition: 0, value: "" }],
      electionid: storeage.getElectionId(),
      isSearchArea: (!this.showTemplate || this.notNeedTemplateApi) ?? false,
      shareToData: {
        searchId: null,
        sharedGroupId: null,
        sharedUserId: null,
      },
      isDefaultTemplate: false,
      defaultTemplate: null,
      isFirstTime: true,
      isSelfDestroy: false,
    };
  },
  mounted() {
    if (!this.notNeedTemplateApi) {
      this.refreshTemplateList();
    }
    document.querySelectorAll("input").forEach((item) => {
      item.maxLength = 200;
    });
    this.$nextTick(() => {
      document
        .querySelector(".search-area-main input,.search-area-main select")
        ?.focus();
    });
  },
  computed: {},
  methods: {
    shareToClickEvent(template) {
      this.shareToData = {
        searchId: template.id,
        sharedGroupId: template.sharedGroupId,
        sharedUserId: template.sharedUserId,
      };
      this.$bvModal.show("modal-shareTo");
    },
    getIsSearchArea() {
      return this.isSearchArea;
    },
    setIsSearchArea(value) {
      this.isSearchArea = value;
      return this.isSearchArea;
    },
    setDefault(template) {
      let item = this.templateList.find((c) => c.isDefault);
      if (item) {
        item.isDefault = false;
      }
      if (!item || item.id != template.id) {
        this.$set(template, "isDefault", true);
      } else {
        this.isDefaultTemplate = false;
        this.defaultTemplate = null;
      }
      setDefault({ searchId: template.id }).then((result) => {
        this.$refs.lblMsg.success(result.message, result.data.status);
      });
    },
    onUsersOKAdd() {
      let items = this.$refs.shareToAddUsers.getSelectItems();
      let query = {
        searchId: this.shareToData.searchId,
        sharedUserId: this.shareToData.sharedUserId,
        userIds: [],
      };
      for (const item of items) {
        query.userIds.push(item.userId);
      }
      saveShareToUsers(query).then((result) => {
        if (result.data.status == repResult.success) {
          this.$refs.shareTo.refreshUsersGrid();
        }
        this.$refs.shareTo.showMessage({
          status: result.data.status,
          message: result.data.info,
          type: 0,
        });
      });
    },
    onGroupsOKAdd() {
      let items = this.$refs.shareToAddGroups.getSelectItems();
      let query = {
        searchId: this.shareToData.searchId,
        sharedGroupId: this.shareToData.sharedGroupId,
        groupIds: [],
      };
      for (const item of items) {
        query.groupIds.push(item.id);
      }
      saveShareToGroups(query).then((result) => {
        if (result.data.status == repResult.success) {
          this.$refs.shareTo.refreshGroupsGrid();
        }
        this.$refs.shareTo.showMessage({
          status: result.data.status,
          message: result.data.info,
          type: 1,
        });
      });
    },
    refreshTemplateList() {
      if (this.showTemplate) {
        this.getTemplateListByTemplateName();
      }
    },
    searchHandler: async function () {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        let jsonData = this.getParas();
        let effectivelength = 4000; //The maximum effective length of the database can be customized
        for (let key in jsonData) {
          let json_value = jsonData[key];
          if (typeof json_value == "string") {
            if (json_value.length > effectivelength) {
              jsonData[key] = json_value.substring(0, effectivelength);
            }
          }
        }
        if (window.innerWidth < 1024) {
          document.querySelector(".nav-btn-search")?.click();
        }
        this.setParas(jsonData, true);
        this.searchClick(this.dynamicConditions);
      }
    },
    clearHandler: function () {
      this.clearClick();
      this.selectTemplateValue = "";
      this.saveTemplate.templateName = "";
      this.dynamicConditions = [
        { field: null, sign: 0, condition: 0, value: "", selectFieldType: 1 },
      ];
      if (this.isClearRefresh) {
        setTimeout(() => {
          this.searchHandler();
        }, 100);
      }
    },
    clearDynamicConditions() {
      this.dynamicConditions = [
        { field: null, sign: 1, condition: 0, value: "", selectFieldType: 1 },
      ];
    },
    templateChooseHandler(template) {
      let paras = JSON.parse(template.content);
      this.setDynamicConditions(paras.dynamicConditions);
      this.setParas(paras, true);
      this.saveTemplate.templateName = template.templateName;
      this.tabsIndex = 0;
      setTimeout(() => {
        this.searchHandler();
      }, 100);
    },
    templateChooseDeleteHandler(template) {
      this.deleteTemplateQuery = {
        id: template.id,
        sharedUserId: template.sharedUserId,
        sharedGroupId: template.sharedGroupId,
      };
      this.deleteDialogVisible = true;
    },
    getTemplateListByTemplateName() {
      this.isSearchArea = false;
      if (this.typeName) {
        let condition = {
          typeName: this.typeName,
        };
        getTemplateType(condition).then((res) => {
          const { data } = res;
          this.templateType = data;
          this.saveTemplate.templateName = "";
          this.getTemplateListByTemplateType();
        });
      }
    },
    getTemplateListByTemplateType(templateType) {
      this.isSearchArea = false;
      if (templateType) this.templateType = templateType;
      this.loading.template = true;
      let model = {
        templateType: this.templateType,
        electionid: this.isEvent ? this.electionid : null,
        ...this.otherTypeNameCondition,
      };
      getTemplateList(model).then((res) => {
        const { data } = res;
        this.templateList = data;
        let selectableTable =
          this.$parent?.$refs[this.pageListRef] ??
          this.$parent.$parent.$refs[this.pageListRef];
        this.setDefaultTemplate();
        this.isSearchArea = true;
        if (!this.isSelfDestroy) {
          eventBus.$emit("defaultTemplateEnded");
          console.warn("fire getTemplateList defaultTemplateEnded");
        }
        if (selectableTable) {
          if (selectableTable.autoBind) {
            // selectableTable?.refresh();
          }
        } else {
          this.$emit("setDefaultTemplateRefresh");
        }
        this.saveTemplate.templateName = "";
        this.loading.template = false;
      });
    },
    setDefaultTemplate() {
      if (this.$route?.query?.restore && this.isFirstTime) {
        this.isFirstTime = false;
        return;
      }
      for (const template of this.templateList) {
        if (template.isDefault) {
          let jsonContent = JSON.parse(template.content);
          this.isDefaultTemplate = true;
          this.defaultTemplate = jsonContent;
          this.setDynamicConditions(jsonContent.dynamicConditions);
          this.setParas(jsonContent, true);
        }
        if (template.templateName == this.saveTemplate.templateName) {
          this.selectTemplateValue = template.id;
          break;
        }
      }
    },
    async isNameExits() {
      let para = {};
      para.templatename = this.saveTemplate.templateName;
      para.templateType = this.templateType;
      para.electionid = this.isEvent ? this.electionid : null;
      let result = null;
      await checktemplatenameexits(para).then(function (rep) {
        result = rep;
      });
      return result;
    },
    saveTemplateHandler() {
      this.saveTemplate.templateType = this.templateType;
      let paras = this.getParas();
      paras.dynamicConditions = [...this.dynamicConditions];
      this.saveTemplate.content = JSON.stringify(paras);
      this.saveTemplate.electionid = this.isEvent ? this.electionid : null;
      this.loading.save = true;
      let data = { ...this.saveTemplate, ...this.otherTypeNameCondition };
      saveTemplate(data).then((res) => {
        this.loading.save = false;
        if (res.data) {
          this.$refs.lblMsg.success(this.$g("search.savedsuccessfully"));
          this.saveDialogVisible = false;
        } else {
          this.$refs.lblMsg.error(this.$g("search.savedfailed"));
        }
        this.getTemplateListByTemplateType();
      });
    },
    deleteTemplateHandler() {
      this.loading.template = true;
      this.loading.deleting = true;
      let self = this;
      deleteTemplate(this.deleteTemplateQuery).then((res) => {
        this.$refs.lblMsg.message(res.data.status, res.message);
        this.deleteDialogVisible = false;
        this.loading.deleting = false;
        self.getTemplateListByTemplateType();
      });
    },
    onReset() {
      this.saveTemplate.templateName = "";
    },
    closeSaveDialog() {
      this.saveDialogVisible = false;
    },
    resetSaveTemplateForm() {
      this.onReset();
    },
    onConditionChange(index, info) {
      this.dynamicConditions[index] = info;
      if (index === this.dynamicConditions.length - 1 && info.condition > 0) {
        this.dynamicConditions.push({
          field: null,
          sign: 1,
          condition: 0,
          value: "",
        });
      } else if (
        index < this.dynamicConditions.length - 1 &&
        info.condition === 0
      ) {
        this.dynamicConditions.splice(
          index + 1,
          this.dynamicConditions.length - 1 - index
        );
      }
    },
    onConditionDelete(index) {
      if (this.dynamicConditions.length === 1) {
        this.dynamicConditions = [
          { field: null, sign: 1, condition: 0, value: "" },
        ];
      } else if (this.dynamicConditions.length > index) {
        this.dynamicConditions.splice(index, 1);
      }
    },
    getDynamicConditions() {
      let result = [];
      let dynamicConditions = [...this.dynamicConditions];
      dynamicConditions.forEach((element) => {
        if (element.field) {
          if (element.value && element.value.trim)
            element.value = element.value.trim();
          result.push(element);
        }
      });
      return result;
    },
    setDynamicConditions(dynamicConditions) {
      if (dynamicConditions && dynamicConditions.length > 0) {
        this.dynamicConditions = dynamicConditions;
      } else {
        this.dynamicConditions = [
          { field: null, sign: 1, condition: 0, value: "", selectFieldType: 1 },
        ];
      }
    },
  },
  beforeDestroy() {
    this.isSelfDestroy = true;
  },
};
</script>

<style scoped lang="scss">
.save-template {
  height: 100px;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.searchIcon {
  display: flex;
  align-items: center;

  > * {
    cursor: pointer;
    margin-right: 5px;
  }

  .badge-light {
    background-color: #6c757d;
    color: #fff;
  }
}
</style>
