import http from "../../node_modules/fe-infrastractures/src/utils/http";
import { globalEnv } from "fe-infrastractures";

const systemPATH = "";
export const getSystemInfo = () => {
  return http.get(
    "/sa/system_information",
    {},
    { loading: false, systemPATH, baseURL: globalEnv.VUE_APP_API_URL },
  );
};

export const getSysPermission = () => {
  // api/system/actions/getPermissionlist
  return http.get(
    "/api/system/actions/getPermissionlist",
    {},
    { loading: false, baseURL: globalEnv.VUE_APP_STD_URL },
  );
};

export const getSysLanguageRes = data => {
  return http.get("/anon/language", data, {
    loading: false,
    baseURL: globalEnv.VUE_APP_STD_URL,
  });
};

export const getSysLanguageList = data => {
  return http.get("/anon/language/list", data, {
    loading: false,
    baseURL: globalEnv.VUE_APP_STD_URL,
  });
};

export const getSysGeneralInfo = data => {
  return http.get("/anon/general", data, {
    loading: false,
    baseURL: globalEnv.VUE_APP_STD_URL,
  });
};

export const getSysMenuList = data => {
  return http.get("/anon/system/list", data, {
    systemPATH: "admin",
    loading: false,
    baseURL: globalEnv.VUE_APP_ADMIN_URL,
  });
};

export const getPortalSetting = () => {
  return http.get("/anon/portal-setting", {}, { loading: false, baseURL: globalEnv.VUE_APP_STD_URL });
};

export const getWorkerPortalPage = (type) => {
  return http.post("/anon/portal-page/setting", { pageName: portalpageRequestData(type) }, {
    loading: false,
    baseURL: globalEnv.VUE_APP_STD_URL,
    useBody: true,
  });
};


const portalpageRequestData = (type) => {
  let data;
  switch (type) {
    case 1:
      data = PageName.reportRecord;
      break;
    // case 2:
    //   data = PageName.campaignFinanceListData
    //   break
    case 3:
      data = PageName.homeNews;
      break;
    // case 4:
    //   data = PageName.campaignFinanceListFunction
    //   break
    case 5:
      data = PageName.top;
      break;
    // case 6:
    //   data = PageName.campaignFinanceHistoricReports
    //   break
    // case 7:
    //   data = PageName.campaignFinanceOutstandingReports
    //   break
    case 8:
      data = PageName.reportRecordSubmitSuccessful;
      break;
    case 9:
      data = PageName.login;
      break;
    case 10:
      data = PageName.forgotPasswordSendCode;
      break;
    case 11:
      data = PageName.candidateNominate;
      break;
    case 12:
      data = PageName.bottom;
      break;
    case 13:
      data = PageName.urlAccess;
      break;
  }
  return data;
};

export const PageName = {
  reportRecord: "ReportRecord",
  //campaignFinanceListData: "CampaignFinance-ListData",
  homeNews: "Home-News",
  //campaignFinanceListFunction: "CampaignFinance-ListFunction",
  top: "Top",
  //campaignFinanceHistoricReports: "CampaignFinance-HistoricReports",
  //campaignFinanceOutstandingReports: "CampaignFinance-OutstandingReports",
  reportRecordSubmitSuccessful: "ReportRecord-SubmitSuccessful",
  login: "Login",
  forgotPasswordSendCode: "ForgotPassword-SendCode",
  candidateNominate: "CandidateNominate",
  bottom: "Bottom",
  urlAccess: "URL-Access",
};

export const PageType = {
  reportRecord: 1,
  //campaignFinanceListData: 2,
  homeNews: 3,
  //campaignFinanceListFunction: 4,
  top: 5,
  //campaignFinanceHistoricReports: 6,
  //campaignFinanceOutstandingReports: 7,
  reportRecordSubmitSuccessful: 8,
  login: 9,
  forgotPasswordSendCode: 10,
  candidateNominate: 11,
  bottom: 12,
};

