import http from "../../utils/http";
import globalEnv from "../../libs/globalEnv";


export const getActivityLog = (paras) => {
  return http.post("/api/weblogs/page", paras, {
    useBody: true,
    loading: false,
    baseURL: globalEnv.VUE_APP_STD_URL,
  });
};
export const getActivityOtherLog = (paras) => {
  return http.post("/api/weblogs/page", paras, {
    useBody: true,
    loading: false,
    systemPATH: globalEnv.VUE_APP_Location_SYSTEM_PATH,
    baseURL: globalEnv.VUE_APP_PLM_URL,
  });
};

export const getCommunicationLogPage = (paras) => {
  return http.post("/api/communicationlogs/page", paras, {
    useBody: true,
    loading: false,
    baseURL: globalEnv.VUE_APP_STD_URL,
  });
};

export const saveCommunicationLog = (paras) => {
  return http.post("/api/communicationlogs", paras, {
    loading: true,
    baseURL: globalEnv.VUE_APP_STD_URL,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const downloadAttachment = (documentId, logRequestType) => {
  return http.down(
    "/api/communicationlogs/actions/download",
    { documentId, logRequestType },
    { loading: false, baseURL: globalEnv.VUE_APP_STD_URL },
  );
};

export const deleteAttachment = (documentId, logRequestType) => {
  return http.down(
    "/api/communicationlogs/actions/delete-file",
    { documentId, logRequestType },
    { loading: true, baseURL: globalEnv.VUE_APP_STD_URL },
  );
};

export const getCommunicationLog = (data) => {
  return http.get(`/api/communicationlogs`, data, {
    baseURL: globalEnv.VUE_APP_STD_URL,
  });
};

export const getEditCommunicationSendType = (paras) => {
  return http.get("/api/enum/edit-communication-send-type", paras, {
    loading: false,
    baseURL: globalEnv.VUE_APP_STD_URL,
  });
};

export const getElectionUseLog = (paras) => {
  return http.post("/api/electionUseLogs/page", paras, {
    useBody: true,
    loading: false,
    baseURL: globalEnv.VUE_APP_STD_URL,
  });
};

export const getSiteSurveyLog = (paras) => {
  return http.post("/api/surveylogs/page", paras, {
    useBody: true,
    loading: false,
    baseURL: globalEnv.VUE_APP_STD_URL,
  });
};

export const getBoundaryLog = (paras) => {
  return http.post("/api/boundaryLogs/page", paras, {
    useBody: true,
    loading: false,
    baseURL: globalEnv.VUE_APP_STD_URL,
  });
};

export const getAssessmentHistory = () => {
  return new Promise();
};

export const getWorkHistory = () => {
  return new Promise();
};

export const getApplicationHistory = () => {
  return new Promise();
};
