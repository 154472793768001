const routes = [
  {
    name: "payment",
    path: "/",
    meta: {
      title: "payment",
      pageTitle: "welcome",
    },
    component: () => import("../../views/payment/payment"),
    route: true,
    children: [],
  },
];

export default [
  {
    path: "/payment",
    route: false,
    component: () => import("@/layout/brief"),
    children: [...routes],
  },
];
