<template>
  <div class="content-page">
    <div class="content-form">
      <div class="nav-btn-group">
        <k-button-group>
          <k-button-back
            :back-route="isEvent ? 'eleMessageGroup' : 'messageGroup'"
          >
            buttons.back
          </k-button-back>
          <k-button
            @click="toggleView(false)"
            v-show="isPlainText"
            v-if="getEditPermission"
          >
            buttons.edit
          </k-button>
          <k-button @click="toggleView(true)" v-show="!isPlainText">
            buttons.view
          </k-button>
          <k-pre-next :has-save-next="false" ref="pn" />
        </k-button-group>
        <div class="nav-search">
          <search-bar
            v-if="isPlainText"
            :data-source="searchBarDataSource"
            query-keyword="keywords"
            :search-bar-fomatter="searchBarFomatter"
            @option-selected="searchSelected"
            sort="groupName"
            order="Asc"
            :other-conditions="{ electionId: electionId }"
          />
        </div>
      </div>
      <div class="content-main">
        <k-alert-msg ref="lblMsg" />
        <div class="content-navTab">
          <group-nav
            :is-event="isEvent"
            ref="nav"
            :tab-index="navIndex"
            :is-plain-text="isPlainText"
          />
        </div>
        <div class="content-party pt-3">
          <common-log
            :fields-type="logFieldsType.activityLog"
            :log-type="logType"
            :id="id"
            :sys-id="sysId"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import GroupNav from "../../components/groupNav";
import {
  getEleGroupSearchBarList,
  getGroupSearchBarList
} from "../../api/messageGroup";
import { logFieldsType } from "../../../log/const";
import { sysId } from "../../../../utils/enums/sysId";
import { activityLogType } from "../../../../utils/enums/logType";
import storeage from "../../../../utils/storeage";
import { permissionCode } from "../../mixins/permissionCode";

export default {
  mixins: [permissionCode],
  components: { GroupNav },
  computed: {
    searchBarDataSource: function () {
      if (this.isEvent) {
        return getEleGroupSearchBarList;
      } else {
        return getGroupSearchBarList;
      }
    },
    getEditPermission() {
      let code = this.PL_CODE?.Communication.GroupsEdit;
      return this.$has(code) || !this.isPermissionCode;
    }
  },
  created() {
    this.PL_CODE = this.isEvent
      ? this.permissionCodeEvent
      : this.permissionCode;
  },
  methods: {
    toggleView(view) {
      this.$router.push({
        name: this.$route.name,
        query: {
          id: this.id,
          isView: view ? "1" : "0",
          dbtn: this.$route.query.dbtn
        }
      });
    },
    searchBarFomatter(item) {
      return item.groupName;
    },
    searchSelected(item) {
      this.$router.push({
        name: this.$route.name,
        query: {
          id: item.id,
          hidePreNext: "1",
          isView: "1",
          dbtn: this.$route.query.dbtn
        }
      });
    }
  },
  data() {
    let isEvent = this.$route.meta.isEvent;
    return {
      isEvent: isEvent,
      id: this.$route.query.id,
      electionId: isEvent ? storeage.getElectionId() : "",
      isPlainText: this.$route.query.isView === "1",
      logFieldsType: logFieldsType,
      logType: activityLogType.group,
      sysId: sysId.plms,
      navIndex: 1
    };
  }
};
</script>
