import store from "@/store";
import operatePermission from "./operatePermission";
import globalEnv from "../../libs/globalEnv";

export const filterTabs = (pCodes, items) => {
  if(globalEnv.VUE_APP_Close_Permission){
    return items;
  }
  if(Array.isArray(items) && items.length){
    return items.filter((item) => {
      if(item.permissionCode){
        let itemCode = Array.isArray(item.permissionCode) ? item.permissionCode : [item.permissionCode];
        return pCodes.some((code) => itemCode.includes(code));
      }
      return true;
    });
  }
};
export const handleTabs = (items, vm) => {
  if(vm && vm.$route.meta.permissionCode?.indexOf && vm.$route.meta.permissionCode?.indexOf("Add") > - 1){
    let tempAry = items.filter((item) => item.permissionCode.indexOf("BasicInfo") > - 1);
    if(tempAry && tempAry.length){
      return [{
        title: tempAry[0].title,
        index: tempAry[0].index,
        route: vm.$route.name,
        permissionCode: tempAry[0].permissionCode,
      }];
    } else {
      return [];
    }
  } else {
    let tempList = operatePermission.getPermission();
    let perList = tempList ? tempList.split(",") : [];
    return filterTabs(perList, items);
  }
};

export const getTabFirstPathName = (items) => {
  let tabItems = handleTabs(items);
  return tabItems && tabItems.length ? tabItems[0].route : "";
};

export const getTabIndexByRouteName = (items, vm) => {
  if(items && items.length){
    return items.findIndex((item) => item.route == vm.$route.name);
  }
};
export const getTabIndex = (items, permissionCode, vm) => {
  if(!items) return;
  let pCode = permissionCode ? permissionCode : vm.$route.meta.permissionCode;
  let metaAry = Array.isArray(pCode) ? pCode : [pCode];
  return items.findIndex(item => {
    if(Array.isArray(item.permissionCode)){
      metaAry.sort();
      return item.permissionCode.sort().toString() === metaAry.toString();
    }
    return metaAry.includes(item.permissionCode);
  });
};

export const getTabsAndIndex = (code, vm) => {
  const tabItems = store.getters.pageTabs[code];
  const items = handleTabs(tabItems, vm);
  const tabindex = getTabIndex(items, null, vm);
  return { items, tabindex };
};

export const getRouterName = (tabItems) => {
  if(!tabItems) return false;
  let route = getTabFirstPathName(tabItems);
  if(route){
    return true;
  }
  return false;

};

export default {
  filterTabs,
  handleTabs,
  getRouterName,
  getTabFirstPathName,
  getTabIndex,
  getTabsAndIndex,
};
