var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._l((_vm.extFields),function(item,index){return [(item.type === 25)?_c('div',{key:index,staticClass:"content-title"}):(item.type !== 0)?_c('k-form-group',{key:item.displayName,attrs:{"label-class":item.isMandatory ? 'require-mark' : '',"label-suffix":item.displayName,"label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","no-locale":true,"label-for":"noneBind"}},[(item.type === _vm.fieldType.string)?_c('KValidationProvider',{attrs:{"rules":{ required: item.isMandatory && _vm.isAllValidate, max: 200 },"slim":"","name":`text${index}`},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),(item.type === _vm.fieldType.string)?_c('k-form-input',{attrs:{"type":"text","plaintext":_vm.handleplaintext(item.isOnlyView),"placeholder":item.placeholder},model:{value:(item.inputValue),callback:function ($$v) {_vm.$set(item, "inputValue", $$v)},expression:"item.inputValue"}}):_vm._e()]}}],null,true)}):_vm._e(),(item.type === _vm.fieldType.int)?_c('KValidationProvider',{attrs:{"rules":{
          required: item.isMandatory && _vm.isAllValidate,
          numberBetween: { minValue: -100000, maxValue: 100000 },
        },"slim":"","name":`number${index}`},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),(item.type === _vm.fieldType.int)?_c('k-form-input',{attrs:{"plaintext":_vm.handleplaintext(item.isOnlyView)},model:{value:(item.inputValue),callback:function ($$v) {_vm.$set(item, "inputValue", $$v)},expression:"item.inputValue"}}):_vm._e()]}}],null,true)}):_vm._e(),(item.type === _vm.fieldType.decimal2)?_c('KValidationProvider',{attrs:{"rules":{
          required: item.isMandatory && _vm.isAllValidate,
          float:true
        },"slim":"","name":`floatNumber${index}`},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),(item.type === _vm.fieldType.decimal2)?_c('k-form-input',{attrs:{"plaintext":_vm.handleplaintext(item.isOnlyView)},model:{value:(item.inputValue),callback:function ($$v) {_vm.$set(item, "inputValue", $$v)},expression:"item.inputValue"}}):_vm._e()]}}],null,true)}):_vm._e(),(item.type === _vm.fieldType.decimal3)?_c('KValidationProvider',{attrs:{"rules":{
          required: item.isMandatory && _vm.isAllValidate,
          floatThree:true
        },"slim":"","name":`floatThreeNumber${index}`},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),(item.type === _vm.fieldType.decimal3)?_c('k-form-input',{attrs:{"plaintext":_vm.handleplaintext(item.isOnlyView)},model:{value:(item.inputValue),callback:function ($$v) {_vm.$set(item, "inputValue", $$v)},expression:"item.inputValue"}}):_vm._e()]}}],null,true)}):_vm._e(),(item.type === _vm.fieldType.datetime)?_c('KValidationProvider',{attrs:{"rules":{
          required: item.isMandatory && _vm.isAllValidate,
          validateDateIsRequired: item.isMandatory && _vm.isAllValidate,
          validateDateFormat: true,
        },"slim":"","name":`datetime${index}`},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0 && !_vm.plaintext,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),(item.type === _vm.fieldType.datetime)?_c('k-form-datepicker',{staticClass:"mb-2",attrs:{"plaintext":_vm.handleplaintext(item.isOnlyView)},model:{value:(item.inputValue),callback:function ($$v) {_vm.$set(item, "inputValue", $$v)},expression:"item.inputValue"}}):_vm._e()]}}],null,true)}):_vm._e(),(item.type === _vm.fieldType.phone)?_c('KValidationProvider',{attrs:{"rules":{
          required: item.isMandatory && _vm.isAllValidate,
          phone: true,
          max: 50,
        },"slim":"","name":`phone${index}`},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),(item.type === _vm.fieldType.phone)?_c('k-form-phone-input',{attrs:{"plaintext":_vm.handleplaintext(item.isOnlyView),"is-phone":true},model:{value:(item.inputValue),callback:function ($$v) {_vm.$set(item, "inputValue", $$v)},expression:"item.inputValue"}}):_vm._e()]}}],null,true)}):_vm._e(),(item.type === _vm.fieldType.email)?_c('KValidationProvider',{attrs:{"rules":{
          required: item.isMandatory && _vm.isAllValidate,
          max: 200,
          email: true,
        },"slim":"","name":`email${index}`},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),(item.type === _vm.fieldType.email)?_c('k-form-input',{attrs:{"type":"text","plaintext":_vm.handleplaintext(item.isOnlyView)},model:{value:(item.inputValue),callback:function ($$v) {_vm.$set(item, "inputValue", $$v)},expression:"item.inputValue"}}):_vm._e()]}}],null,true)}):_vm._e(),(item.type === _vm.fieldType.select)?_c('KValidationProvider',{attrs:{"rules":{ required: item.isMandatory && _vm.isAllValidate },"slim":"","name":`select${index}`},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),(item.type === _vm.fieldType.select)?_c('k-form-select',{attrs:{"plaintext":_vm.handleplaintext(item.isOnlyView),"options":item.options},model:{value:(item.inputValue),callback:function ($$v) {_vm.$set(item, "inputValue", $$v)},expression:"item.inputValue"}}):_vm._e()]}}],null,true)}):_vm._e()],1):_vm._e()]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }