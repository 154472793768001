<template>
  <k-button
    v-bind="$props"
    v-on="listeners"
    @click="onClick"
    class="btn rounded-0"
    v-if="showButton"
  >
    buttons.delete
  </k-button>
</template>
<script>
import { BButton } from "bootstrap-vue";
import Button from "./button.vue";

const addedProps = {
  deleteHandler: {
    type: Function,
  },
  redirectRouteName: {
    type: String,
  },
  redirectQuery: {
    type: Object,
    default() {
      return null;
    },
  },
  delId: {
    type: String,
  },
  params: {
    type: Object,
    default() {
      return null;
    },
  },
  isShowButton: {
    type: Boolean,
    default() {
      return false;
    },
  },
  isConstantPrompt: {
    type: Boolean,
    default() {
      return true;
    },
  },
};
const btnProps = Object.assign({}, BButton.options.props, addedProps);
export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { BButton, Button },
  props: {
    ...btnProps,
  },
  computed: {
    listeners() {
      // eslint-disable-next-line no-unused-vars
      let { click, ...params } = this.$listeners;
      return params;
    },
    showButton() {
      if (this.isShowButton) {
        return this.isShowButton;
      }
      return this.$route.query?.dbtn === "1";
    },
  },
  methods: {
    onClick() {
      let pmOpt = this.$confirm({
        title: this.$g("const.confirm.confirm"),
        content: this.$g("const.confirm.deleteOne"),
        okText: this.$g("const.confirm.yes"),
        cancelText: this.$g("const.confirm.no"),
        cancelType: "secondary",
      });
      if (pmOpt == null) {
        return;
      }
      pmOpt.then(() => {
        let delParams = this.params ? this.params : [this.delId];
        this.deleteHandler(delParams).then((m) => {
          if (m.data.status === 1) {
            let query = this.redirectQuery || {};
            Object.assign(query, { restore: true });
            this.$router.push({
              name: this.redirectRouteName,
              query: query,
              params: {
                message: this.isConstantPrompt
                  ? this.$g("buttons.deleteSuccessfully")
                  : m.message,
                status: m.data.status,
              },
            });
          } else {
            this.$emit("delete-failed", m);
          }
        });
      });
    },
  },
};
</script>
