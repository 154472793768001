<template>
  <div>
    <div v-if="showOperate">
      <b-icon
        icon="trash"
        class="hand right"
        @click="onDeleteClick"
        font-scale="2"
        :title="$g('icon.deleteToolTip')"
      />
      <b-icon
        icon="plus-circle-fill"
        class="hand right"
        @click="onAddClick"
        font-scale="2"
        :title="$g('icon.addToolTip')"
      />
    </div>
    <konn-grid
      ref="selectableTable"
      :has-check-box="!isPlainText"
      :has-action="
        !isPlainText && (editPermissionCode ? has(editPermissionCode) : true)
      "
      :fields="fields"
      :row-read-only="true"
      :get-condition="getCondition"
      :data-source="getDocumentsList"
      @edit-clicked="onEditClicked"
      @row-selected="onRowSelected"
    >
      <template #cell(fileName)="row">
        <k-link @click="downloadPath(row.item.documentId)">
          {{ row.item.fileName }}
        </k-link>
      </template>
      <template #cell(actions)="row">
        <div v-if="row.item.readonly" />
        <k-link v-else @click="onEditClicked(row.item)">
          {{ $g("konnGrid.edit") }}
        </k-link>
      </template>
    </konn-grid>
  </div>
</template>

<script>
import {
  getDocuments,
  deleteDocuments,
  downloadDocumentUrl,
  isExit,
} from "./api";
import { repResult } from "../../libs/global";
import { has } from "../../utils/permission/operatePermission.js";

export default {
  props: {
    logType: {
      type: Number,
      default() {
        return 0;
      },
    },
    docType: {
      type: Number,
      default() {
        return 0;
      },
    },
    id: {
      type: String,
      default() {
        return "";
      },
    },
    editPermissionCode: {
      type: String,
      default() {
        return null;
      },
    },
    showOperate: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isPlainText: {
      type: Boolean,
      default() {
        return false;
      },
    },
    getDocumentsApi: {
      type: Function,
      default: getDocuments,
    },
    deleteDocumentsApi: {
      type: Function,
      default: deleteDocuments,
    },
    downloadDocumentUrlApi: {
      type: Function,
      default: downloadDocumentUrl,
    },
    isExitApi: {
      type: Function,
      default: isExit,
    },
  },
  data() {
    return {
      selected: [],
      selectMode: "multi",
      fields: [
        {
          key: "displayName",
          label: "document.columntitle",
          width: "33%",
        },
        { key: "fileName", label: "document.document", width: "34%" },
        {
          key: "createTimeString",
          label: "document.createtime",
          width: "33%",
        },
      ],
      isExit,
      has,
    };
  },
  computed: {
    getDocumentsList: function () {
      return this.getDocumentsApi;
    },
  },
  methods: {
    refresh() {
      this.$refs.selectableTable.refresh();
    },
    downloadPath(documentId) {
      let model = {
        DocRequestType: this.docType,
        LogRequestType: this.logType,
        DocumentId: documentId,
        RefId: this.id,
      };
      console.log(this.isExitApi);
      this.isExitApi(model).then((m) => {
        if (m.data.status === repResult.success) {
          this.downloadDocumentUrlApi(model);
        } else {
          this.$emit("show-message", m.data.status, m.message);
        }
      });
    },
    onRowSelected(items) {
      this.selected = items;
    },
    getCondition() {
      return {
        DocRequestType: this.docType,
        RefId: this.id,
        Sort: "createtime",
        Order: "Desc",
        PermissionCode: this.$route.meta.permissionCode,
      };
    },
    onEditClicked(item) {
      this.$router.push({
        name: `${this.$route.name}-detail`,
        query: {
          id: item.id,
          docType: this.docType,
          logType: this.logType,
          refId: this.id,
          documentId: item.documentId,
          requestTypeId: this.$route.query.requestTypeId,
        },
      });
    },
    onAddClick() {
      this.$router.push({
        name: `${this.$route.name}-detail`,
        query: {
          docType: this.docType,
          logType: this.logType,
          refId: this.id,
          isAdd: "1",
          requestTypeId: this.$route.query.requestTypeId,
        },
      });
    },
    onDeleteClick() {
      let pmOpt = this.$refs.selectableTable.confirmOpt(
        this.$g("const.confirm.delete")
      );
      if (pmOpt == null) {
        return;
      }
      pmOpt.then(() => {
        let sitems = this.$refs.selectableTable.getSelectedItems();
        let delIds = [];
        sitems.forEach(function (val) {
          delIds.push(val.id);
        });
        let model = {
          RefId: this.id,
          DocRequestType: this.docType,
          LogRequestType: this.logType,
          Ids: delIds,
        };
        this.deleteDocumentsApi(model).then((m) => {
          this.$refs.selectableTable.refresh();
          this.$emit("show-message", m.data.status, m.message);
        });
      });
    },
  },
  watch: {
    isPlainText() {
      this.$refs.selectableTable.refresh();
    },
  },
  mounted() {
    if (this.$route.query.status && this.$route.query.message) {
      this.$emit(
        "show-message",
        this.$route.query.status,
        this.$route.query.message
      );
    }
  },
};
</script>
<style scoped>
.hand:hover {
  cursor: pointer;
}

.right {
  float: right;
}
</style>
