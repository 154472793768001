import { PL_NAV_KEY } from '@/router/permissionCode'
import { getUserPermissionMenuList } from '@/api/user'
import { storeage } from 'fe-infrastractures'

const state = {
  nav: {},
  navToggle: {},
}

const mutations = {
  SET_NAV_ITEMS(state, navs) {
    state.nav = navs
  },
  SET_NAV_TOGGLE(state, navToggle) {
    state.navToggle = navToggle
  },
}

const actions = {
  saveNav({ commit, rootState }, para) {
    let navId = para.navId
    let condition = para.condition
    if (navId && condition) {
      let ts = rootState.nav.nav
      if (!ts) {
        ts = {}
      }
      ts[navId] = condition
      commit('SET_NAV_ITEMS', ts)
    }
  },
  saveNavToggle({ commit, rootState }, para) {
    let navToggleId = para.navToggleId
    let condition = para.condition
    if (navToggleId && condition) {
      let ts = rootState.nav.navToggle
      if (!ts) {
        ts = {}
      }
      ts[navToggleId] = condition
      commit('SET_NAV_TOGGLE', ts)
    }
  },
  getLocationNav({ commit }, res) {
    if (!res) return
    const plNav = getUserPermissionMenuList({
      Tenantkey: res.tenantkey,
      Types: 1024,
      UserId: res.userId,
      SysID: 32,
    })
    return Promise.all([plNav]).then((res) => {
      if (res && res.length) {
        let nav = {}
        nav[PL_NAV_KEY] = res[0].data
          ? res[0].data.map((item) => {
              return { ...item, toggle: false }
            })
          : []
        storeage.setLocationAllNavs(res[0]?.data)
        commit('SET_NAV_ITEMS', nav)
        return res
      }
      commit('SET_LOCATION_NAV', {})
      return []
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
