export let rules = {
  postcode: {
    //reg: /^\s*[A-Za-z]\d[A-Za-z]([\s\-]*)?\d[A-Za-z]\d\s*$/,
    reg: /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i,
    message: "Format Error! e.g K2K 4S4",
    formatfun: function (val) {
      if (val.length == 6) {
        return val.substring(0, 3) + " " + val.substring(3, 6);
      }

    }
  }
}
