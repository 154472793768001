import LayoutRouter from "../layout/router";

const __mapped_object = (obj, ins) => {
  if (obj instanceof Object) {
    return Object.keys(obj).reduce((e, k) => {
      e[k] = obj[k];
      return e;
    }, ins);
  }
};

/**
 * 路由工厂（注册定义路由）
 */
class RouterFactory {
  /**
   * 构造函数
   * @param {Function} raiseRoute 定义路由对象函数
   * @param {Function} raiseChild 定义子路由集合函数
   */
  constructor (raiseRoute, raiseChild) {
    this.routes = [];
    this._raiseRoute = raiseRoute;
    this._raiseChild = raiseChild;
  }

  /**
   * 注册路由
   * @param {Array / Object} routes 路由对象/集合
   */
  register (routes) {
    this.registerRoutes(routes, this.routes);
  }

  /**
   * 注册路由集合
   * @param {Array} routes 路由集合
   * @param {Array} instance 实例对象
   */
  registerRoutes (routes, instance) {
    return routes.reduce((ins, route) => {
      const _route = {};
      __mapped_object(route, _route);
      _route.meta = {};
      __mapped_object(route.meta, _route.meta);

      if (Array.isArray(route.children)) {
        if (route.route) {
          const _redirect = {};

          __mapped_object(route, _redirect);
          _redirect.meta = {};
          __mapped_object(route.meta, _redirect.meta);

          _route.component = LayoutRouter;

          delete _route.name;
          delete _route.route;
          delete _redirect.children;
          delete _redirect.route;

          this.raiseRoute(_redirect);
          ins.push(_redirect);
        }
        _route.children = [];
        const _child = this.registerRoutes(route.children, _route.children);

        this.raiseChild(_child, _route);
      }

      this.raiseRoute(_route);

      ins.push(_route);
      return ins;
    }, instance);
  }

  /**
   * 定义路由对象
   * @param {Object} route 路由对象
   */
  raiseRoute (route) {
    return this._raiseRoute?.call(this, route) || route;
  }

  /**
   * 定义路由集合
   * @param {Array} child 子路由集合
   * @param {Object} route 父路由对象
   */
  raiseChild (child, route) {
    return this._raiseChild?.call(this, child, route) || route;
  }
}

export default RouterFactory;
