<template>
  <KValidationObserver ref="observer" v-slot="valdata" class="content-page">
    <k-form
      @keydown.enter="isPrevent"
      @submit.prevent="onSubmit"
      class="content-form"
    >
      <div class="nav-btn-group">
        <slot name="formBar" v-bind="valdata" />
        <KWikiHelp :props-wiki-help-code="permissionCode.Report.BasicInfo" />
      </div>
      <div class="content-main">
        <slot name="formMsg" />
        <div class="content-navTab">
          <Nav
            ref="nav"
            :tab-index="navIndex"
            :is-event="isEvent"
            :is-add="!form.id"
          />
        </div>
        <div class="content-party pt-3">
          <div class="content-group">
            <k-form-group
              label-class="require-mark"
              label-suffix="template"
              label-align-md="right"
              label-cols-md="3"
              content-cols-lg="6"
              content-cols-md="7"
            >
              <k-form-input v-model="tempDisplayName" :plaintext="true" />
            </k-form-group>
            <k-form-group
              label-class="require-mark"
              label-suffix="title"
              label-align-md="right"
              label-cols-md="3"
              content-cols-lg="6"
              content-cols-md="7"
            >
              <KValidationProvider
                :rules="{
                  required: true,
                  max: 200,
                }"
                v-slot="{ errors }"
                slim
                name="title"
              >
                <k-alert :show="errors.length > 0" variant="warning">
                  {{ errors[0] }}
                </k-alert>
                <k-form-input v-model="form.title" :plaintext="false" />
              </KValidationProvider>
            </k-form-group>
            <k-form-group
              label-class="require-mark"
              label-suffix="category"
              label-align-md="right"
              label-cols-md="3"
              content-cols-lg="6"
              content-cols-md="7"
            >
              <KValidationProvider
                :rules="{
                  required: true,
                  max: 2000,
                }"
                v-slot="{ errors }"
                slim
                name="category"
              >
                <k-alert :show="errors.length > 0" variant="warning">
                  {{ errors[0] }}
                </k-alert>
                <k-form-select
                  v-model="form.categoryId"
                  :options="categoryList"
                  :plaintext="false"
                />
              </KValidationProvider>
            </k-form-group>
            <k-form-group
              label-class="require-mark"
              label-suffix="updateMethod"
              label-align-md="right"
              label-cols-md="3"
              content-cols-lg="6"
              content-cols-md="7"
            >
              <KValidationProvider
                :rules="{
                  required: true,
                  max: 2000,
                }"
                v-slot="{ errors }"
                slim
                name="updateMethod"
              >
                <k-alert :show="errors.length > 0" variant="warning">
                  {{ errors[0] }}
                </k-alert>
                <k-form-select
                  v-model="form.updateMethod"
                  :options="updateMethodList"
                  :plaintext="!canUpdateMethodEdit"
                />
              </KValidationProvider>
            </k-form-group>
            <k-form-group
              label-class="require-mark"
              label-suffix="viewType"
              label-align-md="right"
              label-cols-md="3"
              content-cols-lg="6"
              content-cols-md="7"
              v-if="isCustomReport"
            >
              <KValidationProvider
                :rules="{
                  required: true,
                }"
                v-slot="{ errors }"
                slim
                name="viewType"
              >
                <k-alert :show="errors.length > 0" variant="warning">
                  {{ errors[0] }}
                </k-alert>
                <k-form-select
                  v-model="form.viewType"
                  :options="viewTypeList"
                  :plaintext="false"
                />
              </KValidationProvider>
            </k-form-group>
            <k-form-group
              label-suffix="description"
              label-align-md="right"
              label-cols-md="3"
              content-cols-lg="6"
              content-cols-md="7"
            >
              <KValidationProvider
                :rules="{
                  max: 2000,
                }"
                v-slot="{ errors }"
                slim
                name="description"
              >
                <k-alert :show="errors.length > 0" variant="warning">
                  {{ errors[0] }}
                </k-alert>
                <k-form-textarea
                  v-model="form.description"
                  :plaintext="false"
                />
              </KValidationProvider>
            </k-form-group>
            <k-form-group
              label-class="require-mark"
              label-suffix="reportDisplayFields"
              label-align-md="right"
              label-cols-md="3"
              content-cols-lg="6"
              content-cols-md="7"
              v-if="isCustomReport"
            >
              <KValidationProvider
                rules="required"
                v-slot="{ errors }"
                slim
                name="reportDisplayFields"
              >
                <k-alert :show="errors.length > 0" variant="warning">
                  {{ errors[0] }}
                </k-alert>
                <DisplayFields
                  ref="displayFields"
                  v-model="form.selectedFields"
                  :report-template-id="form.reportTemplateId"
                  @fields-selected="onFieldsSelected"
                />
              </KValidationProvider>
            </k-form-group>

            <slot
              name="conditionPanel"
              :setShowCondition="setShowCondition"
              :reportEditDetailForm="form"
            />
            <div v-show="form.viewType == 2 && totalCount > 0">
              <div id="tooltipDescription" />
              <k-form-group
                label-suffix="dynamicTableViewConfigurationTemplates"
                label-align-md="right"
                label-cols-md="3"
                content-cols-lg="6"
                content-cols-md="7"
                v-if="isCustomReport"
              >
                <k-alert-msg ref="lblMsg" />
                <konn-grid
                  ref="selectableTable"
                  select-mode="multi"
                  :has-check-box="false"
                  :has-action="true"
                  :get-condition="getCondition"
                  :data-source="dataSource"
                  :auto-bind="true"
                  :set-paras="setParas"
                  :fields="fields"
                  :is-original-fields="true"
                  :grid-sort-desc="true"
                  grid-sort-by="CreateTime"
                  list-id="templateList"
                  @total-changed="onTotalChanged"
                >
                  <template #cell(actions)="row">
                    <b-icon-delete
                      v-if="!row.item.usedInWidget"
                      @click="delTemplateHandle(row.item.id)"
                    />
                    <span v-else />
                  </template>
                  <template #cell(description)="row">
                    <div
                      class="lineClamp"
                      v-b-tooltip="{
                        placement: 'bottom',
                        trigger: 'hover',
                        title: row.item.description,
                        boundary: 'scrollParent',
                        container: 'tooltipDescription',
                      }"
                    >
                      {{ row.item.description }}
                    </div>
                  </template>
                </konn-grid>
              </k-form-group>
            </div>
          </div>
        </div>
      </div>
    </k-form>
  </KValidationObserver>
</template>

<script>
import {
  getTemplateInfo,
  getReportDetail,
  getUpdateMethodList,
  getViewTypeList,
  getCategoriesList,
} from "../api/report";
import {
  getTemplatePage,
  deleteTemplateList,
} from "../../../api/searchTemplate";
import Nav from "./report-nav";
import { originDataChangeMixin } from "../../../utils/mixins/mixin";
import storeage from "../../../utils/storeage";
import DisplayFields from "./display-fields";

export default {
  mixins: [originDataChangeMixin],
  data() {
    let isEvent = this.$route.meta.isEvent ?? false;
    return {
      permissionCode: this.$route.meta.permissionCode,
      isEvent: isEvent,
      tempDisplayName: "",
      fieldsChanged: false,
      originalSelectedFields: [],
      form: {
        id: this.$route.query.id,
        reportTemplateId: this.$route.query.reportTemplateId,
        electionId: isEvent ? storeage.getElectionId() : "",
        tempName: "",
        title: "",
        categoryId: this.$route.query.categoryId,
        updateMethod: "",
        viewType: 1,
        description: "",
        selectedFields: [],
      },
      categoryList: [],
      updateMethodList: [],
      viewTypeList: [],
      navIndex: 0,
      tempUpdateMethod: [],
      isCustomReport: false,
      customConditionFields: [],
      canUpdateMethodEdit: true,
      hasCondition: true,
      templateType: 419,
      totalCount: 0,
      fields: [
        {
          key: "templateName",
          label: this.$g("name"),
          sortable: false,
          width: "25%",
          isDynamicCondition: true,
        },
        {
          key: "description",
          label: this.$g("description"),
          sortable: false,
          width: "27%",
          isDynamicCondition: true,
        },
        {
          key: "createBy",
          label: this.$g("creator"),
          sortable: false,
          width: "24%",
          isDynamicCondition: true,
        },
        {
          key: "createTime",
          label: this.$g("date"),
          sortable: false,
          width: "24%",
          isDynamicCondition: true,
        },
      ],
    };
  },
  components: {
    DisplayFields,
    Nav,
  },
  props: {
    reportTemplateId: {
      type: String,
      default() {
        return "";
      },
    },
    save: {
      type: Function,
    },
    validateFailed: {
      type: Function,
    },
  },
  computed: {
    dataSource: function () {
      return getTemplatePage;
    },
  },
  methods: {
    onTotalChanged(total) {
      this.totalCount = total;
    },
    setShowCondition(has) {
      this.hasCondition = has;
    },
    getSelectedFields() {
      return this.form.selectedFields;
    },
    bindUpdateMethodList() {
      getUpdateMethodList().then((resp) => {
        this.updateMethodList = resp.data || [];

        if (this.tempUpdateMethod && this.tempUpdateMethod.length == 1) {
          this.form.updateMethod = this.tempUpdateMethod[0];
          this.canUpdateMethodEdit = false;
          this.setOrignData(this.form);
        }
      });
    },
    bindViewTypeList() {
      getViewTypeList().then((resp) => {
        this.viewTypeList = resp.data || [];
      });
    },
    bindCategoriesList() {
      getCategoriesList({
        isBau: !this.isEvent,
      }).then((resp) => {
        this.categoryList = resp.data || [];
        this.updateTabIndex();
      });
    },
    checkFieldsChanged() {
      if (this.form.id) {
        let originalArray = [];
        this.originalSelectedFields.forEach((element) => {
          originalArray.push(element.fieldName);
        });
        let selectedArray = [];
        this.form.selectedFields.forEach((element) => {
          selectedArray.push(element.fieldName);
        });
        if (
          originalArray.sort().toString() != selectedArray.sort().toString()
        ) {
          this.fieldsChanged = true;
        } else {
          this.fieldsChanged = false;
        }
      }
    },
    onFieldsSelected() {
      this.$refs.observer.validate();
    },
    async onSubmit() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        if (this.form.selectedFields) {
          this.form.selectedFields.forEach((e) => {
            if (!e.needSum) {
              e.needSum = false;
            }
            if (!e.listOrder) {
              e.listOrder = false;
            }
          });
        }
        this.checkFieldsChanged();
        if (this.fieldsChanged) {
          let pmOpt = this.$confirm({
            title: this.$g("const.confirm.confirm"),
            content: this.$g("const.confirm.deleteAllViewTemplate"),
            cancelType: "secondary",
            okText: this.$g("const.confirm.yes"),
            cancelText: this.$g("const.confirm.no"),
          });
          if (pmOpt == null) {
            return;
          }
          pmOpt.then(() => {
            this.save(this.form);
            this.setOrignData(this.form);
            this.delAllTemplate();
            this.resetOriginalFields();
          });
        } else {
          this.save(this.form);
          this.setOrignData(this.form);
        }
      } else {
        if (this.validateFailed) {
          this.validateFailed();
        }
      }
    },
    bindListData() {
      this.bindCategoriesList();
      this.bindUpdateMethodList();
      this.bindViewTypeList();
      this.$emit(
        "getConditionPanelParams",
        this.isCustomReport,
        this.customConditionFields,
        this.form.reportTemplateId,
        this.form.id,
        this.form.tempName
      );
      this.setOrignData(this.form);
    },
    updateTabIndex() {
      if (
        this.categoryList &&
        this.categoryList.length > 0 &&
        this.form.categoryId
      ) {
        let index = this.categoryList.findIndex(
          (category) => category.value == this.form.categoryId
        );
        if (index >= 0) {
          this.$emit("setTabIndex", index);
        }
      }
    },
    isPrevent(e) {
      let event = e.srcElement || e.target;
      if (event.nodeName !== "TEXTAREA") {
        e.preventDefault();
      }
    },
    getCondition() {
      return {
        templateType: this.templateType,
        electionId: this.electionId,
        reportId: this.$route.query.id,
      };
    },
    setParas(para) {
      this.templateType = para.templateType;
      this.electionId = para.electionId;
      this.id = para.reportId;
    },
    delTemplateHandle(id) {
      let pmOpt = this.$confirm({
        title: this.$g("const.confirm.confirm"),
        content: this.$g("const.confirm.delete"),
        cancelType: "secondary",
        okText: this.$g("const.confirm.yes"),
        cancelText: this.$g("const.confirm.no"),
      });
      if (pmOpt == null) {
        return;
      }
      pmOpt.then(() => {
        deleteTemplateList(id).then((res) => {
          this.$refs.lblMsg.message(res.data.status, res.message);
          this.$refs.selectableTable.refresh();
        });
      });
    },
    delAllTemplate() {
      let ids = this.$refs.selectableTable.getAllIds();
      if (ids.length > 0) {
        deleteTemplateList(ids).then((res) => {
          this.$refs.lblMsg.message(res.data.status, res.message);
          this.$refs.selectableTable.refresh();
          this.resetOriginalFields();
        });
      }
    },
    resetOriginalFields() {
      this.originalSelectedFields = [];
      this.form.selectedFields.forEach((element) => {
        this.originalSelectedFields.push(element);
        this.fieldsChanged = false;
      });
    },
  },
  created() {
    if (this.form.id) {
      //edit
      getReportDetail(this.form.id).then((resp) => {
        let data = resp.data;
        if (data) {
          this.isCustomReport = data.isCustomReport;
          this.tempDisplayName = data.tempDisplayName;
          this.tempUpdateMethod = data.tempUpdateMethod;
          this.form.reportTemplateId = data.reportTemplateId;
          this.form.tempName = data.tempName;
          this.form.title = data.title;
          this.form.updateMethod = data.updateMethod;
          this.form.viewType = data.viewType;
          this.form.categoryId = data.categoryId;
          this.updateTabIndex();
          this.form.description = data.description;
          this.form.selectedFields = data.selectedFields;
          this.customConditionFields = data.customConditionFields;
          this.resetOriginalFields();
        }
        this.bindListData();
      });
    } else {
      //add
      getTemplateInfo(this.form.reportTemplateId).then((resp) => {
        let data = resp.data;
        if (data) {
          this.isCustomReport = data.isCustomReport;
          this.tempDisplayName = data.tempDisplayName;
          this.tempUpdateMethod = data.updateMethod;
          this.form.reportTemplateId = data.id;
          this.form.tempName = data.tempName;
          this.form.selectedFields = data.defaultReportFields;
          this.customConditionFields = data.customConditionFields;
        }
        this.bindListData();
      });
    }
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .b-table-sticky-header thead tr th::before {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    height: 1px;
    background-color: #dee2e6;
  }

  #tooltipDescription {
    --iconTooltipBackground: #ffffff;
    --iconTooltipFont: #333333;

    .bs-tooltip-top .arrow::before,
    .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      --iconTooltipBackground: #ffffff;
    }

    .tooltip-inner {
      max-width: 500px;
      padding: 10px;
      border: 1px solid #cccccc;
      box-shadow: 0px 0px 7px 2px rgba(74, 74, 74, 0.1);
      white-space: normal;
    }

    .tooltip[x-placement^="top"] .arrow::before {
      --iconTooltipBackground: #ffffff;
      content: "";
      padding: 0.4rem;
      background: #ffffff;
      border: 1px solid #cccccc;
      border-left-color: transparent;
      border-top-color: transparent;
      display: inline-block;
      transform: rotate(45deg);
      z-index: 9999;
      top: -7px;
      left: 0;
      position: relative;
    }

    .tooltip[x-placement^="bottom"] .arrow::after {
      content: "";
      padding: 0.4rem;
      background: #ffffff;
      border: 1px solid #cccccc;
      border-right-color: transparent;
      border-bottom-color: transparent;
      display: inline-block;
      transform: rotate(45deg);
      z-index: 9999;
      top: -1px;
      left: 0;
      position: relative;
    }
  }
}

.lineClamp {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: normal;
  -webkit-line-clamp: 3;
}

@media (min-width: 900px) and (max-height: 700px) {
  ::v-deep {
    #tooltipDescription {
      .tooltip-inner {
        max-width: 600px;
      }
    }
  }
}
</style>
