<template>
  <span>
    <span
      v-if="plaintext"
      class="col-form-label d-block"
    >
      {{ displayValue }}
    </span>
    <div
      class="datetimepicker"
      v-else
    >
      <k-form-datepicker
        v-model="date"
        :class="_props.class"
        :plaintext="plaintext"
        :dateformat="dateformat"
        class="datepicker-date"
        :is-time-stamp="false"
        :date-format-options="{
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        }"
        @change="onBlur"
        @blur="onBlur"
      />
      <k-form-timepicker
        v-model="time"
        :class="_props.class"
        :plaintext="plaintext"
        :is-time-stamp="false"
        :use-default="useDefault"
        class="datepicker-time"
        @onTimeChange="onBlur"
      />
    </div>
  </span>
</template>

<script>
import Moment from "moment-timezone";
import storeage from "../../utils/storeage";

export default {
  model: { prop: "value", event: "input" },
  props: {
    value: {
      type: Number,
      default(){
        return this.utc19900101;
      },
    },
    plaintext: {
      type: Boolean,
      default(){
        return false;
      },
    },
    useDefault: {
      type: Boolean,
      default(){
        return false;
      },
    },
    timeIsNull: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data(){
    return {
      displayValue: "",
      date: "",
      time: "",
      timeZone: storeage.getTimeZone(),
      dateformat: storeage.getDateFormat(),
      INVALID_DATE: - 1,
      INVALID_TIME: - 2,
      DATE_NULL: - 3,
      TIME_NULL: - 4,
      INVALID_DATESTR: "Invalid date",
      utc19900101: - 2208988800000,
      utc19900101str: "01-01-1900 00:00:00",
    };
  },
  mounted() {
    this.isPlaintextFunc();
  },
  methods: {
    onBlur() {
      this.$emit("blur");
    },
    isPlaintextFunc() {
      if (!this.plaintext) {
        this.setkvalue();
      } else {
        this.setDisplayValue();
      }
    },
    setDisplayValue() {
      if (this.value && this.value !== this.utc19900101) {
        let moment = Moment.tz(this.value, this.timeZone);
        this.displayValue = moment.format(`${this.dateformat} hh:mm A`);
      }
    },
    setkvalue() {
      if (
        this.value !== this.INVALID_DATE &&
        this.value !== this.INVALID_TIME &&
        this.value !== this.DATE_NULL &&
        this.value !== this.TIME_NULL
      ) {
        if (this.value && this.value !== this.utc19900101) {
          let moment = Moment.tz(this.value, this.timeZone);
          this.date = moment.format(this.dateformat);
          this.time = moment.format("HH:mm");
        } else {
          this.date = "";
          this.time = "";
        }
      }
    },
    formatInputDate(result) {
      let value =
        !this.timeIsNull || this.time
          ? `${this.date} ${this.time}`
          : `${this.date}`;
      const moment = Moment.tz(
        value,
        `${this.dateformat} HH:mm:ss`,
        this.timeZone
      );
      let timevalue = moment.valueOf();
      let sm = Moment.tz("1900-01-02", "YYYY-MM-DD", this.timeZone).valueOf();
      if(value === this.utc19900101str){
        timevalue = this.utc19900101;
      }
      if(timevalue >= sm){
        result = timevalue;
      } else {
        result = this.INVALID_DATE;
      }
      return result;
    },
    emitInput() {
      let result;
      if(this.date === this.INVALID_DATESTR){
        //date invalid first
        result = this.INVALID_DATE;
      } else if (
        ((this.date && this.date !== this.INVALID_DATESTR) || !this.date) &&
        this.time === this.INVALID_DATESTR
      ) {
        result = this.INVALID_TIME;
      } else if(!this.date && this.time){
        result = this.DATE_NULL;
      } else if (this.date && !this.time && !this.timeIsNull) {
        result = this.TIME_NULL;
      } else if(this.date && this.time){
        result = this.formatInputDate(result);
      } else if(!this.date && !this.time){
        result = this.utc19900101;
      } else if (this.date && !this.time && this.timeIsNull) {
        result = this.formatInputDate(result);
      } else {
        result = this.INVALID_DATE;
      }

      this.$emit("input", result);
      console.log(this.date, this.time, result);
    },
  },
  watch: {
    date(){
      this.emitInput();
    },
    time(){
      this.emitInput();
    },
    value(){
      this.isPlaintextFunc();
    },
    plaintext(){
      this.isPlaintextFunc();
    },
  },
};
</script>
