<template>
  <b-form-checkbox-group
    v-model="selected"
    :options="options"
    stacked
    :disabled="plaintext"
    @change="checkChange"
  />
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    plaintext: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      selected: [],
    };
  },
  mounted() {
    if (this.value) {
      if (this.trueTypeOf(this.value) === "string") {
        this.selected = this.value.split(",");
      }
    }
  },
  methods: {
    trueTypeOf(item) {
      return Object.prototype.toString.call(item).slice(8, -1).toLowerCase();
    },
    checkChange(val) {
      this.$emit("input", String(val));
      this.$emit("blur");
    },
  },
};
</script>
