<template>
  <detail
    ref="detail"
    :is-plain-text="false"
    :id="id"
    :is-event="isEvent"
    @save-successful="onSaveSuccessful"
    :is-notification="isNotification"
    :props-wiki-help-code="wikiHelpCode"
  >
    <template #formBar>
      <div>
        <k-button-back :back-route="getBackRoute()">
          buttons.back
        </k-button-back>
        <k-button
          type="submit"
          variant="primary"
          class="mr-1"
          ref="btnSave"
        >
          buttons.save
        </k-button>
        <k-button
          @click="onRedirectView"
          v-show="$route.query.id != null"
        >
          buttons.view
        </k-button>
        <!--        <k-pre-next-->
        <!--          ref="pn"-->
        <!--          :has-save-next="true"-->
        <!--          :before-to-leave="preNextToLeave"-->
        <!--        />-->
      </div>
    </template>
    <template #formBarNav>
      <k-alert-msg ref="lblMsg" />
      <template-nav
        :is-event="isEvent"
        ref="nav"
        :tab-index="navIndex"
        :is-plain-text="false"
        :is-notification="isNotification"
      />
    </template>
  </detail>
</template>
<script>
import detail from "../../components/documentTemplateDetail.vue";
import { repResult } from "../../../../libs/global";
import templateNav from "../../components/template-nav.vue";
import { saveAlert } from "../../../../utils/saveAlert";
import { beforeRouteLeaveMixin } from "../../../../utils/mixins/mixin";
import storeage from "../../../../utils/storeage";
import { permissionCode } from "../../mixins/permissionCode";

export default {
  mixins: [beforeRouteLeaveMixin, permissionCode],
  components: { detail, templateNav },
  data(){
    let isEvent = this.$route.meta.isEvent;
    return {
      isEvent: isEvent,
      id: this.$route.query.id,
      electionId: isEvent ? storeage.getElectionId() : "",
      navIndex: 0,
      wikiHelpCode: isEvent
          ? this.$wikiHelpLinkCodes?.Event.Communication.Template.Document
              .BasicInfo
          : this.$wikiHelpLinkCodes?.BAU.Communication.Template.Document.BasicInfo,
    };
  },
  methods: {
    getBackRoute(){
      let routeName = "";
      if(this.isNotification){
        routeName = this.isEvent
            ? "eleNotificationDocumentTemplateList"
            : "notificationDocumentTemplateList";
      } else {
        routeName = this.isEvent
            ? "eleDocumentTemplateList"
            : "documentTemplateList";
      }
      return routeName;
    },
    getRouteNameView(){
      let routeName = "";
      if(this.isNotification){
        routeName = this.isEvent
            ? "eleNotificationDocumentTemplateView"
            : "notificationDocumentTemplateView";
      } else {
        routeName = this.isEvent
            ? "eleDocumentTemplateView"
            : "documentTemplateView";
      }
      return routeName;
    },
    onRedirectView(){
      this.$router.push({
        name: this.getRouteNameView(),
        query: {
          id: this.$route.query.id,
          isView: "1",
          hidePreNext: this.$route.query.hidePreNext,
          dbtn: this.$route.query.dbtn,
          templateType: this.$route.query.templateType,
          tabindex: this.$route.query.tabindex,
        },
      });
    },
    onSaveSuccessful(m){
      this.isDoSave = true;
      // if (this.$refs.pn.checkSaveNexted()) {
      //   this.$refs.pn.goToNext(m);
      // } else {
      this.$router.push({
        name: this.getRouteNameView(),
        query: {
          id: m.data.info,
          message: m.message,
          status: m.data.status,
          isView: "1",
          hidePreNext: this.$route.query.hidePreNext,
          dbtn: this.$route.query.dbtn,
          templateType: this.$route.query.templateType,
        },
        params: {
          message: m.message,
          status: m.data.status,
        },
      });
      // }
    },
    showAlert(){
      let status = saveAlert.savedStatus(this.$route);
      if(status === repResult.success){
        this.$refs.lblMsg.message(status, saveAlert.savedMessage(this.$route));
      }
    },
  },
  mounted(){
    this.showAlert();
  },
};
</script>
