export const sysId = {
  all: - 1,
  unknown: 0,
  plms: 1,
  pwms: 2,
  passTracking: 3,
  pams: 4,
  passTrackingPortal: 5,
  writeRecord: 6,
  writeRecordPortal: 7,
  pems: 8,
  assetWap: 9,
  poll_call: 16,
  poll_access: 32,
  helpDeskWap: 17,
  pAdmin: 64,
  rms: 128,
  communication: 256,
  candidate: 512,
  candidatePortal: 513,
  login: 1024,
  elector: 2048,
  electorPortal: 2049,
  partyPortal: 4096,
  partyManage: 8192,
  event: 16384,
  rOPAMS: 32768,
  voting: 65536,
  voteResults: 131072,
  ePollbook: 262144,
  telephoneVoting: 524288,
  selfServicePortal: 1048576,
  voteResultsPortal: 2097152,
  myProfile: 4194304,
  industrialVoteResultsPortal: 8388608,
  webService: 16777216,
  tenant: 33554432,
  tenantAsset: 33554433,
  myBallot: 67108864,
  myBallotPortal: 134217728,
  idP: 268435456,
};
