<template>
  <div class="content-page">
    <div class="nav-btn-group">
      <b-btn-group>
        <k-button-back
          :back-route="getMessageDeliveryLetterListRouteName()"
          :use-hisotry-back="isUseHisotryBack"
        >
          buttons.back
        </k-button-back>
        <k-export-button
          v-if="isExport"
          :condition-getter="getSearchState"
          :export-handler="exportHandler"
          :check-has-data="checkHasData"
        >
          buttons.export
        </k-export-button>
        <k-export-button
          :params-getter="getExportCSV"
          :export-handler="exportPdfHandler"
          :exported-callback="exportCallbacker"
          :check-has-data="checkHasData"
          v-if="hasDownloadPDF && permissionHasDownloadPDF"
        >
          buttons.downloadPDF
        </k-export-button>
        <k-pre-next
          ref="pn"
          :has-save-next="false"
        />
      </b-btn-group>
      <KWikiHelp :props-wiki-help-code="wikiHelpCode" />
    </div>
    <div class="content-main">
      <k-alert-msg ref="lblMsg" />
      <div class="content-party">
        <div class="content-title">
          {{ data.subject }}
        </div>
        <div class="content-group">
          <k-form-group
            label-align-md="right"
            label-cols-md="2"
            content-cols-lg="7"
            content-cols-md="8"
            label-suffix="printTime"
          >
            <div class="col-form-label">
              {{ data.printTime }}
            </div>
          </k-form-group>
          <k-form-group
            label-align-md="right"
            label-cols-md="2"
            content-cols-lg="7"
            content-cols-md="8"
            label-suffix="recipients"
          >
            <k-form-select
              v-model="rescipient"
              :options="data.rescipients"
              @change="initSelectData"
            />
          </k-form-group>
          <k-form-group
            label-align-md="right"
            label-cols-md="2"
            content-cols-lg="7"
            content-cols-md="8"
            label-suffix="subject"
          >
            <k-form-input
              type="text"
              v-model="selectData.subject"
              :plaintext="true"
            />
          </k-form-group>
          <k-form-group
            label-align-md="right"
            label-cols-md="2"
            content-cols-lg="7"
            content-cols-md="8"
            label-suffix="message"
          >
            <div
              v-html="selectData.message"
              class="border p-3"
            />
          </k-form-group>
        </div>
        <div class="content-table-group">
          <konn-grid
            :has-check-box="hasCheckBox"
            :has-action="hasAction"
            ref="selectableTable"
            :data-source="dataSource"
            :get-condition="getCondition"
            grid-sort-by="subject"
            @total-changed="onTotalChanged"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getDeliveryResultLetterDetailSelectRecipient,
  getDeliveryResultLetterDetailInfo,
  getLetterDeliveryResultDetailList,
  exportDetailPage,
  getLetterDeliveryResultDetailDownloadPDF,
} from "../../api/messageDeliveryResult";
import { repResult } from "../../../../libs/global";
import { saveAlert } from "../../../../utils/saveAlert";
import { has } from "../../../../utils/permission/operatePermission";
import storeage from "../../../../utils/storeage";
import { permissionCode } from "../../mixins/permissionCode";
import { DeliveryResults } from "../../mixins/messagePage";
import { startTaskProgress } from "../../../../business-components/common-remote-task/remoteTaskStarter";

export default {
  mixins: [permissionCode, DeliveryResults],
  data(){
    let isEvent = this.$route.meta.isEvent;
    return {
      isEvent: isEvent,
      data: {
        printTime: "",
        subject: "",
        message: "",
        totalCount: 0,
        rescipients: [],
      },
      hasCheckBox: true,
      hasAction: false,
      queryParams: {
        id: this.$route.query.id,
        electionId: isEvent ? storeage.getElectionId() : "",
      },
      isUseHisotryBack: this.$route.query.historyBack === "1",
      isExport: true,
      permissionHasDownloadPDF: false,
      rescipient: null,
      selectData: {
        subject: "",
        message: "",
      },
      wikiHelpCode: null,
    };
  },
  props: {
    hasDownloadPDF: {
      type: Boolean,
      default(){
        return false;
      },
    },
    propApi: {
      type: Object,
      default(){
        return {
          getDeliveryResultLetterDetailSelectRecipient,
          getDeliveryResultLetterDetailInfo,
          getLetterDeliveryResultDetailList,
          exportDetailPage,
          getLetterDeliveryResultDetailDownloadPDF,
        };
      },
    },
  },
  components: {},
  computed: {
    dataSource: function (){
      return this.propApi.getLetterDeliveryResultDetailList;
    },
    exportHandler: function (){
      return this.propApi.exportDetailPage;
    },
    exportPdfHandler(){
      return this.propApi.getLetterDeliveryResultDetailDownloadPDF;
    },
  },
  created(){
    this.initWikiHelpCode();
  },
  methods: {
    initWikiHelpCode(){
      if(this.isNotification){
        this.wikiHelpCode = this.isEvent
            ? this.$wikiHelpLinkCodes?.Event.Notification?.DeliveryResult?.Letter
                .DeliveryReport
            : this.$wikiHelpLinkCodes?.BAU.Notification?.DeliveryResult?.Letter
                .DeliveryReport;
      } else {
        this.wikiHelpCode = this.isEvent
            ? this.$wikiHelpLinkCodes?.Event.Communication?.DeliveryResult?.Letter
                .DeliveryReport
            : this.$wikiHelpLinkCodes?.BAU.Communication?.DeliveryResult?.Letter
                .DeliveryReport;
      }
    },
    setPermission(){
      if(this.isEvent){
        this.isExport = has(
            this.permissionCodeEvent.Communication.LetterResultDetailExport,
        );
        if(
            this.permissionCodeEvent.Communication.LetterResultDetailDownloadPDF
        ){
          this.permissionHasDownloadPDF = has(
              this.permissionCodeEvent.Communication.LetterResultDetailDownloadPDF,
          );
        }
      } else {
        this.isExport = has(
            this.permissionCode.Communication.LetterResultDetailExport,
        );
      }
    },
    onTotalChanged(total){
      this.totalCount = total;
    },
    checkHasData(){
      if(!this.totalCount){
        this.$refs.lblMsg.message(
            repResult.warning,
            this.$g("const.confirm.exportNoData"),
        );
        return false;
      } else {
        return true;
      }
    },
    getSearchState(){
      let condition = this.$refs.selectableTable.getCondition();
      return this.$refs.selectableTable.getSearchState(condition);
    },
    showAlert(){
      let status = saveAlert.savedStatus(this.$route);
      if(status === repResult.success){
        this.$refs.lblMsg.message(status, saveAlert.savedMessage(this.$route));
      }
    },
    getRecipientsContent(){
      this.propApi
          .getDeliveryResultLetterDetailInfo(this.queryParams.id)
          .then((data) => {
            this.data = data.data;
            if(!this.rescipient){
              this.rescipient = this.data.rescipients[0].value;
            }
            this.initSelectData(this.rescipient);
          });
    },
    initSelectData(id){
      this.propApi
          .getDeliveryResultLetterDetailSelectRecipient({ id: id })
          .then((data) => {
            this.selectData = data.data;
          });
    },
    getCondition(){
      return this.queryParams;
    },
    getExportCSV(){
      return {
        id: this.$route.query.id,
      };
    },
    exportCallbacker(rep){
      startTaskProgress(rep.data.info);
    },
  },
  mounted(){
    this.showAlert();
    this.getRecipientsContent();
    this.setPermission();
  },
};
</script>

<style scoped>
.message {
  padding: 12px 12px 12px 12px;
  text-align: center;
}

.notice {
  background-color: #f2f2f2;
}
</style>
