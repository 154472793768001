import http from "../../../utils/http";
import globalEnv from "../../../libs/globalEnv";

export const getSendDataInit = (data) => {
  return http.post(`/api/msgsend/actions/contact`, data, {
    useBody: true,
    loading: false,
  });
};

export const getContatDropdown = (data) => {
  return http.post(`/api/msgsend/actions/getcontatdropdown`, data, {
    useBody: true,
  });
};

export const getPreview = (data) => {
  return http.post(`/api/msgsend/actions/preview`, data, { useBody: true });
};

export const getEmailSender = (data) => {
  return http.get(`/api/msgsend/actions/getemailsender`, data);
};

export const getPhoneNumbers = (data) => {
  return http.get(`/api/msgsend/actions/getephonenumber`, data);
};

export const getCallerIDs = (data) => {
  return http.get(`/api/msgsend/actions/getecallerid`, data);
};

export const sendEmail = (data) => {
  return http.post(`/api/msgsend/actions/sendemail`, data, {
    loading: true,
    baseURL: globalEnv.VUE_APP_STD_URL,
  });
};

export const sendSMS = (data) => {
  return http.post(`/api/msgsend/actions/sendmessage`, data, {
    loading: true,
    baseURL: globalEnv.VUE_APP_STD_URL,
  });
};

export const sendPhone = (data) => {
  return http.post(`/api/msgsend/actions/sendphone`, data, {
    loading: true,
    baseURL: globalEnv.VUE_APP_STD_URL,
  });
};

export const saveEmailDraft = (data) => {
  return http.post(`/api/msgsend/actions/emaildraft`, data, {
    loading: true,
    baseURL: globalEnv.VUE_APP_STD_URL,
  });
};

export const saveSMSDraft = (data) => {
  return http.post(`/api/msgsend/actions/messagedraft`, data, {
    loading: true,
    baseURL: globalEnv.VUE_APP_STD_URL,
  });
};

export const savePhoneDraft = (data) => {
  return http.post(`/api/msgsend/actions/phonedraft`, data, {
    loading: true,
    baseURL: globalEnv.VUE_APP_STD_URL,
  });
};

export const saveLetterDraft = (data) => {
  return http.post(`/api/msgletter/actions/draft`, data);
};

export const getContactAction = () => {
  return http.get(`/api/msgsend/enum/contact-action`);
};

export const getContacts = (data) => {
  return http.post(`/api/msgsend/page/contact`, data, { useBody: true });
};

export const letterExportToPDF = (body) => {
  return http.post(`/api/msgsend/actions/letterexporttopdf`, body, { useBody: true });
};

export const getCaptcha = (para) => {
  var baseURL = globalEnv.VUE_APP_STD_URL;
  var url = `/anon/captcha?msgid=${para.msgid}&rid=${para.rid}&t=${para.t}&act=${para.act}&key=${para.key}`;
  if (globalEnv.VUE_APP_SYSTEM_PATH) {
    let pathSuff = "/" + globalEnv.VUE_APP_SYSTEM_PATH;
    url = pathSuff + url;
  }

  return baseURL + url;
};

export const verifyCode = (data) => {
  return http.post(`/anon/captcha/actions/verify`, data);
};

export const getResonseResult = (data) => {
  return http.post(`/anon/captcha/actions/resonseresult`, data);
};

export const getInsertLabels = (data) => {
  return http.post(`/api/msgsend/page/insertlabels`, data);
};
export const getPersonLanguages = (data) => {
  return http.get(`/api/msgsend/person/language`, data);
};
export const dowGenerateMailingLabels = (data) => {
  return http.down(`/api/msgsend/actions/generate-mailing-labels`, data, { useBody: true });
};
