export const enumGroupEditTypeValue = {
  unknown: '0',
  bookingStatus: '1',
  bookingType: '2',
  votingStationSize: '3',
  requiredPayment: '4',
  bookingsPaid: '5',
  facilityStatus: '6',
  facilityDesignation: '7',
  boundaryType: '8',
  vOAssignment: '9',
  useFor: '10',
}
