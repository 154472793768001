<template>
  <button
    class="btn rounded-0 nav-btn-search"
    ref="search"
    @click="search"
  >
    <b-icon icon="search" />
    <b-tooltip
      ref="tooltip"
      :target="() => $refs['search']"
      :title="$g('icon.search')"
      boundary="window"
      :container="uid"
      placement="bottomleft"
      triggers="hover"
    />
    <div :id="uid" />
  </button>
</template>
<script>
export default {
  data () {
    return {
      uid: 'tip' + this._uid,
    }
  },
  methods: {
    search () {
      this.$emit('click')
    },
  },
}
</script>
