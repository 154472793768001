const state = {
    toggleState: {}
}

const mutations = {
    SET_NAV_TOGGLE(state1, condition) {
        state1.toggleState = condition
    },
}

const actions = {
    saveNavToggle({ commit, rootState }, condition) {
        var ts = { ...rootState.menu.toggleState }
        ts[condition.type] = condition.value
        commit('SET_NAV_TOGGLE', ts)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}