export const UserPermissionMenuList = {
  "code": 200,
  "data": [
    {
      "menu": {
        "id": "68618d9f-4732-4587-99ee-956f2c7e0cee",
        "menuName": "Home",
        "sortIndex": 1,
        "parentID": null,
        "route": "home",
        "menuIcon": "",
        "displayIcons": true,
      },
      "childMenu": null,
    },
    {
      "menu": {
        "id": "c23bbc74-d5ba-4aa6-8fda-68726ed6abba",
        "menuName": "Work Assignments",
        "sortIndex": 2,
        "parentID": null,
        "route": "workAssignments",
        "menuIcon": "",
        "displayIcons": true,
      },
      "childMenu": null,
    },
    {
      "menu": {
        "id": "79129587-ed23-4a2b-8de4-00a2d3364d4f",
        "menuName": "Training",
        "sortIndex": 4,
        "parentID": null,
        "route": "training",
        "menuIcon": "",
        "displayIcons": true,
      },
      "childMenu": null,
    },
    {
      "menu": {
        "id": "81d3ba05-3401-4087-94b0-4744b7f13a9d",
        "menuName": "Work/Training History",
        "sortIndex": 5,
        "parentID": null,
        "route": "workTrainingHistory",
        "menuIcon": "",
        "displayIcons": true,
      },
      "childMenu": null,
    },
    {
      "menu": {
        "id": "66d34b19-55bd-4193-a8ad-4dfe59sss9be2fa",
        "menuName": "Payment",
        "sortIndex": 10,
        "parentID": null,
        "route": "payment",
        "menuIcon": "",
        "displayIcons": true,
      },
      "childMenu": null,
    },
    {
      "menu": {
        "id": "66d34b19-55bd-4193-a8ad-4dfeaaa599be3fa",
        "menuName": "My Profile",
        "sortIndex": 11,
        "parentID": null,
        "route": "",
        "menuIcon": "",
        "displayIcons": true,
      },
      "childMenu": null,
    },
    {
      "menu": {
        "id": "66d34b19-55bd-4193-a8ad-ddd4dfe599be4fa",
        "menuName": "Messages",
        "sortIndex": 12,
        "parentID": null,
        "route": "messages",
        "menuIcon": "",
        "displayIcons": true,
      },
      "childMenu": null,
    },
    {
      "menu": {
        "id": "66d34b19-55bd-4193aaa-a8ad-4dfe599be5fa",
        "menuName": "Surveys",
        "sortIndex": 13,
        "parentID": null,
        "route": "",
        "menuIcon": "",
        "displayIcons": true,
      },
      "childMenu": null,
    },
  ],
  "desc": "Successfully",
  "warning": "",
  "exception": null,
};

