<template>
  <div class="content-page">
    <election-select v-if="isEvent" />
    <div class="content">
      <div class="right-bar" v-show="seenSearch">
        <b-icon icon="x" @click="onToggleSearch" />
        <search-area
          :show-template="showSearchAreaTemplate"
          :type-name="typeName"
          :search-click="search"
          :clear-click="clear"
          :get-paras="getCondition"
          :set-paras="setParas"
          ref="searchArea"
          :fields="fields"
          :show-dynamic="hasSearchAreaDynamic"
        >
          <k-form-group label-suffix="search.title">
            <k-form-input v-model="queryParams.title" />
          </k-form-group>
          <k-form-group label-suffix="search.fileName">
            <k-form-input v-model="queryParams.filename" />
          </k-form-group>
          <k-form-group label-suffix="search.category">
            <k-multiselect
              :options="categories"
              v-model="queryParams.category"
            />
          </k-form-group>
        </search-area>
      </div>
      <div class="center-bar">
        <div class="nav-btn-group">
          <k-button-group>
            <k-button
              v-text="$g('documentationCategory')"
              @click="toMessageTemplateCategoryList"
              v-if="documentationCategory"
            />
          </k-button-group>
          <button
            class="btn rounded-0 nav-btn-search"
            @click="onToggleSearch"
            :class="{ active: isActive }"
          >
            <b-icon icon="search" />
          </button>
          <KWikiHelp :props-wiki-help-code="wikiHelpCode" />
        </div>

        <div class="content-main">
          <k-alert-msg ref="lblMsg" />
          <div class="content-navTab">
            <div class="nav-tools">
              <b-icon-add v-if="documentAdd" @click="addTemplateHandle" />
              <b-icon-delete v-if="documentDel" @click="delTemplateHandle" />
              <column-config-icon
                v-if="hasColumnConfig"
                :list-id="isEvent ? 'eletemplatedoclist' : 'templatedoclist'"
                @column-changed="onColumnChanged"
              />
            </div>
            <status-Nav
              :refresh="navClick"
              ref="navTabs"
              :items="navItems"
              :current-index="tabindex"
              :need-total-count="true"
            />
          </div>

          <div class="content-party">
            <konn-grid
              :select-mode="selectMode"
              :has-check-box="hasCheckBox"
              :has-action="documentEdit && hasAction && !electionIsArchived"
              ref="selectableTable"
              :data-source="dataSource"
              :get-condition="getCondition"
              @row-selected="onRowSelected"
              @total-changed="onTotalChanged"
              @fields-get="onFieldsGet"
              grid-sort-by="title"
              :set-paras="setParas"
              :list-id="
                isEvent ? 'eleDocumentTemplateList' : 'documentTemplateList'
              "
              :auto-bind="false"
              :sticky-header="true"
              class="stickyTable"
            >
              <template #cell(title)="row">
                <k-link @click="downloadFile(row.item.docId)">
                  {{ row.value }}
                </k-link>
              </template>

              <template #cell(actions)="row">
                <k-link @click="onEditClick(row.item)" class="mr-1">
                  Edit
                </k-link>
              </template>

              <template #cell(size)="row">
                {{ `${toThousands(row.value)} KB` }}
              </template>
            </konn-grid>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getUseFor,
  getCategories,
  getDocumentList,
  delTemplate,
  getSumcount,
  downFilesById,
} from "../../api/messageTemplate";
import statusNav from "../../components/common-nav";
import { enumTemplateType } from "../../enums/enumTemplateType";
import { repResult } from "../../../../libs/global";
import { saveAlert } from "../../../../utils/saveAlert";
import storeage from "../../../../utils/storeage";
import { has } from "../../../../utils/permission/operatePermission";
import { handleTabs } from "../../../../utils/permission/permission";
import { permissionCode } from "../../mixins/permissionCode";
import { getCurPageConfigbyRoutePath } from "../../../../utils/tools";
import { enumSearchType } from "../../../../utils/enums/enumSearchType";

export default {
  mixins: [permissionCode],
  props: {
    hideNav: {
      type: Array,
      default() {
        return [];
      },
    },
    hasSearchAreaDynamic: {
      type: Boolean,
      default() {
        return false;
      },
    },
    addDocId: {
      type: Boolean,
      default() {
        return false;
      },
    },
    hasColumnConfig: {
      type: Boolean,
      default() {
        return true;
      },
    },
    // hasElection: {
    //   type: Boolean,
    //   default() {
    //     return false
    //   },
    // },
  },
  components: {
    statusNav,
  },
  data() {
    let isEvent = this.$route.meta.isEvent;
    let curPageConfig = getCurPageConfigbyRoutePath(
      this.$route.meta.project,
      this.$route.path
    );
    return {
      isEvent: isEvent,
      typeName: isEvent
        ? "eleCommunicationDocumentTemplate"
        : "communicationDocumentTemplate",
      queryParams: {
        electionId: isEvent ? storeage.getElectionId() : "",
        title: "",
        category: [],
        useFor: [],
        isNotification: this.isNotification,
      },
      tabindex: 4,
      fields: [],
      navItems: [],
      items: [],
      selectMode: "multi",
      selected: [],
      hasCheckBox: true,
      hasAction: true,
      showSearchAreaTemplate: false,

      categories: [],
      useFors: [],
      seenSearch: true,
      isActive: true,
      initPaymentStatus: [],
      curNav: {},
      showSearchStatus: true,
      enumTemplateType: enumTemplateType,
      documentAdd: false,
      documentDel: false,
      documentEdit: false,
      documentationCategory: false,
      electionIsArchived: storeage.isElectionIsArchived(),
      wikiHelpCode: "",
      hiddenNav: curPageConfig?.templateTabs?.hidNav ?? [],
    };
  },

  computed: {
    dataSource: function () {
      return getDocumentList;
    },
  },

  mounted() {
    this.bindUseFors();
    this.initNav();
    this.showAlert();
    this.initWikiHelpCode();
  },

  methods: {
    initWikiHelpCode() {
      if (this.isNotification) {
        this.wikiHelpCode = this.isEvent
          ? this.$wikiHelpLinkCodes?.Event.Notification?.Template.Documents.List
          : this.$wikiHelpLinkCodes?.BAU.Notification?.Template.Documents.List;
      } else {
        this.wikiHelpCode = this.isEvent
          ? this.$wikiHelpLinkCodes?.Event.Communication?.Template.Document.List
          : this.$wikiHelpLinkCodes?.BAU.Communication?.Template.Document.List;
      }
    },
    getRouteNameAdd() {
      let routeName = "";
      if (this.isNotification) {
        routeName = this.isEvent
          ? "eleNotificationDocumentTemplateAdd"
          : "notificationDocumentTemplateAdd";
      } else {
        routeName = this.isEvent
          ? "eleDocumentTemplateAdd"
          : "documentTemplateAdd";
      }
      return routeName;
    },
    getRouteNameView() {
      let routeName = "";
      if (this.isNotification) {
        routeName = this.isEvent
          ? "eleNotificationDocumentTemplateView"
          : "notificationDocumentTemplateView";
      } else {
        routeName = this.isEvent
          ? "eleDocumentTemplateView"
          : "documentTemplateView";
      }
      return routeName;
    },
    getRouteNameEdit() {
      let routeName = "";
      if (this.isNotification) {
        routeName = this.isEvent
          ? "eleNotificationDocumentTemplateEdit"
          : "notificationDocumentTemplateEdit";
      } else {
        routeName = this.isEvent
          ? "eleDocumentTemplateEdit"
          : "documentTemplateEdit";
      }
      return routeName;
    },
    toMessageTemplateCategoryList() {
      let routeName = "";
      if (this.isNotification) {
        routeName = this.isEvent
          ? "eleNotificationCategoryList"
          : "notificationCategoryList";
      } else {
        routeName = this.isEvent
          ? "eleMessageTemplateCategoryList"
          : "messageTemplateCategoryList";
      }
      this.$router.push({
        name: routeName,
        query: {
          tabindex: this.curNav.orderIndex,
          templateType: this.curNav.templateType,
        },
      });
    },
    setPermission() {
      let permissionCode = this.isEvent
        ? this.permissionCodeEvent.Communication
        : this.permissionCode.Communication;
      if (permissionCode.DocumentationCategory) {
        this.documentationCategory = has(permissionCode.DocumentationCategory);
      } else {
        this.documentationCategory = true;
      }
      this.documentAdd = has(permissionCode.DocumentationAdd);
      this.documentDel = has(permissionCode.DocumentationDelete);
      this.documentEdit = has(permissionCode.DocumentationEdit);
    },
    downloadFile(docId) {
      downFilesById(docId);
    },
    onEditClick(item) {
      let query = {
        id: item.id,
        isView: "0",
        dbtn: "1",
        templateType: this.curNav.templateType,
        docId: item.docId,
      };
      this.$router.push({
        name: this.getRouteNameEdit(),
        query: query,
      });
    },
    delTemplateHandle() {
      let pmOpt = this.$refs.selectableTable.confirmOpt(
        this.$g("const.confirm.delete")
      );
      if (pmOpt == null) {
        return;
      }
      pmOpt.then(() => {
        let delIds = this.$refs.selectableTable.getSelectedIds();
        delTemplate(delIds).then((m) => {
          this.$refs.selectableTable.refresh();
          this.$refs.lblMsg.message(m.data.status, m.message);
        });
      });
    },
    addTemplateHandle() {
      this.$router.push({
        name: this.getRouteNameAdd(),
        query: {
          isView: "0",
          dbtn: "1",
          templateType: this.curNav.templateType,
          hidePreNext: "1",
        },
      });
    },
    bindUseFors() {
      getUseFor().then((res) => {
        if (res.data.length > 0) {
          this.useFors = res.data.filter((o) => o.value > 0);
        }
      });
    },
    bindCategories() {
      let data = {
        enumTemplateType: this.curNav.templateType,
        electionId: this.queryParams.electionId,
        isNotification: this.isNotification,
      };
      getCategories(data).then((res) => {
        if (res.data.length > 0) {
          this.categories = res.data;
        }
      });
    },
    filterTabs(items, isEvent) {
      let pl_code = isEvent
        ? this.permissionCodeEvent.Communication
        : this.permissionCode.Communication;
      let filterAry = [
        {
          permissionCode: pl_code.EmailTemplate,
          templateType: enumTemplateType.email,
        },
        {
          permissionCode: pl_code.SMSTemplate,
          templateType: enumTemplateType.sms,
        },
        {
          permissionCode: pl_code.PhoneTemplate,
          templateType: enumTemplateType.phone,
        },
        {
          permissionCode: pl_code.LetterTemplate,
          templateType: enumTemplateType.letter,
        },
        {
          permissionCode: pl_code.Documentation,
          templateType: enumTemplateType.documentation,
        },
      ];

      let tempAry = handleTabs(filterAry);
      if (!tempAry.length) {
        return [];
      }
      return items
        .filter((item) => {
          return tempAry.some((ele) => ele.templateType === item.templateType);
        })
        .map((item, index) => {
          return {
            ...item,
            orderIndex: index,
          };
        });
    },
    initNav() {
      getSumcount({
        electionId: this.queryParams.electionId,
        isNotification: this.isNotification,
      }).then((data) => {
        let items = data.data.filter(
          (c) => this.hiddenNav.indexOf(c.templateType) < 0
        );
        items = items.filter((c) => this.hideNav.indexOf(c.templateType) < 0);
        if (items.length) {
          const navArr = [];
          let orderIndex = 0;
          items.forEach(function (item) {
            navArr.push({
              text: item.text,
              templateType: item.templateType,
              total: item.count,
              orderIndex: orderIndex++,
            });
          });
          this.navItems = this.filterTabs(navArr, this.isEvent);
          this.tabindex =
            this.tabindex === 4
              ? this.navItems.findIndex(
                  (item) => item.templateType === enumTemplateType.documentation
                )
              : this.tabindex;
          this.curNav = this.navItems[this.tabindex];
          this.showSearchAreaTemplate = true;
          this.refreshSearchTemplate();
          this.setPermission();
          this.$refs.selectableTable?.refresh();
          this.bindCategories();
        }
      });
    },
    refreshSearchTemplate() {
      this.$refs.searchArea?.getTemplateListByTemplateType(
        this.isNotification
          ? enumSearchType.notificationDocumentTemplate
          : enumSearchType.communicationDocumentTemplate
      );
    },
    onFieldsGet(fields) {
      this.fields = fields;
    },

    onColumnChanged() {
      this.$refs.selectableTable.clearFields(); //清除当前选择的字段
      this.$refs.searchArea.clearHandler();
    },

    navClick(item) {
      let routeName = "";
      if (this.isNotification) {
        routeName = this.isEvent ? "eleNotification" : "notification";
      } else {
        routeName = this.isEvent ? "eleMessageTemplate" : "messageTemplate";
      }
      if (item.orderIndex !== this.tabindex) {
        if (item.templateType !== enumTemplateType.documentation) {
          this.$router.push({
            name: routeName,
            query: {
              tabindex: item.orderIndex,
            },
          });
        } else {
          this.curNav = item;

          this.tabindex = item.orderIndex;
          this.clear();
          this.initNav();
        }
      }
    },

    onRowSelected(items) {
      this.selected = items;
    },
    onTotalChanged(total) {
      this.curNav.total = total;
    },
    clear() {
      this.queryParams.title = "";
      this.queryParams.filename = "";
      this.queryParams.category = [];
      this.$refs.searchArea.setDynamicConditions();
    },
    getParas() {
      return {
        query: this.queryParams,
      };
    },

    setParas(paras) {
      this.queryParams = paras;
    },

    getCondition() {
      this.queryParams.dynamicConditions =
        this.$refs.searchArea.getDynamicConditions();
      this.queryParams.templateType = this.curNav.templateType;
      return this.queryParams;
    },

    search: function () {
      this.$refs.selectableTable.pageChange(1);
    },

    onToggleSearch() {
      this.seenSearch = !this.seenSearch;
      this.isActive = !this.isActive;
    },
    showAlert() {
      let status = saveAlert.savedStatus(this.$route);
      if (status === repResult.success) {
        this.$refs.lblMsg.message(status, saveAlert.savedMessage(this.$route));
      }
    },
    toThousands(val) {
      let num = Math.round(val || 0).toString(),
        result = "";
      while (num.length > 3) {
        result = "," + num.slice(-3) + result;
        num = num.slice(0, num.length - 3);
      }
      if (num) {
        result = num + result;
      }
      return result;
    },
  },
};
</script>
