<template>
  <div class="systemsNavModule">
    <div class="slectedWap form-control">
      {{ slectedName }}
    </div>
    <b-nav class="systemsWeb">
      <b-nav-item to="/dashboard" exact-active-class="active">
        {{ $g("header.home") }}
      </b-nav-item>
      <b-nav-item
        v-for="(item, index) in navList"
        :key="index"
        :to="item.routePath"
        exact-active-class="active"
        :active="item.id === systemsId"
      >
        {{ item.menuName }}
      </b-nav-item>
    </b-nav>
  </div>
</template>

<script>
export default {
  props: {
    navList: {
      type: Array,
      default: function () {
        return [];
      },
    },
    systemsId: {
      type: String,
      default: function () {
        return "";
      },
    },
  },
  data() {
    return {
      slectedName: "",
    };
  },
  created() {
  },
  mounted() {
    this.$nextTick(() => {
      this.slectedItem();
    });
  },
  methods: {
    slectedItem() {
      let $this = this;
      this.navList.forEach((item) => {
        if (item.id == this.systemsId) {
          $this.slectedName = item.menuName;
        }
      });
    },
  },
};
</script>
