<template>
  <div class="content-group">
    <k-form-group
      label-suffix="subject"
      label-for="Subject"
      label-align-md="right"
      label-cols-md="2"
      content-cols-md="9"
    >
      <KValidationProvider
        :rules="{
          required: false,
          max: 200,
          validateSpecialCharacter: false,
          remote: isSubjectExits,
        }"
        v-slot="{ errors }"
        slim
        name="subject"
        ref="subjectValidation"
      >
        <k-alert :show="errors.length > 0" variant="warning">
          {{ errors[0] }}
        </k-alert>
        <k-form-input v-model="form.subject" :plaintext="isPlainText" />
      </KValidationProvider>
    </k-form-group>
    <k-form-group
      label-suffix="header"
      label-for="Header"
      label-align-md="right"
      label-cols-md="2"
      content-cols-md="9"
      v-if="
        templateType === enumTemplateType.email ||
        templateType === enumTemplateType.letter
      "
    >
      <KValidationProvider
        :rules="{ required: false }"
        v-slot="{ errors }"
        slim
        name="header"
        ref="headerValidation"
      >
        <k-alert :show="errors.length > 0" variant="warning">
          {{ errors[0] }}
        </k-alert>
        <k-form-select v-model="form.headerId" :options="headerOptions" />
      </KValidationProvider>
    </k-form-group>
    <k-form-group
      :label-class="getHeaderDisplayeaderRequired ? 'require-mark' : ''"
      label-suffix="headerDisplayeader"
      label-for="headerDisplayeader"
      label-align-md="right"
      label-cols-md="2"
      content-cols-md="9"
      v-if="templateType === enumTemplateType.letter"
    >
      <KValidationProvider
        :rules="{ required: getHeaderDisplayeaderRequired }"
        v-slot="{ errors }"
        slim
        name="headerDisplayeader"
        ref="headerDisplayValidation"
      >
        <k-alert :show="errors.length > 0" variant="warning">
          {{ errors[0] }}
        </k-alert>
        <k-form-select
          v-model="form.headerDisplay"
          :options="
            getHeaderDisplayeaderRequired ? headerDisplayeaderOptions : []
          "
        />
      </KValidationProvider>
    </k-form-group>
    <k-form-group
      label-suffix="footer"
      label-for="Footer"
      label-align-md="right"
      label-cols-md="2"
      content-cols-md="9"
      v-if="
        templateType === enumTemplateType.email ||
        templateType === enumTemplateType.letter
      "
    >
      <KValidationProvider
        :rules="{ required: false }"
        v-slot="{ errors }"
        slim
        name="footer"
        ref="footerValidation"
      >
        <k-alert :show="errors.length > 0" variant="warning">
          {{ errors[0] }}
        </k-alert>
        <k-form-select v-model="form.footerId" :options="footerOptions" />
      </KValidationProvider>
    </k-form-group>
    <k-form-group
      :label-class="getFooterDisplayRequired ? 'require-mark' : ''"
      label-suffix="footerDisplay"
      label-for="footerDisplay"
      label-align-md="right"
      label-cols-md="2"
      content-cols-md="9"
      v-if="templateType === enumTemplateType.letter"
    >
      <KValidationProvider
        :rules="{ required: getFooterDisplayRequired }"
        v-slot="{ errors }"
        slim
        name="footerDisplay"
        ref="footerDisplayValidation"
      >
        <k-alert :show="errors.length > 0" variant="warning">
          {{ errors[0] }}
        </k-alert>
        <k-form-select
          v-model="form.footerDisplay"
          :options="getFooterDisplayRequired ? footerDisplayOptions : []"
        />
      </KValidationProvider>
    </k-form-group>
    <k-form-group
      label-suffix="attachment"
      label-for="Attachment"
      label-align-md="right"
      label-cols-md="2"
      content-cols-md="9"
      v-if="templateType === enumTemplateType.email"
    >
      <KValidationProvider
        :rules="{
          required: false,
          acceptFileType: { fileAccept: accept },
          maxFileSize: size * 1024,
        }"
        v-slot="{ errors }"
        slim
        name="attachment"
        ref="fileInputValidation"
      >
        <k-alert :show="errors.length > 0" variant="warning">
          {{ errors[0] }}
        </k-alert>
        <common-upload
          ref="fileinput"
          v-model="getFile"
          :accept="accept"
          :size="size"
          :is-multiple="true"
          :on-delete="onDelete"
          :placeholder="placeholder"
          :show-message="false"
          :key="uploadKey"
          :is-use-internal-validate="false"
          :plaintext="isPlainText"
        />
      </KValidationProvider>
    </k-form-group>
    <k-form-group
      label-suffix="content"
      label-for="noneBind"
      label-align-md="right"
      label-cols-md="2"
      content-cols-md="9"
      v-if="
        templateType === enumTemplateType.email ||
        templateType === enumTemplateType.letter
      "
    >
      <KValidationProvider
        :rules="{ required: false }"
        v-slot="{ errors }"
        slim
        :name="$g('content')"
        ref="messageEditorValidation"
      >
        <k-alert :show="errors.length > 0" variant="warning">
          {{ errors[0] }}
        </k-alert>
        <message-editor
          @select="onLabelSelect"
          ref="messageEditor"
          v-model="form.body"
          :is-plain-text="isPlainText"
          :ckeditorHeight="300"
        >
          <slot name="insertLabel" />
        </message-editor>
      </KValidationProvider>
    </k-form-group>
    <k-form-group
      label-suffix="content"
      label-for="noneBind"
      label-align-md="right"
      label-cols-md="2"
      content-cols-md="9"
      v-if="templateType === enumTemplateType.sms"
    >
      <template #label>
        {{ $g("content") }}:<slot name="smsInsertLabel" :form="form" />
      </template>
      <KValidationProvider
        :rules="{ required: false }"
        v-slot="{ errors }"
        slim
        :name="$g('content')"
        ref="bodyValidation"
      >
        <k-alert :show="errors.length > 0" variant="warning">
          {{ errors[0] }}
        </k-alert>
        <k-form-textarea
          v-model="form.body"
          :plaintext="isPlainText"
          rows="10"
          ref="taContent"
        />
        <div v-if="!isPlainText">
          {{ `${maxContent - contentLength} ${$g("characterLeft")}` }}
        </div>
      </KValidationProvider>
    </k-form-group>
  </div>
</template>

<script>
import MessageEditor from "../../../../common-ckeditor/messageEditor.vue";
import {
  checkSubjectExits,
  getTemplateDetailUseFor,
  getTemplateDetailHeaderDisplayDropDown,
  getTemplateDetailFooterDisplayDropDown,
} from "../../../api/messageTemplate";
import { repResult } from "../../../../../libs/global";
import { getUploadConfig } from "../../../../../api/upload";
export default {
  components: { MessageEditor },
  props: {
    templateType: {
      type: Number,
      default: () => {
        return 0;
      },
    },
    enumTemplateType: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isPlainText: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    form: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      footerOptions: [],
      footerDisplayOptions: [],
      headerOptions: [],
      headerDisplayeaderOptions: [],
      maxContent: 1500,
      accept: "",
      placeholder: this.$g("noFileChosen"),
      uploadKey: 0,
      size: 0,
      HeaderDisplayeaderRequired: false,
      FooterDisplayRequired: false,
      contentLength: 0,
    };
  },
  computed: {
    getFormStr() {
      return JSON.stringify(this.form);
    },
    getHeaderDisplayeaderRequired() {
      this.HeaderDisplayeaderRequired = !!this.form.headerId;
      return this.HeaderDisplayeaderRequired;
    },
    getFooterDisplayRequired() {
      this.FooterDisplayRequired = !!this.form.footerId;
      return this.FooterDisplayRequired;
    },
    getFile: {
      get() {
        let v = this.form.files;
        this.initUploadList(v);
        return v;
      },
      set(v) {
        this.form.files = [...v];
        this.initUploadList(this.form.files);
      },
    },
    getFileDoc() {
      return this.getFileList(this.form.files);
    },
  },
  methods: {
    onDeleteDoc(doc) {},
    getFileList(files, fileType = "object") {
      if (!files || files.length <= 0) {
        return [];
      }
      switch (fileType) {
        case "object":
          return files.filter((c) => c.documentId);
        case "file":
          return files.filter((c) => !c.documentId);
        default:
          return [];
      }
    },
    async getValidation() {
      const key = Object.keys(this.$refs);
      let ValidationKey = [];
      key.forEach((k) => {
        if (_.endsWith(k, "Validation")) {
          ValidationKey.push(k);
        }
      });
      for (const v of ValidationKey) {
        if (!this.$refs[v]) {
          break;
        }
        let validate = await this.$refs[v].validate();
        if (!validate.valid) {
          return validate.valid;
        }
      }
      return true;
    },
    onDelete(file) {
      this.onDeleteDoc(file);
    },
    async isSubjectExits() {
      if (!this.isSaveTemplate) return { data: { status: repResult.success } };
      let para = {};
      para.subject = this.form.subject;
      para.id = this.templateId;
      para.templateType = this.templateType;
      para.electionId = this.form.electionId;
      let result = null;

      await checkSubjectExits(para).then(function (rep) {
        result = rep;
      });
      return result;
    },
    onLabelSelect() {
      this.$emit("label-select");
    },
    initUploadList(filesArray) {
      this.$nextTick(() => {
        if (this.templateType !== this.enumTemplateType.email) {
          return;
        }
        let f = filesArray?.filter((c) => !c.documentId);
        this.$refs.fileinput.uploadfiles = f;
        this.$refs.fileinput?.initUploadList(filesArray ?? []);
      });
    },
    buildHeaderDisplayOptions() {
      getTemplateDetailHeaderDisplayDropDown()
        .then((result) => {
          this.headerDisplayeaderOptions = result.data;
        })
        .catch((err) => {
          throw err;
        });
    },
    buildFooterDisplayOptions() {
      getTemplateDetailFooterDisplayDropDown()
        .then((result) => {
          this.footerDisplayOptions = result.data;
        })
        .catch((err) => {
          throw err;
        });
    },
    buildFooterOptions() {
      getTemplateDetailUseFor(2)
        .then((result) => {
          this.footerOptions = result.data;
        })
        .catch((err) => {
          throw err;
        });
    },
    buildHeaderOptions() {
      getTemplateDetailUseFor(1)
        .then((result) => {
          this.headerOptions = result.data;
        })
        .catch((err) => {
          throw err;
        });
    },
    setContent() {
      this.contentLength = this.form.body.realLenth;
    },
  },
  created() {
    this.buildFooterOptions();
    this.buildHeaderOptions();
    this.buildHeaderDisplayOptions();
    this.buildFooterDisplayOptions();
  },
  mounted() {
    getUploadConfig("messagedoc").then((data) => {
      this.accept = data.data.acceptTypes;
      this.size = data.data.maxSize / 1024;
    });
  },
  watch: {
    "form.body": function (newVal, oldVal) {
      if (!newVal && newVal != "") {
        return;
      }
      if (
        this.templateType === this.enumTemplateType.sms &&
        newVal.realLenth > this.maxContent
      ) {
        this.$nextTick(() => {
          this.form.body = newVal.left(this.maxContent);
          this.contentLength = this.maxContent;
        });
      } else {
        this.$nextTick(() => {
          this.setContent();
        });
      }
    },
    getFormStr() {
      if (!this.form.headerId) {
        this.form.headerDisplay = "";
      }
      if (!this.form.footerId) {
        this.form.footerDisplay = "";
      }
      if (this.form.subject && this.form.body) {
        this.form.isData = true;
      } else {
        this.form.isData = false;
      }
      this.$emit("form-change", this.form);
    },
  },
};
</script>
