import Vue from "vue";
import msgAlert from "./msgAlert.vue";
import { removeFromDom, spliceIfExist } from "../../utils/sysUtiles";

const queue = [];

const destroy = (instance) => {
  removeFromDom(instance.$el);
  instance.$destroy();
  spliceIfExist(queue, instance);
};

function showAlert(title, content, okText, onDismiss){
  const instance = new Vue({
    extends: msgAlert,
    propsData: {
      title,
      content,
      okText,
      cb(){
        destroy(instance);
        if(onDismiss){
          onDismiss();
        }
      },
    },
  });
  instance.$mount();
  document.body.appendChild(instance.$el);
  instance.show = true;
  queue.push(instance);
}

export default showAlert;

