export let rules = {
  postcode: {
    reg: /^\d{5}(-\d{4})?$/,
    message: "xxxxx or xxxxx-xxxx",
    formatfun: function (val) {
      if (val.length == 9 || val.length == 5) {
        if (val.length == 9) {
          return val.substring(0, 5) + '-' + val.substring(5, 9);
        }
        else {
          return val;
        }
      }

    }
  }
}
