const setLocationNav = (data) => {
    localStorage.setItem("Location_Module_Nav", JSON.stringify(data))
}
const getLocationNav = () => {
    return JSON.parse(localStorage.getItem("Location_Module_Nav"))
}
const removeLocationNav = () => {
    localStorage.removeItem("Location_Module_Nav")
}
const setLocationReportNav = (data) => {
    localStorage.setItem("Location_Module_Report_Nav", JSON.stringify(data))
}
const getLocationReportNav = () => {
    return JSON.parse(localStorage.getItem("Location_Module_Report_Nav"))
}

const setWorkerNav = (data) => {
    localStorage.setItem("Worker_Module_Nav", JSON.stringify(data))
}
const getWorkerNav = () => {
    return JSON.parse(localStorage.getItem("Worker_Module_Nav"))
}
const removeWorkerNav = () => {
    localStorage.removeItem("Worker_Module_Nav")
}
const setWorkerReportNav = (data) => {
    localStorage.setItem("Worker_Module_Report_Nav", JSON.stringify(data))
}
const getWorkerReportNav = () => {
    return JSON.parse(localStorage.getItem("Worker_Module_Report_Nav"))
}

const setAssetNav = (data) => {
    localStorage.setItem("Asset_Module_Nav", JSON.stringify(data))
}
const getAssetNav = () => {
    return JSON.parse(localStorage.getItem("Asset_Module_Nav"))
}
const removeAssetNav = () => {
    localStorage.removeItem("Asset_Module_Nav")
}
const setAssetReportNav = (data) => {
    localStorage.setItem("Asset_Module_Report_Nav", JSON.stringify(data))
}
const getAssetReportNav = () => {
    return JSON.parse(localStorage.getItem("Asset_Module_Report_Nav"))
}

const setAssetNavScroll = (data) => {
    localStorage.setItem("Asset_Module_Nav_Scroll", JSON.stringify(data))
}
const getAssetNavScroll = () => {
    return JSON.parse(localStorage.getItem("Asset_Module_Nav_Scroll"))
}
const removeAssetNavScroll = () => {
    localStorage.removeItem("Asset_Module_Nav_Scroll")
}
const setAssetReportNavScroll = (data) => {
    localStorage.setItem("Asset_Module_Report_Nav_Scroll", JSON.stringify(data))
}
const getAssetReportNavScroll = () => {
    return JSON.parse(localStorage.getItem("Asset_Module_Report_Nav_Scroll"))
}



const setCampaignNav = (data) => {
    localStorage.setItem("Campaign_Module_Nav", JSON.stringify(data))
}
const getCampaignNav = () => {
    return JSON.parse(localStorage.getItem("Campaign_Module_Nav"))
}
const removeCampaignNav = () => {
    localStorage.removeItem("Campaign_Module_Nav")
}
const setCampaignReportNav = (data) => {
    localStorage.setItem("Campaign_Module_Report_Nav", JSON.stringify(data))
}
const getCampaignReportNav = () => {
    return JSON.parse(localStorage.getItem("Campaign_Module_Report_Nav"))
}

export default {
    setLocationNav,
    getLocationNav,
    removeLocationNav,
    setLocationReportNav,
    getLocationReportNav,
    setWorkerNav,
    getWorkerNav,
    removeWorkerNav,
    setWorkerReportNav,
    getWorkerReportNav,
    setAssetNav,
    getAssetNav,
    removeAssetNav,
    setAssetReportNav,
    getAssetReportNav,
    setAssetNavScroll,
    getAssetNavScroll,
    removeAssetNavScroll,
    setAssetReportNavScroll,
    getAssetReportNavScroll,
    setCampaignNav,
    getCampaignNav,
    removeCampaignNav,
    setCampaignReportNav,
    getCampaignReportNav,
}