<template>
  <gmap-autocomplete
    ref="gmapAutocomplete"
    class="form-control"
    :value="autocompleteText"
    @place_changed="onPlaceChanged"
    :disabled="disabled"
    :key="componentKey"
    :options="options"
    :placeholder="placeholder"
    :aria-label="$g('address.googleAddressSearchPlaceholder')"
    onfocus="this.setAttribute('autocomplete', 'disable-autocomplete');"
    @blur="blurEvent"
  />
</template>

<script>
import { specialStreetNameList } from "./googleAddressUtil";

const componentForm = {
  subpremise: "short_name",
  premise: "short_name",
  street_number: "short_name",
  route: "short_name", //street name
  routeLong: "long_name", //street name
  locality: "long_name",
  administrative_area_level_1: "short_name",
  country: "short_name",
  postal_code: "short_name",
  sublocality_level_1: "long_name",
  administrative_area_level_2: "long_name",
};
import KFormInput from "../../k-components/form-input";
import storeage from "../../utils/storeage";

const objProps = Object.assign({}, KFormInput.props);
export default {
  props: {
    ...objProps,
    id: {
      type: String,
      required: true,
    },

    placeholder: {
      type: String,
      default: "",
    },

    types: {
      type: String,
      default: "geocode",
    },

    country: {
      type: [String, Array],
      default: null,
    },
  },

  data() {
    let Country = storeage.getSystemGeneralInfo()?.appCountry ?? "US";
    return {
      autocompleteText: "",
      componentKey: 0,
      options: {
        types: ["geocode"],
        componentRestrictions: { country: [Country] },
      },
    };
  },

  watch: {
    autocompleteText: function (newVal, oldVal) {
      this.$emit("inputChange", { newVal, oldVal }, this.id);
    },
  },

  mounted: function () {
    const options = {};

    if (this.types) {
      options.types = [this.types];
    }

    if (this.country) {
      options.componentRestrictions = {
        country: this.country,
      };
    }
  },

  methods: {
    blurEvent() {
      this.$emit("blurEvent");
    },
    onPlaceChanged(place) {
      if (!place.geometry) {
        this.$emit("no-results-found", place, this.id);
        return;
      }

      if (place.address_components !== undefined) {
        this.$emit("placechanged", this.formatResult(place), this.id);

        this.onChange();
      }
    },
    onChange() {
      this.$emit("change", this.autocompleteText);
    },
    clear() {
      this.componentKey++;
    },
    deleteStringSpace(str) {
      while (str[0] == " " || str[0] == "." || str[0] == ",") {
        str = str.substring(1, str.length);
      }
      if (str.length != 0) {
        while (
          str[str.length - 1] == " " ||
          str[str.length - 1] == "." ||
          str[str.length - 1] == ","
        ) {
          str = str.substring(0, str.length - 1);
        }
      }
      return str;
    },
    formatResult(place) {
      let returnData = {
        subpremise: "",
        premise: "",
        street_number: "",
        route: "",
        routeLong: "",
        neighborhood: "",
        administrative_area_level_1: "",
        country: "",
        postal_code: "",
        sublocality_level_1: "",
        administrative_area_level_2: "",
      };
      for (let item of place.address_components) {
        let addressType = item.types[0];

        if (componentForm[addressType]) {
          let val = item[componentForm[addressType]];
          returnData[addressType] = val;
          if (addressType == "route") {
            returnData.routeLong = item[componentForm.routeLong];
          }
        }
      }
      let streetPostfacilityAddress = [
        "N",
        "S",
        "E",
        "W",
        "NE",
        "NW",
        "SE",
        "SW",
      ];
      let streetPre = "";
      let streetName = "";
      let streetType = "";
      let streetPost = "";
      if (returnData.route && returnData.route.length > 0) {
        returnData.route = this.deleteStringSpace(returnData.route);
        returnData.routeLong = this.deleteStringSpace(returnData.routeLong);
        let arr = returnData.route.split(" ");
        let arrLog = returnData.routeLong.split(" ");
        if (arr.length > 0) {
          if (streetPostfacilityAddress.includes(arr[0])) {
            streetPre = arr[0];
            arr.splice(0, 1);
            arrLog.splice(0, 1);
          }
          if (arr && arr.length > 0) {
            while (arrLog.length > 0) {
              if (
                arr[0] == arrLog[0] ||
                this.hasSpecialStreetName(arr[0], arrLog[0])
              ) {
                streetName += arr[0] + " ";
                arr.splice(0, 1);
                arrLog.splice(0, 1);
              } else {
                if (streetName.length > 0) {
                  break;
                } else {
                  arrLog.splice(0, 1);
                }
              }
            }
            if (
              streetName &&
              streetName.length > 0 &&
              streetName[streetName.length - 1] == " "
            ) {
              streetName = streetName.substring(0, streetName.length - 1);
            }
          }

          if (arr && arr.length > 0) {
            if (streetPostfacilityAddress.includes(arr[arr.length - 1])) {
              streetPost = arr[arr.length - 1];
              arr.splice(arr.length - 1, 1);
            }
          }
          if (arr && arr.length > 0) {
            streetType = arr[arr.length - 1];
          }
        }
      }
      return {
        locality: returnData.locality || returnData.sublocality_level_1,
        postalcode: returnData.postal_code,
        buildingName: returnData.premise,
        country: returnData.country,
        unitNumber: returnData.subpremise,
        streetNumber: returnData.street_number,
        state: returnData.administrative_area_level_1,
        streetPre: streetPre.toUpperCase(),
        streetName: streetName,
        streetType: streetType.toUpperCase(),
        streetPost: streetPost.toUpperCase(),
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
      };
    },
    hasSpecialStreetName(shortName, longName) {
      return specialStreetNameList.some(function (element) {
        return element.shortName == shortName && element.longName == longName;
      });
    },
  },
};
</script>
