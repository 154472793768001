<template>
  <div class="content-page">
    <election-select v-if="isEvent" />
    <div class="content">
      <div class="right-bar" v-show="seenSearch">
        <b-icon icon="x" @click="onToggleSearch" />
        <search-area
          v-if="showThisSearchArea"
          :type-name="typeName"
          :search-click="search"
          :clear-click="clear"
          :get-paras="getCondition"
          :set-paras="setParas"
          ref="searchArea"
          :fields="fields"
        >
          <k-form-group label-suffix="search.title">
            <k-form-input v-model="queryParams.subject" />
          </k-form-group>
        </search-area>
      </div>
      <div class="center-bar">
        <div class="nav-btn-group">
          <button
            class="btn rounded-0 nav-btn-search"
            @click="onToggleSearch"
            :class="{ active: isActive }"
          >
            <b-icon icon="search" />
          </button>
          <KWikiHelp :propsWikiHelpCode="wikiHelpCode" />
        </div>

        <div class="content-main">
          <k-alert-msg ref="lblMsg" />
          <div class="content-navTab">
            <div class="nav-tools">
              <b-icon-add v-if="isAdd" @click="addDraftHandle" />
              <b-icon-delete v-if="isDel" @click="delDraftHandle" />
              <column-config-icon
                list-id="msgletterdraftpagelist"
                @column-changed="onColumnChanged"
              />
            </div>
            <status-Nav
              :refresh="navClick"
              ref="navTabs"
              :items="navItems"
              :current-index="tabindex"
              :need-total-count="true"
            />
          </div>

          <div class="content-party">
            <konn-grid
              :select-mode="selectMode"
              @edit-clicked="onEditClick"
              :has-check-box="hasCheckBox"
              :has-action="isEdit"
              ref="selectableTable"
              :data-source="dataSource"
              :get-condition="getCondition"
              @row-selected="onRowSelected"
              @total-changed="onTotalChanged"
              @fields-get="onFieldsGet"
              grid-sort-by="subject"
              :set-paras="setParas"
              :list-id="listId"
              :auto-bind="false"
              :stickyHeader="true"
              class="stickyTable"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getDrafts,
  getSumcount,
  delDraft,
  delLetterDraft,
} from "../../api/messageDraft";
import { getCategories } from "../../api/messageTemplate";
import statusNav from "../../components/common-nav";
import { enumTemplateType } from "../../enums/enumTemplateType";
import { enumSearchType } from "../../../../utils/enums/enumSearchType";
import { has } from "../../../../utils/permission/operatePermission";
import { handleTabs } from "../../../../utils/permission/permission";
import storeage from "../../../../utils/storeage";
import { permissionCode } from "../../mixins/permissionCode";
import { getCurPageConfigbyRoutePath } from "../../../../utils/tools";

export default {
  mixins: [permissionCode],
  props: {
    // hasElection: {
    //   type: Boolean,
    //   default() {
    //     return false
    //   },
    // },
  },
  components: {
    statusNav,
  },
  data() {
    let isEvent = this.$route.meta.isEvent;
    let curPageConfig = getCurPageConfigbyRoutePath(
      this.$route.meta.project,
      this.$route.path
    );
    return {
      isEvent: isEvent,
      typeName: isEvent ? "eleDraftList" : "draftList",
      listId: isEvent ? "eleDraftList" : "draftList",
      queryParams: {
        electionId: isEvent ? storeage.getElectionId() : "",
        subject: "",
        category: [],
        useFor: [],
      },
      tabindex:
        this.$route.query.tabindex != null
          ? parseInt(this.$route.query.tabindex)
          : 0,
      fields: [],
      navItems: [],
      items: [],
      selectMode: "multi",
      selected: [],
      hasCheckBox: true,
      hasAction: true,
      showThisSearchArea: true,
      docCategories: [],
      categories: [],
      useFors: [],
      seenSearch: true,
      isActive: true,
      initPaymentStatus: [],
      curNav: {},
      showSearchStatus: true,
      selectedId: "",
      doc: {},
      enumTemplateType: enumTemplateType,
      isAdd: false,
      isDel: false,
      isEdit: false,
      wikiHelpCodes: null,
      hiddenNav: curPageConfig?.templateTabs?.hidNav ?? [],
    };
  },

  computed: {
    dataSource: function () {
      return getDrafts;
    },
    delDraftMethod() {
      if (this.curNav.msgType === enumTemplateType.letter) {
        return delLetterDraft;
      }
      return delDraft;
    },
  },

  mounted() {
    this.initNav();
  },

  methods: {
    setPermission() {
      let wikiHelpCodes = this.isEvent
        ? this.$wikiHelpLinkCodes?.Event.Communication.Draft
        : this.$wikiHelpLinkCodes?.BAU.Communication.Draft;
      switch (this.curNav.msgType) {
        case enumTemplateType.email:
          if (this.isEvent) {
            this.isAdd = has(
              this.permissionCodeEvent.Communication.EmailDraftsAdd
            );
            this.isDel = has(
              this.permissionCodeEvent.Communication.EmailDraftsDelete
            );
            this.isEdit = has(
              this.permissionCodeEvent.Communication.EmailDraftsEdit
            );
          } else {
            this.isAdd = has(this.permissionCode.Communication.EmailDraftsAdd);
            this.isDel = has(
              this.permissionCode.Communication.EmailDraftsDelete
            );
            this.isEdit = has(
              this.permissionCode.Communication.EmailDraftsEdit
            );
          }
          this.wikiHelpCode = wikiHelpCodes?.Email.List;
          break;
        case enumTemplateType.phone:
          if (this.isEvent) {
            this.isAdd = has(
              this.permissionCodeEvent.Communication.PhoneDraftsAdd
            );
            this.isDel = has(
              this.permissionCodeEvent.Communication.PhoneDraftsDelete
            );
            this.isEdit = has(
              this.permissionCodeEvent.Communication.PhoneDraftsEdit
            );
          } else {
            this.isAdd = has(this.permissionCode.Communication.PhoneDraftsAdd);
            this.isDel = has(
              this.permissionCode.Communication.PhoneDraftsDelete
            );
            this.isEdit = has(
              this.permissionCode.Communication.PhoneDraftsEdit
            );
          }
          this.wikiHelpCode = wikiHelpCodes?.Phone.List;
          break;
        case enumTemplateType.sms:
          if (this.isEvent) {
            this.isAdd = has(
              this.permissionCodeEvent.Communication.SMSDraftsAdd
            );
            this.isDel = has(
              this.permissionCodeEvent.Communication.SMSDraftsDelete
            );
            this.isEdit = has(
              this.permissionCodeEvent.Communication.SMSDraftsEdit
            );
          } else {
            this.isAdd = has(this.permissionCode.Communication.SMSDraftsAdd);
            this.isDel = has(this.permissionCode.Communication.SMSDraftsDelete);
            this.isEdit = has(this.permissionCode.Communication.SMSDraftsEdit);
          }
          this.wikiHelpCode = wikiHelpCodes?.SMS.List;
          break;
        case enumTemplateType.letter:
          if (this.isEvent) {
            this.isAdd = has(
              this.permissionCodeEvent.Communication.LetterDraftsAdd
            );
            this.isDel = has(
              this.permissionCodeEvent.Communication.LetterDraftsDelete
            );
            this.isEdit = has(
              this.permissionCodeEvent.Communication.LetterDraftsEdit
            );
          } else {
            this.isAdd = has(this.permissionCode.Communication.LetterDraftsAdd);
            this.isDel = has(
              this.permissionCode.Communication.LetterDraftsDelete
            );
            this.isEdit = has(
              this.permissionCode.Communication.LetterDraftsEdit
            );
          }
          this.wikiHelpCode = wikiHelpCodes?.Letter.List;
          break;
        default:
          break;
      }
    },
    onEditClick(item) {
      this.$router.push({
        name: this.isEvent ? "eleSendMessage" : "sendMessage",
        query: {
          id: item.id,
          msgType: this.curNav.msgType,
        },
      });
    },
    delDraftHandle() {
      let pmOpt = this.$refs.selectableTable.confirmOpt(
        this.$g("const.confirm.delete")
      );
      if (pmOpt == null) {
        return;
      }
      pmOpt.then(() => {
        let delIds = this.$refs.selectableTable.getSelectedIds();
        this.delDraftMethod(delIds).then((m) => {
          this.$refs.selectableTable.refresh();
          this.$refs.lblMsg.message(m.data.status, m.message);
        });
      });
    },
    addDraftHandle() {
      this.$router.push({
        name: this.isEvent ? "eleSendMessage" : "sendMessage",
        query: {
          msgType: this.curNav.msgType,
        },
      });
    },
    bindCategories() {
      let data = {
        enumTemplateType: this.curNav.msgType,
        electionId: this.queryParams.electionId,
      };
      getCategories(data).then((res) => {
        if (res.data.length > 0) {
          this.categories = res.data;
        }
      });
    },
    filterTabs(items, isEvent) {
      let pl_code = isEvent ? this.permissionCodeEvent : this.permissionCode;
      let filterAry = [
        {
          permissionCode: pl_code.Communication.EmailDrafts,
          msgType: enumTemplateType.email,
        },
        {
          permissionCode: pl_code.Communication.SMSDrafts,
          msgType: enumTemplateType.sms,
        },
        {
          permissionCode: pl_code.Communication.PhoneDrafts,
          msgType: enumTemplateType.phone,
        },
        {
          permissionCode: pl_code.Communication.LetterDrafts,
          msgType: enumTemplateType.letter,
        },
      ];

      let tempAry = handleTabs(filterAry);
      if (!tempAry.length) {
        return [];
      }
      return items
        .filter((item) => {
          return tempAry.some((ele) => ele.msgType === item.msgType);
        })
        .map((item, index) => {
          return {
            ...item,
            orderIndex: index,
          };
        });
    },
    initNav: function () {
      getSumcount({
        electionId: this.queryParams.electionId,
        isNotification: this.isNotification,
      }).then((data) => {
        const items = data.data.filter(
          (c) => this.hiddenNav.indexOf(c.msgType) < 0
        );
        if (items.length) {
          const navArr = [];
          let orderIndex = 0;
          items.forEach(function (item) {
            navArr.push({
              text: item.text,
              msgType: item.msgType,
              total: item.count,
              orderIndex: orderIndex++,
            });
          });

          this.navItems = this.filterTabs(navArr, this.isEvent);
          this.curNav = this.navItems[this.tabindex];
          this.setPermission();
          this.$refs.selectableTable?.clearFields();
          this.$refs.selectableTable?.refreshSetPage1();

          this.bindCategories();
          this.refreshSearchTemplate();
        }
      });
    },

    onFieldsGet(fields) {
      this.fields = fields;
    },

    onColumnChanged() {
      this.$refs.selectableTable.clearFields(); //清除当前选择的字段
      this.$refs.searchArea.clearHandler();
    },

    navClick(item) {
      if (item.orderIndex !== this.tabindex) {
        this.curNav = item;
        this.tabindex = item.orderIndex;
        this.clear();
        this.initNav();
      }
    },

    onRowSelected(items) {
      this.selected = items;
    },
    onTotalChanged(total) {
      this.curNav.total = total;
    },
    clear() {
      this.queryParams.subject = "";
      this.$refs.searchArea.setDynamicConditions();
    },
    getParas() {
      return {
        query: this.queryParams,
      };
    },

    setParas(paras) {
      this.queryParams = paras;
    },

    getCondition() {
      this.queryParams.dynamicConditions =
        this.$refs.searchArea.getDynamicConditions();
      this.queryParams.type = this.curNav.msgType;

      return this.queryParams;
    },

    search: function () {
      this.$refs.selectableTable.pageChange(1);
    },

    onToggleSearch() {
      this.seenSearch = !this.seenSearch;
      this.isActive = !this.isActive;
    },

    getSearchType(msgType) {
      switch (msgType) {
        case enumTemplateType.email:
          return enumSearchType.communicationEmailDraft;
        case enumTemplateType.phone:
          return enumSearchType.communicationPhoneDraft;
        case enumTemplateType.sms:
          return enumSearchType.communicationSMSDraft;
        case enumTemplateType.letter:
          return enumSearchType.communicationLetterDraft;
        default:
          break;
      }
    },
    refreshSearchTemplate() {
      var searchTemplateType = this.getSearchType(this.curNav.msgType);
      this.$refs.searchArea?.getTemplateListByTemplateType(searchTemplateType);
    },
  },
};
</script>
