<template>
  <div style="display: flex; flex: 1">
    <label class="version">{{ version }}</label>
    <label class="banner" style="text-align: center; flex: 1">{{
      environmentBannerLabel
    }}</label>
    <label class="mb-0 wapVersion">
      {{ wapVersion }}
    </label>
  </div>
</template>

<script>
import storeage from "../../utils/storeage";
import { g } from "../../locale/lang-val";
import config from "@/../package.json";

export default {
  name: "BannerLabel",
  props: {
    isShowModuleName: {
      type: Boolean,
      default: true,
    },
    moduleNameKey: {
      type: String,
      default: "header.moduleLocationName",
    },
  },
  data() {
    let tempVersion = `(${g("header.version")} ${
      storeage.getSystemGeneralInfo()
        ? storeage.getSystemGeneralInfo().version
        : config.version
    })`;
    if (this.isShowModuleName) {
      tempVersion = g(this.moduleNameKey) + tempVersion;
    }
    let wapTempVersion = `${g("header.version")} ${
      storeage.getSystemGeneralInfo()
        ? storeage.getSystemGeneralInfo().version
        : config.version
    }`;
    return {
      version: tempVersion,
      environmentBannerLabel: storeage.getSystemGeneralInfo()
        ? storeage.getSystemGeneralInfo().environmentBannerLabel
        : "",
      wapVersion: wapTempVersion,
    };
  },
};
</script>

