var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('k-form-group',{attrs:{"label-class":"require-mark","label-suffix":"title","label-for":"title","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('KValidationProvider',{attrs:{"rules":{
        required: true,
        max: 200,
      },"slim":"","name":"title"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-input',{attrs:{"plaintext":_vm.isPlainText},model:{value:(_vm.form.subject),callback:function ($$v) {_vm.$set(_vm.form, "subject", $$v)},expression:"form.subject"}})]}}])})],1),_c('k-form-group',{attrs:{"label-suffix":"activate","label-for":"activate","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('KValidationProvider',{attrs:{"rules":{},"slim":"","name":"activate"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-radio-group',{staticClass:"col-form-label d-block",attrs:{"options":_vm.activateOptions,"plaintext":_vm.isPlainText},model:{value:(_vm.form.activate),callback:function ($$v) {_vm.$set(_vm.form, "activate", $$v)},expression:"form.activate"}})]}}])})],1),_c('k-form-group',{attrs:{"label-class":"require-mark","label-suffix":"notificationFrequency","label-for":"notificationFrequency","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('KValidationProvider',{attrs:{"rules":{
        required: true,
      },"slim":"","name":"notificationFrequency"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-select',{attrs:{"options":_vm.notificationFrequencyOptions,"plaintext":_vm.isPlainText},on:{"input":_vm.notificationFrequencyChangeEvent},model:{value:(_vm.form.sendRule),callback:function ($$v) {_vm.$set(_vm.form, "sendRule", $$v)},expression:"form.sendRule"}})]}}])})],1),(_vm.form.sendRule == _vm.notificationFrequencyEnum.Daily)?_c('k-form-group',{attrs:{"label-class":"require-mark","label-suffix":"dailyNotificationTime","label-for":"dailyNotificationTime","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('KValidationProvider',{attrs:{"rules":{
        required: true,
        validateTimeFormat: true,
      },"slim":"","name":"title"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-timepicker',{model:{value:(_vm.form.dailyTime),callback:function ($$v) {_vm.$set(_vm.form, "dailyTime", $$v)},expression:"form.dailyTime"}})]}}],null,false,100571454)})],1):_vm._e(),(_vm.form.sendRule == _vm.notificationFrequencyEnum.Weekly)?_c('k-form-group',{attrs:{"label-class":"require-mark","label-suffix":"weeklyNotificationDayAndTime","label-for":"weeklyNotificationDayAndTime","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('KValidationProvider',{attrs:{"rules":{
        required: true,
        requiredValues: [_vm.form.weekMonth, _vm.form.dailyTime],
      },"slim":"","name":"weeklyNotificationDayAndTime"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('b-input-group',{scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('k-form-select',{attrs:{"options":_vm.weekOptions},model:{value:(_vm.form.weekMonth),callback:function ($$v) {_vm.$set(_vm.form, "weekMonth", $$v)},expression:"form.weekMonth"}})]},proxy:true},{key:"append",fn:function(){return [_c('k-form-timepicker',{staticClass:"ml-2",model:{value:(_vm.form.dailyTime),callback:function ($$v) {_vm.$set(_vm.form, "dailyTime", $$v)},expression:"form.dailyTime"}})]},proxy:true}],null,true)})]}}],null,false,2287297457)})],1):_vm._e(),(_vm.form.sendRule == _vm.notificationFrequencyEnum.Monthly)?_c('k-form-group',{attrs:{"label-class":"require-mark","label-suffix":"monthlyNotificationDayAndTime","label-for":"monthlyNotificationDayAndTime","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('KValidationProvider',{attrs:{"rules":{
        required: true,
        requiredValues: {
          values: [_vm.form.weekMonth, _vm.form.dailyTime],
        },
      },"slim":"","name":"monthlyNotificationDayAndTime"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('b-input-group',{scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('k-form-select',{attrs:{"options":_vm.dayOptions},model:{value:(_vm.form.weekMonth),callback:function ($$v) {_vm.$set(_vm.form, "weekMonth", $$v)},expression:"form.weekMonth"}})]},proxy:true},{key:"append",fn:function(){return [_c('k-form-timepicker',{staticClass:"ml-2",model:{value:(_vm.form.dailyTime),callback:function ($$v) {_vm.$set(_vm.form, "dailyTime", $$v)},expression:"form.dailyTime"}})]},proxy:true}],null,true)})]}}],null,false,3159991537)})],1):_vm._e(),(_vm.form.sendRule == _vm.notificationFrequencyEnum.Yearly)?_c('k-form-group',{attrs:{"label-class":"require-mark","label-suffix":"yearlyNotificationDayAndTime","label-for":"yearlyNotificationDayAndTime","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('KValidationProvider',{attrs:{"rules":{
        required: true,
        requiredValues: {
          values: [_vm.form.months, _vm.form.weekMonth, _vm.form.dailyTime],
        },
      },"slim":"","name":"yearlyNotificationDayAndTime"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('b-input-group',{scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('k-form-select',{attrs:{"name":"year","options":_vm.monthOptions},model:{value:(_vm.form.months),callback:function ($$v) {_vm.$set(_vm.form, "months", $$v)},expression:"form.months"}})]},proxy:true},{key:"append",fn:function(){return [_c('k-form-timepicker',{staticClass:"ml-2",model:{value:(_vm.form.dailyTime),callback:function ($$v) {_vm.$set(_vm.form, "dailyTime", $$v)},expression:"form.dailyTime"}})]},proxy:true}],null,true)},[_c('k-form-select',{staticClass:"ml-2",attrs:{"options":_vm.dayOptions},model:{value:(_vm.form.weekMonth),callback:function ($$v) {_vm.$set(_vm.form, "weekMonth", $$v)},expression:"form.weekMonth"}})],1)]}}],null,false,113308177)})],1):_vm._e(),_c('k-form-group',{attrs:{"label-class":"require-mark","label-suffix":"sendTo","label-for":"sendTo","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('KValidationProvider',{attrs:{"rules":{
        required: true,
      },"slim":"","name":"sendTo"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-multiselect',{attrs:{"options":_vm.sendToOptions,"multiple":false},model:{value:(_vm.form.sendTo),callback:function ($$v) {_vm.$set(_vm.form, "sendTo", $$v)},expression:"form.sendTo"}})]}}])})],1),_c('k-form-group',{attrs:{"label-suffix":"officerRecipients","label-for":"officerRecipients","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('KValidationProvider',{attrs:{"rules":{},"slim":"","name":"officerRecipients"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('OfficerRecipients',{attrs:{"form":_vm.form,"plaintext":_vm.isPlainText}})]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }