import i18n from "./index";
import globalEnv from "../libs/globalEnv";

export const g = (key, pName) => {
  if (typeof key == "string") {
    key = key.trim();
    let pathname = pName ? pName : window.location.pathname;
    if (
      globalEnv.VUE_APP_BASE_PATH &&
      pathname.startsWith(globalEnv.VUE_APP_BASE_PATH)
    ) {
      pathname = pathname.substr(globalEnv.VUE_APP_BASE_PATH.length);
    }
    let pathParts = pathname.split("/");
    if (pathParts.length < 2) {
      return "";
    }
    let first = pathParts[1];
    if (first == "") {
      return i18n.t(`common.${key}`);
    }

    let langKey = first.toLowerCase() + "." + key;
    let r = i18n.t(langKey);
    if (r == langKey) {
      r = i18n.t(`common.${key}`);
    }
    return r;
  }
  return "";
};
