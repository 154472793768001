const routes = [
  {
    name: "form",
    path: "form",
    meta: {
      title: "form",
      pageTitle: "welcome",
      requiresAuth: true,
      anonymous: true
      // permissionCode: PL_Worker_Code.Position.Positions,
    },
    route: true,
    component: () => import("@/views/application/form"),
  },
  {
    name: "searchVoter",
    path: "searchVoter",
    meta: {
      title: "searchVoter",
      pageTitle: "welcome",
      requiresAuth: true,
      anonymous: true
    },
    component: () => import("../../views/application/searchVoter"),
  },
  {
    name: "submitSuccessfully",
    path: "submit-successful",
    meta: {
      title: "Submitted Successfully",
      pageTitle: "welcome",
      requiresAuth: true,
      anonymous: true
      // permissionCode: PL_Worker_Code.Position.Positions,
    },
    route: true,
    component: () => import("@/views/application/submitted-successfully"),
  },
];

export default [
  {
    name: 'application',
    path: '/application',
    meta: {
      title: 'application',
      requiresAuth: true,
      anonymous: true
    },
    component: () => import('@/views/application/loading-page'),
    route: true,
    children: [...routes],
  },
]
