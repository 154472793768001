<template>
  <span class="iconSpan">
    <b-icon
      icon="gear-fill"
      v-b-modal.popcolumnconfig
      ref="settingToolTip"
    />
    <k-popwindow
      id="popcolumnconfig"
      @ok="onPopSelected"
      :before-close="onBeforeClose"
      :title="$g('icon.fieldsSetting')"
    >
      <column-config
        ref="selColumn"
        :list-id="listId"
        :is-use-candidate-api="isUseCandidateApi"
      />
    </k-popwindow>
    <b-tooltip
      :target="() => $refs['settingToolTip']"
      :title="$g('icon.settingToolTip')"
      boundary="window"
      :container="uid"
      placement="bottomleft"
      triggers="hover"
    />
    <div :id="uid" />
  </span>
</template>
<script>
import columnConfig from "./column-config";
import { savePageFieldsConfig, saveCandidatePageFieldsConfig } from "./api/common";

export default {
  data(){
    return {
      uid: "tip" + this._uid,
    };
  },
  components: { columnConfig },
  props: {
    listId: {
      type: String,
      default(){
        return null;
      },
    },
    isUseCandidateApi: {
      type: Boolean,
      default(){
        return false;
      },
    },
  },
  methods: {
    async onBeforeClose(){
      const valid = await this.$refs.selColumn.checkValid();
      if(valid){
        return this.$bvModal
            .msgBoxConfirm(this.$g("const.confirm.save"), {
              title: this.$g("const.confirm.confirm"),
              cancelTitle: this.$g("const.confirm.saveYes"),
              okTitle: this.$g("const.confirm.no"),
              cancelVariant: "primary",
              okVariant: "secondary",
              noCloseOnBackdrop: true,
            })
            .then(value => {
              return value != null && !value;
            });
      }
    },
    onPopSelected(){
      let items = this.$refs.selColumn.getAllRows();
      let selItems = this.$refs.selColumn.getSelected();
      let keysAndOrder = [];
      items.forEach((el) => {
        keysAndOrder.push({
          key: el.key,
          orderIndex: el.orderIndex,
          width: el.width,
          selected: selItems.find((s) => {
            return s.key == el.key;
          })
              ? true
              : false,
        });
      });
      let changeValue = {};
      changeValue.keysAndOrder = keysAndOrder;
      changeValue.listId = this.listId;
      let api;
      if(this.isUseCandidateApi){
        api = saveCandidatePageFieldsConfig;
      } else {
        api = savePageFieldsConfig;
      }
      api(changeValue).then((data) => {
        this.$emit("column-changed", data);
      });
    },
  },
};
</script>
