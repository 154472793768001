<template>
  <div class="content-page">
    <KValidationObserver ref="observer" v-slot="valdata" class="content-page">
      <div class="content-page">
        <div class="content">
          <div
            class="right-bar"
            v-show="
              seenSearch &&
              templateType == enumTemplateType.email &&
              !isPlainText
            "
          >
            <b-icon icon="x" @click="onToggleSearch" />
            <choose-template
              :show-template="false"
              :is-event="isEvent"
              :template-type="parseInt(templateType)"
              @docChoose="onDocChoose"
              ref="chooseTemplate"
              :is-notification="isNotification"
            />
          </div>
          <k-form
            ref="form"
            @reset="onReset"
            class="content-form"
            @submit.prevent="onSubmit"
            @keydown.enter="isPrevent"
          >
            <div class="nav-btn-group">
              <k-button-group>
                <slot name="formBar" v-bind="valdata" :form="form" />
              </k-button-group>
              <KWikiHelp :props-wiki-help-code="wikiHelpCode" />
            </div>
            <div class="content-main">
              <k-alert-msg ref="lblMsg" />
              <div class="content-navTab">
                <slot name="formBarNav" :form="form" />
              </div>
              <div class="content-party pt-3">
                <div class="content-group">
                  <k-form-group
                    label-class="require-mark"
                    label-suffix="title"
                    label-for="title"
                    label-align-md="right"
                    label-cols-md="3"
                    content-cols-lg="6"
                    content-cols-md="7"
                  >
                    <KValidationProvider
                      :rules="{
                        required: true,
                        max: 200,
                        validateSpecialCharacter: false,
                        remote: isSubjectExits,
                      }"
                      v-slot="{ errors }"
                      slim
                      name="title"
                      :mode="inputmode"
                    >
                      <k-alert :show="errors.length > 0" variant="warning">
                        {{ errors[0] }}
                      </k-alert>
                      <k-form-input
                        v-model="form.subject"
                        :plaintext="isPlainText"
                      />
                    </KValidationProvider>
                  </k-form-group>
                  <k-form-group
                    v-if="hasSearchCategory"
                    label-class="require-mark"
                    label-suffix="category"
                    label-for="Category"
                    label-align-md="right"
                    label-cols-md="3"
                    content-cols-lg="6"
                    content-cols-md="7"
                  >
                    <KValidationProvider
                      rules="required"
                      v-slot="{ errors }"
                      slim
                      name="category"
                    >
                      <k-alert :show="errors.length > 0" variant="warning">
                        {{ errors[0] }}
                      </k-alert>
                      <k-form-select
                        v-model="form.category"
                        :options="categories"
                        :plaintext="isPlainText"
                      />
                    </KValidationProvider>
                  </k-form-group>
                  <slot
                    name="formGroup"
                    :form="form"
                    :plaintext="isPlainText"
                    :templateType="templateType"
                    :enumTemplateType="enumTemplateType"
                  />
                  <MessageTemplateDetailPhone
                    v-if="form.templateType == enumTemplateType.phone"
                    :form="form"
                    :is-plain-text="isPlainText"
                    :size="size"
                    :accept="accept"
                  />
                </div>
                <KValidationObserver ref="LanguageListObserver">
                  <LanguageList
                    ref="languageList"
                    v-if="form.templateType != enumTemplateType.phone"
                    :person-languages="personLanguages"
                    :is-validation="true"
                    @onLanguageSwitch="onLanguageSwitch"
                  >
                    <template #content>
                      <MessageTemplateDetailContent
                        ref="messageTemplateDetailContent"
                        :form="templateTypeForm"
                        :enum-template-type="enumTemplateType"
                        :template-type="parseInt(form.templateType)"
                        :is-plain-text="isPlainText"
                        @label-select="onLabelSelect"
                        @form-change="templateTypeFormChange"
                        :subject-required="
                          MessageTemplateDetailContentSubjectRequired
                        "
                        :content-editor-required="
                          MessageTemplateDetailContentContentEditorRequired
                        "
                      >
                        <template #smsInsertLabel="{ form }">
                          <div
                            v-if="!isPlainText"
                            class="col-form-label"
                            id="smsInsertLabel"
                          >
                            <img
                              src="../../../../../assets/image/InsertLabel.png"
                              v-b-tooltip="{
                                placement: 'topright',
                                trigger: 'hover',
                                title: 'Insert Label',
                                boundary: 'window',
                                container: 'insertLabel',
                              }"
                              style="cursor: pointer"
                              alt="smsInsertLabel"
                              v-b-modal.pop-sms-insert-label-dialog
                            />
                          </div>
                          <k-popwindow
                            id="pop-sms-insert-label-dialog"
                            @ok="onSmsLabelSelect(form)"
                            :title="$g('InsertLabels ')"
                          >
                            <slot name="insertLabel" />
                          </k-popwindow>
                        </template>
                        <template #insertLabel>
                          <slot name="insertLabel" />
                        </template>
                        <template #smsInsertLabel="{ form }">
                          <div
                            v-if="!isPlainText"
                            class="col-form-label"
                            id="smsInsertLabel"
                          >
                            <img
                              src="../../../../../assets/image/InsertLabel.png"
                              v-b-tooltip="{
                                placement: 'topright',
                                trigger: 'hover',
                                title: 'Insert Label',
                                boundary: 'window',
                                container: 'insertLabel',
                              }"
                              style="cursor: pointer"
                              alt="smsInsertLabel"
                              v-b-modal.pop-sms-insert-label-dialog
                            />
                          </div>
                          <k-popwindow
                            id="pop-sms-insert-label-dialog"
                            @ok="onSmsLabelSelect(form)"
                            :title="$g('InsertLabels ')"
                          >
                            <slot name="insertLabel" />
                          </k-popwindow>
                        </template>
                      </MessageTemplateDetailContent>
                    </template>

                    <template
                      #right
                      v-if="
                        templateType == enumTemplateType.email && !isPlainText
                      "
                    >
                      <button
                        type="button"
                        class="btn rounded-0 nav-btn-search search-template"
                        @click="onToggleSearch"
                        :class="{ active: isActive }"
                      >
                        <b-icon icon="chevron-right" v-if="seenSearch" />
                        <b-icon icon="chevron-left" v-else />
                      </button>
                    </template>
                  </LanguageList>
                </KValidationObserver>
              </div>
            </div>
          </k-form>
        </div>
      </div>
    </KValidationObserver>
  </div>
</template>

<script>
import Moment from "moment-timezone";

let _ = require("lodash");
import { repResult } from "../../../../../libs/global";
import {
  getTemplate,
  addTemplate,
  putTemplate,
  getCategories,
  checkSubjectExits,
} from "../../../api/messageTemplate";

import chooseTemplate from "../../../components/chooseTemplate";
import LanguageList from "../../../components/languageList.vue";
import { enumTemplateType } from "../../../enums/enumTemplateType";
import { originDataChangeMixin } from "../../../../../utils/mixins/mixin";
import { getUploadConfig } from "../../../../../api/upload.js";
import storeage from "../../../../../utils/storeage";
import { isPrevent } from "../../../../../utils/sysUtiles";
import MessageTemplateDetailContent from "./messageTemplateDetailContent.vue";
import MessageTemplateDetailPhone from "./messageTemplateDetailPhone.vue";
import KWikiHelp from "../../../../wiki-help/wiki-help-link";

export default {
  mixins: [originDataChangeMixin],
  components: {
    chooseTemplate,
    MessageTemplateDetailContent,
    MessageTemplateDetailPhone,
    LanguageList,
    KWikiHelp,
  },
  props: {
    expandForm: {
      type: Array,
      default() {
        return [];
      },
    },
    hasSearchCategory: {
      type: Boolean,
      default() {
        return true;
      },
    },
    id: {
      type: String,
      default() {
        return "";
      },
    },
    isPlainText: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isEvent: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isNotification: {
      type: Boolean,
      default() {
        return false;
      },
    },
    MessageTemplateDetailContentSubjectRequired: {
      type: Boolean,
      default: false,
    },
    MessageTemplateDetailContentContentEditorRequired: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isPrevent,
      seenSearch: true,
      isActive: false,
      maxContent: 1500,
      electionId: this.isEvent ? storeage.getElectionId() : "",
      form: {
        electionId: "",
        subject: "",
        file: null,
        fileName: "audio",
        templateType: this.$route.query.templateType,
        personLanguages: [],
        msgTemplateLanguageDetails: [],
      },
      defaultTemplateTypeForm: {
        id: null,
        languageId: null,
        subject: null,
        content: "",
        files: [],
        documentId: null,
        fileName: null,
        file: null,
        headerId: null,
        headerDisplay: null,
        footerId: null,
        footerDisplay: null,
      },
      templateTypeForm: {},
      templateTypeFormMap: new Map(),
      templateTypeFormNewMap: new Map(),
      personLanguages: [],
      labels: [
        { value: "$FullData$", checked: false },
        { value: "$Data$", checked: false },
        { value: "$AbbDataWeek$", checked: false },
        { value: "$Date(th)$", checked: false },
      ],
      languageId: "",
      enumTemplateType: enumTemplateType,
      categories: [],
      templateType: this.$route.query.templateType,
      accept: "",
      size: 0,
      KVALUE_FOMATE: "YYYY-MM-DD",
      timeZone: storeage.getTimeZone(),
      dateformat: storeage.getDateFormat(),
      wikiHelpCode: null,
      smsForm: null,
    };
  },
  computed: {},
  created() {
    this.initWikiHelpCode();
  },
  mounted() {
    this.bindCategories();
    this.getData();
    this.toggleResponsive();
    window.addEventListener("resize", this.toggleResponsive);
    getUploadConfig("phone").then((data) => {
      this.accept = data.data.acceptTypes;
      this.size = data.data.maxSize;
    });
  },
  methods: {
    onSmsLabelSelect(form) {
      this.smsForm = form;
      this.$emit("label-select");
    },
    initWikiHelpCode() {
      if (!this.$route.query.templateType) {
        return;
      }
      let wikiHelpCodes = this.isEvent
        ? this.$wikiHelpLinkCodes?.Event.Communication.Template
        : this.$wikiHelpLinkCodes?.BAU.Communication.Template;
      if (this.isNotification) {
        wikiHelpCodes = this.isEvent
          ? this.$wikiHelpLinkCodes?.Event.Notification?.Template
          : this.$wikiHelpLinkCodes?.BAU.Notification?.Template;
      }
      switch (parseInt(this.$route.query.templateType)) {
        case enumTemplateType.email:
          this.wikiHelpCode = wikiHelpCodes?.Email.BasicInfo;
          break;
        case enumTemplateType.phone:
          this.wikiHelpCode = wikiHelpCodes?.Phone.BasicInfo;
          break;
        case enumTemplateType.sms:
          this.wikiHelpCode = wikiHelpCodes?.SMS.BasicInfo;
          break;
        case enumTemplateType.letter:
          this.wikiHelpCode = wikiHelpCodes?.Letter.BasicInfo;
          break;
        case enumTemplateType.documentation:
          break;
        default:
          break;
      }
    },
    async getContent() {
      let contentIsValid = true;
      if (this.form.templateType === this.enumTemplateType.letter) {
        contentIsValid = await this.$refs.LanguageListObserver.validate();
      }
      return contentIsValid;
    },
    onDocChoose(doc) {
      if (parseInt(this.templateType) !== enumTemplateType.email) return false;
      if (
        this.templateTypeForm.files.find((c) => c.documentId == doc.docmentId)
      ) {
        return;
      }
      let nowDoc = { ...doc };
      delete nowDoc.docmentId;
      nowDoc.name = doc.fileName;
      nowDoc.documentId = doc.docmentId;
      nowDoc.isDocAdd = true;
      delete nowDoc.name;
      this.templateTypeForm.files.push(nowDoc);
    },
    onToggleSearch() {
      this.seenSearch = !this.seenSearch;
      this.isActive = !this.isActive;
    },
    templateTypeFormChange(newData) {
      if (!newData.languageId) {
        newData.languageId = this.languageId;
      }
      if (!this.templateTypeFormNewMap.get(newData.languageId)) {
        this.templateTypeFormNewMap.set(this.languageId, newData);
      }
      this.personLanguages.find((c) => c.id == this.languageId).isData =
        newData.isData;

      this.form.templateTypeForm = this.templateTypeForm;
    },
    onLanguageSwitch(item) {
      if (this.templateTypeForm.languageId == item.id) {
        return;
      }
      this.languageId = item.id;
      this.setTemplateTypeForm(this.templateTypeFormNewMap.get(item.id));
    },
    setTemplateTypeForm(data) {
      let dataType = Array.isArray(data);
      if (dataType && data.length > 0) {
        data.forEach((c) => {
          this.templateTypeFormMap.set(c.languageId, _.cloneDeep(c));
          this.templateTypeFormNewMap.set(c.languageId, _.cloneDeep(c));
        });
        this.templateTypeForm = this.templateTypeFormNewMap.get(
          this.languageId
        );
      } else {
        if (!data || dataType) {
          this.templateTypeForm = _.cloneDeep(this.defaultTemplateTypeForm);
        } else {
          this.templateTypeForm = data;
        }
      }
      this.templateTypeFormMap.set(
        this.templateTypeForm.languageId,
        _.cloneDeep(this.templateTypeForm)
      );
    },
    inputmode() {
      return { on: ["input"] };
    },

    bindCategories() {
      let data = {
        enumTemplateType: this.templateType,
        electionId: this.electionId,
      };
      getCategories(data).then((res) => {
        if (res.data.length > 0) {
          this.categories = res.data;
        }
      });
    },
    onLabelSelect() {
      this.smsForm = null;
      this.$emit("label-select");
    },
    onSmsLabelSelect(form) {
      this.smsForm = form;
      this.$emit("label-select");
    },
    insertHtml(val) {
      if (this.smsForm) {
        this.smsForm.content = (this.smsForm.content ?? "") + val;
      } else {
        this.$refs.messageTemplateDetailContent.$refs.messageEditor.insertHtml(
          val
        );
      }
    },
    handleDate(date) {
      return Moment.tz(date, this.timeZone).format(storeage.getDateFormat());
    },
    handleTime(time) {
      return Moment.tz(time, this.timeZone).format("HH:mm");
    },
    getDateStamp(time) {
      if (!time) {
        return 0;
      }
      let date = this.getCurDate();
      let datestr = `${this.handleDate(date)} ${this.handleTime(time)}`;
      const dtime = Moment.tz(
        datestr,
        `${storeage.getDateFormat()} HH:mm:ss`,
        this.timeZone
      );
      return dtime.utc().valueOf();
    },
    getCurDate() {
      let value = new Date();
      this.kInputValue = Moment(value, this.KVALUE_FOMATE).format(
        this.dateformat
      );
      let timevalue = Moment.tz(
        value,
        this.KVALUE_FOMATE,
        this.timeZone
      ).valueOf();
      return timevalue;
    },
    formDataAdd() {
      var formData = new FormData();
      if (this.form.id) {
        formData.append("id", this.form.id);
      }
      formData.append("isNotification", this.isNotification);
      for (const ex of this.expandForm) {
        if (this.form[ex.name]) {
          switch (ex.type) {
            case String:
              formData.append(ex.name, this.form[ex.name]);
              break;
            case Array:
              this.form[ex.name].forEach((item, index) => {
                formData.append(`${ex.name}[${index}]`, item);
              });
              break;
            default:
              if (ex.name == "dailyTime") {
                formData.append(ex.name, this.getDateStamp(this.form[ex.name]));
              } else {
                formData.append(ex.name, this.form[ex.name]);
              }
              break;
          }
        }
      }
      formData.append("subject", this.form.subject);
      if (this.form.category) formData.append("category", this.form.category);
      formData.append("templateType", this.form.templateType);
      if (this.form.file && this.form.file.size > 0) {
        formData.append("File", this.form.file);
      }
      formData.append("electionId", this.electionId);
      formData = this.getTypeFormContentaData(formData);
      return formData;
    },
    getTypeFormContentaData(_formData) {
      let formData = _formData;
      let map = this.templateTypeFormNewMap;
      let index = 0;
      const defaultKey = Object.keys(this.defaultTemplateTypeForm);
      let setTypeFormContentToFormFiles = (v) => {
        if (!v) {
          return;
        }
        v.forEach((value, i) => {
          formData.append(
            `MsgTemplateLanguageDetails[${index}].Files[${i}].isDocAdd`,
            value["isDocAdd"] ?? false
          );
          formData.append(
            `MsgTemplateLanguageDetails[${index}].Files[${i}].MessageRecordsId`,
            value["messageRecordsId"] ?? ""
          );
          formData.append(
            `MsgTemplateLanguageDetails[${index}].Files[${i}].DocumentId`,
            value["documentId"] ?? ""
          );
          if (!value.documentId) {
            formData.append(
              `MsgTemplateLanguageDetails[${index}].Files[${i}].FileName`,
              value.name
            );
            formData.append(
              `MsgTemplateLanguageDetails[${index}].Files[${i}].File`,
              value
            );
          } else {
            formData.append(
              `MsgTemplateLanguageDetails[${index}].Files[${i}].FileName`,
              value["fileName"]
            );
          }
        });
      };
      map.forEach((value, key) => {
        defaultKey.forEach((k) => {
          if (k === "Files" || k === "files") {
            setTypeFormContentToFormFiles(value[k]);
          } else {
            formData.append(
              `MsgTemplateLanguageDetails[${index}].${k}`,
              value[k] ?? ""
            );
          }
        });
        index++;
      });
      return formData;
    },
    checkLanguageContent() {
      for (let [key, value] of this.templateTypeFormNewMap) {
        if (key && value.subject && value.content) {
          this.showPreview = true;
          return;
        }
      }
    },
    async onSubmit() {
      const isValid = await this.$refs.observer.validate();
      const contentIsValid = await this.getContent();
      let PersonLanguagesIsData = this.personLanguages.findIndex(
        (c) => c.isData
      );
      if (
        PersonLanguagesIsData < 0 &&
        this.form.templateType != enumTemplateType.phone &&
        this.isNotification
      ) {
        this.$refs.lblMsg.message(
          repResult.faild,
          this.$g("const.confirm.messageContentNull")
        );
        return;
      }
      if (isValid && contentIsValid) {
        let pmOpt = this.$confirm({
          title: this.$g("const.confirm.confirm"),
          content: this.$g("const.confirm.save"),
          cancelType: "secondary",
          okText: this.$g("const.confirm.saveYes"),
          cancelText: this.$g("const.confirm.no"),
        });
        if (pmOpt == null) {
          return;
        }
        pmOpt.then(() => {
          var opt = addTemplate;
          var formData = this.formDataAdd();
          if (this.id && this.id != "00000000-0000-0000-0000-000000000000") {
            opt = putTemplate;
          }

          opt(formData)
            .then((m) => {
              if (m.data.status == repResult.success) {
                this.$emit("save-successful", m, m.data.info);
              } else {
                this.$refs.lblMsg.message(m.data.status, m.message);
              }
            })
            .catch((err) => {
              throw err;
            });
        });
      }
    },
    async isSubjectExits() {
      let result = null;
      await checkSubjectExits({
        subject: this.form.subject,
        id: this.form.id,
        templateType: this.form.templateType,
        electionId: this.form.electionId,
        isNotification: this.isNotification,
      }).then(function (rep) {
        result = rep;
      });
      return result;
    },
    onReset(event) {
      event.preventDefault();
      this.form = {};
    },
    getData() {
      getTemplate(this.id, "", this.isNotification).then((data) => {
        this.form = {
          ...data.data,
          templateType: this.$route.query.templateType,
          msgTemplateLanguageDetails:
            data.data.msgTemplateLanguageDetails ?? [],
          electionId:
            data.data.electionId ?? this.isEvent
              ? storeage.getElectionId()
              : "",
        };
        this.setPersonLanguages(
          data.data.personLanguages,
          this.form.msgTemplateLanguageDetails
        );
        if (data.data.filePath) {
          this.form.file = data.data.filePath;
          this.form.fileName = data.data.fileName;
        }
        this.setTemplateTypeForm(
          data.data.msgTemplateLanguageDetails
            ? data.data.msgTemplateLanguageDetails
            : null
        );
        if (
          this.$route.query.templateType == enumTemplateType.email ||
          this.$route.query.templateType == enumTemplateType.letter
        ) {
          if (this.templateTypeForm.content) {
            this.templateTypeForm.content =
              this.templateTypeForm.content.replace(/\r\n/g, "\n");
          }
        }
        this.$nextTick(() => {
          this.form.templateTypeForm = this.templateTypeForm;
          this.setOrignData(this.form);
        });
        this.$refs.observer.reset();
      });
    },
    setPersonLanguages(item, msgTemplateLanguageDetails) {
      let data = _.cloneDeep(item);
      data.forEach((c) => {
        c.active = c.isDefault;
        c.isData =
          msgTemplateLanguageDetails.findIndex(
            (d) => c.id == d.languageId && d.content && d.subject
          ) >= 0;
      });
      this.personLanguages = data.sort((a, b) => b.isDefault - a.isDefault);
      this.languageId = this.personLanguages.find((c) => c.active).id;
    },
    toggleResponsive() {
      if (window.innerWidth < 1024) {
        if (this.seenSearch) {
          this.onToggleSearch();
        }
      }
    },
    hasClass(classList, className) {
      var result = false;
      for (var i = 0; i < classList.length; i++) {
        if (classList[i] == className) {
          result = true;
          break;
        }
      }
      return result;
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.toggleResponsive);
  },
};
</script>
