<template>
  <span class="wiki-help-link">
    <b-button
      class="float-right"
      v-if="hasWikiHelp"
      @click="onclickEvent"
      :id="uid"
      v-b-tooltip="{
        placement: 'bottomleft',
        trigger: 'hover',
        title: $g('icon.helpLink'),
        boundary: 'window',
        container: uid,
      }"
    >
      <b-icon
        icon="question-circle-fill"
        font-scale="2"
        class=""
      />
    </b-button>
  </span>
</template>

<script>
import { getToken } from "../../utils/token";

export default {
  name: "WikiHelpLink",
  props: {
    propsHasWikiHelp: {
      type: Boolean,
      default: true,
    },
    propsWikiHelpCode: {
      type: String,
      default: null,
    },
  },
  data(){
    return {
      item: null,
      uid: "tip" + this._uid,
      hasWikiHelp: false,
      requestCompletion: false,
    };
  },
  computed: {
    code(){
      return (
          this.propsWikiHelpCode ??
          this.$route.meta.wikiHelpCode ??
          this.$route.meta.permissionCode ??
          null
      );
    },
    // hasWikiHelp: {
    //   get: function() {
    //     if (this.defaultHasWikiHelp) {
    //       return (
    //         this.defaultHasWikiHelp &&
    //         this.$hasWikiHelpCode(this.code) &&
    //         this.propsHasWikiHelp
    //       );
    //     } else {
    //       console.log(this.$hasWikiHelpCode(this.code));
    //       return this.$hasWikiHelpCode(this.code) && this.propsHasWikiHelp;
    //     }
    //   },
    //   set: function(value) {
    //     this.defaultHasWikiHelp = value;
    //   },
    // },
  },
  created(){
    if(this.$setWikiHelpCodes){
      this.$setWikiHelpCodes({ isEvent: this.$route.meta.isEvent }, this);
    }
  },
  methods: {
    onclickEvent(){
      let code = getToken()?.token.substring(7).toString();
      let items = this.$getWikiHelpCode(this.code);
      if(!items){
        return;
      }
      let href = `http://wiki.pollchief.com/login?code=${code}&url=${items.link}`;
      window.open(href);
    },
    requestCompletionEvent(value){
      if(value){
        console.log(this.code);
        this.hasWikiHelp =
            this.$hasWikiHelpCode(this.code) && this.propsHasWikiHelp;
      }
    },
  },
  watch: {
    code(newData, oldData){
      if(newData && oldData != newData){
        this.requestCompletionEvent(true);
      }
    },
  },
};
</script>

<style></style>
