<template>
  <div>
    <template v-for="(item, index) in extFields">
      <div
        class="content-title"
        v-if="item.type === fieldType.section"
        :key="index"
      >
        {{ item.displayName }}
      </div>
      <k-form-group
        v-else-if="item.showAble"
        :key="index"
        :label-class="
          item.isMandatory || item.requiredMark ? 'require-mark' : ''
        "
        :label-suffix="!item.useLabel ? item.displayName : ''"
        :label="item.useLabel ? item.displayName : ''"
        label-align-md="right"
        label-cols-md="3"
        content-cols-lg="6"
        content-cols-md="7"
        :no-locale="true"
        label-for="noneBind"
        :style="{ order: item.order }"
      >
        <KValidationProvider
          v-if="item.type === fieldType.string"
          :rules="{ required: item.isMandatory, max: 200 }"
          v-slot="{ errors }"
          slim
          :name="`text${index}`"
        >
          <k-alert :show="errors.length > 0" variant="warning">
            {{ errors[0] }}
          </k-alert>
          <k-form-input
            v-if="item.type === fieldType.string"
            v-model="item.inputValue"
            type="text"
            :plaintext="handleplaintext(item)"
          />
        </KValidationProvider>
        <KValidationProvider
          v-if="item.type === fieldType.int"
          :rules="{
            required: item.isMandatory,
            numberBetween: { minValue: -1000000, maxValue: 1000000 },
          }"
          v-slot="{ errors }"
          slim
          :name="`number${index}`"
        >
          <k-alert :show="errors.length > 0" variant="warning">
            {{ errors[0] }}
          </k-alert>
          <k-form-input
            v-if="item.type === fieldType.int"
            v-model="item.inputValue"
            :plaintext="handleplaintext(item)"
          />
        </KValidationProvider>
        <KValidationProvider
          v-if="item.type === fieldType.decimal"
          :rules="{
            required: item.isMandatory,
            floatBetween: { minValue: -1000000.0, maxValue: 1000000.0 },
            float: { DecimalPlacesMin: 2 },
          }"
          v-slot="{ errors }"
          slim
          :name="`decimal${index}`"
        >
          <k-alert :show="errors.length > 0" variant="warning">
            {{ errors[0] }}
          </k-alert>
          <k-form-input
            v-if="item.type === fieldType.decimal"
            v-model="item.inputValue"
            :plaintext="handleplaintext(item)"
          />
        </KValidationProvider>
        <KValidationProvider
          v-if="item.type === fieldType.dateTimeAndYears"
          :rules="{
            required: item.isMandatory,
            validateDateIsRequired: item.isMandatory,
            validateDateFormat: true,
          }"
          slim
          :name="`dateTimeAndYears${index}`"
          v-slot="{ errors }"
        >
          <k-alert :show="errors.length > 0 && !plaintext" variant="warning">
            {{ errors[0] }}
          </k-alert>
          <k-form-datepicker
            v-if="item.type === fieldType.dateTimeAndYears"
            v-model="item.inputValue"
            class="mb-2"
            :plaintext="handleplaintext(item)"
          />
        </KValidationProvider>
        <KValidationProvider
          v-if="item.type === fieldType.datetime"
          :rules="{
            required: item.isMandatory,
            validateDateIsRequired: item.isMandatory,
            validateDateTimeFormat: true,
          }"
          slim
          :name="`time${index}`"
          v-slot="{ errors }"
        >
          <k-alert :show="errors.length > 0 && !plaintext" variant="warning">
            {{ errors[0] }}
          </k-alert>
          <k-form-date-timepicker
            v-if="item.type === fieldType.datetime"
            v-model="item.inputValue"
            class="mb-2"
            :plaintext="handleplaintext(item)"
          />
        </KValidationProvider>
        <KValidationProvider
          v-if="item.type === fieldType.date"
          :rules="{
            required: item.isMandatory,
            validateDateIsRequired: item.isMandatory,
            validateDateTimeFormat: true,
          }"
          slim
          :name="`date${index}`"
          v-slot="{ errors }"
        >
          <k-alert :show="errors.length > 0 && !plaintext" variant="warning">
            {{ errors[0] }}
          </k-alert>
          <k-form-datepicker
            v-if="item.type === fieldType.date"
            v-model="item.inputValue"
            class="mb-2"
            :plaintext="handleplaintext(item)"
          />
        </KValidationProvider>
        <KValidationProvider
          v-if="item.type === fieldType.phone"
          :rules="{ required: item.isMandatory, phone: true, max: 50 }"
          v-slot="{ errors }"
          slim
          :name="`phone${index}`"
        >
          <k-alert :show="errors.length > 0" variant="warning">
            {{ errors[0] }}
          </k-alert>
          <k-form-phone-input
            v-if="item.type === fieldType.phone"
            v-model="item.inputValue"
            :plaintext="handleplaintext(item)"
            :is-phone="true"
          />
        </KValidationProvider>
        <KValidationProvider
          v-if="item.type === fieldType.email"
          :rules="{ required: item.isMandatory, email: true, max: 200 }"
          v-slot="{ errors }"
          slim
          :name="`email${index}`"
        >
          <k-alert :show="errors.length > 0" variant="warning">
            {{ errors[0] }}
          </k-alert>
          <k-form-input
            v-if="item.type === fieldType.email"
            v-model="item.inputValue"
            type="text"
            :plaintext="handleplaintext(item)"
          />
        </KValidationProvider>
        <KValidationProvider
          v-if="item.type === fieldType.checkBox"
          :rules="{ required: item.isMandatory }"
          v-slot="{ errors }"
          slim
          :name="`bool${index}`"
        >
          <k-alert :show="errors.length > 0" variant="warning">
            {{ errors[0] }}
          </k-alert>
          <ext-checkbox
            v-if="item.type === fieldType.checkBox"
            v-model="item.inputValue"
            :plaintext="handleplaintext(item)"
            :options="item.options ? item.options : []"
            class="col-form-label d-block"
          ></ext-checkbox>
        </KValidationProvider>
        <KValidationProvider
          v-if="item.type === fieldType.singleSelect"
          :rules="{ required: item.isMandatory }"
          v-slot="{ errors }"
          slim
          :name="`singleSelect${index}`"
        >
          <k-alert :show="errors.length > 0" variant="warning">
            {{ errors[0] }}
          </k-alert>
          <k-form-select
            v-if="item.type === fieldType.singleSelect"
            :name="`singleSelect${index}`"
            v-model="item.inputValue"
            :options="item.options ? item.options : []"
            :plaintext="handleplaintext(item)"
          />
        </KValidationProvider>
        <KValidationProvider
          v-if="item.type === fieldType.multiSelect"
          :rules="{ required: item.isMandatory }"
          v-slot="{ errors }"
          slim
          :name="`multiSelect${index}`"
        >
          <k-alert :show="errors.length > 0" variant="warning">
            {{ errors[0] }}
          </k-alert>
          <ext-multi-select
            v-if="item.type === fieldType.multiSelect"
            v-model="item.inputValue"
            :plaintext="handleplaintext(item)"
            :options="item.options ? item.options : []"
          ></ext-multi-select>
        </KValidationProvider>
        <KValidationProvider
          v-if="item.type === fieldType.textArea"
          :rules="{ required: item.isMandatory, max: 2000 }"
          v-slot="{ errors }"
          slim
          :name="`textArea${index}`"
        >
          <k-alert :show="errors.length > 0" variant="warning">
            {{ errors[0] }}
          </k-alert>
          <k-form-textarea
            v-if="item.type === fieldType.textArea"
            :plaintext="handleplaintext(item)"
            v-model="item.inputValue"
            rows="4"
          />
        </KValidationProvider>
        <KValidationProvider
          v-if="item.type === fieldType.radio"
          :rules="{ required: item.isMandatory }"
          v-slot="{ errors }"
          slim
          :name="`radio${index}`"
        >
          <k-alert :show="errors.length > 0" variant="warning">
            {{ errors[0] }}
          </k-alert>
          <k-form-radio-group
            v-if="item.type === fieldType.radio"
            v-model="item.inputValue"
            :options="item.options ? item.options : []"
            :disabled="handleplaintext(item)"
            class="col-form-label d-block"
          />
        </KValidationProvider>
      </k-form-group>
    </template>
  </div>
</template>
<script>
import frag from "vue-frag";
import ExtCheckbox from "./components/ext-checkbox.vue";
import ExtMultiSelect from "./components/ext-multSelect.vue";
export default {
  components: { ExtCheckbox, ExtMultiSelect },
  directives: {
    frag,
  },
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: Object,
      default() {
        return null;
      },
    },
    plaintext: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isSort: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      extFields: [],
      fieldType: {
        string: 1,
        int: 2,
        datetime: 3,
        decimal: 4,
        phone: 8,
        email: 9,
        dateTimeAndYears: 12,
        singleSelect: 13,
        multiSelect: 14,
        textArea: 15,
        checkBox: 16,
        radio: 17,
        section: 22,
        date: 29,
      },
    };
  },
  methods: {
    handleplaintext(item) {
      if (item.hasOwnProperty("isOnlyView") && item.isOnlyView) {
        return true;
      } else {
        return this.plaintext;
      }
    },
    getInputValue(form, efields) {
      let result = {};
      if (efields) {
        efields.forEach((el) => {
          result[el.fieldName] = el.inputValue;
        });
        Object.assign(form, result);
      }
      return form;
    },
    extFieldsSort(ary) {
      if (Array.isArray(ary)) {
        return ary.sort((a, b) => a.order - b.order);
      }
      return ary;
    },
    includes(arr1, arr2) {
      return arr2.every((val) => arr1.includes(val));
    },
    extFieldFilter(extField) {
      extField?.forEach((element) => {
        element.showAble = true;
        if (element.needConditionalField == 2) {
          let item = extField.find(
            (e) => e.fieldName == element.conditionalField
          );
          if (item) {
            if (item.inputValue instanceof Array) {
              if (this.includes(element.conditionalValue, item.inputValue)) {
                element.showAble = true;
              } else {
                element.showAble = false;
                element.inputValue = null;
              }
            } else {
              if (element.conditionalValue.includes(item.inputValue)) {
                element.showAble = true;
              } else {
                element.showAble = false;
                element.inputValue = null;
              }
            }
          }
        }
      });
      this.$forceUpdate();
    },
  },
  mounted() {
    let ef = this.value.extFields;
    if (ef) {
      ef.forEach((el) => {
        let v = this.value[el.fieldName];
        if (v === 0 || v) {
          el.inputValue = v;
        }
      });
    }
    this.extFields = this.isSort ? this.extFieldsSort(ef) : ef;
  },
  watch: {
    value() {
      let f = this.value.extFields;
      if (f) {
        f.forEach((e) => {
          let v = this.value[e.fieldName];
          if (v === 0 || v) {
            e.inputValue = v;
          }
        });
      }
      this.extFields = f;
    },
    extFields: {
      handler: function (newVal) {
        this.extFieldFilter(newVal);
        let merged = this.getInputValue(this.value, newVal);
        this.$emit("change", merged);
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.paddingTop {
  padding-top: 0 !important;
}
.content-title {
  background-color: #ffffff;
}
</style>
