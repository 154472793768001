var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"detail"},[_c('div',{staticClass:"content"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showThisSearchArea),expression:"showThisSearchArea"}],staticClass:"center-bar"},[_c('div',{staticClass:"nav-btn-group"},[_c('k-button-group',[_c('k-button-back',{attrs:{"back-route":_vm.isEvent ? 'eleReport' : 'report'}},[_vm._v(" buttons.back ")])],1),_c('KWikiHelp',{attrs:{"props-wiki-help-code":_vm.permissionCode.Report.Category}})],1),_c('div',{staticClass:"content-main"},[_c('k-alert-msg',{ref:"lblMsg"}),_c('div',{staticClass:"content-navTab"},[_c('div',{staticClass:"nav-tools"},[_c('b-icon-add',{directives:[{name:"permission",rawName:"v-permission",value:(_vm.permissionCode.Report.CategoryAdd),expression:"permissionCode.Report.CategoryAdd"}],on:{"click":_vm.onAdd}}),_c('b-icon-delete',{directives:[{name:"permission",rawName:"v-permission",value:(_vm.permissionCode.Report.CategoryDelete),expression:"permissionCode.Report.CategoryDelete"}],on:{"click":_vm.onDeleteClick}})],1),_c('div',[_c('b-tabs',[_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$g("categories"))+" "),_c('span',{staticClass:"text-red"},[_vm._v("("+_vm._s(_vm.totalCount)+")")])]},proxy:true}])})],1)],1)]),_c('div',{staticClass:"content-party"},[_c('konn-grid',{ref:"selectableTable",class:{ 'table-show-search-area': _vm.showThisSearchArea },attrs:{"data-source":_vm.dataSource,"get-condition":_vm.getCondition,"list-id":"Id","grid-sort-desc":_vm.sortDesc,"grid-sort-by":_vm.sortField,"sort-icon-left":"","fields":_vm.fields,"has-action":_vm.$has(_vm.permissionCode.Report.CategoryEdit) ||
              _vm.$has(_vm.permissionCode.Report.CategoryAdd)},on:{"total-changed":_vm.onTotalChanged,"items-get":_vm.itemsGet},scopedSlots:_vm._u([{key:"cell(title)",fn:function(row){return [(row.item.isSave == true)?[_c('KValidationObserver',{ref:`observername${row.item.id}`},[_c('KValidationProvider',{attrs:{"rules":{
                      required: true,
                      max: 200,
                      remote: _vm.isCheckname,
                    },"slim":"","mode":"lazy","name":"name"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-input',{attrs:{"id":"txtname","name":"name","type":"text"},model:{value:(row.item.title),callback:function ($$v) {_vm.$set(row.item, "title", $$v)},expression:"row.item.title"}})]}}],null,true)})],1)]:_vm._e()]}},{key:"cell(order)",fn:function(row){return [(row.item.isSave == true)?[_c('KValidationObserver',{ref:`observerorderIndex${row.item.id}`},[_c('KValidationProvider',{attrs:{"rules":{
                      integer: true,
                      required: true,
                      max: 6,
                      numberBetween: { minValue: 1, maxValue: 999999 },
                      remote: _vm.isCheckorder,
                    },"slim":"","name":"orderIndex"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-input',{attrs:{"id":"numberorderIndex","name":"orderIndex","type":"text"},model:{value:(row.item.order),callback:function ($$v) {_vm.$set(row.item, "order", $$v)},expression:"row.item.order"}})]}}],null,true)})],1)]:_vm._e()]}},{key:"cell(actions)",fn:function(row){return [(
                  !_vm.onAddShow(row) && _vm.$has(_vm.permissionCode.Report.CategoryEdit)
                )?_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.onEdit(row)}}},[_vm._v(_vm._s(_vm.$g("buttons.edit")))]):_vm._e(),(_vm.onAddShow(row))?_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.onSaveClick(row)}}},[_vm._v(_vm._s(_vm.$g("buttons.save")))]):_vm._e(),(_vm.onAddShow(row))?_c('a',{staticStyle:{"margin-left":"50px"},attrs:{"href":"#"},on:{"click":function($event){return _vm.onCancelClick(row)}}},[_vm._v(_vm._s(_vm.$g("buttons.cancel")))]):_vm._e()]}}])})],1)],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }