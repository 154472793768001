export const enumContactAction = {
  none: 0,
  organization: 1,
  facility: 2,
  eleFacility: 3,
  PoliticalParty: 4,
  Candidate: 5,
  CandidateAssociate: 6,
  CampaignFinance: 7,
  CampaignFinanceAssociate: 8,
  worker: 9,
  eleWorker: 10,
  Elector: 16,
  EventElector: 17,
  AssetCommunicationVendor: 21,
  helpdeskContactAction: 500,
  eventhelpdeskContactAction: 501
}
