<template>
  <detail
    :is-event="isEvent"
    :is-plain-text="false"
    :id="id"
    @save-successful="onSaveSuccessful"
    @label-select="onLabelSelect"
    ref="detail"
    :expand-form="expandForm"
    :notification-type="notificationType"
  >
    <template #formBar>
      <div>
        <k-button-back
          :back-route="isEvent ? 'eleReport' : 'report'"
          :back-query="{ tabindex: $route.query.tabindex }"
        >
          buttons.back
        </k-button-back>
        <k-button type="submit" variant="primary" class="mr-1" ref="btnSave">
          buttons.save
        </k-button>
      </div>
    </template>
    <template #formBarNav>
      <k-alert-msg ref="lblMsg" />
      <ReportNav
        ref="nav"
        :tab-index="navIndex"
        :is-plain-text="false"
        :is-event="isEvent"
        :is-notification="true"
      />
      <NotificationNav
        ref="notificationNav"
        :notification-type="notificationType"
        :click-tab="clickTab"
      />
    </template>
    <template #insertLabel>
      <InsertLabels ref="insertLabels" :system-type="systemType" />
    </template>
    <template #smsInsertLabel="{ form }">
      <div class="col-form-label" id="insertLabel">
        <img
          src="../../../../../assets/image/InsertLabel.png"
          v-b-tooltip="{
            placement: 'topright',
            trigger: 'hover',
            title: 'Insert Label',
            boundary: 'window',
            container: 'insertLabel',
          }"
          style="cursor: pointer"
          alt="insertLabel"
          v-b-modal.popcolumnconfig
        />
      </div>
      <k-popwindow
        id="popcolumnconfig"
        @ok="onPopSelected(form)"
        ok-title="Save"
        :before-close="onBeforeClose"
      >
        <InsertLabels
          ref="smsInsertLabels"
          :system-type="systemType"
          :notification-type="notificationType"
        />
      </k-popwindow>
    </template>
    <template #formGroup="{ form, plaintext }">
      <slot name="formGroup" :form="form" :plaintext="plaintext" />
    </template>
  </detail>
</template>
<script>
import { saveAlert } from "../../../../../utils/saveAlert";
import { beforeRouteLeaveMixin } from "../../../../../utils/mixins/mixin";
import detail from "./components/notificationDetail.vue";
import { repResult } from "../../../../../libs/global";
import ReportNav from "../../../components/report-nav.vue";
import { permissionCode } from "../../../../common-massage/mixins/permissionCode";
import storeage from "../../../../../utils/storeage";
import InsertLabels from "./components/insertLabels.vue";
import NotificationNav from "./components/notificationNav.vue";
import { notificationTypeEnum } from "./components/components/notificationTypeEnum";

export default {
  props: {
    expandForm: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  mixins: [beforeRouteLeaveMixin, permissionCode],
  components: { detail, ReportNav, InsertLabels, NotificationNav },
  data() {
    let isEvent = this.$route.meta.isEvent;
    return {
      isEvent: isEvent,
      id: this.$route.query.id ?? "00000000-0000-0000-0000-000000000000",
      electionId: isEvent ? storeage.getElectionId() : "",
      navIndex: 2,
      templateEdit: true,
      systemType: isEvent ? 4 : 2,
      notificationType:
        this.$route.query.notificationType ?? notificationTypeEnum.email,
    };
  },
  created() {},
  methods: {
    onSaveSuccessful(m) {
      this.isDoSave = true;
      let query = {
        ...this.$route.query,
        notificationType: this.notificationType,
        t: new Date().getTime(),
      };
      this.$router.push({
        name: this.$route.name,
        query: query,
        params: {
          message: m.message,
          status: m.data.status,
        },
      });
    },
    showAlert() {
      let status = saveAlert.savedStatus(this.$route);
      if (status === repResult.success) {
        this.$refs.lblMsg.message(status, saveAlert.savedMessage(this.$route));
      }
    },
    onLabelSelect() {
      this.$refs.detail.$refs.notificationDetailContent.$refs.messageEditor.insertHtml(
        this.$refs.insertLabels.selectedVal
      );
      this.$emit("label-select");
    },
    insertHtml(val) {
      this.$refs.detail.insertHtml(val);
    },
    clickTab(item, curTabindex) {
      if (!this.isDoSave && this.$refs.detail.isDataChanges()) {
        this.$confirm({
          title: this.$g("const.confirm.confirm"),
          content: this.$g("const.confirm.leaveComfirm"),
          cancelType: "secondary",
          okText: this.$g("const.confirm.leaveComfirmYes"),
          cancelText: this.$g("const.confirm.no"),
        })
          .then(() => {
            let query = {
              ...this.$route.query,
              notificationType: item.type,
              t: new Date().getTime(),
            };
            this.$router.push({
              name: this.$route.name,
              query: query,
            });
          })
          .catch(() => {
            this.$refs.notificationNav.tabindex = curTabindex;
          });
      } else {
        let query = {
          ...this.$route.query,
          notificationType: item.type,
          t: new Date().getTime(),
        };
        this.$router.push({
          name: this.$route.name,
          query: query,
        });
      }
    },
    beforeRouteLeave(to, from, next) {
      if (!this.isDoSave && this.$refs.detail.isDataChanges()) {
        this.$confirm({
          title: this.$g("const.confirm.confirm"),
          content: this.$g("const.confirm.leaveComfirm"),
          cancelType: "secondary",
          okText: this.$g("const.confirm.leaveComfirmYes"),
          cancelText: this.$g("const.confirm.no"),
        })
          .then(() => {
            next();
          })
          .catch(() => {
            //nav maybe in view or detialview
            this.$refs.detail.navToBack();
            this.navToBack();
            next(false);
          });
      } else {
        next();
      }
    },
    onPopSelected(form) {
      form.content = `${form.content}${this.$refs.smsInsertLabels.selectedVal}`;
    },
    onBeforeClose() {},
    // NotificationNavClickTab(item) {
    //   this.notificationType = item.type;
    //   this.$nextTick(() => {
    //     this.$refs.detail.initForm();
    //   });
    // },
  },
  mounted() {
    this.showAlert();
  },
};
</script>
