<template>
  <div>
    <k-form-group
      label-class="require-mark"
      label-suffix="title"
      label-for="title"
      label-align-md="right"
      label-cols-md="3"
      content-cols-lg="6"
      content-cols-md="7"
    >
      <KValidationProvider
        :rules="{
          required: true,
          max: 200,
        }"
        v-slot="{ errors }"
        slim
        name="title"
      >
        <k-alert
          :show="errors.length > 0"
          variant="warning"
        >
          {{ errors[0] }}
        </k-alert>
        <k-form-input
          v-model="form.subject"
          :plaintext="isPlainText"
        />
      </KValidationProvider>
    </k-form-group>
    <k-form-group
      label-suffix="activate"
      label-for="activate"
      label-align-md="right"
      label-cols-md="3"
      content-cols-lg="6"
      content-cols-md="7"
    >
      <KValidationProvider
        :rules="{}"
        v-slot="{ errors }"
        slim
        name="activate"
      >
        <k-alert
          :show="errors.length > 0"
          variant="warning"
        >
          {{ errors[0] }}
        </k-alert>
        <k-form-radio-group
          v-model="form.activate"
          :options="activateOptions"
          :plaintext="isPlainText"
          class="col-form-label d-block"
        />
      </KValidationProvider>
    </k-form-group>
    <k-form-group
      label-class="require-mark"
      label-suffix="notificationFrequency"
      label-for="notificationFrequency"
      label-align-md="right"
      label-cols-md="3"
      content-cols-lg="6"
      content-cols-md="7"
    >
      <KValidationProvider
        :rules="{
          required: true,
        }"
        v-slot="{ errors }"
        slim
        name="notificationFrequency"
      >
        <k-alert
          :show="errors.length > 0"
          variant="warning"
        >
          {{ errors[0] }}
        </k-alert>
        <k-form-select
          v-model="form.sendRule"
          :options="notificationFrequencyOptions"
          :plaintext="isPlainText"
          @input="notificationFrequencyChangeEvent"
        />
      </KValidationProvider>
    </k-form-group>
    <k-form-group
      v-if="form.sendRule == notificationFrequencyEnum.Daily"
      label-class="require-mark"
      label-suffix="dailyNotificationTime"
      label-for="dailyNotificationTime"
      label-align-md="right"
      label-cols-md="3"
      content-cols-lg="6"
      content-cols-md="7"
    >
      <KValidationProvider
        :rules="{
          required: true,
          validateTimeFormat: true,
        }"
        v-slot="{ errors }"
        slim
        name="title"
      >
        <k-alert
          :show="errors.length > 0"
          variant="warning"
        >
          {{ errors[0] }}
        </k-alert>
        <k-form-timepicker v-model="form.dailyTime" />
      </KValidationProvider>
    </k-form-group>
    <k-form-group
      v-if="form.sendRule == notificationFrequencyEnum.Weekly"
      label-class="require-mark"
      label-suffix="weeklyNotificationDayAndTime"
      label-for="weeklyNotificationDayAndTime"
      label-align-md="right"
      label-cols-md="3"
      content-cols-lg="6"
      content-cols-md="7"
    >
      <KValidationProvider
        :rules="{
          required: true,
          requiredValues: [form.weekMonth, form.dailyTime],
        }"
        v-slot="{ errors }"
        slim
        name="weeklyNotificationDayAndTime"
      >
        <k-alert
          :show="errors.length > 0"
          variant="warning"
        >
          {{ errors[0] }}
        </k-alert>
        <b-input-group>
          <template #prepend>
            <k-form-select
              :options="weekOptions"
              v-model="form.weekMonth"
            />
          </template>

          <template #append>
            <k-form-timepicker
              v-model="form.dailyTime"
              class="ml-2"
            />
          </template>
        </b-input-group>
      </KValidationProvider>
    </k-form-group>
    <k-form-group
      v-if="form.sendRule == notificationFrequencyEnum.Monthly"
      label-class="require-mark"
      label-suffix="monthlyNotificationDayAndTime"
      label-for="monthlyNotificationDayAndTime"
      label-align-md="right"
      label-cols-md="3"
      content-cols-lg="6"
      content-cols-md="7"
    >
      <KValidationProvider
        :rules="{
          required: true,
          requiredValues: {
            values: [form.weekMonth, form.dailyTime],
          },
        }"
        v-slot="{ errors }"
        slim
        name="monthlyNotificationDayAndTime"
      >
        <k-alert
          :show="errors.length > 0"
          variant="warning"
        >
          {{ errors[0] }}
        </k-alert>
        <b-input-group>
          <template #prepend>
            <k-form-select
              :options="dayOptions"
              v-model="form.weekMonth"
            />
          </template>
          <template #append>
            <k-form-timepicker
              v-model="form.dailyTime"
              class="ml-2"
            />
          </template>
        </b-input-group>
      </KValidationProvider>
    </k-form-group>
    <k-form-group
      v-if="form.sendRule == notificationFrequencyEnum.Yearly"
      label-class="require-mark"
      label-suffix="yearlyNotificationDayAndTime"
      label-for="yearlyNotificationDayAndTime"
      label-align-md="right"
      label-cols-md="3"
      content-cols-lg="6"
      content-cols-md="7"
    >
      <KValidationProvider
        :rules="{
          required: true,
          requiredValues: {
            values: [form.months, form.weekMonth, form.dailyTime],
          },
        }"
        v-slot="{ errors }"
        slim
        name="yearlyNotificationDayAndTime"
      >
        <k-alert
          :show="errors.length > 0"
          variant="warning"
        >
          {{ errors[0] }}
        </k-alert>
        <b-input-group>
          <template #prepend>
            <k-form-select
              name="year"
              :options="monthOptions"
              v-model="form.months"
            />
          </template>
          <k-form-select
            :options="dayOptions"
            v-model="form.weekMonth"
            class="ml-2"
          />
          <template #append>
            <k-form-timepicker
              v-model="form.dailyTime"
              class="ml-2"
            />
          </template>
        </b-input-group>
      </KValidationProvider>
    </k-form-group>
    <k-form-group
      label-class="require-mark"
      label-suffix="sendTo"
      label-for="sendTo"
      label-align-md="right"
      label-cols-md="3"
      content-cols-lg="6"
      content-cols-md="7"
    >
      <KValidationProvider
        :rules="{
          required: true,
        }"
        v-slot="{ errors }"
        slim
        name="sendTo"
      >
        <k-alert
          :show="errors.length > 0"
          variant="warning"
        >
          {{ errors[0] }}
        </k-alert>
        <k-multiselect
          :options="sendToOptions"
          v-model="form.sendTo"
          :multiple="false"
        />
      </KValidationProvider>
    </k-form-group>
    <k-form-group
      label-suffix="officerRecipients"
      label-for="officerRecipients"
      label-align-md="right"
      label-cols-md="3"
      content-cols-lg="6"
      content-cols-md="7"
    >
      <KValidationProvider
        :rules="{}"
        v-slot="{ errors }"
        slim
        name="officerRecipients"
      >
        <k-alert
          :show="errors.length > 0"
          variant="warning"
        >
          {{ errors[0] }}
        </k-alert>
        <OfficerRecipients
          :form="form"
          :plaintext="isPlainText"
        />
      </KValidationProvider>
    </k-form-group>
  </div>
</template>

<script>
import {
  getSendToTypeEnum,
  getSendruleEnum,
  getWeekDayEnum,
  getMonthDayEnum,
  getMonthEnum,
} from "../api/notification";
import { kExtend } from "../../../../../../k-components/validate/methods";
import { g } from "../../../../../../locale/lang-val";
import OfficerRecipients from "./components/officerRecipients.vue";

///Verify multiple values as required
kExtend("requiredValues", {
  params: ["values"],
  validate(value, { values }){
    let length = values.length;
    for (let index = 0; index < length; index ++) {
      let element = values[index];
      if(!element || element == "Invalid date"){
        return g("validate.required");
      }
    }
    return true;
  },
});
export default {
  components: { OfficerRecipients },
  props: {
    form: {
      type: Object,
      default(){
        return null;
      },
    },
    isPlainText: {
      type: Boolean,
      default(){
        return false;
      },
    },
    isEvent: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    electionId: {
      type: String,
      default: () => {
        return null;
      },
    },
  },
  data(){
    let notificationFrequencyEnum = {
      Hourly: 16,
      Daily: 2,
      Weekly: 4,
      Monthly: 8,
      Yearly: 32,
    };
    return {
      activateOptions: [
        { text: "Yes", value: 2 },
        { text: "No", value: 1 },
      ],
      notificationFrequencyEnum: notificationFrequencyEnum,
      notificationFrequencyOptions: [],
      sendToOptions: [],
      weekOptions: [],
      dayOptions: [],
      monthOptions: [],
    };
  },
  created(){
    this.initSendToOptions();
    this.initNotificationFrequencyOptions();
  },
  methods: {
    initSendToOptions(){
      getSendToTypeEnum().then((result) => {
        this.sendToOptions = [{ text: "", value: null }, ...result.data];
      });
    },
    initNotificationFrequencyOptions(){
      let query = {};
      if(this.isEvent){
        query = { ...query, electionId: this.electionId };
      }
      getSendruleEnum(query).then((result) => {
        this.notificationFrequencyOptions = result.data;
        this.notificationFrequencyOptions[0].value = null;
      });
    },
    initWeekOptions(){
      getWeekDayEnum().then((result) => {
        this.weekOptions = result.data;
        this.weekOptions[0].value = null;
      });
    },
    initDayOptions(){
      getMonthDayEnum().then((result) => {
        this.dayOptions = result.data;
        this.dayOptions[0].value = null;
      });
    },
    initMonthOptions(){
      getMonthEnum().then((result) => {
        this.monthOptions = result.data;
        this.monthOptions[0].value = null;
      });
    },
    notificationFrequencyChangeEvent(v){
      switch (v) {
        case this.notificationFrequencyEnum.Weekly:
          this.initWeekOptions();
          break;
        case this.notificationFrequencyEnum.Monthly:
          this.initDayOptions();
          break;
        case this.notificationFrequencyEnum.Yearly:
          this.initDayOptions();
          this.initMonthOptions();
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style></style>
