<template>
  <div>
    <b-tabs v-model="tabindex">
      <b-tab
        v-for="item in items"
        :key="item.value"
        @click="clickNav(item)"
      >
        <template #title>
          {{ item.text }}
          <span
            class="text-red"
            v-if="needTotalCount"
          >({{ item.total }})</span>
        </template>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
export default {
  data(){
    return {
      tabindex: 0,
    };
  },
  methods: {
    clickNav(item){
      // this.tabindex = item.orderIndex;
      this.refresh(item);
    },
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    refresh: {
      type: Function,
      required: true,
    },
    needTotalCount: { type: Boolean, default: false },
  },
};
</script>

