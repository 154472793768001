// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../fe-workerportal/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../fe-workerportal/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./Lato/Lato-Thin.ttf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./Lato/Lato-ThinItalic.ttf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./Lato/Lato-Light.ttf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./Lato/Lato-LightItalic.ttf");
var ___CSS_LOADER_URL_IMPORT_4___ = require("./Lato/Lato-Regular.ttf");
var ___CSS_LOADER_URL_IMPORT_5___ = require("./Lato/Lato-Italic.ttf");
var ___CSS_LOADER_URL_IMPORT_6___ = require("./Lato/Lato-Bold.ttf");
var ___CSS_LOADER_URL_IMPORT_7___ = require("./Lato/Lato-BoldItalic.ttf");
var ___CSS_LOADER_URL_IMPORT_8___ = require("./Lato/Lato-Black.ttf");
var ___CSS_LOADER_URL_IMPORT_9___ = require("./Lato/Lato-BlackItalic.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
// Module
exports.push([module.id, "@font-face{font-family:Lato;font-weight:100;font-style:normal;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}@font-face{font-family:Lato;font-weight:100;font-style:italic;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}@font-face{font-family:Lato;font-weight:300;font-style:normal;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}@font-face{font-family:Lato;font-weight:300;font-style:italic;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ")}@font-face{font-family:Lato;font-weight:400;font-style:normal;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ")}@font-face{font-family:Lato;font-weight:400;font-style:italic;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ")}@font-face{font-family:Lato;font-weight:700;font-style:normal;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ")}@font-face{font-family:Lato;font-weight:700;font-style:italic;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ")}@font-face{font-family:Lato;font-weight:900;font-style:normal;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_8___ + ")}@font-face{font-family:Lato;font-weight:900;font-style:italic;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_9___ + ")}", ""]);
// Exports
module.exports = exports;
