import store from "@/store";
import { checkRolesChanged } from "@/api/permission";
import { getUserPermissionMenuList } from "@/api/user";
import { storeage, router, operatePermission } from "fe-infrastractures";

export const filterTabs = (pCodes, items) => {
  if (Array.isArray(items) && items.length) {
    return items.filter(item => {
      if (item.permissionCode) {
        let itemCode = Array.isArray(item.permissionCode)
          ? item.permissionCode
          : [item.permissionCode];
        return pCodes.some(code => itemCode.includes(code));
      }
      return true;
    });
  }
};
export const handleTabs = (items, vm) => {
  if (vm && vm.$route.meta.permissionCode?.indexOf("Add") > - 1) {
    let tempAry = items.filter(
      item => item.permissionCode.indexOf("BasicInfo") > - 1,
    );
    if (tempAry && tempAry.length) {
      return [
        {
          title: tempAry[0].title,
          index: tempAry[0].index,
          route: vm.$route.name,
          permissionCode: tempAry[0].permissionCode,
        },
      ];
    } else {
      return [];
    }
  } else {
    let tempList = operatePermission.getPermission();
    let perList = tempList ? tempList.split(",") : [];
    return filterTabs(perList, items);
  }
};

export const getTabFirstPathName = items => {
  let tabItems = handleTabs(items);
  return tabItems && tabItems.length ? tabItems[0].route : "";
};
export const getParentAccess = items => {
  if (items && items.length) {
    let item = items[0];
    let permissionCode = Array.isArray(item.permissionCode)
      ? item.permissionCode[0]
      : item.permissionCode;
    let parentCode = "";
    if (permissionCode) {
      let parentAry = permissionCode.split("-");
      parentAry.pop();
      parentCode = parentAry.join("-");
      return operatePermission.has(parentCode);
    } else {
      return true;
    }
  } else {
    return false;
  }
};

export const getTabIndex = (items, permissionCode, vm) => {
  if (items && items.length) {
    let pCode = permissionCode ? permissionCode : vm.$route.meta.permissionCode;
    let metaAry = Array.isArray(pCode) ? pCode : [pCode];
    return items.findIndex(item => {
      if (Array.isArray(item.permissionCode)) {
        metaAry.sort();
        return item.permissionCode.sort().toString() === metaAry.toString();
      }
      return metaAry.includes(item.permissionCode);
    });
  }
};
export const getTabIndexByRouteName = (items, vm) => {
  if (items && items.length) {
    return items.findIndex(item => item.route == vm.$route.name);
  }
};

export const getTabsAndIndex = (code, vm) => {
  const tabItems = store.getters.pageTabs[code];
  const items = handleTabs(tabItems, vm);
  const tabindex = getTabIndex(items, null, vm);
  return { items, tabindex };
};

export const getRouterName = tabItems => {
  if (!tabItems) return false;
  let route = getTabFirstPathName(tabItems);
  return route ? true : false;
};

let timeId = null;
export const checkPermissionChange = async () => {
  if (!store.getters.tenantkey) {
    timeId = null;
    return;
  }
  let res = await checkRolesChanged({
    tenantKey: store.getters.tenantkey,
    roleTimeStamp: store.getters.roletimestamp,
    userkey: storeage.getUserInfo()?.userId,
  }).catch(e => 3);

  if (res.data == 1) {
    router.push({ name: "login" });
  }
  timeId = setTimeout(() => {
    checkPermissionChange();
  }, 30000);
};
export const checkPermission = mRouter => {
  return new Promise((resolve, reject) => {
    checkPermissionChange();
    resolve();
  });
};

export const getDefaultFirstNav = (navs = []) => {
  if (navs?.length) {
    let firstNav = navs[0];
    if (firstNav.childMenu) {
      return firstNav.childMenu[0]?.route;
    } else {
      return firstNav.menu?.route;
    }
  } else {
    return "";
  }
};

export const getDefaultLocationFirstNav = async () => {
  let navs = storeage.getLocationAllNavs();
  if (navs && navs.length) {
    let firstNav = navs[0];
    if (firstNav.childMenu) {
      return firstNav.childMenu[0]?.route;
    } else {
      return firstNav.menu?.route;
    }
  } else {
    let result = await getUserPermissionMenuList({
      Tenantkey: storeage.getUserInfo()?.tenantkey,
      Types: 1024,
      UserId: storeage.getUserInfo()?.userId,
      SysID: 32,
    }).catch(() => {
      return "";
    });
    if (result?.data && result.data.length) {
      let firstNav = result.data[0];
      if (firstNav.childMenu) {
        return firstNav.childMenu[0]?.route;
      } else {
        return firstNav.menu?.route;
      }
    } else {
      return "";
    }
  }
};
