<template>
  <k-form-group
    label-for="Content"
    label-align-md="right"
    label-cols-md="3"
    content-cols-lg="6"
    content-cols-md="7"
  >
    <KValidationProvider
      :rules="{
        required: true,
        acceptFileType: accept,
        maxFileSize: size
      }"
      v-slot="{ errors }"
      slim
      :name="$g('file')"
    >
      <k-alert :show="errors.length > 0" variant="warning">
        {{ errors[0] }}
      </k-alert>
      <HuiAudio
        v-model="form.file"
        :is-plain-text="isPlainText"
        :name="form.fileName"
      />
      <div class="mt-1" v-show="!isPlainText">
        {{ $g("size") }}: {{ size / 1024 / 1024 }}MB
        <br />
        {{ $g("extensions") }}: {{ accept }}
      </div>
    </KValidationProvider>
  </k-form-group>
</template>

<script>
import HuiAudio from "../../../../../components/media/audio.vue";
export default {
  components: {
    HuiAudio
  },
  props: {
    form: {
      type: Object,
      default: () => {
        return null;
      }
    },
    isPlainText: {
      type: Boolean,
      default: () => {
        return true;
      }
    },
    size: {
      type: Number,
      default: () => {
        return 0;
      }
    },
    accept: {
      type: String,
      default: () => {
        return "";
      }
    }
  },
  data() {
    return {};
  }
};
</script>