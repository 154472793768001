<template>
  <span>
    <span v-if="plaintext" class="col-form-label d-block">
      {{ value }}
    </span>
    <b-form-input
      v-else-if="autocompleteStr"
      v-bind="props"
      :placeholder="gPlaceholder"
      v-on="$listeners"
      @change="changeHandler"
      @blur="changeHandler"
      v-model="kvalue"
      :aria-label="ariaLabel"
      :autocomplete="autocompleteStr"
    />
    <b-form-input
      v-else
      v-bind="props"
      :placeholder="gPlaceholder"
      v-on="$listeners"
      @change="changeHandler"
      @blur="changeHandler"
      v-model="kvalue"
      :aria-label="ariaLabel"
    />
  </span>
</template>
<script>
import { BFormInput } from "bootstrap-vue";
import { g } from "../../locale/lang-val";
import storeage from "../../utils/storeage";

const objProps = Object.assign({}, BFormInput.options.props);

export default {
  components: { BFormInput },
  props: {
    ...objProps,
    ariaLabel: {
      type: String,
      default() {
        return "input";
      },
    },
    id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      kvalue: this.format(this.value),
      autocompleteStr: "",
    };
  },
  mounted() {
    this.changeHandler();
    if (storeage.getSystemGeneralInfo()?.allowBrowserAutoFill == 2) {
        this.autocompleteStr= "";
      } else {
        if (
          !this.id ||
          this.id == "name" ||
          this.id == "id" ||
          this.id == "input-name" ||
          this.id == "emailAddress" ||
          this.id == "phoneNumber" ||
          this.id == "phoneNumber2"
        ) {
          this.autocompleteStr= "off";
        }
        this.autocompleteStr= "close";
      }
  },
  methods: {
    format(value) {
      let reg = /^\d{10}$/;
      if (reg.exec(value)) {
        var part1 = value.substring(0, 3);
        var part2 = value.substring(3, 6);
        var part3 = value.substring(6, 10);
        const v = `(${part1})${part2}-${part3}`;
        this.$emit("input", v);
        this.$emit("change");
        return;
      }
      return value;
    },
    changeHandler() {
      this.kvalue = this.format(this.kvalue);
    },
  },
  computed: {
    props() {
      let { placeholder, ...params } = this.$props;
      return params;
    },
    gPlaceholder() {
      return g(this.placeholder);
    },
  },
  watch: {
    value(val) {
      this.kvalue = this.format(val);
    },
  },
};
</script>
