import store from "../store";
import { hasPermission } from "@/directive/permission";
import { PW_Worker_Portal_Code } from "@/router/permissionCode";
import {
  getSysLanguageRes,
  getSysPermission,
  getSysLanguageList,
  getSysGeneralInfo,
} from "@/api/app";
import { getDefaultLocationFirstNav } from "@/utils/permission/index";
import { doubleClickMenuKey } from "@/utils/storeageKey";
import {
  logout,
  i18n,
  storeage,
  g,
  loadValidateRulesLanguageRes,
  router, getToken,
  saveMoudleLog,
  globalEnv,
} from "fe-infrastractures";

/**
 * 验证是否需要权限验证
 * @param {*} to
 * @param {*} from
 */
const validateAuth = (to, from) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    if (to.meta && to.meta.anonymous) {
      resolve();
    } else {
      reject();
    }
  });
};

/**
 * 验证登录权限。系统及用户参数
 * @param {*} to
 * @param {*} from
 */
let isRefreshNav = true;
const validateToken = (to, from) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    if (getToken()?.token) {
      let navs = storeage.getLocationAllNavs();
      if (isRefreshNav || JSON.stringify(navs) == "{}") {
        await store.dispatch("nav/getLocationNav", storeage.getUserInfo());
        isRefreshNav = false;
        // getSysPermission();
      }
      try {
        // if (store.getters.systems.length === 0) {
        //     await store.dispatch('user/getSystems')
        // }
        resolve();
      } catch (error) {
        logout();
        reject({ name: "login" });
      }
    } else {
      logout();
      let regex = /login$/g;
      if (regex.test(to.name)) {
        reject();
      } else {
        reject({ name: "login" });
      }
    }
  });
};

const refreshLocationNav = to => {
  sessionStorage.removeItem(doubleClickMenuKey);
  return new Promise(async resolve => {
    if (to.name === "home") {
      let routerName = await getDefaultLocationFirstNav();
      if (routerName === "home") {
        resolve();
      } else {
        if (routerName) {
          resolve({
            name: routerName,
          });
        } else {
          resolve();
        }
      }
    } else {
      resolve();
    }
  });
};

const beforeEachFuncIsGetDefLanguage = (languageId, languageList) => {
  let isGetDefLanguage;
  if (languageId) {
    let hasLanguages = languageList.filter(
      item => item.value === languageId,
    );
    if (hasLanguages && hasLanguages.length > 0) {
      isGetDefLanguage = false;
    } else {
      isGetDefLanguage = true;
    }
  } else {
    isGetDefLanguage = true;
  }
  if (isGetDefLanguage) {
    let defLanguages = languageList.filter(item => item.isDefault);
    if (defLanguages && defLanguages.length > 0) {
      languageId = defLanguages[0].value;
      storeage.setLanguageId(languageId);
    }
  }
  return languageId;
};

const doValidate = (to, from, next) => {
  // console.log("to,from",to,from);
  if (to.meta?.pageTitle != null) {
    document.title = g(`pageTitle.${to.meta?.pageTitle}`, to.path);
  }
  validateAuth(to, from)
    .then(next)
    .catch(() => {
      validateToken(to, from)
        .then(async () => {
          refreshLocationNav(to).then(next);
        })
        .catch(next);
    });
};

function getRedirectName() {
  let redirectName = "";
  if (hasPermission(PW_Worker_Portal_Code.TrainingOrHistory.Worker)) {
    redirectName = "workHistory";
  } else if (hasPermission(PW_Worker_Portal_Code.TrainingOrHistory.View)) {
    redirectName = "trainingHistory";
  }
  return redirectName;
}

function getTrainingClassRedirectName() {
  let redirectName = "";
  if (hasPermission(PW_Worker_Portal_Code.Training.View)) {
    redirectName = "trainingClass";
  } else if (hasPermission(PW_Worker_Portal_Code.TrainingMaterials.List)) {
    redirectName = "trainingMaterials";
  }
  return redirectName;
}

let rollbackClick = false;
window.removeEventListener("popstate", browserRollback)
window.addEventListener("popstate", browserRollback)
function browserRollback() { rollbackClick = true; }

/**
 * Vue-Router前置路由守卫
 */
router.beforeEach(async (to, from, next) => {
  if (rollbackClick) {
    rollbackClick = false;
    if ((to.name == "login" || to.name == "validate-reset-password") && from.name == "home") {
      next(false);
      return;
    }
  }
  saveMoudleLog(to, from);
  if (to.name == "workTrainingHistory" || to.path == "/worker-portal/workTrainingHistory") {
    next({
      name: getRedirectName(),
    });
  }
  if (to.name == "training" || to.path == "/worker-portal/training") {
    next({
      name: getTrainingClassRedirectName(),
    });
  }
  if(!from.name){
    getSysGeneralInfo().then(resp => {
      if (resp.data) {
        storeage.setSystemGeneralInfo(resp.data);
      }
    });
  }

  if (!from.name && !globalEnv.VUE_APP_USE_LOCAL_LANGUAGE_RES) {
    getSysLanguageList()
      .then(resp => {
        let languageList = resp.data;
        let languageId = storeage.getLanguageId();
        languageId = beforeEachFuncIsGetDefLanguage(languageId, languageList);
        return languageId;
      })
      .then(languageId => {
        getSysLanguageRes().then(resp => {
          if (resp.data) {
            i18n.setLocaleMessage(languageId, Object.assign(resp.data));
          }
          i18n.locale = languageId;
          loadValidateRulesLanguageRes();
          doValidate(to, from, next);
        });
      });
  } else {
    doValidate(to, from, next);
  }
});

/**
 * Vue-Router后置路由守卫
 */
router.afterEach(async () => {
  return null;
});

