<template>
  <div class="content">
    <div class="center-bar">
      <div class="nav-btn-group">
        <k-button-group>
          <k-button-back
            :back-route="isEvent ? 'eleReport' : 'report'"
            :back-query="{ tabindex: $route.query.tabindex }"
          >
            buttons.back
          </k-button-back>
        </k-button-group>
        <KWikiHelp :props-wiki-help-code="permissionCode.Report.Permissions" />
      </div>
      <div class="content-main">
        <k-alert-msg ref="lblMsg" />
        <div class="content-navTab">
          <div class="nav-tools">
            <b-icon-add
              v-permission="permissionCode.Report.PermissionsAdd"
              @click="onAddClick"
            />
            <b-icon-delete
              v-permission="permissionCode.Report.PermissionsDelete"
              @click="onDeleteClick"
            />
          </div>
          <div>
            <Nav
              ref="nav"
              :tab-index="navIndex"
              :is-event="isEvent"
            />
          </div>
        </div>
        <div class="content-party">
          <div class="pop-flex mb-3 mt-3">
            <k-form-group label-suffix="fristName" />
            <k-form-input
              name="name"
              v-model="form.firstName"
              type="text"
              class="mr-3 wid"
            />
            <k-form-group label-suffix="lastName" />
            <k-form-input
              name="name"
              v-model="form.lastName"
              type="text"
              class="mr-3 wid"
            />
            <k-form-group label-suffix="group" />
            <k-form-select
              id="status"
              name="status"
              :options="groupList"
              v-model="form.authorityRoleId"
              class="mr-3 wid"
            />
            <k-button @click="onSearchClick">
              search
            </k-button>
          </div>
          <konn-grid
            ref="selectableTable"
            :data-source="dataSource"
            :get-condition="getCondition"
            @total-changed="onTotalChanged"
            :grid-sort-desc="sortDesc"
            grid-sort-by="orderIndex"
            sort-icon-left
            :fields="fields"
            :has-action="false"
          >
            <template #cell(canEditReport)="row">
              <k-form-checkbox
                v-model="row.item.canEditReport"
                @change="changeEditTemplate(row.item)"
                :plaintext="!$has(permissionCode.Report.PermissionsEdit)"
              />
            </template>
            <template #cell(canDeleteReport)="row">
              <k-form-checkbox
                v-model="row.item.canDeleteReport"
                @change="changeDeleteTemplate(row.item)"
                :plaintext="!$has(permissionCode.Report.PermissionsEdit)"
              />
            </template>
          </konn-grid>
        </div>
        <UserAdd
          @positionList="getUserlsit"
          :group-list="groupList"
          :is-report="true"
          :report-id="reportId"
          @show-message="showmessage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Nav from "../../components/report-nav.vue";
import UserAdd from "../../components/select-user.vue";
import {
  getReportPermissionsList,
  getGroupsList,
  deleteReportPermissionss,
  changeReportEditPermission,
  changeReportDeletePermission,
} from "../../api/report";

export default {
  components: {
    Nav,
    UserAdd,
  },
  data(){
    let isEvent = this.$route.meta.isEvent ?? false;
    return {
      permissionCode: this.$route.meta.permissionCode,
      navIndex: 1,
      reportId: this.$route.query.id,
      isEvent: isEvent,
      form: {
        firstName: "",
        lastName: "",
        authorityRoles: "",
      },
      groupList: [],
      sortDesc: false,
      totalCount: 0,
      fields: [
        { key: "firstName", label: "firstName" },
        { key: "lastName", label: "lastName" },
        { key: "email", label: "email" },
        { key: "authorityRoles", label: "userGroups" },
        { key: "canEditReport", label: "editReport" },
        { key: "canDeleteReport", label: "deleteReport" },
      ],
    };
  },
  computed: {
    dataSource: function (){
      return getReportPermissionsList;
    },
  },
  methods: {
    showmessage(msg){
      this.$refs.selectableTable.refresh();
      this.$refs.lblMsg.message(msg.data.status, msg.message);
    },
    getUserlsit(list){
      console.log(list, "userList");
    },
    changeEditTemplate(item){
      console.log(item);
      changeReportEditPermission({
        id: item.id,
        allow: item.canEditReport,
      }).then((m) => {
        this.$refs.lblMsg.message(m.data.status, m.message);
      });
    },
    changeDeleteTemplate(item){
      changeReportDeletePermission({
        id: item.id,
        allow: item.canDeleteReport,
      }).then((m) => {
        this.$refs.lblMsg.message(m.data.status, m.message);
      });
    },
    getGroupsList(){
      getGroupsList().then((res) => {
        this.groupList = res.data;
      });
    },
    onAddClick(){
      this.$bvModal.show("model-user");
    },
    onDeleteClick(){
      let pmOpt = this.$refs.selectableTable.confirmOpt(
          this.$g("const.confirm.delete"),
      );
      if(pmOpt == null){
        return;
      }
      pmOpt.then(() => {
        let delIds = this.$refs.selectableTable.getSelectedIds();
        deleteReportPermissionss(delIds).then((m) => {
          this.$refs.selectableTable.refresh();
          this.$refs.lblMsg.message(m.data.status, m.message);
        });
      });
    },
    onTotalChanged(total){
      this.totalCount = total;
    },
    onSearchClick(){
      this.$refs.selectableTable.pageChange(1);
    },
    getCondition(){
      return {
        ...this.form,
        reportId: this.$route.query.id,
      };
    },
    onColumnChanged(){
      this.$refs.selectableTable.refresh();
    },
  },
  mounted(){
    this.getGroupsList();
  },
};
</script>

<style scoped>
.table-actions {
  margin-left: 10px;
}

.wid {
  width: 15%;
}
</style>
