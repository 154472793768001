const routes = [
    {
        name: 'log-out',
        path: '/',
        meta: { title: 'log-out', requiresAuth: false },
        component: () => import('@/views/const/space'),
        route: true,
    },

]

export default [
    {
        path: '/log-out',
        route: false,
        component: () => import('@/layout/brief'),
        children: [...routes],
    },
]