<template>
  <div class="content-page">
    <div class="content">
      <div class="right-bar" v-show="seen">
        <b-icon icon="x" @click="onToggleSearch" />
        <search-area
          ref="searchArea"
          :type-name="typeName"
          :search-click="search"
          :clear-click="clear"
          :get-paras="getCondition"
          :set-paras="setParas"
          :fields="fields"
          :show-dynamic="false"
          :show-template="showSearchAreaTemplate"
        >
          <k-form-group label-suffix="search.name">
            <k-form-input v-model="queryParams.title" />
          </k-form-group>
          <k-form-group label-suffix="search.templateLevel">
            <k-form-select
              :options="templateLevelOptions"
              v-model="queryParams.reportTemplateLevel"
            />
          </k-form-group>
        </search-area>
      </div>
      <div class="center-bar">
        <div class="nav-btn-group">
          <k-button-group>
            <k-button-back
              :back-route="isEvent ? 'eleReport' : 'report'"
              :back-query="{ tabindex: $route.query.tabindex }"
            >
              buttons.back
            </k-button-back>
          </k-button-group>
          <b-icon-search-area
            @click="onToggleSearch"
            :class="{ active: isActive }"
          />
          <KWikiHelp
            :props-wiki-help-code="permissionCode.Report.TemplateBasicInfo"
          />
        </div>
        <div class="content-main">
          <k-alert-msg ref="lblMsg" />
          <div class="bold p-3" style="font-size: 1.2rem">
            {{ $g("pleaseSelectTemplate") }}:
          </div>
          <div>
            <div class="content-party p-3">
              <konn-grid
                ref="selectableTable"
                :has-check-box="false"
                :has-action="false"
                :get-condition="getCondition"
                :data-source="dataSource"
                :set-paras="setParas"
                :sticky-header="true"
                class="stickyTable"
                :fields="fields"
                :is-original-fields="true"
                :has-pager="false"
                :auto-bind="false"
              >
                <template #cell(title)="row">
                  <div class="p-2 mb-3">
                    <div
                      v-if="!row.item.isSystem"
                      style="width: 5%; height: 100%; display: inline-block"
                    ></div>
                    <div style="height: 100%; display: inline-block">
                      <router-link
                        :to="{
                          name: isEvent ? 'eleReport-add' : 'report-add',
                          query: {
                            reportTemplateId: row.item.id,
                            categoryId: $route.query.categoryId,
                            tabindex: $route.query.tabindex,
                          },
                        }"
                      >
                        {{ row.item.tempDisplayName }}
                      </router-link>
                      <div class="mt-2">
                        {{ row.item.description }}
                      </div>
                    </div>
                  </div>
                </template>
              </konn-grid>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getSelectReportTemplatePage,
  getTemplateLevelOptions,
} from "../../api/report";

export default {
  data() {
    return {
      permissionCode: this.$route.meta.permissionCode,
      fields: [
        {
          key: "title",
          label: "",
          sortable: false,
          width: "100%",
        },
      ],
      isEvent: this.$route.meta.isEvent ?? false,
      showThisSearchArea: true,
      typeName: "ReportSelectTemplate",
      showSearchAreaTemplate: true,
      seen: true,
      isActive: true,
      queryParams: {
        title: "",
        reportTemplateLevel: 0,
      },
      templateLevelOptions: [],
    };
  },
  computed: {
    dataSource() {
      return getSelectReportTemplatePage;
    },
  },
  methods: {
    setParas(paras) {
      this.queryParams.title = paras.title;
      this.queryParams.reportTemplateLevel = paras.reportTemplateLevel;
      this.$refs.searchArea.setDynamicConditions(paras.dynamicConditions);
    },
    getCondition() {
      return {
        isBau: !this.isEvent,
        title: this.queryParams.title,
        reportTemplateLevel: this.queryParams.reportTemplateLevel,
        Limit: 999,
        dynamicConditions: this.$refs.searchArea.getDynamicConditions(),
      };
    },
    search() {
      this.$refs.selectableTable.refresh();
    },
    clear() {
      this.queryParams.title = "";
      this.queryParams.reportTemplateLevel = 0;
      this.search();
    },

    onToggleSearch() {
      this.seen = !this.seen;
    },
  },
  mounted() {
    getTemplateLevelOptions().then((resp) => {
      this.templateLevelOptions = resp.data;
    });
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .stickyTable {
    thead {
      display: none;
    }

    tr {
      background-color: initial !important;

      td {
        border: 1px solid #dee2e6;
      }
    }
  }
}
</style>
