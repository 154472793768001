<template>
  <div class="layoutContent">
    <div class="contentParty">
      <div
        class="
          d-flex
          align-items-center
          justify-content-md-start
          mb-2
          flex-md-row-reverse
        "
        style="border-bottom: 1px solid #e7eaee; padding-bottom: 20px"
      >
        <k-form-checkbox
          switch
          v-model="showUnread"
          size="lg"
          @change="showUnreadChange"
          class="messageSwitch"
        />
        <div style="padding-top: 0.3125rem" class="mr-2">
          {{ $g("onlyShowUnread") }}
        </div>
      </div>
      <konn-grid
        ref="selectableTable"
        :data-source="dataSource"
        :get-condition="getCondition"
        :class="{ 'table-show-search-area': true }"
        :grid-dort-desc="sortDesc"
        sort-icon-left
        :fields="fields"
        :has-action="false"
        :has-check-box="false"
        class="messageTable cardBody"
        :row-class="rowClass"
        :page-class="pageClass"
        :has-pager="hasPager"
        @total-changed="totalChanged"
      >
        <template #cell(subject)="row">
          <div class="messageList">
            <div class="titleSubject">
              <div class="electionTitle">
                {{ row.item.electionTitle }}
              </div>
              <a
                class="subject"
                href="javascript:void(0)"
                @click="subjectClick(row.item)"
              >
                {{ row.item.subject }}
              </a>
            </div>
            <div class="sendTimeTypeRead">
              <div class="sendTimeType">
                <div class="sendTime">
                  {{ row.item.sendTime }}
                </div>
                <div class="messageType">
                  {{ row.item.messageType }}
                </div>
              </div>
              <div class="readIcon">
                <b-icon
                  :icon="row.item.isRead ? 'envelope' : 'envelope-open'"
                  :class="row.item.isRead ? 'read' : 'noRead'"
                  class="mr-3"
                  @click="onSetMessage(row.item)"
                  v-b-tooltip="{
                    placement: 'bottomleft',
                    trigger: 'hover',
                    title: row.item.isRead
                      ? $g('markAsUnRead')
                      : $g('markAsRead'),
                    boundary: 'window',
                  }"
                />
                <b-icon-delete
                  class="deleteIcon"
                  @click="deleteMessageClick(row.item)"
                />
              </div>
            </div>
          </div>
        </template>
      </konn-grid>
    </div>
    <pop-window
      id="messagesDetail"
      :check-selected="false"
      :title="$g('popwindow.message')"
      :hide-ok="true"
      :cancel-title="$g('popwindow.close')"
    >
      <messagesPopWindowDetail :detail-id="detailId" />
    </pop-window>
  </div>
</template>

<script>
import { deleteMessage, messagePage, messageSet } from "../api/notification";
import { repResult } from "../../../../src/libs/global";
import storeage from "../../../../src/utils/storeage";
import Moment from "moment-timezone";
import messagesPopWindowDetail from "./notification-detail";
import PopWindow from "../../../k-components/modal/popWindow";
import KonnGrid from "../../../business-components/konn-grid";

export default {
  components: {
    KonnGrid,
    PopWindow,
    messagesPopWindowDetail,
  },
  data() {
    return {
      pageClass: [],
      showUnread: true,
      items: [],
      sortDesc: true,
      fields: [{ key: "subject", table: "subject" }],
      noDataDoc: "",
      timeZone: storeage.getTimeZone(),
      dateFormat: storeage.getDateFormat(),
      hasPager: false,
      detailId: "",
    };
  },
  computed: {
    dataSource() {
      return messagePage;
    },
  },
  methods: {
    deleteMessageClick(item) {
      this.$bvModal
        .msgBoxConfirm(this.$g("const.confirm.delete"), {
          title: this.$g("const.confirm.confirm"),
          cancelTitle: this.$g("const.confirm.yes"),
          okTitle: this.$g("const.confirm.no"),
          cancelVariant: "primary",
          okVariant: "secondary",
          noCloseOnBackdrop: true,
        })
        .then((result) => {
          if (!result) {
            deleteMessage({ ids: [item.id] }).then((result) => {
              if (result.data.status == repResult.success) {
                this.$refs.selectableTable.refresh();
                if (!item.isRead) {
                  //unRead
                  this.$emit("refreshMessageCount");
                }
              }
            });
          }
        });
    },
    setMessageReadStatus(item, showUnread) {
      messageSet({
        id: item.id,
        ShowUnread: showUnread,
      }).then((result) => {
        if (result.data.status == repResult.success) {
          this.$refs.selectableTable.refresh();
          this.$emit("refreshMessageCount");
        }
      });
    },
    subjectClick(item) {
      this.detailId = item.id;
      this.$bvModal.show("messagesDetail");

      if (!item.isRead) {
        this.setMessageReadStatus(item, true);
      }
    },
    totalChanged(total) {
      if (total > 0) {
        this.hasPager = true;
      } else if (!this.showUnread) {
        this.hasPager = false;
      }
    },
    getCondition(){
      return {
        showUnread: this.showUnread,
      };
    },
    showUnreadChange() {
      this.$refs.selectableTable.refreshSetPage1();
    },
    onSetMessage(item) {
      this.setMessageReadStatus(item, !item.isRead);
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (!item.isRead) return "rowUnread";
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .messageTable {
    table {
      border-bottom: 0;
    }

    thead {
      display: none;
    }

    tr {
      background: none !important;
    }

    td {
      vertical-align: middle;
      background: none !important;
      border: 0;
    }

    .messageList {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 20px;
      background: #ffffff;
      border: 1px solid #e9e9e9;
      border-radius: 8px;

      .titleSubject {
        .electionTitle {
          color: var(--colorFontSub);
        }

        .subject {
          font-size: 1.15rem;

          color: var(--colorFontDefault);
        }
      }

      .sendTimeTypeRead {
        display: flex;
        align-items: center;

        .sendTimeType {
          margin-right: 50px;
          text-align: right;
          font-size: 1.1rem;

          .sendTime {
            font-weight: bold;
          }

          .messageType {
            color: var(--colorFontSub);
            font-size: 1.15rem;
          }
        }

        .readIcon {
          width: 100px;
          text-align: center;
          font-size: 1.5rem;

          .noRead {
            color: var(--messageReadIconColorActive);
          }

          .read {
            color: var(--messageReadIconColor);
          }
        }

        .deleteIcon .b-icon.bi {
          color: var(--messageDeleteIconColor);
          font-size: 1.5rem;
        }

        .deleteIcon .b-icon.bi:hover {
          color: var(--messageDeleteIconColorActive);
          font-size: 1.5rem;
        }
      }
    }

    .rowUnread {
      .subject {
        font-weight: bold;
      }

      .messageList {
        background: var(--unreadMessageBackground);
        border: 1px solid var(--unreadMessageBorder);
      }
    }

    @media (max-width: 767px) {
      .messageList {
        flex-direction: column-reverse;
        align-items: initial;
        padding: 0;

        .titleSubject {
          padding: 0 10px 10px;
          font-size: 1rem;

          .subject {
            font-size: 1.2rem;
          }
        }

        .sendTimeTypeRead {
          justify-content: space-between;
          padding: 10px 10px 0;

          .sendTimeType {
            margin-right: 10px;
            text-align: left;
            font-size: 1rem;

            .sendTime {
              font-size: 1.2rem;
            }
          }

          .readIcon {
            width: auto;
            padding-right: 10px;
          }
        }
      }
      .table-pagination > div {
        margin-bottom: 5px;
      }
    }
  }
}
</style>
