<template>
  <div>
    <div v-if="isNoData" class="noDataImg" style="height: 160px">
      <div class="noDataFont">
        {{ $g("search.noDataText") }}
      </div>
    </div>
    <b-list-group-item
      v-for="catagory in catagoryList"
      :key="catagory.categoryId"
      class="d-flex flex-column"
    >
      <k-link
        class="ellipsis"
        type="primary"
        @click="toggleCatagory(catagory.categoryId)"
        style="color: initial; text-decoration: underline"
      >
        <b-icon
          :icon="
            isInArray(showCatagoryIds, catagory.categoryId)
              ? 'dash-square'
              : 'plus-square'
          "
          class="mr-1 messageIcon"
        />
        {{ catagory.category }}
      </k-link>
      <div
        v-for="child in catagory.children"
        :key="child.id"
        style="margin-left: 2.5rem"
        class="d-flex"
      >
        <template v-if="isInArray(showCatagoryIds, catagory.categoryId)">
          <k-link
            class="ellipsis"
            type="primary"
            @click="onTemplateClick(child.id)"
          >
            {{ child.name }}
          </k-link>
        </template>
      </div>
    </b-list-group-item>
  </div>
</template>
<script>
import { getTemplateAllList, getDocAllList } from "../api/messageTemplate";
import { enumTemplateType } from "../enums/enumTemplateType";
import storeage from "../../../utils/storeage";

export default {
  mounted() {
    this.getCatagoryList();
  },
  props: {
    singleCheck: {
      type: Boolean,
      default: function () {
        return false;
      }
    },
    templateType: {
      type: Number,
      default() {
        return 0;
      }
    },
    isEvent: {
      type: Boolean,
      default() {
        return false;
      }
    },
    isNotification: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      electionId: this.isEvent ? storeage.getElectionId() : "",
      catagoryList: [],
      showCatagoryIds: [],
      isNoData: false
    };
  },
  computed: {},
  methods: {
    onTemplateClick(id) {
      this.catagoryList.forEach((catagory) => {
        catagory.children.forEach((child) => {
          if (child.id == id) {
            if (this.singleCheck) child.check = true;
            this.$emit("choose", child);
          } else {
            if (this.singleCheck) child.check = false;
          }
        });
      });
    },
    getCatagoryList() {
      if (this.templateType === enumTemplateType.documentation) {
        getDocAllList({
          electionId: this.electionId,
          isNotification: this.isNotification
        }).then((res) => {
          res.data.forEach((catagory) => {
            catagory.children.forEach((doc) => {
              doc.check = false;
            });
          });
          this.catagoryList = res.data;
          if (res.data.length <= 0) {
            this.isNoData = true;
          } else {
            this.isNoData = false;
          }
        });
      } else if (this.templateType) {
        getTemplateAllList({
          templateType: this.templateType,
          electionId: this.electionId,
          isNotification: this.isNotification
        }).then((res) => {
          res.data.forEach((catagory) => {
            catagory.children.forEach((child) => {
              child.check = false;
            });
          });
          this.catagoryList = res.data;
          if (res.data.length <= 0) {
            this.isNoData = true;
          } else {
            this.isNoData = false;
          }
        });
      }
    },
    toggleCatagory(id) {
      if (this.isInArray(this.showCatagoryIds, id)) {
        this.removeArray(this.showCatagoryIds, id);
      } else {
        this.showCatagoryIds.push(id);
      }
    },
    isInArray(arr, value) {
      for (let item of arr) {
        if (value === item) {
          return true;
        }
      }

      return false;
    },
    removeArray(arr, val) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i] == val) {
          arr.splice(i, 1);
          break;
        }
      }
    }
  },
  watch: {
    templateType() {
      this.getCatagoryList();
    }
  }
};
</script>

<style lang="scss" scoped>
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}

.messageIcon {
  font-size: 1.2rem;
}
</style>
