import permission from './permission'
import {operatePermission} from 'fe-infrastractures'
function has(pCode,isClick) {
  let temps = operatePermission.getPermission()
  const codes =  temps&& temps.split(",")||[]
  const pCodes = pCode instanceof Array && pCode.length > 0 ? pCode : [pCode]
  if (pCodes.length) {
    return codes.some(code => pCodes.includes(code))
  } else {
    return true
  }
}

export const hasPermission = (pCode, isClick) => {
  return has(pCode, isClick);
}

export default {
  install(Vue) {
    Vue.prototype.$has = has
    Vue.directive('permission', permission)
  }
}
