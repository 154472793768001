<template>
  <div>
    <k-form-group
      :label-class="validate ? 'require-mark' : ''"
      label-suffix="address.addressLine1"
      label-for="noneBind"
      label-align-md="left"
      label-cols-md="3"
    >
      <KValidationProvider
        :rules="{ max: 200, required: validate }"
        v-slot="{ errors }"
        slim
        :name="getId('addressLine1')"
      >
        <k-alert :show="errors.length > 0" variant="warning">
          {{ errors[0] }}
        </k-alert>
        <k-form-input
          :id="getId('addressLine1')"
          v-model="innerAddress.addressLine1"
          :plaintext="isPlainText || nonEditableFields['MailingAddressLine1']"
          :disabled="innerAddress.isSame"
          :aria-label="$g('address.addressLine1')"
        />
      </KValidationProvider>
    </k-form-group>
    <k-form-group
      :label-class="validate ? 'require-mark' : ''"
      label-suffix="address.addressLine2"
      label-for="noneBind"
      label-align-md="left"
      label-cols-md="3"
    >
      <KValidationProvider
        :rules="{ max: 200, required: validate }"
        v-slot="{ errors }"
        slim
        :name="getId('addressLine2')"
      >
        <k-alert :show="errors.length > 0" variant="warning">
          {{ errors[0] }}
        </k-alert>
        <k-form-input
          :id="getId('addressLine2')"
          v-model="innerAddress.addressLine2"
          :plaintext="isPlainText"
          :disabled="innerAddress.isSame"
          :aria-label="$g('address.addressLine2')"
        />
      </KValidationProvider>
    </k-form-group>
    <k-form-group
      :label-class="validate ? 'require-mark' : ''"
      label-suffix="address.addressLine3"
      label-for="noneBind"
      label-align-md="left"
      label-cols-md="3"
    >
      <KValidationProvider
        :rules="{ max: 200, required: validate }"
        v-slot="{ errors }"
        slim
        :name="getId('addressLine3')"
      >
        <k-alert :show="errors.length > 0" variant="warning">
          {{ errors[0] }}
        </k-alert>
        <k-form-input
          :id="getId('addressLine3')"
          v-model="innerAddress.addressLine3"
          :plaintext="isPlainText"
          :disabled="innerAddress.isSame"
          :aria-label="$g('address.addressLine3')"
        />
      </KValidationProvider>
    </k-form-group>
    <k-form-group
      v-if="hasOverseaCountry"
      :label-class="validate ? 'require-mark' : ''"
      label-suffix="address.addressLine4"
      label-for="noneBind"
      label-align-md="left"
      label-cols-md="3"
    >
      <KValidationProvider
        :rules="{ max: 200, required: validate }"
        v-slot="{ errors }"
        slim
        :name="getId('country')"
      >
        <k-alert :show="errors.length > 0" variant="warning">
          {{ errors[0] }}
        </k-alert>
        <k-form-input
          :id="getId('country')"
          v-model="innerAddress.overseaCountry"
          :plaintext="isPlainText"
          :disabled="innerAddress.isSame"
          :aria-label="$g('address.addressLine4')"
        />
      </KValidationProvider>
    </k-form-group>
  </div>
</template>
<script>
export default {
  props: {
    identifier: {
      type: String,
      default() {
        return "";
      },
    },
    address: {
      type: Object,
      default() {
        return {};
      },
    },
    isPlainText: {
      type: Boolean,
      default() {
        return false;
      },
    },
    validate: {
      type: Boolean,
      default() {
        return false;
      },
    },
    nonEditableFields: {
      type: Object,
      default() {
        return {
          MailingAddressLine1: false,
        };
      },
    },
    hasOverseaCountry: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      innerAddress: this.address,
    };
  },
  watch: {
    address: function (newAddress) {
      this.innerAddress = newAddress;
    },
    innerAddress: {
      handler: function () {
        this.$emit("addressChanged");
      },
      deep: true,
    },
  },
  methods: {
    clearAddress() {
      this.innerAddress.addressLine1 = "";
      this.innerAddress.addressLine2 = "";
      this.innerAddress.addressLine3 = "";
      this.innerAddress.overseaCountry = "";
    },
    getId(element) {
      return element + this.identifier;
    },
  },
};
</script>
