var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"layoutContent"},[_c('div',{staticClass:"contentParty"},[_c('div',{staticClass:"d-flex align-items-center justify-content-md-start mb-2 flex-md-row-reverse",staticStyle:{"border-bottom":"1px solid #e7eaee","padding-bottom":"20px"}},[_c('k-form-checkbox',{staticClass:"messageSwitch",attrs:{"switch":"","size":"lg"},on:{"change":_vm.showUnreadChange},model:{value:(_vm.showUnread),callback:function ($$v) {_vm.showUnread=$$v},expression:"showUnread"}}),_c('div',{staticClass:"mr-2",staticStyle:{"padding-top":"0.3125rem"}},[_vm._v(" "+_vm._s(_vm.$g("onlyShowUnread"))+" ")])],1),_c('konn-grid',{ref:"selectableTable",staticClass:"messageTable cardBody",class:{ 'table-show-search-area': true },attrs:{"data-source":_vm.dataSource,"get-condition":_vm.getCondition,"grid-dort-desc":_vm.sortDesc,"sort-icon-left":"","fields":_vm.fields,"has-action":false,"has-check-box":false,"row-class":_vm.rowClass,"page-class":_vm.pageClass,"has-pager":_vm.hasPager},on:{"total-changed":_vm.totalChanged},scopedSlots:_vm._u([{key:"cell(subject)",fn:function(row){return [_c('div',{staticClass:"messageList"},[_c('div',{staticClass:"titleSubject"},[_c('div',{staticClass:"electionTitle"},[_vm._v(" "+_vm._s(row.item.electionTitle)+" ")]),_c('a',{staticClass:"subject",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.subjectClick(row.item)}}},[_vm._v(" "+_vm._s(row.item.subject)+" ")])]),_c('div',{staticClass:"sendTimeTypeRead"},[_c('div',{staticClass:"sendTimeType"},[_c('div',{staticClass:"sendTime"},[_vm._v(" "+_vm._s(row.item.sendTime)+" ")]),_c('div',{staticClass:"messageType"},[_vm._v(" "+_vm._s(row.item.messageType)+" ")])]),_c('div',{staticClass:"readIcon"},[_c('b-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
                  placement: 'bottomleft',
                  trigger: 'hover',
                  title: row.item.isRead
                    ? _vm.$g('markAsUnRead')
                    : _vm.$g('markAsRead'),
                  boundary: 'window',
                }),expression:"{\n                  placement: 'bottomleft',\n                  trigger: 'hover',\n                  title: row.item.isRead\n                    ? $g('markAsUnRead')\n                    : $g('markAsRead'),\n                  boundary: 'window',\n                }"}],staticClass:"mr-3",class:row.item.isRead ? 'read' : 'noRead',attrs:{"icon":row.item.isRead ? 'envelope' : 'envelope-open'},on:{"click":function($event){return _vm.onSetMessage(row.item)}}}),_c('b-icon-delete',{staticClass:"deleteIcon",on:{"click":function($event){return _vm.deleteMessageClick(row.item)}}})],1)])])]}}])})],1),_c('pop-window',{attrs:{"id":"messagesDetail","check-selected":false,"title":_vm.$g('popwindow.message'),"hide-ok":true,"cancel-title":_vm.$g('popwindow.close')}},[_c('messagesPopWindowDetail',{attrs:{"detail-id":_vm.detailId}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }