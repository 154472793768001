<template>
  <k-form-group>
    <KValidationProvider
      :rules="{ signatureIsEmpty: isEmpty && !noNeedValidate }"
      v-slot="{ errors }"
      slim
    >
      <k-alert :show="errors.length > 0" variant="warning">
        {{ errors[0] }}
      </k-alert>
      <div class="content-group d-flex align-items-end">
        <vueSignature
          ref="signature"
          :sigOption="option"
          :disabled="disabled"
          :defaultUrl="dataUrl"
          :w="'70%'"
          v-model="isEmpty"
        ></vueSignature>
        <div style="margin-left: 10px" v-if="!disabled">
          <k-button @click="clear" style="margin-bottom: 5px">Signature.Clear</k-button>
        </div>
      </div>
    </KValidationProvider>
  </k-form-group>
</template>
<script>
import { addDocument, updateDocument, getDocumentPhotoList } from "../documentation/api";
import vueSignature from "vue-signature";
export default {
  props: {
    logType: {
      type: Number,
      default() {
        return 0;
      },
    },
    docType: {
      type: Number,
      default() {
        return 0;
      },
    },
    id: {
      type: String,
      default() {
        return "";
      },
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
    addDocumentApi: {
      type: Function,
      default: addDocument,
    },
    updateDocumentApi: {
      type: Function,
      default: updateDocument,
    },
    getDocumentApi: {
      type: Function,
      default: getDocumentPhotoList,
    },
    noNeedValidate: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  components: {
    vueSignature,
  },
  data() {
    let that = this;
    return {
      dataUrl: "",
      isEmpty: true,
      documentId: null,
      isDoSave: false,
      option: {
        penColor: "rgb(0, 0, 0)",
        backgroundColor: "rgb(235,235,235)",
        onEnd() {
          that.isEmpty = this._isEmpty;
        },
      },
    };
  },
  watch: {
    isEmpty() {},
    dataUrl() {},
  },
  mounted() {
    this.loadSignature();
  },
  methods: {
    loadSignature() {
      this.getDocumentApi(this.docType, this.id).then((res) => {
        if (res.data.length > 0) {
          this.$refs.signature.fromDataURL(res.data[0].base64String);
          this.documentId = res.data[0].documentId;
          this.isEmpty = false;
        }
      });
    },
    save() {
      var _this = this;
      if (_this.isDoSave) {
        return;
      }
      var png = _this.$refs.signature.save();
      var byteString = atob(png.split(",")[1]);
      var mimeString = png.split(",")[0].split(":")[1].split(";")[0];
      var ab = new ArrayBuffer(byteString.length);
      var ia = new Uint8Array(ab);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      var blob = new Blob([ab], { type: mimeString });

      let opt;
      var formData = new FormData();
      if (_this.documentId) {
        opt = _this.updateDocumentApi;
        formData.append("Id", _this.id);
        formData.append("DocumentId", _this.documentId);
      } else {
        opt = _this.addDocumentApi;
      }
      formData.append("RefId", _this.id);
      formData.append("DocRequestType", _this.docType);
      formData.append("LogRequestType", _this.logType);
      formData.append("Title", "Signature");
      formData.append("File", blob, "Signature.png");
      _this.isDoSave = true;
      opt(formData).then((m) => {
        _this.isDoSave = false;
        this.$emit("finish-signature", m);
      });
    },
    clear() {
      var _this = this;
      _this.$refs.signature.clear();
      _this.isEmpty = true;
    },
    // fromDataURL(url) {
    //   var _this = this;
    //   _this.$refs.signature.fromDataURL("data:image/png;base64,iVBORw0K...");
    // },
    // checkIsEmpty() {
    //   var _this = this;
    //   if (_this.$refs.signature.isEmpty()) {
    //     this.isEmpty = true;
    //   } else {
    //     this.isEmpty = false;
    //   }
    // },
  },
};
</script>
