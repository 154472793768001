
const routes = [
  {
    name: "home",
    path: "/",
    meta: {
      title: "home",
      pageTitle: "welcome",
    },
    component: () => import("../../views/home/home"),
    route: true,
    children: [
      {
        name: "apply",
        path: "apply",
        meta: {
          title: "apply",
          pageTitle: "welcome",
        },
        route: true,
        component: () => import("../../views/home/apply"),
      },
    ],
  },
];

export default [
  {
    name: "home",
    path: "/home",
    meta: {
      title: "home",
    },
    component: () => import("../../layout/brief"),
    children: [...routes],
  },
];
