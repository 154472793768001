<template>
  <div>
    <div class="d-flex justify-content-end mr-3">
      <b-icon-add
        @click="onAddClick"
        class="mr-3"
        v-if="!plaintext"
      />
      <b-icon-delete
        @click="onDeleteClick"
        v-if="!plaintext"
      />
    </div>
    <k-alert-msg ref="lblMsg" />
    <KonnFixedGrid
      ref="selectableTable"
      :has-check-box="!plaintext"
      :has-action="false"
      :sticky-header="true"
      class="stickyTable table-bordered border-0 mt-3"
      :fields="fields"
      :original-items="form.users"
    />
    <k-popwindow
      id="officerRecipientsAdd"
      :title="$g('popwindow.user')"
      @ok="onPopSelected"
      :before-close="onBeforeClose"
    >
      <OfficerRecipientsAdd ref="officerRecipientsAdd" />
    </k-popwindow>
  </div>
</template>

<script>
import KonnFixedGrid from "../../../../../../../business-components/konn-grid/konnFixedGrid";
import OfficerRecipientsAdd from "./officer-recipients-add.vue";

export default {
  components: {
    KonnFixedGrid,
    OfficerRecipientsAdd,
  },
  props: {
    plaintext: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      default: () => {
        return null;
      },
    },
  },
  data(){
    return {
      fields: [
        { key: "firstName", label: "firstName" },
        { key: "lastName", label: "lastName" },
        { key: "email", label: "email" },
      ],
      items: [],
    };
  },
  created(){
    this.setItems();
  },
  computed: {},
  methods: {
    onAddClick(){
      if(!this.plaintext){
        this.$bvModal.show("officerRecipientsAdd");
      }
    },
    onDeleteClick(){
      let pmOpt = this.$refs.selectableTable.confirmOpt(
          this.$g("const.confirm.delete"),
      );
      if(pmOpt == null){
        return;
      }
      pmOpt.then(() => {
        let select = this.$refs.selectableTable.getSelectedItems();
        select.forEach((c) => {
          let userIdsIndex = this.form.users.findIndex(
              (u) => u.userId == c.userId,
          );
          this.form.users.splice(userIdsIndex, 1);
        });
        this.$refs.selectableTable.deleteSelected();
      });
    },
    onPopSelected(){
      let select =
          this.$refs.officerRecipientsAdd.$refs.selectableTable.getSelectedItems();
      if(select.length > 0){
        if(!this.form.users){
          this.form.users = [];
        }
        console.log(select, this.form.users);
        select.forEach((e, i) => {
          let index = this.form.users.findIndex((c) => c.userId == e.userId);
          if(index < 0){
            this.form.users.push(e);
          }
        });
        this.items = this.form.users;
      }
    },
    onBeforeClose(){
      return this.$bvModal
          .msgBoxConfirm(this.$g("const.confirm.save"), {
            title: this.$g("const.confirm.confirm"),
            cancelTitle: this.$g("const.confirm.saveYes"),
            okTitle: this.$g("const.confirm.no"),
            cancelVariant: "primary",
            okVariant: "secondary",
            noCloseOnBackdrop: true,
          })
          .then((value) => {
            return value != null && !value;
          });
    },
    setItems(){
      if(this.form.users){
        this.items = this.form.users ?? [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .b-table-sticky-header > .table {
    border: 1px solid #dee2e6;
  }

  .b-table-sticky-header > .table.b-table > thead > tr > th {
    outline-color: #dee2e6;
    outline-style: solid;
    outline-width: 1px;
    border: none;
  }
}
</style>
