var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-main"},[_c('KValidationObserver',{ref:"observer",staticClass:"content-page"},[_c('k-form',{staticClass:"content-form",on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();},"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"content-main"},[_c('k-alert-msg',{ref:"lblMsg"}),_c('div',{staticClass:"content-party"},[_c('div',{staticClass:"content-group backgroundGray"},[_c('k-form-group',{attrs:{"label-class":"require-mark","id":"input-group-sort","label-suffix":"sort","label-for":"sort","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('KValidationProvider',{attrs:{"rules":{
                  required: true,
                  max: 3,
                  numberBetween: { minValue: 1, maxValue: 999 },
                  numeric: true,
                  hasFieldUsedKey: {
                    fieldList: _vm.origItems,
                    field: _vm.$g('order'),
                    keyType: Number,
                    key: 'sort',
                  },
                },"slim":"","name":"sort"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-input',{attrs:{"id":"input-sort"},model:{value:(_vm.form.sort),callback:function ($$v) {_vm.$set(_vm.form, "sort", $$v)},expression:"form.sort"}})]}}])})],1),_c('k-form-group',{attrs:{"label-class":"require-mark","id":"input-group-categoryTitle ","label-suffix":"categoryTitle","label-for":"categoryTitle","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('KValidationProvider',{attrs:{"rules":{
                  required: true,
                  max: 200,
                  remote: _vm.checkCategoryUsed,
                },"slim":"","name":"categoryTitle"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-input',{attrs:{"id":"input-categoryTitle"},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})]}}])})],1)],1)])],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }