import Vue from "vue";
import VueI18n from "vue-i18n";
import globalEnv from "../libs/globalEnv";

Vue.use(VueI18n);

let i18n;

if(globalEnv.VUE_APP_USE_LOCAL_LANGUAGE_RES){
  const files = require.context("@/locale/lang/en", true, /\.json$/);
  const modules = files.keys().reduce((ins, key) => {
    let name = key.replace(/^\.\/(.*)\.\w+$/, "$1");
    name = name.toLowerCase();
    const data = files(key);
    (ins[name] = data);
    return ins;
  }, []);
  i18n = new VueI18n({
    locale: "en",
    silentTranslationWarn: true,
    messages: {
      "en": modules,
    },
  });
} else {
  i18n = new VueI18n({
    silentTranslationWarn: true,
    locale: "en",
  });
}

export default i18n;
