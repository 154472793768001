<template>
  <k-popwindow
    id="pop-add-communication-log"
    :title="title"
    @ok="onPopOK"
    :before-close="onBeforeClose"
    :hide-ok="isPlainText"
  >
    <input type="checkbox" checked v-show="false" />
    <div class="content-group">
      <KValidationObserver ref="observer" class="content-page">
        <k-alert-msg ref="lblMsg" />
        <k-form-group
          label-class="require-mark"
          label-suffix="log.subject"
          label-for="no-bind"
          label-align-md="right"
          label-cols-md="3"
          content-cols-lg="6"
          content-cols-md="7"
        >
          <KValidationProvider
            :rules="{
              required: true,
              max: 200,
              validateSpecialCharacter: true,
            }"
            v-slot="{ errors }"
            slim
            name="subject"
            ref="subjectValidation"
          >
            <k-alert :show="errors.length > 0" variant="warning">
              {{ errors[0] }}
            </k-alert>
            <k-form-input v-model="form.subject" :plaintext="isPlainText" />
          </KValidationProvider>
        </k-form-group>
        <k-form-group
          label-class="require-mark"
          label-suffix="log.description"
          label-for="no-bind"
          label-align-md="right"
          label-cols-md="3"
          content-cols-lg="6"
          content-cols-md="7"
        >
          <KValidationProvider
            :rules="{ required: true }"
            v-slot="{ errors }"
            slim
            name="Description"
            ref="contentValidation"
          >
            <k-alert :show="errors.length > 0" variant="warning">
              {{ errors[0] }}
            </k-alert>
            <k-form-textarea
              v-model="form.description"
              rows="10"
              :plaintext="isPlainText"
            />
          </KValidationProvider>
        </k-form-group>
        <k-form-group
          label-suffix="log.type"
          label-for="no-bind"
          label-align-md="right"
          label-cols-md="3"
          content-cols-lg="6"
          content-cols-md="7"
        >
          <k-form-select
            v-model="form.logType"
            :options="types"
            :plaintext="isPlainText"
          />
        </k-form-group>
        <k-form-group
          label-suffix="log.sentTime"
          label-for="no-bind"
          label-align-md="right"
          label-cols-md="3"
          content-cols-lg="6"
          content-cols-md="7"
        >
          <KValidationProvider
            rules="validateDateTimeFormat"
            v-slot="{ errors }"
            slim
            name="schedule"
          >
            <k-alert :show="errors.length > 0" variant="warning">
              {{ errors[0] }}
            </k-alert>
            <k-form-date-timepicker
              v-model="form.sentTime"
              :plaintext="isPlainText"
            />
          </KValidationProvider>
        </k-form-group>
        <k-form-group
          label-suffix="log.attachment"
          label-for="no-bind"
          label-align-md="right"
          label-cols-md="3"
          content-cols-lg="6"
          content-cols-md="7"
        >
          <div v-for="doc in docList" :key="doc.id" class="col-form-label">
            <k-link @click="downloadFile(doc.documentId)">
              {{ doc.fileName }}
            </k-link>
            <span
              class="icon-x"
              @click="onDeleteDoc(doc.documentId)"
              v-if="!isPlainText"
            />
          </div>
          <KValidationProvider
            :rules="{
              acceptFileType: accept,
              maxFileSize: size * 1024,
            }"
            v-slot="{ errors }"
            slim
          >
            <k-alert :show="errors.length > 0" variant="warning">
              {{ errors[0] }}
            </k-alert>
            <common-upload
              ref="fileinput"
              v-model="form.files"
              :accept="accept"
              :size="size"
              :is-multiple="true"
              :show-message="false"
              :is-use-internal-validate="false"
              :plaintext="isPlainText"
              :on-delete="() => {}"
            >
              <template #info>
                <div class="mt-1" v-if="!isPlainText">
                  {{ $g("upload.size") }}: {{ getFileSize() }}
                  <br />
                  {{ $g("upload.extensions") }}: {{ accept }}
                </div>
                <div v-else />
              </template>
            </common-upload>
          </KValidationProvider>
        </k-form-group>
      </KValidationObserver>
    </div>
  </k-popwindow>
</template>

<script>
import {
  saveCommunicationLog,
  getEditCommunicationSendType,
  getCommunicationLog,
  downloadAttachment,
  deleteAttachment,
} from "./api";
import { getUploadConfig } from "../../api/upload.js";
import storeage from "../../utils/storeage";

export default {
  props: {
    id: {
      type: String,
      default() {
        return null;
      },
    },
    refId: {
      type: String,
      default() {
        return null;
      },
    },
    logRequestType: {
      type: Number,
      default() {
        return null;
      },
    },
    isPlainText: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isEvent: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      title: "",
      form: {
        files: [],
        subject: "",
        description: "",
        id: null,
        logType: null,
        sentTime: null,
      },
      types: [],
      accept:
        ".doc, .docx, .xls, .ppt, .pptx, .jpg, .jpeg, .bmp, .png, .wav, .wma, .avi, .wmv, .rmvb, .rm, .mov, .txt, .xml, .pdf",
      size: 1024,
      res: {},
      docList: [],
      maxContent: 2000,
    };
  },
  methods: {
    showPop(id) {
      this.$bvModal.show("pop-add-communication-log");
      this.getData(id);
    },
    onPopOK() {
      let formData = new FormData();
      this.form.files.forEach((file) => {
        formData.append("files", file, file.name);
      });
      formData.append("refId", this.refId);
      formData.append("logRequestType", this.logRequestType);
      formData.append("subject", this.form.subject);
      formData.append("surveyResponse", this.form.description);
      console.log(this.form.description.trim());

      if (this.form.id) formData.append("id", this.form.id);
      if (this.form.logType) formData.append("logType", this.form.logType);
      if (this.form.sentTime)
        formData.append("sendTimeLong", this.form.sentTime);
      if (this.id) formData.append("id", this.id);
      if (this.isEvent) {
        var electionId = storeage.getElectionId();
        if (electionId) formData.append("electionId", electionId);
      }
      saveCommunicationLog(formData).then((res) => {
        this.$emit("saveSuccess", res);
      });
    },
    async onBeforeClose() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        const value = await this.$bvModal.msgBoxConfirm(
          this.$g("const.confirm.save"),
          {
            title: this.$g("const.confirm.confirm"),
            cancelTitle: this.$g("const.confirm.saveYes"),
            okTitle: this.$g("const.confirm.no"),
            cancelVariant: "primary",
            okVariant: "secondary",
            noCloseOnBackdrop: true,
          }
        );

        return value != null && !value;
      }
    },
    bindTypes() {
      return getEditCommunicationSendType({}).then((data) => {
        const items = data.data;
        this.types = items || [];
      });
    },
    getData(id) {
      this.docList = [];
      this.form = {
        files: [],
        subject: "",
        description: "",
        id: null,
        logType: null,
        sentTime: null,
      };
      this.title = this.$g("log.addCommunicationLog");
      if (id) {
        this.title = this.$g("log.editCommunicationLog");
        if (this.isPlainText) {
          this.title = this.$g("log.viewCommunicationLog");
        }
        getCommunicationLog({ id, logRequestType: this.logRequestType }).then(
          (res) => {
            this.form.files = [];
            this.form.id = res.data.id;
            this.form.subject = res.data.subject;
            this.form.description = res.data.surveyResponse;
            this.form.logType = res.data.logType;
            this.form.sentTime = res.data.sendTimeLong;
            this.docList = res.data.logDocs;
          }
        );
      }
    },
    onDeleteDoc(documentId) {
      let pmOpt = this.$bvModal
        .msgBoxConfirm(this.$g("const.confirm.delete"), {
          title: this.$g("const.confirm.confirm"),
          okTitle: this.$g("const.confirm.no"),
          cancelTitle: this.$g("const.confirm.yes"),
          cancelVariant: "primary",
          okVariant: "secondary",
          noCloseOnBackdrop: true,
        })
        .then((value) => {
          return value != null && !value;
        });
      if (pmOpt == null) {
        return;
      }
      pmOpt.then(async (success) => {
        if (success) {
          deleteAttachment(documentId, this.logRequestType).then((m) => {
            this.$refs.lblMsg.message(m.data.status, m.message);
            for (var i = 0; i < this.docList.length; i++) {
              if (this.docList[i].documentId === documentId) {
                this.docList.splice(i, 1);
                break;
              }
            }
          });
        }
      });
    },
    downloadFile(documentId) {
      downloadAttachment(documentId);
    },
    getConfig() {
      getUploadConfig("communicationlogdoc").then((data) => {
        this.accept = data.data.acceptTypes;
        this.size = data.data.maxSize / 1024;
      });
    },
    getFileSize() {
      let rest = "",
        size = this.size || 0;

      if (size < 1024) {
        rest = size + "KB";
      } else if (size < 1024 * 1024) {
        rest = (size / 1024).toFixed(0) + "MB";
      } else {
        //其他转化成GB
        rest = (size / (1024 * 1024)).toFixed(0) + "GB";
      }

      return rest;
    },
  },
  mounted() {
    this.bindTypes();
    this.getConfig();
  },
  watch: {
    "form.description": function (newVal, oldVal) {
      if (!newVal && newVal != "") {
        return;
      }
      if (newVal.realLenth > this.maxContent) {
        this.$nextTick(() => {
          this.form.description = newVal.left(this.maxContent);
        });
      }
    },
  },
};
</script>
