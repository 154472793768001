<template>
  <div>
    <b-tabs v-model="tabindex">
      <b-tab
        v-for="(item, index) in items"
        :title="item.title"
        :key="index"
        @click="clickTab(item, index)"
      />
    </b-tabs>
  </div>
</template>
<script>
import { reportNavAllTabs } from "./reportTabs";
import {
  getTabIndexByRouteName,
  handleTabs,
} from "../../../utils/permission/permission";

export default {
  data(){
    return {
      tabindex: 0,
      items: [],
      permissionCode: this.$route.meta.permissionCode,
    };
  },
  created(){
    this.items = handleTabs(reportNavAllTabs(this.isEvent, this.isAdd, this.permissionCode), this);
    this.tabindex = getTabIndexByRouteName(this.items, this);
  },
  methods: {
    clickTab(item){
      this.$router.push({
        name: item.route,
        query: {
          ...this.$route.query,
          id: this.$route.query.id,
        },
      });
    },
  },
  props: {
    isEvent: {
      type: Boolean,
      default(){
        return false;
      },
    },
    isAdd: {
      type: Boolean,
      default(){
        return false;
      },
    },
  },
};
</script>
