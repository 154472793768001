import Vue from "vue";
import BToast from 'bootstrap-vue';
Vue.use(BToast)
const alertMessage = new Vue();
const h = alertMessage.$createElement
const message = function (title, msg, extMsg) {
  var vMsg = msg
  if (extMsg) {
    vMsg = [h(
      'div',
      msg
    ), h(
      'div',
      extMsg
    )]
  }

  return alertMessage.$bvToast.toast(vMsg, {
    title: title,
    variant: "danger",
    solid: true,
    toaster: "b-toaster-top-center",
    noAutoHide: true,
  })
}
export default message