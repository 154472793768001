export const DeliveryResults = {
    methods: {
        getMessageDeliveryReportRouteName () {
            let routeName = "";
            if (this.isEvent) {
                routeName = this.isNotification
                    ? "eleNotificationDeliveryReport"
                    : "eleMessageDeliveryReport"
            } else {
                routeName = this.isNotification ? "notificationDeliveryReport" : "messageDeliveryReport"
            }
            return routeName;
        },
        getMessageLetterDeliveryDetailsRouteName () {
            let routeName = "";
            if (this.isEvent) {
                routeName = this.isNotification
                    ? "eleNotificationLetterDeliveryDetails"
                    : "eleMessageLetterDeliveryDetails"
            } else {
                routeName = this.isNotification
                    ? "notificationLetterDeliveryDetails" : "messageLetterDeliveryDetails"
            }
            return routeName;
        },
        getMessageDeliveryLetterListRouteName () {
            let routeName = "";
            if (this.isEvent) {
                routeName = this.isNotification
                    ? "eleNotificationDeliveryLetterList"
                    : "eleMessageDeliveryLetterList"
            } else {
                routeName = this.isNotification
                    ? "notificationDeliveryLetterList" : "messageDeliveryLetterList"
            }
            return routeName;
        },
        getMessageDeliveryResultRouteName () {
            let routeName = "";
            if (this.isEvent) {
                routeName = this.isNotification
                    ? "eleNotificationDeliveryResult"
                    : "eleMessageDeliveryResult"
            } else {
                routeName = this.isNotification
                    ? "notificationDeliveryResult" : "messageDeliveryResult"
            }
            return routeName;
        },
        getMessageDeliveryDetailsRoute () {
            let routeName = "";
            if (this.isEvent) {
                routeName = this.isNotification
                    ? "eleNotificationDeliveryDetails"
                    : "eleMessageDeliveryDetails"
            } else {
                routeName = this.isNotification
                    ? "notificationDeliveryDetails" : "messageDeliveryDetails"
            }
            return routeName;
        }
    }

}