<template>
  <div>
    <div class="form-inline mb-2">
      <k-form-group
        label-suffix="category.title"
        label-for="search-area-name"
      >
        <k-form-input
          id="search-area-name"
          v-model="name"
          class="mr-1"
        />
        <k-button @click="search">
          category.search
        </k-button>
      </k-form-group>
    </div>
    <konn-grid
      :select-mode="selectMode"
      :has-action="hasAction"
      :fields="fields"
      ref="selectableTable"
      :data-source="dataSource"
      :get-condition="getCondition"
      @row-selected="onRowSelected"
      grid-sort-by="boundaryCode"
    />
  </div>
</template>
<script>
import { getReportCategorys } from "../api/report";

export default {
  data(){
    return {
      name: "",
      selectMode: "single",
      selected: [],
      hasAction: false,
      fields: [
        {
          key: "title",
          label: "category.title",
          sortable: false,
          sortDirection: "desc",
        },
      ],
    };
  },
  computed: {
    dataSource: function (){
      return getReportCategorys;
    },
  },
  props: {
    isBau: {
      type: Boolean,
      default(){
        return true;
      },
    },
  },
  methods: {
    onRowSelected(items){
      this.selected = items;
    },
    getCondition(){
      return {
        title: this.name.trim(),
        isBau: this.isBau,
      };
    },
    getSelected(){
      return this.selected;
    },
    getSelectedIds(){
      return this.$refs.selectableTable.getSelectedIds();
    },
    search: function (){
      this.$refs.selectableTable.pageChange(1);
    },
  },
};
</script>
