<template>
  <div>
    <span
      v-if="!hideNotificationAlert"
      v-b-tooltip.hover
      :title="$g('notifications')"
      style="position: relative; margin-right: 28px"
    >
      <b-icon
        icon="bell-fill"
        @click="onMessageClick"
      />
      <b-badge
        href="#"
        class="countIcon"
        @click="onMessageClick"
        v-show="messageNum>0"
      >{{
        messageNum
      }}</b-badge>
    </span>
    <pop-window
      id="notifications"
      :hide-ok="true"
      :check-selected="false"
      :title="$g('popwindow.notifications')"
      :cancel-title="$g('popwindow.close')"
    >
      <notification-list @refreshMessageCount="getMessageCount" />
    </pop-window>
  </div>
</template>
<script>
import PopWindow from "../../k-components/modal/popWindow";
import NotificationList from "../../business-components/notifications/components/notification-list";
import { messageCount } from "./api/notification";
import { storeage } from "../../../index";

export default {
  components: { PopWindow, NotificationList },
  data(){
    return {
      messageNum: 0,
      timer: null,
      delay: 30000,
      hideNotificationAlert: storeage.getSystemGeneralInfo().hideNotificationAlert
    };
  },
  methods: {
    onMessageClick(){
      this.$bvModal.show("notifications");
    },
    getMessageCount(){
      messageCount().then((resp) => {
        this.messageNum = resp.data;
        this.timer = setTimeout(() => {
          this.getMessageCount();
        }, this.delay);
      });
    },
  },
  mounted(){
    if(!this.hideNotificationAlert){
      this.getMessageCount();
    }
  },
  beforeDestroy(){
    if(this.timer){
      clearTimeout(this.timer);
      this.timer = null;
    }
  },
};
</script>
<style scoped>
.countIcon {
  position: absolute;
  top: 0;
  left: 0px;
  transform: translate(13px, -50%);
  background-color: var(--messageCountBackground);
  border-radius: 8px;
  color: var(--messageCountColor);
  min-width: 15px;
}

.countIcon:hover,
.countIcon:focus {
  background-color: var(--messageCountBackgroundActive);
  color: var(--messageCountColorActive);
  box-shadow: unset;
}
</style>
