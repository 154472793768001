const routes = [
    {
        name: 'my-profile',
        path: '/',
        meta: { title: 'basic-info', requiresAuth: false },
        component: () => import('@/views/my-profile/my-profile'),
        route: true,
        children: [

            {
                name: "change-password",
                path: "change-password",
                meta: { title: "change-password", requiresAuth: false },
                route: true,
                component: () => import("@/views/my-profile/change-password"),
                children: []
            },
            {
                name: 'basic-information',
                path: '/',
                meta: { title: 'basic-info', requiresAuth: false },
                redirect: { name: "my-profile" },
                component: () => import('@/views/my-profile/basicinfo'),
                route: true,
            },

        ]
    },

]

export default [
    {
        path: '/my-profile',
        route: false,
        component: () => import('@/layout/brief'),
        children: [...routes],
    },
]