<template>
  <k-popwindow
    id="model-user"
    @ok="onOKAddUsers"
    :ok-title="$g('save')"
    :check-selected="true"
    :before-close="onBeforeCloseAddUsers"
    :title="$g('user')"
    @show="initFields"
  >
    <div>
      <div>
        <KValidationObserver ref="observer" class="pop-flex mb-2">
          <k-form-group label-suffix="firstName" />
          <KValidationProvider
            :rules="{ max: 50 }"
            v-slot="{ errors }"
            slim
            name="firstname"
            class="mr-1 wid"
          >
            <k-alert :show="errors.length > 0" variant="warning">
              {{ errors[0] }}
            </k-alert>
            <k-form-input v-model="firstName" type="text" />
          </KValidationProvider>
          <k-form-group label-suffix="lastName" />
          <KValidationProvider
            :rules="{ max: 50 }"
            v-slot="{ errors }"
            slim
            name="lastname"
            class="mr-1 wid"
          >
            <k-alert :show="errors.length > 0" variant="warning">
              {{ errors[0] }}
            </k-alert>
            <k-form-input v-model="lastName" type="text" />
          </KValidationProvider>
          <k-form-group label-suffix="group" />
          <k-form-select
            :options="groupList"
            v-model="groupId"
            class="mr-1 wid"
          />
          <k-button @click="onSearchClick"> Search </k-button>
        </KValidationObserver>
      </div>
      <konn-grid
        ref="selectableTable"
        :data-source="dataSource"
        :get-condition="getCondition"
        :class="{ 'table-show-search-area': true }"
        @total-changed="onTotalChanged"
        :fields="fields"
        :grid-sort-by="sortBy"
        :grid-sort-desc="sortDesc"
        :has-action="false"
        sort-icon-left
      />
    </div>
  </k-popwindow>
</template>

<script>
import { getUser, addUser, addUserToTemplate } from "../api/report";
export default {
  props: {
    reportId: {
      type: String,
    },
    reportTemplateId: {
      type: String,
    },
    isReport: {
      type: Boolean,
    },
    groupList: {
      type: Array,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      fields: [
        { key: "firstName", label: "First Name" },
        { key: "lastName", label: "Last Name" },
        { key: "email", label: "Email" },
        { key: "group", label: "Group" },
        { key: "statusStr", label: "Status" },
      ],
      sortBy: "",
      sortDesc: "",
      firstName: "",
      lastName: "",
      groupId: "",
    };
  },
  created() {},
  computed: {
    dataSource: function () {
      return getUser;
    },
  },
  methods: {
    initFields() {
      this.fields = [
        { key: "firstName", label: "First Name" },
        { key: "lastName", label: "Last Name" },
        { key: "email", label: "Email" },
        { key: "group", label: "Group" },
        { key: "statusStr", label: "Status" },
      ];
      this.firstName = "";
      this.lastName = "";
      this.groupId = "";
    },
    onOKAddUsers() {
      if (this.isReport) {
        let addUserData = {
          userIds: this.$refs.selectableTable.getSelectedIds(),
          reportId: this.$route.query.id,
        };
        addUser(addUserData).then((m) => {
          this.$emit("show-message", m);
        });
      } else {
        let addUserData = {
          userIds: this.$refs.selectableTable.getSelectedIds(),
          reportTemplateId: this.$route.query.id,
        };
        addUserToTemplate(addUserData).then((m) => {
          this.$emit("show-message", m);
        });
      }
    },
    onBeforeCloseAddUsers() {
      return this.$bvModal
        .msgBoxConfirm(this.$g("const.confirm.save"), {
          title: this.$g("const.confirm.confirm"),
          cancelTitle: this.$g("const.confirm.saveYes"),
          okTitle: this.$g("const.confirm.no"),
          cancelVariant: "primary",
          okVariant: "secondary",
          noCloseOnBackdrop: true,
        })
        .then((value) => {
          return value != null && !value;
        })
        .catch((err) => {
          return false;
        });
    },
    async onSearchClick() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        this.$refs.selectableTable.pageChange(1);
      }
    },
    getCondition() {
      return {
        firstName: this.firstName,
        lastName: this.lastName,
        userGroupID: this.groupId,
        reportId: this.reportId,
        reportTemplateId: this.reportTemplateId,
      };
    },
    onTotalChanged(total) {
      this.totalCount = total;
    },
  },
};
</script>

<style scoped>
.k-form {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
}

.k-form-group {
  display: flex;
  flex-grow: 2;
}

.k-form > .k-form-group > .form-group {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  width: 100%;
}

.k-form > .k-form-group > .form-group > div {
  flex-grow: 1;
}

.k-form > .k-form-group > .form-group > label {
  flex-grow: 1;
  text-align: right;
}

.wid {
  width: 15%;
}
</style>
