import { render, staticRenderFns } from "./custom-fields.vue?vue&type=template&id=ae106f06"
import script from "./custom-fields.vue?vue&type=script&lang=js"
export * from "./custom-fields.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../fe-workerportal/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports