<template>
  <div class="content-page">
    <election-select v-if="hasElection" />
    <div class="content">
      <div class="right-bar" v-show="seenSearch">
        <b-icon icon="x" @click="onToggleSearch" />
        <search-area
          v-if="showThisSearchArea"
          :type-name="typeName"
          :search-click="search"
          :clear-click="clear"
          :get-paras="getCondition"
          :set-paras="setParas"
          ref="searchArea"
          :fields="fields"
        >
          <k-form-group
            v-if="hsaSearchName"
            :label-suffix="
              isFacility ? 'search.facilityName' : 'search.organizationName'
            "
          >
            <k-form-input v-model="queryParams.name" />
          </k-form-group>
          <k-form-group
            v-if="hsaSearchContactName"
            label-suffix="search.contactName"
          >
            <k-form-input v-model="queryParams.contactName" />
          </k-form-group>
          <k-form-group label-suffix="search.contactInfo">
            <k-form-input v-model="queryParams.contactInfo" />
          </k-form-group>
          <k-form-group label-suffix="search.status">
            <k-multiselect :options="statusList" v-model="queryParams.status" />
          </k-form-group>
        </search-area>
      </div>
      <div class="center-bar">
        <div class="nav-btn-group">
          <k-button-group>
            <k-button-back
              :back-route="this.getMessageDeliveryResultRouteName()"
              :use-hisotry-back="isUseHisotryBack"
            >
              buttons.back
            </k-button-back>
            <k-export-button
              v-if="isExport"
              :condition-getter="getSearchState"
              :export-handler="exportHandler"
              :check-has-data="checkHasData"
            >
              buttons.export
            </k-export-button>
          </k-button-group>
          <b-icon-search-area
            @click="onToggleSearch"
            :class="{ active: isActive }"
          />
          <KWikiHelp :propsWikiHelpCode="wikiHelpCode" />
        </div>

        <div class="content-main">
          <k-alert-msg ref="lblMsg" />
          <div class="content-navTab">
            <delivery-result-nav
              :is-event="isEvent"
              ref="nav"
              :tab-index="navIndex"
              :isNotification="isNotification"
              :isEvent="isEvent"
            />
          </div>
          <div class="content-party">
            <konn-grid
              :select-mode="selectMode"
              :has-check-box="hasCheckBox"
              :has-action="hasAction"
              ref="selectableTable"
              :data-source="dataSource"
              :get-condition="getCondition"
              @row-selected="onRowSelected"
              @fields-get="onFieldsGet"
              grid-sort-by="name"
              :set-paras="setParas"
              :auto-bind="false"
              @total-changed="onTotalChanged"
            >
              <template #cell(name)="row" v-if="hsaGridNameLink">
                <k-link @click="gotoContact(row.item)" v-if="row.item.entityId">
                  {{ row.value }}
                </k-link>
              </template>
            </konn-grid>
            <div class="mt-4">
              <div class="content-title">{{ $g("messageReportStatus") }}</div>
              <b-table
                :items="statusItems"
                :fields="statusFields"
                caption-top
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getDeliveryResultDetailList,
  delDeliveryResult,
  getDeliveryResultDetialStatusDropdown,
  exportDeliveryDetails,
} from "../../api/messageDeliveryResult";
import DeliveryResultNav from "../../components/deliveryResultNav";
import { repResult } from "../../../../libs/global";
import statusList from "../../components/statusList.js";
import { enumDeliveryResultType } from "../../enums/enumDeliveryResultType";
import { has } from "../../../../utils/permission/operatePermission";
import storeage from "../../../../utils/storeage";

import { permissionCode } from "../../mixins/permissionCode";
import { aloneMobileLayout } from "../../../../utils/mixins/aloneMobileLayout";
import { DeliveryResults } from "../../mixins/messagePage";

export default {
  mixins: [permissionCode, aloneMobileLayout, DeliveryResults],
  props: {
    hasElection: {
      type: Boolean,
      default() {
        return false;
      },
    },
    hsaSearchName: {
      type: Boolean,
      default() {
        return true;
      },
    },
    hsaSearchContactName: {
      type: Boolean,
      default() {
        return true;
      },
    },
    hsaGridNameLink: {
      type: Boolean,
      default() {
        return true;
      },
    },
    propApi: {
      type: Object,
      default() {
        return {
          getDeliveryResultDetailList,
          delDeliveryResult,
          getDeliveryResultDetialStatusDropdown,
          exportDeliveryDetails,
        };
      },
    },
  },
  components: {
    DeliveryResultNav,
    // ColumnConfigIcon,
  },
  data() {
    let isEvent = this.$route.name.startsWith("ele");
    let status = this.$route.query.status;
    if (status) {
      status = status.split(",");
    } else {
      status = [];
    }
    return {
      isEvent: isEvent,
      navIndex: 1,
      deliveryType: this.$route.query.deliveryType,

      statusFields: [
        {
          key: "status",
          label: this.$g("status"),
          sortable: false,
          width: "20%",
          formatter: (value) => {
            return this.$g(value);
          },
        },
        {
          key: "description",
          label: this.$g("description"),
          sortable: false,
          width: "80%",
          formatter: (value) => {
            return this.$g(value);
          },
        },
      ],
      statusItems: statusList,

      queryParams: {
        id: this.$route.query.id,
        electionId: isEvent ? storeage.getElectionId() : "",
        name: "",
        contactName: "",
        contactInfo: "",
        status: status,
        title: this.$route.query.title,
      },
      statusList: [],

      typeName: isEvent ? "EleDeliveryDetails" : "DeliveryDetails",
      fields: [],
      selectMode: "multi",
      selected: [],
      hasCheckBox: true,
      hasAction: false,
      showThisSearchArea: true,

      seenSearch: true,
      isActive: true,
      totalCount: 0,
      isFacility: false,
      isUseHisotryBack: this.$route.query.historyBack === "1",
      isExport: true,
      wikiHelpCode: null,
    };
  },

  computed: {
    dataSource: function() {
      return this.propApi.getDeliveryResultDetailList;
    },
    exportHandler: function() {
      return this.propApi.exportDeliveryDetails;
    },
  },

  mounted() {
    this.$refs.nav.addDeliveryDetailsTab();
    this.propApi
      .getDeliveryResultDetialStatusDropdown({ type: this.deliveryType })
      .then((data) => {
        this.statusList = data.data;
      });
    this.$refs.selectableTable.refresh();
    this.setPermission();
  },

  methods: {
    setPermission() {
      let wikiHelpCodes = this.isEvent
        ? this.$wikiHelpLinkCodes?.Event.Communication?.DeliveryResult
        : this.$wikiHelpLinkCodes?.BAU.Communication?.DeliveryResult;
      if (this.isNotification) {
        wikiHelpCodes = this.isEvent
          ? this.$wikiHelpLinkCodes?.Event.Notification?.DeliveryResult
          : this.$wikiHelpLinkCodes?.BAU.Notification?.DeliveryResult;
      }
      switch (parseInt(this.$route.query.deliveryType ?? -1)) {
        case enumDeliveryResultType.email:
          if (this.isEvent) {
            this.isExport = has(
              this.permissionCodeEvent.Communication
                .EmailResultDeliveryDetailExport
            );
          } else {
            this.isExport = has(
              this.permissionCode.Communication.EmailResultDeliveryDetailExport
            );
          }
          this.wikiHelpCode = wikiHelpCodes?.Email.DeliveryReport;
          break;
        case enumDeliveryResultType.sms:
          if (this.isEvent) {
            this.isExport = has(
              this.permissionCodeEvent.Communication
                .SMSResultDeliveryDetailExport
            );
          } else {
            this.isExport = has(
              this.permissionCode.Communication.SMSResultDeliveryDetailExport
            );
          }
          this.wikiHelpCode = wikiHelpCodes?.SMS.DeliveryReport;
          break;
        case enumDeliveryResultType.phone:
          if (this.isEvent) {
            this.isExport = has(
              this.permissionCodeEvent.Communication
                .PhoneResultDeliveryDetailExport
            );
          } else {
            this.isExport = has(
              this.permissionCode.Communication.PhoneResultDeliveryDetailExport
            );
          }
          this.wikiHelpCode = wikiHelpCodes?.Phone.DeliveryReport;
          break;

        default:
          this.wikiHelpCode = null;
          break;
      }
    },
    gotoContact(item) {
      let PermissionsCode =
        this.permissionCode?.Organization?.ContactInfo ||
        this.permissionCode?.worker?.basicInfo ||
        this.permissionCode?.Vendor?.VendorList?.BasicInfo;
      let Permissions = has(PermissionsCode);
      if (this.isFacility) {
        Permissions = this.isEvent
          ? has(this.permissionCodeEvent.ElectionFacilities.ContactInfo)
          : has(this.permissionCode.Facilities.ContactInfo);
      } else if (this.permissionCodeEvent?.eleWorker) {
        Permissions = has(this.permissionCodeEvent.eleWorker.basicInfo);
        PermissionsCode = this.permissionCodeEvent.eleWorker.basicInfo;
      }
      if (!Permissions) {
        this.$alert({
          title: this.$g("const.confirm.confirm"),
          content: this.$g("const.confirm.noPermission"),
          okText: this.$g("const.confirm.ok"),
        });
        return;
      }
      switch (true) {
        case this.isFacility &&
          PermissionsCode != this.permissionCode?.Organization:
          this.toLocation(item);
          break;
        case PermissionsCode == this.permissionCode?.Organization?.ContactInfo:
          this.toLocation(item);
          break;
        case PermissionsCode == this.permissionCodeEvent?.eleWorker.basicInfo:
          this.toWorker(item);
          break;
        case PermissionsCode == this.permissionCode?.worker.basicInfo:
          this.toWorker(item);
          break;
        case PermissionsCode ==
          this.permissionCode?.Vendor?.VendorList?.BasicInfo:
          this.toVendor(item);
          break;
        default:
          break;
      }
    },
    toLocation(item) {
      let route = "";
      let query = {
        id: item.entityId,
        name: item.name,
        historyBack: true,
        deliveredId: this.queryParams.id,
        dbtn: this.$route.query.dbtn,
        deliveryType: this.$route.query.deliveryType,
      };
      if (this.isFacility) {
        route = this.isEvent
          ? "eleMessageFacilityContactEdit"
          : "messageFacilityContactEdit";
      } else {
        route = this.isEvent
          ? "eleMessageOrganizationContactEdit"
          : "messageOrganizationContactEdit";
      }

      this.$router.push({
        name: route,
        query: query,
      });
    },
    toWorker(item) {
      let route = this.isEvent ? "eleWorker-view" : "worker-view";
      let query = {
        id: item.entityId,
        isView: "1",
        dbtn: 1,
        isDuplicate: 0,
        isShowDelete: 0,
        rollValidationRequired: 1,
        historyBack: 1,
      };
      if (this.isEvent) {
        query = { ...query, pollid: item.entityId };
      }
      this.$router.push({
        name: route,
        query: query,
      });
    },
    toVendor(item) {
      let route = "vendor-edit";
      let query = {
        id: item.entityId,
        isView: "0",
        pageStatus: item.status,
        dbtn: "1",
        historyBack: 1,
      };
      this.$router.push({
        name: route,
        query: query,
      });
    },
    onTotalChanged(total) {
      this.totalCount = total;
    },
    checkHasData() {
      if (!this.totalCount) {
        this.$refs.lblMsg.message(
          repResult.warning,
          this.$g("const.confirm.exportNoData")
        );
        return false;
      } else {
        return true;
      }
    },
    getSearchState() {
      let condition = this.$refs.selectableTable.getCondition();
      return this.$refs.selectableTable.getSearchState(condition);
    },
    delDeliveryResultHandle() {
      let pmOpt = this.confirmOpt(this.$g("const.confirm.delete"));
      if (pmOpt == null) {
        return;
      }
      pmOpt.then(() => {
        let paras = [this.queryParams.id];
        this.propApi.delDeliveryResult(paras).then((rep) => {
          this.$refs.lblMsg.message(rep.data.status, rep.message);
          if (rep.data.status === 1 || rep.data.status === 4) {
            this.$refs.selectableTable.refresh();
          }
        });
      });
    },

    onFieldsGet(fields) {
      this.fields = fields;
      for (let index in this.fields) {
        if (this.fields[index].label.indexOf("Facility") > -1) {
          this.isFacility = true;
          break;
        }
      }
    },

    onColumnChanged() {
      this.$refs.selectableTable.clearFields();
      this.$refs.searchArea.clearHandler();
    },
    onRowSelected(items) {
      this.selected = items;
    },
    clear() {
      this.queryParams.name = "";
      this.queryParams.contactName = "";
      this.queryParams.contactInfo = "";
      this.queryParams.status = [];
      this.queryParams.title = "";
      this.$refs.searchArea.setDynamicConditions();
    },
    getParas() {
      return {
        query: this.queryParams,
      };
    },

    setParas(paras) {
      this.queryParams.name = paras.name;
      this.queryParams.contactName = paras.contactName;
      this.queryParams.contactInfo = paras.contactInfo;
      this.queryParams.status = paras.status;
      this.queryParams.dynamicConditions = paras.dynamicConditions;
    },

    getCondition() {
      this.queryParams.dynamicConditions = this.$refs.searchArea.getDynamicConditions();
      return this.queryParams;
    },

    search: function() {
      this.$refs.selectableTable.pageChange(1);
    },

    onToggleSearch() {
      this.seenSearch = !this.seenSearch;
      this.isActive = !this.isActive;
    },
  },
};
</script>
