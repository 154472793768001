var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-page"},[(_vm.isEvent)?_c('election-select'):_vm._e(),_c('div',{staticClass:"center-bar"},[_c('div',{staticClass:"nav-btn-group"}),_c('div',{staticClass:"content-main"},[_c('k-alert-msg',{ref:"lblMsg"}),_c('div',{staticClass:"content-navTab"},[_c('div',[_c('nav-tab',{ref:"navTabs",attrs:{"refresh":_vm.navClick,"items":_vm.navItems,"need-total-count":false}})],1)]),(_vm.widgets.length)?_c('div',{staticClass:"d-flex flex-wrap background"},_vm._l((_vm.widgets),function(item,index){return _c('div',{key:index,class:{
            chart: true,
            half: item.size === 2 && !item.isEmpty,
            third: item.size === 4 && !item.isEmpty,
            halfEmpty: item.size === 2 && item.isEmpty,
            thirdEmpty: item.size === 4 && item.isEmpty,
          }},[(!item.isEmpty)?_c('GoogleChart',{attrs:{"widget":item,"template-type":419}}):_vm._e()],1)}),0):_c('div',{staticClass:"p-2"},[_vm._v(" "+_vm._s(_vm.$g("noResultsAreAvailable"))+" ")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }