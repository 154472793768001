<template>
  <div class="content-group">
    <k-form-group
      label-class="require-mark"
      label-suffix="subject"
      label-for="Subject"
      label-align-md="right"
      label-cols-md="2"
      content-cols-md="9"
    >
      <KValidationProvider
        :rules="{
          required: true,
          max: 500,
          validateSpecialCharacter: false,
          remote: false,
        }"
        v-slot="{ errors }"
        slim
        name="subject"
        ref="subjectValidation"
      >
        <k-alert
          :show="errors.length > 0"
          variant="warning"
        >
          {{ errors[0] }}
        </k-alert>
        <k-form-input
          v-model="formDetailContent.subject"
          :plaintext="isPlainText"
        />
      </KValidationProvider>
    </k-form-group>
    <k-form-group
      label-class="require-mark"
      label-suffix="content"
      label-for="Content"
      label-align-md="right"
      label-cols-md="2"
      content-cols-md="9"
      v-if="notificationType == notificationTypeEnum.email"
    >
      <KValidationProvider
        :rules="{ required: true }"
        v-slot="{ errors }"
        slim
        :name="$g('content')"
        ref="messageEditorValidation"
      >
        <k-alert
          :show="errors.length > 0"
          variant="warning"
        >
          {{ errors[0] }}
        </k-alert>
        <message-editor
          @select="onLabelSelect"
          ref="messageEditor"
          v-model="formDetailContent.content"
          :is-plain-text="isPlainText"
        >
          <slot name="insertLabel" />
        </message-editor>
      </KValidationProvider>
    </k-form-group>
    <k-form-group
      label-suffix="content"
      label-for="Content"
      label-align-md="right"
      label-cols-md="2"
      content-cols-md="9"
      v-if="notificationType == notificationTypeEnum.sms"
    >
      <template #label>
        <span class="require-mark">{{ $g("content") }}:</span>
        <slot
          name="smsInsertLabel"
          :form="formDetailContent"
        />
      </template>
      <KValidationProvider
        :rules="{ required: true, max: maxContent }"
        v-slot="{ errors }"
        slim
        :name="$g('content')"
        ref="bodyValidation"
      >
        <k-alert
          :show="errors.length > 0"
          variant="warning"
        >
          {{ errors[0] }}
        </k-alert>
        <k-form-textarea
          v-model="formDetailContent.content"
          :plaintext="isPlainText"
          rows="10"
        />
        <div v-if="!isPlainText">
          {{ `${maxContent - contentLength} ${$g("characterLeft")}` }}
        </div>
      </KValidationProvider>
    </k-form-group>
  </div>
</template>

<script>
import MessageEditor from "../../../../../common-ckeditor/messageEditor";
import { checkSubjectUsed } from "../api/notification";
import { notificationTypeEnum } from "./components/notificationTypeEnum";

export default {
  components: { MessageEditor },
  props: {
    formDetailContent: {
      type: Object,
      default: () => {
        return null;
      },
    },
    isPlainText: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    formDetailContentChange: {
      type: Function,
      default: () => {
        return null;
      },
    },
    isEvent: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    electionId: {
      type: String,
      default: () => {
        return null;
      },
    },
    notificationType: {
      type: Number | String,
      default: notificationTypeEnum.email,
    },
  },
  data(){
    return {
      notificationTypeEnum: notificationTypeEnum,
      maxContent: 1500,
    };
  },
  computed: {
    getFormDetailContentStr(){
      return JSON.stringify(this.formDetailContent);
    },
    contentLength(){
      if(this.formDetailContent && this.formDetailContent.content){
        return this.formDetailContent.content.length;
      }
      return 0;
    },
  },
  methods: {
    onLabelSelect(){
      this.$emit("label-select");
    },
    async isSubjectExits(){
      let para = {};
      para.subject = this.formDetailContent.subject;
      para.id = this.formDetailContent.id;
      para.electionId = this.formDetailContent.electionId;
      para.IsNotification = true;
      let result = null;
      await checkSubjectUsed(para).then(function (rep){
        result = rep;
      });
      return result;
    },
  },
  watch: {
    getFormDetailContentStr(NewData, OldData){
      this.formDetailContentChange(NewData, OldData);
    },
  },
};
</script>
