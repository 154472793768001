import http from "../utils/http";
import globalEnv from "../libs/globalEnv";


export const getRemoteTaskStatus = (url, data, baseURL, systemPATH) => {
  return http.post(url, data, {
    loading: false,
    useBody: true,
    systemPATH: systemPATH,
    baseURL: baseURL,
  });
};

export const downloadFile = (url, data, baseURL, systemPATH) => {
  return http.down(url, data, {
    useBody: true,
    systemPATH: systemPATH,
    baseURL: baseURL
  });
};
