<template>
  <div class="error-wrap">
    <div class="error-content">
      <div class="error-title">
        {{ $g("const.confirm.noPermission") }}
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.error-wrap {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 350px;
  max-width: 450px;
  padding: 2em;
  border: 1px solid #eee;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

  .error-content {
    .error-title {
      font-size: 1.2em;
      font-weight: bold;
    }
  }
}
</style>
