<template>
  <span class="iconSpan">
    <b-icon
      icon="envelope-fill"
      @click="communication"
      ref="communicationTip"
    />
    <b-tooltip
      :target="() => $refs['communicationTip']"
      :title="$g('icon.communicationToolTip')"
      boundary="window"
      :container="uid"
      placement="bottomleft"
      triggers="hover"
    />
    <div :id="uid" />
  </span>
</template>
<script>
export default {
  data () {
    return {
      uid: 'tip' + this._uid,
    }
  },
  methods: {
    communication () {
      this.$emit('click')
    },
  },
}
</script>
