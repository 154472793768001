<template>
  <b-modal
    v-bind="$props"
    v-on="listeners"
    ref="mdPop"
    @ok="onOk"
    no-enforce-focus
    :title="title"
    :no-close-on-backdrop="true"
    modal-class="modalOpenStatus"
    dialog-class="modalDialog"
    content-class="modalContent contentScroll"
  >
    <k-alert-msg ref="lblMsg" />
    <span ref="spSlot">
      <slot />
    </span>
    <template #modal-footer="{ cancel, ok }">
      <slot name="preBtn" />
      <b-button v-if="!hideOk" variant="primary" @click="ok()">
        {{ okTitle }}
      </b-button>
      <b-button v-if="!hideCancel" @click="cancel()">
        {{ cancelTitle }}
      </b-button>
      <slot name="nextBtn"></slot>
    </template>
  </b-modal>
</template>

<script>
import { BModal } from "bootstrap-vue";
import KAlertMsg from "../alert/alertMsg.vue";

const objProps = Object.assign({}, BModal.options.props);
objProps.okTitle = {
  type: String,
  default() {
    return this.$g("buttons.save");
  },
};
objProps.cancelTitle = {
  type: String,
  default() {
    return this.$g("buttons.cancel");
  },
};
objProps.size = {
  type: String,
  default() {
    return "xl";
  },
};
objProps.hideOk = {
  type: Boolean,
  default() {
    return false;
  },
};
objProps.hideCancel = {
  type: Boolean,
  default() {
    return false;
  },
};
objProps.checkSelected = {
  type: Boolean,
  default() {
    return true;
  },
};

objProps.beforeClose = {
  type: Function,
  default() {
    return null;
  },
};
objProps.title = {
  type: String,
  default() {
    return null;
  },
};
objProps.onOkFunction = {
  type: Function,
  default() {
    return null;
  },
};

export default {
  components: { BModal, KAlertMsg },
  computed: {
    listeners() {
      // eslint-disable-next-line no-unused-vars
      let { ok, ...params } = this.$listeners;
      return params;
    },
  },
  methods: {
    beforeCloseHandle(bvModalEvt) {
      let lb = this.beforeClose();
      if (lb != null) {
        lb.then((needClose) => {
          if (needClose) {
            this.$refs["mdPop"].hide();
            this.$emit("ok", bvModalEvt);
          }
        });
      } else {
        lb = this.close();
        lb.then(() => {
          this.$refs["mdPop"].hide();
          this.$emit("ok", bvModalEvt);
        });
      }
    },

    onOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      let onOkFunction = this.onOkFunction();
      if (!onOkFunction) {
        if (this.checkSelected) {
          let inputs = this.$refs.spSlot.getElementsByTagName("input");
          let selCount = 0;
          for (let m of inputs) {
            if (m.type === "checkbox") {
              if (m.checked) {
                selCount++;
              }
            }
          }

          if (selCount === 0) {
            this.$refs.lblMsg.message(
              2,
              this.$g("const.warning.select-records")
            );
            return;
          }
        }
        if (this.beforeClose) {
          this.beforeCloseHandle(bvModalEvt);
        } else {
          this.$refs["mdPop"].hide();
          this.$emit("ok", bvModalEvt);
        }
      }
    },
    close() {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, 100);
      });
    },
  },
  props: {
    ...objProps,
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .modalOpenStatus {
    .modalDialog {
      height: calc(100% - 3.5rem);
      .modalContent.contentScroll {
        max-height: 100%;
        overflow: auto;
        .modal-body {
          overflow: auto;
        }
      }
    }
  }
}
</style>
