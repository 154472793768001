<script>
import { BCollapse } from "bootstrap-vue";
const objProps = Object.assign({}, BCollapse.options.props)
objProps.visible = {
  type: Boolean,
  default() {
    return true
  },
}
export default {
  props: {
    ...objProps,
  },
  extends: BCollapse
};
</script>