<template>
  <div class="layout-container">
    <header class="layout-header">
      <Logo />
      <div
        class="
          layout-header-info
          flex-column
          justify-content-center
          align-items-center
        "
      >
        <div>
          {{ $g("countyNameElections") }}
        </div>
        <div>
          {{ $g("website") }}&nbsp; {{ $g("email") }}{{ $g(":") }} &nbsp;
          {{ $g("emailInfo") }}
        </div>
      </div>
    </header>
    <div class="layer-container-fluid">
      <div class="layout-content">
        <div class="content-main">
          <div class="content-party">
            <div class="responseMain">
              <div v-if="showSubmit" :key="submitKey">
                <h4>{{ $g("surveyResponse") }}:</h4>
                <div
                  class="content-group p-3"
                  style="background-color: #f3f3f3"
                >
                  <p>
                    {{ respondStr }}
                  </p>
                  <KValidationObserver ref="observer">
                    <k-form-group
                      label-class="require-mark"
                      label-suffix="verificationCode"
                      label-for="VerificationCode"
                      label-align-md="right"
                      label-cols-md="4"
                      content-cols-md="8"
                    >
                      <div class="d-flex">
                        <img :src="codeImg" alt="No Image" :key="codeKey" />
                        <b-icon
                          icon="arrow-clockwise"
                          aria-hidden="true"
                          @click="refreshCode"
                          class="border rounded p-1 ml-2"
                          font-scale="2.2"
                          style="background-color: #fff"
                        />
                      </div>
                    </k-form-group>
                    <k-form-group
                      label-class="require-mark"
                      label-suffix="enterCode"
                      label-for="EnterCode"
                      label-align-md="right"
                      label-cols-md="4"
                      content-cols-md="8"
                    >
                      <KValidationProvider
                        :rules="{ required: true }"
                        v-slot="{ errors }"
                        slim
                        name="enterCode"
                      >
                        <k-alert-msg ref="lblMsg" />
                        <k-alert :show="errors.length > 0" variant="warning">
                          {{ errors[0] }}
                        </k-alert>
                        <k-form-input v-model="code" />
                      </KValidationProvider>
                    </k-form-group>
                  </KValidationObserver>
                  <k-form-group
                    label-for="noneBind"
                    label-align-md="right"
                    label-cols-md="4"
                    content-cols-md="8"
                  >
                    <k-button @click="onSubmit"> Submit </k-button>
                  </k-form-group>
                </div>
              </div>
              <div v-if="showChange" class="showChange">
                <p class="font-weight-bold">
                  {{ changeRespondStr }}
                </p>
                <k-button @click="changeResponse"> changeResponseNow </k-button>
                <k-button @click="closeWindow" class="ml-2"> cancel </k-button>
              </div>
              <div
                v-if="showResponse"
                style=" display: flex;flex-direction: column;"
              >
                <div style="font-size: 1.2rem; font-weight: bold">
                  {{ alreadyResponded }}
                </div>
                <div style="align-self: center;margin-top: 100px;">
                  <k-button @click="closeWindow" class="ml-2">
                    close
                  </k-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCaptcha, getResonseResult, verifyCode } from "../../api/message";
import { repResult } from "../../../../libs/global";
import storeage from "../../../../utils/storeage";
import Logo from "../../../../layout/components/logo";

export default {
  props: {},
  components: { Logo },
  data() {
    let isEvent = this.$route.meta.isEvent;
    return {
      isEvent: isEvent,
      electionId: isEvent ? storeage.getElectionId() : "",
      showSubmit: false,
      showChange: false,
      showResponse: false,
      codeKey: 0,
      submitKey: 0,
      alreadyResponded: "",
      changeRespondStr: "",
      code: "",
      surveyValue: "",
      respondStr: "",
    };
  },
  computed: {
    codeImg() {
      //?msgid=8d1eed8c-252d-48bc-f1bf-08d909ec0bba&rid=a882f17e-4dc1-4670-a477-879068020020&t=2&act=1
      let para = { ...this.para };
      para.key = this.codeKey;
      return getCaptcha(para);
    },
    para() {
      return {
        msgid: this.$route.query.msgid,
        rid: this.$route.query.rid,
        t: this.$route.query.t,
        act: this.$route.query.act,
        electionId: this.electionId,
      };
    },
  },
  mounted() {
    this.checkResonseResult();
  },
  methods: {
    async onSubmit() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        let para = { ...this.para, code: this.code };
        verifyCode(para).then((m) => {
          if (m.data.status === repResult.success) {
            this.showSubmit = false;
            this.checkResonseResult();
          } else {
            this.$refs.lblMsg.message(m.data.status, m.message);
            this.refreshCode();
          }
        });
      }
    },
    refreshCode() {
      this.codeKey++;
    },
    closeWindow() {
      open(location, "_self").close();
    },
    checkResonseResult() {
      let para = { ...this.para };
      getResonseResult(para).then((res) => {
        if (
          res.data.languageId &&
          res.data.languageId != "00000000-0000-0000-0000-000000000000" &&
          res.data.languageId != storeage.getLanguageId()
        ) {
          storeage.setLanguageId(res.data.languageId);
          this.$router.go(0);
        }
        if (res.data.act == this.$route.query.act) this.showResponse = true;
        else if (res.data.surveyValue) this.showChange = true;
        else this.showSubmit = true;

        this.alreadyResponded = res.data.alreadyRespondedStr;
        this.changeRespondStr = res.data.changeRespondStr;
        this.respondStr = res.data.respondStr;
      });
    },
    changeResponse() {
      this.showChange = false;
      this.showSubmit = true;
      this.submitKey++;
    },
  },
};
</script>

<style lang="scss" scoped>
.responseMain {
  width: 80%;
  max-width: 500px;
  margin: 0 auto;
  padding-top: 10%;
}
.showChange {
  border: 1px solid #dee2e6;
  padding: 40px 20px;
  border-radius: 5px;
}
</style>
