<template>
  <span>
    <span v-if="plaintext" class="col-form-label d-block">{{
      displayText
    }}</span>
    <treeselect
      v-else
      :value="value"
      :multiple="false"
      :options="storages"
      :disable-branch-nodes="disableBranchNodes"
      :disabled="disabled"
      :clearable="clearable"
      v-on="$listeners"
    />
  </span>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";

export default {
  components: { Treeselect },
  props: {
    value: {
      type: String,
      default() {
        return null;
      },
    },
    storages: {
      type: Array,
      default() {
        return [];
      },
    },
    clearable: {
      type: Boolean,
      default() {
        return false;
      },
    },
    disableBranchNodes: {
      type: Boolean,
      default() {
        return false;
      },
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
    plaintext: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  model: {
    prop: "value",
    event: "input",
  },
  data() {
    return {};
  },
  computed: {
    displayText() {
      if (!this.value || !this.storages || this.storages.length === 0) {
        return "";
      } else {
        let result = null;
        for (let item of this.storages) {
          result = this.getDisplayText(this.value, item);
          if (result != null) {
            break;
          }
        }
        return result;
      }
    },
  },
  methods: {
    getDisplayText(selValue, storage) {
      let result = null;
      if (storage.id == selValue) {
        result = storage.label;
      } else {
        if (storage.children) {
          for (let item of storage.children) {
            result = this.getDisplayText(selValue, item);
            if (result) {
              break;
            }
          }
        }
      }
      return result;
    },
  },
};
</script>
