import http from "../../../utils/http";
import globalEnv from "../../../libs/globalEnv";

export const getImportLogPage = (data) => {
  return http.post("/api/import/page", data, { loading: false, baseURL: globalEnv.VUE_APP_STD_URL });
};

export const getImportLogPageWorker = (data) => {
  return http.post("/api/import/page", data, { loading: false, baseURL: globalEnv.VUE_APP_STD_URL, systemPATH: "worker" });
};

export const getUploadInfo = (importType) => {
  return http.get("/api/import/info", { importType: importType }, {
    loading: false,
    baseURL: globalEnv.VUE_APP_STD_URL,
  });
};

export const uploadFiles = (files, importType, condition) => {

  let formdata = new FormData();
  files.forEach(file => {
    formdata.append("file", file, file.name);
  });
  formdata.append("importType", importType);
  if (condition && typeof (condition) === "object") {
    for (var key in condition) {
      formdata.append(key, condition[key]);
    }
  }

  var config = {};
  config.headers = { "Content-Type": "multipart/form-data" };
  config.loading = false;
  config.baseURL = globalEnv.VUE_APP_STD_URL;
  if(condition && condition.systemPATH){
    config.systemPATH = condition.systemPATH
  }
  if(condition && condition.electionId){
    formdata.append("eventId", condition.electionId);
  }
  return http.post((condition && condition.api) ? condition.api : "/api/import/import-file", formdata, config);
};

export const uploadFilesRemoteTask = (files, importType, condition) => {
  let formdata = new FormData();
  files.forEach(file => {
    formdata.append("file", file, file.name);
  });
  formdata.append("importType", importType);
  if (condition && typeof (condition) === "object") {
    for (var key in condition) {
      formdata.append(key, condition[key]);
    }
  }

  var config = {};
  config.headers = { "Content-Type": "multipart/form-data" };
  config.loading = false;
  config.baseURL = globalEnv.VUE_APP_STD_URL;
  if(condition && condition.systemPATH){
    config.systemPATH = condition.systemPATH
  }
  if(condition && condition.electionId){
    formdata.append("eventId", condition.electionId);
  }
  return http.post((condition && condition.api) ? condition.api : "/api/import/import-file/remote-task", formdata, config);
};

export const downloadTemplate = (importType, condition) => {
  var data = {};
  data.importType = importType;
  if(condition && condition.electionId){
    data.electionId = condition.electionId
  }
  return http.down("/api/import/template", data, {
    loading: false,
    baseURL: globalEnv.VUE_APP_STD_URL,
  });
};

export const exportErrorData = (key, condition) => {
  var config = {};
  if(condition && condition.systemPATH){
    config.systemPATH = condition.systemPATH
  }
  return http.down("/api/import/error-datainfo", { key: key}, {
    ...config,
    loading: false,
    baseURL: globalEnv.VUE_APP_STD_URL,
  });
};

