<template>
  <div class="mb-3">
    <div class="dynamic-row">
      <k-form-select
        v-model="infoData.field"
        :options="filedOptions"
        size="sm"
        class="mr-1 w-80"
        @change="onFieldChanged"
      />

      <k-form-select
        v-model="infoData.sign"
        :options="signOptions"
        size="sm"
        @change="onInfoChanged"
        class="w-auto mr-1"
      />

      <k-form-input
        v-if="selectFieldType === fieldType.int || selectFieldType === fieldType.decimal"
        v-model="infoData.value"
        :trim="true"
        type="number"
        size="sm"
        class="mr-1"
      />
      <KValidationProvider
        v-else-if="selectFieldType === fieldType.datetime"
        rules="validateDateFormat"
        slim
        name="date"
        v-slot="{ errors }"
      >
        <k-alert
          :show="errors.length > 0"
          variant="warning"
        >
          {{ errors[0] }}
        </k-alert>
        <k-form-datepicker
          v-model="infoData.value"
          size="sm"
          class="mr-1 d-inline-block"
          @change="onInfoChanged"
          class-name="noMargin"
        />
      </KValidationProvider>
      <k-form-input
        v-else
        v-model="infoData.value"
        :trim="true"
        size="sm"
        class="mr-1"
        @change="onInfoChanged"
      />
    </div>
    <div class="dynamic-row">
      <k-form-select
        v-model="infoData.condition"
        :options="conditionOptions"
        size="sm"
        @change="onInfoChanged"
        class="w-auto"
      />
      <span
        v-if="showDelete"
        :id="'tipDynamic_' + index"
        v-b-tooltip="{placement: 'bottomleft', trigger:'hover', title:$g('search.delete'),boundary:'window',container:'tipDynamic_' + index}"
      >
        <b-icon
          icon="trash"
          @click="onDeleteClick"
        />
      </span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    fields: {
      type: Array,
      default () {
        return [];
      },
    },
    index: {
      type: Number,
      default () {
        return 0;
      },
    },
    info: {
      type: Object,
      default () {
        return {};
      },
    },
    showDelete: {
      type: Boolean,
      default () {
        return false;
      },
    },
  },
  data () {
    return {
      infoData: this.info,
      signOptions: [],
      signOptionsSource: [
        { value: 1, text: "=" },
        { value: 2, text: "!=" },
        { value: 3, text: ">" },
        { value: 4, text: "<" },
        { value: 5, text: "⊇" },
        { value: 8, text: "!⊇" },
      ],
      conditionOptions: [
        { value: 0, text: "" },
        { value: 1, text: "And" },
        { value: 2, text: "Or" },
      ],
      selectFieldType: this.info.selectFieldType,
      fieldType: {
        string: 1,
        int: 2,
        datetime: 3,
        decimal: 4,
      },
    };
  },
  computed: {
    filedOptions () {
      let result = [];
      if (this.fields) {
        this.fields.forEach((e) => {
          if (e.isDynamicCondition) result.push({ value: e.key, text: e.label });
        });
      }
      result.splice(0, 0, { value: "", text: " " });
      return result;
    },
  },
  methods: {
    onFieldChanged (value) {
      this.fields.forEach((e) => {
        if (value === e.key) {
          this.selectFieldType = e.fieldType;
          this.infoData.selectFieldType = e.fieldType;
          this.refreshOptions(e.compareOpts);
        }
      });
      this.infoData.value = null;
      this.onInfoChanged();
    },
    refreshOptions(compareOpts) {
      this.signOptions = [];
      if (compareOpts && compareOpts.length > 0) {
        this.signOptionsSource.forEach((item) => {
          if (("," + compareOpts + ",").indexOf("," + item.text + ",") != -1) {
            this.signOptions.push(item);
          }
        });
      } else {
        this.signOptionsSource.forEach((item) => {
          this.signOptions.push(item);
        });
      }
      this.infoData.sign = this.signOptions[0]?.value
    },
    onInfoChanged () {
      this.$emit("conditionChange", this.index, this.infoData);
    },
    onDeleteClick () {
      this.$emit("conditionDelete", this.index);
    },
  },
  watch: {
    "info.selectFieldType": {
      handler (value) {
        this.selectFieldType = value;
      },
    },
    info (value) {
      this.infoData = value;
    },
  },
};
</script>
