var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('KValidationObserver',{ref:"observer",staticClass:"content-page"},[_c('konn-grid',{ref:"selectableTable",attrs:{"select-mode":_vm.selectMode,"has-action":false,"fields":_vm.fields,"data-source":_vm.fieldsSettingDataSource,"get-condition":_vm.getCondition,"has-pager":false,"auto-select":true,"id-field":"key","has-search-default-template":false},on:{"row-selected":_vm.onRowSelected},scopedSlots:_vm._u([{key:"cell(orderIndex)",fn:function(row){return [_c('KValidationProvider',{attrs:{"rules":{
            required: true,
            numberBetween: { minValue: 0, maxValue: 100 },
          },"slim":"","name":`${row.item.key}orderIndex`},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-input',{model:{value:(row.item.orderIndex),callback:function ($$v) {_vm.$set(row.item, "orderIndex", $$v)},expression:"row.item.orderIndex"}})]}}],null,true)})]}},{key:"cell(width)",fn:function(row){return [_c('KValidationProvider',{attrs:{"rules":{
            required: true,
            numberBetween: { minValue: 0, maxValue: 100 },
          },"slim":"","name":`${row.item.key}width`},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-input',{model:{value:(row.item.width),callback:function ($$v) {_vm.$set(row.item, "width", $$v)},expression:"row.item.width"}})]}}],null,true)})]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }