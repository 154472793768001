<template>
  <div v-frag>
    <k-form-group
      v-for="(item, index) in extFields"
      :key="index"
      :label-class="isRequired ? 'require-mark' : ''"
      :label-suffix="item.displayName"
      label-align-md="right"
      label-cols-md="3"
      content-cols-lg="6"
      content-cols-md="7"
      :no-locale="true"
      label-for="noneBind"
    >
      <KValidationProvider
        v-if="item.type === fieldType.string"
        :rules="{ required: isRequired, max: 200 }"
        v-slot="{ errors }"
        slim
        :name="`text${index}`"
      >
        <k-alert
          :show="errors.length > 0"
          variant="warning"
        >
          {{ errors[0] }}
        </k-alert>
        <k-form-input
          v-if="item.type === fieldType.string"
          v-model="item.inputValue"
          type="text"
          :plaintext="handleplaintext(item.isOnlyView)"
        />
      </KValidationProvider>
      <KValidationProvider
        v-if="item.type === fieldType.int"
        :rules="{
          required: isRequired,
          numberBetween: { minValue: -100000, maxValue: 100000 },
        }"
        v-slot="{ errors }"
        slim
        :name="`number${index}`"
      >
        <k-alert
          :show="errors.length > 0"
          variant="warning"
        >
          {{ errors[0] }}
        </k-alert>
        <k-form-input
          v-if="item.type === fieldType.int"
          v-model="item.inputValue"
          :plaintext="handleplaintext(item.isOnlyView)"
        />
      </KValidationProvider>
      <KValidationProvider
        v-if="item.type === fieldType.datetime"
        :rules="{
          required: isRequired,
          validateDateIsRequired: isRequired,
          validateDateFormat: true,
        }"
        slim
        :name="`datetime${index}`"
        v-slot="{ errors }"
      >
        <k-alert
          :show="errors.length > 0 && !plaintext"
          variant="warning"
        >
          {{ errors[0] }}
        </k-alert>
        <k-form-datepicker
          v-if="item.type === fieldType.datetime"
          v-model="item.inputValue"
          class="mb-2"
          :plaintext="handleplaintext(item.isOnlyView)"
        />
      </KValidationProvider>
      <KValidationProvider
        v-if="item.type === fieldType.phone"
        :rules="{ required: isRequired, phone: true, max: 50 }"
        v-slot="{ errors }"
        slim
        :name="`phone${index}`"
      >
        <k-alert
          :show="errors.length > 0"
          variant="warning"
        >
          {{ errors[0] }}
        </k-alert>
        <k-form-phone-input
          v-if="item.type === fieldType.phone"
          v-model="item.inputValue"
          :plaintext="handleplaintext(item.isOnlyView)"
          :is-phone="true"
        />
      </KValidationProvider>
      <KValidationProvider
        v-if="item.type === fieldType.email"
        :rules="{ required: isRequired, max: 200, email: true }"
        v-slot="{ errors }"
        slim
        :name="`email${index}`"
      >
        <k-alert
          :show="errors.length > 0"
          variant="warning"
        >
          {{ errors[0] }}
        </k-alert>
        <k-form-input
          v-if="item.type === fieldType.email"
          v-model="item.inputValue"
          type="text"
          :plaintext="handleplaintext(item.isOnlyView)"
        />
      </KValidationProvider>
    </k-form-group>
  </div>
</template>
<script>
import frag from "vue-frag";

export default {
  model: {
    prop: "value",
    event: "change",
  },
  directives: {
    frag,
  },
  props: {
    isPlaintextNeed: {
      type: Boolean,
      default: true,
    },
    pagekey: {
      type: String,
      default(){
        return null;
      },
    },
    value: {
      type: Object,
      default(){
        return null;
      },
    },
    plaintext: {
      type: Boolean,
      default(){
        return false;
      },
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleplaintext(text){
      if(this.isPlaintextNeed){
        return this.plaintext;
      } else {
        return text;
      }
    },
    getInputValue(form, efields){
      let result = {};
      if(efields){
        efields.forEach((el) => {
          result[el.fieldName] = el.inputValue;
        });
        Object.assign(form, result);
      }
      return form;
    },
  },
  data(){
    return {
      extFields: [],
      fieldType: {
        string: 1,
        int: 2,
        datetime: 3,
        phone: 8,
        email: 9,
      },
    };
  },
  computed: {},
  mounted(){
    let ef = this.value.extFields;
    if(ef){
      ef.forEach((el) => {
        let v = this.value[el.fieldName];
        if(v === 0 || v){
          el.inputValue = v;
        }
      });
    }
    this.extFields = ef;
  },
  watch: {
    value(){
      let f = this.value.extFields;
      if(f){
        f.forEach((e) => {
          let v = this.value[e.fieldName];
          if(v === 0 || v){
            e.inputValue = v;
          }
        });
      }
      this.extFields = f;
    },
    extFields: {
      handler: function (newVal){
        let merged = this.getInputValue(this.value, newVal);
        this.$emit("change", merged);
      },
      deep: true,
    },
  },
};
</script>
