<template>
  <div class="content-navTab">
    <b-tabs v-model="tabindex">
      <b-tab
        v-for="(item, index) in items"
        :title="item.title"
        :key="index"
        @click="clickTab(item)"
      />
    </b-tabs>
  </div>
</template>
<script>
import { DeliveryResults } from "../mixins/messagePage";
export default {
  mixins: [DeliveryResults],
  data() {
    return {
      tabindex: this.tabIndex,
      items: []
    };
  },
  methods: {
    clickTab(item) {
      this.$router.push({
        name: item.route,
        query: {
          id: this.$route.query.id,
          isView: this.$route.query.isView,
          hidePreNext: this.$route.query.hidePreNext,
          dbtn: this.$route.query.dbtn,
          deliveryType: this.$route.query.deliveryType,
          historyBack: this.$route.query.historyBack
        }
      });
    },
    addDeliveryDetailsTab() {
      this.items.push({
        title: this.$g("tabs.detail.deliveryDetails"),
        index: 1,
        route: this.getMessageDeliveryDetailsRoute()
      });
    }
  },
  props: {
    isNotification: {
      type: Boolean,
      default: false
    },
    tabIndex: {
      type: Number,
      required: true
    },
    isEvent: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  mounted() {
    this.items = [
      {
        title: this.$g("tabs.detail.deliveryReport"),
        index: 0,
        route: this.getMessageDeliveryReportRouteName()
      }
    ];
  }
};
</script>

