<template>
  <div class="backgroundGray">
    <KValidationObserver ref="observer" class="content-page">
      <k-form-group label-class="require-mark" label-suffix="name" label-align-md="right" label-cols-md="3"
        content-cols-lg="6" content-cols-md="7">
        <KValidationProvider :rules="{
          required: true,
          passiveMax: { length: 200 },
          remote: isNameExits,
        }" v-slot="{ errors }" slim name="name">
          <k-alert :show="errors.length > 0" variant="warning">
            {{ errors[0] }}
          </k-alert>
          <k-form-input id="input-code" v-model="form.templateName" :plaintext="false" />
        </KValidationProvider>
      </k-form-group>
      <k-form-group label-suffix="description" label-align-md="right" label-cols-md="3" content-cols-lg="6"
        content-cols-md="7">
        <KValidationProvider :rules="{
          max: 2000,
        }" v-slot="{ errors }" slim name="description">
          <k-alert :show="errors.length > 0" variant="warning">
            {{ errors[0] }}
          </k-alert>
          <k-form-textarea v-model="form.description" :plaintext="false" />
        </KValidationProvider>
      </k-form-group>
    </KValidationObserver>
  </div>
</template>

<script>
import { checktemplatenameexits, getTemplateType } from "../../../api/searchTemplate";
export default {
  props: {
    typeName: {
      type: String,
      default() {
        return "";
      },
    },
    reportId: {
      type: String,
      default() {
        return "";
      },
    },
    electionId: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      form: {
        reportId: this.reportId,
        templateType: 0,
        templateName: "",
        description: "",
      },
    };
  },
  mounted() {
    let condition = {
      typeName: this.typeName,
    };
    getTemplateType(condition).then((res) => {
      const { data } = res;
      this.form.templateType = data;
    });
  },
  methods: {
    async isNameExits() {
      let para = {};
      para.templateName = this.form.templateName;
      para.templateType = this.form.templateType;
      para.electionid = this.electionId;
      para.reportId = this.form.reportId;
      let result = null;
      await checktemplatenameexits(para).then(function (rep) {
        result = rep;
      });
      return result;
    },
    checkValid() {
      return this.$refs.observer.validate();
    },
    getFormData() {
      return this.form;
    },
  },
};
</script>
