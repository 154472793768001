var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('k-alert-msg',{ref:"lblMsg"}),_c('div',{staticClass:"photo-group mb-3 pb-2"},_vm._l((_vm.photoes),function(photo,index){return _c('div',{key:index,staticClass:"photo-group-item"},[_c('img-photo',{attrs:{"src":photo.base64String}}),(!_vm.isPlaintext)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
          placement: 'bottomleft',
          trigger: 'hover',
          title: _vm.$g('icon.deleteToolTip'),
          boundary: 'window',
          container: 'tipImage_' + index,
        }),expression:"{\n          placement: 'bottomleft',\n          trigger: 'hover',\n          title: $g('icon.deleteToolTip'),\n          boundary: 'window',\n          container: 'tipImage_' + index,\n        }"}],staticClass:"photo-trash",attrs:{"id":'tipImage_' + index}},[_c('b-icon',{attrs:{"icon":"trash"},on:{"click":function($event){return _vm.onDeletePhoto(photo)}}})],1):_vm._e(),_vm._t("photoInfo",null,{"itemprop":photo})],2)}),0),(!_vm.isPlaintext)?_c('KValidationObserver',{ref:"observer",staticClass:"content-page"},[_c('k-form',{ref:"form",staticClass:"content-form",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('k-form-group',{attrs:{"label-class":_vm.labelClass,"label-suffix":"document.file","label-align-md":"right","content-cols-lg":"6","content-cols-md":"7"}},[_c('KValidationProvider',{attrs:{"rules":{
            required: _vm.isRequired,
            acceptFileType: _vm.accept,
            maxFileSize: _vm.size * 1024,
          },"slim":"","name":_vm.$g('document.file')},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0 && _vm.isShowErrors,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('common-upload',{ref:"fileinput",attrs:{"accept":_vm.accept,"size":_vm.size,"on-delete":_vm.onDelete,"is-multiple":_vm.isMultiple,"placeholder":_vm.placeholder,"show-message":false,"is-use-internal-validate":false},scopedSlots:_vm._u([{key:"btnGroup",fn:function(){return [_c('k-button',{staticClass:"mb-1",attrs:{"type":"submit"}},[_vm._v(" buttons.upload ")])]},proxy:true}],null,true),model:{value:(_vm.form.file),callback:function ($$v) {_vm.$set(_vm.form, "file", $$v)},expression:"form.file"}})]}}],null,false,956629642)})],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }