const state = {
  pageState: {},
  exportCondition: {}
}

const mutations = {
  SET_PAGE_STATE(state1, condition) {
    state1.pageState = condition
  },
  SET_EXPORT_STATE(state, condition) {
    state.exportCondition = condition
  }
}

const actions = {
  savePageState({ commit, rootState }, para) {
    let listId = para.listId
    let condition = para.condition
    if (listId && condition) {
      let ps = rootState.page.pageState
      if (!ps) {
        ps = {}
      }
      condition.modifyTime = new Date().getTime()
      ps[listId] = condition
      commit('SET_PAGE_STATE', ps)
    }
    commit('SET_EXPORT_STATE', para.exportCondition)
  },
  updateItems({ commit, rootState }, para) {
    let listId = para.listId
    let ps = rootState.page.pageState
    if (ps && ps[listId].pageAddtional) {
      ps[listId].pageAddtional.ids = para.items
      ps[listId].pageAddtional.currentPage = para.currentPage
      commit('SET_PAGE_STATE', ps)
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
