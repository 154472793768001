const routes = [
  {
    name: 'messages',
    path: '/',
    meta: {
      title: 'messages',
      requiresAuth: false,
    },
    component: () => import('@/views/messages/messages-list'),
    route: true,
  },
]

export default [
  {
    path: '/messages',
    route: false,
    component: () => import('@/layout/brief'),
    children: [...routes],
  },
]
