<template>
  <div style="white-space: normal">
    <div v-for="(progress, index) in progressInfo" :key="index">
      <div>
        <span>{{ progress.message }}</span>
      </div>
      <div>
        <span>{{ progress.status }}</span
        >&nbsp;&nbsp;&nbsp;<span>{{ handlePro(progress.progressRate) }}%</span>
      </div>
    </div>
  </div>
</template>
<script>
import { getRemoteTaskStatus, downloadFile } from "../../api/remoteTask";
import { startTaskProgress } from "./remoteTaskStarter";
import button from "../../k-components/button/button.vue";
import eventBus from "../../utils/eventBus";

export default {
  components: { button },
  data() {
    return {
      remoteTaskStatusUrl: "api/get-remote-task-status",
      downloadUrl: "api/download-payroll-export",
      baseUrl: null,
      systemPATH: null,
      description: "",
      status: "",
      progress: 0,
      taskTimeId: 0,
      clearDescTimespan: 10,
      emptyLoopCount: 0,
      isShow: false,
      noDataCount: 0,
      enumResponseResult: {
        NotStarted: 0,
        Running: 1,
        Stopped: 4,
        Finished: 16,
        Abnormal: 1024,
        Removable: 1044,
      },
      enuResultType: {
        None: 0,
        File: 1,
        String: 2,
        ByteArray: 4,
        Post: 8,
        Object: 16,
      },
      progressInfo: [],
    };
  },
  methods: {
    startProgess() {
      var instaince = this;
      this.taskTimeId = setInterval(() => {
        instaince.loopRunning();
      }, 2000);
    },
    stopProgess() {
      if (this.taskTimeId) {
        clearInterval(this.taskTimeId);
        this.taskTimeId = null;
        this.isShow = false;
      }
    },
    getTaskInfoFunc() {
      if (window.taskIds && window.taskIds.length > 0) {
        getRemoteTaskStatus(
          this.remoteTaskStatusUrl,
          window.taskIds,
          this.baseUrl,
          this.systemPATH
        ).then((res) => {
          if (res) {
            this.setTaskInfoOk(res);
          }
        });
      } else {
        this.emptyLoopCount++;
        if (this.emptyLoopCount > this.clearDescTimespan) {
          this.progressInfo = [];
        }
      }
    },
    loopRunning() {
      this.getTaskInfoFunc();
    },
    setTaskInfoOk(res) {
      if (!res.data || !res.data.info || !res.data.info.length) {
        this.noDataCount++;
        if (this.noDataCount > 8) {
          window.taskIds = [];
        }
      } else {
        this.emptyLoopCount = 0;
        this.isShow = true;
        this.noDataCount = 0;
        this.progressInfo = res.data?.info;
        this.progressInfo.forEach((p) => {
          switch (p.taskStatus) {
            case this.enumResponseResult.Finished:
            case this.enumResponseResult.Removable:
              var hasTask = window.taskIds.some(
                (f) => f.taskName == p.taskName
              );
              if (hasTask) {
                this.remove(p.taskName);
                if (p.resultObj && p.resultObj.isRedirect) {
                  this.remoteTaskStatusUrl = p.resultObj.remoteTaskStatusUrl;
                  this.downloadUrl = p.resultObj.downloadUrl;
                  this.baseUrl = p.resultObj.baseUrl;
                  this.systemPATH = p.resultObj.systemPATH;
                  startTaskProgress(p.resultObj.taskId);
                } else {
                  if (p.resultType == this.enuResultType.File) {
                    downloadFile(this.downloadUrl, p)
                      .then((data) => {})
                      .catch((err) => {
                        console.warn(err);
                      });
                  }
                  eventBus.$emit("taskFinished", p);
                }
              }
          }
        });
      }
    },
    remove(taskName) {
      var delIndex = -1;
      for (var i = 0; i < window.taskIds.length; i++) {
        if (window.taskIds[i].taskName == taskName) {
          delIndex = i;
          break;
        }
      }
      if (delIndex != -1) {
        window.taskIds.splice(delIndex, 1);
      }
    },
    handlePro(num) {
      let nums = num != 0 && num ? num * 100 : 0;
      return nums.toFixed(2);
    },
  },
  beforeDestroy() {
    this.stopProgess();
    eventBus.$off("urlChanged");
  },
  mounted() {
    this.startProgess();
    eventBus.$on(
      "urlChanged",
      (remoteTaskStatusUrl, downloadUrl, systemPATH) => {
        this.remoteTaskStatusUrl = remoteTaskStatusUrl;
        this.downloadUrl = downloadUrl;
        this.systemPATH = systemPATH;
      }
    );
  },
};
</script>
