<template>
  <div>
    <div class="form-inline mb-2">
      <k-form-group
        label-suffix="votingStations.name"
        label-for="search-area-name"
      >
        <k-form-input
          id="search-area-name"
          v-model="searchName"
          class="mr-1"
        />
        <k-button @click="search">
          votingStations.search
        </k-button>
      </k-form-group>
    </div>
    <konn-grid
      :select-mode="selectMode"
      :has-action="hasAction"
      :fields="fields"
      ref="selectableTable"
      :data-source="dataSource"
      :get-condition="getCondition"
      @row-selected="onRowSelected"
      grid-sort-by="code"
      id-field="votingStationId"
    />
  </div>
</template>
<script>
import { getVotingStationPageAsync } from "../api/messageGroup";
import storeage from "../../../utils/storeage";

export default {
  props: {
    locationTypeId: {
      type: Array,
      default () {
        return [];
      },
    },
    votingStationCategary: {
      type: Number,
      default () {
        return 0;
      },
    },
    exceptVotingStationIDList: {
      type: Array,
      default () {
        return [];
      },
    },
    redistributionBoundaryId: {
      type: String,
      default () {
        return null;
      },
    },
    usage: {
      type: Number,
      default () {
        return 0;
      },
    },
    isEvent: {
      type: Boolean,
      default () {
        return false;
      },
    },
  },
  data () {
    return {
      searchName: "",
      selectMode: "multi",
      selected: [],
      hasAction: false,
      fields: [
        {
          key: "code",
          label: "votingStations.id",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "name",
          label: "votingStations.name",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "locationTypeDescription",
          label: "votingStations.type",
          sortable: false,
        },
        {
          key: "usage",
          label: "votingStations.usage",
          sortable: false,
        },
        {
          key: "status",
          label: "votingStations.status",
          sortable: false,
        },
        {
          key: "size",
          label: "votingStations.size",
          sortable: false,
        },
      ],
      electionId: this.isEvent ? storeage.getElectionId() : "",
    };
  },
  computed: {
    dataSource: function () {
      return getVotingStationPageAsync;
    },
  },

  methods: {
    onRowSelected (items) {
      this.selected = items;
    },
    getCondition () {
      return {
        name: this.searchName.trim(),
        electionId: this.electionId,
        locationTypeId: "", //this.locationTypeId,
        votingStationCategary: this.votingStationCategary,
        exceptVotingStationIDList: this.exceptVotingStationIDList,
        redistributionBoundaryId: this.redistributionBoundaryId,
        usage: this.usage,
      };
    },

    getLocationTypeId () {
      return this.locationTypeId;
    },
    getVotingStationCategary () {
      return this.votingStationCategary;
    },
    getSelected () {
      if (this.selected && this.selected.length > 0) {
        return this.selected;
      } else {
        alert(this.$g("const.warning.select-records"));
      }
    },
    getSelectedIds () {
      let items = this.$refs.selectableTable.getSelectedIds("votingStationId");

      if (items && items.length > 0) {
        return items;
      } else {
        alert(this.$g("const.warning.select-records"));
        this.$alert({
          title: this.$g("const.confirm.confirm"),
          content: this.$g("const.warning.select-records"),
          okText: this.$g("const.confirm.ok"),
        });
      }
    },

    search: function () {
      this.$refs.selectableTable.pageChange(1);
    },
  },
};
</script>
