<template>
  <div>
    <b-tabs v-model="tabindex">
      <b-tab
        v-for="(item, index) in items"
        :title="item.title"
        :key="index"
        @click="clickTab(item)"
      />
    </b-tabs>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tabindex: this.tabIndex,
      items: [],
    };
  },
  methods: {
    clickTab(item) {
      if (!this.isPlainText && item.route === this.$route.name) {
        // this.$router.replace({
        //   name: "refresh",
        // });
      } else {
        this.$router.push({
          name: item.route,
          query: {
            id: this.$route.query.id,
            isView: this.isPlainText ? "1" : "0",
            hidePreNext: this.$route.query.hidePreNext,
            dbtn: this.$route.query.dbtn,
          },
        });
      }
    },
  },
  props: {
    tabIndex: {
      type: Number,
      required: true,
    },
    isPlainText: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isEvent: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  mounted() {
    let add = this.isEvent ? "eleMessageGroupAdd" : "messageGroupAdd";
    let edit = this.isEvent ? "eleMessageGroupEdit" : "messageGroupEdit";
    let groupViewRouter = this.isEvent
      ? "eleMessageGroupView"
      : "messageGroupView";
    let groupEditRouter = this.$route.query.id != null ? edit : add;
    let router = this.isPlainText ? groupViewRouter : groupEditRouter;
    const tabItems = [
      {
        title: this.$g("tabs.detail.basicInfo"),
        index: 0,
        route: router,
      },
    ];
    if (this.$route.query.id != null) {
      tabItems.push({
        title: this.$g("tabs.detail.logs"),
        index: 1,
        route: this.isEvent ? "eleMessageGroupLog" : "messageGroupLog",
      });
    }

    this.items = tabItems;
  },
};
</script>
