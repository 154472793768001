import http from "../../../utils/http";
import globalEnv from "../../../libs/globalEnv";

export const getGroupDropdown = (electionId) => {
  return http.get("/api/msggroup/action/getgroupdropdown", { electionId },
    {
      loading: false,
      baseURL: globalEnv.VUE_APP_STD_URL,
    });
};

//bau
export const getGroupMatchingList = (data) => {
  return http.post(data.electionId ? "/api/elemsggroup/countdetailpage" : "/api/msggroup/countdetailpage", data, {
    loading: true,
    baseURL: globalEnv.VUE_APP_STD_URL,
  });
};

export const getGroupSearchBarList = (data) => {
  return http.post("/api/msggroup/actions/search-bar/search", data, {
    loading: false,
    baseURL: globalEnv.VUE_APP_STD_URL,
  });
};

export const getEleGroupSearchBarList = (data) => {
  return http.post("/api/elemsggroup/actions/search-bar/search", data, {
    loading: false,
    baseURL: globalEnv.VUE_APP_STD_URL,
  });
};

export const getVotingStationPageAsync = (data) => {
  return http.post(data.electionId ? "/api/CommunicationExtentions/action/elegetvotingstationpageasync" :
    "/api/CommunicationExtentions/action/getvotingstationpageasync", data, {
      loading: false,
      useBody: true,
      baseURL: globalEnv.VUE_APP_STD_URL,
    },
  );
};

export const getGroupList = (data) => {
  return http.post(data.electionId ? "/api/elemsggroup/page" : "/api/msggroup/page", data,
    {
      loading: true,
      baseURL: globalEnv.VUE_APP_STD_URL,
    });
};

//event
export const getBookingStatus = () => {
  return http.get("/api/enum/booking-status");
};

export const getSizes = () => {
  return http.get("/api/enum/votingstation-sizes");
};

// bau and event
export const getGroup = (id) => {
  return http.get(`/api/msggroup/${id}`, {}, { loading: false, baseURL: globalEnv.VUE_APP_STD_URL });
};


export const checkGroupNameUsed = (data) => {
  return http.post("/api/msggroup/actions/check-groupname-used", data, {
    loading: false,
    baseURL: globalEnv.VUE_APP_STD_URL,
  });
};

export const delGroup = (data) => {
  return http.delete("/api/msggroup/deleteGroup", data, {
    useBody: true,
    baseURL: globalEnv.VUE_APP_STD_URL,
  });
};

export const saveGroup = (data) => {
  let url = "/api/msggroup";
  if(data.id){
    return http.post(url, data, {
      loading: false,
      useBody: true,
      baseURL: globalEnv.VUE_APP_STD_URL,
    });
  } else {
    return http.put(url, data, {
      loading: false,
      useBody: true,
      baseURL: globalEnv.VUE_APP_STD_URL,
    });
  }
};

export const getFacilityStatus = (isEvent) => {
  return http.get(isEvent ? "/api/enum/elefacility-status" : "/api/enum/facility-status", {}, {
    loading: false,
    baseURL: globalEnv.VUE_APP_STD_URL,
  });
};

export const getLocationTypes = () => {
  return http.get("/api/facilities/location-types", {}, { loading: false, baseURL: globalEnv.VUE_APP_STD_URL });
};

export const getBoundaryType = (data) => {
  if(data.electionId){
    return http.post("/api/CommunicationExtentions/action/elegetboundarytype", data, {
      loading: false,
      baseURL: globalEnv.VUE_APP_STD_URL,
    });
  } else {
    return http.post("/api/CommunicationExtentions/action/getboundarytype", data, {
      loading: false,
      baseURL: globalEnv.VUE_APP_STD_URL,
    });
  }
};
