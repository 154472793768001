<template>
  <div>
    <div class="content-group">
      <k-form-group
        label-align-md="right"
        label-cols-md="11"
        content-cols-lg="4"
        content-cols-md="6"
        v-if="!isPlainText"
      >
        <KValidationProvider
          ref="fileProvider"
          :rules="{
            required: false,
            acceptFileType: accept,
            maxFileSize: size * 1024,
          }"
          v-slot="{ errors }"
          slim
          :name="$g('document.file')"
        >
          <k-alert
            :show="errors.length > 0"
            variant="warning"
          >
            {{ errors[0] }}
          </k-alert>

          <common-upload
            ref="fileinput"
            v-model="form.files"
            :accept="accept"
            :size="size"
            :is-multiple="true"
            :on-delete="onDelete"
            :placeholder="placeholder"
            :show-message="false"
            :key="uploadKey"
            :is-use-internal-validate="false"
          >
            <template
              #btnGroup
              v-if="isShowUpload"
            >
              <k-button @click="uploadFile">
                upload
              </k-button>
            </template>
          </common-upload>
        </KValidationProvider>
      </k-form-group>
      <k-form-group
        label-align-md="left"
        label-cols-md="0"
        content-cols-lg="4"
        content-cols-md="5"
        v-if="showOperate"
      >
        <div
          class="hand right"
          v-if="isShowDocumentDeleteBtn"
        >
          <b-icon-delete @click="onDeleteClick" />
        </div>
      </k-form-group>
      <konn-grid
        ref="selectableTable"
        :has-check-box="!isPlainText"
        :has-action="!isPlainText && isShowDocumentDeleteBtn"
        :fields="fields"
        :row-read-only="true"
        :get-condition="getCondition"
        :data-source="getDocumentsList"
        @row-selected="onRowSelected"
      >
        <template #cell(fileName)="row">
          <k-link @click="downloadPath(row.item.documentId)">
            {{ row.item.fileName }}
          </k-link>
        </template>
        <template #cell(actions)="row">
          <div v-if="row.item.readonly" />

          <k-link
            v-else
            @click="onDeleteRow(row.item.id)"
          >
            {{ $g("search.delete") }}
          </k-link>
        </template>
      </konn-grid>
    </div>
  </div>
</template>

<script>
import {
  addDocumentList,
  getDocuments,
  deleteDocuments,
  downloadDocumentUrl,
  isExit,
} from "./api";
import { getUploadConfig } from "../../api/upload.js";
import storeage from "../../utils/storeage";
import { repResult } from "../../libs/global";
import { has } from "../../utils/permission/operatePermission.js";

export default {
  props: {
    logType: {
      type: Number,
      default(){
        return 0;
      },
    },
    docType: {
      type: Number,
      default(){
        return 0;
      },
    },
    id: {
      type: String,
      default(){
        return "";
      },
    },
    showOperate: {
      type: Boolean,
      default(){
        return false;
      },
    },
    isPlainText: {
      type: Boolean,
      default(){
        return false;
      },
    },
    addDocumentListApi: {
      type: Function,
      default: addDocumentList,
    },
    getDocumentsApi: {
      type: Function,
      default: getDocuments,
    },
    deleteDocumentsApi: {
      type: Function,
      default: deleteDocuments,
    },
    downloadDocumentUrlApi: {
      type: Function,
      default: downloadDocumentUrl,
    },
    isExitApi: {
      type: Function,
      default: isExit,
    },
    isShowUpload: {
      type: Boolean,
      default(){
        return false;
      },
    },
  },
  data(){
    return {
      selected: [],
      selectMode: "multi",
      fields: [
        { key: "fileName", label: "document.document", width: "40%" },
        {
          key: "createTimeString",
          label: "document.createtime",
          width: "40%",
        },
      ],
      isExit,
      isShowDocumentDeleteBtn: true,
      accept: ".pdf, .doc, .docx, .jpg, .png",
      size: 10 * 1024,
      form: {
        title: [],
        files: [],
      },
      status: 0,
      message: "",
      placeholder: "No file chosen",
      electionId: storeage.getElectionId(),
      uploadKey: 0,
      isDoSave: false,
      originDataString: null,
      isBau: this.$route.meta.isBau,
    };
  },
  computed: {
    getDocumentsList: function (){
      return this.getDocumentsApi;
    },
  },
  methods: {
    async uploadFile(){
      const isValid = await this.$refs.fileProvider.validate();
      if(isValid.valid){
        this.onSubmit();
      }
    },
    refresh(){
      this.$refs.selectableTable.refresh();
    },
    downloadPath(documentId){
      let model = {
        DocRequestType: this.docType,
        LogRequestType: this.logType,
        DocumentId: documentId,
        RefId: this.id,
      };
      console.log(this.isExitApi);
      this.isExitApi(model).then((m) => {
        if(m.data.status === repResult.success){
          this.downloadDocumentUrlApi(model);
        } else {
          this.$emit("show-message", m.data.status, m.message);
        }
      });
    },
    onRowSelected(items){
      this.selected = items;
    },
    getCondition(){
      return {
        DocRequestType: this.docType,
        RefId: this.id,
        Sort: "createtime",
        Order: "Desc",
        PermissionCode: this.$route.meta.permissionCode,
      };
    },
    onDeleteClick(){
      let pmOpt = this.$refs.selectableTable.confirmOpt(this.$g("const.confirm.delete"));
      if(pmOpt == null){
        return;
      }
      pmOpt.then(() => {
        let sitems = this.$refs.selectableTable.getSelectedItems();
        let delIds = [];
        sitems.forEach(function (val){
          delIds.push(val.id);
        });
        let model = {
          RefId: this.id,
          DocRequestType: this.docType,
          LogRequestType: this.logType,
          Ids: delIds,
        };
        this.deleteDocumentsApi(model).then((m) => {
          this.$refs.selectableTable.refresh();
          this.$emit("show-message", m.data.status, m.message);
        });
      });
    },
    onDeleteRow(id){
      let pmOpt = this.$confirm({
        title: this.$g("const.confirm.confirm"),
        content: this.$g("const.confirm.delete"),
        cancelType: "secondary",
        okText: this.$g("const.confirm.yes"),
        cancelText: this.$g("const.confirm.no"),
      });
      if(pmOpt == null){
        return;
      }
      pmOpt.then(() => {
        let delIds = [];
        delIds.push(id);
        let model = {
          RefId: this.id,
          DocRequestType: this.docType,
          LogRequestType: this.logType,
          Ids: delIds,
        };
        this.deleteDocumentsApi(model).then((m) => {
          this.$refs.selectableTable.refresh();
          this.$emit("show-message", m.data.status, m.message);
        });
      });
    },
    onSubmit(){
      if(this.form.files.length === 0 || this.isDoSave){
        let m = { data: { status: 1 } };
        this.$emit("finish-upload", m);
        return;
      }
      this.isDoSave = true;
      let opt;
      var formData = new FormData();
      opt = this.addDocumentListApi;
      for (let i = 0; i < this.form.files.length; i ++) {
        formData.append("Titles", "FileTitle");
        formData.append("Files", this.form.files[i]);
      }
      formData.append("RefId", this.id);
      formData.append("DocRequestType", this.docType);
      formData.append("LogRequestType", this.logType);
      console.log(this.formData);
      if(this.electionId){
        formData.append("ElectionId", this.electionId);
      }
      opt(formData).then((m) => {
        this.isDoSave = false;
        this.status = m.data.status;
        this.message = m.message;
        this.$emit("show-message", m.data.status, m.message);
        this.$refs.fileinput.clear();
        this.$refs.selectableTable.refresh();
        this.$emit("finish-upload", m);
      });
    },
    onDelete(file){
      if(this.form.files.length > 0){
        for (let i = 0; i < this.form.files.length; i ++) {
          if(this.form.files[i].name == file.name) this.form.file.delete(i);
        }
      }
    },
  },
  watch: {
    isPlainText(){
      this.$refs.selectableTable.refresh();
    },
  },
  mounted(){
    if(this.$route.query.status && this.$route.query.message){
      this.$emit("show-message", this.$route.query.status, this.$route.query.message);
    }
    getUploadConfig("uploaddoc").then((data) => {
      this.accept = data.data.acceptTypes;
      this.size = data.data.maxSize / 1024;
    });

    // eslint-disable-next-line no-prototype-builtins
    if(
        this.$route.meta.hasOwnProperty("permissionCode") &&
        this.$route.meta.permissionCode
    ){
      let deleteCode = this.$route.meta.permissionCode + "-Delete";
      this.isShowDocumentDeleteBtn = has(deleteCode);
    }
    if(this.$route.meta.hasOwnProperty("isView") && this.$route.meta.isView == "1"){
      this.isShowDocumentDeleteBtn = false;
    }
  },
};
</script>
<style scoped>
.hand:hover {
  cursor: pointer;
}

.right {
  float: right;
}
</style>
