import http from "../../../utils/http";
import globalEnv from "../../../libs/globalEnv";

export const getUseFor = (data) => {
  return http.get(`/api/msgtemplate/enum/usefor`, data)
}

export const getCategories = (data) => {
  return http.post(`/api/msgtemplate/drop-models/category`, data, {
    useBody: true,
  });
};

export const getTemplateList = (data) => {
  return http.post("/api/msgtemplate/page", data);
};

export const getTemplateSearchBarList = (data) => {
  return http.post("/api/msgtemplate/actions/search-bar/search", data, {
    loading: false,
    baseURL: globalEnv.VUE_APP_STD_URL,
  });
};

export const getDocumentList = (data) => {
  return http.post("/api/msgtemplate/docpage", data);
};

export const getSumcount = (data) => {
  return http.post("/api/msgtemplate/sumcount", data, {
    loading: false,
    baseURL: globalEnv.VUE_APP_STD_URL,
  });
};

export const generatePDFDocument = (data) => {
  return http.post("/api/msgtemplate/action/generatepdfdocument", data, { loading: true });
};

export const getTemplateAllList = (data) => {
  return http.post("/api/msgtemplate/pageall", data, {
    loading: false,
    baseURL: globalEnv.VUE_APP_STD_URL,
  });
};

export const getDocAllList = (data) => {
  return http.post("/api/msgtemplate/docallpage", data, {
    loading: false,
    baseURL: globalEnv.VUE_APP_STD_URL,
  });
};

export const delTemplate = (ids) => {
  return http.delete(
    "/api/msgtemplate",
    { Ids: ids },
    { loading: false, baseURL: globalEnv.VUE_APP_STD_URL },
  );
};

export const checkSubjectExits = (data) => {
  return http.post("/api/msgtemplate/actions/check-subject-used", data, {
    loading: false,
    baseURL: globalEnv.VUE_APP_STD_URL,
  });
};

export const getTemplate = (id, docId, isNotification) => {
  let api = `/api/msgtemplate?id=${id}`;
  if(docId){
    api = `/api/msgtemplate?id=${id}&docId=${docId}`;
  }
  if(isNotification){
    api = `${api}&isNotification=${isNotification}`;
  }
  return http.get(api);
};

export const getTemplateDetail = ({ id, languageId }) => {
  return http.get(`/api/msgtemplate/${id}/${languageId}`);
};

export const getTemplateDetailUseFor = (useFor) => {
  return http.get(
    `/api/email-header-template/actions/get-template-drop-down/${useFor}`,
  );
};
export const getTemplateDetailHeaderDisplayDropDown = () => {
  return http.get(
    `/api/email-header-template/actions/get-header-display-drop-down`,
  );
};

export const getTemplateDetailFooterDisplayDropDown = () => {
  return http.get(
    `/api/email-header-template/actions/get-footer-display-drop-down`,
  );
};

export const addTemplate = (data) => {
  return http.post(`/api/msgtemplate`, data, {
    loading: true,
    baseURL: globalEnv.VUE_APP_STD_URL,
  });
};

export const putTemplate = (data) => {
  return http.put(`/api/msgtemplate`, data, {
    loading: true,
    baseURL: globalEnv.VUE_APP_STD_URL,
  });
};

export const downFilesById = (documentId) => {
  return http.down(
    "/api/msgtemplate/actions/downfilesbyid",
    { documentId },
    {
      loading: false,
      baseURL: globalEnv.VUE_APP_STD_URL,
    },
  );
};
