import http from "../../utils/http";
import globalEnv from "../../libs/globalEnv";

export const getElections = (status) => {
  let relStatus = "";
  if (status) {
    relStatus = status
  }
  return http.get(`/api/elections/drop-modelsByText?status=` + relStatus, null,{
    systemPATH: "location",
    loading: false,
    baseURL: globalEnv.VUE_APP_STD_URL,
  });
};