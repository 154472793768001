<template>
  <b-modal
    :title="title"
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    @hidden="sendResponse"
    @shown="shown"
    :size="modalSize"
    v-model="showModal"
  >
    <div v-if="isHtml" v-html="content"></div>
    <p v-else>{{ content }}</p>
    <template v-if="type === TYPES.PROMPT">
      <b-form-group
        :invalid-feedback="isExist(inputError) ? inputError : ''"
        :valid-feedback="validFeedback"
        :state="!inputNotValid ? inputNotValid : null"
      >
        <b-form-input
          ref="input"
          :type="inputType"
          v-model="input"
          :state="!inputNotValid ? inputNotValid : null"
          required
          data-action="auto-focus"
        ></b-form-input>
      </b-form-group>
    </template>
    <div slot="modal-footer" v-if="type === TYPES.ALERT">
      <b-button
        ref="ok1"
        class="float-right m-1"
        :variant="okType"
        data-action="auto-focus"
        @click="toggle($event, 'ok')"
      >
        {{ okBtnText }}
      </b-button>
    </div>
    <div slot="modal-footer" v-else>
      <b-button
        class="float-right m-1"
        :variant="cancelType"
        @click="toggle($event, 'cancel')"
      >
        {{ cancelBtnText }}
      </b-button>
      <b-button
        ref="ok2"
        class="float-right m-1"
        v-if="type === TYPES.CONFIRM"
        :variant="okType"
        data-action="auto-focus"
        @click="toggle($event, 'ok')"
      >
        {{ okBtnText }}
      </b-button>
      <b-button
        class="float-right m-1"
        v-else
        :variant="okType"
        @click="validate"
      >
        {{ okBtnText }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { TYPES } from "./constants";
import { isExist } from "../utils/objectUtils";
export default {
  name: "boostrapMsgBox",
  props: {
    title: {
      type: String,
      default: "Title",
    },
    isHtml: {
      type: Boolean,
      default: false,
    },
    content: {
      type: String,
      default: "Empty",
    },
    inputType: {
      type: String,
      default: "text",
    },
    okText: {
      type: String,
      default: "Ok",
    },
    okType: {
      type: String,
      default: "primary",
    },
    cancelText: {
      type: String,
      default: "Cancel",
    },
    cancelType: {
      type: String,
      default: "default",
    },
    cb: {
      type: Function,
      required: true,
    },
    validates: {
      type: Function,
      default: () => null,
    },
    type: {
      type: Number,
      required: true,
      default: TYPES.ALERT,
    },
    defaultInputValue: {
      type: String,
      default: "",
    },
    modalSize: {
      type: String,
      default: "",
    },
  },
  data: function () {
    return {
      TYPES,
      showModal: false,
      input: "",
      dirty: false,
      emitValue: "",
      returnValue: "",
    };
  },
  computed: {
    inputError: function () {
      return this.validates(this.input);
    },
    inputNotValid: function () {
      return !this.isExist(this.inputError);
    },
    okBtnText: function () {
      return this.okText || "Ok";
    },
    cancelBtnText: function () {
      return this.cancelText || "Cancel";
    },
    validFeedback: function () {
      return !this.inputNotValid ? "Correcto" : "";
    },
  },
  methods: {
    isExist,
    sendResponse: function () {
      this.cb(this.returnValue);
    },
    toggle: function ($event, value) {
      this.returnValue = value;
      this.showModal = !this.showModal;
    },
    validate: function () {
      this.dirty = true;
      if (!this.isExist(this.inputError)) {
        this.toggle(false, { value: this.input });
      }
    },
    shown(){
      this.$refs.ok1?.focus();
      this.$refs.ok2?.focus();
    }
  },
  mounted: function () {
    document.querySelector(":focus")?.blur();
    this.showModal = true;
    if (this.defaultInputValue) {
      this.input = this.defaultInputValue;
    }
  },
};
</script>

<style scoped>
</style>
