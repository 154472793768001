const pageNavUtil = {
  getRecentList(pageState, expectListId) {
    let lastList = null
    if (pageState) {
      let keys = Object.keys(pageState)
      if (pageState[expectListId]) {
        lastList = pageState[expectListId]
        lastList.listId = expectListId
        return lastList
      }
      let lastDate = 0
      keys.forEach((m) => {
        let modifyTime = pageState[m].modifyTime
        if (modifyTime && modifyTime > lastDate) {
          lastDate = modifyTime
          lastList = pageState[m]
          lastList.listId = m
        }
      })
    }
    return lastList
  },
  getAllEndIds(items) {
    let result = []

    items.forEach((m) => {
      let fields = Object.keys(m)
      let item = {}
      fields.forEach((f) => {
        if (f.toLowerCase().endsWith('id')) {
          item[f] = m[f]
        }
      })
      result.push(item)
    })
    return result
  },
  getAllCustomField(items) {
    let result = []

    items.forEach((m) => {
      let fields = Object.keys(m)
      let item = {}
      fields.forEach((f) => {
        if (f.toLowerCase().endsWith('name') || f.toLowerCase().endsWith('code')) {
          item[f] = m[f]
        }
      })
      result.push(item)
    })
    return result
  },
}

export { pageNavUtil }
