<template>
  <span>
    <b-form-group
      v-bind="repProps"
      :label="gLabel"
      :description="gDescription"
      v-on="$listeners"
    >
    <template #label>
      <slot name="label"/>
    </template>
      <slot />
    </b-form-group>
  </span>
</template>
<script>
import { BFormGroup } from 'bootstrap-vue'
import { g } from '../../locale/lang-val'

const addedProps = {
  labelSuffix: {
    type: String,
    default () {
      return null
    },
  },
  noLocale: {
    type: Boolean,
    default () {
      return false
    },
  },
}
let objProps = Object.assign({}, BFormGroup.props, addedProps)

export default {
  components: { BFormGroup },
  props: {
    ...objProps,
  },
  computed: {
    repProps () {
      let { label, description, ...params } = this.$props
      return params
    },
    gLabel () {
      if (this.labelSuffix) {
        let result = this.getLocale(this.labelSuffix)
        return result + ':'
      } else {
        return this.getLocale(this.label)
      }
    },
    gDescription () {
      return this.getLocale(this.description)
    },
  },
  methods: {
    getLocale (val) {
      if (this.noLocale) {
        return val
      } else {
        return g(val)
      }
    },
  },
}
</script>
