var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('detail',{ref:"detail",attrs:{"save":_vm.save,"plaintext":false},scopedSlots:_vm._u([{key:"formBar",fn:function(){return [_c('b-btn-group',[_c('k-button-back',{attrs:{"back-route":_vm.isEvent?'eleTemplate':'template',"back-query":{ tabindex: _vm.$route.query.tabindex }}},[_vm._v(" buttons.back ")]),_c('k-button',{attrs:{"type":"submit"},on:{"click":_vm.onSave}},[_vm._v(" buttons.save ")]),_c('k-button',{on:{"click":function($event){return _vm.$router.push({
              name: _vm.isEvent?'eleTemplate-view':'template-view',
              query: {
                id: _vm.$route.query.id,
                tabindex:_vm.$route.query.tabindex
              },
            })}}},[_vm._v(" buttons.view ")]),_c('k-pre-next',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"pn",attrs:{"has-save-next":true,"before-to-leave":_vm.preNextToLeave}})],1)]},proxy:true},{key:"formMsg",fn:function(){return [_c('k-alert-msg',{ref:"lblMsg"})]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }