import http from "../../../utils/http";
import globalEnv from "../../../libs/globalEnv";

export const getDeliveryResultList = (data) => {
  return http.post("/api/msgdeliveryresult/page", data, { loading: true, baseURL: globalEnv.VUE_APP_STD_URL });
};
export const getDeliveryResultDetailList = (data) => {
  return http.post("/api/msgdeliveryresult/action/getmessagedetaillist", data, {
    loading: true,
    baseURL: globalEnv.VUE_APP_STD_URL,
    useBody: true,
  });
};
export const getLetterDeliveryResultList = (data) => {
  return http.post("/api/msgletter/page", data, { loading: true, baseURL: globalEnv.VUE_APP_STD_URL });
};
export const getLetterDeliveryResultDetailList = (data) => {
  return http.post("/api/msgletter/action/getdetaillist", data, {
    loading: true,
    baseURL: globalEnv.VUE_APP_STD_URL,
  });
};
export const getLetterDeliveryResultDetailDownloadPDF = (data) => {
  return http.post("/api/msgletter/actions/download-pdf", data, { loading: true, baseURL: globalEnv.VUE_APP_STD_URL });
};
export const delDeliveryResult = (ids) => {
  return http.delete("/api/msgdeliveryresult/deletemessage", { ids: ids }, {
    loading: true,
    baseURL: globalEnv.VUE_APP_STD_URL,
  });
};

export const delLetterDeliveryResult = (ids) => {
  return http.delete("/api/msgletter/deleteletter", { ids: ids }, {
    loading: true,
    baseURL: globalEnv.VUE_APP_STD_URL,
  });
};


export const getRecipientsDropdown = (data) => {
  return http.get("/api/msgdeliveryresult/action/getrecipientsdropdown", data, {
    loading: false,
    baseURL: globalEnv.VUE_APP_STD_URL,
  });
};

export const getRecipientsContent = (data) => {
  return http.get("/api/msgdeliveryresult/action/getrecipientscontent", data, {
    loading: true,
    baseURL: globalEnv.VUE_APP_STD_URL,
  });
};

export const getDeliveryResultStatusDropdown = (data) => {
  return http.get("/api/msgdeliveryresult/action/getdeliveryresultstatusdropdown", data, {
    loading: false,
    baseURL: globalEnv.VUE_APP_STD_URL,
  });
};

export const getDeliveryResultDetialStatusDropdown = (data) => {
  return http.get("/api/msgdeliveryresult/action/getstatusdropdown", data, {
    loading: false,
    baseURL: globalEnv.VUE_APP_STD_URL,
  });
};

export const getDeliveryResultLetterDetailInfo = (id) => {
  return http.get(`/api/msgletter/${id}`, {}, {
    loading: true,
    baseURL: globalEnv.VUE_APP_STD_URL,
  });
};

export const getDeliveryResultLetterDetailSelectRecipient = (data) => {
  return http.get(`/api/msgletter/action/getrecipientscontent/`, data, {
    loading: true,
    baseURL: globalEnv.VUE_APP_STD_URL,
  });
};
export const getDeliveryResultReportDetailInfo = (id) => {
  return http.get(`/api/msgdeliveryresult/${id}`, {}, {
    loading: true,
    baseURL: globalEnv.VUE_APP_STD_URL,
  });
};
export const getDeliveryResultReportDetailInfoRecipients = (id, Recipients, details) => {
  return http.get(`/api/msgdeliveryresult/${id}/${Recipients}/${details}`, {}, {
    loading: true,
    baseURL: globalEnv.VUE_APP_STD_URL,
  });
};

export const getSumcount = (data) => {
  return http.post("/api/msgdeliveryresult/sumcount", data, {
    loading: true,
    baseURL: globalEnv.VUE_APP_STD_URL,
  });
};

export const updateScheduleDatetime = (data) => {
  return http.post("/api/msgdeliveryresult/action/updatescheduledatetime", data, {
    loading: true,
    baseURL: globalEnv.VUE_APP_STD_URL,
  });
};

export const exportDetailPage = (data) => {
  return http.down("/api/msgletter/actions/export-detail-page", data, {
    loading: false,
    baseURL: globalEnv.VUE_APP_STD_URL,
  });
};

export const sendImmediately = (data) => {
  return http.post("/api/msgsend/actions/sending", data, {
    loading: true,
    baseURL: globalEnv.VUE_APP_STD_URL,
  });
};

export const cancelMessage = (ids) => {
  return http.post("/api/msgdeliveryresult/action/cancelmessage", { ids: ids }, {
    loading: false,
    baseURL: globalEnv.VUE_APP_STD_URL,
  });
};

export const exportDeliveryDetails = (data) => {
  return http.down("/api/msgdeliveryresult/actions/export-messagedetail-list", data);
};

export const exportDeliveryResultList = (data) => {
  return http.down("/api/msgdeliveryresult/actions/export-page", data);
};

export const exportLetterDeliveryResultList = (data) => {
  return http.down("/api/msgletter/actions/export-page", data);
};
export const updateShowInPortal = (data) => {
  return http.post("/api/msgdeliveryresult/update-show-in-portal", data, { useBody: true });
};

// bau and event










