import Loading from "../../../node_modules/fe-infrastractures/src/business-components/common-load";
import { getSystemInfo } from "../../api/app";

const state = {
  loading: Loading ? Loading : false,
  isCollapse: false,
  version: "",
  environment: "",
  timezone: 0,
  portalMessageCount: 0,
};

const mutations = {
  LOADING (state1, loading) {
    if (!!loading === true) {
      state1.loading?.open();
    } else {
      state1.loading?.close();
    }
  },
  COLLAPSE (state1, isCollapse) {
    state1.isCollapse = isCollapse;
  },
  TOGGLECOLLAPSE (state1) {
    state1.isCollapse = !state1.isCollapse;
  },
  SET_SYSTEMINFO (state1, systemInfo) {
    state1.version = systemInfo.version;
    state1.environment = systemInfo.environment;
    state1.timezone = systemInfo.timezone;
  },
  SET_PORTALMESSAGECOUNT (state1, count) {
    state1.portalMessageCount = count;
  }
};

const actions = {
  loading ({ commit }, isLoading) {
    commit("LOADING", isLoading);
  },
  collapse ({ commit }, isCollapse) {
    commit("COLLAPSE", isCollapse);
  },
  toggleCollapse ({ commit }) {
    commit("TOGGLECOLLAPSE");
  },
  getSystemInfo ({ commit }) {
    return getSystemInfo().then(res => {
      const { data } = res;
      commit("SET_SYSTEMINFO", { version: data.version, environment: data.environment, timezone: data.timeZone });
    });
  },
  setPortalMessageCount ({ commit }, count) {
    commit("SET_PORTALMESSAGECOUNT", count);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
