import http from "../../node_modules/fe-infrastractures/src/utils/http";
import { UserPermissionMenuList } from "../utils/mock/login";
import VueCookies from "vue-cookies";
import { globalEnv } from "fe-infrastractures";

const isLocalData = false;

export const login = (username, password, googleAuthenticatorCode) => {
  return http.post(
    "/auth/idp/portallogin",
    {
      Username: username,
      Password: password,
      ApiProjectType: 1,
      sysId: 32,
      GoogleAuthenticatorCode: googleAuthenticatorCode
    },
    {
      baseURL: globalEnv.VUE_APP_API_URL,
      throwError: true,
      useBody: true,
      noHttpIntercept: true, //api http response no intercept
    },
  );
};

export const refreshToken = (tokenKey) => {
  return http.post(
    "/auth/idp/refreshtoken",
    {
      RefreshToken: tokenKey,
      sysId: 32
    },
    {
      baseURL: globalEnv.VUE_APP_API_URL,
      throwError: true,
      ignoreLock: true,
      loading: false,
      useBody: true,
    },
  );
};

export const getUser = () => {
  return http.get(
    "/auth/idp/user/info",
    {},
    {
      baseURL: globalEnv.VUE_APP_API_URL,
      throwError: true,
      allowAnonymous: true,
      loading: false,
    },
  );
};

export const getSystem = () => {
  return new Promise(function (resolve) {
    setTimeout(() => {
      resolve();
    }, 500);
  });
};

export const getMenuTree = (systemId) => {
  return http.get(
    "/api/menu/tree",
    {
      SystemID: systemId,
    },
    {
      baseURL: globalEnv.VUE_APP_API_URL,
      throwError: true,
    },
  );
};
export const getUserPermissionMenuList = (data) => {
  if (isLocalData) {
    return Promise.resolve(UserPermissionMenuList);
  }

  return http.post("/auth/menu/user-permission-menulist", data, {
    baseURL: globalEnv.VUE_APP_API_URL,
    throwError: true,
    loading: false,
  });
};

const userTypeKeyConst = "worker_portal_user_type";

export const setUserType = (value) => {
  return VueCookies.set(userTypeKeyConst, value, 60 * 60 * 24 * 365);
};

export const getUserType = () => {
  return VueCookies.get(userTypeKeyConst);
};
