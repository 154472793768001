var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('konn-grid',{ref:"selectableTable",class:{ stickyTable: _vm.stickyHeader },attrs:{"select-mode":"single","has-check-box":false,"has-action":false,"fields":_vm.fields,"get-condition":_vm.getCondition,"data-source":_vm.getLog,"sticky-header":_vm.stickyHeader,"grid-sort-desc":true},scopedSlots:_vm._u([(this.fieldsType === this.logFieldsType.communicationLog)?{key:"cell(subject)",fn:function(row){return [(row.item.msgId != '00000000-0000-0000-0000-000000000000')?_c('router-link',{attrs:{"to":{
          name:_vm.getCommunicationRouteName(row.item) ,
          query: {
            id: row.item.msgId,
            isView: '1',
            dbtn: '0',
            hidePreNext: '1',
            deliveryType: row.item.logType,
            historyBack: '1',
          },
        }}},[_vm._v(" "+_vm._s(row.value)+" ")]):_c('k-link',{on:{"click":function($event){return _vm.showCommunicationLogDetialPop(row.item.id)}}},[_vm._v(" "+_vm._s(row.value)+" ")])]}}:null,{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key,style:({ width: field.width })})})}},{key:"cell(description)",fn:function(row){return _vm._l((row.item.description == null
          ? []
          : row.item.description.split('<br/>')),function(description,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(description)+" ")])})}}],null,true)}),(_vm.fieldsType == _vm.logFieldsType.communicationLog)?_c('CommunicationLogDetialPop',{ref:"communicationLogDetialPop",attrs:{"ref-id":_vm.id,"log-request-type":_vm.logType,"is-plain-text":_vm.isPlainText,"is-event":_vm.isEvent},on:{"saveSuccess":_vm.saveSuccess}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }