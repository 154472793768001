<template>
  <span>
    <span v-if="plaintext" class="col-form-label d-block">
      {{ isShowFloat ? floatValue : value }}
    </span>
    <b-form-input
      v-else-if="autocompleteStr"
      v-bind="props"
      :placeholder="gPlaceholder"
      v-on="$listeners"
      :aria-label="ariaLabel"
      ref="theinput"
      :autocomplete="autocompleteStr"
      :id="id"
    />
    <b-form-input
      v-else
      v-bind="props"
      :placeholder="gPlaceholder"
      v-on="$listeners"
      :aria-label="ariaLabel"
      ref="theinput"
      :id="id"
    />
  </span>
</template>
<script>
import { BFormInput } from "bootstrap-vue";
import { g } from "../../locale/lang-val";
import storeage from "../../utils/storeage";

const addedProps = {
  maxInputLength: {
    type: Number,
    default() {
      return null;
    },
  },
  maxLength: {
    type: Number,
    default() {
      return null;
    },
  },
  ariaLabel: {
    type: String,
    default() {
      return "input";
    },
  },
  id: {
    type: String,
    default: "",
  },
  isShowFloat: {
    type: Boolean,
    default: false,
  },
};

const objProps = Object.assign({}, BFormInput.options.props, addedProps);
// objProps.trim = {
//   type: Boolean,
//   default () {
//     return false
//   },
// }
export default {
  model: {
    props: "value",
    event: "update",
  },
  components: { BFormInput },
  props: {
    ...objProps,
  },
  data() {
    return {
      floatValue: "",
    };
  },
  computed: {
    props() {
      let { placeholder, ...params } = this.$props;
      return params;
    },
    gPlaceholder() {
      return g(this.placeholder);
    },
    autocompleteStr() {
      if (storeage.getSystemGeneralInfo()?.allowBrowserAutoFill == 2) {
        return "";
      } else {
        if (
          !this.id ||
          this.id == "name" ||
          this.id == "id" ||
          this.id == "input-name" ||
          this.id == "emailAddress" ||
          this.id == "phoneNumber" ||
          this.id == "phoneNumber2"
        ) {
          return "off";
        }
        return "close";
      }
    },
  },
  mounted() {
    if (this.maxInputLength > 0) {
      document.querySelectorAll("input").forEach((item) => {
        item.maxLength = this.maxInputLength;
      });
    }
    if (this.id != null && this.id.indexOf("search") != -1) {
      document.querySelectorAll("input").forEach((item) => {
        item.maxLength = 200;
      });
    }
    if (this.maxLength > 0 && !this.plaintext) {
      this.$refs.theinput.$el.maxLength = this.maxLength;
    }

    if (this.isShowFloat) {
      if (this.value && typeof this.value == "number") {
        this.floatValue = this.value.toFixed(2);
      }
    }
  },
  // watch: {
  //   value() {
  //     debugger
  //     this.inputValue = this.value
  //   },
  //   inputValue: {
  //     hanlder: function(newVal) {
  //       debugger
  //       let trimedValue = newVal
  //       if (trimedValue && typeof trimedValue == 'string') {
  //         trimedValue = trimedValue.trim()
  //       }
  //       this.$emit('update', trimedValue)
  //     },
  //   },
  // },
};
</script>
