<template>
  <div class="content">
    <div class="center-bar">
      <div class="nav-btn-group">
        <k-button-group>
          <k-button @click="onBackClick"> buttons.back </k-button>
          <k-export-button
            :params-getter="getExportCSV"
            :export-handler="exportHandler"
            :check-has-data="checkHasData"
            :exported-callback="exportCallbacker"
            v-if="isExport && enumTemplateType.letter == this.type"
          >
            buttons.exportToPDF
          </k-export-button>
          <k-button
            @click="generateMailingAddressClick"
            v-if="
              hasGenerateMailingAddress &&
              $has(
                isEvent
                  ? permissionCodeEvent.Communication.GenerateMailingLabels
                  : permissionCode.Communication.GenerateMailingLabels
              )
            "
          >
            buttons.generateMailingAddress
          </k-button>
        </k-button-group>
        <KWikiHelp :props-wiki-help-code="propsWikiHelpCode" />
      </div>
      <div class="content-main">
        <k-alert-msg ref="lblMsg" />
        <div class="d-flex mt-3 offset-xl-3">
          <div class="text-right col-xl-9 p-0">
            {{ $g("totalCount") }}: {{ totalCount }}
          </div>
        </div>
        <div class="content-party">
          <LanguageList
            :person-languages="personLanguages"
            @onLanguageSwitch="onLanguageSwitch"
            :is-show-hr="false"
            :is-validation="true"
            :show-is-data-icon="false"
            class="previewLanguage"
          >
            <template #content>
              <div class="ml-xl-3">
                <div class="d-xl-flex align-items-center mb-3 col-md-9 p-0">
                  <div class="d-xl-flex align-items-center" style="flex: 1">
                    <div class="mr-2">{{ $g("contactPerson") }}:</div>
                    <k-form-select
                      v-model="contactPerson"
                      :options="contactPersons"
                      style="flex: 1"
                      @change="contactPersonChange"
                    />
                  </div>
                  <div style="width: 300px" class="ml-1 text-xl-right">
                    {{ $g("totalCountLanguage") }}:
                    {{ languageTotalCount }}
                  </div>
                </div>
                <div
                  ref="divContent"
                  class="p-3 border col-md-9"
                  :style="getStyle()"
                />
              </div>
            </template>
          </LanguageList>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getContatDropdown,
  getPreview,
  letterExportToPDF,
  dowGenerateMailingLabels,
} from "../api/message";
import LanguageList from "./languageList.vue";
import { enumTemplateType } from "../enums/enumTemplateType";
import { has } from "../../../utils/permission/operatePermission";
import { permissionCode } from "../mixins/permissionCode";
import { startTaskProgress } from "../../../business-components/common-remote-task/remoteTaskStarter";

export default {
  mixins: [permissionCode],
  props: {
    hasGenerateMailingAddress: {
      type: Boolean,
      default() {
        return false;
      },
    },
    contactOptions: {
      type: Array,
      default() {
        return [];
      },
    },
    body: {
      type: String,
      default() {
        return "";
      },
    },
    groupId: {
      type: String,
      default() {
        return "";
      },
    },
    type: {
      type: Number,
      default() {
        return 0;
      },
    },
    contactAction: {
      type: Number,
      default() {
        return 0;
      },
    },
    actionIds: {
      type: Array,
      default: function () {
        return [];
      },
    },
    contactOptionIds: {
      type: Array,
      default: function () {
        return [];
      },
    },
    subject: {
      type: String,
      default() {
        return "";
      },
    },
    electionId: {
      type: String,
      default() {
        return "";
      },
    },
    contacts: {
      type: Array,
      default: function () {
        return [];
      },
    },
    phoneTypes: {
      type: Array,
      default: function () {
        return [];
      },
    },
    scheduleTime: {
      type: Number,
      default: function () {
        return null;
      },
    },
    previewPersonLanguages: {
      type: Array,
      default: () => {
        return [];
      },
    },
    typeFormContentMap: {
      type: Map,
      default: () => {
        return null;
      },
    },
    propsWikiHelpCode: {
      type: String,
      default: null,
    },
  },
  components: { LanguageList },
  data() {
    let isEvent = this.$route.name.startsWith("ele");
    return {
      isEvent: isEvent,
      hasElection: false,
      contactPersons: [],
      allContactPersons: [],
      contactPerson: "",
      totalCount: "",
      html: "",
      enumTemplateType: enumTemplateType,
      isExport: false,
      languageId: "",
      languageTotalCount: 0,
      personLanguages: this.previewPersonLanguages,
    };
  },
  computed: {
    getLanguageId() {
      if (!this.languageId) {
        let defaultLanguageId =
          this.personLanguages.find((c) => c.isDefault) ??
          this.personLanguages[0];
        this.languageId = defaultLanguageId.id;
        defaultLanguageId.active = true;
      }
      return this.languageId;
    },
    exportHandler() {
      return letterExportToPDF;
    },
  },
  mounted() {
    this.bindContactDropdown();
    this.setPermission();
  },
  methods: {
    getStyle() {
      return this.type===enumTemplateType.sms ? {
        background: "#fff",
        "overflow-wrap": "break-word",
        "word-wrap": "break-word",
        "white-space": "pre-wrap"
      }:{
        background: "#fff",
        "overflow-wrap": "break-word",
      };
    },
    generateMailingAddressClick(){
      if(this.hasGenerateMailingAddress){
        let data = {
          relatedIds: this.allContactPersons.map((c) => {
            return c.label;
          }),
          lableUserFor: this.isEvent ? 2 : 1,
        };
        dowGenerateMailingLabels(data).then((result) => {
          if (result.data.data.status == 3) {
            //erro
            this.$alert({
              title: this.$g("const.confirm.confirm"),
              content: result.data.data.info,
              okText: this.$g("const.confirm.ok"),
            });
            return;
          }
          startTaskProgress(result.data.data.info);
        });
      }
    },
    onLanguageSwitch(item) {
      this.languageId = item.id;
      this.contactPersons = this.allContactPersons.filter(
        (c) => c.relateContent == this.languageId
      );
      this.languageTotalCount = this.contactPersons.length;
      if (this.languageTotalCount > 0) {
        this.contactPerson = this.contactPersons[0].value;
      } else {
        this.contactPerson = "";
      }
      this.contactPersonChange();
    },
    setPermission() {
      if (this.isEvent) {
        this.isExport = has(
          this.permissionCodeEvent.Communication.PrintLetterExporttoPDF
        );
      } else {
        this.isExport = has(
          this.permissionCode.Communication.PrintLetterExporttoPDF
        );
      }
    },
    bindContactDropdown() {
      let para = {
        type: this.type,
        groupId: this.groupId,
        contactAction: this.contactAction,
        actionIds: this.actionIds,
        contactOptionIds: this.contactOptionIds,
        phoneTypes: this.phoneTypes,
        contactOptions: this.contactOptions,
        temLanguageIds: this.previewPersonLanguages.map((c) => c.id),
      };
      getContatDropdown(para).then((res) => {
        this.allContactPersons = res.data || [];
        this.allContactPersons.forEach((item) => {
          item.label = item.value;
          item.value = item.relateContent2 + item.label;
        });
        this.contactPersons = this.allContactPersons.filter(
          (c) => c.relateContent == this.getLanguageId
        );
        this.setTotalCount();
        this.languageTotalCount = this.contactPersons.length;
        if (this.contactPersons.length > 0) {
          this.contactPerson = this.contactPersons[0].value;
          this.contactPersonChange();
        }
      });
    },
    setTotalCount() {
      if (
        this.previewPersonLanguages.find((c) => c.isDefault) &&
        this.previewPersonLanguages.length == 1
      ) {
        this.totalCount = this.allContactPersons.length;
      } else {
        this.totalCount = this.allContactPersons.filter((d) =>
          this.previewPersonLanguages.find((c) => c.id == d.relateContent)
        ).length;
      }
    },
    onBackClick() {
      this.$emit("preview-back");
    },
    setContent(html) {
      this.$refs.divContent.innerHTML = html;
      this.html = html;
    },
    contactPersonChange() {
      let map = this.typeFormContentMap.get(this.languageId);
      let contact = this.contactPersons.find(
        (c) => c.value == this.contactPerson
      );
      let para = {
        contactOptions: this.contactOptions,
        body: map?.body,
        footerDisplay: map?.footerDisplay,
        footerId: map?.footerId,
        headerDisplay: map?.headerDisplay,
        headerId: map?.headerId,
        messageId: map?.messageId,
        relatedType: this.contactAction,
        relatedID: contact?.label,
        contactId: contact?.relateContent2,
        externalParametersDic: {
          ElectionId: this.electionId,
          GroupId: this.groupId,
        },
        scheduleTime: this.scheduleTime,
        languageId: this.languageId,
        relatedIds: this.contactPersons
          ? this.contactPersons.map((c) => c.label)
          : [],
        isLetter: this.enumTemplateType.letter == this.type,
      };
      getPreview(para).then((res) => {
        this.setContent(res.data);
      });
    },
    onExportToPDF() {
      letterExportToPDF(this.getExportCSV());
    },
    getExportCSV() {
      let relatedIds = [];
      let names = [];
      this.allContactPersons.forEach((element) => {
        relatedIds.push({
          languageId: element.relateContent,
          relatedId: element.label,
        });
        names.push(element.text);
      });
      let letterLanguages = [];
      for (let value of this.typeFormContentMap.values()) {
        letterLanguages.push({
          subject: value?.subject,
          languageId: value?.languageId,
          headerDisplay: value?.headerDisplay,
          headerId: value?.headerId,
          footerDisplay: value?.footerDisplay,
          footerId: value?.footerId,
          body: value?.body,
        });
      }
      return {
        title: this.subject,
        relatedType: this.contactAction,
        relatedIds: relatedIds,
        electionId: this.electionId,
        names: names,
        groupId: this.groupId,
        contactAction: this.contactAction,
        contacts: this.contactOptionIds,
        actionIds: this.actionIds,
        letterLanguages: letterLanguages,
      };
    },
    exportCallbacker(rep) {
      startTaskProgress(rep.data.info);
    },
    checkHasData() {
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .personLanguageList {
  height: 100%;
  margin-top: 0;
}
</style>
