<script>
import { ValidationObserver } from "vee-validate";
export default {
  extends: ValidationObserver,
  data() {
    return {
      handleValidate: ValidationObserver.options.methods.validate,
    };
  },
  methods: {
    async validate() {
      let state = await this.handleValidate();
      if (!state) {
        document
          .querySelector('.validateAlert [role="alert"]')
          .parentNode.querySelector("input,select,textarea")
          ?.focus();
      }
      return state;
    },
  },
};
</script>