import http from "../../../../../../utils/http";

export const getNotification = (data) => {
  return http.get(`/api/report/notification`, data);
};
export const addNotification = (data) => {
  return http.post(`/api/report/notification`, data);
};
export const updateNotification = (data) => {
  return http.put(`/api/report/notification`, data);
};
export const getSendToTypeEnum = (data) => {
  return http.get(`/api/report/notification/enum/sendtotype`, data);
};
export const getSendruleEnum = (data) => {
  return http.get(`/api/report/notification/enum/sendrule`, data);
};
export const getWeekDayEnum = (data) => {
  return http.get(`/api/report/notification/enum/week-day`, data, { loading: false });
};
export const getMonthDayEnum = (data) => {
  return http.get(`/api/report/notification/enum/month-day`, data, { loading: false });
};
export const getMonthEnum = (data) => {
  return http.get(`/api/report/notification/enum/month`, data, { loading: false });
};
export const getUserGroupsOptions = (data) => {
  return http.get(`/api/report/notification/officer-recipients/user-groups`, data, { loading: false });
};
export const getOfficerRecipientsPage = (data) => {
  return http.post(`/api/report/notification/officer-recipients/page`, data, { loading: false });
};

export const checkSubjectUsed = (data) => {
  return http.post(`/api/report/notification/actions/check-subject-used`, data, { loading: false, useBody: true });
};
export const getNotificationToken = (data) => {
  return http.get(`/anon/report/notification/token`, data, { loading: true });
};
export const getDynamicLabelSetting = (data) => {
  return http.get(
    `/api/report/notification/actions/get-dynamic-label-setting`,
    data,
    { useBody: true },
  );
};
