import VueCookies from "vue-cookies";
import globalEnv from "../libs/globalEnv";
VueCookies.config(globalEnv.VUE_APP_EXPIRES);
const tokenKeyConst = "__pollchief_token";

function getTokenKey (name) {
  if (name) {
    return name + tokenKeyConst
  }
  let sysPath = globalEnv.VUE_APP_SYSTEM_PATH;
  let useSysPath = globalEnv.VUE_APP_USE_SYSTEM_PATH;
  if (useSysPath && sysPath) {
    return sysPath + tokenKeyConst;
  }
  return tokenKeyConst;
}

export const setToken = (token, name) => {
  let tokenKey = getTokenKey(name);
  return VueCookies.set(tokenKey, token);
};

export const getToken = (name) => {
  let tokenKey = getTokenKey(name);
  return VueCookies.get(tokenKey);
};

export const removeToken = (name) => {
  let tokenKey = getTokenKey(name);
  return VueCookies.remove(tokenKey);
};
