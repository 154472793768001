<template>
  <div>
    <v-calendar
      :class="cssClass"
      :is-expanded="isExpanded"
      :disable-page-swipe="disablePageSwipe"
      :timezone="timeZone"
      :attributes="attributes"
      :select-attribute="selectAttribute"
      ref="calendar"
      @dayclick="onDayClick"
    >
      <template #day-content="{ day, attributes }">
        <slot
          name="day-content"
          :day="day"
          :attributes="attributes"
        />
      </template>
    </v-calendar>
  </div>
</template>
<script>
import Moment from "moment-timezone";
import storeage from "../../utils/storeage";

export default {
  props: {
    attributes: {
      type: Array,
    },
    selectAttribute: {
      type: Object,
      default(){
        return null;
      },
    },
    disablePageSwipe: {
      type: Boolean,
    },
    isExpanded: {
      type: Boolean,
    },
    cssClass: {
      type: String,
      default(){
        return "custom-calendar max-w-full";
      },
    },
  },
  data(){
    return { timeZone: storeage.getTimeZone() };
  },
  methods: {
    onDayClick(day){
      let moment = Moment.tz(day.id, this.timeZone);
      let selectedTimeZoneDay = new Date(moment.valueOf());
      let selectedUTCTimestamp = selectedTimeZoneDay.getTime();
      let selDay = {
        timeZoneDay: selectedTimeZoneDay,
        timestamp: selectedUTCTimestamp,
      };
      this.$emit("dayclick", selDay);
    },
    move(moveToUTCTimestamp){
      const calendar = this.$refs.calendar;
      calendar.move(new Date(moveToUTCTimestamp));
    },
    handleSelectDate(dateAry, selectDate){
      let index = dateAry.findIndex((item) => !!item.customData.selectDay);
      if(index > - 1){
        dateAry.splice(index, 1);
      }
      this.addSelectDate(dateAry, selectDate);
    },
    handleSelectDateAdd(dateAry, selectDate){
      let index = dateAry.findIndex(
          (item) => item.customData.selectDay && item.dates == selectDate,
      );
      if(index > - 1){
        dateAry.splice(index, 1);
      } else {
        this.addSelectDate(dateAry, selectDate);
      }
    },
    addSelectDate(dateAry, selectDate){
      dateAry.push({
        customData: {
          selectDay: true,
        },
        highlight: {
          class: "selectCss",
        },
        dates: selectDate,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .custom-calendar.vc-container {
  --day-border: 1px solid #dddddd;
  --day-border-highlight: 1px solid #dddddd;
  --day-width: 90px;
  --day-height: 50px;
  --weekday-bg: #f8fafc;
  --weekday-border: 1px solid #dddddd;
  --gray-400: #dddddd;
  border-radius: 0;
  width: 100%;

  & .vc-header {
    padding: 10px 0;
    color: var(--colorFontDefault);
  }

  & .vc-weeks {
    padding: 0;
  }

  & .vc-weekday {
    border-bottom: var(--weekday-border);
    border-top: var(--weekday-border);
    border-right: var(--weekday-border);
    padding: 10px 0;
    color: var(--colorFontDefault);

    &:nth-child(7) {
      border-right: 0;
    }
  }

  & .vc-day {
    padding: 5px;
    text-align: center;
    min-height: var(--day-height);
    min-width: var(--day-width);
    background-color: white;

    &.weekday-1,
    &.weekday-7 {
      background-color: #eff8ff;
    }

    &:not(.on-bottom) {
      border-bottom: var(--day-border);

      &.weekday-1 {
        border-bottom: var(--day-border-highlight);
      }
    }

    &:not(.on-right) {
      border-right: var(--day-border);
    }

    .vc-highlights > div:first-child {
      z-index: 1;
    }

    .vc-day-content {
      width: 100%;
      height: 100%;

      &:hover,
      &:focus {
        font-weight: var(--font-medium);
        background: none;
      }
    }

    .selectCss {
      width: 100%;
      height: 100%;
      background-color: #ffeb3b;
      border-radius: 0 !important;
    }

    .dotCss {
      width: 10px;
      height: 10px;
      margin-bottom: 4px;
    }
  }

  & .vc-day-dots {
    margin-bottom: 5px;
  }
}
</style>
