<template>
  <div class="layout-time">
    <b-icon
      icon="clock"
      style="color: var(--topMenuFont); vertical-align: sub; cursor: auto"
    />
    <span class="bold">{{ time }}</span>
    <span>{{ date }}</span>
  </div>
</template>

<script>
import Moment from "moment-timezone";
import storeage from "../../utils/storeage";

export default {
  props: {
    dataFormat: {
      type: String,
      default() {
        return storeage.getDateFormat();
      },
    },
  },
  data() {
    return {
      time: "",
      date: "",
    };
  },
  methods: {
    getDateTime() {
      const date = new Date();
      let moment = Moment.tz(date, storeage.getTimeZone());
      this.time = moment.format("h:mm:ss A");
      this.date = moment.format(this.dataFormat);
    },
  },
  created() {
    this.getDateTime();
    setInterval(() => {
      this.getDateTime();
    }, 1000);
  },
};
</script>

<style lang="scss" scoped>
.layout-time {
  & > *:not(:last-child) {
    margin-right: 0.5em;
  }
}
</style>
