<template>
  <div class="page-loading" v-if="showLoad">
    <div class="loadingSpinner">
      <div class="loadIcon"><em /><em /><em /><em /></div>
      <div class="loadText">
        {{ message }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showLoad: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: "",
    },
    open: {
      type: Number,
      default: 0,
    },
    close: {
      type: Number,
      default: 0,
    },
  },
};
</script>
