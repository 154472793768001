<template>
  <KValidationObserver ref="observer">
    <k-form-group
      :label="labelSuffix"
      label-align-md="right"
      label-cols-md="3"
      :content-cols-lg="lgNum"
      :content-cols-md="mdNum"
      label-for="noneBind"
      label-class="font-weight-bolder"
    >
      <div style="height: 40px" />
      <slot name="formGroup" />
      <k-form-group
        v-if="!isAllegheny && hasLanguage"
        id="input-group-language"
        label-suffix="language preference"
        label="language"
        label-align-md="left"
        label-cols-md="3"
        :label-class="isFormRequired ? 'require-mark' : ''"
        label-for="noneBind"
      >
        <KValidationProvider
          :rules="{ required: isFormRequired }"
          v-slot="{ errors }"
          slim
          :name="`language${identifier}`"
        >
          <k-alert :show="errors.length > 0" variant="warning">
            {{ errors[0] }}
          </k-alert>
          <k-form-select
            v-model="application.languageId"
            :options="languagePreference"
            :plaintext="!edit"
          />
        </KValidationProvider>
      </k-form-group>

      <k-form-group
        label-suffix="preferredContactMethod"
        label-for="noneBind"
        label-align-md="left"
        label-cols-md="3"
        :label-class="isFormRequired ? 'require-mark' : ''"
      >
        <KValidationProvider
          :rules="{ required: isFormRequired }"
          v-slot="{ errors }"
          slim
          :name="`preferredContactMethod${identifier}`"
        >
          <k-alert :show="errors.length > 0" variant="warning">
            {{ errors[0] }}
          </k-alert>
          <k-form-select
            v-model="application.preferredContactMethod"
            :options="EmalilEnumTypeEnumList"
            style="width: 200px"
            :plaintext="!edit"
          />
        </KValidationProvider>
      </k-form-group>

      <k-form-group
        label-suffix="email"
        label-for="noneBind"
        label-align-md="left"
        label-cols-md="3"
      >
        <b-form-radio-group
          v-model="application.emailPreference"
          class="mt-lg-2"
          :plaintext="!edit"
          :disabled="!edit"
          :options="options"
          @change="emailPreferenceChange"
        />
      </k-form-group>

      <k-form-group
        label-suffix="emailAddress"
        label-for="noneBind"
        label-align-md="left"
        label-cols-md="3"
        :label-class="emailPreferenceOn ? 'require-mark' : ''"
      >
        <KValidationProvider
          :rules="{
            max: 200,
            email: true,
            required: emailPreferenceOn,
          }"
          :name="`email${identifier}`"
          v-slot="{ errors }"
        >
          <k-alert :show="errors.length > 0" variant="warning">
            {{ errors[0] }}
          </k-alert>
          <k-form-input
            id="emailAddress"
            v-model="application.email"
            :plaintext="!edit"
          />
        </KValidationProvider>
      </k-form-group>

      <k-form-group
        label-suffix="messages"
        label-for="noneBind"
        label-align-md="left"
        label-cols-md="3"
        v-if="hasMessagesRadio"
      >
        <b-form-radio-group
          v-model="application.smsPreference"
          class="mt-lg-2"
          :plaintext="!edit"
          :disabled="!edit"
          :options="options"
          @change="smsPreferenceChange"
        />
      </k-form-group>

      <k-form-group
        label-suffix="smsPhoneNumber"
        label-for="noneBind"
        label-align-md="left"
        label-cols-md="3"
        :label-class="smsPreferenceOn ? 'require-mark' : ''"
      >
        <KValidationProvider
          :rules="{
            phone: true,
            required: smsPreferenceOn,
          }"
          slim
          :name="`phoneNumber${identifier}`"
          v-slot="{ errors }"
        >
          <k-alert :show="errors.length > 0" variant="warning">
            {{ errors[0] }}
          </k-alert>
          <k-form-phone-input
            id="phoneNumber"
            v-model="application.sms"
            :plaintext="!edit"
          />
        </KValidationProvider>
      </k-form-group>

      <k-form-group
        label-suffix="phoneCall"
        label-for="noneBind"
        label-align-md="left"
        label-cols-md="3"
        v-if="hasPhoneCallRadio"
      >
        <b-form-radio-group
          v-model="application.phonePreference"
          class="mt-lg-2"
          :plaintext="!edit"
          :disabled="!edit"
          :options="options"
          @change="phonePreferenceChange"
        />
      </k-form-group>

      <k-form-group
        label-suffix="phoneCallPhoneNumber"
        label-for="noneBind"
        label-align-md="left"
        label-cols-md="3"
        :label-class="phonePreferenceOn ? 'require-mark' : ''"
        v-if="hasPhoneCallPhoneNumber"
      >
        <KValidationProvider
          :rules="{
            phone: true,
            required: phonePreferenceOn,
          }"
          slim
          :name="`phoneNumber2${identifier}`"
          v-slot="{ errors }"
        >
          <k-alert :show="errors.length > 0" variant="warning">
            {{ errors[0] }}
          </k-alert>
          <k-form-phone-input
            id="phoneNumber2"
            v-model="application.phone"
            :plaintext="!edit"
          />
        </KValidationProvider>
      </k-form-group>

      <k-form-group
        label-suffix="doNotDisturbHours"
        label-for="noneBind"
        label-align-md="left"
        label-cols-md="3"
      >
        <b-form-radio-group
          v-model="application.isUseNoDisturbHours"
          class="mt-lg-2"
          :plaintext="!edit"
          :disabled="!edit"
          :options="options"
          @input="isUseNoDisturbHoursChange"
        />
      </k-form-group>
      <k-form-group
        label-suffix="hours"
        label-align-md="left"
        label-cols-md="3"
        :label-class="this.isUseNoDisturbHoursOn ? 'require-mark' : ''"
        label-for="noneBind"
        v-if="isUseNoDisturbHoursOn"
      >
        <KValidationProvider
          :rules="{
            required:
              isUseNoDisturbHoursOn || application.noDisturbHoursEnd != null,
            validateTimeFormat: true,
          }"
          slim
          v-slot="{ errors }"
          class="inline-time"
          :name="`noDisturbHoursStart${identifier}`"
        >
          <k-alert :show="errors.length > 0" variant="warning">
            {{ errors[0] }}
          </k-alert>
          <k-form-timepicker
            v-model="application.noDisturbHoursStart"
            class="datepicker-time"
            :plaintext="!edit"
            :class="{ minWidth: !edit }"
          />
        </KValidationProvider>
        <span class="timePart">-</span>
        <KValidationProvider
          :rules="{
            required:
              isUseNoDisturbHoursOn || application.noDisturbHoursStart != null,
            validateTimeFormat: true,
          }"
          slim
          v-slot="{ errors }"
          class="inline-time"
          :name="`noDisturbHoursEnd${identifier}`"
        >
          <k-alert :show="errors.length > 0" variant="warning">
            {{ errors[0] }}
          </k-alert>
          <k-form-timepicker
            v-model="application.noDisturbHoursEnd"
            class="datepicker-time"
            :plaintext="!edit"
            :class="{ minWidth: !edit }"
          />
        </KValidationProvider>
      </k-form-group>
    </k-form-group>
  </KValidationObserver>
</template>

<script>
import globalEnv from "../../libs/globalEnv";

export default {
  props: {
    identifier: {
      type: String,
      default() {
        return "";
      },
    },
    hasLanguage: {
      type: Boolean,
      default() {
        return true;
      },
    },
    isFormRequired: {
      type: Boolean,
      default() {
        return false;
      },
    },
    labelSuffix: {
      type: String,
      default() {
        return "application-tab-notification-preferences";
      },
    },
    lgNum: {
      type: Number,
      default() {
        return 6;
      },
    },
    mdNum: {
      type: Number,
      default() {
        return 7;
      },
    },
    edit: {
      type: Boolean,
      default() {
        return false;
      },
    },
    application: {
      type: Object,
      default: function () {
        return {};
      },
    },
    EmalilEnumTypeEnum: {
      type: String,
      default: function () {
        return "EnumMessageType";
      },
    },
    EmalilEnumTypeEnumList: {
      type: Array,
      default: function () {
        return [];
      },
    },
    languagePreference: {
      type: Array,
      default: function () {
        return [];
      },
    },
    hasMessagesRadio: {
      type: Boolean,
      default() {
        return true;
      },
    },
    hasPhoneCallRadio: {
      type: Boolean,
      default() {
        return true;
      },
    },
    hasPhoneCallPhoneNumber: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  model: { prop: "application", event: "input" },
  components: {},
  data() {
    return {
      isAllegheny: globalEnv.VUE_APP_Allegheny,
      curSystemLanguage: "",
      currEnumCommunicationSendType: "",
      electionId: this.electionId,

      emailPreferenceOn: false,
      smsPreferenceOn: false,
      phonePreferenceOn: false,
      isUseNoDisturbHoursOn: false,
      options: [
        { text: this.$g("on"), value: 2 },
        { text: this.$g("off"), value: 1 },
      ],
    };
  },
  created() {
    this.isUseNoDisturbHoursChange(this.application.isUseNoDisturbHours);
    this.emailPreferenceChange(this.application.emailPreference);
    this.smsPreferenceChange(this.application.smsPreference);
    this.phonePreferenceChange(this.application.phonePreference);
  },
  mounted() {
    if (
      globalEnv.VUE_APP_Allegheny &&
      (!this.application.languageId || this.application.languageId.length == 0)
    ) {
      this.languagePreference.forEach((item) => {
        if (item.isDefault) {
          this.application.languageId = item.value;
        }
      });
    }
  },
  methods: {
    emailPreferenceChange(val) {
      this.emailPreferenceOn = val == 2;
    },
    smsPreferenceChange(val) {
      this.smsPreferenceOn = val == 2 && this.hasMessagesRadio;
    },
    phonePreferenceChange(val) {
      this.phonePreferenceOn = val == 2 && this.hasPhoneCallRadio;
    },
    isUseNoDisturbHoursChange(val) {
      this.isUseNoDisturbHoursOn = val == 2;
      if (!this.isUseNoDisturbHoursOn) {
        this.application.noDisturbHoursStart = null;
        this.application.noDisturbHoursEnd = null;
      }
    },
  },
  watch: {},
};
</script>
