<template>
  <div class="color-picker" :id="`div-${_uid}`">
    <k-popover
      :target="`popover-target-${_uid}`"
      triggers="hover"
      placement="bottom"
      :container="`div-${_uid}`"
      boundary="window"
    >
      <chrome-picker
        :value="pickercolors"
        @input="updateValue"
        v-if="!disabled"
      />
    </k-popover>

    <k-input-group :id="`popover-target-${_uid}`" size="sm">
      <template #append v-if="!hideOpacity">
        <k-form-input
          :value="a"
          @input="updateA"
          class="color-picker-opacity"
          disabled
        />
      </template>
      <template #prepend>
        <div
          :style="`background-color: ${hex}; opacity: ${a}`"
          class="color-picker-color"
        />
      </template>
      <k-form-input
        :value="hex"
        @input="updateHex"
        class="color-picker-hex"
        disabled
      />
    </k-input-group>
  </div>
</template>
<script>
import { Chrome } from "vue-color";

export default {
  props: {
    colors: {
      type: Object,
      default() {
        return {
          hex: "#194d33",
          a: 1,
        };
      },
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
    hideOpacity: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  components: {
    "chrome-picker": Chrome,
  },
  data() {
    return {
      pickercolors: { ...this.colors },
    };
  },
  computed: {
    hex() {
      if (this.pickercolors && this.pickercolors.hex) {
        return this.pickercolors.hex;
      }
      return "";
    },
    a() {
      if (this.pickercolors && this.pickercolors.a) {
        return this.pickercolors.a;
      }
      return "";
    },
    hex8() {
      if (this.pickercolors && this.pickercolors.hex8) {
        return this.pickercolors.hex8;
      }
      return "";
    },
  },
  mounted() {},
  methods: {
    updateValue(value) {
      this.$emit("input", value);
      this.pickercolors = value;
    },
    updateHex(value) {
      this.pickercolors.hex = value;
    },
    updateA(value) {
      this.pickercolors.a = value;
    },
  },
};
</script>

<style scoped>
.color-picker-opacity {
  width: 50px;
}

.color-picker-hex {
  width: 100px;
  margin-right: 5px;
}

.color-picker-color {
  width: 40px;
  margin-right: 5px;
  padding: 3px;
  border: 1px solid #aaa;
  background-clip: content-box;
}

.color-picker {
  width: 200px;
}
</style>
