<template>
  <div class="content-page">
    <election-select v-if="isEvent" />
    <div class="center-bar">
      <div class="nav-btn-group" />
      <div class="content-main">
        <k-alert-msg ref="lblMsg" />
        <div class="content-navTab">
          <div>
            <nav-tab
              ref="navTabs"
              :refresh="navClick"
              :items="navItems"
              :need-total-count="false"
            />
          </div>
        </div>
        <div
          class="d-flex flex-wrap background"
          v-if="widgets.length"
        >
          <div
            v-for="(item,index) in widgets"
            :key="index"
            :class="{
              chart: true,
              half: item.size === 2 && !item.isEmpty,
              third: item.size === 4 && !item.isEmpty,
              halfEmpty: item.size === 2 && item.isEmpty,
              thirdEmpty: item.size === 4 && item.isEmpty,
            }"
          >
            <GoogleChart
              v-if="!item.isEmpty"
              :widget="item"
              :template-type="419"
            />
          </div>
        </div>
        <div
          v-else
          class="p-2"
        >
          {{ $g("noResultsAreAvailable") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavTab from "../../components/nav-tab";
import { getWidgetCategoryList, getReportWidgetList } from "../../api/report";
import storeage from "../../../../utils/storeage";
import GoogleChart from "../../components/google-chart";

export default {
  data(){
    let isEvent = this.$route.meta.isEvent ?? false;
    return {
      emptyGuid: "00000000-0000-0000-0000-000000000000",
      isEvent: isEvent,
      widgets: [],
      electionId: isEvent ? storeage.getElectionId() : null,
      tabindex:
          this.$route.query.tabindex != null
              ? parseInt(this.$route.query.tabindex)
              : 0,
      category:
          this.$route.query.tabindex != null
              ? parseInt(this.$route.query.tabindex) + 1
              : 1,
      curNav: {},
      navItems: [],
    };
  },
  components: {
    NavTab,
    GoogleChart,
  },
  computed: {},
  methods: {
    addEmptyChart(){
      let items = [];
      this.widgets.forEach((item) => {
        items.push({
          ...item,
        });
      });
      let count = 0;
      let add = 0;
      items.forEach((item, index) => {
        if(index < items.length - 1){
          if(index > 0){
            if(item.size === items[index - 1].size){
              count ++;
            } else {
              count = 0;
            }
          }
          if(item.size === 2){
            if(item.size != items[index + 1].size){
              if(count != 1){
                let data = item;
                data.isEmpty = true;
                add ++;
                this.widgets.splice(index + add, 0, { ...data });
              }
              count = 0;
            }
          } else if(item.size === 4){
            if(item.size != items[index + 1].size){
              if(count != 2){
                let data = item;
                data.isEmpty = true;
                add ++;
                this.widgets.splice(index + add, 0, { ...data });
              }
              count = 0;
            }
          }
        }
      });
    },
    navClick: function (item){
      let routeName;
      routeName = this.isEvent ? "eleReport-dashboard" : "report-dashboard";
      this.$router.push({
        name: routeName,
        query: {
          tabindex: item.orderIndex,
        },
      });
    },
    getTabs(){
      getWidgetCategoryList(!this.isEvent, this.electionId).then((data) => {
        const items = data.data || [];
        if(items.length){
          const navArr = [];
          items.forEach(function (item, index){
            navArr.push({
              text: item.text,
              value: item.value,
              total: item.total,
              orderIndex: index,
            });
          });
          this.navItems = navArr;
          this.curNav = this.navItems[this.tabindex];
          let data = {
            electionId: this.electionId,
            isBau: !this.isEvent,
            category: this.category,
          };
          getReportWidgetList(data).then((rep) => {
            let data = rep.data;
            data.forEach((item) => {
              this.widgets.push({
                ...item,
                isEmpty: false,
              });
            });
            console.log(this.widgets);
            this.addEmptyChart();
            console.log(this.widgets);
          });
          setTimeout(() => {
            if(this.$refs.navTabs){
              this.$refs.navTabs.tabindex = this.tabindex;
            }
          }, 100);
        }
      });
    },
  },
  created(){
    this.getTabs();
  },
};
</script>
<style lang="scss" scoped>
::v-deep .plaintext {
  > span {
    margin-top: -7px;
  }
}

.chart {
  flex: 0 0 99%;
  margin-top: 0.5%;
  margin-left: 0.5%;
  margin-right: 0.5%;
  margin-bottom: 0.5%;
  height: 300px;
  width: 99%;
  background-color: #ffffff;
}

.chart.half {
  flex: 0 0 49%;
  margin-top: 0.5%;
  margin-left: 0.5%;
  margin-right: 0.5%;
  margin-bottom: 0.5%;
  height: 300px;
  width: 49%;
  background-color: #ffffff;
}

.chart.third {
  flex: 0 0 32%;
  margin-top: 0.5%;
  margin-left: 0.5%;
  margin-right: 0.5%;
  margin-bottom: 0.5%;
  height: 300px;
  width: 32%;
  background-color: #ffffff;
}

.chart.halfEmpty {
  flex: 0 0 49%;
  margin-top: 0.5%;
  margin-left: 0.5%;
  margin-right: 0.5%;
  margin-bottom: 0.5%;
  height: 300px;
  width: 49%;
  background-color: #f0f0f0;
}

.chart.thirdEmpty {
  flex: 0 0 32%;
  margin-top: 0.5%;
  margin-left: 0.5%;
  margin-right: 0.5%;
  margin-bottom: 0.5%;
  height: 300px;
  width: 32%;
  background-color: #f0f0f0;
}

.background {
  background-color: #f0f0f0;
}
</style>
