// import { checkRolesChanged } from "../api/permission";
// import { caheChangeType } from "./enums/caheChangeType";
// import storeage from "../utils/storeage";
// import router from "../router/routerProvider";
// import { g } from "../locale/lang-val";
import { sysId } from "./enums/sysId";

//Detroit大屏前后台不需要轮训
let isCheck = process.env.VUE_APP_SYSTEM_ID != sysId.passTracking &&
  process.env.VUE_APP_SYSTEM_ID != sysId.passTrackingPortal;

const startPollingCheckRolesChanged = async () => {
  return true;
  // if (storeage.getUserInfo()?.tenantkey) {
  //   let params = {
  //     tenantKey: storeage.getUserInfo()?.tenantkey,
  //     roleTimeStamp: storeage.getUserInfo()?.roletimestamp,
  //     userkey: storeage.getUserInfo()?.userId,
  //     userInfoHash: storeage.getUserInfo()?.userInfoHash,
  //     tenantInfoHash: storeage.getUserInfo()?.tenantInfoHash,
  //     loginTypeInfoHash: storeage.getUserInfo()?.loginTypeInfoHash,
  //   };
  //   //Detroit后台需要sysid
  //   if (process.env.VUE_APP_SYSTEM_ID == sysId.writeRecord) {
  //     params.sysId = parseInt(process.env.VUE_APP_SYSTEM_ID);
  //   }
  //   let res = await checkRolesChanged(params).catch((e) => 3);
  //   if (res.data == caheChangeType.userChanged ||
  //     res.data == caheChangeType.permissionChanged ||
  //     res.data == caheChangeType.tenantChanged ||
  //     res.data == caheChangeType.loginTypeChanged) {
  //     let p = {};
  //     let message = "";
  //     if (res.data == caheChangeType.userChanged) {
  //       message = g("userInfoChange");
  //     } else if (res.data == caheChangeType.loginTypeChanged) {
  //       message = g("loginAndSessionChange");
  //     } else {
  //       message = g("permissionChange");
  //     }
  //     p.msg = message;
  //     router.push({
  //       name: "login",
  //       query: { d: +new Date() },
  //       params: p,
  //     });
  //   }
  // }
  // setTimeout(() => {
  //   startPollingCheckRolesChanged();
  // }, 30000);
};

export const checkPermissionChange = async () => {
  if (!isCheck) {
    return;
  }
  isCheck = false;
  await startPollingCheckRolesChanged();
};
