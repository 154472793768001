var render = function render(){var _vm=this,_c=_vm._self._c;return _c('detail',{ref:"detail",attrs:{"is-event":_vm.isEvent,"is-plain-text":false,"id":_vm.id,"expand-form":_vm.expandForm,"notification-type":_vm.notificationType},on:{"save-successful":_vm.onSaveSuccessful,"label-select":_vm.onLabelSelect},scopedSlots:_vm._u([{key:"formBar",fn:function(){return [_c('div',[_c('k-button-back',{attrs:{"back-route":_vm.isEvent ? 'eleReport' : 'report',"back-query":{ tabindex: _vm.$route.query.tabindex }}},[_vm._v(" buttons.back ")]),_c('k-button',{ref:"btnSave",staticClass:"mr-1",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" buttons.save ")])],1)]},proxy:true},{key:"formBarNav",fn:function(){return [_c('k-alert-msg',{ref:"lblMsg"}),_c('ReportNav',{ref:"nav",attrs:{"tab-index":_vm.navIndex,"is-plain-text":false,"is-event":_vm.isEvent,"is-notification":true}}),_c('NotificationNav',{ref:"notificationNav",attrs:{"notification-type":_vm.notificationType,"click-tab":_vm.clickTab}})]},proxy:true},{key:"insertLabel",fn:function(){return [_c('InsertLabels',{ref:"insertLabels",attrs:{"system-type":_vm.systemType}})]},proxy:true},{key:"smsInsertLabel",fn:function({ form }){return [_c('div',{staticClass:"col-form-label",attrs:{"id":"insertLabel"}},[_c('img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
          placement: 'topright',
          trigger: 'hover',
          title: 'Insert Label',
          boundary: 'window',
          container: 'insertLabel',
        }),expression:"{\n          placement: 'topright',\n          trigger: 'hover',\n          title: 'Insert Label',\n          boundary: 'window',\n          container: 'insertLabel',\n        }"},{name:"b-modal",rawName:"v-b-modal.popcolumnconfig",modifiers:{"popcolumnconfig":true}}],staticStyle:{"cursor":"pointer"},attrs:{"src":require("../../../../../assets/image/InsertLabel.png"),"alt":"insertLabel"}})]),_c('k-popwindow',{attrs:{"id":"popcolumnconfig","ok-title":"Save","before-close":_vm.onBeforeClose},on:{"ok":function($event){return _vm.onPopSelected(form)}}},[_c('InsertLabels',{ref:"smsInsertLabels",attrs:{"system-type":_vm.systemType,"notification-type":_vm.notificationType}})],1)]}},{key:"formGroup",fn:function({ form, plaintext }){return [_vm._t("formGroup",null,{"form":form,"plaintext":plaintext})]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }