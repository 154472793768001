<template>
  <k-tabs v-model="tabsIndex" class="search-area">
    <k-tab class="search-container" v-if="showTemplate">
      <template #title>
        <div class="tab-lable">
          {{ $g("template") }}
        </div>
      </template>
      <div class="search-area-main">
        <chooseTemplateList
          :is-event="isEvent"
          :template-type="templateType"
          @choose="onTemplateChoose"
          single-check
          :key="templateListKey"
          ref="chooseTemplateList"
        />
      </div>
      <div class="search-area-footer mt-3">
        <!-- <k-button variant="primary" class="mr-1" @click="onTemplateDeleteClick">
          buttons.delete
        </k-button> -->
      </div>
    </k-tab>
    <k-tab
      v-if="templateType == enumTemplateType.email"
      class="search-container"
    >
      <template #title>
        <div class="tab-lable">
          {{ $g("documentation") }}
        </div>
      </template>
      <div>
        <chooseTemplateList
          :is-event="isEvent"
          :template-type="enumTemplateType.documentation"
          @choose="onDocChoose"
          ref="chooseDocList"
          :isNotification="isNotification"
        />
      </div>
      <div class="search-area-footer mt-3">
        <!-- <k-button variant="primary" class="mr-1" @click="onDocDeleteClick">
          buttons.delete
        </k-button> -->
      </div>
    </k-tab>
  </k-tabs>
</template>

<script>
import chooseTemplateList from "./chooseTemplateList";
import { enumTemplateType } from "../enums/enumTemplateType";
import { delTemplate } from "../api/messageTemplate";

export default {
  components: { chooseTemplateList },
  props: {
    showTemplate: {
      type: Boolean,
      default() {
        return true;
      }
    },
    templateType: {
      type: Number,
      default() {
        return 0;
      }
    },
    isEvent: {
      type: Boolean,
      default() {
        return false;
      }
    },
    isNotification: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      tabsIndex: 0,
      enumTemplateType: enumTemplateType,
      keyNum: 0
    };
  },
  computed: {
    templateListKey() {
      return "templateListKey" + this.keyNum;
    }
  },
  methods: {
    onTemplateDeleteClick() {
      let ids = [];
      this.$refs.chooseTemplateList.catagoryList.forEach((catagory) => {
        catagory.children.forEach((child) => {
          if (child.check) ids.push(child.id);
        });
      });
      if (ids.length === 0) {
        this.$alert({
          title: this.$g("const.confirm.confirm"),
          content: this.$g("const.warning.select-records"),
          okText: this.$g("const.confirm.ok")
        });
        return;
      }

      let pmOpt = this.$confirm({
        title: this.$g("const.confirm.confirm"),
        content: this.$g("const.confirm.deleteTemplate"),
        okText: this.$g("const.confirm.yes"),
        cancelText: this.$g("const.confirm.no"),
        cancelType: "secondary"
      });
      if (pmOpt == null) {
        return;
      }
      pmOpt.then(() => {
        delTemplate(ids).then((m) => {
          this.$refs.chooseTemplateList.getCatagoryList();
          this.$emit("templateDelete", ids, m.data.status, m.message);
        });
      });
    },
    onDocDeleteClick() {
      let tempIds = [];
      let ids = [];
      this.$refs.chooseDocList.catagoryList.forEach((catagory) => {
        catagory.children.forEach((child) => {
          if (child.check) {
            ids.push(child.id);
            tempIds.push(child.tempId);
          }
        });
      });
      if (ids.length === 0) {
        this.$alert({
          title: this.$g("const.confirm.confirm"),
          content: this.$g("const.warning.select-records"),
          okText: this.$g("const.confirm.ok")
        });
        return;
      }

      let pmOpt = this.$confirm({
        title: this.$g("const.confirm.confirm"),
        content: this.$g("const.confirm.deleteDocumentation"),
        okText: this.$g("const.confirm.yes"),
        cancelText: this.$g("const.confirm.no"),
        cancelType: "secondary"
      });
      if (pmOpt == null) {
        return;
      }
      pmOpt.then(() => {
        delTemplate(tempIds).then((m) => {
          this.$refs.chooseDocList.getCatagoryList();
          this.$emit("docDelete", ids, m.data.status, m.message);
        });
      });
    },
    refreshTemplateList() {
      this.keyNum++;
    },
    onTemplateChoose(template) {
      this.$emit("templateChoose", template);
    },
    onDocChoose(doc) {
      this.$emit("docChoose", doc);
    }
  }
};
</script>

<style>
.search-area .nav-tabs {
  padding-right: 0px !important;
}
</style>
