var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"frag",rawName:"v-frag"}]},_vm._l((_vm.extFields),function(item,index){return _c('k-form-group',{key:index,attrs:{"label-class":_vm.isRequired ? 'require-mark' : '',"label-suffix":item.displayName,"label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","no-locale":true,"label-for":"noneBind"}},[(item.type === _vm.fieldType.string)?_c('KValidationProvider',{attrs:{"rules":{ required: _vm.isRequired, max: 200 },"slim":"","name":`text${index}`},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),(item.type === _vm.fieldType.string)?_c('k-form-input',{attrs:{"type":"text","plaintext":_vm.handleplaintext(item.isOnlyView)},model:{value:(item.inputValue),callback:function ($$v) {_vm.$set(item, "inputValue", $$v)},expression:"item.inputValue"}}):_vm._e()]}}],null,true)}):_vm._e(),(item.type === _vm.fieldType.int)?_c('KValidationProvider',{attrs:{"rules":{
        required: _vm.isRequired,
        numberBetween: { minValue: -100000, maxValue: 100000 },
      },"slim":"","name":`number${index}`},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),(item.type === _vm.fieldType.int)?_c('k-form-input',{attrs:{"plaintext":_vm.handleplaintext(item.isOnlyView)},model:{value:(item.inputValue),callback:function ($$v) {_vm.$set(item, "inputValue", $$v)},expression:"item.inputValue"}}):_vm._e()]}}],null,true)}):_vm._e(),(item.type === _vm.fieldType.datetime)?_c('KValidationProvider',{attrs:{"rules":{
        required: _vm.isRequired,
        validateDateIsRequired: _vm.isRequired,
        validateDateFormat: true,
      },"slim":"","name":`datetime${index}`},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0 && !_vm.plaintext,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),(item.type === _vm.fieldType.datetime)?_c('k-form-datepicker',{staticClass:"mb-2",attrs:{"plaintext":_vm.handleplaintext(item.isOnlyView)},model:{value:(item.inputValue),callback:function ($$v) {_vm.$set(item, "inputValue", $$v)},expression:"item.inputValue"}}):_vm._e()]}}],null,true)}):_vm._e(),(item.type === _vm.fieldType.phone)?_c('KValidationProvider',{attrs:{"rules":{ required: _vm.isRequired, phone: true, max: 50 },"slim":"","name":`phone${index}`},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),(item.type === _vm.fieldType.phone)?_c('k-form-phone-input',{attrs:{"plaintext":_vm.handleplaintext(item.isOnlyView),"is-phone":true},model:{value:(item.inputValue),callback:function ($$v) {_vm.$set(item, "inputValue", $$v)},expression:"item.inputValue"}}):_vm._e()]}}],null,true)}):_vm._e(),(item.type === _vm.fieldType.email)?_c('KValidationProvider',{attrs:{"rules":{ required: _vm.isRequired, max: 200, email: true },"slim":"","name":`email${index}`},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),(item.type === _vm.fieldType.email)?_c('k-form-input',{attrs:{"type":"text","plaintext":_vm.handleplaintext(item.isOnlyView)},model:{value:(item.inputValue),callback:function ($$v) {_vm.$set(item, "inputValue", $$v)},expression:"item.inputValue"}}):_vm._e()]}}],null,true)}):_vm._e()],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }