export const aloneMobileLayout = {
    data() {
        return {
            seenSearch: true,
            isActive: true,
        }
    },
    mounted() {
        this.toggleResponsive();
        window.addEventListener("resize", this.toggleResponsive)
    },
    methods: {
        onToggleSearch() {
            this.seenSearch = !this.seenSearch;
            this.isActive = !this.isActive;
        },
        toggleResponsive() {
            if (window.innerWidth < 1024) {
                if (this.seenSearch) {
                    this.onToggleSearch();
                }
            }
        }
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.toggleResponsive)
    }
}