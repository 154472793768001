<template>
  <div>
    <b-tabs v-model="tabindex">
      <b-tab
        v-for="(item, index) in items"
        :title="item.title"
        :key="index"
        @click="clickTab(item, index)"
      />
    </b-tabs>
  </div>
</template>
<script>
import { widgetNavAllTabs } from "./widgetTabs";
import { getTabIndexByRouteName, handleTabs } from "../../../utils/permission/permission";

export default {
  data(){
    return {
      permissionCode: this.$route.meta.permissionCode,
      tabindex: 0,
      items: [],
    };
  },
  created(){
    this.items = handleTabs(widgetNavAllTabs(this.isEvent, this.permissionCode), this);
    this.tabindex = getTabIndexByRouteName(this.items, this);
  },
  methods: {
    clickTab(item){
      if(this.tabindex != item.index){
        this.$router.push({
          name: item.route,
          query: {
            id: this.$route.query.id,
          },
        });
      }
    },
  },
  props: {
    isEvent: {
      type: Boolean,
      default(){
        return false;
      },
    },
    isAdd: {
      type: Boolean,
      default(){
        return false;
      },
    },
  },
};
</script>
