<template>
  <div class="content-page">
    <KValidationObserver ref="observer" v-slot="valdata" class="content-page">
      <div class="content-page">
        <div class="content">
          <k-form
            ref="form"
            class="content-form"
            @submit.prevent="onSubmit"
            @keydown.enter="isPrevent"
          >
            <div class="nav-btn-group">
              <k-button-group>
                <slot name="formBar" v-bind="valdata" />
              </k-button-group>
              <KWikiHelp :propsWikiHelpCode="wikiHelpCode" />
            </div>
            <div class="content-main">
              <k-alert-msg ref="lblMsg" />
              <div class="content-navTab">
                <slot name="formBarNav" />
              </div>
              <div class="content-party pt-3">
                <div class="content-group">
                  <NotificationDetailDefaultForm
                    :isPlainText="isPlainText"
                    :form="form"
                    :isEvent="isEvent"
                    :electionId="electionId"
                  />
                  <slot
                    name="formGroup"
                    :form="form"
                    :plaintext="isPlainText"
                  />
                </div>
                <KValidationObserver ref="LanguageListObserver">
                  <LanguageList
                    ref="languageList"
                    :personLanguages="personLanguages"
                    :is-validation="true"
                    @onLanguageSwitch="onLanguageSwitch"
                  >
                    <template #content>
                      <NotificationDetailContent
                        ref="notificationDetailContent"
                        :formDetailContent="formDetailContent"
                        :isPlainText="isPlainText"
                        :formDetailContentChange="formDetailContentChange"
                        :isEvent="isEvent"
                        :electionId="electionId"
                        @label-select="onLabelSelect"
                        :notificationType="notificationType"
                      >
                        <template #insertLabel>
                          <slot name="insertLabel" />
                        </template>
                        <template #smsInsertLabel="{ form }" :form="form">
                          <slot name="smsInsertLabel" :form="form" />
                        </template>
                      </NotificationDetailContent>
                    </template>
                  </LanguageList>
                </KValidationObserver>
              </div>
            </div>
          </k-form>
        </div>
      </div>
    </KValidationObserver>
  </div>
</template>

<script>
let _ = require("lodash");
import { repResult } from "../../../../../../libs/global";
import {
  getNotification,
  addNotification,
  updateNotification,
} from "../api/notification";

import LanguageList from "./languageList.vue";
import { originDataChangeMixin } from "../../../../../../utils/mixins/mixin";
import storeage from "../../../../../../utils/storeage";
import NotificationDetailContent from "./notificationDetailContent.vue";
import NotificationDetailDefaultForm from "./notificationDetailDefaultForm.vue";

export default {
  mixins: [originDataChangeMixin],
  components: {
    NotificationDetailContent,
    NotificationDetailDefaultForm,
    LanguageList,
  },
  props: {
    expandForm: {
      type: Array,
      default() {
        return [];
      },
    },
    id: {
      type: String,
      default() {
        return "";
      },
    },
    isPlainText: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isEvent: {
      type: Boolean,
      default() {
        return false;
      },
    },
    notificationType: {
      type: Number | String,
      default: 0,
    },
  },
  data() {
    return {
      seenSearch: true,
      isActive: false,
      maxContent: 1500,
      electionId: this.isEvent ? storeage.getElectionId() : "",
      form: {
        electionId: "",
        subject: "",
        activate: null,
        notificationFrequency: null,
        sendTo: null,
        personLanguages: [],
        msgTemplateLanguageDetails: [],
        weekMonth: null,
        dailyTime: null,
        months: null,
        users: [],
        sendRule: "",
        description: null,
        tempDocId: null,
        templateType: this.notificationType,
      },
      formDetailContent: { subject: "", content: "" },
      templateTypeFormMap: new Map(),
      formDetailContentNewMap: new Map(),
      personLanguages: [],
      nowLanguage: "",
      reportId: this.$route.query.id,
      wikiHelpCode: this.isEvent
        ? this.$wikiHelpLinkCodes?.Event.Report.ReportProfile.Notification
        : this.$wikiHelpLinkCodes?.BAU.Report.ReportProfile.Notification,
    };
  },
  computed: {},
  created() {
    this.initForm();
  },
  mounted() {},
  methods: {
    initForm() {
      let query = {
        id: this.$route.query.id,
        templateType: this.notificationType,
      };
      getNotification(query).then((result) => {
        this.form = result.data;
        if (!this.form.activate) {
          this.form.activate = 1;
        }
        this.form.users = this.form.users ?? [];
        this.form.reportId = this.form.reportId ?? this.reportId;
        this.initLanguagesList(result.data.personLanguages);
        this.initFormDetailContentNewMap();
        this.$nextTick(() => {
          this.$refs.observer.reset();
        });
        setTimeout(() => {
          this.setOrignData(this.form);
        }, 500);
      });
    },
    initLanguagesList(languages) {
      this.personLanguages = languages;
      this.personLanguages.forEach((element) => {
        if (element.isDefault) {
          this.$set(element, "active", true);
        }
      });
      this.nowLanguage = this.personLanguages.find((c) => c.isDefault);
    },
    initFormDetailContentNewMap() {
      if (this.form.msgTemplateLanguageDetails.length > 0) {
        for (const item of this.form.msgTemplateLanguageDetails) {
          this.personLanguages.find(
            (c) => c.id == item.languageId
          ).isData = true;
          this.formDetailContentNewMap.set(item.languageId, item);
        }
        this.formDetailContent = this.formDetailContentNewMap.get(
          this.nowLanguage.id
        ) ?? { subject: "", content: "" };
      }
    },
    onLanguageSwitch(item) {
      this.nowLanguage = item;
      this.formDetailContent = this.formDetailContentNewMap.get(item.id) ??
        this.templateTypeFormMap.get(item.id) ?? {
          languageId: item.id,
          subject: "",
          content: "",
        };
    },
    formDetailContentChange(NewData, OldData) {
      if (NewData != OldData) {
        let j = JSON.parse(NewData);
        if (!j) {
          return;
        }
        j.languageId = this.nowLanguage.id;
        this.formDetailContentNewMap.set(this.nowLanguage.id, j);
        if (j.subject && j.content) {
          this.nowLanguage.isData = true;
        } else {
          this.nowLanguage.isData = false;
        }
      }
    },
    async onSubmit() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        let pmOpt = this.$confirm({
          title: this.$g("const.confirm.confirm"),
          content: this.$g("const.confirm.save"),
          cancelType: "secondary",
          okText: this.$g("const.confirm.saveYes"),
          cancelText: this.$g("const.confirm.no"),
        });
        pmOpt.then(() => {
          this.form.msgTemplateLanguageDetails = Array.from(
            this.formDetailContentNewMap.values()
          ).filter((c) => c.content && c.subject);
          this.form.templateType = this.notificationType;
          let api = addNotification;
          if (
            !this.form.id &&
            this.form.id != "00000000-0000-0000-0000-000000000000"
          ) {
            api = updateNotification;
          }
          api(this.form).then((m) => {
            if (m.data.status != repResult.faild) {
              this.$emit("save-successful", m);
            } else {
              this.$refs.lblMsg.message(m.data.status, m.message);
            }
          });
        });
      }
    },
    onLabelSelect() {
      this.$emit("label-select");
    },
  },
  beforeDestroy() {},
};
</script>
