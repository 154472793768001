var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('detail',{attrs:{"plaintext":true},scopedSlots:_vm._u([{key:"formBar",fn:function(){return [_c('b-btn-group',[_c('k-button-back',{attrs:{"back-route":_vm.isEvent ? 'eleTemplate' : 'template',"back-query":{ tabindex: _vm.$route.query.tabindex }}},[_vm._v(" buttons.back ")]),_c('k-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.canBeEdit),expression:"canBeEdit"},{name:"permission",rawName:"v-permission",value:(_vm.permissionCode.Report.TemplateEdit),expression:"permissionCode.Report.TemplateEdit"}],on:{"click":function($event){return _vm.$router.push({
              name: _vm.isEvent ? 'eleTemplate-edit' : 'template-edit',
              query: {
                id: _vm.$route.query.id,
                tabindex: _vm.$route.query.tabindex,
              },
            })}}},[_vm._v(" buttons.edit ")]),_c('k-pre-next',{ref:"pn",attrs:{"has-save-next":false}}),_c('k-button-delete',{directives:[{name:"permission",rawName:"v-permission",value:(_vm.permissionCode.Report.TemplateDelete),expression:"permissionCode.Report.TemplateDelete"}],attrs:{"delete-handler":_vm.deleteHandler,"redirect-route-name":_vm.isEvent ? 'eleTemplate' : 'template',"del-id":_vm.$route.query.id},on:{"delete-failed":_vm.deleteFailed}})],1)]},proxy:true},{key:"formMsg",fn:function(){return [_c('k-alert-msg',{ref:"lblMsg"})]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }