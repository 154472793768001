<template>
  <div>
    <b-checkbox v-show="false" :checked="allSelected.length > 0" />
    <b-table
      :items="items"
      :fields="gridFields"
      :select-mode="selectMode"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :class="tableClass"
      responsive="sm"
      ref="selectableTable"
      no-provider-paging
      selectable
      :no-select-on-click="true"
      no-local-sorting
      no-sort-reset
      @sort-changed="sortingChanged"
      @row-selected="onRowSelected"
      :tbody-tr-class="rowClass"
      :tbody-tr-attr="rowAttr"
      :sticky-header="stickyHeader"
      sort-icon-left
    >
      <template #cell(All)="row">
        <template v-if="row.item.readonly && rowReadOnly">
          <div />
        </template>
        <template v-else-if="row.rowSelected">
          <b-checkbox
            @change="selectRecord(row, $event)"
            checked="true"
            :disabled="row.item.disabled"
          />
          <span class="sr-only">Selected</span>
        </template>
        <template v-else>
          <b-checkbox
            @change="selectRecord(row, $event)"
            checked="false"
            :disabled="row.item.disabled"
          />
          <span class="sr-only">Not selected</span>
        </template>
      </template>
      <template #head(All)="data">
        <b-checkbox
          v-if="showAllCheckbox"
          @change="toggleSelect"
          v-model="selectAll"
          :disabled="items.length === 0 || selectAllDisabled"
        >
          {{ data.label }}
        </b-checkbox>
        <span v-else>{{ "" }}</span>
      </template>
      <template #cell(actions)="row">
        <slot
          :name="cellSlotName({ key: 'actions', label: actionNameLabel })"
          v-bind="row"
        >
          <b-link
            v-if="!row.item.hideAction"
            @click="info(row.item, row.index, $event.target)"
            class="mr-1"
          >
            {{ actionName }}
          </b-link>
        </slot>
      </template>
      <template v-for="field in cellSlots" #[cellSlotName(field)]="data">
        <slot :name="cellSlotName(field)" v-bind="data">
          {{ data.value }}
        </slot>
      </template>
      <template v-for="field in cellSlots" #[headSlotName(field)]="data">
        <slot :name="headSlotName(field)" v-bind="data">
          {{ data.label }}
        </slot>
      </template>
      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{ width: field.width }"
        />
      </template>
      <template #table-caption v-if="hasFooter">
        <slot name="tableCaption">
          <div v-show="items.length == 0" class="noResults">
            {{ $g("noResultsAreAvailable") }}
          </div>
        </slot>
      </template>
    </b-table>
    <div v-if="gridFooterString">
      {{ gridFooterString }}
    </div>
    <div class="form-inline table-pagination" v-if="hasPager">
      <div>
        <b-form-select
          id="ddlPageSelect"
          v-model="perPage"
          :options="pageOptions"
          size="sm"
          @change="pageSizeChange"
          class="mr-1 w-auto"
          :aria-label="$g('konnGrid.paginationSelect')"
        />
      </div>
      <div>
        <b-pagination
          :total-rows="totalRows"
          :per-page="perPage"
          size="sm"
          :class="['my-0 mt-1 d-inline-flex', ...pageClass]"
          @change="onPaginationChange"
          v-model="currentPage"
          :key="paginationKey"
        />
      </div>
      <div>
        <span class="mr-3"
          >{{ totalRowsDisplay }} {{ $g("konnGrid.totalRecords") }}
        </span>
        {{ $g("konnGrid.goTo") }}

        <vue-typeahead-bootstrap
          v-model="currentPageStr"
          :data="pageSelectOptions"
          :show-on-focus="true"
          @input="handlePageInput"
          class="page-change"
          :class="{ pageBottom: isPageBottom }"
          @hit="handlePageSelect"
          @keyup.enter="handlePageBlur"
          size="sm"
          :max-matches="pageSelectOptions.length"
          input-class="pageInput"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { pageNavUtil } from "../../utils/pageNavUtil";
import VueTypeaheadBootstrap from "vue-typeahead-bootstrap";
import eventBus from "../../utils/eventBus";

export default {
  components: { VueTypeaheadBootstrap },
  props: {
    pageClass: {
      type: Array,
      default() {
        return [];
      },
    },
    idField: {
      type: String,
      default() {
        return null;
      },
    },
    hasPager: {
      type: Boolean,
      default() {
        return true;
      },
    },
    selectMode: {
      type: String,
      default() {
        return "multi";
      },
    },
    listId: {
      type: String,
      default() {
        return null;
      },
    },
    showAllCheckbox: {
      type: Boolean,
      default() {
        return true;
      },
    },
    fields: {
      type: Array,
      default() {
        return null;
      },
    },
    getCondition: {
      type: Function,
      default() {
        return null;
      },
    },
    dataSource: {
      type: Function,
      default() {
        return null;
      },
    },
    hasCheckBox: {
      type: Boolean,
      default() {
        return true;
      },
    },
    hasAction: {
      type: Boolean,
      default() {
        return true;
      },
    },
    autoBind: {
      type: Boolean,
      default() {
        return true;
      },
    },
    actionName: {
      type: String,
      default() {
        return this.$g("konnGrid.edit");
      },
    },
    actionNameLabel: {
      type: String,
      default() {
        return this.$g("konnGrid.actions");
      },
    },
    tableClass: {
      type: Object,
      default() {
        return null;
      },
    },
    rowClass: {
      type: Function,
      default() {
        return null;
      },
    },
    rowAttr: {
      type: Function,
      default() {
        return null;
      },
    },
    setParas: {
      type: Function,
      default() {
        return null;
      },
    },
    gridSortBy: {
      type: String,
      default() {
        return "";
      },
    },
    gridSortDesc: Boolean,
    autoSelect: {
      type: Boolean,
      default() {
        return false;
      },
    },
    ChangeCheckboxFunc: {
      type: Function,
      default() {
        return null;
      },
    },
    stickyHeader: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isOriginalFields: {
      type: Boolean,
      default() {
        return false;
      },
    },
    gridFooterString: {
      type: String,
      default() {
        return null;
      },
    },
    rowReadOnly: {
      type: Boolean,
      default: false,
    },
    hasFooter: {
      type: Boolean,
      default: true,
    },
    ignoreSelectedProps: {
      type: Boolean,
      default: false,
    },
    tdAttr: {
      type: Function,
      default: null,
    },
    //no search template , not need listener
    hasSearchDefaultTemplate: {
      type: Boolean,
      default: true,
    },
    replaceKeyList: {
      type: Array,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      perPage: 20,
      totalRows: -1,
      currentPage: 1,
      currentPageStr: "",
      pageOptions: [
        { value: 5, text: this.$g("konnGrid.page5") },
        { value: 20, text: this.$g("konnGrid.page20") },
        { value: 50, text: this.$g("konnGrid.page50") },
        { value: 100, text: this.$g("konnGrid.page100") },
      ],
      sortBy: this.gridSortBy,
      sortDesc: this.gridSortDesc,
      items: [],
      gridFields: this.gFields(this.fields),
      optMsg: null,
      needLoad: false,
      globaled: false,
      selectAll: false,
      needReloadFields: false,
      paginationKey: 0,
      pageSelectOptions: [],
      disabledAndUnCheckBox: 0,
      isPageBottom: false,
      allSelected: [],
      uniqueId: this.idField,
      ignoreSelected: this.ignoreSelectedProps,
      // setIntervalBindGridId: null,
      // setIntervalBindGridIndex: 0,
      selectAllDisabled: false,
    };
  },
  computed: {
    cellSlots() {
      if (!this.gridFields) {
        return [];
      }
      return this.gridFields.filter(
        (f) => f.key !== "All" && f.key !== "actions"
      );
    },
    totalRowsDisplay() {
      if (this.totalRows > 0) {
        return this.totalRows;
      }
      return 0;
    },
  },
  methods: {
    setSelectAll(value) {
      this.selectAll = value;
    },
    onPaginationChange(value) {
      this.currentPageStr = "";
      this.paginationChange(value);
    },
    handlePageBlur() {
      if (this.currentPage != this.currentPageStr) {
        this.paginationChange(parseInt(this.currentPageStr));
      }
    },
    handlePageInput(val) {
      let pageStr;
      if (isNaN(parseInt(val))) {
        pageStr = "";
      } else {
        let page = parseInt(val);
        let pageCount = (this.totalRows + this.perPage - 1) / this.perPage;
        if (page < 1) {
          page = 1;
        } else if (page > pageCount) {
          page = parseInt(pageCount);
        }
        pageStr = page + "";
      }
      this.$nextTick(() => {
        this.currentPageStr = pageStr;
      });
    },
    handlePageSelect(val) {
      this.paginationChange(parseInt(val));
    },
    getPageSelectOption() {
      let array = [];
      let pageCount = (this.totalRows + this.perPage - 1) / this.perPage;
      for (let i = 0; i < parseInt(pageCount); i++) {
        array.push(i + 1 + "");
      }
      this.pageSelectOptions = array;
    },
    gFields(fields) {
      if (
        !this.globaled &&
        !this.isOriginalFields &&
        fields &&
        fields.length > 0
      ) {
        let gFields = fields.slice(0);
        gFields.forEach((m) => {
          if (m.label) {
            m.label = this.$g(m.label);
          }
        });
        return gFields;
      } else {
        return fields;
      }
    },
    getAllRows() {
      return this.items;
    },
    confirmOpt(msg, noSelectedMsg) {
      if (
        (!this.allSelected || this.allSelected.length === 0) &&
        !this.ignoreSelected
      ) {
        this.$alert({
          title: this.$g("const.confirm.confirm"),
          content: noSelectedMsg
            ? noSelectedMsg
            : this.$g("const.warning.select-records"),
          okText: this.$g("const.confirm.ok"),
        });
        return null;
      }
      return this.$confirm({
        title: this.$g("const.confirm.confirm"),
        content: msg,
        okText: this.$g("const.confirm.yes"),
        cancelText: this.$g("const.confirm.no"),
        cancelType: "secondary",
      });
    },
    checkSelected() {
      if (!this.allSelected || this.allSelected.length === 0) {
        this.$alert({
          title: this.$g("const.confirm.confirm"),
          content: this.$g("const.warning.select-records"),
          okText: this.$g("const.confirm.ok"),
        });
        return null;
      } else {
        return true;
      }
    },
    checkSingleSelected() {
      if (!this.allSelected || this.allSelected.length === 0) {
        this.$alert({
          title: this.$g("const.confirm.confirm"),
          content: this.$g("const.warning.select-records"),
          okText: this.$g("const.confirm.ok"),
        });
        return null;
      } else if (this.allSelected.length > 1) {
        this.$alert({
          title: this.$g("const.confirm.confirm"),
          content: this.$g("const.warning.select-one-records"),
          okText: this.$g("const.confirm.ok"),
        });
        return null;
      } else {
        return true;
      }
    },
    onRowSelected(items) {
      if (!items || items.length === 0) {
        this.selectAll = false;
        return;
      }
      if (!this.hasPager) {
        this.allSelected = items;
      }
      this.selectAll =
        items.length === this.items.length - this.disabledAndUnCheckBox;
      this.$emit("row-selected", this.allSelected);
    },
    sortingChanged(column) {
      if (column.sortBy) {
        this.sortBy = column.sortBy;
        this.sortDesc = column.sortDesc;
        var cd;
        let pageState = this.$store.getters.pageState[this.listId];
        if (pageState) {
          cd = pageState.condition;
        }
        this.dataProvider(cd);
      }
    },
    getSelectedItems() {
      return this.allSelected;
    },
    hasField(fieldName) {
      if (!this.gridFields) {
        return false;
      } else {
        let result = false;
        for (let item of this.gridFields) {
          if (item.key.toLowerCase() == fieldName.toLowerCase()) {
            result = true;
            break;
          }
        }
        return result;
      }
    },
    getSelectedIds(ifn) {
      return this.getIds(ifn, this.allSelected);
    },
    getAllIds(ifn) {
      return this.getIds(ifn, this.items);
    },
    getIds(ifn, lst) {
      let result = [];
      if (lst) {
        lst.forEach((e) => {
          if (!ifn) {
            ifn = "id";
          }
          if (e[ifn]) {
            result.push(e[ifn]);
          }
        });
      }
      return result;
    },
    getAllEndIds() {
      return pageNavUtil.getAllEndIds(this.items);
    },
    getAllCustomField() {
      return pageNavUtil.getAllCustomField(this.items);
    },
    cellSlotName(fieldName) {
      return "cell(" + fieldName.key + ")";
    },
    headSlotName(fieldName) {
      return "head(" + fieldName.key + ")";
    },
    pageSizeChange() {
      this.currentPage = 1;
      this.currentPageStr = "";
      this.paginationChange(this.currentPage);
    },
    refresh() {
      this.allSelected = [];
      this.needLoad = true;
      this.dataProvider();
    },
    refreshSetPage1() {
      this.allSelected = [];
      this.currentPage = 1;
      this.currentPageStr = "";
      this.needLoad = true;
      this.dataProvider();
    },
    refreshItem() {
      this.$refs.selectableTable.refresh();
    },
    paginationChange(value) {
      if (value > 0) {
        var cd;
        this.currentPage = value;
        let pageState = this.$store.getters.pageState[this.listId];
        if (pageState) {
          cd = pageState.condition;
        }
        this.bindGrid(cd);
      }
    },
    pageChange(value) {
      if (value > 0) {
        this.currentPage = value;
        this.currentPageStr = "";
        this.dataProvider();
      }
    },
    selectAllRows() {
      let disabledAndUnCheckBox = 0;
      for (let i = 0; i < this.items.length; i++) {
        if (!this.items[i].disabled) {
          this.selectRowByIndex(i);
          this.addSelected(this.items[i]);
        } else {
          if (!this.items[i].rowSelected) {
            disabledAndUnCheckBox++;
          }
        }
      }
      this.disabledAndUnCheckBox = disabledAndUnCheckBox;
    },
    clearSelected() {
      for (let i = 0; i < this.items.length; i++) {
        if (!this.items[i].disabled) {
          this.unselectRowByIndex(i);
          this.removeSelected(this.items[i]);
        }
      }
    },
    selectRecord(row, selected) {
      if (selected) {
        this.selectRowByIndex(row.index);
        this.addSelected(row.item);
      } else {
        this.unselectRowByIndex(row.index);
        this.removeSelected(row.item);
      }
      if (this.ChangeCheckboxFunc) {
        this.ChangeCheckboxFunc(row);
      }
    },
    removeSelected(item) {
      let index = -1;
      for (let i = 0; i < this.allSelected.length; i++) {
        if (this.allSelected[i][this.uniqueId] == item[this.uniqueId]) {
          index = i;
        }
      }
      if (index > -1) {
        this.allSelected.splice(index, 1);
      }
    },
    addSelected(item) {
      if (this.selectMode == "single") {
        this.allSelected.length = 0;
      }
      let array = this.allSelected.filter(
        (it) => it[this.uniqueId] == item[this.uniqueId]
      );
      if (!array || array.length == 0) {
        this.allSelected.push(item);
      }
    },
    clearFields() {
      this.needReloadFields = true;
      // this.gridFields = null
    },
    toggleSelect(checked) {
      if (checked) {
        this.selectAllRows();
      } else {
        this.clearSelected();
      }
    },
    selectRowByIndex(idx) {
      this.$refs.selectableTable?.selectRow(idx);
    },
    unselectRowByIndex(idx) {
      this.$refs.selectableTable?.unselectRow(idx);
    },
    dataProvider(cd) {
      let searchArea = this.$parent.$refs?.searchArea;
      let isSearchArea = searchArea?.getIsSearchArea() ?? true;
      if (searchArea && isSearchArea === false && this.autoBind) {
        this.items = [];
      } else if (!this.autoBind && !this.needLoad) {
        this.items = [];
      } else {
        if (isSearchArea === true || this.autoBind) {
          this.bindGrid(cd);
        }
      }
    },
    addNewRow(data, usePush) {
      if (usePush) {
        this.items.push(data);
      } else {
        this.items.unshift(data);
      }
    },
    insertRowData(data, index) {
      if (Array.isArray(data)) {
        this.items.splice(index, 0, ...data);
      } else {
        this.items.splice(index, 0, data);
      }
    },
    clearRow() {
      this.items = [];
    },
    insertNewRow(data, index) {
      this.items.splice(index, 0, data);
    },
    deleteNewRow(data) {
      let length = this.items.length;
      for (let i = 0; i < length; i++) {
        if (this.items[i] === data) {
          this.items.splice(i, 1); //删除下标为i的元素
        }
      }
    },
    deleteSelected() {
      var ret = [];
      if (this.allSelected) {
        for (let item of this.allSelected) {
          this.deleteNewRow(item);
          ret.push(item);
        }
        this.allSelected = [];
      }
      return ret;
    },
    getSearchState(cdtion) {
      if (!cdtion) {
        cdtion = this.getCondition();
      }
      let condition = this.getPageCondition();
      Object.assign(condition, cdtion);
      return condition;
    },
    getExportState(cdtion) {
      let condition = this.getPageCondition();
      Object.assign(condition, cdtion);
      return condition;
    },
    getPageState(cdtion) {
      let page = this.getPageAdditional();
      let rt = {};
      rt.pageAddtional = page;
      rt.condition = { ...cdtion };
      return rt;
    },
    bindGrid(condition) {
      let me = this;
      if (!condition) {
        condition = this.getCondition();
      }
      let searchState = this.getSearchState(condition);
      this.$emit("beforeRequest", searchState);
      return this.dataSource(searchState).then((data) => {
        this.$emit("requestChange", data);
        if (data.data) {
          this.doApiListResult(data, me, searchState, condition);
        }
      });
    },
    doApiListResult(data, me, searchState, condition) {
      const items = data.data.rows;
      if (
        (!items || items.length === 0) &&
        data.data.total > 0 &&
        this.currentPage - 1 > 0
      ) {
        this.paginationChange(this.currentPage - 1);
        return;
      }
      this.totalRows = data.data.total;
      this.$emit("total-changed", this.totalRows);
      this.$emit("dataTotalChange", this.totalRows);
      this.bindGridFuncGridFields(data, items, me, searchState, condition);

      this.getPageSelectOption();

      this.$nextTick(() => {
        this.isPageBottom =
          this.$refs.selectableTable?.$el.offsetHeight > 200 ? false : true;

        let pageInput = document.getElementsByClassName("pageInput")[0];
        if (pageInput) {
          pageInput.ariaLabel = this.$g("konnGrid.goToPage");
        }
      });
    },
    bindGridFieldsFunc1(data) {
      if (this.gridFields == null) {
        this.gridFields = data.data.fields;
        this.$emit("fields-get", data.data.fields);
      } else if (this.needReloadFields) {
        if (this.fields) {
          this.gridFields = this.gFields(this.fields);
        } else {
          this.gridFields = data.data.fields;
        }
        this.$emit("fields-get", data.data.fields);
        this.needReloadFields = false;
      }
      if (this.gridFields == null) {
        this.gridFields = [];
      }
      this.gridFields.forEach((item) => {
        if (item.hasTdAttr && this.tdAttr) {
          item.tdAttr = this.tdAttr;
        }
        if (!item.class) {
          switch (item.horAlignment) {
            case 0:
              item.class = "text-center";
              break;
            case 1:
              item.class = "text-left";
              break;
            case 2:
              item.class = "text-right";
              break;
          }
        }
      });
    },
    getUniqueId() {
      let id;
      if (this.items && this.items.length > 0) {
        let ids = Object.keys(this.items[0]).filter((key) =>
          key.toLowerCase().endsWith("id")
        );
        if (ids && ids.length > 0) {
          ids.forEach((item) => {
            if (item.toLowerCase() === "id") {
              id = item;
            }
          });
          if (!id) {
            id = ids[0];
          }
        } else {
          id = "id";
        }
      }
      return id;
    },
    bindGridFieldsFunc2() {
      if (this.hasCheckBox) {
        this.gridFields = [
          ...[{ key: "All", label: "", width: "35px" }],
          ...this.gridFields,
        ];
      } else {
        if (this.gridFields[0].key === "All") this.gridFields.splice(0, 1);
      }
      if (this.hasAction) {
        this.gridFields = [
          ...this.gridFields,
          ...[{ key: "actions", label: this.actionNameLabel }],
        ];
      } else {
        if (this.gridFields[this.gridFields.length - 1].key === "actions")
          this.gridFields.splice(this.gridFields.length - 1, 1);
      }
      this.gridFields[this.gridFields.length - 1].width = "";
    },
    bindGridFuncGridFields(data, items, me, searchState, condition) {
      this.bindGridFieldsFunc1(data);
      this.camelCaseField(items);
      this.bindGridFieldsFunc2();
      me.items = items || [];
      let that = this;
      this.$emit("items-get", items, searchState);
      this.$emit("all-get", data);
      let pageState = this.getPageState(condition);
      let exportCondition = this.getExportState(condition);
      if (this.$store) {
        this.$store.dispatch("page/savePageState", {
          listId: this.listId,
          condition: pageState,
          exportCondition: exportCondition,
        });
      }
      this.setTimeoutFunc(that);
      if (!this.uniqueId) {
        this.uniqueId = this.getUniqueId();
      }
    },
    setTimeoutFunc(that) {
      that.selectAll = false;
      that.$refs.selectableTable?.clearSelected();
      if (this.autoSelect) {
        this.$nextTick(() => {
          for (let i = 0; i < that.items.length; i++) {
            if (that.items[i].rowSelected) {
              that.selectRowByIndex(i);
              this.allSelected.push(that.items[i]);
            }
          }
        }, 100);
      } else {
        this.$nextTick(() => {
          for (let i = 0; i < that.items.length; i++) {
            if (that.hasItemSelected(that.items[i])) {
              that.selectRowByIndex(i);
            }
          }
        });
      }
    },
    hasItemSelected(item) {
      let array = this.allSelected.filter(
        (it) => it[this.uniqueId] && it[this.uniqueId] == item[this.uniqueId]
      );
      return array && array.length > 0;
    },
    camelCaseField(rows) {
      if (
        !rows ||
        rows.length === 0 ||
        !this.gridFields ||
        this.gridFields.length === 0
      ) {
        return;
      }
      let dataFields = {};
      for (let f in rows[0]) {
        dataFields[f.toLowerCase()] = f;
      }
      for (let item of this.gridFields) {
        let lField = item.key.toLowerCase();
        let fieldName = dataFields[lField];
        if (fieldName) {
          item.key = fieldName;
        }
      }
    },
    info(item, index, target) {
      this.$emit("edit-clicked", item, index, target);
    },
    cancel(item, index, target) {
      this.$emit("cancel-clicked", item, index, target);
    },
    getPageCondition() {
      let sortBy = this.getReplaceKey(this.sortBy);
      if (!sortBy) {
        sortBy = this.sortBy;
      }
      let param = {
        Sort: sortBy,
        Order: this.sortDesc ? "Desc" : "Asc",
        SelectedGuidIDs: this.getSelectedIds(this.uniqueId),
      };
      if (this.hasPager) {
        param.Offset = (this.currentPage - 1) * this.perPage;
        param.Limit = this.perPage;
      }
      return param;
    },
    getReplaceKey(fieldName) {
      if (!fieldName) {
        return null;
      }
      if (!this.gridFields) {
        if (this.replaceKeyList) {
          let replaceKey = null;
          for (let item of this.replaceKeyList) {
            if (item.key.toLowerCase() == fieldName.toLowerCase()) {
              replaceKey = item.replaceKey;
              break;
            }
          }
          return replaceKey;
        } else {
          return null;
        }
      } else {
        let replaceKey = null;
        for (let item of this.gridFields) {
          if (item.key.toLowerCase() == fieldName.toLowerCase()) {
            replaceKey = item.replaceKey;
            break;
          }
        }
        return replaceKey;
      }
    },
    getSortBy() {
      return this.sortBy;
    },
    getPageAdditional() {
      return {
        ids: this.getAllEndIds(),
        currentPage: this.currentPage,
        rawCurrentPage: this.currentPage,
        perPage: this.perPage,
        sortBy: this.sortBy,
        sortDesc: this.sortDesc,
        totalRows: this.totalRows,
        navHandler: this.dataSource,
        hasPager: this.hasPager,
        routeName: this.$route?.name,
        query: this.$route?.query,
        customField: this.getAllCustomField(),
      };
    },
  },
  watch: {
    totalRows(newValue) {
      this.$emit("total-changed", newValue);
    },
  },
  mounted: function () {
    if (this.$route && this.$route.query.restore) {
      let pageState = this.$store.getters.pageState[this.listId];
      if (pageState) {
        let pa = pageState.pageAddtional;
        if (pa) {
          this.totalRows = pa.totalRows;
          this.currentPage = pa.rawCurrentPage;
          if (this.currentPage > 1) this.paginationKey++;
          this.perPage = pa.perPage;
          this.sortBy = pa.sortBy;
          this.sortDesc = pa.sortDesc;
        }
        let cd = pageState.condition;
        if (cd) {
          this.setParas(cd);
        }
      }
    }
    if (this.hasSearchDefaultTemplate) {
      eventBus.$on("defaultTemplateEnded", () => {
        // this.$store.dispatch("app/loading", false);
        console.warn("on defaultTemplateEnded");
        this.refresh();
      });
    }
    this.dataProvider();
  },
  beforeDestroy() {
    if (this.hasSearchDefaultTemplate) {
      // removing eventBus listener
      eventBus.$off("defaultTemplateEnded");
    }
  },
};
</script>
