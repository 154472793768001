import { g } from "../../../locale/lang-val";

export const widgetNavAllTabs = (isEvent, permissionCode) => {
  let tabs = [{
    title: g("tabs.detail.basicInfo"),
    index: 0,
    route: isEvent ? "eleReport-widget-edit" : "report-widget-edit",
    permissionCode: permissionCode.DashboardConfiguration.ConfigurationBasicInfo,
  }];
  return tabs;
};
