var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"wiki-help-link"},[(_vm.hasWikiHelp)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
      placement: 'bottomleft',
      trigger: 'hover',
      title: _vm.$g('icon.helpLink'),
      boundary: 'window',
      container: _vm.uid,
    }),expression:"{\n      placement: 'bottomleft',\n      trigger: 'hover',\n      title: $g('icon.helpLink'),\n      boundary: 'window',\n      container: uid,\n    }"}],staticClass:"float-right",attrs:{"id":_vm.uid},on:{"click":_vm.onclickEvent}},[_c('b-icon',{attrs:{"icon":"question-circle-fill","font-scale":"2"}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }