const logFieldsType = {
  activityLog: "Activity Log",
  communicationLog: "Communication Log",
  electionUseLog: "Election Use Log",
  surveyLogType: "Site Survey Log",
  boundaryLog: "Boundary Log",
  customizeLog: "Customize Log",
  // assessmentHistory: "Assessment History",
  // workHistory: "Work History",
  // applicationHistory: "Application History",

};

export {
  logFieldsType,
};
