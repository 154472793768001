export const enumSearchType = {
  unknown : 0,
  boundary : 1,
  organizations : 2,
  neighbour : 3,
  election : 4,
  facility : 5,
  eleBoundary : 6,
  subdivision : 7,
  eleVotingStation : 8,
  otherBooking : 9,
  eleOtherBooking : 10,
  eleSubdivision : 11,
  votingStation : 12,
  redistribution : 13,
  street : 14,
  eleFacility : 15,
  pontentialFacility : 16,
  facilityDepositPayment : 17,
  facilityRentalPayment : 18,
  facilityPayrollEvent : 19,
  facilityPayrollEventDetail : 20,
  invItem : 21,
  equipmentCategory : 22,
  equipmentItem : 23,
  communicationEmailTemplate : 24,
  communicationPhoneTemplate : 25,
  communicationSMSTemplate : 26,
  communicationLetterTemplate : 27,
  communicationDocumentTemplate : 28,
  communicationEmailDraft : 33,
  communicationPhoneDraft : 34,
  communicationSMSDraft : 35,
  communicationLetterDraft : 36,
  communicationEmailDelivery : 37,
  communicationPhoneDelivery : 38,
  communicationSMSDelivery : 39,
  communicationLetterDelivery : 40,
  communicationGroup : 41,

  FacilityDepositConfirmedPayment : 42,
  FacilityDepositApprovedPayment : 43,
  FacilityDepositPaidPayment : 44,
  FacilityDepositVoidPayment : 45,

  FacilityRentalConfirmedPayment : 46,
  FacilityRentalApprovedPayment : 47,
  FacilityRentalPaidPayment : 48,
  FacilityRentalVoidPayment : 49,

  notificationEmailTemplate : 74,
  notificationPhoneTemplate : 75,
  notificationSMSTemplate : 76,
  notificationLetterTemplate : 77,
  notificationDocumentTemplate : 78,
}
