import { getIsHideConactUs } from "@/api/home";
import { getDefaultLocationFirstNav } from "@/utils/permission/index";
import {
  g,
  showAlert,
  getToken
} from "fe-infrastractures"

export default [
  {
    name: 'contactUs',
    path: '/contactUs',
    meta: {
      title: 'contactUs', requiresAuth: true,
      anonymous: true
    },
    beforeEnter: async (to, from, next) => {
      let res = await getIsHideConactUs();
      if (res.data) {
        if (getToken()?.token) {
          let routerName = await getDefaultLocationFirstNav();
          showAlert(g("const.confirm.confirm"), g("const.confirm.noPermission"), g("const.confirm.ok"), () => {
            next({ name: routerName });
          });
        } else {
          showAlert(g("const.confirm.confirm"), g("const.confirm.noPermission"), g("const.confirm.ok"), () => {
            next({ name: "login" });
          });
        }

      } else {
        next();
      }
    },
    component: () => import('@/views/contactus/contactus'),
    route: true,

  },
]
