import { render, staticRenderFns } from "./messageDeliveryReport.vue?vue&type=template&id=7e480466&scoped=true"
import script from "./messageDeliveryReport.vue?vue&type=script&lang=js"
export * from "./messageDeliveryReport.vue?vue&type=script&lang=js"
import style0 from "./messageDeliveryReport.vue?vue&type=style&index=0&id=7e480466&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../fe-workerportal/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e480466",
  null
  
)

export default component.exports