import { g } from "../../../locale/lang-val";

export const templateNavAllTabs = (isEvent, isPlainText, isSystem, permissionCode) => {
  let allTabs = [];
  if(isEvent){
    allTabs.push({
      title: g("tabs.detail.basicInfo"),
      index: 0,
      route: isPlainText ? "eleTemplate-view" : "eleTemplate-edit",
      permissionCode: permissionCode.Report.TemplateBasicInfo,
    });
    if(!isSystem){
      allTabs.push({
        title: g("tabs.detail.permissions"),
        index: 1,
        route: "eleTemplate-permissions",
        permissionCode: permissionCode.Report.TemplatePermissions,
      });
    }
  } else {
    allTabs.push({
      title: g("tabs.detail.basicInfo"),
      index: 0,
      route: isPlainText ? "template-view" : "template-edit",
      permissionCode: permissionCode.Report.TemplateBasicInfo,
    });
    if(!isSystem){
      allTabs.push({
        title: g("tabs.detail.permissions"),
        index: 1,
        route: "template-permissions",
        permissionCode: permissionCode.Report.TemplatePermissions,
      });
    }
  }
  return allTabs;
};

