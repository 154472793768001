import http from "../../utils/http";
import globalEnv from "../../libs/globalEnv";

export const addPhoto = (model) => {
    return http.post("/api/docs", model, {
      loading: true,
      baseURL: globalEnv.VUE_APP_STD_URL,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  };