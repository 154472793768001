<template>
  <div style="position: relative">
    <b-icon
      icon="search"
      class="searchBarIcon"
      style="color: #c0c4cc !important"
    />
    <vue-typeahead-bootstrap
      v-model="query"
      :data="data"
      @input="querySearchAsync"
      :serializer="(item) => item.fullNmae"
      :max-matches="20"
      :min-matching-chars="1"
      @hit="handleSelect"
      style="width: 320px; font-size: 14px"
      class="searchBarInput"
    />
  </div>
</template>

<script>
import VueTypeaheadBootstrap from "vue-typeahead-bootstrap";

export default {
  components: {
    VueTypeaheadBootstrap,
  },
  props: {
    dataSource: {
      type: Function,
      default() {
        return () => {};
      },
    },
    queryKeyword: {
      type: String,
      default() {
        return "";
      },
    },
    sort: {
      type: String,
      default() {
        return "";
      },
    },
    order: {
      type: String,
      default() {
        return "";
      },
    },
    otherConditions: {
      type: Object,
      default() {
        return {};
      },
    },
    searchBarFomatter: {
      type: Function,
      default() {
        return () => {};
      },
    },
  },
  data() {
    return {
      query: "",
      data: [],
    };
  },
  methods: {
    handleSelect(item) {
      this.$emit("option-selected", item);
      this.query = "";
    },
    querySearchAsync() {
      let condition = { ...this.otherConditions };
      condition[this.queryKeyword] = this.query.trim();
      condition.limit = 20;
      condition.sort = this.sort;
      condition.order = this.order;
      this.dataSource(condition)
        .then((result) => {
          result.data.rows.forEach((e) => {
            e.fullNmae = this.searchBarFomatter(e);
          });
          this.data = result.data.rows;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.searchBarIcon {
  position: absolute;
  z-index: 999;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  cursor: auto;
}
.searchBarInput::v-deep input[type="text"] {
  padding-left: 30px;
}
</style>