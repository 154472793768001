import { render, staticRenderFns } from "./shareToAddGroups.vue?vue&type=template&id=5757c717"
import script from "./shareToAddGroups.vue?vue&type=script&lang=js"
export * from "./shareToAddGroups.vue?vue&type=script&lang=js"
import style0 from "./shareToAddGroups.vue?vue&type=style&index=0&id=5757c717&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../fe-workerportal/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports