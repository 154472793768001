<template>
  <b-alert
    ref="lblAlert"
    :variant="resultType"
    :show="showMsg"
    :class="alertClass"
    :style="alertStyle"
    @dismissed="onAlertDismissed"
  >
    <template v-if="!isHtml">
      {{ alertMsg }}
    </template>
    <template v-else>
      <span v-html="alertMsg" />
    </template>
  </b-alert>
</template>

<script>
import { BAlert } from "bootstrap-vue";

const objProps = Object.assign({}, BAlert.options.props);
objProps.displayTime = {
  type: Number | Boolean | String,
  default() {
    return 15;
  },
};
export default {
  components: { BAlert },
  props: {
    ...objProps,
    alertClass: {
      type: Object,
      default() {
        return {};
      },
    },
    alertStyle: {
      type: Object,
      default() {
        return {};
      },
    },
    isHtml: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  methods: {
    onAlertDismissed() {
      this.showMsg = false;
    },
    message(rTtype, msg) {
      this.alertMsg = msg;
      switch (rTtype) {
        case 1:
          this.resultType = "primary";
          break;
        case 2:
          this.resultType = "warning";
          break;
        case 3:
          this.resultType = "danger";
          break;
        case 4:
          this.resultType = "info";
          break;
        case 100:
          this.resultType = "success";
          break;
        default:
          this.resultType = "primary";
          break;
      }
      this.showMsg = this.displayTime;
    },
    success(msg) {
      this.message(1, msg);
    },
    error(msg) {
      this.message(3, msg);
    },
    warning(msg) {
      this.message(2, msg);
    },
    info(msg) {
      this.message(4, msg);
    },
  },
  data() {
    return {
      showMsg: false,
      alertMsg: "",
      resultType: "primary",
    };
  },
};
</script>
