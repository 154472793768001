const routes = [
    {
        name: 'trainingClass',
        path: 'trainingClass',
        meta: {
            title: 'trainingClass',
            pageTitle: 'welcome',
        },
        route: true,
        component: () => import('@/views/trainingClasses/trainingClass'),
        children: [
            {
                name: 'courseInfo',
                path: 'courseInfo',
                meta: {
                    title: 'courseInfo',
                    pageTitle: 'welcome',
                },
                route: true,
                component: () => import('@/views/trainingClasses/courseInfo'),
            },
            {
                name: 'takeQuiz',
                path: 'quiz',
                meta: {
                    title: 'takeQuiz',
                    pageTitle: 'welcome',
                },
                route: false,
                component: () => import('@/views/trainingClasses/takeQuiz'),
            },
            {
                name: 'quiz-successful',
                path: 'successful',
                meta: {
                    title: 'quizSuccessful',
                    pageTitle: 'welcome',
                },
                route: false,
                component: () => import('@/views/trainingClasses/quiz-successful'),
            }
        ]
    },
    {
        name: 'trainingMaterials',
        path: 'materials',
        meta: {
            title: 'trainingMaterials',
            pageTitle: 'welcome',
        },
        route: true,
        component: () => import('@/views/trainingClasses/trainingMaterials'),
    },
]

export default [
    {
        name: 'training',
        path: '/training',
        meta: {
            title: 'training',
            requiresAuth: false,
        },
        redirect: {
            name: '',
        },
        component: () => import('@/layout/brief'),
        children: [...routes],
    },
]
