import http from "../../utils/http";
import globalEnv from "../../libs/globalEnv";

export const getDocs = (id,id2, type) => {
  if(id2 && id2.length){
    return http.get(`/api/docs/photoList?refId=${id}&refId2=${id2}&docRequestType=${type}`, {}, {
      loading: false,
      baseURL: globalEnv.VUE_APP_STD_URL,
    });
  }
  return http.get(`/api/docs/photoList?refId=${id}&docRequestType=${type}`, {}, {
    loading: false,
    baseURL: globalEnv.VUE_APP_STD_URL,
  });
};

export const deleteDocs = (data) => {
  return http.delete(`/api/docs`, data, {
    loading: false,
    baseURL: globalEnv.VUE_APP_STD_URL,
    useBody: true
  });
};

export const addDocument = (model) => {
  return http.post("/api/docs", model, {
    loading: true,
    baseURL: globalEnv.VUE_APP_STD_URL,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const showPhoto = (model) => {
  return http.down("/api/docs/actions/showPhoto", { model }, {
    loading: false,
    baseURL: globalEnv.VUE_APP_STD_URL,
  });
};



