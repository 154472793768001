<template>
  <div class="mb-2">
    <div class="d-flex flex-wrap align-items-end">
      <k-form-select
        v-model="infoData.fieldNameLeft"
        :options="filedOptions"
        size="sm"
        class="mr-1"
        @change="onFieldChanged"
      />

      <k-form-select
        v-model="infoData.compareOperator"
        :options="signOptions"
        size="sm"
        @change="onInfoChanged"
        class="mr-1"
        style="width: 150px"
      />
      <div style="flex: 1" class="mr-1">
        <k-form-input
          v-if="
            selectFieldType === fieldType.int ||
            selectFieldType === fieldType.decimal
          "
          v-model="infoData.compareValue"
          :trim="true"
          type="number"
          size="sm"
        />
        <KValidationProvider
          v-else-if="selectFieldType === fieldType.datetime"
          rules="validateDateFormat"
          slim
          name="date"
          v-slot="{ errors }"
        >
          <k-alert :show="errors.length > 0" variant="warning">
            {{ errors[0] }}
          </k-alert>
          <k-form-datepicker
            v-model="infoData.compareValue"
            size="sm"
            class="d-inline-block"
            @change="onInfoChanged"
            class-name="noMargin"
          />
        </KValidationProvider>
        <k-form-input
          v-else
          v-model="infoData.compareValue"
          :trim="true"
          size="sm"
          @change="onInfoChanged"
        />
      </div>
      <k-form-select
        v-model="infoData.logicalOperator"
        :options="conditionOptions"
        size="sm"
        @change="onInfoChanged"
        class="mr-1"
        style="width: 60px"
      />
      <span
        v-if="showDelete"
        :id="'tipDynamic_' + index"
        v-b-tooltip="{
          placement: 'bottomleft',
          trigger: 'hover',
          title: $g('search.delete'),
          boundary: 'window',
          container: 'tipDynamic_' + index,
        }"
      >
        <b-icon icon="trash" @click="onDeleteClick" />
      </span>
      <div v-else style="width: 1.35rem"></div>
    </div>
  </div>
</template>
<script>
import {
  getCompareOperatorsList,
  getLogicalOperatorsList,
} from "../api/report";

export default {
  props: {
    filedOptions: {
      type: Array,
      default() {
        return [];
      },
    },
    index: {
      type: Number,
      default() {
        return 0;
      },
    },
    info: {
      type: Object,
      default() {
        return {};
      },
    },
    showDelete: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      infoData: this.info,
      signOptions: [],
      conditionOptions: [],
      selectFieldType: this.info.selectFieldType,
      fieldType: {
        string: 1,
        int: 2,
        datetime: 3,
        decimal: 4,
      },
    };
  },
  methods: {
    onFieldChanged(value) {
      this.filedOptions.forEach((e) => {
        if (value === e.value) {
          this.selectFieldType = e.relateContent;
          this.infoData.selectFieldType = e.relateContent;
        }
      });
      this.infoData.compareValue = null;
      this.onInfoChanged();
    },
    onInfoChanged() {
      this.$emit("conditionChange", this.index, this.infoData);
    },
    onDeleteClick() {
      this.$emit("conditionDelete", this.index);
    },
  },
  watch: {
    "info.selectFieldType": {
      handler(value) {
        this.selectFieldType = value;
      },
    },
    info(value) {
      this.infoData = value;
    },
  },
  created() {
    getCompareOperatorsList().then((resp) => {
      this.signOptions = resp.data || [];
    });
    getLogicalOperatorsList().then((resp) => {
      this.conditionOptions = resp.data || [];
    });
  },
};
</script>
