import http from "../utils/http";
import globalEnv from "../libs/globalEnv";

export const getGoogleMapKey = (sysid) => {
  return http.get(`/anon/googleKey/${sysid}`, null, {
    systemPATH: "admin",
    loading: false,
    baseURL: globalEnv.VUE_APP_ADMIN_URL,
  });
};
