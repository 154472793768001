<template>
  <div>
    <b-tabs v-model="tabindex">
      <b-tab
        v-for="item in items"
        :key="item.value"
        @click="clickNav(item)"
        :disabled="item.disabled"
      >
        <template #title>
          {{ item.text }}
          <span
            class="text-red"
            v-if="needTotalCount"
          >
            ({{ item.total }})
          </span>
        </template>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tabindex: 0,
    };
  },
  methods: {
    clickNav (item) {
      let lastIndex=this.tabindex
      this.tabindex = item.orderIndex;
      this.refresh(item,lastIndex);
    },
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    refresh: {
      type: Function,
      required: true,
    },
    currentIndex: {
      type: Number,
      default () {
        return 0;
      },
    },
    needTotalCount: { type: Boolean, default: false },
  },
  watch: {
    items () {
      var me = this;
      window.setTimeout(function () {
        me.tabindex = me.currentIndex;
      }, 100);
    },
    currentIndex () {
      if (this.tabindex != this.currentIndex) {
        var me = this;
        window.setTimeout(function () {
          me.tabindex = me.currentIndex;
        }, 100);
      }
    },
  },
};
</script>
