import Vue from "vue";
import {
  SearchArea,
  CommonLog,
  CommunicationLogDetialPop,
  DocumentationList,
  GoogleAutocomplete,
  ElectionSelect,
  SearchBar,
  ImgCarousels,
  AddressView,
  AddressForm,
  AddressFormOverseas,
  ImagesActionPanel,
  BIconDelete,
  BIconAdd,
  BIconCommunication,
  BIconSearch,
  PhotoTailor
} from "fe-infrastractures";

Vue.component("SearchArea", SearchArea);
Vue.component("CommonLog", CommonLog);
Vue.component("CommunicationLogDetialPop", CommunicationLogDetialPop);
Vue.component("BIconDelete", BIconDelete);
Vue.component("BIconAdd", BIconAdd);
Vue.component("BIconCommunication", BIconCommunication);
Vue.component("BIconSearchArea", BIconSearch);
Vue.component("DocumentationList", DocumentationList);
Vue.component("GoogleAutocomplete", GoogleAutocomplete);
Vue.component("ElectionSelect", ElectionSelect);
Vue.component("ImgCarousels", ImgCarousels);
Vue.component("SearchBar", SearchBar);
Vue.component("AddressView", AddressView);
Vue.component("AddressForm", AddressForm);
Vue.component("AddressFormOverseas", AddressFormOverseas);
Vue.component("ImagesActionPanel", ImagesActionPanel);
Vue.component("PhotoTailor", PhotoTailor);
