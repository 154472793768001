<template>
  <div>
    <VueReCaptcha
      :sitekey="publicKey"
      ref="recaptcha"
      :load-recaptcha-script="true"
      type="Checkbox"
      size="12"
      @verify="validate"
      @expired="reset"
      v-if="publicKey"
    />
  </div>
</template>
<script>
import VueReCaptcha from "vue-recaptcha";
import { validateRecaptcha, getRecaptchaPublicKey } from "./api/recaptcha";

export default {
  data() {
    return {
      publicKey: "",
    };
  },
  props: {
    siteKey: {
      type: String,
      default() {
        return "";
      },
    },
  },
  components: { VueReCaptcha },
  methods: {
    validate(data) {
      validateRecaptcha({ Response: data })
        .then((res) => {
          if (res.status == 200) {
            this.$emit("validate", res.data.success);
          } else {
            this.$refs.recaptcha.reset();
          }
        })
        .catch(() => {
          this.$refs.recaptcha.reset();
        });
    },
    reset() {
      this.$refs.recaptcha.reset();
      this.$emit("expired");
    },
  },
  created() {
    if (this.siteKey) {
      this.publicKey = this.siteKey;
    } else {
      getRecaptchaPublicKey().then((res) => {
        this.publicKey = res.data;
      });
    }
  },
};
</script>
