import http from "../../utils/http";
import globalEnv from "../../libs/globalEnv";

export const getDocuments = (requestModel) => {
  return http.post("/api/docs/page", requestModel, {
    useBody: true,
    loading: false,
    baseURL: globalEnv.VUE_APP_STD_URL,
  });
};

export const getDocumentPhotoList = (docRequestType, refId) => {
  return http.get("/api/docs/photoList?docRequestType=" + docRequestType + "&refId=" + refId, {
    loading: false,
    baseURL: globalEnv.VUE_APP_STD_URL,
  });
};

export const getDocument = (id) => {
  return http.get(`/api/docs/${id}`, {}, { loading: true, baseURL: globalEnv.VUE_APP_STD_URL });
};

export const isExit = (model) => {
  return http.post(`/api/docs/actions/IsExit`, { model }, { loading: true, baseURL: globalEnv.VUE_APP_STD_URL });
};

export const downloadDocumentUrl = (model) => {
  return http.down("/api/docs/actions/download", { model }, { loading: false, baseURL: globalEnv.VUE_APP_STD_URL });
};

export const addDocument = (model) => {
  return http.post("/api/docs", model, {
    loading: true,
    baseURL: globalEnv.VUE_APP_STD_URL,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const addDocumentList = (model) => {
  return http.post("/api/docs/saveFiles", model, {
    loading: true,
    baseURL: globalEnv.VUE_APP_STD_URL,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const updateDocument = (model) => {
  return http.put("/api/docs", model, {
    loading: true,
    baseURL: globalEnv.VUE_APP_STD_URL,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const deleteDocuments = (model) => {
  return http.delete("/api/docs", model,
    {
      loading: false,
      baseURL: globalEnv.VUE_APP_STD_URL,
      useBody: true
    });
};
