<template>
  <span>
    <k-button
      type="submit"
      variant="primary"
      v-show="showSaveNext"
      @click="onSaveNext"
    >
      preNext.saveNext
    </k-button>
    <k-button
      v-show="showPre && !hiddenRightBtn"
      @click="goToPrevious"
      class="nav-btn-Previous"
      ref="btnPre"
      :style="getStyle"
    >
      preNext.previous
    </k-button>
    <k-button
      v-show="showNext && !hiddenRightBtn"
      @click="onGotoNext"
      class="nav-btn-next"
      ref="btnNext"
      :style="getStyle"
    >
      preNext.next
    </k-button>
  </span>
</template>
<script>
import KButton from "../../k-components/button/button.vue";
import { pageNavUtil } from "../../utils/pageNavUtil";

export default {
  components: { KButton },
  props: {
    getStyle: {
      type: Object,
    },
    hiddenRightBtn: {
      type: Boolean,
      default: false,
    },
    hasSaveNext: {
      type: Boolean,
      default() {
        return false;
      },
    },
    priorityIdName: {
      type: String,
      default() {
        return null;
      },
    },
    otherQueryIds: {
      type: Array,
      default() {
        return [];
      },
    },
    gridListId: {
      type: String,
      default() {
        return null;
      },
    },
    beforeToLeave: {
      type: Function,
      default() {
        return true;
      },
    },
    changeRouteName: {
      type: Function,
      default: null,
    },
    customFieldName: {
      type: String,
      default() {
        return "name";
      },
    },
    customFieldNameFunc: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      id: this.$route.query?.id || this.$route.params?.id,
      showPre: false,
      showNext: false,
      ids: [],
      navigateState: null,
      condition: null,
      currentIndex: 0,
      navHandler: null,
      saveNexted: false,
      customField: [],
    };
  },
  methods: {
    checkSaveNexted() {
      return this.saveNexted;
    },
    showButton() {
      if (this.$route.query?.hidePreNext == 1) {
        return;
      }
      if (this.id) {
        if (this.currentIndex === 0 && this.currentPage === 1) {
          this.showPre = false;
        } else {
          this.showPre = true;
        }
        if (this.ids && this.ids.length > 0) {
          if (
            this.currentIndex === this.ids.length - 1 &&
            this.currentPage === this.totalPages
          ) {
            this.showNext = false;
          } else {
            this.showNext = true;
            if (this.id === "00000000-0000-0000-0000-000000000000") {
              this.showNext = false;
            }
          }
        } else {
          this.showNext = false;
        }
      }
    },
    async redirect(id, m, customField) {
      let routeName = this.$route.name;
      if (this.changeRouteName) {
        routeName = await this.changeRouteName(id);
        if (!routeName) {
          this.$alert({
            title: this.$g("const.confirm.confirm"),
            content: this.$g("noPermission"),
          });
          return;
        }
      }
      let q = { ...this.$route.query };
      if (!q) {
        q = {};
      }
      q.id = id;
      q.redirectName = routeName;
      q.customField = customField;
      let p = { ...this.$route.params };
      if (!p) {
        p = {};
      }
      p.id = id;
      if (m) {
        p.message = m.message;
        p.status = m.data.status;
      } else {
        p.message = "";
        p.status = "";
      }
      this.otherQueryIds.forEach((queryId) => {
        q[queryId] = this.ids[this.currentIndex][queryId];
      });
      this.$router.push({
        name: routeName,
        query: q,
        params: p,
      });
    },
    async goToPrevious() {
      let result = await this.beforeToLeave();
      if (!result) {
        return null;
      }
      let idFieldName = this.getIdFieldName();
      let customField = null;
      if (this.currentIndex > 0) {
        this.currentIndex--;
        let id = this.ids[this.currentIndex][idFieldName];
        if (this.customField.length > 0) {
          if (this.customFieldNameFunc) {
            customField = this.customFieldNameFunc(
              this.customField[this.currentIndex]
            );
          } else {
            customField =
              this.customField[this.currentIndex][this.customFieldName];
          }
        }
        this.redirect(id, null, customField);
      } else {
        let movePageIndex = this.currentPage - 1;
        let ritems = await this.movePage(movePageIndex);
        if (ritems.customField.length > 0) {
          if (this.customFieldNameFunc) {
            customField = this.customFieldNameFunc(
              ritems.customField[ritems.customField.length - 1]
            );
          } else {
            customField =
              ritems.customField[ritems.customField.length - 1][
                this.customFieldName
              ];
          }
        }
        this.setPageStateIds(ritems, movePageIndex);
        this.redirect(
          ritems.ids[ritems.ids.length - 1][idFieldName],
          null,
          customField
        );
      }
    },
    async onGotoNext() {
      let result = await this.beforeToLeave();
      if (!result) {
        return null;
      }
      await this.goToNext();
    },
    async goToNext(m) {
      let idFieldName = this.getIdFieldName();
      let customField = null;
      if (this.currentIndex < this.ids.length - 1) {
        this.currentIndex++;
        let id = this.ids[this.currentIndex][idFieldName];
        if (this.customField.length > 0) {
          if (this.customFieldNameFunc) {
            customField = this.customFieldNameFunc(
              this.customField[this.currentIndex]
            );
          } else {
            customField =
              this.customField[this.currentIndex][this.customFieldName];
          }
        }
        this.redirect(id, m, customField);
      } else {
        let movePageIndex = this.currentPage + 1;
        let ritems = await this.movePage(movePageIndex);
        if (this.customField.length > 0) {
          if (this.customFieldNameFunc) {
            customField = this.customFieldNameFunc(ritems.customField[0]);
          } else {
            customField = ritems.customField[0][this.customFieldName];
          }
        }
        this.setPageStateIds(ritems, movePageIndex);
        this.redirect(ritems.ids[0][idFieldName], m, customField);
      }
    },
    async movePage(pageIndex) {
      let searchState = this.getSearchState(pageIndex);
      let rst = await this.navHandler(searchState);
      return {
        ids: pageNavUtil.getAllEndIds(rst.data.rows),
        customField: pageNavUtil.getAllCustomField(rst.data.rows),
      };
    },
    getSearchState(pageIndex) {
      if (this.navigateState) {
        this.navigateState.currentPage = pageIndex;
        var param = {
          Sort: this.navigateState.sortBy,
          Order: this.navigateState.sortDesc ? "Desc" : "Asc",
        };
        if (this.navigateState.hasPager) {
          param.Offset =
            (this.navigateState.currentPage - 1) * this.navigateState.perPage;
          param.Limit = this.navigateState.perPage;
        }
        Object.assign(param, this.condition);
      }
      return param;
    },
    setPageStateIds(ritems, movePageIndex) {
      var pageState = pageNavUtil.getRecentList(
        this.$store.getters.pageState,
        this.gridListId
      );
      if (pageState) {
        var pa = pageState.pageAddtional;
        if (pa) {
          if (this.$store) {
            this.$store.dispatch("page/updateItems", {
              listId: pageState.listId,
              items: ritems.ids,
              currentPage: movePageIndex,
              customField: ritems.customField,
            });
          }
        }
      }
    },
    onSaveNext() {
      this.saveNexted = true;
    },
    onUnSaveNext() {
      this.saveNexted = false;
    },
    getNaviState() {
      this.navigateState = null;
      var pageState = pageNavUtil.getRecentList(
        this.$store.getters.pageState,
        this.gridListId
      );
      //no user
      // var lastListRoute = this.$store.getters.lastListRoute
      // var lastDataListRoute = null
      if (pageState) {
        var pa = pageState.pageAddtional;
        if (pa) {
          this.navigateState = pa;
          this.ids = pa.ids;
          this.navHandler = pa.navHandler;
          this.currentIndex = this.getCurrentIndex(this.ids, this.id);
          this.customField = pa.customField;
        }
        var cd = pageState.condition;
        if (cd) {
          this.condition = cd;
          // lastDataListRoute = cd.routeName
        }
      }
    },
    getIdFieldName() {
      let idFieldName = this.priorityIdName;
      if (!idFieldName) {
        idFieldName = "id";
      }
      return idFieldName;
    },
    getCurrentIndex(ids, id) {
      if (!ids) {
        return 0;
      }
      let idFieldName = this.getIdFieldName();
      let result = 0;
      for (let i = 0; i < ids.length; i++) {
        if (ids[i][idFieldName] === id) {
          result = i;
          break;
        }
      }
      return result;
    },
    navBtnSearch() {
      this.$nextTick(() => {
        let preWidth = this.$refs.btnPre.$el.offsetWidth;
        let nextWidth = this.$refs.btnNext.$el.offsetWidth;
        let rightSearch = preWidth + nextWidth;
        let navSearch = document.getElementsByClassName("nav-search")[0];
        let wikiHelpLink = document.getElementsByClassName("wiki-help-link")[0];
        if (wikiHelpLink) {
          wikiHelpLink.style.position = "absolute";
          wikiHelpLink.style.right = `${rightSearch + 5}px`;
        }
        if (!this.showPre && !this.showNext) {
          document
            .getElementsByClassName("nav-btn-group")[0]
            .classList.add("preNextTwoHide");
          rightSearch += 5;
        } else if (!(this.showPre && this.showNext)) {
          document
            .getElementsByClassName("nav-btn-group")[0]
            .classList.add("preNextOne");
          rightSearch += 10;
          nextWidth += 5;
        } else {
          document
            .getElementsByClassName("nav-btn-group")[0]
            .classList.remove("preNextOne", "preNextTwoHide");
          rightSearch += 15;
          nextWidth += 10;
        }
        if (navSearch) {
          if (wikiHelpLink) {
            wikiHelpLink.style.right = `${
              rightSearch + navSearch.offsetWidth
            }px`;
          }
          navSearch.style.right = rightSearch + "px";
        }
        this.$refs.btnPre.$el.style.right = nextWidth + "px";
      });
    },
  },
  computed: {
    showSaveNext() {
      return this.hasSaveNext && this.showNext;
    },
    currentPage() {
      if (this.navigateState) {
        return this.navigateState.currentPage;
      }
      return 0;
    },
    totalPages() {
      if (this.navigateState) {
        let totalRows = this.navigateState.totalRows;
        let hasPager = this.navigateState.hasPager;
        let perPage = this.navigateState.perPage;
        if (!hasPager) {
          return 1;
        }
        let result = Math.floor(totalRows / perPage);
        if (totalRows % perPage !== 0) {
          result++;
        }
        return result;
      }
      return 0;
    },
    repResult() {
      return {
        success: 1,
        warning: 2,
        faild: 3,
        information: 4,
      };
    },
  },
  mounted: function () {
    if (this.id) {
      this.getNaviState();
      this.showButton();
    }
    this.navBtnSearch();
  },
};
</script>
<style lang="scss">
.preNextOne.nav-btn-group {
  .nav-btn-Previous {
    right: 5px;
  }
}

.preNextTwoHide.nav-btn-group {
  .nav-search {
    right: 5px;
  }
}
</style>
