const navToBackFunc = (nav, navIndex) => {
  if (nav != null) {
    nav.tabindex = navIndex;
  }
};
export const beforeRouteLeaveMixin = {
  data: function () {
    return {
      isDoSave: false,
    };
  },
  methods: {
    navToBack() {
      navToBackFunc(this.$refs.nav, this.navIndex);
    },
    onSave() {
      if (this.$refs.pn != null) {

        this.$refs.pn.onUnSaveNext();
      }

    },
    /**
     * ps:same router not run beforeRouteLeave
     */
    viewToLeave() {
      if (!this.isDoSave && this.$refs.detail && this.$refs.detail.isDataChanges && this.$refs.detail.isDataChanges()) {
        this.$confirm({
          title: this.$g("const.confirm.confirm"),
          content: this.$g("const.confirm.leaveComfirm"),
          cancelType: "secondary",
          okText: this.$g("const.confirm.leaveComfirmYes"),
          cancelText: this.$g("const.confirm.no"),
        })
          .then(() => {
            this.goTo(true);
          })
          .catch(() => {
            //nav maybe in view or detialview
            this.$refs.detail.navToBack();
            this.navToBack();
          });
      } else {
        this.goTo(true);
      }
    },
    preNextToLeave() {
      if (!this.isDoSave && this.$refs.detail && this.$refs.detail.isDataChanges && this.$refs.detail.isDataChanges()) {
        return this.$confirm({
          title: this.$g("const.confirm.confirm"),
          content: this.$g("const.confirm.leaveComfirm"),
          cancelType: "secondary",
          okText: this.$g("const.confirm.leaveComfirmYes"),
          cancelText: this.$g("const.confirm.no"),
        })
          .then(() => {
            return true;
          })
          .catch(() => {
            return false;
          });
      } else {
        return true;
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!this.isDoSave && this.$refs.detail && this.$refs.detail.isDataChanges && this.$refs.detail.isDataChanges()) {
      this.$confirm({
        title: this.$g("const.confirm.confirm"),
        content: this.$g("const.confirm.leaveComfirm"),
        cancelType: "secondary",
        okText: this.$g("const.confirm.leaveComfirmYes"),
        cancelText: this.$g("const.confirm.no"),
      })
        .then(() => {
          next();
        })
        .catch(() => {
          //nav maybe in view or detialview
          this.$refs.detail.navToBack();
          this.navToBack();
          next(false);
        });
    } else {
      next();
    }
  },
};
export const originDataChangeMixin = {
  data: function () {
    return {
      originDataString: null,
      curDataFrom: null,
    };
  },
  methods: {
    setOrignData(formData) {
      this.originDataString = JSON.stringify(formData);
      this.curDataFrom = formData;
    },
    isDataChanges() {
      if (this.originDataString == null && this.curDataFrom == null) {
        return false;
      }
      let formStr = JSON.stringify(this.curDataFrom);
      return (this.originDataString !== formStr ||
        (this.$refs.comments != null && this.$refs.comments.isDataChanges()));
    },
    navToBack() {
      navToBackFunc(this.$refs.nav, this.navIndex);
    },
  },
};

/**
 * only edit  no detail component
 *
 *if curDataFrom is array  not  ues
 * @type {{data: (function(): {originDataString: null, curDataFrom: null}), methods: {setOrignData(*=): void, isDataChanges(): (boolean|*), navToBack(): void}}}
 */
export const originDataChangeMixinOnlyEdit = {
  data() {
    return {
      originDataString: null,
      curDataFrom: null,
      isDoSave: false,
    };
  },
  methods: {
    navToBack() {
      navToBackFunc(this.$refs.nav, this.navIndex);
    },
    setOrignData(formData) {
      this.originDataString = JSON.stringify(formData);
      this.curDataFrom = formData;
    },
    isDataChanges() {
      if (this.originDataString == null && this.curDataFrom == null) {
        return false;
      }
      let formStr = JSON.stringify(this.curDataFrom);
      return this.originDataString !== formStr;
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!this.isDoSave && this.isDataChanges()) {
      this.$confirm({
        title: this.$g("const.confirm.confirm"),
        content: this.$g("const.confirm.leaveComfirm"),
        cancelType: "secondary",
        okText: this.$g("const.confirm.leaveComfirmYes"),
        cancelText: this.$g("const.confirm.no"),
      })
        .then(() => {
          next();
        })
        .catch(() => {
          this.navToBack();
        });
    } else {
      next();
    }
  },
};

