<template>
  <div v-if="!isHidePage" class="content-page">
    <election-select v-if="isEvent" />
    <div class="content">
      <div class="right-bar" v-show="seenSearch">
        <b-icon icon="x" @click="onToggleSearch" />
        <search-area
          v-if="showThisSearchArea"
          :type-name="typeName"
          :search-click="search"
          :clear-click="clear"
          :get-paras="getCondition"
          :set-paras="setParas"
          ref="searchArea"
          :fields="fields"
        >
          <k-form-group label-suffix="search.title">
            <k-form-input v-model="queryParams.subject" />
          </k-form-group>
          <k-form-group label-suffix="search.sendBy">
            <k-form-input v-model="queryParams.sendBy" />
          </k-form-group>
          <k-form-group label-suffix="search.printedRangeFrom">
            <KValidationProvider
              rules="validateDateFormat"
              slim
              name="electionDateValue"
              v-slot="{ errors }"
            >
              <k-alert
                :show="errors.length > 0 && !isPlainText"
                variant="warning"
              >
                {{ errors[0] }}
              </k-alert>
              <k-form-datepicker v-model="queryParams.sendDateTimeFrom" />
            </KValidationProvider>
          </k-form-group>
          <k-form-group label-suffix="search.printedRangeTo">
            <KValidationProvider
              rules="validateDateFormat"
              slim
              name="electionDateValue"
              v-slot="{ errors }"
            >
              <k-alert
                :show="errors.length > 0 && !isPlainText"
                variant="warning"
              >
                {{ errors[0] }}
              </k-alert>
              <k-form-datepicker v-model="queryParams.sendDateTimeTo" />
            </KValidationProvider>
          </k-form-group>
        </search-area>
      </div>
      <div class="center-bar">
        <div class="nav-btn-group">
          <k-button-group>
            <k-export-button
              v-if="isExport"
              :condition-getter="getSearchState"
              :export-handler="exportHandler"
              :check-has-data="checkHasData"
            >
              buttons.export
            </k-export-button>
          </k-button-group>
          <b-icon-search-area
            @click="onToggleSearch"
            :class="{ active: isActive }"
          />
          <KWikiHelp :props-wiki-help-code="wikiHelpCode" />
        </div>

        <div class="content-main">
          <k-alert-msg ref="lblMsg" />
          <div class="content-navTab">
            <div class="nav-tools">
              <b-icon-delete v-if="isDel" @click="delDeliveryResultHandle" />
              <column-config-icon
                :list-id="
                  isEvent
                    ? isNotification
                      ? 'eleNotificationmsgletterpagelist'
                      : 'elemsgletterpagelist'
                    : 'msgletterpagelist'
                "
                @column-changed="onColumnChanged"
              />
            </div>
            <status-Nav
              :refresh="navTabClick"
              ref="navTabs"
              :items="navTabItems"
              :current-index="curTabIndex"
              :need-total-count="true"
            />
          </div>
          <div class="content-party">
            <konn-grid
              :select-mode="selectMode"
              :has-check-box="hasCheckBox"
              :has-action="hasAction"
              ref="selectableTable"
              :data-source="dataSource"
              :get-condition="getCondition"
              @row-selected="onRowSelected"
              @total-changed="onTotalChanged"
              @fields-get="onFieldsGet"
              grid-sort-by="sendDateTime"
              :set-paras="setParas"
              :list-id="isEvent ? 'elemsgletterpagelist' : 'msgletterpagelist'"
              id-field="id"
              :auto-bind="false"
              :grid-sort-desc="true"
              :sticky-header="true"
              class="stickyTable"
            >
              <template #cell(subject)="row">
                <router-link
                  :to="{
                    name: getMessageLetterDeliveryDetailsRouteName(),
                    query: {
                      id: row.item.id,
                      isView: '1',
                      dbtn: '0',
                    },
                  }"
                >
                  {{ row.value }}
                </router-link>
              </template>
              <template #cell(showInPortal)="row" v-if="hasShowInPortal">
                <k-form-checkbox
                  v-model="row.value"
                  :value="true"
                  :unchecked-value="false"
                  @change="showInPortalChangeEvent(row.value, row.item.id)"
                  switch
                />
              </template>
            </konn-grid>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getLetterDeliveryResultList,
  getSumcount,
  delLetterDeliveryResult,
  exportLetterDeliveryResultList,
  updateShowInPortal,
} from "../../api/messageDeliveryResult";
import statusNav from "../../components/common-nav";
import { repResult } from "../../../../libs/global";
import { enumSearchType } from "../../../../utils/enums/enumSearchType";
import { enumDeliveryResultType } from "../../enums/enumDeliveryResultType";
import { has } from "../../../../utils/permission/operatePermission";
import { handleTabs } from "../../../../utils/permission/permission";
import storeage from "../../../../utils/storeage";
import { permissionCode } from "../../mixins/permissionCode";
import { DeliveryResults } from "../../mixins/messagePage";
import { getCurPageConfigbyRoutePath } from "../../../../utils/tools";

export default {
  mixins: [permissionCode, DeliveryResults],
  props: {
    hasShowInPortal: {
      type: Boolean,
      default() {
        return false;
      },
    },
    hasNavTabItems: {
      type: Array,
      default() {
        return [];
      },
    },
    propApi: {
      type: Object,
      default() {
        return {
          getLetterDeliveryResultList,
          getSumcount,
          delLetterDeliveryResult,
          exportLetterDeliveryResultList,
        };
      },
    },
  },
  components: {
    statusNav,
    // ColumnConfigIcon,
  },
  data() {
    let isEvent = this.$route.name.startsWith("ele");
    let curPageConfig = getCurPageConfigbyRoutePath(
      this.$route.meta.project,
      this.$route.path
    );
    console.log(curPageConfig);
    return {
      isEvent: isEvent,
      electionId: isEvent ? storeage.getElectionId() : null,
      queryParams: {
        electionId: isEvent ? storeage.getElectionId() : "",
        subject: "",
        sendBy: "",
        sendDateTimeFrom: "",
        sendDateTimeTo: "",
      },
      hasElection: false,
      typeName: "DeliveryResultLetter",
      curTabIndex: 3,
      fields: [],
      navTabItems: [],
      selectMode: "multi",
      selected: [],
      hasCheckBox: true,
      hasAction: false,
      showThisSearchArea: true,

      categories: [],
      useFors: [],
      seenSearch: true,
      isActive: true,
      initPaymentStatus: [],
      showSearchStatus: true,
      totalCount: 0,
      isExport: true,
      isDel: true,
      isHidePage: false,
      wikiHelpCode: null,
      hiddenNav: curPageConfig?.templateTabs?.hidNav ?? [],
    };
  },
  computed: {
    dataSource: function () {
      return this.propApi.getLetterDeliveryResultList;
    },
    exportHandler: function () {
      return this.propApi.exportLetterDeliveryResultList;
    },
  },

  mounted() {
    this.initNav();
    this.initWikiHelpCode();
  },

  methods: {
    initWikiHelpCode() {
      if (this.isNotification) {
        this.wikiHelpCode = this.isEvent
          ? this.$wikiHelpLinkCodes?.Event.Notification?.DeliveryResult?.Letter
              .List
          : this.$wikiHelpLinkCodes?.BAU.Notification?.DeliveryResult?.Letter
              .List;
      } else {
        this.wikiHelpCode = this.isEvent
          ? this.$wikiHelpLinkCodes?.Event.Communication?.DeliveryResult?.Letter
              .List
          : this.$wikiHelpLinkCodes?.BAU.Communication?.DeliveryResult?.Letter
              .List;
      }
    },
    showInPortalChangeEvent(v, id) {
      let query = {
        id: id,
        showInPortal: v,
        electionId: this.isEvent ? storeage.getElectionId() : "",
      };
      updateShowInPortal(query).then((rep) => {
        this.$refs.lblMsg.message(rep.data.status, rep.message);
      });
    },
    setPermission() {
      if (this.isEvent) {
        this.isExport = has(
          this.permissionCodeEvent.Communication.LetterResultExport
        );
        this.isDel = has(
          this.permissionCodeEvent.Communication.LetterResultDelete
        );
      } else {
        this.isExport = has(
          this.permissionCode.Communication.LetterResultExport
        );
        this.isDel = has(this.permissionCode.Communication.LetterResultDelete);
      }
    },
    checkHasData() {
      if (!this.totalCount) {
        this.$refs.lblMsg.message(
          repResult.warning,
          this.$g("const.confirm.exportNoData")
        );
        return false;
      } else {
        return true;
      }
    },
    getSearchState() {
      let condition = this.$refs.selectableTable.getCondition();
      return this.$refs.selectableTable.getSearchState(condition);
    },
    delDeliveryResultHandle() {
      let pmOpt = this.$refs.selectableTable.confirmOpt(
        this.$g("const.confirm.delete")
      );
      if (pmOpt == null) {
        return;
      }
      pmOpt.then(() => {
        let delIds = this.$refs.selectableTable.getSelectedIds();
        this.propApi.delLetterDeliveryResult(delIds).then((rep) => {
          this.$refs.lblMsg.message(rep.data.status, rep.message);
          if (rep.data.status != repResult.faild) {
            this.initNav();
          }
        });
      });
    },
    filterTabs(items, isEvent) {
      let pl_code = isEvent ? this.permissionCodeEvent : this.permissionCode;
      let filterAry = [
        {
          permissionCode: pl_code.Communication.EmailResult,
          type: enumDeliveryResultType.email,
        },
        {
          permissionCode: pl_code.Communication.SMSResult,
          type: enumDeliveryResultType.sms,
        },
        {
          permissionCode: pl_code.Communication.PhoneResult,
          type: enumDeliveryResultType.phone,
        },
        {
          permissionCode: pl_code.Communication.LetterResult,
          type: enumDeliveryResultType.letter,
        },
      ];

      let tempAry = handleTabs(filterAry);
      if (!tempAry.length) {
        return [];
      }
      return items
        .filter((item) => {
          return (tempAry || []).some((ele) => ele.type === item.type);
        })
        .map((item, index) => {
          return {
            ...item,
            orderIndex: index,
          };
        });
    },
    initNav() {
      this.propApi
        .getSumcount({
          electionId: this.queryParams.electionId,
          isNotification: this.isNotification,
        })
        .then((data) => {
          let items = data.data;
          if (this.hasNavTabItems.length > 0) {
            items = data.data.filter(
              (c) =>
                this.hasNavTabItems.findIndex(
                  (d) => d == c.deliveryResultType
                ) >= 0
            );
          }
          items = items.filter(
            (c) => !this.hiddenNav.find((d) => d == c.deliveryResultType)
          );
          if (items.length) {
            const navArr = [];
            items.forEach(function (item, index) {
              navArr.push({
                text: item.text,
                orderIndex: index,
                total: item.count,
                type: item.deliveryResultType,
                sonCount: item.sonCount,
              });
            });
            this.navTabItems = this.filterTabs(navArr, this.isEvent);
            this.curTabIndex = this.navTabItems.findIndex(
              (item) => item.type === enumDeliveryResultType.letter
            );
            this.setPermission();
            this.$refs.selectableTable?.refresh();
            this.refreshSearchTemplate();
          }
        });
    },

    onFieldsGet(fields) {
      this.fields = fields;
    },

    onColumnChanged() {
      this.$refs.selectableTable.clearFields(); //清除当前选择的字段
      this.$refs.searchArea.clearHandler();
    },

    navTabClick(item) {
      if (item.orderIndex !== this.curTabIndex) {
        if (item.type !== enumDeliveryResultType.letter) {
          this.$router.push({
            name: this.getMessageDeliveryResultRouteName(),
            params: {
              tabindex: item.orderIndex,
            },
          });
        }
      }
    },

    onRowSelected(items) {
      this.selected = items;
    },
    onTotalChanged(total) {
      this.totalCount = total;
      if (this.navTabItems[this.curTabIndex]) {
        this.navTabItems[this.curTabIndex].total = total;
      }
    },
    clear() {
      this.queryParams.subject = "";
      this.queryParams.sendBy = "";
      this.queryParams.sendDateTimeFrom = "";
      this.queryParams.sendDateTimeTo = "";
      this.$refs.searchArea.setDynamicConditions();
    },
    getParas() {
      return {
        query: this.queryParams,
      };
    },

    setParas(paras, isSearchArea) {
      this.curTabIndex =
        isSearchArea || !this.$route?.query?.restore
          ? this.curTabIndex
          : paras.tabindex;
      this.queryParams = {
        ...paras,
        electionId: this.isEvent ? this.electionId : null,
      };
    },

    getCondition() {
      this.queryParams.dynamicConditions =
        this.$refs.searchArea.getDynamicConditions();
      if (this.isNotification) {
        this.$set(this.queryParams, "isNotification", this.isNotification);
      }
      return this.queryParams;
    },

    search: function () {
      this.$refs.selectableTable.pageChange(1);
    },

    onToggleSearch() {
      this.seenSearch = !this.seenSearch;
      this.isActive = !this.isActive;
    },

    refreshSearchTemplate() {
      this.$refs.searchArea?.getTemplateListByTemplateType(
        enumSearchType.communicationLetterDelivery
      );
    },
  },
};
</script>
