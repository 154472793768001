const state = {
    mobileteam: {}
};

const mutations = {
    SET_MOBILE_TEAM(state1, mobileteam) {
        state1.mobileteam = mobileteam
    }
};

const actions = {
    saveMobileTeam({ commit }, para) {
        commit('SET_MOBILE_TEAM', para)
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
