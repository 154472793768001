<template>
  <div class="layout-logo">
    <img
      :src="src"
      alt="No Image"
    >
  </div>
</template>

<script>
import globalEnv from "../../libs/globalEnv";

export default {
  props: {
    logoSrc: {
      type: String,
      default: null,
    },
  },
  data(){
    return {
      src: this.logoSrc ? this.logoSrc : globalEnv.VUE_APP_STD_URL + "/admin/anon/system/logo",
    };
  },
};
</script>
