import Vue from "vue";
import Vuex from "vuex";
import getters from "./getters";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const files = require.context("./modules", true, /\.js$/);
const modules = files.keys().reduce((ins, key) => {
  const name = key.replace(/^\.\/(.*)\.\w+$/, "$1");
  const data = files(key);
  (ins[name] = data.default)
  return ins;
}, []);
export default new Vuex.Store({
  getters,
  modules,
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
      reducer(val) {
        return {
          page: val.page,
          user: val.user,
          sys: val.sys,
          pageTab: val.pageTab,
          nav: val.nav,
          menu: val.menu,
          mobileteam: val.mobileteam
        };
      }
    })
  ]
});
