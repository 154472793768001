<template>
  <div class="content-page">
    <div class="nav-btn-group">
      <b-btn-group>
        <k-button-back
          :back-route="getMessageDeliveryResultRouteName()"
          :use-hisotry-back="isUseHisotryBack"
          :backParams="backParams"
        >
          buttons.back
        </k-button-back>
        <k-button @click="cancel" v-show="data.isShowCancel && isCancel">
          buttons.cancel
        </k-button>
        <k-button
          @click="sendImmediately"
          v-show="data.isShowSendImmediately && isImmediately"
        >
          buttons.sendImmediately
        </k-button>
        <k-button
          v-b-modal.pop
          @click="showScheduleModal"
          v-show="data.isShowSchedule && isChangeDate"
        >
          buttons.changeScheduleDateTime
        </k-button>
        <k-pre-next ref="pn" :has-save-next="false" />
      </b-btn-group>
      <KWikiHelp :propsWikiHelpCode="wikiHelpCode" />
    </div>
    <div class="content-main">
      <k-alert-msg ref="lblMsg" />
      <div class="content-navTab">
        <delivery-result-nav
          :is-event="isEvent"
          ref="nav"
          :tab-index="navIndex"
          :isNotification="isNotification"
          :isEvent="isEvent"
        />
      </div>
      <div class="content-party">
        <div class="">
          <div class="content-title">
            {{ data.title }}
          </div>
          <div class="content-group marginNone">
            <k-form-group
              v-for="item in data.content"
              :key="item.key"
              label-align-md="right"
              label-cols-md="2"
              content-cols-lg="7"
              content-cols-md="8"
              :label-suffix="item.key"
              :no-locale="true"
            >
              <k-form-input :value="item.value" :plaintext="true" />
            </k-form-group>
          </div>
          <div class="content-title" v-if="showMessageDetail">
            {{ this.messageDetail }}
          </div>
          <div class="content-title" v-if="!showMessageDetail">
            {{ $g("messageDetail") }}
          </div>
          <div class="content-group" v-if="recipients.length > 0">
            <k-form-group
              label-align-md="right"
              label-cols-md="2"
              content-cols-lg="7"
              content-cols-md="8"
              label-suffix="recipients"
            >
              <k-form-select
                @change="getRecipientsContent"
                v-model="recipients"
                :disabled="isHidePart"
                :options="recipientsList"
              />
            </k-form-group>
            <k-form-group
              v-if="deliveryType != enumDeliveryResultType.phone"
              label-align-md="right"
              label-cols-md="2"
              content-cols-lg="7"
              content-cols-md="8"
              label-suffix="subject"
            >
              <k-form-input
                type="text"
                v-model="data.subject"
                :plaintext="true"
              />
            </k-form-group>
            <k-form-group
              v-if="deliveryType != '2'"
              label-align-md="right"
              label-cols-md="2"
              content-cols-lg="7"
              content-cols-md="8"
              label-suffix="message"
            >
              <div
                v-html="recipientsContent"
                class="border p-3"
                style="white-space: pre-wrap"
              />
            </k-form-group>
            <k-form-group
              v-if="data.docDetail.length > 0 && deliveryType == '1'"
              label-align-md="right"
              label-cols-md="2"
              content-cols-lg="7"
              content-cols-md="8"
              label-suffix="attachment"
            >
              <div class="col-form-label">
                <k-link
                  :key="index"
                  v-for="(item, index) in data.docDetail"
                  @click="downloadFile(item.docId)"
                  class="d-block"
                >
                  {{ item.fileName }}
                </k-link>
              </div>
            </k-form-group>
            <k-form-group
              label-for="content"
              label-align-md="right"
              label-cols-md="2"
              content-cols-lg="7"
              content-cols-md="8"
              v-if="data.filePath && deliveryType == '2'"
            >
              <HuiAudio
                v-model="data.filePath"
                :is-plain-text="true"
                :name="data.fileName"
              />
            </k-form-group>
          </div>
          <div class="content-group" v-else>
            {{ $g("notMessageDetail") }}
          </div>
        </div>
        <div v-show="data.isShowChart && !isHidePart">
          <div class="content-title">
            {{ $g("deliveryReportStatistics") }}
          </div>
          <div class="notice">
            <span style="color: rgb(170, 198, 231)">{{
              $g("blueRepresentsSuccessful")
            }}</span
            >,
            <span style="color: rgb(246, 185, 159)">{{
              $g("orangeRepresentsUnsuccessful")
            }}</span
            >,
            <span style="color: rgb(196, 196, 196)">{{
              $g("grayRepresentsDidNotDeliver")
            }}</span
            >.
          </div>

          <!-- <div id="chart" style="width: 300px; height: 200px; display: inline-block"/> -->

          <GChart
            :type="chartType"
            :data="chartData"
            :options="options"
            ref="googleChart"
            :settings="{ packages: ['corechart'] }"
            style="display: inline-block"
          />
          <div class="deliveries" v-if="recipients.length > 0">
            <template
              v-for="(item, index) in [
                data.successful,
                data.unSuccessful,
                data.didNotDelivery,
              ]"
            >
              <div :key="index">
                <div class="item">
                  <k-link
                    @click="gotoDetailsWithStatus(item.status, suffixs[index])"
                  >
                    {{ suffixsText[index] }} : {{ item.count }}
                  </k-link>
                </div>
                <div class="itemChild">
                  <k-link
                    :key="index"
                    v-for="(cItem, index) in item.content"
                    @click="gotoDetailsWithStatus(cItem.status, cItem.title)"
                  >
                    {{ cItem.title }} : {{ cItem.value }}
                  </k-link>
                </div>
              </div>
            </template>
          </div>
          <div class="deliveries" style="color: black" v-else>
            {{ $g("notMessageDetailDeliveries") }}
          </div>
        </div>
      </div>
    </div>
    <k-modal
      ref="mdPop"
      id="pop"
      size="lg"
      no-enforce-focus
      @ok="changeSchedule"
      :title="$g('changeScheduleDateTime')"
    >
      <template #modal-footer="{ cancel, ok }">
        <k-button variant="primary" @click="ok()"> buttons.save </k-button>
        <k-button @click="cancel()"> buttons.cancel </k-button>
      </template>
      <KValidationObserver ref="observer">
        <k-form-group
          label-suffix="scheduleDateTime"
          label-align-md="right"
          label-cols-md="3"
          content-cols-lg="8"
          content-cols-md="9"
        >
          <KValidationProvider
            rules="required|validateDateIsRequired|validateDateTimeFormat|validateDatetimeBigNow"
            v-slot="{ errors }"
            slim
            name="datetimepickerStart"
          >
            <k-alert :show="errors.length > 0" variant="warning">
              {{ errors[0] }}
            </k-alert>
            <k-form-date-timepicker
              class="mb-2"
              v-model="scheduleDateTimeValue"
            />
          </KValidationProvider>
        </k-form-group>
      </KValidationObserver>
    </k-modal>
  </div>
</template>

<script>
import DeliveryResultNav from "../../components/deliveryResultNav";
import { GChart } from "vue-google-charts/legacy";

import {
  getRecipientsDropdown,
  getRecipientsContent,
  cancelMessage,
  sendImmediately,
  updateScheduleDatetime,
  getDeliveryResultReportDetailInfo,
  getDeliveryResultReportDetailInfoRecipients,
  getDeliveryResultDetailList,
} from "../../api/messageDeliveryResult";
import { repResult } from "../../../../libs/global";
import { saveAlert } from "../../../../utils/saveAlert";
import { enumDeliveryResultType } from "../../enums/enumDeliveryResultType";
import { has } from "../../../../utils/permission/operatePermission";
import * as echarts from "echarts/core";
import { PieChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import { downFilesById } from "../../../../business-components/common-massage/api/messageTemplate";
import HuiAudio from "../../../../components/media/audio";
import storeage from "../../../../utils/storeage";
import { permissionCode } from "../../mixins/permissionCode";
import { DeliveryResults } from "../../mixins/messagePage";

echarts.use([PieChart, CanvasRenderer]);

export default {
  mixins: [permissionCode, DeliveryResults],
  props: {
    permissionCode: {
      type: Object,
      default() {
        return null;
      },
    },
    permissionCodeEvent: {
      type: Object,
      default() {
        return null;
      },
    },
    propApi: {
      type: Object,
      default() {
        return {
          getRecipientsDropdown,
          getRecipientsContent,
          cancelMessage,
          sendImmediately,
          updateScheduleDatetime,
          getDeliveryResultReportDetailInfo,
          getDeliveryResultReportDetailInfoRecipients,
          getDeliveryResultDetailList,
        };
      },
    },
  },
  data() {
    let isEvent = this.$route.name.startsWith("ele");
    return {
      chartType: "PieChart",
      colorConfigDefault: [
        "rgb(170,198,231)",
        "rgb(246,185,159)",
        "rgb(196,196,196)",
      ],
      chartData: [],
      options: {
        title: "",
        is3D: false,
        colors: this.colorConfigDefault,
        series: this.colorConfigDefault,
        chartArea: {
          width: "80%",
          height: "80%",
        },
        legend: { position: "none" },
        width: 300,
        height: 200,
      },
      enumDeliveryResultType,
      isEvent: isEvent,
      navIndex: 0,
      id: this.$route.query.id,
      deliveryType: this.$route.query.deliveryType,
      contactCount: this.$route.query.contactCount,
      showMessageDetail: false,
      messageDetail: "",
      electionId: isEvent ? storeage.getElectionId() : "",
      data: {
        title: "",
        subject: "",
        content: {},
        docDetail: [],
        successful: {
          count: 0,
          chartDisplayName: "",
          content: [],
        },
        unSuccessful: {
          count: 0,
          chartDisplayName: "",
          content: [],
        },
        didNotDelivery: {
          count: 0,
          chartDisplayName: "",
          content: [],
        },
        isShowCancel: false,
        isShowSendImmediately: false,
        isShowSchedule: false,
        scheduleDateTimeValue: null,
        isShowChart: false,
        filePath: "",
        fileName: "audio",
      },
      recipients: "",
      recipientsList: [],
      recipientsContent: "",
      suffixs: [
        "Successful Deliveries",
        "Unsuccessful Deliveries",
        "Did not Delivery",
      ],
      suffixsText: [
        this.$g("successfulDeliveries"),
        this.$g("unsuccessfulDeliveries"),
        this.$g("didnotDelivery"),
      ],
      scheduleDateTimeValue: null,
      isUseHisotryBack: this.$route.query.historyBack === "1",
      isCancel: true,
      isChangeDate: true,
      isImmediately: true,
      isHidePart: false,
      backParams: {},
      wikiHelpCode: null,
    };
  },
  components: {
    DeliveryResultNav,
    HuiAudio,
    GChart,
  },
  methods: {
    setPermission() {
      let wikiHelpCodes = this.isEvent
        ? this.$wikiHelpLinkCodes?.Event.Communication?.DeliveryResult
        : this.$wikiHelpLinkCodes?.BAU.Communication?.DeliveryResult;
      if (this.isNotification) {
        wikiHelpCodes = this.isEvent
          ? this.$wikiHelpLinkCodes?.Event.Notification?.DeliveryResult
          : this.$wikiHelpLinkCodes?.BAU.Notification?.DeliveryResult;
      }
      switch (parseInt(this.$route.query.deliveryType ?? -1)) {
        case enumDeliveryResultType.email:
          if (this.isEvent) {
            this.isCancel = has(
              this.permissionCodeEvent.Communication.EmailResultCancel
            );
            this.isChangeDate = has(
              this.permissionCodeEvent.Communication
                .EmailResultChangeScheduleDate
            );
            this.isImmediately = has(
              this.permissionCodeEvent.Communication.EmailResultSendImmediately
            );
          } else {
            this.isCancel = has(
              this.permissionCode.Communication.EmailResultCancel
            );
            this.isChangeDate = has(
              this.permissionCode.Communication.EmailResultChangeScheduleDate
            );
            this.isImmediately = has(
              this.permissionCode.Communication.EmailResultSendImmediately
            );
          }
          this.wikiHelpCode = wikiHelpCodes?.Email.DeliveryReport;
          break;
        case enumDeliveryResultType.sms:
          if (this.isEvent) {
            this.isCancel = has(
              this.permissionCodeEvent.Communication.SMSResultCancel
            );
            this.isChangeDate = has(
              this.permissionCodeEvent.Communication.SMSResultChangeScheduleDate
            );
            this.isImmediately = has(
              this.permissionCodeEvent.Communication.SMSResultSendImmediately
            );
          } else {
            this.isCancel = has(
              this.permissionCode.Communication.SMSResultCancel
            );
            this.isChangeDate = has(
              this.permissionCode.Communication.SMSResultChangeScheduleDate
            );
            this.isImmediately = has(
              this.permissionCode.Communication.SMSResultSendImmediately
            );
          }
          this.wikiHelpCode = wikiHelpCodes?.SMS.DeliveryReport;
          break;
        case enumDeliveryResultType.phone:
          if (this.isEvent) {
            this.isCancel = has(
              this.permissionCodeEvent.Communication.PhoneResultCancel
            );
            this.isChangeDate = has(
              this.permissionCodeEvent.Communication
                .PhoneResultChangeScheduleDate
            );
            this.isImmediately = has(
              this.permissionCodeEvent.Communication.PhoneResultSendImmediately
            );
          } else {
            this.isCancel = has(
              this.permissionCode.Communication.PhoneResultCancel
            );
            this.isChangeDate = has(
              this.permissionCode.Communication.PhoneResultChangeScheduleDate
            );
            this.isImmediately = has(
              this.permissionCode.Communication.PhoneResultSendImmediately
            );
          }
          this.wikiHelpCode = wikiHelpCodes?.Phone.DeliveryReport;
          break;

        default:
          this.wikiHelpCode = null;
          break;
      }
    },
    permissionJudge() {
      let pl_code = this.isEvent
        ? this.permissionCodeEvent
        : this.permissionCode;
      let isHasEmailSub = has([
        pl_code.Communication.EmailResultOutbox,
        pl_code.Communication.EmailResultDelivered,
        pl_code.Communication.EmailResultAll,
      ]);
      let isHasSMSSub = has([
        pl_code.Communication.SMSResultOutbox,
        pl_code.Communication.SMSResultDelivered,
        pl_code.Communication.SMSResultAll,
      ]);
      let isHasPhoneSub = has([
        pl_code.Communication.PhoneResultOutbox,
        pl_code.Communication.PhoneResultDelivered,
        pl_code.Communication.PhoneResultAll,
      ]);
      switch (this.$route.query.deliveryType) {
        case enumDeliveryResultType.email:
          this.isHidePart = !isHasEmailSub;
          break;
        case enumDeliveryResultType.sms:
          this.isHidePart = !isHasSMSSub;
          break;
        case enumDeliveryResultType.phone:
          this.isHidePart = !isHasPhoneSub;
          break;
          GChart;

        default:
          break;
      }
    },
    showScheduleModal() {
      this.scheduleDateTimeValue = this.data.scheduleDateTimeValue;
      this.$refs["mdPop"].show();
    },
    downloadFile(docId) {
      downFilesById(docId);
    },
    gotoDetailsWithStatus(status, title) {
      this.$router.push({
        name: this.getMessageDeliveryDetailsRoute(),
        query: {
          id: this.id,
          hidePreNext: this.$route.query.hidePreNext,
          dbtn: this.$route.query.dbtn,
          deliveryType: this.$route.query.deliveryType,
          status: status,
          title: title,
          historyBack: this.$route.query.historyBack,
        },
      });
    },
    initChart() {
      this.chartData.push(["Name", this.$g("Number")]);
      this.chartData.push([
        this.data.successful.count === 0
          ? ""
          : this.data.successful.chartDisplayName,
        this.data.successful.count,
      ]);
      this.chartData.push([
        this.data.unSuccessful.count === 0
          ? ""
          : this.data.unSuccessful.chartDisplayName,
        this.data.unSuccessful.count,
      ]);
      this.chartData.push([
        this.data.didNotDelivery.count === 0
          ? ""
          : this.data.didNotDelivery.chartDisplayName,
        this.data.didNotDelivery.count,
      ]);
      // let myChart = echarts.init(document.getElementById("chart"));
      // let option = {
      //   series: [
      //     {
      //       type: "pie",
      //       label: {
      //         show: true,
      //         position: "inside",
      //       },
      //       left: 0,
      //       right: 0,
      //       top: 0,
      //       bottom: 0,
      //       data: [
      //         {
      //           value: this.data.successful.count,
      //           name:
      //             this.data.successful.count === 0
      //               ? ""
      //               : this.data.successful.chartDisplayName,
      //           itemStyle: {
      //             color: "rgb(170,198,231)",
      //           },
      //         },
      //         {
      //           value: this.data.unSuccessful.count,
      //           name:
      //             this.data.unSuccessful.count === 0
      //               ? ""
      //               : this.data.unSuccessful.chartDisplayName,
      //           itemStyle: {
      //             color: "rgb(246,185,159)",
      //           },
      //         },
      //         {
      //           value: this.data.didNotDelivery.count,
      //           name:
      //             this.data.didNotDelivery.count === 0
      //               ? ""
      //               : this.data.didNotDelivery.chartDisplayName,
      //           itemStyle: {
      //             color: "rgb(196,196,196)",
      //           },
      //         },
      //       ],
      //     },
      //   ],
      // };
      // myChart.setOption(option);
    },
    cancel() {
      let pmOpt = this.$confirm({
        title: this.$g("const.confirm.confirm"),
        content: this.$g("const.confirm.cancelMsg"),
        cancelType: "secondary",
        okText: this.$g("const.confirm.yes"),
        cancelText: this.$g("const.confirm.no"),
      });
      if (pmOpt == null) {
        return;
      }
      pmOpt.then(() => {
        this.propApi.cancelMessage([this.id]).then((rep) => {
          this.$refs.lblMsg.message(rep.data.status, rep.message);
          if (rep.data.status === repResult.success) {
            this.getMessageDetailAndChartData();
            //   this.$router.push({
            //     name: 'messageDeliveryResult',
            //     query: this.$route.query,
            //   })
          }
        });
      });
    },
    sendImmediately() {
      let pmOpt = this.$confirm({
        title: this.$g("const.confirm.confirm"),
        content: this.$g("const.confirm.sendImmediately"),
        cancelType: "secondary",
        okText: this.$g("const.confirm.yes"),
        cancelText: this.$g("const.confirm.no"),
      });
      if (pmOpt == null) {
        return;
      }
      pmOpt.then(() => {
        let paras = {
          msgId: this.id,
          type: this.deliveryType,
        };
        this.propApi.sendImmediately(paras).then((r) => {
          this.$refs.lblMsg.message(r.data.status, r.message);
          if (r.data.status === repResult.success) {
            this.getMessageDetailAndChartData();
            // this.$router.push({
            //   name: 'messageDeliveryResult',
            //   query: this.$route.query,
            // })
          }
        });
      });
    },
    async changeSchedule(bvModalEvt) {
      bvModalEvt.preventDefault();
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        this.$bvModal
          .msgBoxConfirm(this.$g("const.confirm.save"), {
            title: this.$g("const.confirm.confirm"),
            cancelTitle: this.$g("const.confirm.saveYes"),
            okTitle: this.$g("const.confirm.no"),
            cancelVariant: "primary",
            okVariant: "secondary",
            noCloseOnBackdrop: true,
          })
          .then((value) => {
            let result = value != null && !value;
            if (result) {
              this.$refs["mdPop"].hide();
              let paras = {
                id: this.id,
                scheduleDateTime: this.scheduleDateTimeValue,
              };
              this.propApi.updateScheduleDatetime(paras).then((m) => {
                this.$refs.lblMsg.message(m.data.status, m.message);
                if (m.data.status === repResult.success) {
                  this.getMessageDetailAndChartData();
                  // this.$router.push({
                  //   name: 'messageDeliveryReport',
                  //   query: {
                  //     id: this.id,
                  //     isView: '1',
                  //     dbtn: '0',
                  //     deliveryType: this.deliveryType,
                  //   },
                  // })
                }
              });
            }
          });
      }
    },
    showAlert() {
      let status = saveAlert.savedStatus(this.$route);
      if (status === repResult.success) {
        this.$refs.lblMsg.message(status, saveAlert.savedMessage(this.$route));
      }
    },
    getRecipientsContent() {
      let paras = {
        id: this.recipients,
      };
      this.getMessageDetailAndChartData();
      this.propApi.getRecipientsContent(paras).then((res) => {
        if (this.$route.query.deliveryType == enumDeliveryResultType.email) {
          this.recipientsContent = res.data
            .replace(/\r\n/g, "")
            .replace(
              /<[\/]?(p)(:?\s+(:?class|style)=(['"])[^'"]*['"])*>/g,
              function (m, m1) {
                return m.replace("p", "div");
              }
            );
        } else {
          this.recipientsContent = res.data
            .replace(/\r\n/g, "<br/>")
            .replace(
              /<[\/]?(p)(:?\s+(:?class|style)=(['"])[^'"]*['"])*>/g,
              function (m, m1) {
                return m.replace("p", "div");
              }
            );
        }
      });
    },
    bindRecipientsList() {
      let paras = {
        id: this.id,
      };
      this.propApi.getRecipientsDropdown(paras).then((res) => {
        this.recipientsList = (res.data || []).filter((item) => item.value);
        if (this.recipientsList.length > 0) {
          this.recipients = this.recipientsList[0].value;
          this.getRecipientsContent();
        } else {
          this.getMessageDetailAndChartData();
        }
      });
    },
    getMessageDetailAndChartData() {
      let doApi;
      if (this.recipients) {
        doApi = this.propApi.getDeliveryResultReportDetailInfoRecipients(
          this.id,
          this.recipients,
          0
        );
      } else {
        doApi = this.propApi.getDeliveryResultReportDetailInfoRecipients(
          this.id,
          "00000000-0000-0000-0000-000000000000",
          this.contactCount || 0
        );
      }
      doApi.then((res) => {
        if (res) {
          this.data = res.data;

          this.showMessageDetail = res.data.showMessageDetail;
          this.messageDetail = res.messageDetail;

          this.chartData = [];
          this.initChart();
        }
      });
    },
    isAddDeliveryDetailsTab() {
      let paras = {
        id: this.id,
        electionId: this.electionId,
        name: "",
        contactInfo: "",
        status: [],
      };
      this.propApi.getDeliveryResultDetailList(paras).then((rep) => {
        if (rep.data.rows.length > 0 && !this.isHidePart) {
          this.$refs.nav.addDeliveryDetailsTab();
        }
      });
    },
    setBackParam() {
      if (this.$route.query.fromSend) {
        this.backParams.fromSend = 1;

        this.backParams = { tabindex: 1, fromSend: 1 };
        switch (this.$route.query.deliveryType) {
          case enumDeliveryResultType.email:
            this.backParams.tabindex = 0;
            break;
          case enumDeliveryResultType.sms:
            this.backParams.tabindex = 1;
            break;
          case enumDeliveryResultType.phone:
            this.backParams.tabindex = 2;
            break;
          default:
            this.backParams.tabindex = 0;
            break;
        }
      }
    },
  },
  mounted() {
    this.isAddDeliveryDetailsTab();
    this.showAlert();
    this.bindRecipientsList();
    this.setPermission();
    this.permissionJudge();
    this.setBackParam();
  },
};
</script>

<style scoped lang="scss">
.message {
  padding: 12px 12px 12px 12px;
  text-align: center;
}

.notice {
  min-height: 2rem;
  font-size: 1.2rem;
  padding: 0.5rem;
  margin-bottom: 2rem;
}

.deliveries {
  display: inline-block;
  vertical-align: top;
  color: #007bff;

  .item {
    width: 300px;
    margin-top: 1rem;
    font-weight: bold;
  }

  .itemChild {
    display: block;
    padding-left: 50px;

    a {
      display: block;
      position: relative;
      height: 25px;
      line-height: 25px;

      &::before {
        content: "";
        display: inline-block;
        width: 15px;
        height: 15px;
        border-bottom: 1px solid #007bff;
        border-left: 1px solid #007bff;
        position: absolute;
        left: -25px;
        top: 0;
        pointer-events: none;
      }

      &:nth-child(n + 2) {
        &::before {
          height: 25px;
          top: -12px;
        }
      }
    }
  }

  a {
    text-decoration: underline;
  }
}

/deep/ .marginNone .form-group {
  margin-bottom: 0;
}
</style>
