<template>
  <div>
    <detail
      :save="save"
      ref="detail"
      :plaintext="false"
    >
      <template #formBar>
        <b-btn-group>
          <k-button-back
            :back-route="isEvent?'eleTemplate':'template'"
            :back-query="{ tabindex: $route.query.tabindex }"
          >
            buttons.back
          </k-button-back>
          <k-button
            type="submit"
            @click="onSave"
          >
            buttons.save
          </k-button>
          <k-button
            @click="
              $router.push({
                name: isEvent?'eleTemplate-view':'template-view',
                query: {
                  id: $route.query.id,
                  tabindex:$route.query.tabindex
                },
              })
            "
          >
            buttons.view
          </k-button>
          <k-pre-next
            v-show="false"
            ref="pn"
            :has-save-next="true"
            :before-to-leave="preNextToLeave"
          />
        </b-btn-group>
      </template>
      <template #formMsg>
        <k-alert-msg ref="lblMsg" />
      </template>
    </detail>
  </div>
</template>

<script>
import Detail from "../../components/template-detail";
import { saveReportTemplate } from "../../api/report";
import { beforeRouteLeaveMixin } from "../../../../utils/mixins/mixin";
import { repResult } from "../../../../libs/global";
import { saveAlert } from "../../../../utils/saveAlert";

export default {
  mixins: [beforeRouteLeaveMixin],
  data(){
    return {
      isEvent: this.$route.meta.isEvent ?? false,
    };
  },
  components: {
    Detail,
  },
  methods: {
    showAlert(){
      let status = saveAlert.savedStatus(this.$route);
      if(status === repResult.success){
        this.$refs.lblMsg.message(status, saveAlert.savedMessage(this.$route));
      }
    },
    save(form){
      let pmOpt = this.$confirm({
        title: this.$g("const.confirm.confirm"),
        content: this.$g("const.confirm.save"),
        cancelType: "secondary",
        okText: this.$g("const.confirm.saveYes"),
        cancelText: this.$g("const.confirm.no"),
      });
      if(pmOpt == null){
        return;
      }
      pmOpt.then(async () => {
        saveReportTemplate(form).then((response) => {
          this.isDoSave = true;
          if(this.$refs.pn.checkSaveNexted()){
            this.$refs.pn.goToNext(response);
          } else {
            if(response.data.status === repResult.success){
              this.$router.push({
                name: this.isEvent ? "eleTemplate-view" : "template-view",
                query: {
                  id: response.data.info,
                },
                params: {
                  message: response.message,
                  status: response.data.status,
                },
              });
            } else {
              this.$refs.lblMsg.message(response.data.status, response.message);
            }
          }
        });
      });
    },
  },
  mounted(){
    this.showAlert();
  },
};
</script>


