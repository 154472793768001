var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isShow)?_c('div',{staticClass:"mt-3 pl-0 file-group-width"},[_c('b-list-group',_vm._l((_vm.files),function(file,index){return _c('b-list-group-item',{key:index,staticClass:"d-flex justify-content-between align-items-center"},[(file.documentId)?_c('k-link',{on:{"click":function($event){return _vm.downloadPath(file.documentId)}}},[_vm._v(" "+_vm._s(file.name ? file.name : file.fileName)+" ")]):_c('div',[_vm._v(" "+_vm._s(file.name ? file.name : file.fileName)+" ")]),(!_vm.plaintext)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
            placement: 'bottomleft',
            trigger: 'hover',
            title: _vm.$g('icon.deleteToolTip'),
            boundary: 'window',
            container: 'tipUpload_' + index,
          }),expression:"{\n            placement: 'bottomleft',\n            trigger: 'hover',\n            title: $g('icon.deleteToolTip'),\n            boundary: 'window',\n            container: 'tipUpload_' + index,\n          }"}],attrs:{"id":'tipUpload_' + index}},[_c('b-icon',{attrs:{"icon":"trash"},on:{"click":function($event){return _vm.deleteFile(index)}}})],1):_vm._e()],1)}),1)],1):_c('div',{staticClass:"mt-3"})])
}
var staticRenderFns = []

export { render, staticRenderFns }