<template>
  <div id="map">
    <GmapMap
      :center="{ lat: defaultLat, lng: defaultLng }"
      :options="{ gestureHandling: gestureHandlingProps }"
      :zoom="12"
      style="width: 100%; height: 100%"
      ref="mapRef"
      class="content-group"
    />
  </div>
</template>
<script>
/**
 * :options="{
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        disableDefaultUi: false
      }"
 */
export default {
  props: {
    mapClicked: {
      type: Function,
      default() {
        return null;
      },
    },
    mapInit: {
      type: Function,
      default() {
        return null;
      },
    },
    zoom: {
      type: Number,
      default() {
        return 12;
      },
    },
    isDefaultZoom: {
      type: Boolean,
      default: false,
    },
    gestureHandlingProps: {
      type: String,
      default: "auto", //cooperative or auto or greedy
    },
  },
  data() {
    return {
      geoJson: null,
      map: null,
      isLoaded: false,
      defaultLat: 0,
      defaultLng: 0,
    };
  },
  computed: {},
  mounted() {
    this.$refs.mapRef.$mapPromise.then((map) => {
      this.map = map;
      this.initMap(map);
    });
  },
  methods: {
    setLocation(Lat, Lng) {
      this.map.setCenter(new google.maps.LatLng(Lat, Lng));
    },
    setZoom(zoom = 12) {
      this.map.setZoom(zoom);
    },
    getMapData() {
      return this.map.data;
    },
    getMap() {
      return this.map;
    },
    clear(featureType) {
      let me = this;
      this.map.data.forEach(function(feature) {
        if (
          !featureType ||
          (featureType && feature.getProperty("featureType") == featureType)
        ) {
          me.map.data.remove(feature);
        }
      });
    },
    addGeoJson(geoJson) {
      let center = geoJson.center;
      if (center && center.latitude && center.latitude) {
        this.defaultLat = center.latitude;
        this.defaultLng = center.longitude;
      }
      if (geoJson.features && geoJson.features.length > 0) {
        this.map.data.addGeoJson(geoJson);
      }
    },
    initMap(map) {
      let me = this;
      // console.log(google.maps.LatLngBounds);
      // eslint-disable-next-line no-undef
      let bounds = new google.maps.LatLngBounds();
      map.data.addListener("addfeature", (e) => {
        map.data.forEach((feature) => {
          feature.getGeometry().forEachLatLng(function(latlng) {
            bounds.extend(latlng);
          });
        });
        me.map.fitBounds(bounds);
        if (this.isDefaultZoom) {
          me.map.setZoom(this.zoom);
        }
      });
      if (this.mapInit) {
        this.mapInit(map, this);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
#map {
  height: 100%;
  max-height: 500px;
  min-height: 400px;
}
</style>
