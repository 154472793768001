export default {
  version: state => "(Version " + state.sys.version + ")",
  environment: state => state.sys.environment,
  timezone: state => state.app.timezone,
  isCollapse: state => state.app.isCollapse,
  portalMessageCount: state => state.app.portalMessageCount,
  token: state => state.user.token,
  tokenKey: state => state.user.tokenKey,
  systems: state => state.user.systems,
  menus: state => state.user.menus,
  tenant: state => state.user.tenant,
  username: state => state.user.username,
  nickname: state => state.user.nickname,
  pageState: state => state.page.pageState,
  exportCondition: state => state.page.exportCondition,
  toggleState: state => state.menu.toggleState,
  communicationIds: state => state.user.communicationIds,
  permissionList: state => state.user.permissionList,
  tenantkey: state => state.user.tenantkey,
  roletimestamp: state => state.user.roletimestamp,
  pageTabs: state => state.pageTab.pageTabs,
  nav: state => state.nav.nav,
  navToggle: state => state.nav.navToggle,
};
