var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-group"},[_c('k-form-group',{attrs:{"label-suffix":"subject","label-for":"Subject","label-align-md":"right","label-cols-md":"2","content-cols-md":"9"}},[_c('KValidationProvider',{ref:"subjectValidation",attrs:{"rules":{
        required: false,
        max: 200,
        validateSpecialCharacter: false,
        remote: _vm.isSubjectExits,
      },"slim":"","name":"subject"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-input',{attrs:{"plaintext":_vm.isPlainText},model:{value:(_vm.form.subject),callback:function ($$v) {_vm.$set(_vm.form, "subject", $$v)},expression:"form.subject"}})]}}])})],1),(
      _vm.templateType === _vm.enumTemplateType.email ||
      _vm.templateType === _vm.enumTemplateType.letter
    )?_c('k-form-group',{attrs:{"label-suffix":"header","label-for":"Header","label-align-md":"right","label-cols-md":"2","content-cols-md":"9"}},[_c('KValidationProvider',{ref:"headerValidation",attrs:{"rules":{ required: false },"slim":"","name":"header"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-select',{attrs:{"options":_vm.headerOptions},model:{value:(_vm.form.headerId),callback:function ($$v) {_vm.$set(_vm.form, "headerId", $$v)},expression:"form.headerId"}})]}}],null,false,596158734)})],1):_vm._e(),(_vm.templateType === _vm.enumTemplateType.letter)?_c('k-form-group',{attrs:{"label-class":_vm.getHeaderDisplayeaderRequired ? 'require-mark' : '',"label-suffix":"headerDisplayeader","label-for":"headerDisplayeader","label-align-md":"right","label-cols-md":"2","content-cols-md":"9"}},[_c('KValidationProvider',{ref:"headerDisplayValidation",attrs:{"rules":{ required: _vm.getHeaderDisplayeaderRequired },"slim":"","name":"headerDisplayeader"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-select',{attrs:{"options":_vm.getHeaderDisplayeaderRequired ? _vm.headerDisplayeaderOptions : []},model:{value:(_vm.form.headerDisplay),callback:function ($$v) {_vm.$set(_vm.form, "headerDisplay", $$v)},expression:"form.headerDisplay"}})]}}],null,false,1153300250)})],1):_vm._e(),(
      _vm.templateType === _vm.enumTemplateType.email ||
      _vm.templateType === _vm.enumTemplateType.letter
    )?_c('k-form-group',{attrs:{"label-suffix":"footer","label-for":"Footer","label-align-md":"right","label-cols-md":"2","content-cols-md":"9"}},[_c('KValidationProvider',{ref:"footerValidation",attrs:{"rules":{ required: false },"slim":"","name":"footer"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-select',{attrs:{"options":_vm.footerOptions},model:{value:(_vm.form.footerId),callback:function ($$v) {_vm.$set(_vm.form, "footerId", $$v)},expression:"form.footerId"}})]}}],null,false,251433230)})],1):_vm._e(),(_vm.templateType === _vm.enumTemplateType.letter)?_c('k-form-group',{attrs:{"label-class":_vm.getFooterDisplayRequired ? 'require-mark' : '',"label-suffix":"footerDisplay","label-for":"footerDisplay","label-align-md":"right","label-cols-md":"2","content-cols-md":"9"}},[_c('KValidationProvider',{ref:"footerDisplayValidation",attrs:{"rules":{ required: _vm.getFooterDisplayRequired },"slim":"","name":"footerDisplay"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-select',{attrs:{"options":_vm.getFooterDisplayRequired ? _vm.footerDisplayOptions : []},model:{value:(_vm.form.footerDisplay),callback:function ($$v) {_vm.$set(_vm.form, "footerDisplay", $$v)},expression:"form.footerDisplay"}})]}}],null,false,2514724672)})],1):_vm._e(),(_vm.templateType === _vm.enumTemplateType.email)?_c('k-form-group',{attrs:{"label-suffix":"attachment","label-for":"Attachment","label-align-md":"right","label-cols-md":"2","content-cols-md":"9"}},[_c('KValidationProvider',{ref:"fileInputValidation",attrs:{"rules":{
        required: false,
        acceptFileType: { fileAccept: _vm.accept },
        maxFileSize: _vm.size * 1024,
      },"slim":"","name":"attachment"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('common-upload',{key:_vm.uploadKey,ref:"fileinput",attrs:{"accept":_vm.accept,"size":_vm.size,"is-multiple":true,"on-delete":_vm.onDelete,"placeholder":_vm.placeholder,"show-message":false,"is-use-internal-validate":false,"plaintext":_vm.isPlainText},model:{value:(_vm.getFile),callback:function ($$v) {_vm.getFile=$$v},expression:"getFile"}})]}}],null,false,1555875243)})],1):_vm._e(),(
      _vm.templateType === _vm.enumTemplateType.email ||
      _vm.templateType === _vm.enumTemplateType.letter
    )?_c('k-form-group',{attrs:{"label-suffix":"content","label-for":"noneBind","label-align-md":"right","label-cols-md":"2","content-cols-md":"9"}},[_c('KValidationProvider',{ref:"messageEditorValidation",attrs:{"rules":{ required: false },"slim":"","name":_vm.$g('content')},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('message-editor',{ref:"messageEditor",attrs:{"is-plain-text":_vm.isPlainText,"ckeditorHeight":300},on:{"select":_vm.onLabelSelect},model:{value:(_vm.form.body),callback:function ($$v) {_vm.$set(_vm.form, "body", $$v)},expression:"form.body"}},[_vm._t("insertLabel")],2)]}}],null,true)})],1):_vm._e(),(_vm.templateType === _vm.enumTemplateType.sms)?_c('k-form-group',{attrs:{"label-suffix":"content","label-for":"noneBind","label-align-md":"right","label-cols-md":"2","content-cols-md":"9"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$g("content"))+":"),_vm._t("smsInsertLabel",null,{"form":_vm.form})]},proxy:true}],null,true)},[_c('KValidationProvider',{ref:"bodyValidation",attrs:{"rules":{ required: false },"slim":"","name":_vm.$g('content')},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-textarea',{ref:"taContent",attrs:{"plaintext":_vm.isPlainText,"rows":"10"},model:{value:(_vm.form.body),callback:function ($$v) {_vm.$set(_vm.form, "body", $$v)},expression:"form.body"}}),(!_vm.isPlainText)?_c('div',[_vm._v(" "+_vm._s(`${_vm.maxContent - _vm.contentLength} ${_vm.$g("characterLeft")}`)+" ")]):_vm._e()]}}],null,false,3490348797)})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }