<template>
  <div>
    <div
      v-if="isNoData"
      style="text-align: center;margin: 320px 0 320px 0"
    >
      No data
    </div>
    <GChart
      v-else
      :type="chartType"
      :data="data"
      :options="options"
      ref="googleChart"
      :events="chartEvents"
      :settings="{ packages: ['corechart', 'table', 'sankey'] }"
      @ready="initChart"
      :style="styleObject"
    />
  </div>
</template>

<script>
import { GChart } from "vue-google-charts/legacy";

export default {
  props: {
    itemsColumn: {
      type: Array,
      default(){
        return [];
      },
    },
    itemsPie: {
      type: Array,
      default(){
        return [];
      },
    },
    isBottom: {
      type: Boolean,
      default(){
        return true;
      },
    },
    hasLegend: {
      type: Boolean,
      default(){
        return true;
      },
    },
    autoBind: {
      type: Boolean,
      default(){
        return true;
      },
    },
    hasColor: {
      type: Boolean,
      default(){
        return false;
      },
    },
    is3D: {
      type: Boolean,
      default(){
        return false;
      },
    },
    chartData: {
      type: Function,
      default(){
        return null;
      },
    },

    getCondition: {
      type: Object,
      default(){
        return null;
      },
    },
    chartType: {
      type: String,
      default(){
        return "BarChart";
      },
    },
    title: {
      type: String,
      default(){
        return "";
      },
    },
    textFontSize: {
      type: Number,
      default(){
        return 16;
      },
    },
    subTextFontSize: {
      type: Number,
      default(){
        return 16;
      },
    },
    chartTitle: {
      type: Array,
      default(){
        return null;
      },
    },
    pieHole: {
      type: Number,
      default(){
        return 0;
      },
    },
    hasMoreValue: {
      type: Boolean,
      default(){
        return false;
      },
    },
    isNoNegativeValue: {
      type: Boolean,
      default(){
        return false;
      },
    },
    width: {
      type: String,
      default(){
        return null;
      },
    },
    height: {
      type: String,
      default(){
        return null;
      },
    },
    legendSize: {
      type: Number,
      default(){
        return 20;
      },
    },
    colorConfig: {
      type: Array,
      default(){
        return [];
      },
    },
    pieLabelFormatter: {
      type: String,
      default(){
        return "{b}:   {c}  ({d}%)";
      },
    },
  },
  data(){
    return {
      styleObject: {
        cursor: "default",
      },
      chartEvents: {
        select: () => {
          const chart = this.$refs.googleChart.chartObject;
          const selection = chart.getSelection();
          let params = null;
          if(this.itemsPie.length > 0 && selection[0]){
            params = { data: this.itemsPie[selection[0].row] };
          } else if(this.itemsColumn.length > 0 && selection[0]){
            params = { data: this.itemsColumn[selection[0].row] };
          }
          this.$emit("gchartClick", params);
        },
        onmouseover: () => {
          this.styleObject = { cursor: "pointer" };
        },
        onmouseout: () => {
          this.styleObject = { cursor: "default" };
        },
      },
      colorConfigDefault: [
        "#5470c6",
        "#91cc75",
        "#fac858",
        "#ee6666",
        "#73c0de",
        "#749f83",
        "#3ba272",
        "#fc8452",
        "#9a60b4",
        "#ea7ccc",
        "#c4ccd3",
      ],
      options: {
        title: this.title,
        is3D: this.is3D,
        colors: this.colorConfigDefault,
        series: this.colorConfigDefault,
        sliceVisibilityThreshold: 0,
        chartArea: {
          width: "90%",
          height: "80%",
        },
        legend: this.hasLegend
            ? this.isBottom
                ? { position: "bottom", textStyle: { fontSize: this.legendSize } }
                : { position: "right", textStyle: { fontSize: this.legendSize } }
            : { position: "none", textStyle: { fontSize: this.legendSize } },
        width: this.width ?? window.width * 0.9,
        height: this.height ?? window.innerHeight * 0.85,
        pieHole: this.pieHole,
        tooltip: {},
        axes: {
          x: {
            0: { side: "bottom", label: "" }, // Top x-axis.
          },
        },
        vAxis: {},
      },
      res: [],
      data: [],
      isNoData: false,
    };
  },
  components: { GChart },
  watch: {
    itemsColumn: {
      deep: true,
      handler(){
        this.initChart();
      },
    },
  },
  methods: {
    initChart(){
      if(this.autoBind){
        this.chartData(this.getCondition).then((res) => {
          this.res = res.data;
          if(this.res && this.res.text.length > 0){
            switch (this.chartType) {
              case "PieChart":
                this.setPiChartData(this.res);
                break;
              case "BarChart":
                this.setBarChartData(this.res);
                break;
              case "ColumnChart":
                this.setColumnChartData(this.res);
                break;
              default:
                this.setPiChartData(this.res);
                break;
            }
          } else {
            this.isNoData = true;
          }
        });
      } else if(this.itemsPie.length > 0){
        this.res = this.itemsPie;
        console.log(this.pieLabelFormatter);
        if(this.chartTitle){
          this.data.push(this.chartTitle);
        } else {
          this.data.push([
            "Name",
            "Number",
            { type: "string", role: "tooltip" },
          ]);
        }
        if(this.hasColor){
          this.colorConfigDefault = [];
        }
        let total = 0;
        for (let index = 0; index < this.res.length; index ++) {
          total += this.res[index].value;
        }
        this.res.forEach((item) => {
          let percentage = (
              (item.value * 100) /
              (total == 0 ? 1 : total)
          ).toFixed(1);
          let tooltip = "";
          if(this.pieLabelFormatter != ""){
            tooltip = this.pieLabelFormatter
                .replace("{b}", item.name)
                .replace("{c}", item.value)
                .replace("{d}", percentage);
          } else {
            this.options.tooltip = { trigger: "none" };
          }
          this.data.push([
            item.name,
            total == 0
                ? item.value == 0
                    ? 0.0000000001
                    : item.value
                : item.value,
            tooltip,
          ]);
          if(this.hasColor){
            this.colorConfigDefault.push(item.itemStyle.color);
            this.options.colors = this.colorConfigDefault;
            this.options.series = this.colorConfigDefault;
          }
        });
      } else if(this.itemsColumn.length > 0){
        console.log(this.itemsColumn);
        if(this.hasColor && this.colorConfig.length > 0){
          this.options.colors = this.colorConfig;
          this.options.series = this.colorConfig;
        }
        this.res = this.itemsColumn;
        console.log(this.res);
        let firstRow = ["Group"];
        let keys = Object.keys(this.res[0]);
        for (let index = 0; index < keys.length; index ++) {
          const key = keys[index];
          if(key != "Group" && key != "__ob__"){
            firstRow.push(key);
          }
        }
        this.data.push(firstRow);
        this.res.forEach((item) => {
          let itemDate = [];
          for (let index = 0; index < keys.length; index ++) {
            const key = keys[index];
            if(key != "Group"){
              itemDate.push(Number(item[key]));
            } else {
              itemDate.push(item[key]);
            }
          }
          this.data.push(itemDate);
        });
        this.options.legend = { position: "top" };
      }
    },
    refresh(){
      this.initChart();
    },
    setPiChartData(res){
      if(this.hasColor){
        this.colorConfigDefault = [];
      }
      if(this.chartTitle){
        this.data.push(this.chartTitle);
      } else {
        this.data.push(["Name", "Number"]);
      }
      let total = 0;
      for (let index = 0; index < res.value.length; index ++) {
        total += res.value[index].value;
      }
      res.text.forEach((text, index) => {
        this.data.push([
          text,
          total == 0
              ? res.value[index].value == 0
                  ? 0.0000000001
                  : res.value[index].value
              : res.value[index].value,
        ]);
        if(this.hasColor){
          this.colorConfigDefault.push(res.value[index].color);
          this.options.colors = this.colorConfigDefault;
          this.options.series = this.colorConfigDefault;
        }
      });
    },
    setBarChartData(res){
      if(this.hasColor){
        this.colorConfigDefault = [];
      }
      if(this.chartTitle){
        this.data.push(this.chartTitle);
      } else {
        this.data.push(["Name", "Number", { role: "style" }]);
      }
      res.text.forEach((text, index) => {
        if(this.hasColor){
          this.data.push([
            text,
            res.value[index].value,
            res.value[index].color,
          ]);
        } else {
          this.data.push(
              [text, res.value[index].value],
              this.colorConfigDefault[index % this.colorConfigDefault.length],
          );
        }
      });
    },
    setColumnChartData(res){
      if(this.hasColor){
        this.colorConfigDefault = [];
      }
      if(this.hasMoreValue){
        if(this.chartTitle){
          this.data.push(this.chartTitle);
        } else {
          this.data.push(["Name", "Number", "Number2"]);
        }
        res.text.forEach((text, index) => {
          this.data.push([
            text,
            res.value[index].value,
            res.value[index].relateContent,
          ]);
          if(this.hasColor){
            this.colorConfigDefault.push(res.value[index].color);
            this.options.colors = this.colorConfigDefault;
            this.options.series = this.colorConfigDefault;
          }
        });
      } else {
        if(this.chartTitle){
          this.data.push(this.chartTitle);
        } else {
          this.data.push(["Name", "Number", { role: "style" }]);
        }
        res.text.forEach((text, index) => {
          if(this.hasColor){
            this.data.push([
              text,
              res.value[index].value,
              res.value[index].color,
            ]);
          } else {
            this.data.push([
              text,
              res.value[index].value,
              this.colorConfigDefault[index % this.colorConfigDefault.length],
            ]);
          }
        });

        if(this.isNoNegativeValue){
          let max = res.value.sort(function (a, b){
            return b.value - a.value;
          })[0].value;
          let ticks = [];
          let r = parseInt(max / 8) + 1;
          for (let i = 0; i < 8; i ++) {
            ticks.push(i * r);
          }
          console.log(1111, ticks);
          this.options.vAxis = {
            minValue: 0,
            ticks: ticks,
          };
        }
        console.log(this.data, res);
      }
    },
  },
  mounted(){
  },
};
</script>
<style>
.google-visualization-tooltip {
  pointer-events: none;
}
</style>
