import { extend } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import { localMessages } from "../../locale/lang/en/vee-validate.json";
import Moment from "moment";
import { cityRules as regRules } from "../validate/cityRules.js";
import storeage from "../../utils/storeage";
import { g } from "../../locale/lang-val";
import globalEnv from "../../libs/globalEnv";

function valueTrim(value) {
  let result = value;
  if (value && typeof value === "string") {
    result = value.trim();
  }
  return result;
}

function validateFunc(validate) {

  return function (...args) {
    let res = valueTrim(args[0]);
    args[0] = res;
    return validate(...args);
  };
}

function isReg(val) {
  let isRegValue;
  try {
    isRegValue = eval(val) instanceof RegExp;
  } catch (e) {
    isRegValue = false;
  }
  return isRegValue;
}

export const loadValidateRulesLanguageRes = () => {
  Object.keys(rules).forEach((rule) => {
    let { validate, ...params } = rules[rule];
    let vFunc = validateFunc(validate);
    extend(rule, {
      ...params, // copies rule configuration
      validate: vFunc,
      message: g(`validate.${rule}`), // assign message
    });
  });
};

if (globalEnv.VUE_APP_USE_LOCAL_LANGUAGE_RES) {
  Object.keys(rules).forEach((rule) => {
    let { validate, ...params } = rules[rule];
    let vFunc = validateFunc(validate);
    extend(rule, {
      ...params, // copies rule configuration
      validate: vFunc,
      message: localMessages[rule], // assign message
    });
  });
}

const paramNames = ["fn"];

extend("remote", {
  validate: async function (val, fn) {
    const response = await fn[0](val);
    if (response) {
      this.message = response.message;
      return response.data.status === 1;
    }

    this.message = g("validate.remote");
    return false;
  },
},
  { paramNames },
);



extend("validateTestControlRemote", {
  validate: async function (val, fn) {
    const response = await fn[0](val, fn[1]);
    if (response) {
      this.message = response.message;
      return response.data.status === 1;
    }
    this.message = g("validate.remote");
    return false;
  },
},
  { paramNames },
);

extend("money", {
  validate: (value) => {
    value = valueTrim(value);
    let reg = /(^(\d+)?(\.\d{1,2})?$)|(^(\d+)$)|(^\d\.[1-9]{1,2}$)|(^\d\.[0]{1}[1-9]{1}$|(^\d\.[1-9]{1}[0]{1}$)$)/;
    if (value) {
      if (value === "0" || value === "0.00") {
        return true;
      } else {
        if (!reg.exec(value)) {
          return g("validate.money");
        }
      }
    }
    return true;
  },
});

extend("moneyDecimal", {
  validate: (value) => {
    value = valueTrim(value);
    let decimal = storeage.getDecimalPlacesOfMoney();
    //let reg = new RegExp(`(^(0|[1-9][0-9]*)(\\.\\d{1,${decimal}})?$)`);
    let reg = new RegExp(`(^(\\d+)?(\\.\\d{1,${decimal}})?$)`);
    if (value && !reg.exec(value)) {
      let field = "0.";
      for (let i = 1; i <= decimal; i++) {
        field += "0";
      }
      return g("validate.moneyDecimal").format([field]);
    }
    return true;
  },
});

extend("moneyDecimal2", {
  validate: (value) => {
    value = valueTrim(value);
    let decimal = storeage.getDecimalPlacesOfMoney();
    // let reg = new RegExp(`(^(\\-)?(0|[1-9][0-9]*)(\\.\\d{1,${decimal}})?$)`);
    let reg = new RegExp(`(^(\\-)?(\\d+)?(\\.\\d{1,${decimal}})?$)`);
    if (value && !reg.exec(value)) {
      let field = "0.";
      for (let i = 1; i <= decimal; i++) {
        field += "0";
      }
      return g("validate.moneyDecimal").format([field]);
    }
    return true;
  },
});

extend("validateLatitude", {
  validate: (value) => {
    value = valueTrim(value);
    let reg = /(([1-9]\d*)(\.\d+)?)$|^(-0\.\d*[1-9])$/;
    if (parseFloat(value) > 90 || parseFloat(value) < - 90) {
      return g("validate.validateLatitude");
    }

    if (value && !reg.exec(value)) {
      return g("validate.validateLatitude");
    }
    return true;
  },
});

extend("validateLongitude", {
  validate: (value) => {
    value = valueTrim(value);
    let reg = /(([1-9]\d*)(\.\d+)?)$|^(-0\.\d*[1-9])$/;
    if (parseFloat(value) > 180 || parseFloat(value) < - 180) {
      return g("validate.validateLongitude");
    }

    if (value && !reg.exec(value)) {
      return g("validate.validateLongitude");
    }
    return true;
  },
});

extend("checkImageSize", {
  params: ["imageSizeRule", "imageSize"],
  validate(value, { imageSizeRule, imageSize }) {
    let { width, height } = { ...imageSize };
    let result = false;
    imageSizeRule.forEach(rule => {
      if (rule.width == width && rule.height == height) {
        result = true;
      }
    });
    if (result) {
      return true;
    } else {
      return g("validate.checkImageSize");
    }
  },
});

extend("confirmpassword", {
  params: ["target"],
  validate(value, { target }) {
    value = valueTrim(value);
    if (value === target) {
      return true;
    } else {
      return g("validate.confirmpassword");
    }
  },
});

extend("validateSpecialCharacter", {
  validate: (value) => {
    value = valueTrim(value);
    var patrn = /[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]/im;
    if (!patrn.test(value)) {
      return true;
    } else {
      return g("validate.validateSpecialCharacter");
    }
  },
});
extend("validateSpecialCharacterDetroit", {
  validate: (value) => {
    value = valueTrim(value);
    var patrn = /[`~!@#$%^&*_\\+=<>?:"{}|,./;\\[\]·~！@#￥%……&*（）——\\+={}|《》？：“”【】、；‘，。、]/im;
    if (!patrn.test(value)) {
      return true;
    } else {
      return g("validate.validateSpecialCharacterDetroit");
    }
  },
});
extend("maxMoney", {
  params: ["maxValue"],
  validate(value, { maxValue }) {
    value = valueTrim(value);
    let tempValue = Math.ceil(value);
    if (tempValue >= 0 && tempValue <= maxValue) {
      return true;
    }
    return g("validate.maxMoney");
  },
});

extend("maxFileNameLength", {
  params: ["length"],
  validate(value, { length }) {
    if (value instanceof Array) {
      for (let file of value) {
        if (file.name && file.name.length >= length) {
          return g("validate.maxFileNameLength").format(length);
        }
      }
    } else {
      if (file.name && file.name.length >= length) {
        return g("validate.maxFileNameLength").format(length);
      }
    }
    return true;
  },
});

extend("maxFileSize", {
  params: ["maxSize"],
  validate(value, { maxSize }) {
    if (value instanceof Array) {
      for (let file of value) {
        if (file.size && file.size >= maxSize) {
          return g("validate.maxFileSize");
        }
      }
    } else {
      if (value.size && value.size >= maxSize) {
        return g("validate.maxFileSize");
      }
    }
    return true;
  },
});

extend("acceptFileType", {
  params: ["fileAccept"],
  validate(value, { fileAccept }) {
    const getExtension = (fileName) => {
      let index = fileName.lastIndexOf(".");
      return fileName.substring(index + 1);
    };
    const isExtensionOk = (file) => {
      if (file.name) {
        let extension = getExtension(file.name).toLowerCase();
        let fileAcceptArray = fileAccept.split(",");
        if (fileAcceptArray.length > 0 && fileAcceptArray.findIndex(c => c.toLowerCase().trim() == `.${extension}`) < 0) {
          return false;
        }
      }
      return true;
    };
    let isOK = true;
    if (value instanceof Array) {
      for (let file of value) {
        if (!isExtensionOk(file)) {
          return g("validate.acceptFileType");
        }
      }
    } else {
      isOK = isExtensionOk(value);
    }
    if (!isOK) {
      return g("validate.acceptFileType");
    }
    return isOK;
  },
});

extend("password", {
  validate(value) {
    value = valueTrim(value);
    if (value?.length < 8) {
      return g("validate.passwordLongerThen7");
    }
    if (value.length > 256) {
      return g("validate.passwordNoLongerThen256");
    }
    /* eslint-disable */
    let regex = /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z_!@#$%^&*()-+={}[\]|\:’,.?/`~”]+$)(?![a-z0-9]+$)(?![a-z_!@#$%^&*()-+={}[\]|\:’,.?/`~”]+$)(?![0-9_!@#$%^&*()-+={}[\]|\:’,.?/`~”]+$)[a-zA-Z0-9_!@#$%^&*()-+={}[\]|\:’,.?/`~”]{8,256}$/;
    /* eslint-disable */
    if (regex.test(value)) {
      return true;
    }
    return g("validate.password");
  },
});

extend("phone", {
  validate: (value) => {
    value = valueTrim(value);
    let reg = /^([(]\d{3}[)]\d{3}-\d{4})$|^(\d{3}-\d{3}-\d{4})$/;
    if (value && !reg.exec(value)) {
      return g("validate.phone");
    }
    return true;
  },
});

extend("postcode", {
  validate: (value) => {
    value = valueTrim(value);
    let reg = regRules.postcode.reg;
    if (value && !reg.exec(value)) {
      this.message = regRules.postcode.message;
      return false;
    }
    return true;
  },
});

/**
 * StartDate->EndDate
 */
extend("validateStartDateEndDateComparison", {
  params: ["endDateValue", "equal"],
  validate(value, { endDateValue, equal = false }) {
    const INVALID_DATE = "Invalid date";
    const utc19900101 = - 2208988800000;
    if (!endDateValue || endDateValue == INVALID_DATE || endDateValue == utc19900101) {
      return true;
    }
    if (equal && value >= endDateValue) {
      return g("validate.startTimeNotLaterEndTime");
    }
    if (!equal && value > endDateValue) {
      return g("validate.startTimeNotLaterEndTime");
    }
    return true;
  },
});
/**
 * EndDate->StartDate
 */
extend("validateEndDateStartDateComparison", {
  params: ["startDateValue"],
  validate(value, { startDateValue }) {
    if (value <= startDateValue) {
      return g("validate.startTimeNotLaterEndTime");
    }
    return true;
  },
});

/**
 * if select one, and the other one must select
 */
extend("validateStartDateAndEndDateRequired", {
  params: ["endDate"],
  validate(value, { endDate }) {
    const utc19900101 = - 2208988800000;
    if (
      (value == null || value === utc19900101) &&
      endDate != null &&
      endDate !== utc19900101
    ) {
      return g("validate.required");
    } else if (
      value != null &&
      value !== utc19900101 &&
      (endDate == null || endDate === utc19900101)
    ) {
      return g("validate.required");
    } else {
      return true;
    }
  },
});


extend("validateOtherOneHasValueThisRequired", {
  params: ["otherDate"],
  validate(value, { otherDate }) {
    const utc19900101 = - 2208988800000;
    if (
      otherDate != null &&
      otherDate !== utc19900101
    ) {
      if (value == null || value === utc19900101) {
        return g("validate.required");
      }
      return true;
    }
    return true;

  },
});

/**
 * start time < end time
 */
extend("validateStartTimeAndEndTime", {
  params: ["endTimeValue"],
  validate(value, { endTimeValue }) {
    if (value != null && endTimeValue != null) {
      if (value >= endTimeValue) {
        return g("validate.startTimeNotLaterEndTime");
      }
    }
    return true;
  },
});

/**
 * two datetime picker comparison
 */
extend("validateStartDateTimeAndEndDateTimeComparison", {
  params: ["endDateValue"],
  validate(value, { endDateValue }) {
    const INVALID_DATE = -1;
    const INVALID_TIME = -2;
    const INVALID_DATE_NULL = -3;
    const INVALID_TIME_NULL = -4;
    const utc19900101 = -2208988800000;
    if (!endDateValue || endDateValue == INVALID_DATE || endDateValue == INVALID_TIME
      || endDateValue == INVALID_DATE_NULL || endDateValue == INVALID_TIME_NULL
      || endDateValue == utc19900101) {
      return true;
    }
    if (!value || value == INVALID_DATE || value == INVALID_TIME
      || value == INVALID_DATE_NULL || value == INVALID_TIME_NULL || value == utc19900101) {
      return true;
    }
    if (value >= endDateValue) {
      return g("validate.startTimeNotLaterEndTime");
    }
    return true;
  },
});

extend("validateStartDateTimeAndEndDateTimeComparisonCanEqual", {
  params: ["endDateValue"],
  validate(value, { endDateValue }) {
    const INVALID_DATE = -1;
    const INVALID_TIME = -2;
    const INVALID_DATE_NULL = -3;
    const INVALID_TIME_NULL = -4;
    const utc19900101 = -2208988800000;
    if (!endDateValue || endDateValue == INVALID_DATE || endDateValue == INVALID_TIME
      || endDateValue == INVALID_DATE_NULL || endDateValue == INVALID_TIME_NULL
      || endDateValue == utc19900101) {
      return true;
    }
    if (!value || value == INVALID_DATE || value == INVALID_TIME
      || value == INVALID_DATE_NULL || value == INVALID_TIME_NULL || value == utc19900101) {
      return true;
    }
    if (value > endDateValue) {
      return g("validate.startTimeNotLaterEndTime");
    }
    return true;
  },
});

extend("validateEndDateAndStartDateComparison", {
  params: ["startDateValue", "field1", "field2"],
  validate(value, { startDateValue, field1, field2 }) {
    const INVALID_DATE = "Invalid date";
    const utc19900101 = -2208988800000;
    if (!value || value == INVALID_DATE || value == utc19900101) {
      return true;
    }
    if (!startDateValue || startDateValue == INVALID_DATE || startDateValue == utc19900101) {
      return true;
    }

    let ss = Moment.tz(startDateValue, storeage.getTimeZone())
      .format("YYYY-MM-DD")
      .toString()
      .split("-");
    let se = Moment.tz(value, storeage.getTimeZone())
      .format("YYYY-MM-DD")
      .toString()
      .split("-");

    if (parseInt(se[0]) > parseInt(ss[0])) {
      return true;
    } else if (parseInt(se[0]) === parseInt(ss[0])) {
      if (parseInt(se[1]) > parseInt(ss[1])) {
        return true;
      } else if (parseInt(se[1]) === parseInt(ss[1])) {
        if (parseInt(se[2]) > parseInt(ss[2])) {
          return true;
        }
      }
    }
    return g("validate.endTimeBigStartTime").format(field1, field2);
  },
});

extend("validateEndDateTimeAndStartDateTimeComparison", {
  params: ["startDateValue", "startTimeValue", "endDateValue", "endTimeValue", "field1", "field2"],
  validate(value, { startDateValue, startTimeValue, endDateValue, endTimeValue, field1, field2 }) {
    const INVALID_DATE = "Invalid date";
    const utc19900101 = -2208988800000;
    if (
      startDateValue != null &&
      startDateValue !== utc19900101 &&
      startDateValue !== INVALID_DATE &&
      startTimeValue != null &&
      startTimeValue !== INVALID_DATE &&
      endDateValue != null &&
      endDateValue !== utc19900101 &&
      endDateValue !== INVALID_DATE &&
      endTimeValue != null &&
      endTimeValue !== INVALID_DATE
    ) {
      let ss = Moment.tz(startDateValue, storeage.getTimeZone())
        .format("YYYY-MM-DD")
        .toString()
        .split("-");
      let se = Moment.tz(endDateValue, storeage.getTimeZone())
        .format("YYYY-MM-DD")
        .toString()
        .split("-");

      if (parseInt(se[0]) > parseInt(ss[0])) {
        return true;
      } else if (parseInt(se[0]) === parseInt(ss[0])) {
        if (parseInt(se[1]) > parseInt(ss[1])) {
          return true;
        } else if (parseInt(se[1]) === parseInt(ss[1])) {
          if (parseInt(se[2]) > parseInt(ss[2])) {
            return true;
          } else if (parseInt(se[2]) === parseInt(ss[2])) {
            let tss = Moment.tz(startTimeValue, storeage.getTimeZone())
              .format("H:mm")
              .toString()
              .split(":");
            let tse = Moment.tz(endTimeValue, storeage.getTimeZone())
              .format("H:mm")
              .toString()
              .split(":");
            if (parseInt(tss[0]) < parseInt(tse[0])) {
              return true;
            } else if (parseInt(tss[0]) === parseInt(tse[0])) {
              if (parseInt(tss[1]) < parseInt(tse[1])) {
                return true;
              }
            }
          }
        }
      }
      return g("validate.endTimeBigStartTime").format(field1, field2);
    }
    return true;
  },
});


extend("validateStartDateTimeEndDateTimeComparison", {
  params: ["startTimeValue", "endDateValue", "endTimeValue"],
  validate(value, { startTimeValue, endDateValue, endTimeValue }) {
    const INVALID_DATE = "Invalid date";
    const utc19900101 = -2208988800000;
    if (
      value != null &&
      value !== utc19900101 &&
      value !== INVALID_DATE &&
      startTimeValue != null &&
      startTimeValue !== INVALID_DATE &&
      endDateValue != null &&
      endDateValue !== utc19900101 &&
      endDateValue !== INVALID_DATE &&
      endTimeValue != null &&
      endTimeValue !== INVALID_DATE
    ) {
      if (value > endDateValue) {
        return g("validate.startTimeNotLaterEndTime");
      } else if (value === endDateValue) {
        var ms = Moment.tz(startTimeValue, storeage.getTimeZone());
        let ss = ms
          .format("H:mm")
          .toString()
          .split(":");
        var me = Moment.tz(endTimeValue, storeage.getTimeZone());
        let se = me
          .format("H:mm")
          .toString()
          .split(":");
        if (parseInt(ss[0]) < parseInt(se[0])) {
          return true;
        } else if (parseInt(ss[0]) === parseInt(se[0])) {
          if (parseInt(ss[1]) < parseInt(se[1])) {
            return true;
          }
        }
        return g("validate.startTimeNotLaterEndTime");
      }
    }
    return true;
  },
});

/**
 * timepicker
 */
extend("validateTimeFormat", {
  params: [],
  validate(value) {
    const INVALID_DATE = "Invalid date";
    if (value === INVALID_DATE) {
      return g("validate.invalidformat");
    }
    return true;
  },
});
/**
 * datepicker and timepicker
 */
extend("validateDateAndTimeFormat", {
  params: ["timeValue"],
  validate(value, { timeValue }) {
    value = valueTrim(value);
    const INVALID_DATE = -1;
    const INVALID_TIME = -2;
    if (value === INVALID_DATE || value == "Invalid date") {
      return g("validate.invalidformat") + `(${storeage.getDateFormat()})`;
    }
    if (timeValue === INVALID_TIME || timeValue == INVALID_DATE) {
      return g("validate.invalidformat");
    }
    return true;
  },
});

extend("passiveMax", {
  params: ["length"],
  validate(value, { length }) {
    if (!value) {
      value = "";
    }
    value = valueTrim(value);
    if (value.length > length) {
      return g("validate.passiveMax").format(length);
    }
    return true;
  },
});
/**
 * datepicker
 */
extend("validateDateFormat", {
  validate(value) {
    value = valueTrim(value);
    const INVALID_DATE = -1;
    const INVALID_TIME = -2;
    if (value === INVALID_DATE || value == "Invalid date") {
      return g("validate.invalidformat") + `(${storeage.getDateFormat()})`;
    }
    if (value === INVALID_TIME) {
      return g("validate.invalidformat");
    }
    return true;
  },
});

/**
 * datetimepicker
 */
extend("validateDateTimeFormat", {
  validate(value) {
    value = valueTrim(value);
    const INVALID_DATE = -1;
    const INVALID_TIME = -2;
    const INVALID_DATE_NULL = -3;
    const INVALID_TIME_NULL = -4;
    if (value === INVALID_DATE || value == "Invalid date") {
      return g("validate.invalidformat") + `(${storeage.getDateFormat()})`;
    }
    if (value === INVALID_TIME) {
      return g("validate.invalidformat");
    }
    if (value === INVALID_DATE_NULL || value === INVALID_TIME_NULL) {
      return g("validate.invalidformat");
    }
    return true;
  },
});

extend("validateDateTimeIsRequired", {
  params: ["timeValue"],
  validate(value, { timeValue }) {
    const utc19900101 = -2208988800000;
    if (value == null || value == undefined || value == "" || value <= utc19900101) {
      return g("validate.required");
    }
    if (timeValue == null || timeValue == undefined || timeValue == "" || timeValue <= utc19900101) {
      return g("validate.required");
    }
    return true;
  },
});
extend("validateDateIsRequired", {
  validate(value) {
    const utc19900101 = -2208988800000;
    if (value == null || value == undefined || value == "" || value <= utc19900101) {
      return g("validate.required");
    }
    return true;
  },
});
extend("validateDatetimeBigNow", {
  validate(value) {
    const utc19900101 = -2208988800000;
    if (value == utc19900101) {
      return true;
    }
    let now = Moment.tz(new Date(), storeage.getTimeZone()).valueOf();
    if (value <= now) {
      return g("validate.validateDatetimeBigNow");
    }
    return true;
  },
});

extend("validateDateBigEqualNow", {
  validate(value) {
    const utc19900101 = -2208988800000;
    if (value == utc19900101) {
      return true;
    }
    let dateStr = Moment.tz(new Date(), storeage.getTimeZone()).format("YYYY-MM-DD");
    let now = Moment.tz(
      dateStr,
      "YYYY-MM-DD",
      storeage.getTimeZone(),
    ).valueOf();
    if (value < now) {
      return g("validate.validateDatetimeBigNow");
    }
    return true;
  },
});

extend("validateDateAndTimeBigNow", {
  params: ["timeValue"],
  validate(value, { timeValue }) {
    const utc19900101 = -2208988800000;
    if (value == utc19900101 || !timeValue) {
      return true;
    }
    let nowDate = Moment.tz(new Date(), storeage.getTimeZone());
    let tempDate = Moment.tz(value, storeage.getTimeZone());
    let nowDateArray = nowDate.format("YYYY-M-D").toString().split("-");
    let tempDateArray = tempDate.format("YYYY-M-D").toString().split("-");

    if (parseInt(tempDateArray[0]) < parseInt(nowDateArray[0])) {
      return g("validate.validateDatetimeBigNow");
    } else if (parseInt(tempDateArray[0]) == parseInt(nowDateArray[0])) {
      if (parseInt(tempDateArray[1]) < parseInt(nowDateArray[1])) {
        return g("validate.validateDatetimeBigNow");
      } else if (parseInt(tempDateArray[1]) == parseInt(nowDateArray[1])) {
        if (parseInt(tempDateArray[2]) < parseInt(nowDateArray[2])) {
          return g("validate.validateDatetimeBigNow");
        } else if (parseInt(tempDateArray[2]) == parseInt(nowDateArray[2])) {
          let ms = Moment.tz(new Date(), storeage.getTimeZone());
          let ss = ms
            .format("H:mm")
            .toString()
            .split(":");
          let me = Moment.tz(timeValue, storeage.getTimeZone());
          let se = me
            .format("H:mm")
            .toString()
            .split(":");

          if (parseInt(se[0]) < parseInt(ss[0])) {
            return g("validate.validateDatetimeBigNow");
          } else if (parseInt(se[0]) === parseInt(ss[0])) {
            if (parseInt(se[1]) < parseInt(ss[1]) || parseInt(se[1]) == parseInt(ss[1])) {
              return g("validate.validateDatetimeBigNow");
            }
          }
        }
      }
    }
    return true;
  },
});

extend("validateDateTimeSelect", {
  params: ["timeValue"],
  validate(value, { timeValue }) {
    const INVALID_DATE = "Invalid date";
    if (value === INVALID_DATE) {
      return `${g("validate.invalidformat")} (${storeage.getDateFormat()})`;
    }
    if (timeValue === INVALID_DATE) {
      return g("validate.invalidformat");
    }
    const utc19900101 = -2208988800000;
    if (((value == null || value === utc19900101) && timeValue != null) ||
      (value != null && value !== utc19900101 && timeValue == null)) {
      return g("validate.invalidformat");
    }
    return true;
  },
});
extend("validateStartAndEndDateCanNull", {
  params: ["endDate"],
  validate(value, { endDate }) {
    const utc19900101 = -2208988800000;
    if (
      value != null &&
      value !== utc19900101 &&
      endDate != null &&
      endDate !== utc19900101 &&
      endDate !== 0
    ) {
      if (value > endDate) {
        return g("validate.startDateNotLaterEndDate");
      }
    }
    return true;
  },
});

extend("requireWithZero", {
  validate(value) {
    value = valueTrim(value);
    if (!(value === -2208988800000 || value === 0 || value === "0" || value === "00000000-0000-0000-0000-000000000000" || value == null || value == "")) {
      return true;
    }
    return g("validate.required");
  },
});


extend("validataFromNumAndToNum", {
  params: ["toNum", "field1", "field2"],
  validate(value, { toNum, field1, field2 }) {
    value = valueTrim(value);
    var iFromNum = parseInt(value);
    var iToNum = parseInt(toNum);
    if (iFromNum < 0) {
      return "Invalid Number";
    }
    if (iFromNum > iToNum) {
      return g("validate.validataFromNumAndToNum").format(field1, field2);
    }

    return true;
  },
});
extend("validataToNumAndFromNum", {
  params: ["toNum", "field1", "field2"],
  validate(value, { toNum, field1, field2 }) {
    value = valueTrim(value);
    var iToNum = parseInt(toNum);
    var iFromNum = parseInt(value);
    if (iToNum < 0) {
      return "Invalid Number";
    }
    if (iToNum < iFromNum) {
      return g("validate.validataToNumAndFromNum").format(field1, field2);
    }

    return true;
  },
});
extend("validataNoOverlap", {
  params: ["toNum", "field"],
  validate(value, { toNum, field }) {
    value = valueTrim(value);
    var iToNum = parseInt(toNum);
    var iFromNum = parseInt(value);
    if (iFromNum === iToNum) {
      return g("validate.validataNoOverlap").format(field);
    }

    return true;
  },
});
extend("numberBetween", {
  params: ["minValue", "maxValue"],
  validate(value, { minValue, maxValue }) {
    if (minValue == null && maxValue == null)
      return true;
    value = valueTrim(value);
    let ex = /^(0|[1-9][0-9]*|-[1-9][0-9]*)$/;
    if (ex.test(value) && parseInt(value) >= parseInt(minValue) && parseInt(value) <= parseInt(maxValue)) {
      return true;
    }
    return g("validate.numberBetween").format(minValue, maxValue);
  },
});

extend("numberMultiple", {
  params: ["minValue", "maxValue"],
  validate(value, { minValue, maxValue }) {
    if (minValue == null && maxValue == null)
      return true;
    let regex = /^-?\d+$/;
    let ex = /^(0|[1-9][0-9]*|-[1-9][0-9]*)$/;
    value = valueTrim(value);
    let numbers = value.split(";");
    for (let i = 0; i < numbers.length; i++) {
      if (!regex.test(numbers[i])) {
        return g("validate.number");
      }
      if (!(ex.test(numbers[i]) && parseInt(numbers[i]) >= parseInt(minValue) && parseInt(numbers[i]) <= parseInt(maxValue))) {
        return g("validate.numberBetween").format(minValue, maxValue);
      }
    }
    return true;
  },
});

extend("numberPositive", {
  validate(value) {
    value = valueTrim(value);
    let ex = /^0$|(^[1-9]\d*$)/;
    if (ex.test(value)) {
      return true;
    }
    return g("validate.numberPositive");
  },
});
extend("numberInteger", {
  params: ["valid"],
  validate(value) {
    if (value == "0")
      return true;
    value = valueTrim(value);
    let ex = /^[-]?[1-9]\d*$/;
    if (ex.test(value)) {
      return true;
    } else {
      return g("validate.numberPositive");
    }
  },
});

extend("numberListBetween", {
  params: ["rangs"],
  validate(value, { rangs }) {
    value = valueTrim(value);
    let ex = /^[-]?[0-9]\d*$/;
    if (ex.test(value)) {
      let intvalue = parseInt(value);
      let result = false;
      rangs.forEach((el) => {
        if (intvalue >= el.numFrom && intvalue <= el.numTo) {
          result = true;
        }
      });
      if (result)
        return result;
    }
    var rangstr = "";
    rangs.forEach((el) => {
      if (rangstr != "") {
        rangstr += ", ";
      }
      rangstr += el.numFrom + "-" + el.numTo;
    });
    return g("validate.numberListBetween").format(rangstr);
  },
});

extend("hasOrderUsed", {
  params: ["orderList"],
  validate(value, { orderList }) {
    value = valueTrim(value);
    if (orderList) {
      let array = orderList.filter(item => (item.order ? item.order : item.sortIndex) == value);
      if (array.length > 1) {
        return g("validate.hasOrderUsed");
      }
    }
    return true;
  },
});

extend("hasFieldUsed", {
  params: ["fieldList", "field"],
  validate(value, { fieldList, field }) {
    value = valueTrim(value);
    if (fieldList) {
      let array = fieldList.filter(item => item == value);
      if (array.length > 1) {
        return g("validate.hasFieldUsed").format(field);
      }
    }
    return true;
  },
});
const hasFieldUsedKeyKeyType = (keyType, key, item, value) => {
  if (keyType == Number) {
    return Number(item[key]) == Number(value);
  } else {
    return item[key] == value;
  }
};
extend("hasFieldUsedKey", {
  params: ["key", "fieldList", "field", "keyType"],
  validate(value, { key, fieldList, field, keyType }) {
    value = valueTrim(value);
    if (fieldList) {
      let array = fieldList.filter(item => hasFieldUsedKeyKeyType(keyType, key, item, value));
      if (array.length > 1) {
        return g("validate.hasFieldUsed").format(field);
      }
    }
    return true;
  },
});

extend("hasFieldOnlyUsedKey", {
  params: ["key", "fieldList", "field", "keyType"],
  validate(value, { key, fieldList, field, keyType }) {
    value = valueTrim(value);
    if (fieldList) {
      let array = fieldList.filter(item => hasFieldUsedKeyKeyType(keyType, key, item, value));
      if (array.length > 0) {
        return g("validate.hasFieldUsed").format(field);
      }
    }
    return true;
  },
});

extend("hasFieldUsedKeyArrayUnion", {
  params: ["key", "fieldList", "field", "item"],
  validate(value, { key, fieldList, field, item }) {
    value = valueTrim(value);
    let array = [];
    if (fieldList) {
      for (const e of key) {
        array = fieldList.filter(i => i[e] == value && i != item);
        if (array.length > 0) {
          return g("validate.hasFieldUsed").format(field);
        }
      }
    }
    return true;
  },
});
/**
 * key:Array
 * item:{key: '', value: }
 */
extend("hasFieldUsedKeyArrayDifference", {
  params: ["key", "fieldList", "field", "item"],
  validate(value, { key, fieldList, field, item }) {
    value = valueTrim(value);
    let array = [];
    if (fieldList) {
      array = fieldList.findIndex(i => i[getObjectKey(i, key)].toLowerCase() == value.toLowerCase() && i[item.key].toLowerCase() != item.value.toLowerCase());
      if (array >= 0) {
        return g("validate.hasFieldUsed").format(field);
      }
    }
    return true;
  },
});
const getObjectKey = (item, key) => {
  if (item[key[0]]) {
    return key[0];
  }
  for (let e of key) {
    if (item[e]) {
      return e;
    }
  }
  return key[0];
};

extend("validateVotesNum", {
  params: ["minValue", "maxValue"],
  validate(value, { minValue, maxValue }) {
    value = valueTrim(value);
    let num = value.replace(/,/g, "");
    let ex = /^[0-9]\d*$/;
    if (ex.test(num) && num >= minValue && num <= maxValue) {
      return true;
    } else if (value === "") {
      return g("validate.required");
    } else {
      return g("validate.numberBetween").format(minValue, maxValue);
    }
  },
});

extend("validateTwoTimeIsRequired", {
  params: ["startTimeValue", "endTimeValue"],
  validate(value, { startTimeValue, endTimeValue }) {
    if (startTimeValue && endTimeValue) {
      if (startTimeValue >= endTimeValue) {
        return g("validate.startTimeNotLaterEndTime");
      } else {
        return true;
      }
    } else {
      return g("validate.required");
    }
  },
});


extend("validateTwoTimeIsReactive", {
  params: ["startTimeValue", "endTimeValue", "reactive"],
  validate(value, { startTimeValue, endTimeValue, reactive }) {
    if (reactive) {
      if (startTimeValue && endTimeValue) {
        if (startTimeValue >= endTimeValue) {
          return g("validate.startTimeNotLaterEndTime");
        } else {
          return true;
        }
      } else {
        return g("validate.required");
      }
    } else {
      return true;
    }
  },
});

extend("validateTwoDateIsSame", {
  params: ["startDate", "endDate"],
  validate(value, { startDate, endDate }) {
    startDate = Moment.tz(startDate, storeage.getTimeZone())
      .format("YYYY-MM-DD")
      .split(" ")[0];
    endDate = Moment.tz(endDate, storeage.getTimeZone())
      .format("YYYY-MM-DD")
      .split(" ")[0];
    if (startDate && endDate) {
      if (startDate == endDate) {
        return true;
      } else {
        return g("validate.startDateAndEndDateDifferent");
      }
    } else {
      return g("validate.required");
    }
  },
});

extend("validatestartDateTimeNotLaterEndDateTime", {
  params: ["startDateValue", "startTimeValue", "endDateValue", "endTimeValue"],
  validate(value, { startDateValue, startTimeValue, endDateValue, endTimeValue }) {
    const INVALID_DATE = "Invalid date";
    const utc19900101 = -2208988800000;
    if (
      startDateValue != null &&
      startDateValue !== utc19900101 &&
      startDateValue !== INVALID_DATE &&
      startTimeValue != null &&
      startTimeValue !== INVALID_DATE &&
      endDateValue != null &&
      endDateValue !== utc19900101 &&
      endDateValue !== INVALID_DATE &&
      endTimeValue != null &&
      endTimeValue !== INVALID_DATE
    ) {
      if (startDateValue > endDateValue) {
        return g("validate.startTimeNotLaterEndTime");
      } else if (startDateValue === endDateValue) {
        var ms = Moment.tz(startTimeValue, storeage.getTimeZone());
        let ss = ms
          .format("H:mm")
          .toString()
          .split(":");
        var me = Moment.tz(endTimeValue, storeage.getTimeZone());
        let se = me
          .format("H:mm")
          .toString()
          .split(":");
        if (parseInt(ss[0]) < parseInt(se[0])) {
          return true;
        } else if (parseInt(ss[0]) === parseInt(se[0])) {
          if (parseInt(ss[1]) < parseInt(se[1])) {
            return true;
          }
        }
        return g("validate.startTimeNotLaterEndTime");
      }
    }
    return true;
  },
});

extend("validatestartTimeNotLaterEndTime", {
  params: ["startTimeValue", "endTimeValue"],
  validate(value, { startTimeValue, endTimeValue }) {
    const utc19900101 = -2208988800000;
    if ((startTimeValue && endTimeValue) && startTimeValue != utc19900101 && endTimeValue != utc19900101) {
      if (startTimeValue >= endTimeValue) {
        return g("validate.startTimeNotLaterEndTime");
      } else {
        return true;
      }
    }
    return true;
  },
});

extend("nameFormatValide", {
  validate(value) {
    value = valueTrim(value);
    if (value) {
      let regex = /^[a-zA-Z0-9@'.\-_!#^~]{0,256}$/g;
      if (!regex.test(value)) {
        return g("checkUserName");
      }
      return true;
    }
    return true;
  },
});

extend("float", {
  params: ["DecimalPlacesMin"],
  validate(value, { DecimalPlacesMin = 1, DecimalPlacesMax = 2 }) {
    value = valueTrim(value);
    if (value) {
      let regex = new RegExp(`^([0-9]*)(\\.[0-9]{${DecimalPlacesMin},${DecimalPlacesMax}})?$`);
      if (!regex.test(value)) {
        return g("validate.float");
      }
      return true;
    }
    return true;
  },
});

extend("floatIgnorePositiveNegative", {
  params: ["DecimalPlacesMin"],
  validate(value, { DecimalPlacesMin = 1, DecimalPlacesMax = 2 }) {
    value = valueTrim(value);
    if (value) {
      if (value[0] == "-") {
        value = value.slice(1)
      }
      let regex = new RegExp(`^([0-9]*)(\\.[0-9]{${DecimalPlacesMin},${DecimalPlacesMax}})?$`);
      if (!regex.test(value)) {
        return g("validate.float");
      }
      return true;
    }
    return true;
  },
});

extend("floatThree", {
  params: ["DecimalPlacesMin"],
  validate(value, { DecimalPlacesMin = 1, DecimalPlacesMax = 3 }) {
    value = valueTrim(value);
    if (value) {
      let regex = new RegExp(`^([0-9]*)(\\.[0-9]{${DecimalPlacesMin},${DecimalPlacesMax}})?$`);
      if (!regex.test(value)) {
        return g("validate.float-0.000");
      }
      return true;
    }
    return true;
  },
});
extend("floatBetween", {
  params: ["minValue", "maxValue"],
  validate(value, { minValue, maxValue }) {
    value = valueTrim(value);
    if (value >= minValue && value <= maxValue) {
      return true;
    }
    return g("validate.floatBetween").format(minValue, maxValue);
  },
});
extend("radioRequire", {
  validate(value) {
    if (!value) {
      return g("validate.required");
    }
    return true;
  },
});

extend("numAisLessThanNumB", {
  params: ["num"],
  validate(value, { num }) {
    if (parseInt(value) > parseInt(num)) {
      return g("validate.numAGreaterThanNumB");
    }
    return true;
  },
});
extend("numAGreaterThanNumB", {
  params: ["num", "msg"],
  validate(value, { num, msg }) {
    if (parseInt(value) < parseInt(num)) {
      return msg ?? g("validate.numAisLessThanNumB");
    }
    return true;
  },
});

extend("hoursMinutesCannotBoth0", {
  params: ["num"],
  validate(value, { num }) {
    if (value && num && value == 0 && num == 0) {
      return g("validate.hoursMinutesCannotBoth0");
    }
    return true;
  },
});

extend("floatingNumber", {
  params: ["DecimalPlacesMin", "isVerify"],
  validate(value, { DecimalPlacesMin = 1, DecimalPlacesMax = 2, isVerify }) {
    value = valueTrim(value);
    if (!isVerify) {
      return true;
    }
    if (value) {
      let regex = new RegExp(`^-?[0-9]+(\\.[0-9]{${DecimalPlacesMin},${DecimalPlacesMax}})?$`);
      if (!regex.test(value)) {
        return g("validate.float");
      }
      return true;
    }
    return true;
  },
});
extend("number", {
  validate(value) {
    value = valueTrim(value);
    if (value) {
      let regex = /^-?\d+$/;
      if (!regex.test(value)) {
        return g("validate.number");
      }
      return true;
    }
    return true;
  },
});
extend("url", {
  validate(value) {
    value = valueTrim(value);
    if (value) {
      let Expression = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/;
      let objExp = new RegExp(Expression);
      if (!objExp.test(value)) {
        return g("validate.url");
      }
      return true;
    }
    return true;
  },
});

extend("dob", {
  validate(value) {
    let TayDate = Moment.tz(new Date(), storeage.getTimeZone()).valueOf();
    value = valueTrim(value);
    if (value) {
      if (value > TayDate) {
        return g("validate.birthDayError");
      }
      return true;
    }
    return true;
  },
});

extend("birthDay", {
  validate(value) {
    let TayDate = Moment.tz(new Date(), storeage.getTimeZone()).valueOf();
    value = valueTrim(value);
    if (value) {
      if (value > TayDate) {
        return g("validate.birthDayError");
      }
      return true;
    }
    return true;
  },
});
extend("fieldRequired", {
  validate(value) {
    value = valueTrim(value);
    if (!value || value === "00000000-0000-0000-0000-000000000000") {
      return g("validate.required");
    }
    return true;
  },
});

extend("hasParamsNumber", {
  params: ["isNeed", "errorMsg"],
  validate(value, { isNeed, errorMsg }) {
    if (isNeed) {
      if (value) {
        let regex = /^-?\d+$/;
        if (!regex.test(value)) {
          return errorMsg;
        }
        return true;
      }
      return true;
    }
    return true;
  },
});

extend("checkReg", {
  async validate(value) {
    value = valueTrim(value);
    let regVal = "/" + value + "/";
    if (value) {
      if (!isReg(regVal)) {
        return g("validate.regular-error");
      }
      return true;
    }
    return true;
  },
});

extend("userName", {
  async validate(value) {
    value = valueTrim(value);
    if(value.indexOf(" ")>=0)
      return g("checkUserName");

    let regex = localStorage.getItem("userNameRex");
    if (value) {
      let objExp = new RegExp(regex);
      if (!objExp.test(value)) {
        return g("validate.userNameError");
      }
      return true;
    }
    return true;
  },
});;

extend("password", {
  async validate(value) {
    value = valueTrim(value);
    let regex = localStorage.getItem("passwordRegex");
    if (value) {
      let objExp = new RegExp(regex);
      if (!objExp.test(value)) {
        return g("validate.passwordError");
      }
      return true;
    }
    return true;
  },
});

extend("signatureIsEmpty", {
  validate(value) {
    if (value) {
      return g("validate.signatureIsEmpty");
    }
    return true;
  },
});

// Start time and end time interval
extend("validateStartDateEndDateInterval", {
  params: ["interval", "startDateValue", "endDateValue"],
  validate(value, { interval = 1, startDateValue, endDateValue }) {
    value = valueTrim(value);
    const INVALID_DATE = "Invalid date";
    const intervalData = interval * 365 * 24 * 60 * 60 * 1000;
    const utc19900101 = -2208988800000;
    if (startDateValue == INVALID_DATE || startDateValue == utc19900101) {
      return true;
    }
    if (endDateValue == INVALID_DATE || endDateValue == utc19900101) {
      return true;
    }
    if (startDateValue && ((value - startDateValue) > intervalData)) {
      return g("validate.startTimeAndLaterEndTimeInterval").format(interval);
    }
    if (endDateValue && ((endDateValue - value) > intervalData)) {
      return g("validate.startTimeAndLaterEndTimeInterval").format(interval);
    }
    return true;
  },
});
extend("multiSelectMaxLength", {
  validate(value, maxLength) {
    let length = value.length;
    if (length > maxLength[0]) {
      return g("validate.multiSelectMaxLength").format(maxLength[0]);
    }
    return true;
  },
});

extend("multiSelectNowSelectMaxLength", {
  validate(value, maxLength) {
    if (value) {
      let length = value.length;
      let middle = parseInt(length / 2) + 1;
      let existTotal = 0;
      for (let index = 0; index < middle; index++) {
        if (value[index].length > maxLength[0] || (value[index + middle] && value[index + middle].length > maxLength[0])) {
          existTotal += 1;
          break;
        }
      }
      if (existTotal > 0) {
        return g("validate.multiSelectNowSelectMaxLength").format(maxLength[0]);
      }
    }
    return true;
  },
});

extend("numberAndSign", {
  params: ["otherStr", "negativeNumber", "zero"],
  validate(value, { otherStr = ",", negativeNumber = true, zero = true }) {
    if (value) {
      value = valueTrim(value);
      let patrn = new RegExp(`^(?:0*[1-9]|${negativeNumber ? "-?" : ""}[${zero ? "0" : "1"}-9]+(?:${otherStr}(?!$)|$))+$`);
      if (!patrn.test(value)) {
        return g("validate.numberAndSign");
      }
    }
    return true;
  },
});

extend("beforeDaysTextNumberAndSign", {
  params: ["otherStr", "negativeNumber", "zero"],
  validate(value, { otherStr = ",", negativeNumber = true, zero = true }) {
    if (value) {
      value = valueTrim(value);
      let isValid = true;
      let reg = /^(\d+;)*\d+$/;
      if (!reg.test(value)) {
        isValid = false;
      }
      let numReg = /^[1-9]\d*$/;
      let numArr = value.split(otherStr);
      numArr.forEach((num) => {
        if (!numReg.test(num)) {
          isValid = false;
        }
      });
      if (!isValid) {
        return g("validate.numberAndSign");
      }
    }
    return true;
  },
});

extend("ssn", {
  params: ["firstBanNumber", "firstBanNumberBetween", "middleBanNumber", "middleNumberBetween", "lastBanNumber", "lastnNumberBetween"],
  validate(value, {
    firstBanNumber,
    firstBanNumberBetween,
    middleBanNumber,
    middleNumberBetween,
    lastBanNumber,
    lastnNumberBetween,
  }) {
    if (value) {
      let v = value.trim().replaceAll(" ", "").replaceAll("-", "");
      let firstStr = v.slice(0, 3);
      let middleStr = v.slice(3, 5);
      let lastStr = v.slice(5);
      let regex = /^[0-9]*$/;
      if (!regex.test(firstStr) || !regex.test(middleStr) || !regex.test(lastStr)) {
        return g("validate.ssnNotNumber");
      }
      if (firstBanNumber?.indexOf(firstStr) >= 0) {
        return validateSsnBanNumber(1, { firstBanNumber: firstBanNumber });
      }
      if (middleBanNumber?.indexOf(middleStr) >= 0) {
        return validateSsnBanNumber(2, { middleBanNumber: middleBanNumber });
      }
      if (lastBanNumber?.indexOf(lastStr) >= 0) {
        return validateSsnBanNumber(3, { lastBanNumber: lastBanNumber });
      }
      return validateSsnBetween(firstStr, firstBanNumberBetween, middleStr, middleNumberBetween, lastStr, lastnNumberBetween);
    }
    return true;
  },
});
const validateSsnBanNumber = (v, banNumber = { firstBanNumber: [], middleBanNumber: [], lastBanNumber: [] }) => {
  let message = "";
  switch (v) {
    case 1://first
      console.log(1);
      message = g("validate.ssnFirstBanNumber").format(banNumber.firstBanNumber.join());
      break;
    case 2://middle
      message = g("validate.ssnMiddleBanNumber").format(banNumber.middleBanNumber.join());
      break;
    case 3://last
      message = g("validate.ssnLastBanNumber").format(banNumber.lastBanNumber.join());
      break;
    default:
      break;
  }
  return message;
};
const validateSsnBetween = (firstStr, firstBanNumberBetween, middleStr, middleNumberBetween, lastStr, lastnNumberBetween) => {
  if (firstBanNumberBetween && parseInt(firstStr) >= firstBanNumberBetween[0] && parseInt(firstStr) <= firstBanNumberBetween[1]) {
    return g("validate.ssnFirstBanNumberBetween").format(firstBanNumberBetween[0], firstBanNumberBetween[1]);
  }
  if (middleNumberBetween && (parseInt(middleStr) <= middleNumberBetween[0] || parseInt(middleStr) >= middleNumberBetween[1])) {
    return g("validate.ssnMiddleNumberBetween").format([middleNumberBetween[0], middleNumberBetween[1]]);
  }
  if (lastnNumberBetween && (parseInt(lastStr) <= lastnNumberBetween[0] || parseInt(lastStr) >= lastnNumberBetween[1])) {
    return g("validate.ssnLastNumberBetween").format([lastnNumberBetween[0], lastnNumberBetween[1]]);
  }
  return true;
};
extend("ssnLength", {
  validate(value, length) {
    if (value && value.replaceAll("-", "").length != length) {
      return g("validate.ssnLength");
    }
    return true;
  },
});

extend("ssnLength_4", {
  params: ["numberBetween"],
  validate(value, { numberBetween }) {
    let regex = /^[0-9]*$/;
    if (value && !regex.test(value)) {
      return g("validate.ssnNotNumber");
    }
    if (value && value.length != 4) {
      return g("validate.ssnLength_4");
    }
    if (value && (parseInt(value) <= numberBetween[0] || parseInt(value) >= numberBetween[1])) {
      return g("validate.ssnNumberBetween_4");
    }
    return true;
  },
});

extend("validateResultReportingId", {
  params: ["length"],
  validate(value, { length }) {
    value = valueTrim(value);
    let reg = /^[a-zA-Z0-9]{4}$/;
    if (length == 3) {
      reg = /^[a-zA-Z0-9]{3}$/;
    }
    if (value && !reg.exec(value)) {
      return g("validate.validateResultReportingId").format(length);
    }
    return true;
  },
});

extend("checkBoxRequired", {
  validate(value) {
    if (value == null || value == false) {
      return g("validate.required");
    }
    return true;
  },
});
