const routes = [
    {
        name: 'outstanding-reports',
        path: '/',
        meta: { title: 'outstanding-reports', requiresAuth: false },
        component: () => import('@/views/const/space'),
        route: true,
    },

]

export default [
    {
        path: '/outstanding-reports',
        route: false,
        component: () => import('@/layout/brief'),
        children: [...routes],
    },
]