<template>
  <div class="content-page">
    <election-select v-if="isEvent" />
    <KValidationObserver
      ref="observer"
      v-slot="valdata"
      class="content-page"
    >
      <k-form
        ref="form"
        @reset="onReset"
        class="content-form"
        @submit.prevent="onSubmit"
      >
        <div class="nav-btn-group">
          <k-button-group>
            <slot
              name="formBar"
              v-bind="valdata"
            />
          </k-button-group>
          <KWikiHelp
            :props-wiki-help-code="propsWikiHelpCode"
            :props-has-wiki-help="!isNotification"
          />
        </div>
        <div class="content-main">
          <k-alert-msg ref="lblMsg" />
          <div class="content-navTab">
            <slot name="formBarNav" />
          </div>
          <div class="content-party pt-3">
            <div class="content-group">
              <k-form-group
                label-class="require-mark"
                label-suffix="category"
                label-for="Category"
                label-align-md="right"
                label-cols-md="3"
                content-cols-lg="6"
                content-cols-md="7"
              >
                <KValidationProvider
                  :rules="{ required: true }"
                  v-slot="{ errors }"
                  slim
                  name="category"
                >
                  <k-alert
                    :show="errors.length > 0"
                    variant="warning"
                  >
                    {{ errors[0] }}
                  </k-alert>
                  <k-form-select
                    v-model="form.category"
                    :options="categories"
                    :plaintext="isPlainText"
                  />
                </KValidationProvider>
              </k-form-group>
              <k-form-group
                label-class="require-mark"
                label-suffix="fileTitle"
                label-for="Subject"
                label-align-md="right"
                label-cols-md="3"
                content-cols-lg="6"
                content-cols-md="7"
              >
                <KValidationProvider
                  :rules="{
                    required: true,
                    max: 200,
                    validateSpecialCharacter: true,
                    remote: isSubjectExits,
                  }"
                  v-slot="{ errors }"
                  slim
                  name="fileTitle"
                >
                  <k-alert
                    :show="errors.length > 0"
                    variant="warning"
                  >
                    {{ errors[0] }}
                  </k-alert>
                  <k-form-input
                    v-model="form.subject"
                    :plaintext="isPlainText"
                  />
                </KValidationProvider>
              </k-form-group>
              <k-form-group
                label-class="require-mark"
                label-suffix="file"
                label-align-md="right"
                label-cols-md="3"
                content-cols-lg="6"
                content-cols-md="7"
              >
                <KValidationProvider
                  :rules="{
                    required: true,
                    acceptFileType: accept,
                    maxFileSize: size * 1024,
                  }"
                  v-slot="{ errors }"
                  slim
                  name="file"
                >
                  <k-alert
                    :show="errors.length > 0"
                    variant="warning"
                  >
                    {{ errors[0] }}
                  </k-alert>
                  <common-upload
                    ref="fileinput"
                    v-model="form.file"
                    :accept="accept"
                    :size="size"
                    :is-multiple="false"
                    :on-delete="onDelete"
                    :placeholder="placeholder"
                    :show-message="false"
                    :key="uploadKey"
                    :is-use-internal-validate="false"
                    :plaintext="isPlainText"
                  />
                </KValidationProvider>
              </k-form-group>
            </div>
          </div>
        </div>
      </k-form>
    </KValidationObserver>
  </div>
</template>

<script>
import { repResult } from "../../../libs/global";
import {
  getTemplate,
  addTemplate,
  putTemplate,
  getCategories,
  checkSubjectExits,
} from "../api/messageTemplate";
import { originDataChangeMixin } from "../../../utils/mixins/mixin";
import { getUploadConfig } from "../../../api/upload.js";
import storeage from "../../../utils/storeage";

export default {
  mixins: [originDataChangeMixin],
  props: {
    id: {
      type: String,
      default(){
        return "";
      },
    },
    isPlainText: {
      type: Boolean,
      default(){
        return false;
      },
    },
    isEvent: {
      type: Boolean,
      default(){
        return false;
      },
    },
    isNotification: {
      type: Boolean,
      default(){
        return false;
      },
    },
    propsWikiHelpCode: {
      type: String,
      default: null,
    },
  },
  data(){
    return {
      form: {
        electionId: this.isEvent ? storeage.getElectionId() : "",
        subject: "",
        content: "",
        file: [],
      },
      labels: [
        { value: "$FullData$", checked: false },
        { value: "$Data$", checked: false },
        { value: "$AbbDataWeek$", checked: false },
        { value: "$Date(th)$", checked: false },
      ],
      docId: this.$route.query.docId,
      categories: [],
      templateType: this.$route.query.templateType,
      accept: "",
      size: 0,
      uploadKey: 0,
      placeholder: this.$g("noFileChosen"),
    };
  },
  computed: {
    contentLength(){
      if(this.form.content){
        return this.form.content.length;
      }
      return 0;
    },
  },
  mounted(){
    this.bindCategories();
    this.getData();

    getUploadConfig("messagedoc").then((data) => {
      this.accept = data.data.acceptTypes;
      this.size = data.data.maxSize / 1024;
    });
  },
  methods: {
    bindCategories(){
      let data = {
        enumTemplateType: this.templateType,
        electionId: this.form.electionId,
        isNotification: this.isNotification,
      };
      getCategories(data).then((res) => {
        if(res.data.length > 0){
          this.categories = res.data;
        }
      });
    },
    onLabelSelect(){
      let val = "";
      this.labels.forEach((element) => {
        if(element.checked){
          val += element.value;
          element.checked = false;
        }
      });
      this.$refs.messageEditor.insertHtml(val);
    },
    formDataAdd(){
      let formData = new FormData();
      if(this.form.id){
        formData.append("id", this.form.id);
      }
      formData.append("isNotification", this.isNotification);
      formData.append("subject", this.form.subject);
      formData.append("content", this.form.content);
      if(this.form.category){
        formData.append("category", this.form.category);
      }
      formData.append("templateType", this.form.templateType);
      if(this.form.file && this.form.file.length > 0){
        formData.append("File", this.form.file[0]);
      }
      if(this.form.electionId){
        formData.append("electionId", this.form.electionId);
      }
      return formData;
    },
    async onSubmit(){
      const isValid = await this.$refs.observer.validate();
      if(isValid){
        let pmOpt = this.$confirm({
          title: this.$g("const.confirm.confirm"),
          content: this.$g("const.confirm.save"),
          cancelType: "secondary",
          okText: this.$g("const.confirm.saveYes"),
          cancelText: this.$g("const.confirm.no"),
        });
        if(pmOpt == null){
          return;
        }
        pmOpt.then(() => {
          let opt;

          let formData = this.formDataAdd();

          if(this.id){
            opt = putTemplate;
          } else {
            opt = addTemplate;
          }

          opt(formData).then((m) => {
            if(m.data.status == repResult.success){
              this.$emit("save-successful", m, m.data.info);
            } else {
              this.$refs.lblMsg.message(m.data.status, m.message);
            }
          });
        });
      }
    },
    onReset(event){
      event.preventDefault();
      this.form = {};
    },
    getData(){
      if(this.id){
        getTemplate(this.id, this.docId).then((data) => {
          this.form = data.data;
          this.form.file = [{ name: data.data.fileName }];
          this.placeholder = data.data.fileName;
          this.uploadKey ++;

          this.setOrignData(this.form);
        });
      } else {
        this.form.templateType = this.$route.query.templateType;

        this.setOrignData(this.form);
      }
    },
    onDelete(file){
      if(this.form.file[0] && this.form.file[0].name == file.name){
        this.form.file = [];
        this.uploadKey ++;
      }
    },
    async isSubjectExits(){
      let result = null;
      await checkSubjectExits({
        subject: this.form.subject,
        id: this.form.id,
        templateType: this.form.templateType,
        electionId: this.form.electionId,
        isNotification: this.isNotification,
      }).then(function (rep){
        result = rep;
      });
      return result;
    },
  },
};
</script>
