import http from "../../../utils/http";

export const getUsers = (data) => {
    return http.post("/api/search/search-users/page", data, { useBody: true });
};
export const getShareToUsers = (data) => {
    return http.get("/api/search/search-user/models", {
        searchId: data.searchId,
        sharedUserId: data.sharedUserId
    }, {});
};
export const saveShareToUsers = (data) => {
    return http.post("/api/search/search-users/page-save", data, { useBody: true });
};
export const deleteShareToUsers = (data) => {
    return http.delete("api/search/user", data, { useBody: true });
};
export const getGroups = (data) => {
    return http.post("/api/search/search-groups/page", data, { useBody: true });
};
export const getShareToGroups = (data) => {
    return http.get("/api/search/search-group/models", {
        searchId: data.searchId,
        sharedGroupId: data.sharedGroupId
    }, {});
};
export const saveShareToGroups = (data) => {
    return http.post("/api/search/search-groups/page-save", data, { useBody: true });
};
export const deletShareToGroups = (data) => {
    return http.delete("/api/search/group", data, { useBody: true });
};

export const setDefault = (data) => {
    return http.post("/api/search/default", data, { useBody: true, loading: false });
};