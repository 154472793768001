<template>
  <div class="content-page">
    <div class="nav-btn-group">
      <k-button-back :back-route="backRoute">
        import.back
      </k-button-back>
    </div>
    <div class="content-main">
      <div>
        <b-alert
          :show="errors.length > 0"
          :variant="variant"
        >
          {{ errors[0] }}
        </b-alert>
      </div>
      <slot />
      <div class="pr-1 pl-1 file-group">
        <upload
          ref="upload"
          v-model="form.file"
          :accept="accept"
          :size="size"
          :is-multiple="false"
          @input="inputHandler"
          @errMsgChange="onErrMsgChangeHanlder"
        >
          <template #errMsg>
            <div />
          </template>
          <template #info="infoData">
            <k-button
              @click="upload"
              class="mr-1 mb-1"
              :disabled="uploading"
            >
              import.upload
            </k-button>
            <k-button
              @click="downloadTemplateHandler"
              class="mb-1"
            >
              import.downloadTemplate
            </k-button>
            <div class="mt-1">
              {{ $g("import.size") }}: {{ getFileSize() }}
              <br>
              {{ $g("import.extensions") }}: {{ infoData.data.accept }}
            </div>
          </template>
        </upload>
        <div />
        <b-card
          v-show="isShowErrorDataInfo"
          class="mb-2 col-5 pr-0 pl-0"
        >
          <h5>{{ $g("import.errDataInfo") }}</h5>
          <div>
            <table class="mb-2 table table-bordered">
              <caption />
              <tr>
                <th
                  style="width: 100px"
                  scope="col"
                >
                  {{ $g("import.row") }}
                </th>
                <th scope="col">
                  {{ $g("import.reason") }}
                </th>
              </tr>
              <tr
                v-for="(errorData, index) in responseErrorDatas"
                :key="index"
              >
                <td>{{ errorData.rowNumber }}</td>
                <td>{{ errorData.message }}</td>
              </tr>
            </table>

            <k-button @click="exportErrorDataHandler">
              import.exportErrorData
            </k-button>
          </div>
        </b-card>
        <div class="text-red">
          <div>{{ $g("import.requiredField") }}:</div>
          <div
            v-for="(requireField, index) in requireFields"
            :key="index"
          >
            {{ requireField }}
          </div>
        </div>
      </div>
      <div class="content-partyNormal" :class="{'content-party': !isShowErrorDataInfo}">
        <slot name="logList" />
        <logList
          v-if="!hideLogList"
          ref="logList"
          :import-type="importType"
          :is-event="isEvent"
        />
      </div>
    </div>
  </div>
</template>
<script>
import upload from "../common-upload";
import logList from "./components/importLogList";

import {
  getUploadInfo,
  uploadFiles,
  downloadTemplate,
  //exportForImport,
  exportErrorData,
  uploadFilesRemoteTask,
} from "./api/import";
import eventBus from "../../utils/eventBus";
import { startTaskProgress, startTaskProgressWorker } from "../../business-components/common-remote-task/remoteTaskStarter";
import { importType } from "../../libs/global"

export default {
  data(){
    return {
      size: 0,
      accept: "",
      isShowErrorDataInfo: false,
      requireFields: [],
      responseErrorDatas: [],
      enumResponseResult: {
        Success: 1,
        Warning: 2,
        Faild: 3,
        Information: 4,
      },
      form: {
        title: "",
        file: [],
      },
      enumImportStatusError: 4,
      currentImportId: "",
      errors: [],
      variant: "warning",
      uploading: false,
      isError: false,
      sysImportType: importType
    };
  },
  watch: {},
  methods: {
    upload(){
      this.errors.splice(0, this.errors.length);
      if(this.otherCheck){
        var result = this.otherCheck();
        if(result != ""){
          this.getInfo(result, "warning");
          return;
        }
      }
      let files = this.form.file;
      if(files.length === 0){
        this.getInfo(this.$g("import.selectFile"), "danger");
        return;
      }

      this.uploading = true;
      this.uploadFiles(files, this.importType, this.condition);
    },
    uploadFiles(files, importType, condition){
      // debugger;
      if(condition && condition.useRemoteTask){
        uploadFilesRemoteTask(files, importType, condition).then((res) => {
          this.$refs.upload.clear();
          this.responseErrorDatas.splice(0, this.responseErrorDatas.length);
          if(res.data.status !== this.enumResponseResult.Success){
            this.getInfo(res.message, "danger");
          } else {
            this.getInfo(res.message, "success");
            if( 
              importType == this.sysImportType.votingStationUpdateResourceAdjustmentBAU ||
              importType == this.sysImportType.votingStationUpdateResourceAdjustmentEvent
            ){
              startTaskProgressWorker(res.data.info.taskId);
            } else {
              startTaskProgress(res.data.info.taskId);
            }
           
          }
          this.uploading = false;
          this.refreshLogList();
        });
      } else {
        uploadFiles(files, importType, condition).then((res) => {
          this.$refs.upload.clear();
          this.responseErrorDatas.splice(0, this.responseErrorDatas.length);
          if(res.data.status !== this.enumResponseResult.Success){
            this.getInfo(res.data.info, "danger");
          } else {
            if(res.data.info.errorItems.length > 0){
              this.getInfo(res.data.info.log, "warning");
              this.responseErrorDatas.push(res.data.info.errorItems[0]);
              this.currentImportId = res.data.info.id;
              this.isShowErrorDataInfo = true;
            } else {
              if(res.data.info.status === this.enumImportStatusError){
                this.getInfo(res.data.info.log, "danger");
              } else {
                this.getInfo(res.data.info.log, "success");
              }
              this.isShowErrorDataInfo = false;
            }
          }
          this.uploading = false;
          this.refreshLogList();
        });
      }
    },
    downloadTemplateHandler(){
      downloadTemplate(this.importType,this.condition);
    },

    exportErrorDataHandler(){
      exportErrorData(this.currentImportId,this.condition);
    },

    inputHandler(){
      if(!this.isError) this.errors.splice(0, this.errors.length);
      this.isError = false;
      this.isShowErrorDataInfo = false;
      this.responseErrorDatas.splice(0, this.responseErrorDatas.length);
    },
    onErrMsgChangeHanlder(errMsg){
      if(errMsg.length > 0){
        this.getInfo(errMsg[0], "danger");
        this.isError = true;
      }
    },
    refreshLogList(){
      if(this.hideLogList){
        this.$emit("refresh-logList");
      } else {
        this.$refs.logList.refresh();
      }
    },
    getFileSize(){
      let rest = "",
          size = this.size || 0;

      if(size < 1024){
        rest = size + "KB";
      } else if(size < 1024 * 1024){
        rest = (size / 1024).toFixed(0) + "MB";
      } else {
        //其他转化成GB
        rest = (size / (1024 * 1024)).toFixed(0) + "GB";
      }

      return rest;
    },
    getInfo(msg, type){
      this.errors.splice(0, this.errors.length);
      this.errors.push(msg);
      this.variant = type;
      setTimeout(() => {
        this.errors = [];
      }, 5000);
    },
  },

  props: {
    importType: {
      type: Number,
      default(){
        return 0;
      },
    },
    backRoute: {
      type: String,
      default(){
        return "";
      },
    },
    condition: {
      type: Object,
      default(){
        return {};
      },
    },
    otherCheck: {
      type: Function,
    },
    hideLogList: {
      type: Boolean,
      default(){
        return false;
      },
    },
    isEvent: {
      type: Boolean,
      default(){
        return false;
      },
    },
  },
  mounted(){
    eventBus.$on("taskFinished", (taskContext) => {
      this.refreshLogList();
      this.getInfo(this.$g("taskSuccess"), "success");

      this.$refs.upload.clear();
      this.responseErrorDatas.splice(0, this.responseErrorDatas.length);
      var info = taskContext.resultObj;
      if(info.errorItems.length > 0){
        this.getInfo(info.log, "warning");
        this.responseErrorDatas.push(info.errorItems[0]);
        this.currentImportId = info.id;
        this.isShowErrorDataInfo = true;
      } else {
        if(info.status === this.enumImportStatusError){
          this.getInfo(info.log, "danger");
        } else {
          this.getInfo(info.log, "success");
        }
        this.isShowErrorDataInfo = false;
      }
      this.uploading = false;
      this.refreshLogList();
    });
  },
  beforeDestroy(){
    eventBus.$off("taskFinished");
  },
  created(){
    getUploadInfo(this.importType).then((res) => {
      this.size = parseInt(res.data.info.size);
      this.accept = res.data.info.accept;
      this.requireFields = res.data.info.required.split(",");
    });
  },
  components: { logList, upload },
};
</script>
<style lang="scss">
.file-group {
  .file-group-width {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .file-group {
    .file-group-width {
      width: 100%;
    }
  }
}
</style>
