const state = {
  pageTabs: {}
};

const mutations = {
  SET_TAB_ITEMS(state1, tabs) {
    state1.pageTabs = tabs
  }
};

const actions = {
  savePageTabs({ commit, rootState }, para) {
    let listId = para.listId
    let condition = para.condition
    if (listId && condition) {
      let ts = rootState.pageTab.pageTabs
      if (!ts) {
        ts = {}
      }
      ts[listId] = condition
      commit('SET_TAB_ITEMS', ts)
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
