<template>
  <b-button
    v-bind="$props"
    v-on="listeners"
    @click="onClick"
  >
    <span
      ref="spSlot"
      style="display:none"
    >
      <slot />
    </span>
  </b-button>
</template>
<script>
import { BButton } from 'bootstrap-vue'
import { g } from '../../locale/lang-val'

const btnProps = Object.assign({}, BButton.options.props)
btnProps.squared = {
  type: Boolean,
  default () {
    return false
  },
}
btnProps.noLocale = {
  type: Boolean,
  default () {
    return false
  },
}
export default {
  components: { BButton },
  props: {
    ...btnProps,
  },
  computed: {
    listeners () {
      // eslint-disable-next-line no-unused-vars
      let { click, ...params } = this.$listeners
      return params
    },
  },
  methods: {
    onClick () {
      this.$emit('click')
    },
  },
  mounted () {
    let key = this.$refs.spSlot.innerText
    if (!this.noLocale) {
      this.$refs.spSlot.innerText = g(key)
    }
    this.$refs.spSlot.style.display = 'block'
  },
}
</script>
