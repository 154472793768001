import Vue from "vue";
//import "bootstrap/dist/css/bootstrap.css";
//import "bootstrap-vue/dist/bootstrap-vue.css";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

import {
  KAlert,
  KAspect,
  KAvatar,
  KAvatarGroup,
  KBadge,
  KBreadcrumb,
  KBreadcrumbItem,
  KButton,
  KButtonClose,
  KButtonGroup,
  KButtonToolbar,
  KCalendar,
  KCard,
  KCardHeader,
  KCardBody,
  KCardTitle,
  KCardSubTitle,
  KCardFooter,
  KCardImg,
  KCardImgLazy,
  KCardText,
  KCardGroup,
  KCarousel,
  KCarouselSlide,
  KCollapse,
  KDropdown,
  KDropdownItem,
  KDropdownItemButton,
  KDropdownHeader,
  KDropdownDivider,
  KDropdownForm,
  KDropdownText,
  KDropdownGroup,
  KEmbed,
  KForm,
  KFormDatalist,
  KFormText,
  KFormInvalidFeedback,
  KFormValidFeedback,
  KFormCheckbox,
  KFormCheckboxGroup,
  KFormDatepicker,
  KFormDateTimepicker,
  KFormFile,
  KFormGroup,
  KFormInput,
  KFormRadio,
  KFormRadioGroup,
  KFormRating,
  KFormSelect,
  KFormSelectOption,
  KFormSelectOptionGroup,
  KFormSpinbutton,
  KFormTags,
  KFormTag,
  KFormTextarea,
  KFormTimepicker,
  KImg,
  KImgLazy,
  KInputGroup,
  KInputGroupAddon,
  KInputGroupPrepend,
  KInputGroupAppend,
  KInputGroupText,
  KJumbotron,
  KContainer,
  KRow,
  KCol,
  KFormRow,
  KLink,
  KListGroup,
  KListGroupItem,
  KMedia,
  KMediaAside,
  KMediaBody,
  KModal,
  KNav,
  KNavItem,
  KNavText,
  KNavForm,
  KNavItemDropdown,
  KNavbar,
  KNavbarNav,
  KNavbarBrand,
  KNavbarToggle,
  KOverlay,
  KPopover,
  KProgress,
  KProgressBar,
  KSidebar,
  KSkeleton,
  KSkeletonIcon,
  KSkeletonImg,
  KSkeletonTable,
  KSkeletonWrapper,
  KSpinner,
  KTime,
  KToast,
  KToaster,
  KTooltip,
  KPopWindow,
  KValidationProvider,
  KValidationObserver,
  KonnGrid,
  KonnRowSpanGrid,
  KTabs,
  KTab,
  KAlertMsg,
  CommonImport,
  CommonUpload,
  KButtonBack,
  ExportButton,
  KExtFields,
  KExtendFields,
  KPreNext,
  KMultiselect,
  KMap,
  KFormPhoneInput,
  KButtonDelete,
  ColumnConfigIcon,
  ErrorPage,
  KCustomFields,
  KonnFixedGrid,
} from "fe-infrastractures";

Vue.component("KAlert", KAlert);
Vue.component("KAspect", KAspect);
Vue.component("KAvatar", KAvatar);
Vue.component("KAvatarGroup", KAvatarGroup);
Vue.component("KBadge", KBadge);
Vue.component("KBreadcrumb", KBreadcrumb);
Vue.component("KBreadcrumbItem", KBreadcrumbItem);
Vue.component("KButton", KButton);
Vue.component("KButtonClose", KButtonClose);
Vue.component("KButtonGroup", KButtonGroup);
Vue.component("KButtonToolbar", KButtonToolbar);
Vue.component("KCalendar", KCalendar);
Vue.component("KCard", KCard);
Vue.component("KCardHeader", KCardHeader);
Vue.component("KCardBody", KCardBody);
Vue.component("KCardTitle", KCardTitle);
Vue.component("KCardSubTitle", KCardSubTitle);
Vue.component("KCardFooter", KCardFooter);
Vue.component("KCardImg", KCardImg);
Vue.component("KCardImgLazy", KCardImgLazy);
Vue.component("KCardText", KCardText);
Vue.component("KCardGroup", KCardGroup);
Vue.component("KCarousel", KCarousel);
Vue.component("KCarouselSlide", KCarouselSlide);
Vue.component("KCollapse", KCollapse);
Vue.component("KDropdown", KDropdown);
Vue.component("KDropdownItem", KDropdownItem);
Vue.component("KDropdownItemButton", KDropdownItemButton);
Vue.component("KDropdownHeader", KDropdownHeader);
Vue.component("KDropdownDivider", KDropdownDivider);
Vue.component("KDropdownForm", KDropdownForm);
Vue.component("KDropdownText", KDropdownText);
Vue.component("KDropdownGroup", KDropdownGroup);
Vue.component("KEmbed", KEmbed);
Vue.component("KForm", KForm);
Vue.component("KFormDatalist", KFormDatalist);
Vue.component("KFormText", KFormText);
Vue.component("KFormInvalidFeedback", KFormInvalidFeedback);
Vue.component("KFormValidFeedback", KFormValidFeedback);
Vue.component("KFormCheckbox", KFormCheckbox);
Vue.component("KFormCheckboxGroup", KFormCheckboxGroup);
Vue.component("KFormDatepicker", KFormDatepicker);
Vue.component("KFormDateTimepicker", KFormDateTimepicker);
Vue.component("KFormFile", KFormFile);
Vue.component("KFormGroup", KFormGroup);
Vue.component("KFormInput", KFormInput);
Vue.component("KFormPhoneInput", KFormPhoneInput);
Vue.component("KFormRadio", KFormRadio);
Vue.component("KFormRadioGroup", KFormRadioGroup);
Vue.component("KFormRating", KFormRating);
Vue.component("KFormSelect", KFormSelect);
Vue.component("KFormSelectOption", KFormSelectOption);
Vue.component("KFormSelectOptionGroup", KFormSelectOptionGroup);
Vue.component("KFormSpinbutton", KFormSpinbutton);
Vue.component("KFormTags", KFormTags);
Vue.component("KFormTag", KFormTag);
Vue.component("KFormTextarea", KFormTextarea);
Vue.component("KFormTimepicker", KFormTimepicker);
Vue.component("KImg", KImg);
Vue.component("KImgLazy", KImgLazy);
Vue.component("KInputGroup", KInputGroup);
Vue.component("KInputGroupAddon", KInputGroupAddon);
Vue.component("KInputGroupPrepend", KInputGroupPrepend);
Vue.component("KInputGroupAppend", KInputGroupAppend);
Vue.component("KInputGroupText", KInputGroupText);
Vue.component("KJumbotron", KJumbotron);
Vue.component("KContainer", KContainer);
Vue.component("KRow", KRow);
Vue.component("KCol", KCol);
Vue.component("KFormRow", KFormRow);
Vue.component("KLink", KLink);
Vue.component("KListGroup", KListGroup);
Vue.component("KListGroupItem", KListGroupItem);
Vue.component("KMedia", KMedia);
Vue.component("KMediaAside", KMediaAside);
Vue.component("KMediaBody", KMediaBody);
Vue.component("KModal", KModal);
Vue.component("KNav", KNav);
Vue.component("KNavItem", KNavItem);
Vue.component("KNavText", KNavText);
Vue.component("KNavForm", KNavForm);
Vue.component("KNavItemDropdown", KNavItemDropdown);
Vue.component("KNavbar", KNavbar);
Vue.component("KNavbarNav", KNavbarNav);
Vue.component("KNavbarBrand", KNavbarBrand);
Vue.component("KNavbarToggle", KNavbarToggle);
Vue.component("KOverlay", KOverlay);
Vue.component("KPopover", KPopover);
Vue.component("KProgress", KProgress);
Vue.component("KProgressBar", KProgressBar);
Vue.component("KSidebar", KSidebar);
Vue.component("KSkeleton", KSkeleton);
Vue.component("KSkeletonIcon", KSkeletonIcon);
Vue.component("KSkeletonImg", KSkeletonImg);
Vue.component("KSkeletonTable", KSkeletonTable);
Vue.component("KSkeletonWrapper", KSkeletonWrapper);
Vue.component("KSpinner", KSpinner);
Vue.component("KTabs", KTabs);
Vue.component("KTab", KTab);
Vue.component("KTime", KTime);
Vue.component("KToast", KToast);
Vue.component("KToaster", KToaster);
Vue.component("KTooltip", KTooltip);
Vue.component("KValidationProvider", KValidationProvider);
Vue.component("KValidationObserver", KValidationObserver);
Vue.component("KonnGrid", KonnGrid);
Vue.component("ErrorPage", ErrorPage);
Vue.component("KonnRowSpanGrid", KonnRowSpanGrid);
Vue.component("KAlertMsg", KAlertMsg);
Vue.component("KPopwindow", KPopWindow);
Vue.component("CommonUpload", CommonUpload);
Vue.component("CommonImport", CommonImport);
Vue.component("KButtonBack", KButtonBack);
Vue.component("KExportButton", ExportButton);
Vue.component("KExtFields", KExtFields);
Vue.component('KPreNext', KPreNext)
Vue.component('KMultiselect', KMultiselect)
Vue.component('KMap', KMap)
Vue.component('KButtonDelete', KButtonDelete)
Vue.component('ColumnConfigIcon', ColumnConfigIcon)
Vue.component("KCustomFields", KCustomFields);
Vue.component("KExtendFields", KExtendFields);
Vue.component("KonnFixedGrid", KonnFixedGrid);
