<template>
  <group-detail
    :is-event="isEvent"
    :plaintext="isPlainText"
    :id="id"
    ref="detail"
  >
    <template #formBar>
      <div>
        <k-button-back
          :back-route="isEvent ? 'eleMessageGroup' : 'messageGroup'"
        >
          buttons.back
        </k-button-back>
        <k-button id="btnEdit" v-if="isEdit" @click="onRedirectEdit">
          buttons.edit
        </k-button>
        <k-pre-next :has-save-next="false" ref="pn" />
        <k-button-delete
          v-if="isDel"
          :delete-handler="deleteHandler"
          :redirect-route-name="isEvent ? 'eleMessageGroup' : 'messageGroup'"
          :del-id="id"
          @delete-failed="deleteFailed"
        />
      </div>
      <div class="nav-search">
        <search-bar
          :data-source="searchBarDataSource"
          query-keyword="keywords"
          :search-bar-fomatter="searchBarFomatter"
          @option-selected="searchSelected"
          sort="groupName"
          order="Asc"
          :other-conditions="{ electionId: electionId }"
        />
      </div>
    </template>
    <template #formBarNav>
      <k-alert-msg ref="lblMsg" />
      <group-nav
        :is-event="isEvent"
        :tab-index="0"
        :is-plain-text="isPlainText"
      />
    </template>
  </group-detail>
</template>
<script>
import GroupNav from "../../components/groupNav";
import GroupDetail from "../../components/groupDetail";
import { repResult } from "../../../../libs/global";
import { saveAlert } from "../../../../utils/saveAlert";
import {
  delGroup,
  getGroupSearchBarList,
  getEleGroupSearchBarList
} from "../../api/messageGroup";
import { has } from "../../../../utils/permission/operatePermission";
import storeage from "../../../../utils/storeage";
import { permissionCode } from "../../mixins/permissionCode";

export default {
  mixins: [permissionCode],
  components: { GroupDetail, GroupNav },
  props: {
    propPageConfigSystem: {
      type: String,
      default() {
        return null;
      }
    }
  },
  data() {
    let isEvent = this.$route.meta.isEvent;
    return {
      isEvent: isEvent,
      id: this.$route.query.id,
      electionId: isEvent ? storeage.getElectionId() : "",
      isPlainText: true,
      isEdit: false,
      isDel: false
    };
  },
  computed: {
    deleteHandler: function () {
      return delGroup;
    },
    searchBarDataSource: function () {
      if (this.isEvent) {
        return getEleGroupSearchBarList;
      } else {
        return getGroupSearchBarList;
      }
    }
  },
  methods: {
    setPermission() {
      if (this.isEvent) {
        this.isEdit = has(this.permissionCodeEvent.Communication.GroupsEdit);
        this.isDel = has(this.permissionCodeEvent.Communication.GroupsDelete);
      } else {
        this.isEdit = has(this.permissionCode.Communication.GroupsEdit);
        this.isDel = has(this.permissionCode.Communication.GroupsDelete);
      }
    },
    onRedirectEdit() {
      this.$router.push({
        name: this.isEvent ? "eleMessageGroupEdit" : "messageGroupEdit",
        query: {
          id: this.$route.query.id,
          isView: "0",
          hidePreNext: this.$route.query.hidePreNext,
          dbtn: this.$route.query.dbtn
        }
      });
    },
    showAlert() {
      let status = saveAlert.savedStatus(this.$route);
      if (status === repResult.success) {
        this.$refs.lblMsg.message(status, saveAlert.savedMessage(this.$route));
      }
    },
    deleteFailed(m) {
      this.$refs.lblMsg.message(m.data.status, m.message);
    },
    searchBarFomatter(item) {
      return item.groupName;
    },
    searchSelected(item) {
      this.$router.push({
        name: this.$route.name,
        query: {
          id: item.id,
          hidePreNext: "1",
          isView: "1",
          dbtn: this.$route.query.dbtn
        }
      });
    }
  },
  mounted() {
    this.showAlert();
    this.setPermission();
  }
};
</script>
