<template>
  <div>
    <div class="d-flex flex-row">
      <k-form-group
        label-suffix="search.firstName"
        label-for="noneBind"
        label-align-md="right"
        label-cols-md="4"
        content-cols-lg="6"
        content-cols-md="8"
      >
        <k-form-input v-model="queryParas.firstName" />
      </k-form-group>
      <k-form-group
        label-suffix="search.lastName"
        label-for="noneBind"
        label-align-md="right"
        label-cols-md="4"
        content-cols-lg="6"
        content-cols-md="7"
      >
        <k-form-input v-model="queryParas.lastName" />
      </k-form-group>
      <k-form-group
        label-suffix="search.group"
        label-for="noneBind"
        label-align-md="right"
        label-cols-md="4"
      >
        <k-form-select v-model="queryParas.groupId" :options="groupOptions" />
      </k-form-group>
      <k-form-group
        label-for="noneBind"
        label-align-md="right"
        label-cols-md="4"
        content-cols-lg="6"
        content-cols-md="7"
      >
        <k-button type="button" @click="onSearch">search</k-button>
      </k-form-group>
    </div>
    <konn-grid
      ref="selectableTable"
      :has-check-box="true"
      :has-action="false"
      :data-source="dataSource"
      :get-condition="getCondition"
      grid-sort-by="subject"
      class="stickyTable"
      :fields="fields"
    ></konn-grid>
  </div>
</template>

<script>
import {
  getUserGroupsOptions,
  getOfficerRecipientsPage
} from "../../api/notification";
export default {
  data() {
    return {
      fields: [
        { key: "firstName", label: "firstName" },
        { key: "lastName", label: "lastName" },
        { key: "email", label: "email" },
        { key: "group", label: "group" },
        { key: "status", label: "status" }
      ],
      queryParas: {
        firstName: "",
        lastName: "",
        groupId: ""
      },
      groupOptions: []
    };
  },
  computed: {
    dataSource() {
      return getOfficerRecipientsPage;
    }
  },
  created() {
    this.initOptions();
  },
  methods: {
    getCondition() {
      return { ...this.queryParas };
    },
    onSearch() {
      this.$refs.selectableTable.refresh();
    },
    initOptions() {
      getUserGroupsOptions().then((result) => {
        this.groupOptions = result.data;
      });
    }
  }
};
</script>

<style>
</style>