import http from "../../../utils/http";
import globalEnv from "../../../libs/globalEnv";

export const messagePage = (data) => {
  return http.post("api/message-alert/page", data, {
    useBody: true,
    systemPATH: "admin-interact",
    loading: false,
    baseURL: globalEnv.VUE_APP_ADMIN_URL,
  });
};
export const messageSet = (data) => {
  return http.post("api/message-alert/read-status/set", data, {
    systemPATH: "admin-interact",
    loading: false,
    baseURL: globalEnv.VUE_APP_ADMIN_URL,
  });
};
export const messageCount = (data) => {
  return http.get("api/message-alert/count", data, {
    systemPATH: "admin-interact",
    loading: false,
    baseURL: globalEnv.VUE_APP_ADMIN_URL,
  });
};
export const messageDetail = (id) => {
  return http.get(`api/message-alert/${id}`, null, {
    systemPATH: "admin-interact",
    loading: false,
    baseURL: globalEnv.VUE_APP_ADMIN_URL,
  });
};
export const deleteMessage = (data) => {
  return http.delete("/api/message-alert", data, {
    systemPATH: "admin-interact",
    loading: false,
    baseURL: globalEnv.VUE_APP_ADMIN_URL,
  });
};

