import Vue from "vue";
import VueRouter from "vue-router";
import RouterFactory from "./factory";
import { moduleFiles, constantFile } from "@/router/routerFilesProvide";
import globalEnv from "../libs/globalEnv";

Vue.use(VueRouter);

//注释的地方是允许路由重复点击，如果你觉得框架路由跳转规范太过严格可选择放开
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location){
  return originalPush.call(this, location).catch(err => err);
};

const asyncRoutes = []; //动态路由集合
const constantRoutes = []; //常量路由集合

/**
 * 定义路由对象
 * @param {Object} route 路由对象
 */
const raiseRoute = route => {
  route.meta = Object.assign(
    {
      isCache: true, // 是否缓存页面
      requiresAuth: true, // 是否需要权限
    },
    route.meta,
  );
};
/**
 * 定义路由集合
 * @param {Array} child 子路由集合
 * @param {Object} route 父路由对象
 */
const raiseChild = (child, route) => {
  child.push({
    name: (route.name || "") + 404,
    path: "*",
    meta: { requiresAuth: true, is404: true },
    component: () => import("../../src/business-components/error/404.vue"),
  });
};

const asyncFactory = new RouterFactory(raiseRoute, raiseChild);
const constantFactory = new RouterFactory(raiseRoute, raiseChild);

if(moduleFiles && moduleFiles.length > 0){
  moduleFiles.keys().forEach(key => {
    const module = moduleFiles(key);
    if(module !== undefined && module.default !== undefined){
      if(globalEnv.VUE_APP_BASE_PATH){
        module.default[0].path =
          globalEnv.VUE_APP_BASE_PATH + module.default[0].path;
      }
      asyncFactory.register(module.default);
    }
  });
}

if(constantFile && constantFile.length > 0){
  constantFactory.register(constantFile);
}

asyncRoutes.push(...asyncFactory.routes);
constantRoutes.push(...constantFactory.routes);

/**
 * 构建路由
 * @param {Array} routes 路由集合
 */
const buildRouter = routes =>
  new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [...constantRoutes, ...(routes || [])],
    scrollBehavior(){
      return { x: 0, y: 0 };
    },
  });

const router = buildRouter(asyncRoutes);

/**
 * 重置路由
 * @param {Array} routes 路由集合
 */
const resetRouter = routes => {
  const newRouter = buildRouter(routes);
  router.matcher = newRouter.matcher;
};

export { asyncRoutes, resetRouter, constantRoutes };

export default router;
