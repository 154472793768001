<template>
  <span>
    <span v-if="plaintext">
      <span v-html="displayText" /> <slot v-if="displayText != ''" />
    </span>
    <b-form-checkbox
      v-else
      v-bind="props"
      v-on="listeners"
    >
      <span
        ref="spSlot"
        style="display: none"
      >
        <slot />
      </span>
    </b-form-checkbox>
    <span />
  </span>
</template>
<script>
import { BFormCheckbox } from 'bootstrap-vue'
import { g } from '../../locale/lang-val'

const addedProps = {
  plaintext: {
    type: Boolean,
    default () {
      return false
    },
  },
  noLocale: {
    type: Boolean,
    default () {
      return false
    },
  },
}
let objProps = Object.assign({}, BFormCheckbox.options.props, addedProps)

export default {
  components: { BFormCheckbox },
  model: {
    prop: 'checked',
    event: 'input',
  },
  props: {
    ...objProps,
  },
  computed: {
    props () {
      return this.$props
    },
    listeners () {
      return this.$listeners
    },
    displayText () {
      if (this.plaintext) {
        const htmlCheck = '&check;'
        if (
            this.checked ||
            (typeof this.value == 'string' &&
                this.$parent &&
                this.$parent != this &&
                this.$parent.checked instanceof Array &&
                this.$parent.checked.includes(this.value))
        ) {
          return htmlCheck
        }
      }
      return ''
    },
  },
  mounted () {
    if (this.$refs.spSlot !== undefined) {
      let key = this.$refs.spSlot.innerText
      if (key != '') {
        this.$refs.spSlot.style.display = 'block'
        if (!this.noLocale && !this.plaintext) {
          this.$refs.spSlot.innerText = g(key)
        }
      }
    }
  },
}
</script>
