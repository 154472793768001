<template>
  <span>
    <span v-if="plaintext">
      <span v-html="displayText" /> <slot v-if="displayText != ''" />
    </span>
    <b-form-radio-group
      v-else
      v-bind="props"
      v-on="listeners"
    >
      <slot />
    </b-form-radio-group>
  </span>
</template>
<script>
import { BFormRadioGroup } from 'bootstrap-vue'

const addedProps = {
  plaintext: {
    type: Boolean,
    default () {
      return false
    },
  },
}
let objProps = Object.assign({}, BFormRadioGroup.options.props, addedProps)
export default {
  components: { BFormRadioGroup },
  model: {
    prop: 'checked',
    event: 'input',
  },
  props: {
    ...objProps,
  },
  computed: {
    props () {
      return this.$props
    },
    listeners () {
      return this.$listeners
    },
    displayText () {
      if (this.plaintext) {
        let htmlCheck = ''
        if (this.checked) {
          let item1 = this.options.find((item) => {
            return item.value == this.checked
          })
          if (item1) {
            htmlCheck += item1.text + ' '
          }
        }
        return htmlCheck
      }
      return ''
    },
  },
}
</script>
