const routes = [
    {
        name: 'notifications',
        path: '/',
        meta: { title: 'notifications', requiresAuth: false },
        component: () => import('@/views/const/space'),
        route: true,
    },

]

export default [
    {
        path: '/notifications',
        route: false,
        component: () => import('@/layout/brief'),
        children: [...routes],
    },
]