<template>
  <div>
    <div class="content-title" v-if="isShowTitle">
      {{ title }}

      <k-button
        v-if="rightBtnText && rightBtnText != ''"
        @click="clickRightBtn"
        :no-locale="true"
        class="rightBtn"
      >
        {{ rightBtnText }}
      </k-button>
      <k-button
        v-if="rightBtnTextV2 && rightBtnTextV2 != ''"
        @click="clickRightBtnV2"
        :no-locale="true"
        class="rightBtn"
        style="margin-right: 15px"
      >
        {{ rightBtnTextV2 }}
      </k-button>
    </div>

    <div class="content-group">
      <k-carousel
        id="carousel-1"
        :interval="interval"
        :controls="!isNoImage"
        indicators
        :background="background"
        style="text-shadow: 1px 1px 2px #333"
        @sliding-start="onSlideStart"
        @sliding-end="onSlideEnd"
      >
        <k-carousel-slide v-for="(photo, index) in photoes" :key="index">
          <template #img>
            <img
              class="img-fluid d-block"
              :src="photo.base64String"
              alt="image slot"
              @click="clickModel"
            />
          </template>
        </k-carousel-slide>
        <div class="noPhotoImage" v-if="isNoImage" @click="clickModel">
          <div class="noPhotoFont" style="font-size: 2rem">
            {{ $g("images.noPhotoText") }}
          </div>
        </div>
      </k-carousel>
    </div>
  </div>
</template>
<script>
import { getDocs } from "./image";

export default {
  components: {},
  props: {
    isShowTitle: {
      type: Boolean,
      default() {
        return true;
      },
    },
    interval: {
      type: Number,
      default() {
        return 0;
      },
    },
    background: {
      type: String,
      default: "#ababab",
    },
    docRequestType: {
      type: Number,
      default() {
        return 0;
      },
    },
    refId: {
      type: String,
      default() {
        return "";
      },
    },
    refId2: {
      type: String,
      default() {
        return "";
      },
    },
    clickModel: {
      type: Function,
      default: function () {
        return null;
      },
    },
    clickRightBtn: {
      type: Function,
      default: function () {
        return null;
      },
    },
    clickRightBtnV2: {
      type: Function,
      default: function () {
        return null;
      },
    },
    defaultId: {
      type: String,
      default() {
        return "";
      },
    },
    title: {
      type: String,
      default() {
        return this.$g("images.photos");
      },
    },
    rightBtnText: {
      type: String,
      default() {
        return "";
      },
    },
    rightBtnTextV2: {
      type: String,
      default() {
        return "";
      },
    },
  },

  data() {
    return {
      photoes: [],
      isNoImage: false,
    };
  },

  methods: {
    async bindPhotoes() {
      if (this.refId && this.refId.length > 0) {
        await getDocs(this.refId,this.refId2, this.docRequestType).then((res) => {
          const items = res.data;
          this.photoes = items || [];
          this.sortImg();
          if (res.data.length === 0) {
            this.isNoImage = true;
          } else {
            this.isNoImage = false;
          }
        });
      }
    },
    sortImg() {
      if (this.defaultId != "") {
        var that = this;
        this.photoes.forEach(function (value, index) {
          value.ordeindex = that.defaultId == value.id ? 0 : 1;
        });
        this.photoes.sort(function (a, b) {
          return a.ordeindex - b.ordeindex;
        });
      }
    },
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },

    refresh() {
      this.bindPhotoes();
    },
  },

  mounted() {
    this.bindPhotoes();
  },
  watch: {
    refId() {
      this.bindPhotoes();
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .carousel.slide {
    width: 80%;
    margin: 0 auto;
  }

  .carousel-control-prev {
    left: -50px !important;
    width: auto !important;
  }

  .carousel-control-next {
    right: -50px !important;
    width: auto !important;
  }

  .carousel-control-prev .carousel-control-prev-icon,
  .carousel-control-next .carousel-control-next-icon {
    background-color: #aaa;
    padding: 5px;
    box-sizing: content-box;
    border-radius: 50%;
    background-size: 70%;
  }

  .carousel-item {
    height: 400px;
  }

  .carousel-item .img-fluid {
    width: auto !important;
    max-height: 100%;
    margin: 0 auto;
    cursor: pointer;
  }
  .rightBtn {
    float: right;
  }
}
</style>
