<template>
  <div class="content-main">
    <KValidationObserver ref="observer" class="content-page">
      <k-form
          @keydown.enter.prevent
          @submit.prevent="onSubmit"
          class="content-form"
      >
        <div class="content-main">
          <k-alert-msg ref="lblMsg" />
          <div class="content-party">
            <div class="content-group backgroundGray">
              <k-form-group
                  label-class="require-mark"
                  id="input-group-sort"
                  label-suffix="sort"
                  label-for="sort"
                  label-align-md="right"
                  label-cols-md="3"
                  content-cols-lg="6"
                  content-cols-md="7"
              >
                <KValidationProvider
                    :rules="{
                    required: true,
                    max: 3,
                    numberBetween: { minValue: 1, maxValue: 999 },
                    numeric: true,
                    hasFieldUsedKey: {
                      fieldList: origItems,
                      field: $g('order'),
                      keyType: Number,
                      key: 'sort',
                    },
                  }"
                    v-slot="{ errors }"
                    slim
                    name="sort"
                >
                  <k-alert :show="errors.length > 0" variant="warning">
                    {{ errors[0] }}
                  </k-alert>
                  <k-form-input id="input-sort" v-model="form.sort" />
                </KValidationProvider>
              </k-form-group>
              <k-form-group
                  label-class="require-mark"
                  id="input-group-categoryTitle "
                  label-suffix="categoryTitle"
                  label-for="categoryTitle"
                  label-align-md="right"
                  label-cols-md="3"
                  content-cols-lg="6"
                  content-cols-md="7"
              >
                <KValidationProvider
                    :rules="{
                    required: true,
                    max: 200,
                    remote: checkCategoryUsed,
                  }"
                    v-slot="{ errors }"
                    slim
                    name="categoryTitle"
                >
                  <k-alert :show="errors.length > 0" variant="warning">
                    {{ errors[0] }}
                  </k-alert>
                  <k-form-input id="input-categoryTitle" v-model="form.title" />
                </KValidationProvider>
              </k-form-group>
            </div>
          </div>
        </div>
      </k-form>
    </KValidationObserver>
  </div>
</template>
<script>
import {
  getCategory,
  getCheckCategoryUsed,
} from "../../../../api/messageTemplateCategory";
export default {
  components: {},
  props: {
    categoryId: {
      type: String,
      default() {
        return "";
      },
    },
    electionId: {
      type: String,
      default() {
        return "";
      },
    },
    isNotification: {
      type: Boolean,
      default() {
        return false;
      },
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      origItems: [...this.items],
      form: {
        sort: "",
        title: "",
      },
    };
  },
  created() {
    this.buildForm();
  },
  methods: {
    async checkCategoryUsed() {
      let para = {};
      para.title = this.form.title;
      para.id = this.categoryId;
      para.sort = 0;
      para.electionId = this.electionId;
      para.templateType = this.$route.query.templateType;
      para.isNotification = this.isNotification;
      let result = null;
      await getCheckCategoryUsed(para).then((rep) => {
        result = rep;
      });
      return result;
    },
    buildForm() {
      if (this.categoryId) {
        getCategory(this.categoryId).then((r) => {
          this.form = r.data;
          this.origItems = this.origItems.filter(
              (item) => item.id != this.categoryId
          );
          this.origItems.push(this.form);
        });
      } else {
        this.origItems.push(this.form);
      }
    },
    async checkValid() {
      return this.$refs.observer.validate();
    },
    getSearchContent() {
      return this.passwordForm;
    },
  },
};
</script>
