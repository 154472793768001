import Axios from "axios";
import Qs from "qs";
import globalEnv from "../libs/globalEnv";

var cancelToken = Axios.CancelToken;
var cancelSource = cancelToken.source();

/**
 * 解析请求参数
 * @param {Object} config 请求参数
 */
const handleBefore = (config) => {
  return Promise.resolve(config);
};

/**
 * 配置请求参数
 * @param {Object} config 请求参数
 */
const handleDeploy = (config) => {
  if(config.data instanceof (FormData)){
    return;
  }
  const headers = config.headers || {};
  const contentType =
    headers[
      Object.keys(headers).find((e) => e.toLowerCase() === "content-type")
      ];

  if(contentType){
    // axios默认数据格式为JSON，如果后端接收的是表单类型，表单的数据为字符串类型，需要转换为字符串。
    if(contentType.toLowerCase() === "application/x-www-form-urlencoded"){
      config.transformRequest = config.transformRequest instanceof Array ? config.transformRequest : [];
      config.transformRequest.push(function (data){
        return data instanceof Object ? Qs.stringify(data) : data;
      });
    }
  }
};

/**
 * 请求成功
 * @param {Object} config 请求参数
 */
const handleRequestSucced = (config) => {
  return Promise.resolve(config);
};

/**
 * 请求失败
 * @param {Error} error 异常数据
 */
const handleRequestFailed = (error) => {
  return Promise.reject(error);
};

/**
 * 响应成功
 * @param {Object} response 响应数据
 */
const handleResponseSucced = (response) => {
  return Promise.resolve(response);
};

/**
 * 响应失败
 * @param {Error} error 异常数据
 */
const handleResponseFailed = (error) => {
  return Promise.reject(error);
};

/**
 * 当响应类型为二进制流（Blob）时，下载数据
 * @param {Object} response 响应数据
 */
const hanldeResponseBlob = (response) => {
  var extendInfo = response.headers["extend-info"];
  if(extendInfo === "print"){
    return Promise.resolve(response);
  }

  return new Promise((resolve, reject) => {
    hanldeBlob(resolve, reject, response);
  });
};
const hanldeResponseBlobFunc = (headers) => {
  let name = "";
  if(headers["content-disposition"].indexOf("filename*") > 0){
    name = headers["content-disposition"]
      .split(";")[2]
      .split("=UTF-8''")[1]
      .replaceAll("\"", "");
    name = decodeURIComponent(name);
  } else {
    name = headers["content-disposition"]
      .split(";")[1]
      .split("filename=")[1]
      .replaceAll("\"", "");
  }
  return name;
};

const msSaveBlob = (blob, name) => {
  const elink = document.createElement("a");
  elink.download = name;
  elink.style.display = "none";
  elink.href = URL.createObjectURL(blob);
  document.body.appendChild(elink);
  elink.click();
  URL.revokeObjectURL(elink.href);
  document.body.removeChild(elink);
};

const hanldeBlob = (resolve, reject, response) => {
  const content = response.data;
  const headers = response.headers;
  try {
    if(content instanceof Blob){
      const blob = content;
      let name = "";
      name = hanldeResponseBlobFunc(headers);

      if("navigator" in window && "msSaveBlob" in window.navigator){
        navigator.msSaveBlob(blob, name);
      } else if("download" in document.createElement("a")){
        msSaveBlob(blob, name);
      }
      resolve(response);
    } else if(content.type === "application/octet-stream"){
      const blob = new Blob([content]);
      const name = headers["content-disposition"]
        .split(";")[1]
        .split("filename=")[1]
        .replaceAll("\"", "");

      if("navigator" in window && "msSaveBlob" in window.navigator){
        navigator.msSaveBlob(blob, name);
      } else if("download" in document.createElement("a")){
        msSaveBlob(blob, name);
      }
      resolve(response);
    } else if("FileReader" in window){
      let fileReader = new FileReader();
      fileReader.onload = function (){
        response.data = JSON.parse(this.result);
        resolve(response);
      };
      fileReader.readAsText(content);
    } else {
      const error = new Error("Download tool not found");
      error.status = 404;
      error.response = response;
      reject(error);
    }
  } catch (err) {
    let fileReader = new FileReader();
    fileReader.onload = function (){
      response.data = JSON.parse(this.result);
      resolve(response);
    };
    fileReader.readAsText(content);
  }
};

/**
 * 基于axios封装的http库
 */
class AxiosHttp {
  constructor(){
    this.instance = Axios.create();
    this.before = handleBefore;
    this.requestSucced = handleRequestSucced;
    this.requestFailed = handleRequestFailed;
    this.responseSucced = handleResponseSucced;
    this.responseFailed = handleResponseFailed;
    this.responseBlob = hanldeResponseBlob;

    this.interceptors();
  }

  interceptors(){
    // axios请求拦截器
    this.instance.interceptors.request.use(
      (config) => {
        config.cancelToken = cancelSource.token;
        return this._requestSucced(config);
      },
      (error) => {
        return this._requestFailed(error);
      },
    );

    // axios响应拦截器
    this.instance.interceptors.response.use(
      (response) => {
        return this._responseSucced(response);
      },
      (error) => {
        return this._responseFalied(error);
      },
    );
  }

  _requestSucced(config){
    return this.requestSucced(config);
  }

  _requestFailed(error){
    return this.requestFailed(error);
  }

  _responseSucced(response){
    if(response.status === 200){
      if(response.config.responseType === "blob"){
        return this.responseBlob(response)
          .then(() => {
            return this.responseSucced(response);
          })
          .catch((err) => {
            return this.responseFailed(err);
          });
      } else {
        return this.responseSucced(response);
      }
    } else {
      const error = new Error(response.statusText);
      error.status = response.status;
      error.response = response;
      return this.responseFailed(error);
    }
  }

  _responseFalied(error){
    if(error instanceof Axios.Cancel){
      const cancelError = new Error(error.message);
      cancelError.status = 424;
      return this.responseFailed(cancelError);
    } else {
      return this.responseFailed(error);
    }
  }

  request(config){
    return this.before(config || {}).then(() => {
      handleDeploy(config);
      return this.instance(config);
    });
  }

  /**
   * 发起GET请求，读取（Read）数据
   * @param {String} url 请求地址
   * @param {Object} data 请求数据
   * @param {Object} config 配置参数
   */
  get(url, data, config){
    if(config && config.useEoLinker){
      return Axios.get(globalEnv.VUE_APP_EOLINKER_URL + "/mockApi/" + globalEnv.VUE_APP_EOLINKER_PROJECTID + url, {
        params: {
          ...data,
        },
      }).then(response => {
        return Promise.resolve(response.data);
      });
    } else {
      this.paramsTrim(data);
      return this.request(
        Object.assign(
          {
            method: "GET",
            url: url,
            params: data,
          },
          config,
        ),
      );
    }
  }

  /**
   * 发起PUT请求，更新（Update）数据
   * @param {String} url 请求地址
   * @param {Object} data 请求数据
   * @param {Object} config 配置参数
   */
  put(url, data, config){
    this.paramsTrim(data);
    return this.request(
      Object.assign(
        {
          method: "PUT",
          url: url,
          data: data,
        },
        config,
      ),
    );
  }

  // 去掉参数前后空格
  paramsTrim(data){
    if(!data){
      return;
    }
    var entries = Object.entries(data);
    for (const [key, value] of entries) {
      if(typeof value === "string"){
        data[key] = value.trim();
      } else if(value && typeof value === "object"){
        this.paramsTrim(value);
      }
    }
  }

  /**
   * 发起POST请求，创建（Create）数据
   * @param {String} url 请求地址
   * @param {Object} data 请求数据
   * @param {Object} config 配置参数
   */
  post(url, data, config){
    if(config && config.useEoLinker){
      return Axios.post(globalEnv.VUE_APP_EOLINKER_URL + "/mockApi/" + globalEnv.VUE_APP_EOLINKER_PROJECTID + url, Qs.stringify({
        ...data,
      })).then(response => {
        return Promise.resolve(response.data);
      });
    } else {
      this.paramsTrim(data);
      return this.request(
        Object.assign(
          {
            method: "POST",
            url: url,
            data: data,
          },
          config,
        ),
      );
    }
  }

  /**
   * 发起DELETE请求，删除（Delete）数据
   * @param {String} url 请求地址
   * @param {Object} data 请求数据
   * @param {Object} config 配置参数
   */
  delete(url, data, config){
    return this.request(
      Object.assign(
        {
          method: "DELETE",
          url: url,
          data: data,
        },
        config,
      ),
    );
  }

  /**
   * 发起POST请求，提交FormData表单数据
   * @param {String} url 请求地址
   * @param {data / Object} data 请求数据，数据类型为Object时，函数会自动转换为FormData类型
   * @param {Object} config 配置参数
   */
  form(url, data, config){
    let requestData = new FormData();

    if(data instanceof FormData){
      requestData = data;
    } else if(data instanceof Object){
      requestData = Object.keys(data).reduce((ins, propKey) => {
        const propVal = data[propKey];
        if(propVal instanceof Array){
          propVal.forEach((e) => {
            ins.append(propKey, e);
          });
        } else {
          ins.append(propKey, propVal);
        }
        return ins;
      }, requestData);
    }

    return this.request(
      Object.assign(
        {
          method: "POST",
          url: url,
          data: requestData,
          headers: { "Content-Type": "multipart/form-data" },
        },
        config,
      ),
    );
  }

  /**
   * 发起Post请求，响应数据类型为Blob类型，并下载数据
   * @param {String} url 请求地址
   * @param {Object} data 请求数据
   * @param {Object} config 配置参数
   */
  down(url, data, config){
    this.paramsTrim(data);
    return this.request(
      Object.assign(
        {
          method: "POST",
          url: url,
          data: data,
          responseType: "blob",
        },
        config,
      ),
    );
  }

  cancel(message){
    cancelSource.cancel(message || "Interrupt call chain");
    cancelSource = cancelToken.source();
  }
}

export default AxiosHttp;
