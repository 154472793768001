<template>
  <div class="hui-audio">
    <div
      class="hui-audio-toolbar mb-2"
      v-if="!isPlainText"
    >
      <b-button
        @click="onStar"
        size="sm"
        variant="default"
        class="mr-1"
        :disabled="isRecording"
      >
        <b-icon
          v-if="loading"
          icon="arrow-clockwise"
          animation="spin"
          font-scale="1"
          style="color: inherit; cursor: inherit"
          class="align-middle"
        />
        {{ $g("start") }}
      </b-button>
      <b-button
        @click="onStop"
        size="sm"
        variant="default"
        class="mr-1"
        :disabled="!isRecording"
      >
        <b-icon
          v-if="loading"
          icon="arrow-clockwise"
          animation="spin"
          style="color: inherit; cursor: inherit"
          class="align-middle"
        />
        {{ $g("stop") }}
      </b-button>
      <k-button
        @click="onSelect"
        size="sm"
        variant="default"
      >
        select
      </k-button>
      <input
        v-show="false"
        ref="file"
        type="file"
        accept=".mp3,.wav"
        @change="onFileChange()"
      >
    </div>
    <div class="hui-audio-graphic">
      <div class="hui-audio-title">
        <div
          ref="hui-audio-duration"
          v-show="!isPlainText"
        >
          {{ duration }}
        </div>
        <div ref="hui-audio-filename">
          {{ filename }}
        </div>
      </div>
      <!--      <div-->
      <!--        class="hui-audio-waveview"-->
      <!--        v-if="!isPlainText"-->
      <!--      />-->
      <audio
        ref="player"
        style="width: 100%"
        controls
        @timeupdate="onTimeupdate"
      />
    </div>
  </div>
</template>

<script>
import Recorder from "./lib/recorder";
import { convertTimeMMSS } from "./lib/utils";

export default {
  props: {
    value: {
      type: [Object, File, String],
    },
    name: {
      type: String,
      default(){
        return "audio";
      },
    },
    type: {
      type: String,
      default(){
        return "wav";
      },
    },
    bitRate: {
      type: Number,
      default(){
        return 128;
      },
    },
    sampleRate: {
      type: Number,
      default(){
        return 44100;
      },
    },
    isPlainText: {
      type: Boolean,
      default(){
        return false;
      },
    },
    micFailed: {
      type: Function,
      default: null,
    },
    beforeRecording: {
      type: Function,
      default: null,
    },
    pauseRecording: {
      type: Function,
      default: null,
    },
    afterRecording: {
      type: Function,
      default: null,
    },
    maxTime: {
      type: Number,
      default: 600,//600s=20m
    },
  },
  data(){
    return {
      loading: true,
      filename: this.name + "." + this.type,
      recorder: this._initRecorder(),
    };
  },
  watch: {
    value(newValue){
      this.onPlay(newValue);
    },
  },
  methods: {
    define(file){
      this.$emit("input", file);
    },
    onStar(){
      if(this.isRecording){
        return;
      }
      this.recorder.start();
      this.$emit("onStar");
    },
    onStop(){
      if(!this.isRecording){
        return;
      }

      this.recorder.stop();
      let file = new File([this.recorder.lastRecord()[0].blob], this.filename, {
        lastModified: Date.now(),
      });
      this.define(file);
      this.$emit("onStop");
      this.onPlay(file);
    },
    onPlay(data){
      if(data instanceof File){
        this.filename = data.name;
        this.$refs.player.src = (
            window.URL || window.webkitURL
        ).createObjectURL(data);
        return;
      } else if(typeof data === "string"){
        this.filename =
            this.name.indexOf(".") > - 1 ? this.name : this.name + "." + this.type;
        // this.$refs.player.src = 'data:audio/mp3;base64,' + data
        this.$refs.player.src = data;
        return;
      }
      // this.filename = null;
      // this.$refs.player.src = null;
    },
    onSelect(){
      this.$refs.file.click();
    },
    onFileChange(){
      const files = this.$refs.file.files;
      if(files.length > 0){
        this.onPlay(files[0]);
        this.define(files[0]);
      }
    },
    onTimeupdate(){
      return;
    },
    _initRecorder(){
      return new Recorder({
        beforeRecording: this.beforeRecording,
        afterRecording: this.afterRecording,
        pauseRecording: this.pauseRecording,
        micFailed: (error) => {
          this.loading = true;
          console.log(error);
        },
        bitRate: this.bitRate,
        sampleRate: this.sampleRate,
        format: this.type,
      });
    },
  },
  computed: {
    isPause(){
      return this.recorder.isPause;
    },
    isRecording(){
      return this.recorder.isRecording;
    },
    volume(){
      return parseFloat(this.recorder.volume);
    },
    duration(){
      if(this.maxTime && this.recorder.duration >= this.maxTime){
        this.onStop();
      }
      return convertTimeMMSS(this.recorder.duration);
    },
  },
  mounted(){
    this.loading = false;
    this.onPlay(this.value);
  },
  beforeDestroy(){
    this.onStop();
  },
};
</script>

<style lang="scss" scoped>
audio::-webkit-media-controls-panel {
  background-color: #fff;
}

.hui-audio-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #eee;
  margin: 10px 0 10px 0;
}

.hui-audio-waveview {
  width: 100%;
  height: 60px;
  border: 1px solid #eee;
}
</style>
