<template>
  <k-popwindow
    id="pop-insert-label-dialog"
    @ok="onPopSelected"
    :title="$g('InsertLabels ')"
  >
    <slot />
  </k-popwindow>
</template>
<script>
export default {
  data() {
    return {}
  },
  methods: {
    show() {
      this.$bvModal.show('pop-insert-label-dialog')
    },
    onPopSelected(ev) {
      this.$emit('select', ev)
    },    
  },
}
</script>
