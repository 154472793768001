import Moment from "moment-timezone";
import storeage from "../storeage"
export const yobYearUtc = {
    methods: {
        getYobDatepickerUtcTimestamp(date) {
            if (!date) {
                return ""
            }
            let momentNow = Moment(new Date());
            let utcNow = momentNow.utcOffset();
            date = new Date(Moment(date).format("YYYY-01-01 00:00:00"));
            date.setHours(utcNow / 60 + date.getHours());
            return Moment(date).utc().valueOf();
        }
    }
}
export const yobUtcYear = {
    methods: {
        getYobDatepickerUtcYear(d) {
            if (!d) {
                return ""
            }
            let strTemplate = storeage.getSystemGeneralInfo().dateFormat
                ? `${storeage.getSystemGeneralInfo().dateFormat.toUpperCase()} hh:mm:ss A`
                : "MM/DD/YYYY hh:mm:ss A";
            let date = Moment(d, strTemplate);
            let utc = (0 - new Date().getTimezoneOffset()) / 60;
            date.hours(date.hours() + utc)
            return Moment(date).utc().year();
        }
    }
}