<template>
  <div class="form-row content-group m-0 pt-3">
    <b-col xl="3" class="text-xl-right">
      <b-list-group class="personLanguageList">
        <template v-for="item in personLanguages">
          <b-list-group-item
            :active="item.active"
            class="d-flex justify-content-between align-items-center"
            v-if="personLanguages.length > 0"
            @click="onLanguageSwitch(item)"
            :key="item.id"
          >
            <div>
              {{ item.languageName }}
              <span v-if="item.isDefault" class="ml-1 mr-1">
                {{ $g("default") }}
              </span>
            </div>
            <b-icon
              v-if="ShowIsDataIcon"
              icon="check-circle-fill"
              :class="item.isData ? 'success' : 'default'"
            >
            </b-icon>
          </b-list-group-item>
        </template>
      </b-list-group>
    </b-col>
    <b-col xl="9">
      <div class="languageRight">
        <slot name="right" />
      </div>
      <slot name="content" />
    </b-col>
  </div>
</template>

<script>
export default {
  props: {
    ShowIsDataIcon: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    personLanguages: {
      type: Array,
      default: () => {
        return [];
      },
    },
    isShowHr: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    isValidation: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  methods: {
    onLanguageSwitch(item) {
      let a = this.personLanguages.find((c) => c.active);
      if (!a) {
        item.active = true;
        return;
      }
      if (item.id == a.id) {
        return;
      }
      a.active = false;
      item.active = true;
      this.$emit("onLanguageSwitch", item);
    },
    setNewValidation(v, languageId) {
      if (!this.isValidation) {
        return;
      }
      if (!v) {
        return;
      }
      this.personLanguages.forEach((c) => {
        c.active = c.id == languageId;
      });
    },
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
.personLanguageList {
  display: inline-flex;
  width: 250px;
  text-align: left;
  height: calc(100% - 30px - 0.75rem);
  background: #fff;
  border: 1px solid #ddd;
  border-top: 0;
  margin-top: 10px;
  .b-icon.bi {
    &.success {
      color: #008000;
    }
    &.default {
      color: #aaaaaa;
    }
  }
  .list-group-item {
    border-right: none;
    border-left: none;
    &:last-child {
      border-radius: initial;
    }
    &.active,
    &:hover {
      background: #f1f1f1;
      border-color: rgba(0, 0, 0, 0.125);
      color: inherit;
      cursor: pointer;
    }
  }
}
.languageRight {
  button {
    outline: none;
    box-shadow: none;
    padding: 0;
    display: inline-block;
    width: 35px;
    line-height: 35px;
    text-align: center;
    position: absolute;
    right: 10px;
    background: var(--btnGroupIconBcakgroundActive) !important;

    .b-icon.bi {
      color: var(--btnGroupIconFontActive) !important;
    }
    &.active {
      background: #fff !important;
      border: 1px solid #ced4da;
    }
    &:hover {
      background: var(--btnGroupIconBcakgroundActive) !important;
    }
  }
}
::v-deep .search-template.active path {
  stroke: #333;
  stroke-width: 1px;
}
@media (max-width: 1550px) {
  .personLanguageList {
    width: 100%;
  }
}
</style>