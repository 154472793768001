<template>
  <div>
    <div class="form-inline mb-2">
      <k-form-group
        label-suffix="displayFields.fieldName"
        label-for="search-area-name"
      >
        <k-form-input id="search-area-name" v-model="fieldName" class="mr-1" />
      </k-form-group>
      <k-form-group label-suffix="source" label-for="search-description">
        <k-form-input
          id="search-description"
          v-model="description"
          class="mr-1"
        />
        <k-button @click="search"> boundaries.search </k-button>
      </k-form-group>
    </div>
    <konn-grid
      :select-mode="selectMode"
      :has-action="hasAction"
      :fields="fields"
      ref="selectableTable"
      :data-source="dataSource"
      :get-condition="getCondition"
      @row-selected="onRowSelected"
      grid-sort-by="orderIndex"
    />
  </div>
</template>
<script>
import { getReportFieldPage } from "../api/report";

export default {
  data() {
    return {
      fieldName: "",
      description: "",
      selectMode: "multi",
      selected: [],
      hasAction: false,
      fields: [
        {
          key: "displayName",
          label: "category.fieldName",
          sortable: false,
          sortDirection: "desc",
        },
        {
          key: "description",
          label: "source",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    dataSource: function () {
      return getReportFieldPage;
    },
  },
  props: {
    reportTemplateId: {
      type: String,
      default() {
        return "";
      },
    },
    exceptedReportFieldNames: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  methods: {
    onRowSelected(items) {
      this.selected = items;
    },
    getCondition() {
      return {
        displayName: this.fieldName.trim(),
        reportTemplateId: this.reportTemplateId,
        exceptedReportFieldNames: this.exceptedReportFieldNames,
        description: this.description.trim(),
      };
    },
    getSelected() {
      return this.selected;
    },
    getSelectedItems() {
      return this.$refs.selectableTable.getSelectedItems();
    },
    search: function () {
      this.$refs.selectableTable.pageChange(1);
    },
  },
};
</script>
