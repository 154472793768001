<template>
  <b-tabs
    v-model="tabsIndex"
    class="search-area"
  >
    <k-alert-msg
      ref="lblMsg"
      class="mt-1 mb-0"
    />
    <b-tab>
      <template #title>
        <div class="tab-lable">
          {{ $g("search.tabs.template") }}
        </div>
      </template>
      <div class="search-container">
        <b-overlay
          :show="loading.template"
          rounded="sm"
        >
          <div
            v-if="templateList.length <= 0"
            class="noDataImg"
            style="height: 160px"
          >
            <div class="noDataFont">
              {{ $g("search.noDataText") }}
            </div>
          </div>
          <b-list-group-item
            v-for="template in templateList"
            :key="template.id"
            class="d-flex justify-content-between align-items-center"
          >
            <b-link
              class="ellipsis"
              type="primary"
              @click="templateChoose(template)"
            >
              {{ template.templateName }}
            </b-link>
            <span
              v-if="!template.usedInWidget"
              :id="'tipSearch_' + template.id"
              v-b-tooltip="{
                placement: 'bottomleft',
                trigger: 'hover',
                title: $g('search.delete'),
                boundary: 'window',
                container: 'tipSearch_' + template.id,
              }"
            >
              <b-icon
                icon="trash"
                @click="templateChooseDeleteHandler(template)"
                class="template-delete"
              />
            </span>
          </b-list-group-item>
        </b-overlay>
        <b-modal
          v-model="deleteDialogVisible"
          hide-footer
          dialog-class="confirm-dialog"
          :no-close-on-backdrop="true"
        >
          <b-overlay
            :show="loading.deleting"
            rounded="sm"
          >
            <p>{{ $g("search.confirmDelete") }}</p>
            <div class="text-right search-dialog-footer">
              <k-button
                variant="primary"
                @click="deleteTemplateHandler()"
                class="mr-1"
              >
                search.yes
              </k-button>
              <k-button @click="deleteDialogVisible = false">
                search.no
              </k-button>
            </div>
          </b-overlay>
        </b-modal>
      </div>
    </b-tab>
  </b-tabs>
</template>

<script>
import {
  getTemplateList,
  deleteTemplateList,
  getTemplateType,
} from "../../../api/searchTemplate";
import storeage from "../../../utils/storeage";

export default {
  props: {
    typeName: {
      type: String,
      default(){
        return "";
      },
    },
    reportId: {
      type: String,
      default(){
        return "";
      },
    },
    templateChoose: {
      type: Function,
      default(){
        return () => {
        };
      },
    },
    templateDelete: {
      type: Function,
      default(){
        return () => {
        };
      },
    },
    templateSave: {
      type: Function,
      default(){
        return () => {
        };
      },
    },
    isEvent: {
      type: Boolean,
      default: false,
    },
  },
  mounted(){
    this.refreshTemplateList();
  },
  data(){
    return {
      templateType: 0,
      templateList: [],
      selectTemplateValue: "",
      saveTemplate: {
        templateName: "",
      },
      saveDialogVisible: false,
      deleteDialogVisible: false,
      tabsIndex: 0,
      loading: {
        save: false,
        template: true,
        deleting: false,
      },
      deleteTemplateId: 0,
      electionid: storeage.getElectionId(),
    };
  },
  methods: {
    refreshTemplateList(){
      this.getTemplateListByTemplateName();
    },
    getTemplateListByTemplateName(){
      if(this.typeName){
        let condition = {
          typeName: this.typeName,
        };
        getTemplateType(condition).then((res) => {
          const { data } = res;
          this.templateType = data;

          this.getTemplateListByTemplateType();
        });
      }
    },
    getTemplateListByTemplateType(afterSave){
      this.loading.template = true;
      let model = {
        templateType: this.templateType,
        electionid: this.isEvent ? this.electionid : "",
        reportId: this.reportId,
      };
      getTemplateList(model).then((res) => {
        const { data } = res;
        this.templateList = data;
        for (const template of this.templateList) {
          if(template.templateName == this.saveTemplate.templateName){
            this.selectTemplateValue = template.id;
            break;
          }
        }
        this.saveTemplate.templateName = "";
        this.loading.template = false;
        if(afterSave === 1){
          this.templateSave(this.templateList[0].id);
          this.templateChoose(this.templateList[0]);
        }
      });
    },
    templateChooseDeleteHandler(template){
      this.deleteTemplateId = template.id;
      this.deleteDialogVisible = true;
    },
    deleteTemplateHandler(){
      this.loading.template = true;
      this.loading.deleting = true;
      const id = this.deleteTemplateId;
      var self = this;
      deleteTemplateList(id).then((res) => {
        this.$refs.lblMsg.message(res.data.status, res.message);
        this.deleteDialogVisible = false;
        this.loading.deleting = false;
        this.templateDelete(id);
        self.getTemplateListByTemplateType();
      });
    },
  },
};
</script>
<style scoped>
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
