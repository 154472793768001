<template>
  <div class="electionbar">
    <div
      class="mr-1"
    >
      {{ $g("electionSelect.event") + ":" }}
    </div>
    <k-form-select
      v-model="electionId"
      :options="electionList"
      :plaintext="plaintext"
      @change="onChangeElection"
    />
  </div>
</template>
<script>
import { getElections } from "./election";
import storeage from "../../utils/storeage";

export default {
  props: {
    plaintext: {
      type: Boolean,
      default () {
        return true;
      },
    },
  },
  data () {
    return {
      electionId: storeage.getElectionId(),
      electionList: [],
    };
  },
  mounted () {
    this.bindElections();
  },
  methods: {
    bindElections: function () {
      getElections().then((data) => {
        const items = data.data || [];
        this.electionList = items.filter((item) => item.value);

        if (this.electionList && this.electionList.length > 0) {
          if (this.electionId) {
            let array = this.electionList.filter((item) => this.electionId == item.value);
            if (array && array.length === 0) {
              this.setDefaultElectionId();
            }
          } else {
            this.setDefaultElectionId();
          }
        } else {
          this.onChangeElection("");
        }
      });
    },
    setDefaultElectionId () {
      this.electionList.forEach((item) => {
        if (item.isDefault) {
          this.electionId = item.value;
        }
      });
      if (!this.electionId) {
        this.electionId = this.electionList[0].value;
      }
      this.onChangeElection(this.electionId);
    },
    onChangeElection (val) {
      storeage.setElectionId(val);
      this.$emit("onElectionChanged", val);
    },
  },
};
</script>
