let timeout;
import { getSysMoudleLog, saveFeError } from "../api/app";

export function debounce(func, wait = 300, immediate = false) {
  if (timeout !== null) clearTimeout(timeout);
  if (immediate) {
    let callNow = !timeout;
    timeout = setTimeout(function () {
      timeout = null;
    }, wait);
    if (callNow) typeof func === "function" && func();
  } else {
    timeout = setTimeout(function () {
      typeof func === "function" && func();
    }, wait);
  }
}

export const saveMoudleLog = (to, from) => {
  try {
    getSysMoudleLog({
      "formRoute": from.name,
      "formPath": from.path,
      "formQuery": JSON.stringify(from.query),
      "formParam": JSON.stringify(from.params),
      "toRoute": to.name,
      "toPath": to.path,
      "toQuery": JSON.stringify(to.query),
      "toParam": JSON.stringify(to.params),
    });
  } catch (error) {
    console.warn(error);
  }
};

export const handlePath = (pathStr) => {
  let lastStr = pathStr.substr(pathStr.length - 1, 1);
  if (lastStr === "/") {
    pathStr = pathStr.substr(0, pathStr.length - 1);
  }
  return pathStr;
};

export const postProjectError = {
  install(Vue) {
    try {
      Vue.config.errorHandler = (err) => {
        const {
          message,
          name,
          script,
          line,
          column,
          stack,
        } = err;
        captureError({
          name, errorMessage: message, scriptURI: script, lineNo: line, columnNo: column, error: stack,
        });
      };

      window.addEventListener("error", function (event) {
        captureError({
          name: event.type,
          errorMessage: event.message,
          scriptURI: event.scripturi,
          lineNo: event.lineno,
          columnNo: event.colno,
          filename: event.filename,
        });
      });

      window.addEventListener("unhandledrejection", (event) => {
        if (event.reason) {
          captureError({
            name: event.type,
            errorMessage: String(event.reason),
            filename: event.currentTarget?.location?.pathname,
          });
        }
      });

      function captureError(err) {
        const type = err.name || "noType";
        const message = err.errorMessage || "noMessage";
        const stack = err.error ? JSON.stringify(err.error) : "noStack";
        const controlerAction = err.filename || "";
        saveFeError({
          "message": message,
          "name": type,
          "controlerAction": controlerAction,
          "script": err.script,
          "line": err.lineNo,
          "column": err.columnNo,
          "stack": stack,
        });
      }
    } catch (error) {
      console.warn(error);
    }
  },
};
export const getCurPageConfigbyRoutePath = (projectName, pathStr) => {
  let lastStr = pathStr.substr(pathStr.length - 1, 1);
  if (lastStr === "/") {
    pathStr = pathStr.substr(0, pathStr.length - 1);
  }
  let curPageConfig;
  if (window.pageConfig && window.pageConfig[projectName]) {
    curPageConfig = window.pageConfig[projectName][pathStr];
  }
  if (curPageConfig) {
    curPageConfig = JSON.parse(JSON.stringify(curPageConfig));
  }
  return curPageConfig;
};
export const getCurPageConfigAndDefaultPageConfigbyRoutePath = (projectName, pathStr) => {
  let lastStr = pathStr.substr(pathStr.length - 1, 1);
  if (lastStr === "/") {
    pathStr = pathStr.substr(0, pathStr.length - 1);
  }
  let curPageConfigAndDefaultPageConfig = {};
  if (window.pageConfig && window.pageConfig[projectName]) {
    curPageConfigAndDefaultPageConfig.pageConfig = window.pageConfig[projectName][pathStr];
  }
  if (window.defaultPageConfig && window.defaultPageConfig[projectName]) {
    curPageConfigAndDefaultPageConfig.defaultPageConfig = window.defaultPageConfig[projectName][pathStr];
  }
  if (curPageConfigAndDefaultPageConfig) {
    curPageConfigAndDefaultPageConfig = JSON.parse(JSON.stringify(curPageConfigAndDefaultPageConfig));
  }
  return curPageConfigAndDefaultPageConfig;
};
export const getConfigVal = (curPageConfigAndDefaultPageConfig, moduleId, order) => {
  if (!curPageConfigAndDefaultPageConfig.defaultPageConfig) {
    throw new TypeError(`defaultPageConfig is underfind`)
  }
  let val;
  if (curPageConfigAndDefaultPageConfig?.pageConfig && curPageConfigAndDefaultPageConfig?.defaultPageConfig) {
    let obj = curPageConfigAndDefaultPageConfig?.pageConfig[moduleId];
    if (obj && (obj[order]) != null && (obj[order]) != "undefined") {
      val = curPageConfigAndDefaultPageConfig?.pageConfig[moduleId][order];
    } else {
      if (!curPageConfigAndDefaultPageConfig?.defaultPageConfig[moduleId]) {
        throw new TypeError(`${moduleId} is not in defaultPageConfig`)
      }
      val = curPageConfigAndDefaultPageConfig?.defaultPageConfig[moduleId][order];

    }
  } else if (curPageConfigAndDefaultPageConfig?.defaultPageConfig) {
    val = curPageConfigAndDefaultPageConfig?.defaultPageConfig[moduleId][order];

  }
  return val;
};
