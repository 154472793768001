<template>
  <detail
    :is-event="isEvent"
    :is-plain-text="false"
    :id="id"
    @save-successful="onSaveSuccessful"
    @label-select="onLabelSelect"
    ref="detail"
    :has-search-category="hasSearchCategory"
    :expand-form="expandForm"
    :is-notification="isNotification"
    :message-template-detail-content-content-editor-required="
      MessageTemplateDetailContentSubjectRequired
    "
    :message-template-detail-content-subject-required="
      MessageTemplateDetailContentSubjectRequired
    "
  >
    <template #formBar>
      <div>
        <k-button-back
          :back-route="getBackRoute()"
          :back-query="{
            tabindex: $route.query.tabindex,
            templateType:$route.query.templateType
          }"
        >
          buttons.back
        </k-button-back>
        <k-button
          type="submit"
          variant="primary"
          class="mr-1"
          ref="btnSave"
        >
          buttons.save
        </k-button>
        <k-button
          @click="onRedirectView"
          v-show="$route.query.id != null"
        >
          buttons.view
        </k-button>
        <k-pre-next
          ref="pn"
          :has-save-next="templateEdit"
          :hidden-right-btn="!templateEdit"
          :before-to-leave="preNextToLeave"
        />
      </div>
    </template>
    <template #formBarNav>
      <k-alert-msg ref="lblMsg" />
      <template-nav
        ref="nav"
        :tab-index="navIndex"
        :is-plain-text="false"
        :is-event="isEvent"
        :is-notification="isNotification"
        :show-edit="true"
      />
    </template>
    <template #insertLabel>
      <slot
        name="insertLabel"
        :dynamicInsertLabels="dynamicInsertLabels"
      />
    </template>

    <template #formGroup="{ form, plaintext, templateType, enumTemplateType }">
      <slot
        name="formGroup"
        :form="form"
        :plaintext="plaintext"
        :templateType="templateType"
        :enumTemplateType="enumTemplateType"
      />
    </template>

    <template
      #smsInsertLabel="{ form }"
      :form="form"
    >
      <slot
        name="smsInsertLabel"
        :form="form"
      />
    </template>
  </detail>
</template>
<script>
import detail from "./components/messageTemplateDetail.vue";
import { repResult } from "../../../../libs/global";
import templateNav from "../../components/template-nav.vue";
import { saveAlert } from "../../../../utils/saveAlert";
import { beforeRouteLeaveMixin } from "../../../../utils/mixins/mixin";
import storeage from "../../../../utils/storeage";
import { getInsertLabels } from "../../api/message";
import { permissionCode } from "../../mixins/permissionCode";
import { enumTemplateType } from "../../../../business-components/common-massage/enums/enumTemplateType";
import { has } from "../../../../utils/permission/operatePermission";

export default {
  props: {
    expandForm: {
      type: Array,
      default() {
        return [];
      },
    },
    hasSearchCategory: {
      type: Boolean,
      default() {
        return true;
      },
    },
    MessageTemplateDetailContentSubjectRequired: {
      type: Boolean,
      default: false,
    },
    MessageTemplateDetailContentContentEditorRequired: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [beforeRouteLeaveMixin, permissionCode],
  components: { detail, templateNav },
  data() {
    let isEvent = this.$route.meta.isEvent;
    return {
      isEvent: isEvent,
      id: this.$route.query.id ?? "00000000-0000-0000-0000-000000000000",
      electionId: isEvent ? storeage.getElectionId() : "",
      navIndex: 0,
      dynamicInsertLabels: [],
      templateEdit: true,
    };
  },
  created() {
    this.setPermission();
    this.initInsertLabels();
  },
  methods: {
    setPermission() {
      if (!this.$route.query.templateType) {
        this.templateEdit = false;
        return;
      }
      let permissionCodeNow = this.isEvent
        ? this.permissionCodeEvent?.Communication
        : this.permissionCode?.Communication;
      switch (parseInt(this.$route.query.templateType)) {
        case enumTemplateType.email:
          this.templateEdit = has(permissionCodeNow?.EmailTemplateEdit);
          break;
        case enumTemplateType.phone:
          this.templateEdit = has(permissionCodeNow?.PhoneTemplateEdit);
          break;
        case enumTemplateType.sms:
          this.templateEdit = has(permissionCodeNow?.SMSTemplateEdit);
          break;
        case enumTemplateType.letter:
          this.templateEdit = has(permissionCodeNow?.LetterTemplateEdit);
          break;
        case enumTemplateType.documentation:
        default:
          break;
      }
    },
    getBackRoute() {
      let routeName = "";
      if (this.isNotification) {
        routeName = this.isEvent ? "eleNotification" : "notification";
      } else {
        routeName = this.isEvent ? "eleMessageTemplate" : "messageTemplate";
      }
      return routeName;
    },
    initInsertLabels() {
      if (!this.isNotification) {
        getInsertLabels().then((res) => {
          this.dynamicInsertLabels = res.data;
        });
      }
    },
    onRedirectView() {
      let routeName = "";
      if (this.isNotification) {
        routeName = this.isEvent ? "eleNotificationView" : "notificationView";
      } else {
        routeName = this.isEvent
          ? "eleMessageTemplateView"
          : "messageTemplateView";
      }
      this.$router.push({
        name: routeName,
        query: {
          id: this.$route.query.id,
          isView: "1",
          hidePreNext: this.$route.query.hidePreNext,
          dbtn: this.$route.query.dbtn,
          templateType: this.$route.query.templateType,
          tabindex: this.$route.query.tabindex,
        },
      });
    },
    onSaveSuccessful(m) {
      this.isDoSave = true;
      if (this.$refs.pn.checkSaveNexted()) {
        this.$refs.pn.goToNext(m);
      } else {
        let routeName = "";
        if (this.isNotification) {
          routeName = this.isEvent ? "eleNotificationView" : "notificationView";
        } else {
          routeName = this.isEvent
            ? "eleMessageTemplateView"
            : "messageTemplateView";
        }
        this.$router.push({
          name: routeName,
          query: {
            id: m.data.info,
            isView: "1",
            hidePreNext: this.$route.query.hidePreNext,
            dbtn: this.$route.query.dbtn,
            templateType: this.$route.query.templateType,
            tabindex: this.$route.query.tabindex,
          },
          params: {
            message: m.message,
            status: m.data.status,
          },
        });
      }
    },
    showAlert() {
      let status = saveAlert.savedStatus(this.$route);
      if (status === repResult.success) {
        this.$refs.lblMsg.message(status, saveAlert.savedMessage(this.$route));
      }
    },
    onLabelSelect() {
      this.$emit("label-select");
    },
    insertHtml(val) {
      this.$refs.detail.insertHtml(val);
    },
    beforeRouteLeave(to, from, next) {
      if (!this.isDoSave && this.$refs.detail.isDataChanges()) {
        this.$confirm({
          title: this.$g("const.confirm.confirm"),
          content: this.$g("const.confirm.leaveComfirm"),
          cancelType: "secondary",
          okText: this.$g("const.confirm.leaveComfirmYes"),
          cancelText: this.$g("const.confirm.no"),
        })
          .then(() => {
            next();
          })
          .catch(() => {
            //nav maybe in view or detialview
            this.$refs.detail.navToBack();
            this.navToBack();
          });
      } else {
        next();
      }
    },
  },
  mounted() {
    this.showAlert();
  },
};
</script>
