<template>
  <div>
    <k-form-group
      label-suffix="address.googleAddressSearch"
      label-for="noneBind"
      label-align-md="left"
      label-cols-md="3"
      v-if="!isPlainText && !innerAddress.isSame"
    >
      <google-autocomplete
        :id="getId('googleAddressSearch')"
        :placeholder="$g('address.googleAddressSearchPlaceholder')"
        @placechanged="placeChanged"
        ref="googleAddressSearch"
      />
    </k-form-group>
    <k-form-group
      label-suffix="address.unit"
      label-for="noneBind"
      label-align-md="left"
      label-cols-md="3"
      v-if="isShow('Unit')"
    >
      <KValidationProvider
        rules="max:50"
        v-slot="{ errors }"
        slim
        :name="getId('unit')"
      >
        <k-alert :show="errors.length > 0" variant="warning">
          {{ errors[0] }}
        </k-alert>
        <k-form-input
          :id="getId('unit')"
          v-model="innerAddress.unit"
          :plaintext="isPlainText"
          :disabled="innerAddress.isSame"
          :aria-label="$g('address.unit')"
          @blur="subdivisionAndBoundaryAddressFormBlurEvent"
        />
      </KValidationProvider>
    </k-form-group>
    <k-form-group
      :label-class="needValidate ? 'require-mark' : ''"
      label-suffix="address.street#"
      label-for="noneBind"
      label-align-md="left"
      label-cols-md="3"
      v-if="isShow('Street Number')"
    >
      <KValidationProvider
        :rules="{ max: 50, required: needValidate }"
        v-slot="{ errors }"
        slim
        :name="getId('streetCharp')"
      >
        <k-alert :show="errors.length > 0" variant="warning">
          {{ errors[0] }}
        </k-alert>
        <k-form-input
          :id="getId('streetNumber')"
          v-model="innerAddress.streetNumber"
          :plaintext="isPlainText"
          :disabled="innerAddress.isSame"
          :aria-label="$g('address.street#')"
          @blur="subdivisionAndBoundaryAddressFormBlurEvent"
        />
      </KValidationProvider>
    </k-form-group>
    <k-form-group
      label-suffix="address.streetFraction"
      label-for="noneBind"
      label-align-md="left"
      label-cols-md="3"
      v-if="isShow('Street Fraction')"
    >
      <KValidationProvider
        rules="max:200"
        v-slot="{ errors }"
        slim
        :name="getId('streetFraction')"
      >
        <k-alert :show="errors.length > 0" variant="warning">
          {{ errors[0] }}
        </k-alert>
        <k-form-input
          :id="getId('streetFraction')"
          v-model="innerAddress.streetFraction"
          :plaintext="isPlainText"
          :disabled="innerAddress.isSame"
          :aria-label="$g('address.streetFraction')"
          @blur="subdivisionAndBoundaryAddressFormBlurEvent"
        />
      </KValidationProvider>
    </k-form-group>
    <k-form-group
      label-suffix="address.streetPre"
      label-for="noneBind"
      label-align-md="left"
      label-cols-md="3"
      v-if="isShow('Street Pre')"
    >
      <k-form-select
        :id="getId('streetPre')"
        v-model="innerAddress.streetPre"
        :plaintext="isPlainText"
        :options="streetPosts"
        :disabled="innerAddress.isSame"
        :aria-label="$g('address.streetPre')"
        @blur="subdivisionAndBoundaryAddressFormBlurEvent"
      />
    </k-form-group>
    <k-form-group
      :label-class="needValidate ? 'require-mark' : ''"
      label-suffix="address.streetName"
      label-for="noneBind"
      label-align-md="left"
      label-cols-md="3"
      v-if="isShow('Street Name')"
    >
      <KValidationProvider
        :rules="{ max: 200, required: needValidate }"
        v-slot="{ errors }"
        slim
        :name="getId('streetName')"
      >
        <k-alert :show="errors.length > 0" variant="warning">
          {{ errors[0] }}
        </k-alert>
        <k-form-input
          :placeholder="streetNamePlaceholder ? streetNamePlaceholder : null"
          :id="getId('streetName')"
          v-model="innerAddress.streetName"
          :plaintext="isPlainText || nonEditableFields['ResidentialStreetName']"
          :disabled="innerAddress.isSame"
          :aria-label="$g('address.streetName')"
          @blur="subdivisionAndBoundaryAddressFormBlurEvent"
        />
      </KValidationProvider>
    </k-form-group>
    <k-form-group
      label-suffix="address.streetType"
      label-for="noneBind"
      label-align-md="left"
      label-cols-md="3"
      v-if="isShow('Street Type')"
    >
      <k-form-select
        :id="getId('streetType')"
        v-model="innerAddress.streetType"
        :plaintext="isPlainText"
        :options="streetTypes"
        :disabled="innerAddress.isSame"
        :aria-label="$g('address.streetType')"
        @blur="subdivisionAndBoundaryAddressFormBlurEvent"
      />
    </k-form-group>
    <k-form-group
      label-suffix="address.streetPost"
      label-for="noneBind"
      label-align-md="left"
      label-cols-md="3"
      v-if="isShow('Street Post')"
    >
      <k-form-select
        :id="getId('streetPost')"
        v-model="innerAddress.streetPost"
        :plaintext="isPlainText"
        :options="streetPosts"
        :disabled="innerAddress.isSame"
        :aria-label="$g('address.streetPost')"
        @blur="subdivisionAndBoundaryAddressFormBlurEvent"
      />
    </k-form-group>
    <k-form-group
      label-suffix="address.building"
      label-for="noneBind"
      label-align-md="left"
      label-cols-md="3"
      v-if="isShow('Building')"
    >
      <KValidationProvider
        rules="max:200"
        v-slot="{ errors }"
        slim
        :name="getId('building')"
      >
        <k-alert :show="errors.length > 0" variant="warning">
          {{ errors[0] }}
        </k-alert>
        <k-form-input
          :id="getId('building')"
          v-model="innerAddress.building"
          :plaintext="isPlainText"
          :disabled="innerAddress.isSame"
          :aria-label="$g('address.building')"
          @blur="subdivisionAndBoundaryAddressFormBlurEvent"
        />
      </KValidationProvider>
    </k-form-group>
    <k-form-group
      label-suffix="address.apartment"
      label-for="noneBind"
      label-align-md="left"
      label-cols-md="3"
      v-if="isShow('Apartment')"
    >
      <KValidationProvider
        rules="max:200"
        v-slot="{ errors }"
        slim
        :name="getId('apartment')"
      >
        <k-alert :show="errors.length > 0" variant="warning">
          {{ errors[0] }}
        </k-alert>
        <k-form-input
          :id="getId('apartment')"
          v-model="innerAddress.apartment"
          :plaintext="isPlainText"
          :disabled="innerAddress.isSame"
          :aria-label="$g('address.apartment')"
          @blur="aptAddressBlur"
        />
      </KValidationProvider>
    </k-form-group>
    <k-form-group
      :label-class="needValidate ? 'require-mark' : ''"
      label-suffix="address.city"
      label-for="noneBind"
      label-align-md="left"
      label-cols-md="3"
      v-if="isShow('City')"
    >
      <KValidationProvider
        :rules="{ max: 200, required: needValidate }"
        v-slot="{ errors }"
        slim
        :name="getId('city')"
      >
        <k-alert :show="errors.length > 0" variant="warning">
          {{ errors[0] }}
        </k-alert>
        <k-form-input
          :id="getId('city')"
          v-model="innerAddress.city"
          :plaintext="isPlainText"
          :disabled="innerAddress.isSame"
          :aria-label="$g('address.city')"
          @blur="subdivisionAndBoundaryAddressFormBlurEvent"
        />
      </KValidationProvider>
    </k-form-group>
    <k-form-group
      :label-class="needValidate ? 'require-mark' : ''"
      label-suffix="address.state"
      label-for="noneBind"
      label-align-md="left"
      label-cols-md="3"
      v-if="isShow('State')"
    >
      <KValidationProvider
        :rules="{ required: needValidate }"
        v-slot="{ errors }"
        slim
        :name="getId('state')"
        mode="lazy"
      >
        <k-alert :show="errors.length > 0" variant="warning">
          {{ errors[0] }}
        </k-alert>
        <k-form-select
          :id="getId('state')"
          v-model="innerAddress.state"
          :plaintext="isPlainText"
          :options="stateTypes"
          :disabled="innerAddress.isSame"
          :aria-label="$g('address.state')"
          @blur="subdivisionAndBoundaryAddressFormBlurEvent"
        />
      </KValidationProvider>
    </k-form-group>
    <k-form-group
      :label-class="needValidate ? 'require-mark' : ''"
      label-suffix="address.postCode"
      label-for="noneBind"
      label-align-md="left"
      label-cols-md="3"
      v-if="isShow('Postal Code')"
    >
      <KValidationProvider
        :rules="{ max: 50, required: needValidate }"
        v-slot="{ errors }"
        slim
        :name="getId('postcode')"
      >
        <k-alert :show="errors.length > 0" variant="warning">
          {{ errors[0] }}
        </k-alert>
        <k-form-input
          :id="getId('postcode')"
          v-model="innerAddress.postcode"
          :plaintext="isPlainText"
          :disabled="innerAddress.isSame"
          :aria-label="$g('address.postCode')"
          @blur="subdivisionAndBoundaryAddressFormBlurEvent"
        />
      </KValidationProvider>
    </k-form-group>
    <k-form-group
      label-suffix="address.country"
      label-for="noneBind"
      label-align-md="left"
      label-cols-md="3"
      v-if="isShow('Country')"
    >
      <KValidationProvider
        rules="max:200"
        v-slot="{ errors }"
        slim
        :name="getId('country')"
      >
        <k-alert :show="errors.length > 0" variant="warning">
          {{ errors[0] }}
        </k-alert>
        <k-form-input
          :id="getId('country')"
          v-model="innerAddress.country"
          :plaintext="isPlainText"
          :disabled="innerAddress.isSame"
          :aria-label="$g('address.country')"
          @blur="subdivisionAndBoundaryAddressFormBlurEvent"
        />
      </KValidationProvider>
    </k-form-group>
  </div>
</template>
<script>
import { cityRules as regRules } from "../../k-components/validate/cityRules.js";

export default {
  props: {
    identifier: {
      type: String,
      default() {
        return "";
      },
    },
    address: {
      type: Object,
      default() {
        return {};
      },
    },
    streetTypes: {
      type: Array,
      default() {
        return [];
      },
    },
    stateTypes: {
      type: Array,
      default() {
        return [];
      },
    },
    streetPosts: {
      type: Array,
      default() {
        return [];
      },
    },
    isPlainText: {
      type: Boolean,
      default() {
        return false;
      },
    },
    addressformat: {
      type: String,
      default() {
        return "$Street Number$ $Street Fraction$ $Street Pre$ $Street Name$ $Street Type$ $City$ $State$ $Postal Code$";
      },
    },
    validate: {
      type: Boolean,
      default() {
        return true;
      },
    },
    fullAddressValidate: {
      type: Boolean,
      default() {
        return false;
      },
    },
    streetNamePlaceholder: {
      type: String,
      default() {
        return "";
      },
    },
    nonEditableFields: {
      type: Object,
      default() {
        return {
          ResidentialStreetName: false,
        };
      },
    },
    aptAddressFormBlurEvent: {
      type: Function,
      default() {
        return () => {};
      },
    },
    subdivisionAndBoundaryAddressFormBlurEvent: {
      type: Function,
      default() {
        return () => {};
      },
    },
  },
  computed: {
    needValidate() {
      if (this.validate) return true;
      if (this.fullAddressValidate) {
        return this.checkFullAddressValidate();
      }
      return false;
    },
  },
  data() {
    let address = {
      unit: "",
      streetNumber: "",
      streetName: "",
      streetType: "",
      city: "",
      state: "",
      postcode: "",
      country: "",
      addressLine1: "",
      addressLine2: "",
      addressLine3: "",
      streetFraction: "",
      streetPre: "",
      streetPost: "",
      building: "",
      apartment: "",
    };
    if (this.address) address = this.address;
    return {
      innerAddress: address,
    };
  },
  methods: {
    subdivisionAndBoundaryAddressFormChangeEvent() {},
    aptAddressBlur() {
      this.subdivisionAndBoundaryAddressFormBlurEvent();
      this.aptAddressFormBlurEvent();
    },
    postcodechangeHandler(postcode) {
      if (regRules.postcode.reg.exec(postcode)) {
        let strNumber = "";
        for (let i = 0; i < postcode.length; i++) {
          if (postcode[i] != " " && postcode[i] != "-") {
            strNumber += postcode.substring(i, i + 1);
          }
        }

        let formatVal = regRules.postcode.formatfun(strNumber);
        if (formatVal) {
          this.innerAddress.postcode = formatVal;
        }
      }
    },
    placeChanged(place) {
      this.innerAddress.buildingName = place.buildingName;
      this.innerAddress.unit = place.unitNumber;
      this.innerAddress.streetNumber = place.streetNumber;
      this.innerAddress.streetFraction = "";
      this.innerAddress.streetPre = place.streetPre;
      this.innerAddress.streetName = place.streetName;
      if (this.streetTypes.find((it) => it?.value == place.streetType)) {
        this.innerAddress.streetType = place.streetType;
      } else if (
        this.streetTypes.find((it) => it?.relateContent == place.streetType)
      ) {
        this.innerAddress.streetType = this.streetTypes.find(
          (it) => it?.relateContent == place.streetType
        ).value;
      } else {
        this.innerAddress.streetType = "";
      }
      this.innerAddress.streetPost = place.streetPost;
      this.innerAddress.building = place.buildingName;
      this.innerAddress.apartment = place.unitNumber;
      this.innerAddress.city = place.locality;
      this.innerAddress.state = place.state;
      this.innerAddress.postcode = place.postalcode;
      this.innerAddress.addressLine1 = "";
      this.innerAddress.addressLine2 = "";
      this.innerAddress.addressLine3 = "";
      this.innerAddress.overseaCountry = "";
      this.innerAddress.country = place.country;
      this.$emit("googlePlaceChanged", place);
      this.subdivisionAndBoundaryAddressFormBlurEvent();
      this.aptAddressFormBlurEvent();
    },
    clearAddress() {
      this.innerAddress.unit = "";
      this.innerAddress.streetNumber = "";
      this.innerAddress.streetName = "";
      this.innerAddress.streetType = "";
      this.innerAddress.city = "";
      this.innerAddress.state = "";
      this.innerAddress.postcode = "";
      this.innerAddress.country = "";
      this.innerAddress.addressLine1 = "";
      this.innerAddress.addressLine2 = "";
      this.innerAddress.addressLine3 = "";
      this.innerAddress.overseaCountry = "";
      this.innerAddress.streetFraction = "";
      this.innerAddress.streetPre = "";
      this.innerAddress.streetPost = "";
      this.innerAddress.building = "";
      this.innerAddress.apartment = "";
      if (this.$refs.googleAddressSearch) {
        this.$refs.googleAddressSearch.clear();
      }
    },
    maxLenth(val, obj, length, oldVal) {
      if (val && val.length > length) {
        this.$nextTick(() => {
          this["innerAddress"][obj] = oldVal;
        });
      }
    },
    getId(element) {
      return element + this.identifier;
    },
    isShow(fieldName) {
      if (this.addressformat) return this.addressformat.indexOf(fieldName) > 0;

      return false;
    },
    getAddressNative() {
      return this.innerAddress;
    },
    checkFullAddressValidate() {
      let isUnit = this.isShow("Unit") && this.innerAddress.unit;
      let isStreetNumber =
        this.isShow("Street Number") && this.innerAddress.streetNumber;
      let isStreetFraction =
        this.isShow("Street Fraction") && this.innerAddress.streetFraction;
      let isStreetPre =
        this.isShow("Street Pre") && this.innerAddress.streetPre;
      let isStreetName =
        this.isShow("Street Name") && this.innerAddress.streetName;
      let isStreetType =
        this.isShow("Street Type") && this.innerAddress.streetType;
      let isStreetPost =
        this.isShow("Street Post") && this.innerAddress.streetPost;
      let isBuilding =
        this.isShow("Street Building") && this.innerAddress.building;
      let isApartment = this.isShow("Apartment") && this.innerAddress.apartment;
      let isCity = this.isShow("City") && this.innerAddress.city;
      let isState = this.isShow("State") && this.innerAddress.state;
      let isPostcode = this.isShow("Postal Code") && this.innerAddress.postcode;
      let isCountry = this.isShow("Country") && this.innerAddress.country;
      let result =
        isUnit ||
        isStreetNumber ||
        isStreetFraction ||
        isStreetPre ||
        isStreetName ||
        isStreetType ||
        isStreetPost ||
        isBuilding ||
        isApartment ||
        isCity ||
        isState ||
        isPostcode ||
        isCountry;
      return result ? true : false;
    },
  },
  mounted() {
    if (!this.isPlainText) {
      if (
        this.streetTypes != null &&
        this.streetTypes.length > 0 &&
        this.streetTypes.find(
          (it) => it?.value == this.innerAddress.streetType
        ) == null
      ) {
        this.innerAddress.streetType = "";
      }
      if (
        this.stateTypes != null &&
        this.stateTypes.length > 0 &&
        this.stateTypes.find((it) => it?.value == this.innerAddress.state) ==
          null
      ) {
        this.innerAddress.state = "";
      }
      if (
        this.streetPosts != null &&
        this.streetPosts.length > 0 &&
        this.streetPosts.find(
          (it) => it?.value == this.innerAddress.streetPost
        ) == null
      ) {
        this.innerAddress.streetPost = "";
      }
      if (
        this.streetPosts != null &&
        this.streetPosts.length > 0 &&
        this.streetPosts.find(
          (it) => it?.value == this.innerAddress.streetPre
        ) == null
      ) {
        this.innerAddress.streetPre = "";
      }
    }
  },
  watch: {
    address: function (newAddress) {
      this.innerAddress = newAddress;
      if (!this.isPlainText) {
        if (
          this.streetTypes != null &&
          this.streetTypes.length > 0 &&
          this.streetTypes.find(
            (it) => it?.value == this.innerAddress.streetType
          ) == null
        ) {
          this.innerAddress.streetType = null;
        }
        if (
          this.stateTypes != null &&
          this.stateTypes.length > 0 &&
          this.stateTypes.find((it) => it?.value == this.innerAddress.state) ==
            null
        ) {
          this.innerAddress.state = "";
        }
        this.stateTypes.forEach((item) => {
          if (item.isDefault && !this.innerAddress.state) {
            this.innerAddress.state = item.value;
          }
        });
        if (
          this.streetPosts != null &&
          this.streetPosts.length > 0 &&
          this.streetPosts.find(
            (it) => it?.value == this.innerAddress.streetPost
          ) == null
        ) {
          this.innerAddress.streetPost = "";
        }
        if (
          this.streetPosts != null &&
          this.streetPosts.length > 0 &&
          this.streetPosts.find(
            (it) => it?.value == this.innerAddress.streetPre
          ) == null
        ) {
          this.innerAddress.streetPre = "";
        }
      }
    },
    innerAddress: {
      handler: function () {
        this.$emit("addressChanged");
      },
      deep: true,
    },
    stateTypes() {
      this.stateTypes.forEach((item) => {
        if (item.isDefault && !this.innerAddress.state) {
          this.innerAddress.state = item.value;
        }
      });
    },
    // "innerAddress.unit": function (val, oldVal) {
    //   this.maxLenth(val, "unit", 40, oldVal);
    // },
    // "innerAddress.streetNumber": function (val, oldVal) {
    //   this.maxLenth(val, "streetNumber", 40, oldVal);
    // },
    // "innerAddress.streetName": function (val, oldVal) {
    //   this.maxLenth(val, "streetName", 40, oldVal);
    // },
    // "innerAddress.city": function (val, oldVal) {
    //   this.maxLenth(val, "city", 40, oldVal);
    // },
    // "innerAddress.postcode": function (val, oldVal) {
    //   this.maxLenth(val, "postcode", 12, oldVal);
    //   this.postcodechangeHandler(val);
    // },
    // "innerAddress.addressLine1": function (val, oldVal) {
    //   this.maxLenth(val, "addressLine1", 200, oldVal);
    // },
    // "innerAddress.addressLine2": function (val, oldVal) {
    //   this.maxLenth(val, "addressLine2", 200, oldVal);
    // },
    // "innerAddress.addressLine3": function (val, oldVal) {
    //   this.maxLenth(val, "addressLine3", 200, oldVal);
    // },
    // "innerAddress.country": function (val, oldVal) {
    //   this.maxLenth(val, "country", 120, oldVal);
    // },
    // "innerAddress.streetFraction": function (val, oldVal) {
    //   this.maxLenth(val, "streetFraction", 200, oldVal);
    // },
    // "innerAddress.building": function (val, oldVal) {
    //   this.maxLenth(val, "building", 200, oldVal);
    // },
    // "innerAddress.apartment": function (val, oldVal) {
    //   this.maxLenth(val, "apartment", 200, oldVal);
    // },
  },
};
</script>
