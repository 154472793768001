class SingleLock {
  constructor(context) {
    this.context = context || {}
    this.context.status = false
    this.context.waitings = []
  }
  invoke(ignore, action) {
    return new Promise((resolve, reject) => {
      if (this.context.status && !ignore) {
        this.context.waitings.push((data) => action(resolve, reject, data))
      } else {
        action(resolve, reject)
      }
    })
  }
  locked() {
    this.context.status = true
  }
  unlock(data) {
    this.context.status = false

    const doWaitings = () => {
      if (!this.context.status && this.context.waitings.length > 0) {
        const wait = this.context.waitings.shift()
        wait(data)
        doWaitings()
      }
    }
    doWaitings()
  }
}

export default SingleLock