<template>
  <div>
    <k-alert
      :show="!inputIsValid()"
      variant="warning"
    >
      {{ this.$g("validate.invalidformat") }}
    </k-alert>
    <ConditionFields
      v-for="(dynamicCondition, i) in customFieldsConditions"
      :key="i"
      :first="i == 0"
      :index="parseInt(i)"
      :info="dynamicCondition"
      @conditionChange="onConditionChange"
      @conditionDelete="onConditionDelete"
      :filed-options="customConditionFields"
      :show-delete="customFieldsConditions.length > 1 && i > 0"
    />
  </div>
</template>

<script>
import ConditionFields from "./condition-fields.vue";

export default {
  data(){
    return {};
  },
  props: {
    customConditionFields: {
      type: Array,
      default(){
        return [];
      },
    },
    customFieldsConditions: {
      type: Array,
      default(){
        return [];
      },
    },
  },
  components: {
    ConditionFields,
  },
  methods: {
    checkIsFull(r){
      return (
          r.fieldNameLeft != null &&
          r.compareOperator != null &&
          r.compareOperator != 0 &&
          r.compareValue != null &&
          r.compareValue != ""
      );
    },
    inputIsValid(){
      if(
          !this.customFieldsConditions ||
          this.customFieldsConditions.length == 0
      ){
        return true;
      }
      let passed = true;
      for (var i = 0; i < this.customFieldsConditions.length; i ++) {
        let c = this.customFieldsConditions[i];
        let isFull = this.checkIsFull(c);
        let isEmpty = this.checkIsAllEmpty(c);
        if(!isFull && !isEmpty){
          passed = false;
          break;
        }
      }
      return passed;
    },
    clearCustomInput(){
      if(this.customFieldsConditions){
        for (var i = 0; i < this.customFieldsConditions.length; i ++) {
          if(this.checkIsAllEmpty(this.customFieldsConditions[i])){
            this.customFieldsConditions.splice(i, 1);
            i --;
          }
        }
        let lastPos = this.customFieldsConditions.length - 1;
        if(lastPos >= 0){
          this.customFieldsConditions[lastPos].logicalOperator = 0;
        } else {
          this.addNewRow();
        }
      }
    },
    checkIsAllEmpty(r){
      return (
          r.fieldNameLeft == null &&
          (r.compareOperator == null || r.compareOperator == 0) &&
          (r.logicalOperator == null || r.logicalOperator == 0) &&
          (r.compareValue == null || r.compareValue == "")
      );
    },
    addNewRow(){
      this.customFieldsConditions.push({
        fieldNameLeft: null,
        compareOperator: null,
        logicalOperator: 0,
        compareValue: null,
      });
    },
    onConditionChange(index, info){
      if(info.fieldNameLeft == null){
        info.compareOperator = null;
        info.logicalOperator = null;
        info.compareValue = null;
      }
      this.customFieldsConditions[index] = info;
      if(
          index === this.customFieldsConditions.length - 1 &&
          info.logicalOperator > 0
      ){
        this.addNewRow();
      } else if(
          index < this.customFieldsConditions.length - 1 &&
          info.logicalOperator === 0
      ){
        this.customFieldsConditions.splice(
            index + 1,
            this.customFieldsConditions.length - 1 - index,
        );
      }
      if(info.logicalOperator == null){
        let removeCnt = this.customFieldsConditions.length - 1 - index;
        for (var i = 0; i < removeCnt; i ++) {
          this.customFieldsConditions.splice(index + 1, 1);
        }
      }
    },
    onConditionDelete(index){
      if(this.customFieldsConditions.length > index){
        this.customFieldsConditions.splice(index, 1);
        if(this.customFieldsConditions.length == 1){
          this.customFieldsConditions[0].logicalOperator = 0;
        }
      }
    },
  },
};
</script>
