<template>
  <sendMessageDetail
    @label-select="onLabelSelect"
    ref="detail"
    :id="id"
    :is-event="isEvent"
    :showSendTo="showSendTo"
    :showDetailButton="showDetailButton"
    :showAddGroup="showAddGroup"
    :showContactOption="showContactOption"
    :hideNav="hideNav"
    :showSaveDraft="showSaveDraft"
    :permissionCode="permissionCode"
    :permissionCodeEvent="permissionCodeEvent"
    :hasGenerateMailingAddress="hasGenerateMailingAddress"
    :hasSurvey="hasSurvey"
  >
    <template #insertLabel="data">
      <slot
        name="insertLabel"
        :dynamicInsertLabels="dynamicInsertLabels"
        :templateType="data.templateType"
      />
    </template>
    <template #contact-details="data">
      <slot
        name="contact-details"
        :groupId="data.groupId"
        :contactOptionIds="data.contactOptionIds"
        :groupType="data.groupType"
        :getContacts="data.getContacts"
        :templateType="data.templateType"
        :actionIds="data.actionIds"
        :contactAction="data.contactAction"
        :phoneTypes="data.phoneTypes"
      />
    </template>
  </sendMessageDetail>
</template>
<script>
import sendMessageDetail from "./components/sendMessageDetail.vue";

import { permissionCode } from "../../mixins/permissionCode";
import { getInsertLabels } from "../../api/message";
import storeage from "../../../../utils/storeage";
import { beforeRouteLeaveMixin } from "../../../../utils/mixins/mixin";

export default {
  mixins: [beforeRouteLeaveMixin, permissionCode],
  props: {
    hasGenerateMailingAddress: {
      type: Boolean,
      default() {
        return false;
      },
    },
    showSaveDraft: {
      type: Boolean,
      default() {
        return true;
      },
    },
    hideNav: {
      type: Array,
      default() {
        return [];
      },
    },
    showContactOption: {
      type: Boolean,
      default() {
        return true;
      },
    },
    showDetailButton: {
      type: Boolean,
      default() {
        return true;
      },
    },
    showAddGroup: {
      type: Boolean,
      default() {
        return true;
      },
    },
    showSendTo: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isEvent: {
      type: Boolean,
      default() {
        return false;
      },
    },
    hasSurvey: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  components: {
    sendMessageDetail,
  },
  data() {
    return {
      id: this.$route.query.id,
      electionId: this.isEvent ? storeage.getElectionId() : "",
      dynamicInsertLabels: [],
    };
  },
  created() {
    getInsertLabels().then((res) => {
      this.dynamicInsertLabels = res.data;
    });
  },
  methods: {
    onLabelSelect() {
      this.$emit("label-select");
    },
    insertHtml(val) {
      this.$refs.detail.insertHtml(val);
    },
    beforeRouteLeave(to, from, next) {
      if (!this.isDoSave && this.$refs.detail.isDataChanges()) {
        this.$confirm({
          title: this.$g("const.confirm.confirm"),
          content: this.$g("const.confirm.leaveComfirm"),
          cancelType: "secondary",
          okText: this.$g("const.confirm.leaveComfirmYes"),
          cancelText: this.$g("const.confirm.no"),
        })
          .then(() => {
            next();
          })
          .catch(() => {
            //nav maybe in view or detialview
            this.$refs.detail.navToBack();
            this.navToBack();
            next(false);
          });
      } else {
        next();
      }
    },
  },
};
</script>
