<template>
  <div style="display: flex; justify-content: center">
    <k-alert-msg ref="lblMsg" />
    <div class="info-item">
      <!-- <input
        type="button"
        class="oper"
        style="height: 20px; width: 23px; font-size: 20px; margin: 3px 5px"
        value="↓"
        title="下载"
        @click="down('blob')"
      /> -->
      <!-- <input
        type="button"
        class="btn btn-blue"
        value="上传头像"
        @click="finish('blob')"
      /> -->
      <div class="line">
        <div class="cropper-content">
          <div class="cropper">
            <vueCropper
              ref="cropper"
              :img="option.img"
              :outputSize="option.size"
              :outputType="option.outputType"
              :info="true"
              :full="option.full"
              :canMove="option.canMove"
              :canMoveBox="option.canMoveBox"
              :original="option.original"
              :autoCrop="option.autoCrop"
              :autoCropWidth="option.autoCropWidth"
              :autoCropHeight="option.autoCropHeight"
              :fixedBox="option.fixedBox"
              :centerBox="true"
            ></vueCropper>
          </div>
        </div>
      </div>

      <div
        style="
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          height: 80px;
        "
      >
        <b-icon icon="zoom-in" scale="2" @click="changeScale(1)"></b-icon>
        <b-icon icon="zoom-out" scale="2" @click="changeScale(-1)"></b-icon>
      </div>
      <div style="display: flex; justify-content: center; align-items: center">
        <!-- <label
        class="btn btn-orange"
        for="uploads"
        style="
          display: inline-block;
          width: 70px;
          padding: 0;
          text-align: center;
          line-height: 28px;
        "
        >选择图片</label
      > -->
        <input
          type="file"
          :id="id"
          :value="imgFile"
          style="position: absolute; clip: rect(0 0 0 0)"
          accept="image/png, image/jpeg, image/jpg"
          @change="uploadImg($event, 1)"
        />
        <label :for="id" class="btn btn-secondary mb-1 mr-1">
          {{ $g("upload.browse") }}
        </label>
        <!-- <b-form-file
      :id="id"
      ref="fileInput"
      :multiple="false"
      @click="uploadImg($event, 1)"
    /> -->
      </div>
      <!-- <input
        type="button"
        class="oper"
        style="height: 20px; width: 23px; font-size: 20px; margin: 3px 5px"
        value="+"
        title="放大"
        @click="changeScale(1)"
      /> -->

      <!-- <input
        type="button"
        class="oper"
        style="height: 20px; width: 23px; font-size: 20px; margin: 3px 5px"
        value="-"
        title="缩小"
        @click="changeScale(-1)"
      /> -->
    </div>
  </div>
</template>

<script>
import VueCropper from "./vue-cropper";
import { addPhoto } from "./api";

export default {
  props: {
    requestApi: {
      type: Function,
      default: addPhoto,
    },
    getimgurldata: {
      type: Function,
      default: null,
    },
    refId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      id: "upload",
      headImg: "",
      //剪切图片上传
      crap: false,
      previews: {},
      option: {
        img: "",
        outputSize: 1, //剪切后的图片质量（0.1-1）
        full: true, //输出原图比例截图 props名full
        outputType: "png",
        canMove: true,
        original: false,
        canMoveBox: true,
        autoCrop: true,
        autoCropWidth: 200,
        autoCropHeight: 200,
        fixedBox: true,
      },
      fileName: "", //本机文件地址
      downImg: "#",
      imgFile: "",
      uploadImgRelaPath: "", //上传后的图片的地址（不带服务器域名）
      importFileData: {},
    };
  },
  components: {
    VueCropper,
  },
  methods: {
    //放大/缩小
    changeScale(num) {
      num = num || 1;
      this.$refs.cropper.changeScale(num);
    },
    //坐旋转
    rotateLeft() {
      console.log("rotateLeft");
      this.$refs.cropper.rotateLeft();
    },
    //右旋转
    rotateRight() {
      console.log("rotateRight");
      this.$refs.cropper.rotateRight();
    },
    //上传图片（点击上传按钮）
    finish() {
      console.log(this.option.img)
      let formData = new FormData();
      // 输出
      this.$refs.cropper.getCropBlob((data) => {
        let importFileData = {};
        importFileData.url = window.URL.createObjectURL(data);
        if (this.refId) {
          formData.append("RefId", this.refId);
        }
        formData.append("Title", this.fileName);
        formData.append("File", data, this.fileName);
        this.requestApi(formData).then((res) => {
          importFileData.id = res.data.info;
          this.getimgurldata && this.getimgurldata(importFileData);
        });
      });
    },
    photoValid(){
      return this.option.img?true:false
    },
    setMessage(message) {
      this.$refs.lblMsg.message(3, message);
    },
    //下载图片
    down(type) {
      console.log("down");

      var aLink = document.createElement("a");
      aLink.download = "author-img";
      if (type === "blob") {
        this.$refs.cropper.getCropBlob((data) => {
          this.downImg = window.URL.createObjectURL(data);
          console.log("this.downImg: ", this.downImg);

          aLink.href = window.URL.createObjectURL(data);
          aLink.click();
        });
      } else {
        this.$refs.cropper.getCropData((data) => {
          this.downImg = data;
          aLink.href = data;
          aLink.click();
        });
      }
    },
    //选择本地图片
    uploadImg(e, num) {
      var _this = this;
      //上传图片
      var file = e.target.files[0];
      _this.fileName = file.name;
      if (!/\.(jpg|jpeg|png|bmp|JPG|PNG)$/.test(e.target.value)) {
        alert("Picture type must be .jpeg,jpg,png,bmp");
        return false;
      }
      var reader = new FileReader();
      reader.onload = (e) => {
        let data;
        if (typeof e.target.result === "object") {
          // 把Array Buffer转化为blob 如果是base64不需要
          data = window.URL.createObjectURL(new Blob([e.target.result]));
        } else {
          data = e.target.result;
        }
        if (num === 1) {
          _this.option.img = data;
        } else if (num === 2) {
          _this.example2.img = data;
        }
      };
      // 转化为base64
      // reader.readAsDataURL(file)
      // 转化为blob
      reader.readAsArrayBuffer(file);
    },
  },
};
</script>

<style lang="scss" scoped>
.info {
  width: 720px;
  margin: 0 auto;
  .oper-dv {
    height: 20px;
    text-align: right;
    margin-right: 100px;
    a {
      font-weight: 500;
      &:last-child {
        margin-left: 30px;
      }
    }
  }
  .info-item {
    margin-top: 15px;
    label {
      display: inline-block;
      width: 100px;
      text-align: right;
    }
    .sel-img-dv {
      position: relative;
      .sel-file {
        position: absolute;
        width: 90px;
        height: 30px;
        opacity: 0;
        cursor: pointer;
        z-index: 2;
      }
      .sel-btn {
        position: absolute;
        cursor: pointer;
        z-index: 1;
      }
    }
  }
}

.cropper-content {
  display: flex;
  display: -webkit-flex;
  justify-content: flex-start;
  -webkit-justify-content: flex-start;
  .cropper {
    width: 400px;
    height: 400px;
  }
  .show-preview {
    flex: 1;
    -webkit-flex: 1;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    -webkit-justify-content: center;
    .preview {
      overflow: hidden;
      border-radius: 50%;
      border: 1px solid #cccccc;
      background: #cccccc;
      margin-left: 40px;
    }
  }
}
.cropper-content .show-preview .preview {
  margin-left: 0;
}
</style>