const routes = [
    {
        name: 'late-fee-payment',
        path: '/',
        meta: { title: 'lateFeePayment', requiresAuth: false },
        component: () => import('@/views/const/space'),
        route: true,
    },

]

export default [
    {
        path: '/late-fee-payment',
        route: false,
        component: () => import('@/layout/brief'),
        children: [...routes],
    },
]