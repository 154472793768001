<template>
  <div>
    <slot name="errMsg" :data="errMsg">
      <p v-for="(msg, index) in errMsg" :key="index">
        <template v-if="showMessage">
          {{ msg }}
        </template>
      </p>
    </slot>
    <k-button
      class="btn btn-secondary mb-1 mr-1"
      style="vertical-align: top;"
      v-if="!plaintext"
      @click="openFile"
    >
      upload.browse
    </k-button>
    <slot name="btnGroup"></slot>
    <b-form-file
      :id="id"
      ref="fileInput"
      :multiple="isMultiple"
      :accept="accept"
      @input="onInput"
      :state="state"
      plain
      :placeholder="placeholder"
      hidden
      v-model="uploadfiles"
    />

    <slot name="info" :data="{ accept: accept, size: size }" v-if="!plaintext">
      <div class="mt-1">
        {{ $g("upload.size") }}: {{ getFileSize() }}
        <br />
        {{ $g("upload.extensions") }}: {{ accept }}
      </div>
    </slot>
    <slot name="fileList" :data="files">
      <upload-list
        ref="uploadList"
        :file-list="files"
        @onDelete="onUploadDelete"
        :is-show="isShow"
        :plaintext="plaintext"
      />
    </slot>
  </div>
</template>

<script>
import uploadList from "./components/uploadList";

export default {
  model: { prop: "files", event: "input" },
  data() {
    return {
      id: "upload",
      errMsg: [],
      state: null,
      isShow: true,
      uploadfiles: null, // files  from b-form-file
    };
  },
  props: {
    downloadParams: {
      type: Object,
      default: null,
    },
    files: {
      // files for this  component
      type: Array,
      default() {
        return [];
      },
    },
    accept: {
      type: String,
      required: true,
      default() {
        return ".xls,.xlsx";
      },
    },
    size: {
      type: Number,
      required: true,
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    onDelete: {
      type: Function,
      default: null,
    },
    placeholder: {
      type: String,
      default: "",
    },
    showMessage: {
      type: Boolean,
      default: true,
    },
    isUseInternalValidate: {
      type: Boolean,
      default: true,
    },
    plaintext: {
      type: Boolean,
      default: false,
    },
    isImage: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onUploadDelete(file) {
      this.uploadfiles = [];
      if (typeof file.length != "undefined") {
        this.onDelete(file);
      } else {
        this.uploadfiles.splice(
          this.uploadfiles.findIndex((item) => item === file),
          1
        );
      }
      this.onDelete(file);
      this.$emit("blur");
    },
    onInput(input) {
      if (!input) return;
      let inputFiles = this.isMultiple ? input : [input];
      this.handleFiles(inputFiles);
      this.$emit("input", this.$refs.uploadList.getFiles());
      this.$emit("blur");
      if (this.isImage) this.getImageSize(input);
    },
    openFile() {
      document.getElementById(this.id).click();
    },
    getImageSize(input) {
      this.$emit("onGetImageSize", { width: 0, height: 0 });
      var that = this;
      var img = new Image();
      img.src = window.URL.createObjectURL(input);
      if (img.complete) {
        that.$emit("onGetImageSize", { width: img.width, height: img.height });
      } else {
        img.onload = function () {
          that.$emit("onGetImageSize", {
            width: img.width,
            height: img.height,
          });
        };
      }
    },

    handleFiles(inputFiles) {
      if (!inputFiles || inputFiles.length === 0) return;
      this.uploadValidate(inputFiles);
      if (this.state) {
        inputFiles.forEach((file) => {
          if (this.isMultiple) {
            this.$refs.uploadList.addFile(file);
          } else {
            this.$refs.uploadList.clearAddFile(file);
          }
        });
      } else {
        this.uploadfiles = [];
      }
    },
    uploadValidate(inputFiles) {
      if (this.errMsg.length > 0) {
        this.clearErrMsg();
      }
      if (!this.isUseInternalValidate) {
        this.state = true;
        return;
      }
      inputFiles.forEach((file) => {
        if (!file) return;
        if (file.size > this.size * 1024) {
          this.addErrMsg(this.$g("upload.overLimitSize"));
        }
        let extension = this.getExtension(file).toLowerCase();
        if (this.accept.toLowerCase().indexOf(extension) < 0) {
          this.addErrMsg(this.$g("upload.invalidFormat"));
        }
      });
      this.state = this.errMsg.length <= 0;
    },

    addErrMsg(msg) {
      this.errMsg.push(msg);
      this.$emit("errMsgChange", this.errMsg);
    },

    clearErrMsg() {
      this.errMsg.splice(0, this.errMsg.length);
      this.$emit("errMsgChange", this.errMsg);
    },
    getErrMsg() {
      return this.errMsg;
    },

    getExtension(file) {
      var index = file.name.lastIndexOf(".");
      return file.name.substring(index + 1);
    },

    getFileSize() {
      let rest = "",
        size = this.size || 0;

      if (size < 1024) {
        rest = size + "KB";
      } else if (size < 1024 * 1024) {
        rest = (size / 1024).toFixed(0) + "MB";
      } else {
        //其他转化成GB
        rest = (size / (1024 * 1024)).toFixed(0) + "GB";
      }

      return rest;
    },
    initUploadList(files) {
      this.$refs.uploadList.initFiles(files);
    },
    clear() {
      this.$refs.uploadList.clear();
      this.uploadfiles = [];
    },
  },
  created() {
    this.id = "upload_" + new Date().getTime();
  },
  components: { uploadList },
};
</script>
