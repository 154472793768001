import { g } from "../../../locale/lang-val";

export const reportNavAllTabs = (isEvent, isAdd, permissionCode) => {
  const hasNotification = process.env.VUE_APP_HAS_NOTIFICATION && process.env.VUE_APP_HAS_NOTIFICATION == "true";
  let tabs = [{
    title: g("tabs.detail.basicInfo"),
    index: 0,
    route: isEvent ? "eleReport-edit" : "report-edit",
    permissionCode: permissionCode.Report.BasicInfo,
  }];
  if(!isAdd){
    tabs.push({
      title: g("tabs.detail.permissions"),
      index: 1,
      route: isEvent ? "eleReport-permissions" : "report-permissions",
      permissionCode: permissionCode.Report.Permissions,
    });
  }
  if(hasNotification && !isAdd){
    tabs.push({
      title: g("tabs.detail.notification"),
      index: 2,
      route: isEvent ? "eleReportNotification" : "reportNotification",
      permissionCode: permissionCode.Report.Notification,
    });
  }
  return tabs;
};

