<template>
  <div>
    <b-tabs v-model="tabindex">
      <b-tab
        v-for="(item, index) in items"
        :title="item.title"
        :key="index"
        @click="clickTab(item)"
      />
    </b-tabs>
  </div>
</template>
<script>
import { enumTemplateType } from "../enums/enumTemplateType";

export default {
  data() {
    return {
      tabindex: this.tabIndex,
      items: [],
    };
  },
  props: {
    tabIndex: {
      type: Number,
      required: true,
    },
    isPlainText: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isEvent: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isNotification: {
      type: Boolean,
      default() {
        return false;
      },
    },
    showEdit: {
      type: Boolean,
      default() {
        return false;
      },
    }
  },
  methods: {
    getBasicRouteName() {
      let basicRouteName = "";
      switch (true) {
        case !this.isPlainText && !this.isNotification:
          basicRouteName = this.isEvent
            ? "eleDocumentTemplateEdit"
            : "documentTemplateEdit";
          break;
        case this.isPlainText && !this.isNotification:
          basicRouteName = this.isEvent
            ? "eleDocumentTemplateView"
            : "documentTemplateView";
          break;
        case this.isPlainText && this.isNotification:
          basicRouteName = this.isEvent
            ? "eleNotificationDocumentTemplateView"
            : "notificationDocumentTemplateView";
          break;
        case !this.isPlainText && this.isNotification:
          basicRouteName = this.isEvent
            ? "eleNotificationDocumentTemplateEdit"
            : "notificationDocumentTemplateEdit";
          break;
        default:
          basicRouteName = this.isEvent
            ? "eleMessageTemplateEdit"
            : "messageTemplateEdit";
          break;
      }
      return basicRouteName;
    },
    getBasicRouteName2() {
      let basicRouteName = "";
      switch (true) {
        case this.isPlainText && !this.isNotification:
          basicRouteName = this.isEvent
            ? "eleMessageTemplateView"
            : "messageTemplateView";
          break;
        case !this.isPlainText && this.isNotification:
          basicRouteName = this.isEvent
            ? "eleNotificationEdit"
            : "notificationEdit";
          break;
        case this.isPlainText && this.isNotification:
          basicRouteName = this.isEvent
            ? "eleNotificationView"
            : "notificationView";
          break;
        default:
          basicRouteName = this.isEvent
            ? "eleMessageTemplateEdit"
            : "messageTemplateEdit";
          break;
      }
      return basicRouteName;
    },
    clickTab(item) {
      if (
        !this.isPlainText &&
        item.route.toLocaleLowerCase() === this.$route.name.toLocaleLowerCase()
      ) {
        return;
      } else {
        this.$router.push({
          name: item.route,
          query: {
            id: this.$route.query.id,
            isView: this.$route.query.isView,
            hidePreNext: this.$route.query.hidePreNext,
            dbtn: this.$route.query.dbtn,
            templateType: this.$route.query.templateType,
            tabindex: this.$route.query.tabindex,
            showEdit: this.showEdit ? "1" : "0"
          },
        });
      }
    },
  },
  mounted() {
    let basicRouteName;
    if (this.$route.query.templateType == enumTemplateType.documentation) {
      basicRouteName = this.getBasicRouteName();
      console.log(basicRouteName);
    } else {
      basicRouteName = this.getBasicRouteName2();
    }

    let logRouteName = "";
    switch (true) {
      case this.$route.query.templateType == enumTemplateType.documentation &&
        !this.isNotification:
        logRouteName = this.isEvent
          ? "eleDocumentTemplateLog"
          : "documentTemplateLog";
        break;
      case this.$route.query.templateType == enumTemplateType.documentation &&
        this.isNotification:
        logRouteName = this.isEvent
          ? "eleNotificationDocumentTemplateLog"
          : "notificationDocumentTemplateLog";
        break;
      case this.$route.query.templateType != enumTemplateType.documentation &&
        this.isNotification:
        logRouteName = this.isEvent ? "eleNotificationLog" : "notificationLog";
        break;
      default:
        logRouteName = this.isEvent
          ? "eleMessageTemplateLog"
          : "messageTemplateLog";
        break;
    }

    const tabItems = [
      {
        title: this.$g("tabs.detail.basicInfo"),
        index: 0,
        route: basicRouteName,
      },
    ];
    if (this.$route.query.id != null) {
      tabItems.push({
        title: this.$g("tabs.detail.logs"),
        index: 1,
        route: logRouteName,
      });
    }

    this.items = tabItems;
  },
};
</script>
