<template>
  <b-tabs v-model="tabindex">
    <b-tab
      v-for="(item, index) in items"
      :title="item.title"
      :key="index"
      @click="onClickTab(item, index)"
    />
  </b-tabs>
</template>
<script>
import { notificationTypeEnum } from "./components/notificationTypeEnum";
export default {
  props: {
    notificationType: {
      type: String | Number,
      default() {
        return notificationTypeEnum.email;
      },
    },
    isEvent: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isAdd: {
      type: Boolean,
      default() {
        return false;
      },
    },
    clickTab: {
      type: Function,
    },
  },
  data() {
    return {
      tabindex: 0,
      items: [
        {
          title: this.$g("reportNotification.email"),
          index: 0,
          type: notificationTypeEnum.email,
        },

        {
          title: this.$g("reportNotification.sms"),
          index: 1,
          type: notificationTypeEnum.sms,
        },
      ],
    };
  },
  created() {
    this.tabindex =
      this.items.find((c) => c.type == this.notificationType)?.index ?? 0;
  },
  methods: {
    onClickTab(item) {
      if (this.tabindex == item.index) {
        return;
      }
      this.clickTab(item, this.tabindex);
    },
  },
};
</script>
