<script type="text/javascript" src="static/ckeditor/ckeditor.js"></script>
<template>
  <div>
    <textarea :id="id" />
    <insert-label-dialog ref="dialog" @select="onLabelSelect">
      <slot />
    </insert-label-dialog>
  </div>
</template>

<script>
import { getToken } from "../../utils/token";
import InsertLabelDialog from "./insertLabelDialog";
import globalEnv from "../../libs/globalEnv";

export default {
  model: { prop: "value", event: "input" },
  props: {
    value: {
      type: String,
      default: function () {},
    },
    isPlainText: {
      type: Boolean,
      default() {
        return false;
      },
    },
    showInsertLabel: {
      type: Boolean,
      default() {
        return true;
      },
    },
    ckeditorHeight: {
      type: Number,
      default() {
        return 200;
      },
    },
    apiUrl: {
      type: String,
      default() {
        return "/api/msgtemplate/action/uploadeditorattachment";
      },
    },
  },
  components: { InsertLabelDialog },
  data: function () {
    return {
      id: "message-editor" + this._uid,
      ckeditor: null,
      process: process,
    };
  },
  mounted() {
    const self = this;
    var systemURL = globalEnv.VUE_APP_STD_URL;
    var systemPath = globalEnv.VUE_APP_SYSTEM_PATH;
    var path = systemURL;
    if (systemPath != "") {
      path += "/" + systemPath;
    }
    self.ckeditor = window.CKEDITOR.replace(self.id, {
      filebrowserUploadUrl: path + self.$props.apiUrl,
      language: "en",
      height: this.ckeditorHeight,
    });
    self.ckeditor.config.showInsertLabel = self.$props.showInsertLabel;
    self.ckeditor.on("instanceReady", () => {
      self.ckeditor.insertLabelDialog = self.$refs.dialog;
      if (self.value) self.ckeditor.setData(self.value);
      self.ckeditor.setReadOnly(self.isPlainText);
    });

    self.ckeditor.on("change", function () {
      self.$emit("input", self.ckeditor.getData());
    });
    self.ckeditor.on("blur", function () {
      self.$emit("blur", self.ckeditor.getData());
    });
    self.ckeditor.on("fileUploadRequest", function (evt) {
      var xhr = evt.data.fileLoader.xhr;
      xhr.setRequestHeader("Authorization", getToken()?.token);
    });
    self.ckeditor.on("fileUploadResponse", function (evt) {
      setTimeout(() => {
        if (evt.data.uploaded == 1) {
          self.$emit("input", self.ckeditor.getData());
        }
      }, 500);
    });
  },
  methods: {
    onLabelSelect(ev) {
      this.$emit("select", ev);
    },
    insertHtml(html) {
      console.log("html: ", html, this.id);
      this.ckeditor.insertHtml(html);
    },
  },
  watch: {
    value: function () {
      if (this.value !== this.ckeditor.getData()) {
        this.ckeditor.setData(this.value);
      }
    },
  },
  beforeDestroy: function () {
    this.ckeditor.destroy();
  },
};
</script>
<style>
.cke_screen_reader_only {
  position: fixed !important;
}
</style>
