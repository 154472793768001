const routes = [
    {
        name: "requiredDocuments",
        path: "",
        meta: {
            title: "requiredDocuments",
            pageTitle: "welcome",
        },
        route: true,
        component: () => import("@/views/requiredDocuments/required-documents.vue"),
    },
    {
        name: "requiredDocumentsEdit",
        path: "requiredDocumentsEdit",
        meta: {
            title: "requiredDocuments",
            pageTitle: "welcome",
        },
        route: true,
        component: () => import("@/views/requiredDocuments/required-documents-edit.vue"),
    },
    {
        name: "requiredDocumentsView",
        path: "requiredDocumentsView",
        meta: {
            title: "requiredDocuments",
            pageTitle: "welcome",
        },
        props: {
            isPlaintext: true
        },
        route: true,
        component: () => import("@/views/requiredDocuments/required-documents-view.vue"),
    },
    {
        name: "requiredDocumentsSubmitSuccessfully",
        path: "requiredDocumentsSubmitSuccessfully",
        meta: {
            title: "requiredDocuments",
            pageTitle: "welcome",
        },
        route: true,
        component: () => import("@/views/requiredDocuments/submit-successfully.vue"),
    },
]

export default [
    {
        path: "/requiredDocuments",
        meta: {
            title: "requiredDocuments",
        },
        component: () => import("@/layout/brief"),
        children: [...routes],
    },
];
