<template>
  <span>
    <span v-if="plaintext">
      <span class="checkboxGroup" v-html="displayText" />
      <slot v-if="displayText != ''" />
    </span>
    <b-form-checkbox-group v-else v-bind="props" v-on="listeners">
      <slot />
    </b-form-checkbox-group>
  </span>
</template>
<script>
import { BFormCheckboxGroup } from "bootstrap-vue";

const addedProps = {
  plaintext: {
    type: Boolean,
    default() {
      return false;
    },
  },
};
let objProps = Object.assign({}, BFormCheckboxGroup.options.props, addedProps);
export default {
  components: { BFormCheckboxGroup },
  model: {
    prop: "checked",
    event: "input",
  },
  props: {
    ...objProps,
  },
  computed: {
    props() {
      return this.$props;
    },
    listeners() {
      return this.$listeners;
    },
    displayText() {
      if (this.plaintext) {
        let htmlCheck = "";
        if (this.checked) {
          this.options.forEach((el) => {
            let item1 = this.checked.find((item) => {
              return item == el.value;
            });
            if (item1) {
              htmlCheck +=
                '<span class="checkboxList">' + el.text + "</span>";
            }
          });

          // this.checked.forEach((el) => {
          //   let item1 = this.options.find((item) => {
          //     return item.value == el;
          //   });
          //   if (item1) {
          //     htmlCheck +=
          //         "<span class=\"checkboxList\">" + item1.text + "</span>";
          //   }
          // });
        }
        return htmlCheck;
      }
      return "";
    },
  },
};
</script>
<style>
.checkboxList:nth-last-child(n + 2)::after {
  content: ",";
  display: inline-block;
  margin-right: 3px;
}
</style>
