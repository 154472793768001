import { sysId } from "../utils/enums/sysId";

function getGlobalEnv(){
  let result;
  switch (parseInt(process.env.VUE_APP_SYSTEM_ID)) {
    case sysId.login:
      result = window.appConfig.login;
      break;
    case sysId.plms:
      if(process.env.VUE_APP_IS_WORKER_REPORT){
        result = window.appConfig.locationReport;
      } else {
        result = window.appConfig.location;
      }
      break;
    case sysId.pams:
      if(process.env.VUE_APP_IS_WORKER_REPORT){
        result = window.appConfig.assetReport;
      } else {
        result = window.appConfig.pams;
      }
      break;
    case sysId.pwms:
      if(process.env.VUE_APP_IS_WORKER_REPORT){
        result = window.appConfig.workerReport;
      } else {
        result = window.appConfig.worker;
      }
      break;
    case sysId.pAdmin:
      result = window.appConfig.admin;
      break;
    case sysId.poll_call:
      if(process.env.VUE_APP_IS_WORKER_REPORT){
        result = window.appConfig.helpdeskReport;
      } else {
        result = window.appConfig.helpdesk;
      }
      break;
    case sysId.myBallot:
      if(process.env.VUE_APP_IS_ADMIN){
        result = window.appConfig.myballotAdmin;
      } else {
        result = window.appConfig.myballot;
      }
      break;
    case sysId.elector:
      if(process.env.VUE_APP_IS_WORKER_REPORT){
        result = window.appConfig.electorReport;
      } else {
        result = window.appConfig.elector;
      }
      break;
    case sysId.candidate:
      if(process.env.VUE_APP_IS_WORKER_REPORT){
        result = window.appConfig.candidateReport;
      } else {
        result = window.appConfig.candidate;
      }
      break;
    case sysId.voteResults:
      if(process.env.VUE_APP_IS_WORKER_REPORT){
        result = window.appConfig.resultReport;
      } else {
        result = window.appConfig.result;
      }
      break;
    case sysId.tenant:
      result = window.appConfig.tenantMain;
      break;
    case sysId.tenantAsset:
      result = window.appConfig.tenantAsset;
      break;
    default:
      result = {};
      break;
  }
  return { ...result, ...window.appConfig.common };
}

let globalEnv = "";

if(process.env.VUE_APP_SYSTEM_ID){
  if(process.env.VUE_APP_ISSPA == undefined){
    globalEnv = getGlobalEnv();
  } else if(process.env.VUE_APP_ISSPA != undefined && process.env.VUE_APP_ISSPA == "false"){
    globalEnv = {
      VUE_APP_API_URL: process.env.VUE_APP_API_URL,
      VUE_APP_STD_URL: process.env.VUE_APP_STD_URL,
      VUE_APP_ADMIN_URL: process.env.VUE_APP_ADMIN_URL,
      UE_APP_ADMIN_URL: process.env.UE_APP_ADMIN_URL,
      VUE_APP_SYSTEM_PATH: process.env.VUE_APP_SYSTEM_PATH,
      VUE_APP_BASE_PATH: process.env.VUE_APP_BASE_PATH,
      VUE_APP_USE_SYSTEM_PATH: process.env.VUE_APP_USE_SYSTEM_PATH,
      VUE_APP_DEBUG: process.env.VUE_APP_DEBUG,
      VUE_APP_Close_Permission: process.env.VUE_APP_Close_Permission,
      VUE_APP_PLM_URL: process.env.VUE_APP_PLM_URL,
      VUE_APP_Location_SYSTEM_PATH: process.env.VUE_APP_Location_SYSTEM_PATH,
    };
  } else {
    if(process.env.VUE_APP_ISSPA == "true"){
      globalEnv = getGlobalEnv();
    } else {
      globalEnv = window.appConfig;
    }
  }
} else {
  globalEnv = window.appConfig;
}

console.log("fe-infr-globalEnv", globalEnv);

export default globalEnv;
