<template>
  <span>
    <span
      v-if="plaintext"
      class="col-form-label d-block"
    >{{
      displayText
    }}</span>
    <b-form-select
      tabindex="0"
      @blur.native.capture="blurNote()"
      v-else
      v-bind="$props"
      v-on="listeners"
      :aria-label="ariaLabel"
    />
  </span>
</template>
<script>
import { BFormSelect } from "bootstrap-vue";

const addedProps = {
  plaintext: {
    type: Boolean,
    default(){
      return false;
    },
  },
  ariaLabel: {
    type: String,
    default(){
      return "select";
    },
  },
};
const objProps = Object.assign({}, BFormSelect.options.props, addedProps);

export default {
  components: { BFormSelect },
  props: {
    ...objProps,
  },
  methods: {
    blurNote(){
      this.$emit("blur");
    },
    getDisplayText(sValue, options){
      let result = [];
      if(options){
        sValue.forEach((el) => {
          let found = options.find((m) => m.value == el);
          if(found){
            result.push(found.text);
          }
        });
      }
      return result.join(", ");
    },
  },
  computed: {
    listeners(){
      return this.$listeners;
    },
    displayText(){
      let sValue = [];
      if(this.value instanceof Array){
        sValue = this.value;
      } else {
        sValue.push(this.value);
      }
      return this.getDisplayText(sValue, this.options);
    },
  },
};
</script>
