<template>
  <div>
    <detail :plaintext="true">
      <template #formBar>
        <b-btn-group>
          <k-button-back
            :back-route="isEvent ? 'eleTemplate' : 'template'"
            :back-query="{ tabindex: $route.query.tabindex }"
          >
            buttons.back
          </k-button-back>
          <k-button
            v-show="canBeEdit"
            @click="
              $router.push({
                name: isEvent ? 'eleTemplate-edit' : 'template-edit',
                query: {
                  id: $route.query.id,
                  tabindex: $route.query.tabindex,
                },
              })
            "
            v-permission="permissionCode.Report.TemplateEdit"
          >
            buttons.edit
          </k-button>
          <k-pre-next
            ref="pn"
            :has-save-next="false"
          />
          <k-button-delete
            :delete-handler="deleteHandler"
            :redirect-route-name="isEvent ? 'eleTemplate' : 'template'"
            :del-id="$route.query.id"
            @delete-failed="deleteFailed"
            v-permission="permissionCode.Report.TemplateDelete"
          />
        </b-btn-group>
      </template>
      <template #formMsg>
        <k-alert-msg ref="lblMsg" />
      </template>
    </detail>
  </div>
</template>

<script>
import Detail from "../../components/template-detail";
import { delReportTemplates, checkCanEdit } from "../../api/report";
import { beforeRouteLeaveMixin } from "../../../../utils/mixins/mixin";
import { repResult } from "../../../../libs/global";
import { saveAlert } from "../../../../utils/saveAlert";

export default {
  mixins: [beforeRouteLeaveMixin],
  data(){
    return {
      permissionCode: this.$route.meta.permissionCode,
      isEvent: this.$route.meta.isEvent ?? false,
      canBeEdit: false,
    };
  },
  computed: {
    deleteHandler: function (){
      return delReportTemplates;
    },
  },
  components: {
    Detail,
  },
  methods: {
    deleteFailed(m){
      this.$refs.lblMsg.message(m.data.status, m.message);
    },
    showAlert(){
      let status = saveAlert.savedStatus(this.$route);
      if(status === repResult.success){
        this.$refs.lblMsg.message(status, saveAlert.savedMessage(this.$route));
      }
    },
  },
  mounted(){
    let id = this.$route.query.id;
    if(id){
      checkCanEdit(id).then((resp) => {
        this.canBeEdit = resp.data;
      });
    }

    this.showAlert();
  },
};
</script>


