<template>
  <div class="electionbar">
    <div
      class="mr-1"
      v-if="electionId != null && electionId.length > 0"
    >
      {{ $g("electionSelect.event") + ":" }}
    </div>
    <k-form-select
      v-model="electionId"
      :options="electionList"
      :plaintext="true"
    />
  </div>
</template>
<script>
import { getElections } from "./election";
import storeage from "../../utils/storeage";

export default {
  props: {
    plaintext: {
      type: Boolean,
      default () {
        return false;
      },
    },
  },
  data () {
    return {
      electionId: storeage.getElectionId(),
      electionList: [],
    };
  },

  created: function () {
    if (this.electionId != null) {
      this.bindElections();
    }
  },
  methods: {
    bindElections: function () {
      getElections().then((data) => {
        const items = data.data;
        this.electionList = items || [];
        let result = items.filter(
            (election) => this.electionId === election.value,
        );
        storeage.setElectionStatus(result[0]?.status);
      });
    },
  },
};
</script>
