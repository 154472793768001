<template>
  <div>
    <template v-for="(item, index) in extFields">
      <div class="content-title" v-if="item.type === 25" :key="index">
      </div>
      <k-form-group
        v-else-if="item.type !== 0"
        :key="item.displayName"
        :label-class="item.isMandatory ? 'require-mark' : ''"
        :label-suffix="item.displayName"
        label-align-md="right"
        label-cols-md="3"
        content-cols-lg="6"
        content-cols-md="7"
        :no-locale="true"
        label-for="noneBind"
      >
        <KValidationProvider
          v-if="item.type === fieldType.string"
          :rules="{ required: item.isMandatory && isAllValidate, max: 200 }"
          v-slot="{ errors }"
          slim
          :name="`text${index}`"
        >
          <k-alert :show="errors.length > 0" variant="warning">
            {{ errors[0] }}
          </k-alert>
          <k-form-input
            v-if="item.type === fieldType.string"
            v-model="item.inputValue"
            type="text"
            :plaintext="handleplaintext(item.isOnlyView)"
            :placeholder="item.placeholder"
          />
        </KValidationProvider>
        <KValidationProvider
          v-if="item.type === fieldType.int"
          :rules="{
            required: item.isMandatory && isAllValidate,
            numberBetween: { minValue: -100000, maxValue: 100000 },
          }"
          v-slot="{ errors }"
          slim
          :name="`number${index}`"
        >
          <k-alert :show="errors.length > 0" variant="warning">
            {{ errors[0] }}
          </k-alert>
          <k-form-input
            v-if="item.type === fieldType.int"
            v-model="item.inputValue"
            :plaintext="handleplaintext(item.isOnlyView)"
          />
        </KValidationProvider>
        <KValidationProvider
          v-if="item.type === fieldType.decimal2"
          :rules="{
            required: item.isMandatory && isAllValidate,
            float:true
          }"
          v-slot="{ errors }"
          slim
          :name="`floatNumber${index}`"
        >
          <k-alert :show="errors.length > 0" variant="warning">
            {{ errors[0] }}
          </k-alert>
          <k-form-input
            v-if="item.type === fieldType.decimal2"
            v-model="item.inputValue"
            :plaintext="handleplaintext(item.isOnlyView)"
          />
        </KValidationProvider>
        <KValidationProvider
          v-if="item.type === fieldType.decimal3"
          :rules="{
            required: item.isMandatory && isAllValidate,
            floatThree:true
          }"
          v-slot="{ errors }"
          slim
          :name="`floatThreeNumber${index}`"
        >
          <k-alert :show="errors.length > 0" variant="warning">
            {{ errors[0] }}
          </k-alert>
          <k-form-input
            v-if="item.type === fieldType.decimal3"
            v-model="item.inputValue"
            :plaintext="handleplaintext(item.isOnlyView)"
          />
        </KValidationProvider>
        <KValidationProvider
          v-if="item.type === fieldType.datetime"
          :rules="{
            required: item.isMandatory && isAllValidate,
            validateDateIsRequired: item.isMandatory && isAllValidate,
            validateDateFormat: true,
          }"
          slim
          :name="`datetime${index}`"
          v-slot="{ errors }"
        >
          <k-alert :show="errors.length > 0 && !plaintext" variant="warning">
            {{ errors[0] }}
          </k-alert>
          <k-form-datepicker
            v-if="item.type === fieldType.datetime"
            v-model="item.inputValue"
            class="mb-2"
            :plaintext="handleplaintext(item.isOnlyView)"
          />
        </KValidationProvider>
        <KValidationProvider
          v-if="item.type === fieldType.phone"
          :rules="{
            required: item.isMandatory && isAllValidate,
            phone: true,
            max: 50,
          }"
          v-slot="{ errors }"
          slim
          :name="`phone${index}`"
        >
          <k-alert :show="errors.length > 0" variant="warning">
            {{ errors[0] }}
          </k-alert>
          <k-form-phone-input
            v-if="item.type === fieldType.phone"
            v-model="item.inputValue"
            :plaintext="handleplaintext(item.isOnlyView)"
            :is-phone="true"
          />
        </KValidationProvider>
        <KValidationProvider
          v-if="item.type === fieldType.email"
          :rules="{
            required: item.isMandatory && isAllValidate,
            max: 200,
            email: true,
          }"
          v-slot="{ errors }"
          slim
          :name="`email${index}`"
        >
          <k-alert :show="errors.length > 0" variant="warning">
            {{ errors[0] }}
          </k-alert>
          <k-form-input
            v-if="item.type === fieldType.email"
            v-model="item.inputValue"
            type="text"
            :plaintext="handleplaintext(item.isOnlyView)"
          />
        </KValidationProvider>

        <KValidationProvider
          v-if="item.type === fieldType.select"
          :rules="{ required: item.isMandatory && isAllValidate }"
          v-slot="{ errors }"
          slim
          :name="`select${index}`"
        >
          <k-alert :show="errors.length > 0" variant="warning">
            {{ errors[0] }}
          </k-alert>
          <k-form-select
            v-if="item.type === fieldType.select"
            v-model="item.inputValue"
            :plaintext="handleplaintext(item.isOnlyView)"
            :options="item.options"
          />
        </KValidationProvider>
      </k-form-group>
    </template>
  </div>
</template>
<script>
export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    isPlaintextNeed: {
      type: Boolean,
      default: true,
    },
    pagekey: {
      type: String,
      default() {
        return null;
      },
    },
    value: {
      type: Object,
      default() {
        return null;
      },
    },
    plaintext: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isAllValidate: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  methods: {
    handleplaintext(text) {
      if (this.isPlaintextNeed) {
        return this.plaintext;
      } else {
        return text;
      }
    },
    getInputValue(form, efields) {
      let result = {};
      if (efields) {
        efields.forEach((el) => {
          result[el.fieldName] = el.inputValue;
        });
        Object.assign(form, result);
      }
      return form;
    },
    setInputValue() {
      this.extFields.forEach((c, index) => {
        this.$set(c, "inputValue", this.value.extFields[index].value);
      });
    },
  },
  data() {
    return {
      extFields: [],
      fieldType: {
        string: 1,
        int: 2,
        datetime: 3,
        phone: 8,
        email: 9,
        select: 13,
        decimal2:27,
        decimal3:28,
      },
    };
  },
  computed: {},
  mounted() {
    let ef = this.value.extFields || this.value.fields;
    if (ef) {
      ef.forEach((el) => {
        let v = this.value[el.fieldName];
        if (v) {
          el.inputValue = v;
        }
      });
    }
    this.extFields = ef;
  },
  watch: {
    value() {
      let e = this.value.extFields;
      if (e) {
        e.forEach((l) => {
          let v = this.value[l.fieldName];
          if (v) {
            l.inputValue = v;
          }
        });
      }
      this.extFields = e;
    },
    extFields: {
      handler: function (newVal) {
        let merged = this.getInputValue(this.value, newVal);
        this.$emit("change", merged);
      },
      deep: true,
    },
  },
};
</script>
