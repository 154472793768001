<template>
  <div>
    <b-table-simple
      :bordered="hasBordered"
      responsive="sm"
      ref="selectableTable"
      no-provider-paging
      :sticky-header="stickyHeader"
      :class="{ stickyHeaderBorder: stickyHeader }"
    >
      <colgroup>
        <col
          style="width: 50px"
          v-if="hasCheckBox"
        >
        <col
          :key="item.key"
          v-for="item in gridFields"
          :style="`width:${item.width};`"
        >
        <col v-if="hasAction">
      </colgroup>

      <b-thead>
        <b-tr>
          <b-th v-if="hasCheckBox">
            <b-checkbox
              @change="toggleSelect"
              :checked="isCheckedAll"
            />
          </b-th>
          <b-th
            v-for="item in gridFields"
            :key="item.guid"
            :aria-sort="item.sort"
            @click="changeSort(item)"
          >
            {{ item.label }}
          </b-th>
          <b-th v-if="hasAction">
            Actions
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <template v-for="item in items">
          <b-tr v-for="(detail, index) in item.detailInfo" :key="detail.id">
            <b-th v-if="hasCheckBox" class="bg-white" :key="`checkbox${index}`">
              <b-checkbox
                :value="detail"
                v-model="checkedDetails"
              />
            </b-th>

            <template v-for="field in gridFields">
              <b-td
                style="vertical-align: middle"
                class="bg-white"
                v-if="index === 0 || field.level !== 0"
                :rowspan="field.level === 0 ? item.detailInfo.length : 1"
                :key="`${field.guid}${index}`"
              >
                <slot
                  name="todo"
                  v-bind="{
                    fieldKey: field.key,
                    fieldValue: getFieldValue(field, item, detail),
                    detail: detail,
                  }"
                >
                  <span>
                    {{ getFieldValue(field, item, detail) }}
                  </span>
                </slot>
              </b-td>
            </template>

            <b-td
              style="vertical-align: middle"
              v-if="hasAction && index === 0"
              :rowspan="item.detailInfo.length"
              class="bg-white"
              :key="`edit${index}`"
            >
              <b-link
                @click="info(detail, index, $event.target)"
                class="mr-1"
              >
                {{ actionName }}
              </b-link>
            </b-td>
          </b-tr>
        </template>
      </b-tbody>
    </b-table-simple>
    <div
      class="form-inline table-pagination"
      v-show="hasPager"
    >
      <div>
        <b-form-select
          id="ddlPageSelect"
          v-model="perPage"
          :options="pageOptions"
          size="sm"
          @change="pageSizeChange"
          class="mr-1 w-auto"
        />
      </div>
      <div>
        <b-pagination
          :total-rows="totalRows"
          :per-page="perPage"
          size="sm"
          class="my-0 mt-1 d-inline-flex"
          @change="onPaginationChange"
          v-model="currentPage"
          :key="paginationKey"
        />
      </div>
      <div>
        <span class="mr-3">{{ totalRowsDisplay }} total record(s) </span>
        {{ $g("konnGrid.goTo") }}
        <vue-typeahead-bootstrap
          v-model="currentPageStr"
          :data="pageSelectOptions"
          :show-on-focus="true"
          @input="handlePageInput"
          class="page-change"
          @hit="handlePageSelect"
          @keyup.enter="handlePageBlur"
          size="sm"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { pageNavUtil } from "../../utils/pageNavUtil";
import VueTypeaheadBootstrap from "vue-typeahead-bootstrap";
import eventBus from "../../utils/eventBus";

/**
 * api data format
 * fields: level=0 need set rowspan number
 *
 * {
    "code":200,
    "data":{
        "total":1,
        "rows":[
            {
                "totalpayment":3,
                "detailInfo":[
                    {
                        "grandTotalPaymentStr":"$ 3.00",
                        "id":"5dc93622-6459-4b60-94fc-91ad855158a0",
                        "lastModifyTime":"04/09/2021 03:11:28 上午",
                        "eleFacilityId":"e3d2ff94-9739-4d40-806a-9ec56cf361a9",
                        "lastModifyBy":"Test",
                        "status":"Voided",
                        "electionId":"b69d3b4e-4fd1-4e38-1de2-08d8acba0f8d",
                        "penumber":"PETest20210309085944"
                    },
                    {
                        "grandTotalPaymentStr":"$ 3.00",
                        "id":"20df7131-01a8-4d4f-8d8d-02a8fb62b57c",
                        "lastModifyTime":"04/08/2021 03:17:27 上午",
                        "eleFacilityId":"e3d2ff94-9739-4d40-806a-9ec56cf361a9",
                        "lastModifyBy":"Test",
                        "status":"Confirmed",
                        "electionId":"b69d3b4e-4fd1-4e38-1de2-08d8acba0f8d",
                        "penumber":null
                    }
                ],
                "totalpaymentStr":"$ 3.00",
                "eleFacilityId":"e3d2ff94-9739-4d40-806a-9ec56cf361a9",
                "orgName":"00002 cyl test Organization",
                "electionId":"b69d3b4e-4fd1-4e38-1de2-08d8acba0f8d",
                "name":"00002 cyl test regular active from voting stations"
            }
        ],
        "letters":null,
        "fields":[
            {
                "key":"Name",
                "label":"Facility",
                "sortable":true,
                "width":"20%",
                "orderIndex":0,
                "required":true,
                "isDefault":false,
                "horAlignment":1,
                "isDynamicCondition":true,
                "isMerge":true,
                "fieldType":0,
                "level":0,
                "replaceKey":null
            },
            {
                "key":"Status",
                "label":"Status",
                "sortable":false,
                "width":"20%",
                "orderIndex":0,
                "required":false,
                "isDefault":false,
                "horAlignment":1,
                "isDynamicCondition":false,
                "isMerge":true,
                "fieldType":0,
                "level":1,
                "replaceKey":null
            },
            {
                "key":"Penumber",
                "label":"SPA Event",
                "sortable":false,
                "width":"20%",
                "orderIndex":0,
                "required":false,
                "isDefault":false,
                "horAlignment":1,
                "isDynamicCondition":false,
                "isMerge":true,
                "fieldType":0,
                "level":1,
                "replaceKey":null
            },
            {
                "key":"OrgName",
                "label":"Organization",
                "sortable":false,
                "width":"20%",
                "orderIndex":0,
                "required":false,
                "isDefault":false,
                "horAlignment":1,
                "isDynamicCondition":true,
                "isMerge":true,
                "fieldType":0,
                "level":0,
                "replaceKey":null
            },
            {
                "key":"LastModifyTime",
                "label":"Modified Date/Time",
                "sortable":false,
                "width":"20%",
                "orderIndex":0,
                "required":false,
                "isDefault":false,
                "horAlignment":1,
                "isDynamicCondition":false,
                "isMerge":true,
                "fieldType":3,
                "level":1,
                "replaceKey":null
            },
            {
                "key":"LastModifyBy",
                "label":"Modified By",
                "sortable":false,
                "width":"20%",
                "orderIndex":0,
                "required":false,
                "isDefault":false,
                "horAlignment":1,
                "isDynamicCondition":false,
                "isMerge":true,
                "fieldType":0,
                "level":1,
                "replaceKey":null
            },
            {
                "key":"GrandTotalPaymentStr",
                "label":"Payment",
                "sortable":false,
                "width":"20%",
                "orderIndex":0,
                "required":true,
                "isDefault":false,
                "horAlignment":2,
                "isDynamicCondition":false,
                "isMerge":true,
                "fieldType":0,
                "level":1,
                "replaceKey":null
            },
            {
                "key":"TotalpaymentStr",
                "label":"Grand Total",
                "sortable":true,
                "width":"20%",
                "orderIndex":0,
                "required":false,
                "isDefault":false,
                "horAlignment":2,
                "isDynamicCondition":true,
                "isMerge":true,
                "fieldType":4,
                "level":0,
                "replaceKey":"Totalpayment"
            }
        ]
    },
    "desc":"Successfully",
    "warning":"",
    "exception":null
}
 */

export default {
  components: { VueTypeaheadBootstrap },
  props: {
    hasBordered: {
      type: Boolean,
      default(){
        return true;
      },
    },
    idField: {
      type: String,
      default(){
        return null;
      },
    },
    hasPager: {
      type: Boolean,
      default(){
        return true;
      },
    },
    listId: {
      type: String,
      default(){
        return null;
      },
    },
    getCondition: {
      type: Function,
      default(){
        return null;
      },
    },
    dataSource: {
      type: Function,
      default(){
        return null;
      },
    },
    hasCheckBox: {
      type: Boolean,
      default(){
        return true;
      },
    },
    hasAction: {
      type: Boolean,
      default(){
        return true;
      },
    },
    actionName: {
      type: String,
      default(){
        return this.$g("konnGrid.edit");
      },
    },
    setParas: {
      type: Function,
      default(){
        return null;
      },
    },
    gridSortBy: {
      type: String,
      default(){
        return null;
      },
    },
    gridSortDesc: {
      type: Boolean,
      default(){
        return false;
      },
    },
    stickyHeader: {
      type: Boolean,
      default(){
        return false;
      },
    },
    autoBind: {
      type: Boolean,
      default(){
        return true;
      },
    },
  },
  data(){
    return {
      ariaSort: {
        none: "none",
        desc: "descending",
        asc: "ascending",
      },
      perPage: 20,
      totalRows: - 1,
      currentPage: 1,
      currentPageStr: "",
      pageOptions: [
        { value: 5, text: this.$g("konnGrid.page5") },
        { value: 20, text: this.$g("konnGrid.page20") },
        { value: 50, text: this.$g("konnGrid.page50") },
        { value: 100, text: this.$g("konnGrid.page100") },
      ],
      sortBy: this.gridSortBy,
      sortDesc: this.gridSortDesc,
      items: [],
      gridFields: [],
      isCheckedAll: false,
      defCheckedDetails: [],
      checkedDetails: [],
      paginationKey: 0,
      pageSelectOptions: [],
    };
  },
  computed: {
    totalRowsDisplay(){
      if(this.totalRows > 0){
        return this.totalRows;
      }
      return 0;
    },
  },
  methods: {
    firstWordtoLowerCase(str){
      return str.replace(str[0], str[0].toLowerCase());
    },
    getFieldValue(field, item, detail){
      return field.level === 0
          ? item[this.firstWordtoLowerCase(field.key)]
          : detail[this.firstWordtoLowerCase(field.key)];
    },
    sortingChanged(field){
      if(field.sortable){
        this.sortBy = field.key;
        this.sortDesc = field.sort === this.ariaSort.desc;
        let cd;
        let pageState = this.$store.getters.pageState[this.listId];
        if(pageState){
          cd = pageState.condition;
        }
        this.bindGrid(cd);
      }
    },
    onPaginationChange(value){
      this.currentPageStr = "";
      this.paginationChange(value);
    },
    paginationChange(value){
      if(value > 0){
        var cd;
        this.currentPage = value;
        let pageState = this.$store.getters.pageState[this.listId];
        if(pageState){
          cd = pageState.condition;
        }
        this.bindGrid(cd);
      }
    },
    changeSort(field){
      if(field.sortable){
        this.gridFields.forEach((element) => {
          if(element.key !== field.key && element.sortable){
            element.sort = this.ariaSort.none;
          }
        });
        if(field.sort === this.ariaSort.asc){
          field.sort = this.ariaSort.desc;
        } else {
          field.sort = this.ariaSort.asc;
        }
        field.guid = this.guid();
        this.sortingChanged(field);
      }
    },
    pageChange(value){
      if(value > 0){
        this.currentPage = value;
        this.currentPageStr = "";
        this.bindGrid();
      }
    },

    handlePageBlur(){
      if(this.currentPage !== this.currentPageStr){
        this.paginationChange(parseInt(this.currentPageStr));
      }
    },
    handlePageInput(){
      if(isNaN(parseInt(this.currentPageStr))){
        this.currentPageStr = "";
      } else {
        var page = parseInt(this.currentPageStr);
        var pageCount = (this.totalRows + this.perPage - 1) / this.perPage;
        if(page < 1) page = 1;
        else if(page > pageCount) page = parseInt(pageCount);
        this.currentPageStr = page + "";
      }
    },
    handlePageSelect(val){
      this.paginationChange(parseInt(val.value));
    },
    pageSizeChange(){
      this.currentPage = 1;
      this.currentPageStr = "";
      this.paginationChange(this.currentPage);
    },
    getPageSelectOption(){
      let array = [];
      let pageCount = (this.totalRows + this.perPage - 1) / this.perPage;
      for (let i = 0; i < parseInt(pageCount); i ++) {
        array.push(i + 1 + "");
      }
      this.pageSelectOptions = array;
    },
    getSearchState(cdtion){
      if(!cdtion){
        cdtion = this.getCondition();
      }
      let condition = this.getPageCondition();
      Object.assign(condition, cdtion);
      return condition;
    },
    getPageState(cdtion){
      let page = this.getPageAdditional();
      let rt = {};
      rt.pageAddtional = page;
      rt.condition = { ...cdtion };
      return rt;
    },
    getPageCondition(){
      let param = {
        Sort: this.sortBy,
        Order: this.sortDesc ? "Desc" : "Asc",
        SelectedGuidIDs: this.getSelectedIds(this.idField),
      };
      if(this.hasPager){
        param.Offset = (this.currentPage - 1) * this.perPage;
        param.Limit = this.perPage;
      }
      return param;
    },
    getPageAdditional(){
      return {
        ids: this.getAllEndIds(),
        currentPage: this.currentPage,
        rawCurrentPage: this.currentPage,
        perPage: this.perPage,
        sortBy: this.sortBy,
        sortDesc: this.sortDesc,
        totalRows: this.totalRows,
        navHandler: this.dataSource,
        hasPager: this.hasPager,
        routeName: this.$route?.name,
        query: this.$route?.query,
        customField: this.getAllCustomField(),
      };
    },
    getAllEndIds(){
      return pageNavUtil.getAllEndIds(this.items);
    },
    getAllCustomField() {
      return pageNavUtil.getAllCustomField(this.items);
    },
    getAllRows(){
      return this.items;
    },
    confirmOpt(msg){
      if(!this.checkedDetails || this.checkedDetails.length === 0){
        this.$alert({
          title: this.$g("const.confirm.confirm"),
          content: this.$g("const.warning.select-records"),
          okText: this.$g("const.confirm.ok"),
        });
        return null;
      }
      return this.$confirm({
        title: this.$g("const.confirm.confirm"),
        content: msg,
        okText: this.$g("const.confirm.yes"),
        cancelText: this.$g("const.confirm.no"),
        cancelType: "secondary",
      });
    },
    getSelectedItems(){
      return this.checkedDetails;
    },
    getSelectedIds(ifn){
      return this.getIds(ifn, this.checkedDetails);
    },
    getAllIds(ifn){
      return this.getIds(ifn, this.items);
    },
    getIds(ifn, lst){
      let result = [];
      if(lst){
        lst.forEach((e) => {
          if(!ifn){
            ifn = "id";
          }
          if(e[ifn]){
            result.push(e[ifn]);
          }
        });
      }
      return result;
    },
    refresh(){
      let searchArea = this.$parent.$refs?.searchArea;
      let isSearchArea = searchArea?.getIsSearchArea() ?? true;
      if(searchArea && isSearchArea === false && this.autoBind){
        this.items = [];
      } else {
        if(isSearchArea === true || this.autoBind){
          this.bindGrid();
        }
      }
    },
    toggleSelect(checked){
      if(checked){
        this.checkedDetails = this.defCheckedDetails;
      } else {
        this.checkedDetails = [];
      }
    },
    addNewRow(data){
      this.items.unshift(data);
    },
    insertNewRow(data, index){
      this.items.splice(index, 0, data);
    },
    deleteNewRow(data){
      let length = this.items.length;
      for (let i = 0; i < length; i ++) {
        if(this.items[i] === data){
          this.items.splice(i, 1); //删除下标为i的元素
        }
      }
    },
    deleteSelected(){
      if(this.checkedDetails){
        for (let value of this.checkedDetails) {
          this.deleteNewRow(value);
        }
      }
    },
    guid(){
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
          /[xy]/g,
          function (c){
            const crypto = window.crypto || window.msCrypto;
            var array = new Uint32Array(1);
            var random = crypto.getRandomValues(array) / Math.pow(2, 32);
            var r = (random * 16) | 0,
                v = c === "x" ? r : (r & 0x3) | 0x8;
            return v.toString(16);
          },
      );
    },
    bindGrid(condition){
      if(!condition){
        condition = this.getCondition();
      }
      let searchState = this.getSearchState(condition);
      return this.dataSource(searchState).then((data) => {
        this.gridFields = data.data.fields;
        this.$emit("fields-get", data.data.fields);

        this.gridFields.forEach((element) => {
          element.guid = this.guid();
          if(element.sortable){
            element.sort = this.ariaSort.none;
          }
          if(this.firstWordtoLowerCase(element.key) === this.firstWordtoLowerCase(this.sortBy)){
            element.sort = this.sortDesc
                ? this.ariaSort.desc
                : this.ariaSort.asc;
          }
        });

        let rows = data.data.rows;
        this.defCheckedDetails = [];
        this.checkedDetails = [];
        rows.forEach((item) => {
          item.detailInfo.forEach((detail) => {
            this.defCheckedDetails.push(detail);
          });
        });

        this.items = data.data.rows;
        this.totalCount = data.data.total;
        this.totalRows = data.data.total;

        let pageState = this.getPageState(condition);
        if(this.$store){
          this.$store.dispatch("page/savePageState", {
            listId: this.listId,
            condition: pageState,
          });
        }

        this.getPageSelectOption();
      });
    },

    info(item, index, target){
      this.$emit("edit-clicked", item, index, target);
    },
  },
  watch: {
    totalRows(newValue){
      this.$emit("total-changed", newValue);
    },

    checkedDetails(){
      this.isCheckedAll =
          this.defCheckedDetails.length === this.checkedDetails.length;
    },
  },
  mounted: function (){
    if(this.$route && this.$route.query.restore){
      let pageState = this.$store.getters.pageState[this.listId];
      if(pageState){
        let pa = pageState.pageAddtional;
        if(pa){
          this.totalRows = pa.totalRows;
          this.currentPage = pa.rawCurrentPage;
          if(this.currentPage > 1) this.paginationKey ++;
          this.perPage = pa.perPage;
          this.sortBy = pa.sortBy;
          this.sortDesc = pa.sortDesc;
        }
        let cd = pageState.condition;
        if(cd){
          this.setParas(cd);
        }
      }
    }
    if(this.autoBind){
      this.bindGrid();
    }
    eventBus.$on("defaultTemplateEnded", () => {
      this.bindGrid();
    });
  },
  beforeDestroy(){
    eventBus.$off("defaultTemplateEnded");
  },
};
</script>

<style lang="scss" scoped>
::v-deep .stickyHeaderBorder > table {
  border-top: 0;
}
</style>
