<template>
  <detail
    :is-event="isEvent"
    :is-plain-text="true"
    :id="id"
    @save-successful="onSaveSuccessful"
    :isNotification="isNotification"
    :propsWikiHelpCode="wikiHelpCode"
  >
    <template #formBar>
      <div>
        <k-button-back :back-route="getBackRoute()">
          buttons.back
        </k-button-back>
        <k-button v-if="documentEdit" @click="onRedirectEdit">
          buttons.edit
        </k-button>
        <k-pre-next :has-save-next="false" ref="pn" />
        <k-button-delete
          v-if="documentDel"
          :delete-handler="deleteHandler"
          :redirect-route-name="getBackRoute()"
          :del-id="id"
          @delete-failed="deleteFailed"
          :redirect-query="{ tabindex: $route.query.tabindex }"
        />
      </div>
    </template>
    <template #formBarNav>
      <k-alert-msg ref="lblMsg" />
      <template-nav
        :is-event="isEvent"
        ref="nav"
        :tab-index="navIndex"
        :is-plain-text="true"
        :isNotification="isNotification"
      />
    </template>
  </detail>
</template>
<script>
import detail from "../../components/documentTemplateDetail.vue";
import { repResult } from "../../../../libs/global";
import templateNav from "../../components/template-nav.vue";
import { saveAlert } from "../../../../utils/saveAlert";
import { delTemplate } from "../../api/messageTemplate";
import { has } from "../../../../utils/permission/operatePermission";
import storeage from "../../../../utils/storeage";
import { permissionCode } from "../../mixins/permissionCode";

export default {
  mixins: [permissionCode],
  components: { detail, templateNav },
  data() {
    let isEvent = this.$route.meta.isEvent;
    return {
      isEvent: isEvent,
      id: this.$route.query.id,
      electionId: isEvent ? storeage.getElectionId() : "",
      navIndex: 0,
      documentEdit: true,
      documentDel: true,
      wikiHelpCode: isEvent
        ? this.$wikiHelpLinkCodes?.Event.Communication.Template.Document
            .BasicInfo
        : this.$wikiHelpLinkCodes?.BAU.Communication.Template.Document.BasicInfo,
    };
  },
  computed: {
    deleteHandler: function () {
      return delTemplate;
    },
  },
  methods: {
    getBackRoute() {
      let routeName = "";
      if (this.isNotification) {
        routeName = this.isEvent
          ? "eleNotificationDocumentTemplateList"
          : "notificationDocumentTemplateList";
      } else {
        routeName = this.isEvent
          ? "eleDocumentTemplateList"
          : "documentTemplateList";
      }
      return routeName;
    },
    getRouteNameView() {
      let routeName = "";
      if (this.isNotification) {
        routeName = this.isEvent
          ? "eleNotificationDocumentTemplateView"
          : "notificationDocumentTemplateView";
      } else {
        routeName = this.isEvent
          ? "eleDocumentTemplateView"
          : "documentTemplateView";
      }
      return routeName;
    },
    getRouteNameEdit() {
      let routeName = "";
      if (this.isNotification) {
        routeName = this.isEvent
          ? "eleNotificationDocumentTemplateEdit"
          : "notificationDocumentTemplateEdit";
      } else {
        routeName = this.isEvent
          ? "eleDocumentTemplateEdit"
          : "documentTemplateEdit";
      }
      return routeName;
    },
    setPermission() {
      if (this.isEvent) {
        this.documentEdit = has(
          this.permissionCodeEvent.Communication.DocumentationEdit
        );
        this.documentDel = has(
          this.permissionCodeEvent.Communication.DocumentationDelete
        );
      } else {
        this.documentEdit = has(
          this.permissionCode.Communication.DocumentationEdit
        );
        this.documentDel = has(
          this.permissionCode.Communication.DocumentationDelete
        );
      }
    },
    deleteFailed(m) {
      this.$refs.lblMsg.message(m.data.status, m.message);
    },
    onRedirectEdit() {
      this.$router.push({
        name: this.getRouteNameEdit(),
        query: {
          id: this.$route.query.id,
          isView: "0",
          hidePreNext: this.$route.query.hidePreNext,
          dbtn: this.$route.query.dbtn,
          templateType: this.$route.query.templateType,
        },
      });
    },
    onSaveSuccessful(m) {
      this.isDoSave = true;
      this.$router.push({
        name: this.getRouteNameEdit(),
        query: {
          id: m.data.info,
          message: m.message,
          status: m.data.status,
          isView: "1",
          hidePreNext: this.$route.query.hidePreNext,
          dbtn: this.$route.query.dbtn,
          templateType: this.$route.query.templateType,
        },
      });
    },
    showAlert() {
      let status = saveAlert.savedStatus(this.$route);
      if (status === repResult.success) {
        this.$refs.lblMsg.message(status, saveAlert.savedMessage(this.$route));
      }
    },
  },
  mounted() {
    this.showAlert();
    this.setPermission();
  },
};
</script>
