import AxiosHttp from "../libs/http";
import store from "@/store";
import router from "../router/routerProvider";
import SingleLock from "../libs/lock";
import { getToken } from "./token";
import storeage from "./storeage";
import { g } from "../locale/lang-val";
import Message from "./alertMessage";
import globalEnv from "../libs/globalEnv";
import {logout} from "fe-infrastractures";

const http = new AxiosHttp();
const lock = new SingleLock();

const __webpack_define_ = (val, def) => {
  return val == undefined ? def : val;
};

const __webpack_loading_ = {
  open: function (config){
    if(config.loading){
      store.dispatch("app/loading", true);
    }
  },
  close: function (config){
    if(config.loading){
      store.dispatch("app/loading", false);
    }
  },
};

/**
 * 是否显示出错信息提示
 */
let showErrorMessage = true;

const handleBeforeFunc = (config) => {
  return lock.invoke(config.ignoreLock, (resolve) => {
    if(!config.baseURL){
      config.baseURL = globalEnv.VUE_APP_STD_URL;
    }
    if(!config.isApiDebug){
      let urlLower = config.url.toLowerCase();
      if(!urlLower.startsWith("/auth") && globalEnv.VUE_APP_SYSTEM_PATH){
        let sp = "";
        if(!config.url.startsWith("/")){
          sp = "/";
        }
        let pathSuff = "/" + globalEnv.VUE_APP_SYSTEM_PATH + sp;
        // eslint-disable-next-line no-prototype-builtins
        if(config.hasOwnProperty("systemPATH") && config.systemPATH){
          pathSuff = "/" + config.systemPATH + sp;
        }
        if(!urlLower.startsWith(pathSuff)){
          config.url = pathSuff + config.url;
        }
      }
    }
    let conType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    if(config.useBody){
      conType = {
        "Content-Type": "application/json",
      };
    }
    config.headers = Object.assign(conType, config.headers);
    config.headers["Authorization"] = getToken()?.token;
    if(router.currentRoute.name == "reportKeyPreview"){
      console.log(router.currentRoute, router.currentRoute.path.split("/"));
      let pathStrArray = router.currentRoute.path.split("/");
      config.headers["Authorization"] = getToken(`${pathStrArray[1]}_reportKeyPreview`);
    }
    config.headers["languageId"] = storeage.getLanguageId();

    resolve(config);
  });
};

/**
 * 发起请求之前，处理请求参数
 * 设置系统状态默认值
 * 设置BaseURL为环境变量中的值
 * 设置Headers.Content-Type: application/x-www-form-urlencoded，
 * 设置Headers.Authorization: store.getters.token
 * @param {Object}} config 请求参数
 */
function handleBefore(config){
  config.loading = __webpack_define_(config.loading, true); //是否显示加载动画
  config.redirect = __webpack_define_(config.redirect, true); //是否重定向
  config.throwError = __webpack_define_(config.throwError, false); //是否抛出异常
  config.allowAnonymous = __webpack_define_(config.allowAnonymous, false); //是否允许匿名
  config.ignoreLock = __webpack_define_(config.ignoreLock, false); //是否忽略Lock锁
  showErrorMessage = __webpack_define_(config.showErrorMessage, true);
  __webpack_loading_.open(config);
  return handleBeforeFunc(config);
}

const handleFailedFunc401 = (error, config, redirect, resolve, reject) => {
  if(config.refreshToken){
    logout();
    router.push({
      name: "login"
    });
  } else {
    // eslint-disable-next-line no-use-before-define
    handleFailedFuncRefreshToken(config, resolve, reject);
  }
};
const handleFailedFuncNot401 = (status, statusText, message, data, reject, config) => {
  if(status != 602 && status != 600 && status != 430){
    if(message == "Network Error"){
      Message(message, message, "Please check the network environment or try again.");
    } else if(!storeage.getSystemGeneralInfo().disabledResponseErrorMessage && !config.hideError){
      Message(g("error"), status + ": " + message + " " + statusText + " " + data);
    } else {
      reject();
    }
  } else {
    let msg = message;
    if(status == 430){
      lock.unlock({ refreshToken: false });
      msg = g("tokenTimedOut");
    }
    storeage.timeSet("tokenOut", msg, 8);
    if(window.location.origin){
      window.location.href = window.location.origin;
    } else {
      logout();
      router.push({
        name: "login"
      });
    }
  }
};

/**
 * 请求失败或响应失败
 * 401异常：Token令牌过期
 * @param {Error} error 异常对象
 */
function handleFailed(error){
  return new Promise((resolve, reject) => {
    const status = error.status || error.response?.status;
    const config =
      error.config || error.response?.config || error.request?.config;
    const statusText = error.statusText || error.response?.statusText;
    const message =
      error.message || error.response?.message || error.response?.data?.desc;
    const data = error.data || error.response?.data;
    __webpack_loading_.close(config);
    const redirect = (curError, routeName) => {
      const curConfig =
        curError.config ||
        curError.response?.config ||
        curError.request?.config ||
        config;

      if(curConfig.redirect === true){
        http.cancel();
        router.push({ name: routeName });
      }
    };

    if(!showErrorMessage && status != 401){
      return;
    }
    if(status == 401){
      handleFailedFunc401(error, config, redirect, resolve, reject);
    } else {
      handleFailedFuncNot401(status, statusText, message, data, reject, config);
    }
  });
}

/**
 * Token令牌超时失效，通过RefreshToken令牌刷新Token令牌
 * 刷新成功则重新执行401异常的请求
 * 刷新失败则跳转到登录页面
 */
function handleSucced(response){
  return new Promise((resolve, reject) => {
    const { data, config, request } = response;
    const BM = { status: data?.code, data: data?.data, message: data?.desc };

    if(response.config.responseType === "blob"){
      BM.status = 200;
      BM.data = data;
    }

    __webpack_loading_.close(config);
    if(BM.status == 200){
      resolve(BM);
    } else {
      let error = new Error(BM.message);
      error.status = BM.status;
      error.request = request;
      error.response = response;

      if(response?.config?.noHttpIntercept){//api http response no intercept  sp:login page
        reject(error);
      } else {
        handleFailed(error)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      }
    }
  });
}

/**
 * 请求成功之后，处理业务逻辑
 * 响应的Response.Data对象默认为｛Code, Data, Desc｝
 * response.data.code相当于http.status，默认200为成功
 * @param {Object} response 响应对象
 */
function refreshToken(){
  return lock.invoke(false, (resolve, reject, data) => {
    if(data instanceof Object){
      if(data.refreshToken){
        resolve();
      }
    } else {
      lock.locked();
      store
        .dispatch("user/refreshToken")
        .then((res) => {
          lock.unlock({ refreshToken: true });
          resolve(res);
        })
        .catch((err) => {
          lock.unlock({ refreshToken: false });
          reject(err);
        });
    }
  });
}

const handleFailedFuncRefreshToken = (config, resolve, reject) => {
  refreshToken().then(() => {
    config.refreshToken = true;
    http
      .request(config)
      .then(resolve)
      .catch(reject);
  })
    .catch(() => {
      Logout();
      router.push({ name: "login" });
    });
};

http.before = handleBefore;
http.requestFailed = handleFailed;
http.responseSucced = handleSucced;
http.responseFailed = handleFailed;

export default {
  get: (...args) => http.get(...args),
  put: (...args) => http.put(...args),
  post: (...args) => http.post(...args),
  delete: (...args) => http.delete(...args),
  down: (...args) => http.down(...args),
};
