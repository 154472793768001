export const permissionCode = {
  props: {
    isPermissionCode: {
      type: Boolean,
      default () {
        return true
      },
    },
    permissionCode: {
      type: Object,
      default () {
        return null
      },
    },
    permissionCodeEvent: {
      type: Object,
      default () {
        return null
      },
    },
    isNotification: {
      type: Boolean,
      default () {
        return false;
      }
    }
  },
}
