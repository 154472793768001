export const startTaskProgress = (taskId) => {
    if (!window.taskIds) {
        window.taskIds = [];
    }
    window.taskIds.push(taskId);
}

export const startTaskProgressWorker = (taskId) => {
    if (!window.workerTaskIds) {
        window.workerTaskIds = [];
    }
    window.workerTaskIds.push(taskId);
}