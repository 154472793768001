var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-page"},[(_vm.isEvent)?_c('election-select'):_vm._e(),_c('KValidationObserver',{ref:"observer",staticClass:"content-page",scopedSlots:_vm._u([{key:"default",fn:function(valdata){return [_c('k-form',{ref:"form",staticClass:"content-form",on:{"reset":_vm.onReset,"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"nav-btn-group"},[_c('k-button-group',[_vm._t("formBar",null,null,valdata)],2),_c('KWikiHelp',{attrs:{"props-wiki-help-code":_vm.propsWikiHelpCode,"props-has-wiki-help":!_vm.isNotification}})],1),_c('div',{staticClass:"content-main"},[_c('k-alert-msg',{ref:"lblMsg"}),_c('div',{staticClass:"content-navTab"},[_vm._t("formBarNav")],2),_c('div',{staticClass:"content-party pt-3"},[_c('div',{staticClass:"content-group"},[_c('k-form-group',{attrs:{"label-class":"require-mark","label-suffix":"category","label-for":"Category","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('KValidationProvider',{attrs:{"rules":{ required: true },"slim":"","name":"category"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-select',{attrs:{"options":_vm.categories,"plaintext":_vm.isPlainText},model:{value:(_vm.form.category),callback:function ($$v) {_vm.$set(_vm.form, "category", $$v)},expression:"form.category"}})]}}],null,true)})],1),_c('k-form-group',{attrs:{"label-class":"require-mark","label-suffix":"fileTitle","label-for":"Subject","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('KValidationProvider',{attrs:{"rules":{
                  required: true,
                  max: 200,
                  validateSpecialCharacter: true,
                  remote: _vm.isSubjectExits,
                },"slim":"","name":"fileTitle"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-input',{attrs:{"plaintext":_vm.isPlainText},model:{value:(_vm.form.subject),callback:function ($$v) {_vm.$set(_vm.form, "subject", $$v)},expression:"form.subject"}})]}}],null,true)})],1),_c('k-form-group',{attrs:{"label-class":"require-mark","label-suffix":"file","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('KValidationProvider',{attrs:{"rules":{
                  required: true,
                  acceptFileType: _vm.accept,
                  maxFileSize: _vm.size * 1024,
                },"slim":"","name":"file"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('common-upload',{key:_vm.uploadKey,ref:"fileinput",attrs:{"accept":_vm.accept,"size":_vm.size,"is-multiple":false,"on-delete":_vm.onDelete,"placeholder":_vm.placeholder,"show-message":false,"is-use-internal-validate":false,"plaintext":_vm.isPlainText},model:{value:(_vm.form.file),callback:function ($$v) {_vm.$set(_vm.form, "file", $$v)},expression:"form.file"}})]}}],null,true)})],1)],1)])],1)])]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }