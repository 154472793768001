<template>
  <router-view :key="$route.fullPath" v-if="isRouterAlive" />
</template>
<script>
import { getUserNameRegex, getPasswordRegex } from "./api/app.js";
export default {
  data() {
    return {
      isRouterAlive: true,
    };
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
    getUserNameAndPwdRege() {
      getUserNameRegex().then((res) => {
        localStorage.setItem("userNameRex", res.data);
      });
      getPasswordRegex().then((res) => {
        localStorage.setItem("passwordRegex", res.data);
      });
    },
  },
  mounted() {
    this.getUserNameAndPwdRege();
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
};
</script>
