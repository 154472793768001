import http from "../../../utils/http";
import globalEnv from "../../../libs/globalEnv";


export const getDrafts = (data) => {
  return http.post("/api/msgdraft/page", data);
};

export const getDraft = (id) => {
  return http.get(`/api/msgdraft/${id}`);
};

export const getLetterDraft = (id) => {
  return http.get(`/api/msgdraft/letter/${id}`);
};

export const getSumcount = (data) => {
  return http.post("/api/msgdraft/sumcount", data, {
    loading: true,
    baseURL: globalEnv.VUE_APP_STD_URL,
  });
};

export const getActions = (data) => {
  return http.post(`/api/msgdraft/actions`, data);
};

export const delDraft = (ids) => {
  return http.delete("/api/msgdraft", { Ids: ids }, {
    loading: false,
    baseURL: globalEnv.VUE_APP_STD_URL,
  },
  );
};

export const delLetterDraft = (ids) => {
  return http.delete("/api/msgletter/deleteletter", { ids: ids }, {
    loading: false,
    baseURL: globalEnv.VUE_APP_STD_URL,
  },
  );
};
