import { router } from "fe-infrastractures";

const __define_json = (val) => {
    if (val && typeof val == 'string') {
        var valArr = val.split('&')
        return valArr.reduce((ins, str) => {
            var strAr = str.split('=')
            ins[strAr[0]] = strAr[1]
            return ins
        }, {})
    } else {
        return val
    }
}

const state = {
    logs: []
}

const mutations = {
    RECORD(state1, logInfo) {
        logInfo = Object.assign({
            type: 0, //100.操作日志，200.api日志，300.异常日志
            typeText: '',
            status: 0, //日志状态
            route: router.currentRoute,
            content: null,
            description: null,
            datetime: new Date()
        }, logInfo)

        state1.logs.push(logInfo)
    },
    REMOVE() {
        return
    }
}

const actions = {
    sys({ commit }, logInfo) {
        const { content, description } = logInfo

        commit('RECORD', {
            type: 100,
            typeText: 'Sys',
            status: 200,
            content: { Content: content },
            description: description || content
        })
    },
    api({ commit }, logInfo) {
        const { config, request, response, BM, description } = logInfo

        const content = {
            ApiUrl: request?.responseURL,
            Method: config?.method.toUpperCase(),
            Headers: config?.headers || {},
            Request: __define_json(config?.data),
            Response: BM
        }

        commit('RECORD', {
            type: 200,
            typeText: 'Api',
            status: BM?.code || response?.status || request?.status || 0,
            content: content,
            description: description || BM?.message
        })
    },
    err({ commit }, logInfo) {
        commit('RECORD', Object.assign({ type: 300 }, logInfo))
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
