import globalEnv from "../../libs/globalEnv";

const permissionKey = "__pollchief_permission_key";

export const setPermission = (Permission) => {
  return localStorage.setItem(permissionKey, Permission);
};

export const getPermission = () => {
  return localStorage.getItem(permissionKey);
};

export const removePermission = () => {
  return localStorage.removeItem(permissionKey);
};


export const has = (pCode) => {
  if(globalEnv.VUE_APP_Close_Permission){
    return true;
  }
  let permissionStr = getPermission();
  const codes = permissionStr ? permissionStr.split(",") : [];
  const pCodes = pCode instanceof Array && pCode.length > 0 ? pCode : [pCode];
  if(pCodes.length){
    return codes.some(code => pCodes.includes(code));
  } else {
    return true;
  }
};

export default {
  has,
  setPermission,
  removePermission,
  getPermission,
};
