<template>
  <HuiAudio
    v-if="detail.logType==2"
    v-model="detail.fileUrl"
    :is-plain-text="true"
    :name="detail.fileName"
  />
  <p
    v-else
    v-html="detail.content"
  />
</template>

<script>
import { messageDetail } from "../api/notification";
import HuiAudio from "../../../components/media/audio";

export default {
  components: {
    HuiAudio,
  },
  props: {
    detailId: {
      type: String,
      default(){
        return null;
      },
    },
  },
  data(){
    return {
      detail: {
        content: "",
        logType: 0,
        fileUrl: "",
        fileName: "",
      },
    };
  },
  created(){
    this.initDetail();
  },
  methods: {
    initDetail(){
      messageDetail(this.detailId).then((result) => {
        this.detail = result.data;
      });
    },
  },
};
</script>

<style>
</style>
