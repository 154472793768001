<template>
  <b-overlay
    :show="loading"
    rounded="sm"
  >
    <div class="d-flex">
      <!-- <div v-bind:class="{ chart: true, half: form.size === 2, third: form.size === 4, }"> -->
      <div class="chart">
        <div class="center">
          <span>{{ form.widgetName }}
            <b-icon
              class="right"
              scale="1.1"
              v-show="!loading"
              icon="arrow-clockwise"
              @click="refreshData(form)"
            />
          </span>
        </div>
        <Pivot
          v-if="form.widgetType === 1"
          ref="dataRocks"
          :toolbar="false"
          height="260px"
        />
        <Pivot
          v-if="form.widgetType === 2"
          v-show="false"
          ref="dataRocks"
          :toolbar="false"
          height="0"
          width="0"
        />
        <GChart
          v-if="form.widgetType === 2"
          :type="type"
          ref="googleChart"
          :data="data"
          :options="options"
          :settings="{ packages: ['corechart', 'table', 'sankey'] }"
          @ready="onChartTypesChanged"
        />
      </div>
    </div>
  </b-overlay>
</template>

<script>
import { Pivot } from "vue-webdatarocks";
import { GChart } from "vue-google-charts/legacy";
import {
    getTemplateList,
} from "../../../api/searchTemplate";
import { number } from "echarts";
import "webdatarocks/webdatarocks.googlecharts.js";
import { renewReportWidgetUrl } from "../api/report";

export default {
    props: {
        widget: {
            type: Object,
            default() {
                return {
                    id: "",
                    widgetName: "",
                    reportId: "",
                    templateId: "",
                    electionId: "",
                    order: 0,
                    category: 0,
                    widgetStatus: 0,
                    widgetType: 0,
                    chartType: 0,
                    size: 0,
                    isBau: true,
                    filePath: "",
                    description: "",
                    createBy: "",
                };
            }
        },
        templateType: {
            type: number,
            default() {
                return 0;
            },
        }
    },
    data() {
        return {
            loading: false,
            type: "PieChart",
            rockType: "pie",
            data: [],
            options: {
                title: "",
                curveType: "function",
                legend: { position: "bottom" },
                is3D: false,
                height: 270,
            },
            form: {
                id: this.widget.id,
                widgetName: this.widget.widgetName,
                reportId: this.widget.reportId,
                templateId: this.widget.templateId,
                electionId: this.widget.electionId,
                order: this.widget.order,
                category: this.widget.category,
                widgetStatus: this.widget.widgetStatus,
                widgetType: this.widget.widgetType,
                chartType: this.widget.chartType,
                size: this.widget.size,
                isBau: this.widget.isBau,
                filePath: this.widget.filePath,
                description: this.widget.description,
                createBy: this.widget.createBy,
            },
        };
    },
    components: { Pivot, GChart },
    methods: {
        customizeToolbar(toolbar) {
            var tabs = toolbar.getTabs();
            toolbar.getTabs = function () {
                delete tabs[0];
                delete tabs[1];
                delete tabs[2];
                return tabs;
            };
        },
        onChartTypesChanged() {
            switch (this.form.chartType) {
                case 1:
                    this.type = "AreaChart";
                    this.rockType = "area";
                    break;
                case 2:
                    this.type = "BarChart";
                    this.rockType = "bar";
                    break;
                case 4:
                    this.type = "ColumnChart";
                    this.rockType = "column";
                    break;
                case 8:
                    this.type = "LineChart";
                    this.rockType = "line";
                    break;
                case 16:
                    this.type = "PieChart";
                    this.rockType = "pie";
                    break;
                case 32:
                    this.type = "Sankey";
                    this.rockType = "sankey";
                    break;
                default:
                    this.type = "AreaChart";
                    this.rockType = "area";
                    break;
            }
            this.$refs.dataRocks.webdatarocks.googlecharts.getData(
                { type: this.rockType },
                this.getData,
                this.getData
            );
        },
        getData(_data) {
            this.data = _data.data;
        },
        getTemplate() {
            this.loading = true;
            let model = {
                templateType: this.templateType,
                electionid: this.form.electionId,
                reportId: this.form.reportId,
            };
            getTemplateList(model).then((res) => {
                const { data } = res;
                this.templateList = data;
                this.$refs.dataRocks.webdatarocks.updateData({
                filename: this.form.filePath,
            });
                for (const template of this.templateList) {
                    if (template.id == this.form.templateId) {
                        var report = JSON.parse(template.content);
                        report.dataSource.filename = this.form.filePath;
                        this.$refs.dataRocks.webdatarocks.setReport(report);
                    }
                }
                this.loading = false;
            });
        },
        refreshData(widget) {
            this.loading = true;
            renewReportWidgetUrl(widget).then((rep) => {
                this.form.filePath = rep.data;
                this.$refs.dataRocks.webdatarocks.updateData({
                    filename: this.form.filePath,
                });
                this.loading = false;
            })
        },
    },
    create() {
    },
    mounted() {
        this.getTemplate();
    },
}
</script>
<style>
.chart {
    flex: 0 0 100%;
    height: 300px;
    width: 100%;
    background-color: #ffffff;
}

.right {
    float: right;
    margin-top: 5px;
    margin-right: 2px;
}

.center {
    text-align: center;
    font-size: 20px;
}
.google-visualization-tooltip{ pointer-events: none; }
</style>
